import { AgentSelect, LEAD_PRODUCT, LEAD_TYPE } from '../../types';

export interface LeadMultiplierData {
  FIPS: string;
  MltFEX: number;
  MltMP: number;
}

export interface SubscriptionRegionData {
  FIPSRegId: number;
  Region: string | null;
  ZipCodes: string[];
}

export interface SubscriptionPriceData {
  LeadType: string | null;
  Product: string | null;
  LeadPrice: number | null;
  ExlLeadPrice: number | null;
  SubQtyMax: number | null;
}

export interface SubscriptionInfoData {
  FIPS: string;
  St: string | null;
  County: string | null;
  Pricing: SubscriptionPriceData[];
  Regions: SubscriptionRegionData[];
}

export interface CartSubData {
  RecAgt: AgentSelect | null;
  FIPS: string;
  SubRegions: SubscriptionRegionData[];
  Product: LEAD_PRODUCT;
  LeadType: LEAD_TYPE;
  WklyInv: number | string;
  Exclusive: boolean;
  TempID?: string;
}

export enum SUB_STATUS {
  'Active' = 'Active',
  'Cancelling' = 'Cancelling',
  'On Waitlist' = 'On Waitlist',
  'Cancelled' = 'Cancelled',
}

export interface SubscriptionData {
  SubscriptionID: string;
  FIPS: string;
  County: string;
  State: string;
  Product: LEAD_PRODUCT;
  LeadType: LEAD_TYPE;
  PayAmt: number;
  Status: SUB_STATUS;
  SubscribeDate: string;
  ExpirationDate: string;
}

export interface LeadSubData {
  LeadSubId: number;
  BillToAgt: string;
  BillAgtName: string;
  RecAgt: string;
  RecAgtName: string;
  FIPS: string;
  State: string;
  County: string;
  Regions: SubRegion[] | null;
  SubRegions: null;
  Product: LEAD_PRODUCT;
  DisplayName: string;
  LeadType: LEAD_TYPE;
  LeadPrice: number;
  WklyInv: number;
  Quantity: number;
  Exclusive: boolean;
  Status: SUB_STATUS;
  CreatedDate: string;
  CreatedBy: string;
  CancelledDate: string | null;
  isSuspended: 0 | 1 | null;
  BkndFillFactor: number | null;
}

export interface SubRegion {
  LeadSubId: number;
  FIPSRegId: number | null;
  Region: string | null;
  ZipCodes: string[] | null;
}
