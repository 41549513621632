import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import { useQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import { format } from 'date-fns';
import { orderBy } from 'lodash';
import React from 'react';
import { CardIcon } from '../../components/card-icon';
import { RouteContainer } from '../../components/route-container';
import { AuthContext } from '../../contexts/auth-context';
import { usePermissions } from '../../hooks/use-permissions';
import { AWS_GROUP } from '../../types';
import { toCurrency } from '../../utils/formatter';
import { isAgt } from '../../utils/is-agent';
import { OrderCartData } from './data';
import { OrderItem } from './order-item';

export function History() {
  // Context
  const {
    state: { impersonatedAgent, user },
  } = React.useContext(AuthContext);
  // State
  const [selected, setSelected] = React.useState<number>();
  // Hooks
  const { hasGroup } = usePermissions();

  const AgtNo = impersonatedAgent?.AgtNo || user?.getUsername();

  // Query - Cart History
  const path = '/leads/instant-purchase/cart-history';
  const query = useQuery({
    queryKey: [path, { AgtNo }],
    queryFn: async () => {
      if (isAgt(AgtNo)) {
        const response: {
          data: OrderCartData[];
        } = await API.post('LeadsAPI', path, { body: { AgtNo } });

        return response.data;
      }

      return [];
    },
  });

  const orders = orderBy(query.data || [], 'ComplDate', 'desc');

  return (
    <RouteContainer
      routeTitle="Cart History"
      loading={query.isFetching}
      hasError={query.isError}
    >
      <Container maxWidth="sm">
        <Box sx={{ pt: 2, pb: 16 }}>
          <Stack spacing={1}>
            {!isAgt(AgtNo) &&
            hasGroup(AWS_GROUP.LDS_GlobalAdmin) &&
            orders.length === 0 ? (
              <Box
                sx={{
                  p: 4,
                  textAlign: 'center',
                  backgroundColor: '#f5f5f5',
                  border: '1px solid #cacaca',
                  borderRadius: 2,
                }}
              >
                <Box sx={{ fontFamily: 'Roboto Mono' }}>
                  Impersonate an Agent to view their Cart History
                </Box>
              </Box>
            ) : orders.length === 0 ? (
              <Box
                sx={{
                  p: 4,
                  textAlign: 'center',
                  backgroundColor: '#f5f5f5',
                  border: '1px solid #cacaca',
                  borderRadius: 2,
                }}
              >
                <Box sx={{ fontFamily: 'Roboto Mono' }}>
                  No orders in Cart History
                </Box>
              </Box>
            ) : null}

            {orders.map((item) => {
              return (
                <Box
                  key={item.CartId}
                  component="button"
                  sx={{
                    color: '#000',
                    textAlign: 'left',
                    borderWidth: 2,
                    borderStyle: 'solid',
                    borderColor: '#eaeaea',
                    borderRadius: 1,
                    backgroundColor: '#fff',
                    cursor: 'pointer',
                    transition: 'all 0.2s',
                    ':hover': {
                      transform: 'scale(1.01)',
                      borderColor: '#aeaeae',
                    },
                  }}
                  onClick={() => setSelected(item.CartId)}
                >
                  <Stack direction="row" alignItems="center" sx={{ p: 0.5 }}>
                    <Box>
                      <Stack spacing={1} direction="row" alignItems="center">
                        <Box sx={{ fontSize: 22, fontFamily: 'Roboto Mono' }}>
                          {toCurrency({ value: item.FinalTotal })}
                        </Box>

                        {item.AcctType ? (
                          <CardIcon cardType={item.AcctType} size="lg" />
                        ) : null}
                      </Stack>

                      {item.ComplDate ? (
                        <Box component="small">
                          {format(new Date(item.ComplDate), 'PPPppp')}
                        </Box>
                      ) : null}
                    </Box>

                    <Box sx={{ flex: 1 }} />

                    <Stack spacing={0.5}>
                      <Stack
                        spacing={1}
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-end"
                      >
                        <Box sx={{ fontSize: 16 }}>Leads</Box>

                        <Box
                          sx={{
                            textAlign: 'right',
                            fontSize: 18,
                            fontFamily: 'Roboto Mono',
                            fontWeight: 'bold',
                            color: '#2196f3',
                          }}
                        >
                          {item.TotalLeads}
                        </Box>
                      </Stack>
                      <Stack
                        spacing={1}
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-end"
                      >
                        <Box sx={{ fontSize: 16 }}>Counties</Box>

                        <Box
                          sx={{
                            textAlign: 'right',
                            fontSize: 18,
                            fontFamily: 'Roboto Mono',
                            fontWeight: 'bold',
                            color: '#b26500',
                          }}
                        >
                          {item.TotalCounties}
                        </Box>
                      </Stack>
                    </Stack>
                  </Stack>
                </Box>
              );
            })}
          </Stack>
        </Box>

        <OrderItem
          item={orders.find((i) => i.CartId === selected)}
          onClose={() => setSelected(undefined)}
        />
      </Container>
    </RouteContainer>
  );
}
