import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Warning from '@mui/icons-material/Warning';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { usePermissions } from '../../hooks/use-permissions';
import { AWS_GROUP, LEAD_PRODUCT } from '../../types';
import { toCurrency } from '../../utils/formatter';
import { LeadSubData, SUB_STATUS } from './data';

export function MySubscriptionsItem(props: {
  item: LeadSubData;
  onSelect: (LeadSubId: LeadSubData['LeadSubId']) => void;
}) {
  // Props
  const { item } = props;
  // Hooks
  const { hasGroup } = usePermissions();

  const subForSelf = item.RecAgt === item.BillToAgt;

  let canEdit = true;
  // Only Admins can edit Annuity Products
  if (item.Product === LEAD_PRODUCT['ANN']) {
    if (hasGroup(AWS_GROUP.LDS_GlobalAdmin)) {
      canEdit = true;
    } else {
      canEdit = false;
    }
  }

  return (
    <Stack
      spacing={1}
      direction="row"
      alignItems="center"
      sx={{
        p: 1,
        px: 2,
        transition: 'all 0.2s',
        ':hover': {
          backgroundColor: '#f5f5f5',
        },
      }}
    >
      {item.isSuspended ? (
        <Tooltip title="Billing Suspended" arrow placement="left">
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              py: { xs: 0.5, md: 1 },
            }}
          >
            <Warning color="warning" sx={{ height: 36 }} />
          </Box>
        </Tooltip>
      ) : null}

      <Box
        sx={{
          px: { xs: 0, md: 1 },
          fontSize: 18,
          fontWeight: 'bold',
          fontFamily: 'Roboto Mono',
          color: 'teal',
        }}
      >
        {item.LeadType}
      </Box>

      <Box
        sx={{
          fontWeight: 'bold',
          fontFamily: 'Roboto Mono',
          textTransform: 'uppercase',
        }}
      >
        <Stack
          sx={{
            fontSize: { xs: 13, md: 15 },
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: { xs: 'flex-start', md: 'center' },
          }}
        >
          <Box
            sx={{
              textTransform: 'none',
              fontFamily: 'Roboto',
              mr: { xs: 0, md: 1 },
            }}
          >
            {item?.RecAgtName}
          </Box>
          <Box sx={{ color: subForSelf ? '#b26500' : 'purple', fontSize: 12 }}>
            {item?.RecAgt}
          </Box>
        </Stack>

        {item.Status === SUB_STATUS['Active'] ? (
          <Box>
            <Box sx={{ fontSize: 13, color: '#00C868' }}>Active</Box>
            <Box sx={{ fontSize: 12, color: '#9b9b9b' }}>
              Subscribed {item.CreatedDate.slice(0, 10)}
            </Box>
          </Box>
        ) : item.Status === SUB_STATUS['Cancelling'] ? (
          <Box>
            <Box sx={{ fontSize: 14, color: 'darkorange' }}>Cancelling</Box>
            <Box sx={{ fontSize: 14, color: '#4a4a4a' }}>
              Expires {item.CancelledDate?.slice(0, 10)}
            </Box>
          </Box>
        ) : item.Status === SUB_STATUS['On Waitlist'] ? (
          <Box>
            <Box sx={{ fontSize: 13, color: '#0094FF' }}>On Waitlist</Box>
            <Box sx={{ fontSize: 12, color: '#9b9b9b' }}>
              Subscribed {item.CreatedDate.slice(0, 10)}
            </Box>
          </Box>
        ) : item.Status === SUB_STATUS['Cancelled'] ? (
          <Box>
            <Box sx={{ fontSize: 13, color: '#b61f26' }}>Cancelled</Box>
            <Box sx={{ fontSize: 12, color: '#9b9b9b' }}>
              Expired {item.CancelledDate?.slice(0, 10)}
            </Box>
          </Box>
        ) : null}
      </Box>

      <Box
        sx={{
          flex: 1,
          borderTop: '1px solid #eaeaea',
        }}
      />

      <Box
        sx={{
          fontFamily: 'Roboto Mono',
          fontSize: 14,
          color: '#9b9b9b',
          textDecoration: item.Status === 'Cancelled' ? 'line-through' : 'none',
        }}
      >
        {toCurrency({ value: item.WklyInv })}
      </Box>

      {canEdit ? (
        <Tooltip title="Manage Subscription" placement="left" arrow>
          <IconButton
            size="small"
            onClick={() => props.onSelect(item.LeadSubId)}
          >
            <MoreHorizIcon />
          </IconButton>
        </Tooltip>
      ) : null}
    </Stack>
  );
}
