import CloseIcon from '@mui/icons-material/Close';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip, { ChipOwnProps } from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import React from 'react';
import { AuthContext } from '../../contexts/auth-context';
import { CartContext } from '../../contexts/cart-context';
import { CountyFeatureData } from '../../data/county-features';
import { usePermissions } from '../../hooks/use-permissions';
import { AWS_GROUP, LEAD_PRODUCT, LEAD_TYPE } from '../../types';
import { captureError } from '../../utils/capture-error';
import { toCurrency } from '../../utils/formatter';
import { getProductTitle } from '../../utils/general';
import { sumArray } from '../../utils/sumarray';
import { LeadMultiplierData } from '../subscriptions/data';
import { CartItem } from './cart-item';
import { PurchaseInfoData } from './data';
import { PurchaseAdd } from './purchase-add';
import {
  getCartItemID,
  getCartItemUpdate,
  getDetailsFIPS,
} from './purchase-utils';

function PricingIndicator(props: {
  Product: LEAD_PRODUCT;
  Multiplier: number;
}) {
  let label: ChipOwnProps['label'] = 'Base';
  let color: ChipOwnProps['color'] = 'default';
  let icon: ChipOwnProps['icon'] = <TrendingFlatIcon fontSize="small" />;
  if (props.Multiplier > 1) {
    label = 'Premium';
    color = 'error';
    icon = <TrendingUpIcon fontSize="small" />;
  } else if (props.Multiplier < 1) {
    label = 'Discount';
    color = 'success';
    icon = <TrendingDownIcon fontSize="small" />;
  }

  return (
    <Chip
      size="small"
      variant="outlined"
      color={color}
      icon={icon}
      label={label}
      sx={{ fontWeight: 'bold' }}
    />
  );
}

export function CountyListItem(props: {
  feature: google.maps.Data.Feature;
  index: number;
  county: CountyFeatureData | null;
  leadMult: LeadMultiplierData | undefined;
  onUnselect: () => void;
}) {
  // Props
  const { county } = props;
  // Context
  const {
    state: { cart },
  } = React.useContext(CartContext);
  const {
    state: { impersonatedAgent, user },
  } = React.useContext(AuthContext);
  // State
  const [open, setOpen] = React.useState(false);
  const [adding, setAdding] = React.useState(false);
  // Hooks
  const { hasGroup } = usePermissions();

  React.useEffect(() => {
    if (props.index === 0) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [props.index]);

  // Only Admins can manage carts for other users
  let AgtNo = user?.getUsername() || null;
  if (hasGroup(AWS_GROUP.LDS_GlobalAdmin)) {
    AgtNo = impersonatedAgent?.AgtNo || null;
  }

  const FIPS = county?.FIPS || '';

  // Query - Available Leads for County
  const path = '/leads/instant-purchase/county-info';
  const query = useQuery({
    queryKey: [path, { FIPS, AgtNo }],
    queryFn: async () => {
      const response: {
        data: PurchaseInfoData;
      } = await API.post('LeadsAPI', path, {
        body: { FIPS, AgtNo },
      });

      return response.data;
    },
  });

  const CartItems = cart?.CartItems || [];
  const { quantity, purchases } = getDetailsFIPS({ data: CartItems, FIPS });

  const queryClient = useQueryClient();
  // Mutation - Remove Leads for County from Cart
  const mutationRemove = useMutation({
    mutationFn: async () => {
      if (purchases.length) {
        for (const key in purchases) {
          const purchase = purchases[key];
          const Quantity = 0;
          const newItem = { ...purchase, Quantity };
          const update = getCartItemUpdate({
            item: newItem,
            BillToAgt: cart?.BillToAgt,
          });
          if (update) {
            await API.post('LeadsAPI', '/leads/instant-purchase/cart/update', {
              body: { ...update },
            });
          }
        }
      }
    },
    onSuccess: async () => {
      // Refetch cart data
      await queryClient.invalidateQueries({
        queryKey: ['/leads/instant-purchase/cart'],
      });

      // Remove County from Map
      props.onUnselect();
    },
    onError: (error) => captureError({ data: { error } }),
  });

  let availableInventory = 0;
  if (query.data) {
    availableInventory = sumArray(
      query.data.Pricing.map((i) => i.TotalLeads || 0),
    );
  }

  return (
    <React.Fragment>
      <Paper
        sx={{
          overflow: 'hidden',
          background: `linear-gradient(to top left, #00ff0011, #fffbf3)`,
        }}
      >
        <Stack
          spacing={1}
          direction="row"
          alignItems="center"
          sx={{
            p: 1,
            py: 0.5,
            flex: 1,
            background: `linear-gradient(to bottom right, #00ff0022, #fffbf3)`,
          }}
        >
          <Tooltip title="Remove County" placement="right" arrow>
            <span>
              <IconButton
                disabled={mutationRemove.isPending}
                size="small"
                sx={{
                  borderRadius: 1.2,
                  border: '1px solid #cacaca',
                  backgroundColor: '#fff',
                }}
                onClick={() => {
                  if (purchases.length) {
                    // Remove from Cart
                    mutationRemove.mutate();
                  } else {
                    // Remove from Map
                    props.onUnselect();
                  }
                }}
              >
                {mutationRemove.isPending ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  <CloseIcon />
                )}
              </IconButton>
            </span>
          </Tooltip>

          <Box
            sx={{
              color: '#b26500',
              fontSize: { xs: 16, md: 22 },
              fontWeight: 'bold',
              fontFamily: 'Roboto Mono',
            }}
          >
            {county?.StateAbbr}
          </Box>

          <Box sx={{ fontSize: { xs: 18, md: 24 } }}>{county?.NAME}</Box>

          <Box sx={{ flex: 1 }} />

          {query.isLoading ? <CircularProgress size={24} color="info" /> : null}

          <Chip
            disabled={!Boolean(quantity)}
            size="small"
            label={
              <Box sx={{ fontWeight: 'bold' }}>
                <Stack spacing={1} direction="row" alignItems="center">
                  <Box sx={{ fontFamily: 'Roboto Mono' }}>{quantity || 0}</Box>
                  <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                    in Cart
                  </Box>
                </Stack>
              </Box>
            }
            variant="outlined"
            color="success"
            sx={{ backgroundColor: '#fff' }}
          />

          <Chip
            disabled={availableInventory === 0}
            size="small"
            label={
              <Box sx={{ fontWeight: 'bold' }}>
                <Stack spacing={1} direction="row" alignItems="center">
                  <Box sx={{ fontFamily: 'Roboto Mono' }}>
                    {availableInventory}
                  </Box>
                  <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                    Available
                  </Box>
                </Stack>
              </Box>
            }
            variant="outlined"
            color="info"
            sx={{ backgroundColor: '#fff' }}
          />

          <Tooltip title={open ? 'Collapse' : 'Expand'} placement="left" arrow>
            <IconButton
              size="small"
              onClick={() => setOpen((currentState) => !currentState)}
              sx={{ backgroundColor: '#fff' }}
            >
              {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Tooltip>
        </Stack>

        {open ? (
          <React.Fragment>
            <Divider />

            <Box sx={{ p: 1 }}>
              <Stack spacing={1}>
                <Box>
                  <Stack spacing={1} direction="row">
                    {[LEAD_PRODUCT['FEX'], LEAD_PRODUCT['MP']].map(
                      (Product) => {
                        let Multiplier: number | null = null;
                        if (props.leadMult) {
                          if (Product === LEAD_PRODUCT['FEX']) {
                            Multiplier = props.leadMult.MltFEX;
                          } else if (Product === LEAD_PRODUCT['MP']) {
                            Multiplier = props.leadMult.MltMP;
                          }
                        }

                        return (
                          <Box
                            key={Product}
                            sx={{
                              flex: 1,
                              backgroundColor: '#fff',
                              border: '1px solid #cacaca',
                              borderRadius: 1,
                            }}
                          >
                            <Stack spacing={0.4}>
                              <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                                sx={{ p: 1 }}
                              >
                                <Box sx={{ fontWeight: 'bold' }}>
                                  <Box
                                    sx={{
                                      display: { xs: 'block', md: 'none' },
                                    }}
                                  >
                                    {Product}
                                  </Box>
                                  <Box
                                    sx={{
                                      display: { xs: 'none', md: 'block' },
                                    }}
                                  >
                                    {getProductTitle(Product as LEAD_PRODUCT)}
                                  </Box>
                                </Box>

                                {Multiplier ? (
                                  <PricingIndicator
                                    Product={Product as LEAD_PRODUCT}
                                    Multiplier={Multiplier}
                                  />
                                ) : null}
                              </Stack>

                              {Object.keys(LEAD_TYPE).map((LeadType) => {
                                const leadInventory = query.data?.Pricing.find(
                                  (i) =>
                                    i.Product === Product &&
                                    i.LeadType === LeadType,
                                );
                                const TotalLeads =
                                  leadInventory?.TotalLeads || 0;

                                return (
                                  <Box key={LeadType} sx={{ px: 1 }}>
                                    <Stack
                                      spacing={1}
                                      direction="row"
                                      alignItems="center"
                                    >
                                      <Box
                                        sx={{
                                          opacity: TotalLeads === 0 ? 0.3 : 1,
                                          fontSize: { xs: 16, md: 18 },
                                          fontWeight: 'bold',
                                          fontFamily: 'Roboto Mono',
                                        }}
                                      >
                                        {LeadType}
                                      </Box>

                                      {TotalLeads > 0 ? (
                                        <Box
                                          sx={{
                                            fontFamily: 'Roboto Mono',
                                            fontWeight: 'bold',
                                            fontSize: { xs: 10, md: 12 },
                                          }}
                                        >
                                          {toCurrency({
                                            value:
                                              leadInventory?.LeadPrice || 0,
                                          })}
                                        </Box>
                                      ) : null}

                                      <Box sx={{ flex: 1 }} />

                                      <Button
                                        disabled={TotalLeads === 0}
                                        color="info"
                                        size="small"
                                        variant={
                                          TotalLeads === 0 ? 'text' : 'outlined'
                                        }
                                        onClick={() => setAdding(true)}
                                        sx={{ minWidth: { xs: 48, md: 64 } }}
                                      >
                                        {TotalLeads}
                                      </Button>
                                    </Stack>
                                  </Box>
                                );
                              })}
                            </Stack>
                          </Box>
                        );
                      },
                    )}
                  </Stack>
                </Box>

                {purchases.map((purchase) => {
                  const purchaseKey = getCartItemID({ data: purchase });
                  return <CartItem key={purchaseKey} item={purchase} />;
                })}

                <Button
                  disabled={availableInventory === 0}
                  fullWidth
                  variant="outlined"
                  size="large"
                  color="info"
                  sx={{ backgroundColor: '#fffe' }}
                  startIcon={<PlaylistAddIcon />}
                  onClick={() => setAdding(true)}
                >
                  Select Leads for {county?.NAME}
                </Button>
              </Stack>
            </Box>
          </React.Fragment>
        ) : null}
      </Paper>

      {query.data ? (
        <PurchaseAdd
          open={adding}
          onClose={() => setAdding(false)}
          feature={props.feature}
          PurchaseInfo={query.data}
        />
      ) : null}
    </React.Fragment>
  );
}
