import React, { Dispatch } from 'react';
import { CartData } from '../routes/leads-map/data';
import { CartSubData } from '../routes/subscriptions/data';

// State
type CartState = {
  cartOpen: boolean;
  cart: CartData | null;
  leadsMap: google.maps.Data.Feature[];
  subsMap: google.maps.Data.Feature[];
  subs: CartSubData[];
};
const initialState: CartState = {
  cartOpen: false,
  cart: null,
  leadsMap: [],
  subsMap: [],
  subs: [],
};

// Actions
type CartActions =
  | { type: 'TOGGLE_CART' }
  | { type: 'VIEW_CART'; payload: boolean }
  | { type: 'RESET_LEADS' }
  | { type: 'RESET_SUBS' }
  | { type: 'ADD_FEAT_LEADS'; payload: google.maps.Data.Feature }
  | { type: 'ADD_FEAT_SUBS'; payload: google.maps.Data.Feature }
  | { type: 'REMOVE_FEAT_LEADS'; payload: { GEO_ID: string } }
  | { type: 'REMOVE_FEAT_SUBS'; payload: { GEO_ID: string } }
  | { type: 'CART_SET_FEAT'; payload: google.maps.Data.Feature }
  | { type: 'CART_SET_LEADS'; payload: CartData | null }
  | { type: 'CART_SUB_ADD'; payload: CartSubData }
  | { type: 'CART_SUB_REMOVE'; payload: CartSubData['TempID'] }
  | { type: 'CART_SUB_REMOVE_COUNTY'; payload: CartSubData['FIPS'] };

const reducer = (state: CartState, action: CartActions) => {
  switch (action.type) {
    case 'TOGGLE_CART': {
      return { ...state, cartOpen: !state.cartOpen };
    }
    case 'VIEW_CART': {
      return { ...state, cartOpen: action.payload };
    }
    case 'RESET_LEADS': {
      return { ...state, leads: null, leadsMap: [] };
    }
    case 'RESET_SUBS': {
      return { ...state, subs: [], subsMap: [] };
    }
    case 'ADD_FEAT_LEADS': {
      // Check to see if this feature is already selected on the map
      // This can happen when adding counties from the search component
      const isAlreadyOnMap = state.leadsMap.find(
        (i) => i.getProperty('GEO_ID') === action.payload.getProperty('GEO_ID'),
      );

      let leadsMap = state.leadsMap;
      if (isAlreadyOnMap === undefined) {
        leadsMap = [action.payload, ...state.leadsMap];
      }

      return { ...state, leadsMap };
    }
    case 'ADD_FEAT_SUBS': {
      // Check to see if this feature is already selected on the map
      // This can happen when adding counties from the search component
      const isAlreadyOnMap = state.subsMap.find(
        (i) => i.getProperty('GEO_ID') === action.payload.getProperty('GEO_ID'),
      );

      let subsMap = state.subsMap;
      if (isAlreadyOnMap === undefined) {
        subsMap = [action.payload, ...state.subsMap];
      }

      return { ...state, subsMap };
    }
    case 'REMOVE_FEAT_LEADS': {
      const { GEO_ID } = action.payload;
      return {
        ...state,
        leadsMap: state.leadsMap.filter((i) => {
          const I_GEO_ID = i.getProperty('GEO_ID');
          return Boolean(I_GEO_ID !== GEO_ID);
        }),
      };
    }
    case 'REMOVE_FEAT_SUBS': {
      const { GEO_ID } = action.payload;
      return {
        ...state,
        subsMap: state.subsMap.filter((i) => {
          const I_GEO_ID = i.getProperty('GEO_ID');
          return Boolean(I_GEO_ID !== GEO_ID);
        }),
      };
    }
    case 'CART_SET_FEAT': {
      // Set a County from the Cart to be selected on the Map
      // Useful for something like a page refresh which will reset selected counties on the map
      const feature = action.payload;
      const GEO_ID = feature.getProperty('GEO_ID') as string;
      // Check to see if the County from the Cart is already selected on the map
      const foundFeature = state.leadsMap.find((i) => {
        const i_GEO_ID = i.getProperty('GEO_ID') as string;
        return i_GEO_ID === GEO_ID;
      });
      let leadsMap = [...state.leadsMap];
      if (foundFeature === undefined) {
        // Add County to map
        leadsMap = [...state.leadsMap, feature];
      }

      return { ...state, leadsMap };
    }
    case 'CART_SET_LEADS': {
      return { ...state, cart: action.payload };
    }
    case 'CART_SUB_ADD': {
      const newSub = action.payload;

      return { ...state, subs: [...state.subs, newSub] };
    }
    case 'CART_SUB_REMOVE': {
      const TempID = action.payload;

      // Remove any sub with this TempID
      const currentSubs = state.subs.filter((i) => i.TempID !== TempID);

      return { ...state, subs: [...currentSubs] };
    }
    case 'CART_SUB_REMOVE_COUNTY': {
      const FIPS = action.payload;

      // Remove all Subs for this FIPS
      const currentSubs = state.subs.filter((i) => i.FIPS !== FIPS);

      return { ...state, subs: [...currentSubs] };
    }
    default: {
      return { ...state };
    }
  }
};

export const CartContext = React.createContext(
  {} as {
    state: CartState;
    dispatch: Dispatch<CartActions>;
  },
);

export function CartProvider(props: { children: React.ReactNode }) {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return (
    <CartContext.Provider value={{ state, dispatch }}>
      {props.children}
    </CartContext.Provider>
  );
}
