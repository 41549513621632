import AddIcon from '@mui/icons-material/Add';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import RemoveIcon from '@mui/icons-material/Remove';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import { orderBy } from 'lodash';
import React from 'react';
import { DisplayMutationError } from '../../components/display-mutation-error';
import { SelectAgent } from '../../components/select-agent';
import { AuthContext } from '../../contexts/auth-context';
import { CartContext } from '../../contexts/cart-context';
import { usePermissions } from '../../hooks/use-permissions';
import { AWS_GROUP, AgentSelect, LEAD_PRODUCT, LEAD_TYPE } from '../../types';
import { captureError } from '../../utils/capture-error';
import { toCurrency } from '../../utils/formatter';
import { getProductTitle } from '../../utils/general';
import { isAgt } from '../../utils/is-agent';
import { getCounty } from '../../utils/map';
import { multNums } from '../../utils/mult-nums';
import { sumArray } from '../../utils/sumarray';
import {
  CartPurchaseData,
  PurchaseInfoData,
  PurchasePriceData,
  PurchaseRegionData,
} from './data';
import { PurchaseAddSelectRegion } from './purchase-add-select-region';
import { getCartItemID } from './purchase-utils';

const getCartUpdates = (params: {
  data: CartPurchaseData;
  BillToAgt: string;
}) => {
  // Params
  const { data, BillToAgt } = params;

  let purchases: {
    BillToAgt: string;
    RecAgt: string | null;
    FIPS: string;
    Product: string;
    LeadType: string;
    FIPSRegId: number | null;
    Quantity: number;
    Exclusive: boolean;
  }[] = [];

  data.Amounts.forEach((i) => {
    if (Number(i.Quantity) > 0) {
      purchases.push({
        BillToAgt,
        RecAgt: data.RecAgt?.AgtNo || BillToAgt,
        FIPS: data.FIPS,
        Product: i.Product,
        LeadType: i.LeadType,
        FIPSRegId: data.FIPSReg?.FIPSRegId || null,
        Quantity: Number(i.Quantity),
        Exclusive: data.Exclusive,
      });
    }
  });

  return purchases;
};

const getProductOptionID = (params: {
  RecAgt: string;
  FIPS: string;
  FIPSRegId: number | undefined;
  option: {
    LeadType: LEAD_TYPE;
    Product: LEAD_PRODUCT;
    Quantity: string;
    Pricing: PurchasePriceData;
  };
}) => {
  const { RecAgt, FIPS, FIPSRegId } = params;
  const { LeadType, Product } = params.option;
  return `${RecAgt}-${FIPS}-${FIPSRegId || '_'}-${LeadType}-${Product}`;
};

interface PurchaseData {
  FIPS: CartPurchaseData['FIPS'];
  FIPSReg: CartPurchaseData['FIPSReg'];
  Exclusive: CartPurchaseData['Exclusive'];
  Amounts: CartPurchaseData['Amounts'];
}

export function PurchaseAdd(props: {
  open: boolean;
  onClose: () => void;
  feature: google.maps.Data.Feature;
  PurchaseInfo: PurchaseInfoData;
}) {
  // Props
  const { feature } = props;
  // Context
  const {
    state: { impersonatedAgent, user },
  } = React.useContext(AuthContext);
  const {
    state: { cart },
  } = React.useContext(CartContext);
  // State
  const [showRecAgt, setShowRecAgt] = React.useState(false);
  const [RecAgt, setRecAgt] = React.useState<AgentSelect | null>(null);
  const [PurchaseInfo, setPurchaseInfo] = React.useState(props.PurchaseInfo);
  const [FIPSRegId, setFIPSRegId] =
    React.useState<PurchaseRegionData['FIPSRegId']>();
  const [data, setData] = React.useState<PurchaseData>({
    FIPS: props.PurchaseInfo.FIPS,
    FIPSReg: null,
    Exclusive: false,
    Amounts: [],
  });
  // Hooks
  const { hasGroup } = usePermissions();

  const isAdmin = hasGroup(AWS_GROUP.LDS_GlobalAdmin);
  // Only Admins can manage carts for other users
  let AgtNo = user?.getUsername() || null;
  if (isAdmin) {
    AgtNo = impersonatedAgent?.AgtNo || null;
  }

  const county = getCounty(feature);
  const FIPS = county?.FIPS || '';
  const purchaseFor = RecAgt?.AgtNo || AgtNo;

  // Query - Available Leads for County
  const path = '/leads/instant-purchase/county-info';
  const query = useQuery({
    enabled: props.open,
    queryKey: [path, { FIPS, AgtNo: purchaseFor }],
    queryFn: async () => {
      const response: {
        data: PurchaseInfoData;
      } = await API.post('LeadsAPI', path, {
        body: { FIPS, AgtNo: purchaseFor },
      });

      setPurchaseInfo(response.data);

      return response.data;
    },
  });

  React.useEffect(() => {
    if (props.open) {
      const Amounts: CartPurchaseData['Amounts'] = [];

      let region: PurchaseRegionData | undefined = undefined;
      if (FIPSRegId) {
        region = PurchaseInfo.Regions.find((i) => i.FIPSRegId === FIPSRegId);
      }

      if (region) {
        region.Pricing.forEach((Pricing) => {
          if (Pricing.TotalLeads && Pricing.TotalLeads > 0) {
            Amounts.push({
              LeadType: Pricing.LeadType as LEAD_TYPE,
              Product: Pricing.Product as LEAD_PRODUCT,
              Quantity: '0',
              Pricing,
            });
          }
        });
      } else if (PurchaseInfo.Regions.length === 0) {
        PurchaseInfo.Pricing.forEach((Pricing) => {
          if (Pricing.TotalLeads && Pricing.TotalLeads > 0) {
            Amounts.push({
              LeadType: Pricing.LeadType as LEAD_TYPE,
              Product: Pricing.Product as LEAD_PRODUCT,
              Quantity: '0',
              Pricing,
            });
          }
        });
      }

      const newData: CartPurchaseData = {
        RecAgt: null,
        FIPS: PurchaseInfo.FIPS,
        FIPSReg: region || null,
        Exclusive: false,
        Amounts: orderBy(Amounts, 'LeadType'),
      };
      setData(newData);
    }
  }, [PurchaseInfo, FIPSRegId, props.open]);

  const queryClient = useQueryClient();

  const handleClose = async () => {
    props.onClose();
    // Reset
    setShowRecAgt(false);
    setRecAgt(null);
    setFIPSRegId(undefined);
    mutationAdd.reset();
    // Refetch county info to reflect any Leads added to cart
    // Spefically if the Agent has added Leads for another Agent
    // the amount of Leads available to the current Agent will have been reduced
    await queryClient.invalidateQueries({
      queryKey: ['/leads/instant-purchase/county-info', { FIPS, AgtNo }],
    });
  };

  const BillToAgt = AgtNo || '';
  const pathCart = '/leads/instant-purchase/cart';
  const pathUpdate = '/leads/instant-purchase/cart/update';

  // Mutation - Add to Cart
  const mutationAdd = useMutation({
    mutationFn: async () => {
      const updates = getCartUpdates({ data: { ...data, RecAgt }, BillToAgt });
      for (const key in updates) {
        const update = updates[key];
        await API.post('LeadsAPI', pathUpdate, { body: { ...update } });
      }
    },
    onSuccess: async () => {
      // Refetch number of Leads Available for County
      await queryClient.invalidateQueries({
        queryKey: [path, { FIPS, AgtNo: purchaseFor }],
      });
      // Refetch Cart Content after adding any updates
      await queryClient.invalidateQueries({ queryKey: [pathCart] });
    },
    onError: (error) => captureError({ data: { error } }),
  });

  // If the county has regions they will need to select a Region before selecting Leads
  const hasRegions = props.PurchaseInfo.Regions.length > 0;

  // If they have selected a Region get the name of the Region
  let regionName = '';
  if (FIPSRegId) {
    regionName =
      PurchaseInfo.Regions.find((i) => i.FIPSRegId === FIPSRegId)?.Region || '';
  }

  // Total number of Leads being purchased
  const quantity = sumArray(data.Amounts.map((i) => Number(i.Quantity) || 0));

  // Total amount being spent on Leads selected
  const spend = sumArray(
    data.Amounts.map((i) => {
      if (data.Exclusive && i.Pricing.ExlLeadPrice) {
        return multNums(Number(i.Quantity), i.Pricing.ExlLeadPrice);
      } else if (i.Pricing.LeadPrice) {
        return multNums(Number(i.Quantity), i.Pricing.LeadPrice);
      } else {
        return 0;
      }
    }),
  );

  // For at least one of the Lead Types the user has
  // selected more Leads than are available for that Type
  let exceedsAvailable = false;
  data.Amounts.forEach((amount) => {
    if (!exceedsAvailable && amount.Pricing.TotalLeads) {
      const hasExceeded = Number(amount.Quantity) > amount.Pricing.TotalLeads;
      if (hasExceeded) {
        exceedsAvailable = hasExceeded;
      }
    }
  });

  const submitDisabled = !isAgt(AgtNo) || quantity <= 0 || exceedsAvailable;

  return (
    <Drawer
      container={window.document.body}
      variant="temporary"
      anchor="right"
      open={props.open}
      onClose={handleClose}
      sx={{
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: { xs: '100vw', md: '60vw', lg: '50vw', xl: '40vw' },
          height: '100dvh',
        },
      }}
    >
      <Box sx={{ height: '100dvh', display: 'flex', flexDirection: 'column' }}>
        <Toolbar>
          <IconButton
            disabled={mutationAdd.isPending}
            sx={{ mr: 2 }}
            onClick={handleClose}
          >
            {mutationAdd.isPending ? (
              <CircularProgress size={24} />
            ) : (
              <CloseIcon />
            )}
          </IconButton>

          <Box sx={{ flex: 1, display: 'flex', alignItems: 'center' }}>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ fontSize: { xs: 14, sm: 16, md: 20, lg: 22 } }}
            >
              Select Leads for {county?.NAME} {county?.LSAD}
            </Typography>

            <Box sx={{ flex: 1 }} />
          </Box>
        </Toolbar>

        <Divider />

        {hasRegions && !FIPSRegId ? (
          <PurchaseAddSelectRegion
            PurchaseInfo={props.PurchaseInfo}
            onSelect={(newRegion) => setFIPSRegId(newRegion.FIPSRegId)}
          />
        ) : (
          <Box
            sx={{
              minHeight: 0,
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box sx={{ flex: 1, overflow: 'scroll' }}>
              <Stack spacing={2} sx={{ p: 2 }}>
                {FIPSRegId ? (
                  <Box
                    component="button"
                    sx={{
                      pr: { xs: 1, md: 2 },
                      fontFamily: 'Roboto',
                      textAlign: 'left',
                      borderWidth: 2,
                      borderStyle: 'solid',
                      borderColor: '#eaeaea',
                      borderRadius: 1,
                      backgroundColor: '#fff',
                      cursor: 'pointer',
                      transition: 'all 0.2s',
                      ':hover': {
                        transform: 'scale(1.01)',
                        borderColor: '#aeaeae',
                      },
                    }}
                    onClick={() => {
                      // Clear out selected Region
                      setFIPSRegId(undefined);
                      // Reset the mutation
                      mutationAdd.reset();
                    }}
                  >
                    <Stack
                      spacing={1}
                      direction="row"
                      alignItems="center"
                      sx={{ p: 0.5 }}
                    >
                      <ArrowBackIcon />

                      <Box sx={{ fontWeight: 'bold', fontSize: 24 }}>
                        {regionName}
                      </Box>

                      <Box sx={{ flex: 1 }} />
                    </Stack>
                  </Box>
                ) : null}

                <Stack
                  spacing={1}
                  direction={{ xs: 'column', md: 'row' }}
                  alignItems={{ xs: 'flex-start', md: 'center' }}
                >
                  <FormControlLabel
                    disabled={
                      mutationAdd.isPending ||
                      mutationAdd.isSuccess ||
                      mutationAdd.isError
                    }
                    control={
                      <Switch
                        size="medium"
                        color="info"
                        checked={showRecAgt}
                        onChange={(event, checked) => {
                          setShowRecAgt(checked);
                          setRecAgt(null);
                        }}
                      />
                    }
                    label={
                      <Box sx={{ fontWeight: 'bold', textWrap: 'nowrap' }}>
                        Receiving Agent
                      </Box>
                    }
                  />

                  <Box component="small" sx={{ color: '#4a4a4a' }}>
                    Do you want an Agent other than yourself to receive the
                    Leads selected for purchase?
                  </Box>
                </Stack>

                {showRecAgt ? (
                  <SelectAgent
                    onSelectAgent={(agent) => {
                      setRecAgt(agent);
                    }}
                  />
                ) : null}

                {query.isLoading ? <LinearProgress /> : null}

                <Stack
                  spacing={1}
                  direction={{ xs: 'column', md: 'row' }}
                  alignItems={{ xs: 'flex-start', md: 'center' }}
                >
                  <FormControlLabel
                    disabled={
                      mutationAdd.isPending ||
                      mutationAdd.isSuccess ||
                      mutationAdd.isError
                    }
                    control={
                      <Switch
                        size="medium"
                        color="info"
                        checked={data.Exclusive}
                        onChange={(event, checked) => {
                          setData((currentState) => ({
                            ...currentState,
                            Exclusive: checked,
                          }));
                        }}
                      />
                    }
                    label={
                      <Box
                        sx={{
                          textTransform: 'uppercase',
                          fontWeight: 'bold',
                          color: 'darkorange',
                        }}
                      >
                        Exclusive
                      </Box>
                    }
                  />

                  <Box component="small" sx={{ color: '#4a4a4a' }}>
                    Cost double, but will be taken out of inventory regardless
                    if a policy is sold.
                  </Box>
                </Stack>

                {data.Amounts.length === 0 ? (
                  <Box
                    sx={{
                      p: 3,
                      textAlign: 'center',
                      backgroundColor: '#fafafa',
                      border: '1px solid #4a4a4a',
                      borderRadius: 2,
                    }}
                  >
                    <Box sx={{ fontSize: 24 }}>
                      There are no Leads available for this area
                    </Box>
                  </Box>
                ) : null}

                {Object.keys(LEAD_PRODUCT).map((Product) => {
                  const productOptions = data.Amounts.filter(
                    (i) => i.Product === Product,
                  );

                  if (productOptions.length > 0) {
                    return (
                      <Box key={Product}>
                        <Stack spacing={1}>
                          <Box
                            sx={{
                              fontWeight: 'bold',
                              fontSize: { xs: 26, md: 32 },
                              color: '#4a4a4a',
                            }}
                          >
                            {getProductTitle(Product as LEAD_PRODUCT)}
                          </Box>

                          {productOptions.map((option) => {
                            const { Pricing } = option;
                            const displayPrice = data.Exclusive
                              ? Pricing.ExlLeadPrice
                              : Pricing.LeadPrice;
                            const TotalLeads = Pricing.TotalLeads || 0;

                            let inCart = false;
                            // Are they changing the `Exclusive` value for an existing CartItem
                            let shouldDisable = false;
                            // Check to see if this Lead Type is already in the cart
                            if (cart && cart.CartItems) {
                              cart.CartItems.forEach((item) => {
                                if (purchaseFor) {
                                  const itemID = getCartItemID({ data: item });
                                  const optionID = getProductOptionID({
                                    RecAgt: purchaseFor,
                                    FIPS,
                                    FIPSRegId,
                                    option,
                                  });
                                  if (itemID === optionID) {
                                    // This Purchase Option is in their Cart
                                    inCart = true;

                                    if (data.Exclusive !== item.Exclusive) {
                                      // They cannot change the Exclusive value for Cart items
                                      shouldDisable = true;
                                    }
                                  }
                                }
                              });
                            }

                            return (
                              <Box
                                key={option.LeadType}
                                sx={{ opacity: shouldDisable ? 0.4 : 1 }}
                              >
                                <Stack
                                  spacing={1}
                                  direction="row"
                                  alignItems="center"
                                  sx={{ p: 1, py: 0.5 }}
                                >
                                  <Box
                                    sx={{
                                      px: { xs: 0, md: 1 },
                                      fontSize: { xs: 20, md: 26 },
                                      fontWeight: 'bold',
                                      fontFamily: 'Roboto Mono',
                                      color: 'teal',
                                    }}
                                  >
                                    {option.LeadType}
                                  </Box>

                                  <Stack
                                    spacing={{ xs: 0, md: 1 }}
                                    direction={{ xs: 'column', md: 'row' }}
                                    alignItems={{
                                      xs: 'flex-start',
                                      md: 'center',
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        color: '#4a4a4a',
                                        fontSize: { xs: 12, md: 14 },
                                        fontWeight: 'bold',
                                        fontFamily: 'Roboto Mono',
                                      }}
                                    >
                                      {toCurrency({ value: displayPrice })}
                                    </Box>

                                    {inCart ? (
                                      <Box
                                        sx={{
                                          fontFamily: 'Roboto Mono',
                                          fontWeight: 'bold',
                                          fontSize: 10,
                                          textTransform: 'uppercase',
                                          color: 'green',
                                        }}
                                      >
                                        in cart
                                      </Box>
                                    ) : null}
                                  </Stack>

                                  <Box
                                    sx={{
                                      flex: 1,
                                      borderTop: '1px solid #0001',
                                    }}
                                  />

                                  <Box
                                    sx={{
                                      fontSize: { xs: 12, md: 14 },
                                      fontWeight: 'bold',
                                      color: '#4a4a4a',
                                      cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                      setData((currentState) => ({
                                        ...currentState,
                                        Amounts: currentState.Amounts.map(
                                          (Amount) => {
                                            if (
                                              Amount.Product ===
                                                option.Product &&
                                              Amount.LeadType ===
                                                option.LeadType
                                            ) {
                                              // Set to max Available
                                              const Quantity = `${TotalLeads}`;
                                              return { ...Amount, Quantity };
                                            } else {
                                              return Amount;
                                            }
                                          },
                                        ),
                                      }));
                                    }}
                                  >
                                    <Stack
                                      spacing={1}
                                      direction="row"
                                      alignItems="center"
                                    >
                                      <Box sx={{ fontFamily: 'Roboto Mono' }}>
                                        {TotalLeads}
                                      </Box>
                                      <Box>Available</Box>
                                    </Stack>
                                  </Box>

                                  <IconButton
                                    disabled={
                                      Number(option.Quantity) <= 0 ||
                                      shouldDisable
                                    }
                                    size="small"
                                    onClick={() => {
                                      setData((currentState) => ({
                                        ...currentState,
                                        Amounts: currentState.Amounts.map(
                                          (Amount) => {
                                            if (
                                              Amount.Product ===
                                                option.Product &&
                                              Amount.LeadType ===
                                                option.LeadType
                                            ) {
                                              const currQty = Number(
                                                Amount.Quantity,
                                              );
                                              // Decrease Quantity by 1
                                              const Quantity = `${currQty - 1}`;
                                              return { ...Amount, Quantity };
                                            } else {
                                              return Amount;
                                            }
                                          },
                                        ),
                                      }));
                                    }}
                                  >
                                    <RemoveIcon />
                                  </IconButton>

                                  <TextField
                                    disabled={shouldDisable}
                                    size="small"
                                    type="number"
                                    value={option.Quantity}
                                    sx={{ width: 88 }}
                                    inputProps={{
                                      min: 0,
                                      max: TotalLeads,
                                      style: { fontFamily: 'Roboto Mono' },
                                    }}
                                    onChange={(event) => {
                                      setData((currentState) => ({
                                        ...currentState,
                                        Amounts: currentState.Amounts.map(
                                          (Amount) => {
                                            if (
                                              Amount.Product ===
                                                option.Product &&
                                              Amount.LeadType ===
                                                option.LeadType
                                            ) {
                                              // Set Quantity
                                              const Quantity =
                                                event.target.value;
                                              return { ...Amount, Quantity };
                                            } else {
                                              return Amount;
                                            }
                                          },
                                        ),
                                      }));
                                    }}
                                  />

                                  <IconButton
                                    disabled={
                                      Number(option.Quantity) >= TotalLeads ||
                                      shouldDisable
                                    }
                                    size="small"
                                    onClick={() => {
                                      setData((currentState) => ({
                                        ...currentState,
                                        Amounts: currentState.Amounts.map(
                                          (Amount) => {
                                            if (
                                              Amount.Product ===
                                                option.Product &&
                                              Amount.LeadType ===
                                                option.LeadType
                                            ) {
                                              const currQty = Number(
                                                Amount.Quantity,
                                              );
                                              // Increase Quantity by 1
                                              const Quantity = `${currQty + 1}`;
                                              return { ...Amount, Quantity };
                                            } else {
                                              return Amount;
                                            }
                                          },
                                        ),
                                      }));
                                    }}
                                  >
                                    <AddIcon />
                                  </IconButton>
                                </Stack>
                              </Box>
                            );
                          })}
                        </Stack>
                      </Box>
                    );
                  } else {
                    return null;
                  }
                })}
              </Stack>
            </Box>

            <Divider />

            {mutationAdd.isSuccess ? (
              <Alert severity="success" onClose={handleClose}>
                <AlertTitle>Added to Cart!</AlertTitle>
                Your selections will be saved for the next 15 minutes.
              </Alert>
            ) : null}

            <DisplayMutationError
              isError={mutationAdd.isError}
              error={mutationAdd.error}
              onClose={() => mutationAdd.reset()}
              displayMore={isAdmin}
            />

            <Button
              disabled={
                submitDisabled || mutationAdd.isPending || mutationAdd.isSuccess
              }
              fullWidth
              size="large"
              color="info"
              variant="contained"
              disableElevation
              startIcon={<AddShoppingCartIcon />}
              sx={{ minHeight: 64, borderRadius: 0 }}
              onClick={() => mutationAdd.mutate()}
            >
              Add to Cart
              {quantity && !exceedsAvailable
                ? ` - ${quantity} - ${toCurrency({ value: spend })}`
                : null}
            </Button>
          </Box>
        )}
      </Box>
    </Drawer>
  );
}
