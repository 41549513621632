import { Decimal } from 'decimal.js';

/**
 * Multiplying decimals in JavaScript can do strange things
 *
 * Multiplying these decimal values in JavaScript:
 * 9.6 * 3 = 28.799999999999997
 * 1.1 * 1.3 = 1.4300000000000002
 *
 * Using the multNums function:
 * multNums(9.6, 3) = 28.8
 * multNums(1.1, 1.3) = 1.43
 *
 */
export function multNums(num1: number, num2: number) {
  const numA = new Decimal(num1);
  const numB = new Decimal(num2);
  const result = numA.mul(numB);
  return result.toNumber();
}
