import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import { ColumnHeader, LEAD_PRODUCT } from '../../../types';
import { toCurrency } from '../../../utils/formatter';
import { getProductColor } from '../../../utils/general';
import { LeadSubData, SUB_STATUS } from '../../subscriptions/data';

const getStatusColor = (status: SUB_STATUS): React.CSSProperties['color'] => {
  switch (status) {
    case SUB_STATUS['Active']: {
      return '#00C868';
    }
    case SUB_STATUS['Cancelled']: {
      return '#b61f26';
    }
    case SUB_STATUS['Cancelling']: {
      return 'darkorange';
    }
    case SUB_STATUS['On Waitlist']: {
      return '#0094FF';
    }
    default: {
      return 'inherit';
    }
  }
};

export function SubscriptionsRow(props: {
  row: LeadSubData;
  tableColumns: ColumnHeader<LeadSubData>[];
}) {
  // Props
  const { row, tableColumns } = props;

  const isCancelled = row.Status === SUB_STATUS['Cancelled'];

  return (
    <TableRow
      hover
      sx={{
        opacity: isCancelled ? 0.6 : 1,
        whiteSpace: 'nowrap',
        ':nth-of-type(odd)': {
          backgroundColor: '#fafafa',
        },
      }}
    >
      {tableColumns.map((column) => {
        const align = column.align || 'center';
        switch (column.key) {
          case 'CreatedDate': {
            const value = row[column.key];
            return (
              <TableCell
                key={column.key}
                align={align}
                sx={{
                  borderLeftWidth: 2,
                  borderLeftStyle: 'solid',
                  borderLeftColor: getStatusColor(row.Status as SUB_STATUS),
                }}
              >
                <Box component="span" sx={{ fontFamily: 'Roboto Mono' }}>
                  {value.slice(0, 10)}
                </Box>
              </TableCell>
            );
          }
          case 'Status': {
            const value = row[column.key] as SUB_STATUS;
            return (
              <TableCell key={column.key} align={align}>
                <Box
                  component="span"
                  sx={{ fontWeight: 'bold', color: getStatusColor(value) }}
                >
                  {value}
                </Box>
              </TableCell>
            );
          }
          case 'FIPS': {
            return (
              <TableCell key={column.key} align={align}>
                <Box component="span" sx={{ fontFamily: 'Roboto Mono' }}>
                  {row[column.key]}
                </Box>
              </TableCell>
            );
          }
          case 'State': {
            return (
              <TableCell key={column.key} align={align}>
                <Box
                  component="span"
                  sx={{
                    fontFamily: 'Roboto Mono',
                    fontWeight: 'bold',
                    color: '#b26500',
                  }}
                >
                  {row[column.key]}
                </Box>
              </TableCell>
            );
          }
          case 'BillToAgt': {
            return (
              <TableCell key={column.key} align={align}>
                <Box
                  component="span"
                  sx={{
                    fontFamily: 'Roboto Mono',
                    fontWeight: 'bold',
                    color: '#b26500',
                  }}
                >
                  {row[column.key]}
                </Box>
              </TableCell>
            );
          }
          case 'RecAgt': {
            const isBillToAgt = row.RecAgt === row.BillToAgt;
            return (
              <TableCell key={column.key} align={align}>
                <Box
                  component="span"
                  sx={{
                    fontFamily: 'Roboto Mono',
                    fontWeight: 'bold',
                    color: isBillToAgt ? '#b26500' : 'purple',
                  }}
                >
                  {row[column.key]}
                </Box>
              </TableCell>
            );
          }
          case 'Product': {
            const value = row[column.key] as LEAD_PRODUCT;
            return (
              <TableCell key={column.key} align={align}>
                <Box
                  component="span"
                  sx={{
                    fontFamily: 'Roboto Mono',
                    fontWeight: 'bold',
                    color: getProductColor(value),
                  }}
                >
                  {value}
                </Box>
              </TableCell>
            );
          }
          case 'LeadType': {
            return (
              <TableCell key={column.key} align={align}>
                <Stack spacing={1} direction="row" alignItems="center">
                  <Box
                    component="span"
                    sx={{
                      fontFamily: 'Roboto Mono',
                      fontWeight: 'bold',
                      color: 'teal',
                    }}
                  >
                    {row[column.key]}
                  </Box>

                  {row.Exclusive ? (
                    <Tooltip title="Exclusive" placement="right" arrow>
                      <Box
                        component="span"
                        sx={{
                          textTransform: 'uppercase',
                          fontFamily: 'Roboto Mono',
                          fontWeight: 'bold',
                          color: 'darkorange',
                        }}
                      >
                        Exc
                      </Box>
                    </Tooltip>
                  ) : null}
                </Stack>
              </TableCell>
            );
          }
          case 'LeadPrice': {
            const value = row[column.key];
            return (
              <TableCell key={column.key} align={align}>
                <Box component="span" sx={{ fontFamily: 'Roboto Mono' }}>
                  {toCurrency({ value })}
                </Box>
              </TableCell>
            );
          }
          case 'WklyInv': {
            const value = row[column.key];
            return (
              <TableCell key={column.key} align={align}>
                <Box
                  component="span"
                  sx={{
                    fontFamily: 'Roboto Mono',
                    fontWeight: 'bold',
                  }}
                >
                  {toCurrency({ value })}
                </Box>
              </TableCell>
            );
          }
          default: {
            const value = row[column.key];
            return (
              <TableCell key={column.key} align={align}>
                {Array.isArray(value) ? null : value}
              </TableCell>
            );
          }
        }
      })}
    </TableRow>
  );
}
