import ChecklistIcon from '@mui/icons-material/Checklist';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import React from 'react';
import { Link } from 'react-router-dom';
import { RouteContainer } from '../../components/route-container';
import { AuthContext } from '../../contexts/auth-context';
import { LeadData } from '../../types';
import { isAgt } from '../../utils/is-agent';
import { Announcements } from './announcements';
import { NewLeads } from './new-leads';
import { Notifications } from './notifications';

export function Home() {
  // Context
  const {
    state: { impersonatedAgent, user },
  } = React.useContext(AuthContext);

  // Query - Leads
  const pathLeads = '/leads/distribution';
  const AgtNo = impersonatedAgent?.AgtNo || user?.getUsername();
  const query = useQuery({
    queryKey: [pathLeads, { AgtNo }],
    queryFn: async () => {
      if (isAgt(AgtNo)) {
        const response: {
          data: LeadData[];
        } = await API.post('LeadsAPI', pathLeads, { body: { AgtNo } });

        return response.data || [];
      } else {
        return [];
      }
    },
  });

  return (
    <RouteContainer routeTitle="Home" loading={query.isLoading}>
      <Container maxWidth="md">
        <Box sx={{ pt: 2, pb: 16 }}>
          <Stack spacing={2}>
            <Box>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <Paper
                    elevation={0}
                    component={Link}
                    to="/map"
                    sx={{
                      textDecorationLine: 'none',
                      display: 'block',
                      overflow: 'hidden',
                      borderWidth: 1,
                      borderStyle: 'solid',
                      borderColor: '#eaeaea',
                      backgroundColor: '#fcfcfc',
                      transition: 'all 0.2s',
                      ':hover': {
                        transform: 'scale(1.01)',
                        borderColor: '#aeaeae',
                        backgroundColor: '#fff',
                      },
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Box sx={{ p: 1 }}>
                        <TravelExploreIcon fontSize="large" />
                      </Box>
                      <Box>
                        <Box sx={{ fontWeight: 'bold' }}>
                          Purchase New Leads
                        </Box>
                        <Box component="small">
                          Find Leads to start working today
                        </Box>
                      </Box>
                    </Box>
                  </Paper>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Paper
                    elevation={0}
                    component={Link}
                    to="/subscriptions"
                    sx={{
                      textDecorationLine: 'none',
                      display: 'block',
                      overflow: 'hidden',
                      borderWidth: 1,
                      borderStyle: 'solid',
                      borderColor: '#eaeaea',
                      backgroundColor: '#fcfcfc',
                      transition: 'all 0.2s',
                      ':hover': {
                        transform: 'scale(1.01)',
                        borderColor: '#aeaeae',
                        backgroundColor: '#fff',
                      },
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Box sx={{ p: 1 }}>
                        <DynamicFeedIcon fontSize="large" />
                      </Box>
                      <Box>
                        <Box sx={{ fontWeight: 'bold' }}>
                          Setup Lead Subscriptions
                        </Box>
                        <Box component="small">
                          Generate Leads for a specific area
                        </Box>
                      </Box>
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            </Box>

            <Box sx={{ px: 1, display: 'flex' }}>
              <Box sx={{ transform: 'rotate(-9deg)', pr: 2 }}>
                <ChecklistIcon fontSize="large" />
              </Box>

              <Typography
                variant="h4"
                component="div"
                sx={{ fontSize: { xs: 28, md: 36 } }}
              >
                Your Leads Dashboard
              </Typography>
            </Box>

            <NewLeads data={query.data || []} />

            <Notifications />

            <Announcements />
          </Stack>
        </Box>
      </Container>
    </RouteContainer>
  );
}
