import CloseIcon from '@mui/icons-material/Close';
import PhoneIcon from '@mui/icons-material/Phone';
import RoomIcon from '@mui/icons-material/Room';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import LinearProgress from '@mui/material/LinearProgress';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import {
  GoogleMap,
  LoadScriptProps,
  Marker,
  useJsApiLoader,
} from '@react-google-maps/api';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import Bowser from 'bowser';
import { format } from 'date-fns/format';
import React from 'react';
import pinRed from '../assets/maps-pin-red.svg';
import { AuthContext } from '../contexts/auth-context';
import { leadStatuses } from '../routes/leads/data';
import { LeadData } from '../types';
import { captureError } from '../utils/capture-error';
import { formatDate } from '../utils/formatter';
import { getProductColor, getProductTitle } from '../utils/general';
import { DisplayValue } from './display-value';
import { RouteError } from './route-container/route-error';

const containerStyle = { width: '100%', height: 256 };
const libraries: LoadScriptProps['libraries'] = ['visualization'];

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(
  function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} {...props} />;
  },
);

const DocIframe = React.memo(function DocIframe(props: { data: any }) {
  // State
  const [loading, setLoading] = React.useState(true);

  return (
    <React.Fragment>
      {loading ? <LinearProgress /> : null}

      <iframe
        title="Lead Image"
        src={window.URL.createObjectURL(
          new Blob([props.data], { type: 'application/pdf' }),
        )}
        style={{ flex: 1, width: '100%', border: 'none' }}
        onLoad={() => setLoading(false)}
      />
    </React.Fragment>
  );
});

const isAndroidDevice = (): boolean => {
  const browser = Bowser.getParser(window.navigator.userAgent);
  const osName = browser.getOSName();
  return osName === 'Android';
};

export function LeadItem(props: {
  item: LeadData | undefined;
  onClose: () => void;
}) {
  // Props
  const { item } = props;
  // Context
  const {
    state: { impersonatedAgent, user },
  } = React.useContext(AuthContext);
  // State
  const [status, setStatus] = React.useState('Open');
  const [open, setOpen] = React.useState(false);
  const [tab, setTab] = React.useState<'info' | 'image'>('image');

  const isAndroid = isAndroidDevice();

  React.useEffect(() => {
    if (item) {
      setStatus(item.ContactStatus);

      if (isAndroid) {
        // Android Mobile browsers do not natively render PDF's in iframes
        // So default to the 'info' tab and let the user download the image
        // from the 'image' tab if they want to view it on mobile
        setTab('info');
      } else if (item.DistHistID && item.DistFilePath) {
        setTab('image');
      } else {
        setTab('info');
      }
    } else {
      setStatus('');
    }
  }, [item, isAndroid]);

  const AgtNo = impersonatedAgent?.AgtNo || user?.getUsername();

  // Query - Source file for Lead
  const path = '/leads/distribution/image';
  const DistHistID = item?.DistHistID;
  const query = useQuery({
    enabled: Boolean(
      item !== undefined &&
        item.DistHistID !== null &&
        item.DistFilePath !== null,
    ),
    queryKey: [path, { AgtNo, DistHistID }],
    queryFn: async () => {
      const response = await API.post('LeadsAPI', path, {
        responseType: 'arraybuffer',
        body: { AgtNo, DistHistID },
      });

      if (response) {
        return response;
      }

      return null;
    },
  });

  const queryClient = useQueryClient();
  // Mutation - Update Lead Status
  const mutation = useMutation({
    mutationFn: async () => {
      await API.post('LeadsAPI', '/leads/distribution/contact-status/update', {
        body: { DistHistID: item?.DistHistID, ContactStatus: status, AgtNo },
      });
      setOpen(true);
    },
    onSuccess: async () => {
      // Refetch leads for user
      await queryClient.invalidateQueries({
        queryKey: ['/leads/distribution', { AgtNo }],
      });
    },
    onError: (error) => captureError({ data: { error } }),
  });

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY || '',
    libraries,
  });

  const handleCloseAlert = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleClose = async () => {
    if (!mutation.isPending) {
      props.onClose();
      setTab('info');
      setOpen(false);
      mutation.reset();
    }
  };

  // If coordinates are not found for an address they API will default to 0,0
  const hasCoords =
    item &&
    item.Latitude &&
    item.Longitude &&
    Number(item.Latitude) !== 0 &&
    Number(item.Longitude) !== 0;

  // Googls Maps URL with address query
  const mapsURL = `https://maps.google.com/?q=${item?.Address1} ${
    item?.Address2 || ''
  }, ${item?.City}, ${item?.St}, ${item?.Zip}`;

  return (
    <Drawer
      container={window.document.body}
      variant="temporary"
      anchor="right"
      open={props.item !== undefined}
      onClose={handleClose}
      sx={{
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: { xs: '100vw', md: '60vw', lg: '50vw', xl: '40vw' },
          height: '100dvh',
        },
      }}
    >
      <Box sx={{ height: '100dvh', display: 'flex', flexDirection: 'column' }}>
        <Toolbar>
          <IconButton
            disabled={mutation.isPending}
            sx={{ mr: 2 }}
            onClick={handleClose}
          >
            {mutation.isPending ? (
              <CircularProgress size={24} />
            ) : (
              <CloseIcon />
            )}
          </IconButton>

          <Stack
            spacing={1}
            direction="row"
            alignItems="center"
            sx={{ flex: 1 }}
          >
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ fontSize: { xs: 16, md: 20, lg: 24 } }}
            >
              {item?.FirstName} {item?.LastName}
            </Typography>

            <Box sx={{ flex: 1 }} />

            <Stack alignItems="flex-end">
              <Stack
                spacing={1}
                direction="row"
                alignItems="center"
                sx={{ fontSize: { xs: 14, sm: 18 } }}
              >
                <Box
                  sx={{
                    fontFamily: 'Roboto Mono',
                    fontWeight: 'bold',
                    color: 'teal',
                  }}
                >
                  {item?.LeadType}
                </Box>

                <Box
                  sx={{
                    height: 4,
                    width: 4,
                    borderRadius: 1,
                    backgroundColor: '#7a7a7a',
                  }}
                />

                <Box
                  sx={{
                    fontWeight: 'bold',
                    color: item?.Product
                      ? getProductColor(item.Product)
                      : 'inherit',
                  }}
                >
                  <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                    {item?.Product ? getProductTitle(item.Product) : ''}
                  </Box>

                  <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                    {item?.Product}
                  </Box>
                </Box>
              </Stack>

              {item?.AgtExport ? (
                <Box
                  sx={{
                    fontSize: 10,
                    fontWeight: 'bold',
                    fontFamily: 'Roboto Mono',
                    textTransform: 'uppercase',
                    color: '#2196f3',
                  }}
                >
                  Exported to PDF
                </Box>
              ) : null}

              {item?.Exclusive ? (
                <Box
                  component="small"
                  sx={{
                    textTransform: 'uppercase',
                    fontWeight: 'bold',
                    color: 'darkorange',
                    fontSize: 10,
                  }}
                >
                  Exclusive
                </Box>
              ) : null}
            </Stack>
          </Stack>
        </Toolbar>

        <Divider />

        <Box sx={{ p: 1 }}>
          <FormControl disabled={mutation.isPending} fullWidth size="small">
            <InputLabel id="lead-status-select-label">Lead Status</InputLabel>
            <Select
              labelId="lead-status-select-label"
              id="lead-status-select"
              value={status}
              label="Lead Status"
              onChange={(event: SelectChangeEvent) => {
                setStatus(event.target.value as string);
                mutation.mutate();
              }}
            >
              {leadStatuses.map((ls) => {
                return (
                  <MenuItem key={ls.status} value={ls.status}>
                    <Stack spacing={1} direction="row" alignItems="center">
                      <Box>
                        <Box
                          sx={{
                            backgroundColor: ls.color,
                            borderRadius: 2,
                            height: 28,
                            width: 6,
                          }}
                        />
                      </Box>
                      <Box sx={{ fontSize: 16 }}>{ls.status}</Box>
                      <Box sx={{ flex: 1 }} />
                      {mutation.isPending ? (
                        <Box
                          sx={{
                            pr: 1,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <CircularProgress size={22} color="inherit" />
                        </Box>
                      ) : null}
                    </Stack>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={open}
            autoHideDuration={4000}
            onClose={handleCloseAlert}
          >
            <Alert
              onClose={handleCloseAlert}
              severity="success"
              sx={{ width: '100%' }}
            >
              Status updated for {item?.FirstName} {item?.LastName}!
            </Alert>
          </Snackbar>
        </Box>

        <Divider />

        {props.item ? (
          <Box
            sx={{
              minHeight: 0,
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Tabs
              variant="fullWidth"
              indicatorColor="secondary"
              value={tab}
              onChange={(even, value) => {
                setTab(value);
              }}
            >
              {item?.DistHistID && item.DistFilePath ? (
                <Tab label="Lead Image" value="image" />
              ) : null}
              <Tab label="Lead Info" value="info" />
            </Tabs>

            <Divider />

            {tab === 'info' ? (
              <Box
                sx={{
                  minHeight: 0,
                  px: { xs: 1, md: 2 },
                  pt: 2,
                  pb: 16,
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  overflow: 'auto',
                }}
              >
                <Stack spacing={1}>
                  <Paper
                    elevation={0}
                    sx={{ border: '1px solid #00000022', overflow: 'hidden' }}
                  >
                    <Stack direction="row" alignItems="center" sx={{ p: 1 }}>
                      <Box sx={{ flex: 1 }}>
                        <DisplayValue
                          label="Lead Purchase"
                          value={
                            item?.RecDate
                              ? format(
                                  new Date(formatDate({ value: item.RecDate })),
                                  'EEE, LLL do, yyyy',
                                )
                              : null
                          }
                        />
                      </Box>
                      <Box sx={{ flex: 1 }}>
                        <DisplayValue
                          label="Lead Expiration"
                          value={
                            item?.ExpireDate
                              ? format(
                                  new Date(
                                    formatDate({ value: item.ExpireDate }),
                                  ),
                                  'EEE, LLL do, yyyy',
                                )
                              : null
                          }
                        />
                      </Box>
                    </Stack>
                  </Paper>

                  <Paper
                    elevation={0}
                    sx={{ border: '1px solid #00000022', overflow: 'hidden' }}
                  >
                    <Stack direction="row" alignItems="center" sx={{ p: 1 }}>
                      <Box sx={{ flex: 1 }}>
                        <DisplayValue
                          label="Distribution Source"
                          value={item?.DistSrc ? item.DistSrc : 'Unknown'}
                        />
                      </Box>
                    </Stack>
                  </Paper>

                  {hasCoords ? (
                    <Paper
                      elevation={0}
                      sx={{ border: '1px solid #00000022', overflow: 'hidden' }}
                    >
                      {isLoaded && item && item.Latitude && item.Longitude ? (
                        <GoogleMap
                          options={{ streetViewControl: false }}
                          mapContainerStyle={containerStyle}
                          center={{
                            lat: Number(item.Latitude),
                            lng: Number(item.Longitude),
                          }}
                          zoom={15}
                        >
                          <Marker
                            icon={pinRed}
                            position={{
                              lat: Number(item.Latitude),
                              lng: Number(item.Longitude),
                            }}
                          />
                        </GoogleMap>
                      ) : (
                        <React.Fragment />
                      )}
                    </Paper>
                  ) : null}

                  <Box
                    component="a"
                    href={mapsURL}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      padding: 1,
                      display: 'flex',
                      alignItems: 'center',
                      color: '#4a4a4a',
                      textDecorationLine: 'none',
                      border: '1px solid #eee',
                      borderRadius: 1,
                      transition: 'all 0.2s',
                      '&:hover': {
                        color: '#2a2a2a',
                        border: '1px solid #2979ff',
                        backgroundColor: '#2979ff11',
                      },
                    }}
                  >
                    <RoomIcon />

                    <strong style={{ marginLeft: 8 }}>
                      Open in Google Maps
                    </strong>
                  </Box>

                  <Paper
                    elevation={0}
                    sx={{ border: '1px solid #00000022', overflow: 'hidden' }}
                  >
                    <Box sx={{ p: 1 }}>
                      <DisplayValue
                        label="Address"
                        value={
                          <Box sx={{ fontSize: 18 }}>
                            <Box>{item?.Address1}</Box>
                            {item?.Address2 ? (
                              <Box>{item?.Address2}</Box>
                            ) : null}
                            <Box>
                              {item?.City}, {item?.St} {item?.Zip}
                            </Box>
                          </Box>
                        }
                      />
                    </Box>
                  </Paper>

                  {item &&
                  (item.MortgageAmount || item.MortgageDate || item.Lender) ? (
                    <Paper
                      elevation={0}
                      sx={{ border: '1px solid #00000022', overflow: 'hidden' }}
                    >
                      <Stack spacing={1} sx={{ p: 1 }}>
                        <DisplayValue
                          label="Mortgage Date"
                          value={
                            item?.MortgageDate
                              ? format(
                                  new Date(
                                    formatDate({ value: item.MortgageDate }),
                                  ),
                                  'EEE, LLL do, yyyy',
                                )
                              : ''
                          }
                        />
                        <DisplayValue
                          monospace
                          label="Mortgage Amount"
                          value={item?.MortgageAmount}
                        />
                        <DisplayValue
                          label="Lender"
                          value={item?.Lender || ''}
                        />
                      </Stack>
                    </Paper>
                  ) : null}

                  <Paper
                    elevation={0}
                    sx={{
                      border: '1px solid #00000022',
                      overflow: 'hidden',
                      display: 'flex',
                      alignItems: 'stretch',
                    }}
                  >
                    <Box sx={{ flex: 1, borderRight: '1px solid #00000022' }}>
                      <Stack spacing={1} sx={{ p: 1 }}>
                        <Stack spacing={1} direction="row" alignItems="center">
                          <Box sx={{ fontSize: 22, fontWeight: 'bold' }}>
                            {item?.FirstName} {item?.LastName}
                          </Box>
                        </Stack>

                        {item?.Age ? (
                          <DisplayValue label="Age" value={item.Age} />
                        ) : null}

                        {item?.DOB ? (
                          <DisplayValue
                            label="Birth Date"
                            value={
                              item?.DOB
                                ? format(
                                    new Date(formatDate({ value: item.DOB })),
                                    'PPPP',
                                  )
                                : null
                            }
                          />
                        ) : null}

                        {item?.Phone ? (
                          <DisplayValue
                            label="Phone Number"
                            value={item.Phone || null}
                          />
                        ) : null}

                        {item?.Email ? (
                          <DisplayValue
                            label="Email Address"
                            value={
                              <Box
                                component="a"
                                href={`mailto:${item.Email}`}
                                sx={{ fontSize: 18 }}
                              >
                                {item.Email}
                              </Box>
                            }
                          />
                        ) : null}
                      </Stack>
                    </Box>

                    {item?.Phone ? (
                      <Tooltip
                        title={`Call ${item?.FirstName}`}
                        placement="left"
                        arrow
                      >
                        <Button
                          component="a"
                          href={`tel:${item.Phone}`}
                          color="info"
                          sx={{ height: '100%', borderRadius: 0 }}
                        >
                          <PhoneIcon />
                        </Button>
                      </Tooltip>
                    ) : null}
                  </Paper>

                  {item?.SpouseName ? (
                    <Paper
                      elevation={0}
                      sx={{
                        border: '1px solid #00000022',
                        overflow: 'hidden',
                        display: 'flex',
                        alignItems: 'stretch',
                      }}
                    >
                      <Box sx={{ flex: 1, borderRight: '1px solid #00000022' }}>
                        <Stack spacing={1} sx={{ p: 1 }}>
                          <Stack
                            spacing={1}
                            direction="row"
                            alignItems="center"
                          >
                            <Box sx={{ fontSize: 22, fontWeight: 'bold' }}>
                              {item?.SpouseName}
                            </Box>
                            <Box
                              sx={{
                                fontSize: 12,
                                fontWeight: 'bold',
                                textTransform: 'uppercase',
                                color: '#7a7a7a',
                              }}
                            >
                              Spouse
                            </Box>
                          </Stack>

                          {item?.SpouseAge ? (
                            <DisplayValue label="Age" value={item.SpouseAge} />
                          ) : null}
                        </Stack>
                      </Box>
                    </Paper>
                  ) : null}
                </Stack>
              </Box>
            ) : null}

            {query.isFetching ? (
              <LinearProgress />
            ) : query.isError ? (
              <RouteError />
            ) : tab === 'image' && query.data ? (
              <DocIframe data={query.data} />
            ) : null}
          </Box>
        ) : (
          <React.Fragment />
        )}
      </Box>
    </Drawer>
  );
}
