import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import React from 'react';
import { AuthContext } from '../../contexts/auth-context';
import { captureError } from '../../utils/capture-error';
import { LeadSubData } from './data';

export function UpdateFillFactor(props: {
  item: LeadSubData | undefined;
  onClose: () => void;
}) {
  // Props
  const { item } = props;
  // Context
  const {
    state: { impersonatedAgent, user },
  } = React.useContext(AuthContext);
  // State
  const [fillFactor, setFillFactor] = React.useState<any>(
    item?.BkndFillFactor || 1,
  );
  const AgtNo = impersonatedAgent?.AgtNo || user?.getUsername();

  const queryClient = useQueryClient();
  // Mutation - Update Subscription Fill Factor
  const mutation = useMutation({
    mutationFn: async () => {
      await API.post(
        'LeadsAPI',
        '/admin/leads/subscriptions/update-fill-factor',
        {
          body: { LeadSubId: item?.LeadSubId, BkndFillFactor: fillFactor },
        },
      );
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['/leads/subscriptions', { AgtNo }],
      });
      await queryClient.invalidateQueries({
        queryKey: ['/admin/leads/subscriptions', { FIPS: item?.FIPS }],
      });
    },
    onError: (error: any) => {
      captureError({ data: { error } });
    },
  });

  return (
    <Paper
      elevation={0}
      sx={{ border: '1px solid #00000022', overflow: 'hidden', p: 1 }}
    >
      <InputLabel
        sx={{
          textTransform: 'uppercase',
          fontSize: 10,
          fontWeight: 'bold',
          color: '#9b9b9b',
        }}
      >
        Fill Priority
      </InputLabel>
      <Stack direction="row" spacing={2}>
        <FormControl disabled={mutation.isPending} size="small">
          <Select
            value={fillFactor}
            sx={{ fontWeight: 'bold' }}
            onChange={(event: SelectChangeEvent) => {
              setFillFactor(event.target.value);
            }}
          >
            {[1, 1.1, 1.2, 1.3, 1.4, 1.5, 1.6, 1.7, 1.8, 1.9, 2].map((i) => {
              return (
                <MenuItem key={i} value={i} sx={{ fontWeight: 'bold' }}>
                  {i}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        {item?.BkndFillFactor !== fillFactor && (
          <Stack direction="row" spacing={2}>
            <Button
              variant="outlined"
              size="small"
              onClick={() => setFillFactor(item?.BkndFillFactor)}
            >
              Reset
            </Button>
            <Button
              color="info"
              variant="contained"
              size="small"
              disabled={mutation.isPending}
              onClick={() => mutation.mutate()}
            >
              {mutation.isPending ? 'Saving...' : 'Save'}
            </Button>
            {mutation.isError && (
              <Alert
                sx={{ py: 0 }}
                severity="error"
                onClose={() => mutation.reset()}
              >
                An error has occurred!
              </Alert>
            )}
          </Stack>
        )}
      </Stack>
    </Paper>
  );
}
