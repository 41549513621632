import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Tooltip from '@mui/material/Tooltip';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { useQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import React from 'react';
import { RouteContainer } from '../../components/route-container';
import { AuthContext } from '../../contexts/auth-context';
import { CartContext } from '../../contexts/cart-context';
import geoCounties from '../../data/gz_2010_us_050_00_20m.json';
import { FeaturePropertiesData, LEAD_PRODUCT } from '../../types';
import { captureError } from '../../utils/capture-error';
import { getProductTitle } from '../../utils/general';
import { isAgt } from '../../utils/is-agent';
import {
  defaultStyle,
  discountStyle,
  getCounty,
  hoverStyle,
  libraries,
  premiumStyle,
  selectedStyle,
  stateAbbrs,
  stateNames,
} from '../../utils/map';
import { stripDiacritics } from '../../utils/strip-diacritics';
import { CountyListItem } from './county-list-item';
import { LeadMultiplierData, LeadSubData } from './data';
import { MySubscriptions } from './my-subscriptions';

// @ts-ignore
const searchOptions = geoCounties.features.map((i) => {
  return i.properties;
}) as FeaturePropertiesData[];

export function Subscriptions() {
  // Context
  const {
    state: { impersonatedAgent, user },
  } = React.useContext(AuthContext);
  const {
    state: { subsMap },
    dispatch,
  } = React.useContext(CartContext);
  // Ref
  const mapRef = React.useRef<google.maps.Map>();
  // State
  const [filters, setFilters] = React.useState<string[]>([]);
  const [center, setCenter] = React.useState({
    lat: 36.07813,
    lng: -79.432279,
  });
  const [loading, setLoading] = React.useState(true);
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');

  const AgtNo = impersonatedAgent?.AgtNo || user?.getUsername();

  // Get user's location
  React.useEffect(() => {
    const getCurrentPosition = () => {
      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          (successCallback) => {
            resolve(successCallback);
          },
          (errorCallback) => {
            reject(errorCallback);
          },
        );
      });
    };
    const fetchData = async () => {
      try {
        setLoading(true);
        // Alliance Convention Center
        const defaultCoordinates: GeolocationCoordinates = {
          accuracy: 10,
          altitude: null,
          altitudeAccuracy: null,
          heading: null,
          latitude: 36.07813,
          longitude: -79.432279,
          speed: null,
        };

        if ('geolocation' in navigator) {
          // Coordinates
          try {
            const position =
              (await getCurrentPosition()) as GeolocationPosition;
            setCenter({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
          } catch (error) {
            // Permission was denied
            setCenter({
              lat: defaultCoordinates.latitude,
              lng: defaultCoordinates.longitude,
            });
          }
        } else {
          /* geolocation IS NOT available */
          setCenter({
            lat: defaultCoordinates.latitude,
            lng: defaultCoordinates.longitude,
          });
        }
      } catch (error) {
        captureError({ data: { error } });
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  // Query - Multipliers
  const path = '/leads/multipliers';
  const queryMult = useQuery({
    queryKey: [path],
    queryFn: async () => {
      const response: {
        data: LeadMultiplierData[];
      } = await API.post('LeadsAPI', path, {});

      return response.data || [];
    },
  });

  React.useEffect(() => {
    if (mapRef.current) {
      mapRef.current.data.forEach((feature) => {
        const GEO_ID = feature.getProperty('GEO_ID') as string;
        const addedCounty = subsMap.find(
          (i) => i.getProperty('GEO_ID') === GEO_ID,
        );
        if (Boolean(addedCounty)) {
          feature.setProperty('isSelected', true);
        } else {
          feature.setProperty('isSelected', false);
        }
      });
    }
  }, [mapRef, subsMap]);

  React.useEffect(() => {
    if (mapRef.current) {
      mapRef.current.data.setStyle((feature) => {
        const STATE = feature.getProperty('STATE') as string | undefined;
        const stateName = STATE ? stateNames[STATE] : '';
        const stateAbbr = stateAbbrs[stateName];
        const COUNTY = feature.getProperty('COUNTY') as string | undefined;
        const FIPS = `${stateAbbr}${COUNTY}`;
        const CountyMult = queryMult.data?.find((i) => i.FIPS === FIPS) || null;
        if (feature.getProperty('isSelected')) {
          return selectedStyle;
        } else if (filters.includes(LEAD_PRODUCT['FEX'])) {
          const Multiplier = CountyMult?.MltFEX || 1;
          if (Multiplier > 1) {
            return { ...premiumStyle, fillOpacity: Multiplier / 3 };
          } else if (Multiplier < 1) {
            return { ...discountStyle, fillOpacity: 1 - Multiplier };
          }
        } else if (filters.includes(LEAD_PRODUCT['MP'])) {
          const Multiplier = CountyMult?.MltMP || 1;
          if (Multiplier > 1) {
            return { ...premiumStyle, fillOpacity: Multiplier / 3 };
          } else if (Multiplier < 1) {
            return { ...discountStyle, fillOpacity: 1 - Multiplier };
          }
        }

        return defaultStyle;
      });
    }
  }, [mapRef, filters, queryMult.data]);

  // Query - Subscriptions
  const pathSub = '/leads/subscriptions';
  const query = useQuery({
    queryKey: [pathSub, { AgtNo }],
    queryFn: async () => {
      if (isAgt(AgtNo)) {
        const response: {
          data: LeadSubData[];
        } = await API.post('LeadsAPI', pathSub, { body: { AgtNo } });

        return response.data;
      }

      return [];
    },
  });

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY || '',
    libraries,
  });

  // Don't render all 3000+ Counties in the Autocomplete component until
  // after the user has started typing
  let filteredOptions: FeaturePropertiesData[] = [];
  if (inputValue.trim().length >= 2) {
    filteredOptions = searchOptions.filter((i) =>
      stripDiacritics(`${i.NAME} ${i.LSAD}`)
        .toLowerCase()
        .includes(inputValue.toLowerCase()),
    );
  }

  return (
    <RouteContainer routeTitle="Lead Subscriptions" loading={query.isLoading}>
      <Container maxWidth="lg">
        <Box sx={{ pt: 2, pb: 16 }}>
          <Stack spacing={2}>
            <Paper
              elevation={0}
              sx={{ border: '1px solid #00000022', overflow: 'hidden' }}
            >
              <Stack
                spacing={1}
                direction="row"
                alignItems="center"
                sx={{ p: 1 }}
              >
                <Tooltip title="Counties selected" placement="right" arrow>
                  <Box
                    sx={{
                      mr: 1,
                      height: 28,
                      width: 28,
                      borderRadius: 3,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor:
                        subsMap.length === 0 ? '#2196f388' : '#2196f3',
                      fontWeight: 'bold',
                      fontSize: 16,
                      color: '#fff',
                    }}
                  >
                    {subsMap.length}
                  </Box>
                </Tooltip>

                <Box sx={{ flex: 1 }}>
                  <Autocomplete
                    disabled={loading}
                    fullWidth
                    blurOnSelect
                    clearOnBlur
                    size="small"
                    noOptionsText="Start typing for options..."
                    options={filteredOptions}
                    getOptionLabel={(option) => `${option.NAME}`}
                    getOptionKey={(option) => `${option.GEO_ID}`}
                    filterOptions={createFilterOptions({
                      stringify: (option) => `${option.NAME} ${option.LSAD}`,
                    })}
                    renderOption={(props, option) => {
                      if (option.STATE) {
                        const StateName = stateNames[option.STATE];
                        const StateAbbr = stateAbbrs[StateName];
                        return (
                          <Box component="li" {...props}>
                            <Stack
                              spacing={1}
                              direction="row"
                              alignItems="center"
                            >
                              <Box
                                sx={{
                                  color: '#b26500',
                                  fontSize: 14,
                                  fontWeight: 'bold',
                                  fontFamily: 'Roboto Mono',
                                }}
                              >
                                {StateAbbr}
                              </Box>
                              <Box>
                                {option.NAME} {option.LSAD}
                              </Box>
                            </Stack>
                          </Box>
                        );
                      } else {
                        return null;
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Search for County"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                        InputProps={{
                          ...params.InputProps,
                          type: 'search',
                        }}
                      />
                    )}
                    value={value}
                    onChange={(event, value, reason) => {
                      if (reason === 'selectOption' && value) {
                        if (mapRef.current) {
                          mapRef.current.data.forEach((feature) => {
                            const GEO_ID = feature.getProperty(
                              'GEO_ID',
                            ) as string;
                            if (GEO_ID === value.GEO_ID) {
                              // Add this county as a selected county on the map
                              dispatch({
                                type: 'ADD_FEAT_SUBS',
                                payload: feature,
                              });
                              // Recent the map on this county
                              const county = getCounty(feature);
                              if (county) {
                                setCenter({ lat: county.lat, lng: county.lng });
                              }
                            }
                          });
                        }
                        // Reset search component
                        setValue(null);
                        setInputValue('');
                      }
                    }}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                      setInputValue(newInputValue);
                    }}
                  />
                </Box>

                <ToggleButtonGroup
                  color="info"
                  size="small"
                  exclusive
                  value={filters}
                  onChange={(event, value) => {
                    if (value) {
                      setFilters(value);
                    } else {
                      setFilters([]);
                    }
                  }}
                >
                  {[LEAD_PRODUCT['FEX'], LEAD_PRODUCT['MP']].map((option) => {
                    const isSelected = filters.includes(option);

                    return (
                      <ToggleButton
                        key={option}
                        value={option}
                        sx={{ py: 0.4 }}
                      >
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Box
                            sx={{
                              display: { xs: 'none', md: 'flex' },
                              alignItems: 'center',
                              mr: 0.6,
                            }}
                          >
                            {isSelected ? (
                              <HighlightOffIcon fontSize="small" />
                            ) : (
                              <AddCircleOutlineIcon fontSize="small" />
                            )}
                          </Box>
                          <Box sx={{ display: { xs: 'inherit', lg: 'none' } }}>
                            {option}
                          </Box>
                          <Box
                            sx={{
                              display: { xs: 'none', lg: 'inherit' },
                              textWrap: 'nowrap',
                            }}
                          >
                            {getProductTitle(option)}
                          </Box>
                        </Box>
                      </ToggleButton>
                    );
                  })}
                </ToggleButtonGroup>

                <Button
                  disabled={subsMap.length === 0}
                  size="small"
                  variant="outlined"
                  startIcon={<RestartAltIcon />}
                  onClick={() => {
                    dispatch({ type: 'RESET_SUBS' });
                  }}
                >
                  Reset
                </Button>
              </Stack>

              <Divider />

              {isLoaded && !loading ? (
                <Box sx={{ height: { xs: 256, md: 384 } }}>
                  <GoogleMap
                    options={{ streetViewControl: false }}
                    mapContainerStyle={{ width: '100%', height: '100%' }}
                    center={center}
                    zoom={7}
                    onLoad={(map) => {
                      mapRef.current = map;

                      const geoJsonData = geoCounties;
                      map.data.addGeoJson(geoJsonData);
                      map.data.setStyle(defaultStyle);
                      map.data.forEach((feature) => {
                        const GEO_ID = feature.getProperty('GEO_ID') as string;
                        const addedCounty = subsMap.find(
                          (i) => i.getProperty('GEO_ID') === GEO_ID,
                        );
                        if (Boolean(addedCounty)) {
                          feature.setProperty('isSelected', true);
                        } else {
                          feature.setProperty('isSelected', false);
                        }
                      });

                      // Change the color when the isSelected property is set to true.
                      map.data.setStyle((feature) => {
                        if (feature.getProperty('isSelected')) {
                          return selectedStyle;
                        } else {
                          return defaultStyle;
                        }
                      });

                      // When the user clicks, set 'isSelected', changing the color of the feature
                      map.data.addListener('click', (event: any) => {
                        const feature =
                          event.feature as google.maps.Data.Feature;
                        if (feature.getProperty('isSelected')) {
                          const GEO_ID = feature.getProperty(
                            'GEO_ID',
                          ) as string;
                          // Remove feature from map
                          dispatch({
                            type: 'REMOVE_FEAT_SUBS',
                            payload: { GEO_ID },
                          });
                          // Remove any subscriptions from cart
                          const county = getCounty(feature);
                          dispatch({
                            type: 'CART_SUB_REMOVE_COUNTY',
                            payload: county?.FIPS || '',
                          });
                        } else {
                          dispatch({ type: 'ADD_FEAT_SUBS', payload: feature });
                        }
                      });

                      // Call revertStyle() to remove all overrides. This will use the style rules
                      // defined in the function passed to setStyle()
                      map.data.addListener('mouseover', (event: any) => {
                        map.data.revertStyle();
                        map.data.overrideStyle(event.feature, hoverStyle);
                      });

                      map.data.addListener('mouseout', (event: any) => {
                        map.data.revertStyle();
                      });
                    }}
                  ></GoogleMap>
                </Box>
              ) : (
                <Box
                  sx={{
                    height: { xs: 256, md: 384 },
                    backgroundColor: '#E6E3E0',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <CircularProgress color="info" size={64} />
                </Box>
              )}

              {subsMap.length > 0 ? (
                <Box>
                  <Box sx={{ p: 1 }}>
                    <Stack spacing={1}>
                      {subsMap.map((feature, index) => {
                        const GEO_ID = feature.getProperty('GEO_ID') as string;
                        const county = getCounty(feature);
                        const leadMult = queryMult.data?.find(
                          (i) => i.FIPS === county?.FIPS,
                        );

                        return (
                          <CountyListItem
                            key={GEO_ID}
                            feature={feature}
                            filters={filters}
                            county={county}
                            leadMult={leadMult}
                            onUnselect={() => {
                              if (mapRef.current) {
                                dispatch({
                                  type: 'REMOVE_FEAT_SUBS',
                                  payload: { GEO_ID },
                                });
                              }
                            }}
                          />
                        );
                      })}
                    </Stack>
                  </Box>

                  <Divider />

                  <Button
                    fullWidth
                    color="info"
                    size="large"
                    startIcon={<ShoppingCartCheckoutIcon />}
                    sx={{ borderRadius: 0 }}
                    onClick={() => dispatch({ type: 'TOGGLE_CART' })}
                  >
                    View Cart
                  </Button>
                </Box>
              ) : (
                <Box
                  sx={{
                    p: 3,
                    textAlign: 'center',
                    fontFamily: 'Roboto Mono',
                    color: '#4a4a4a',
                  }}
                >
                  Select counties from the map above to start setting up
                  Subscriptions
                </Box>
              )}
            </Paper>

            <MySubscriptions subs={query.data} />
          </Stack>
        </Box>
      </Container>
    </RouteContainer>
  );
}
