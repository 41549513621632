export interface CountyFeatureData {
  GEO_ID: string;
  STATE: string;
  COUNTY: string;
  NAME: string;
  LSAD: string;
  CENSUSAREA: number;
  FIPS: string;
  fips: string;
  StateAbbr: string;
  StateName: string;
  lat: number;
  lng: number;
}

export const countyFeatures: CountyFeatureData[] = [
  {
    GEO_ID: '0500000US01001',
    STATE: '01',
    COUNTY: '001',
    NAME: 'Autauga',
    LSAD: 'County',
    CENSUSAREA: 594.436,
    FIPS: 'AL001',
    fips: '01001',
    StateAbbr: 'AL',
    StateName: 'Alabama',
    lat: 32.53514228,
    lng: -86.64289976,
  },
  {
    GEO_ID: '0500000US01009',
    STATE: '01',
    COUNTY: '009',
    NAME: 'Blount',
    LSAD: 'County',
    CENSUSAREA: 644.776,
    FIPS: 'AL009',
    fips: '01009',
    StateAbbr: 'AL',
    StateName: 'Alabama',
    lat: 33.98087061,
    lng: -86.5670064,
  },
  {
    GEO_ID: '0500000US01017',
    STATE: '01',
    COUNTY: '017',
    NAME: 'Chambers',
    LSAD: 'County',
    CENSUSAREA: 596.531,
    FIPS: 'AL017',
    fips: '01017',
    StateAbbr: 'AL',
    StateName: 'Alabama',
    lat: 32.91366622,
    lng: -85.39168915,
  },
  {
    GEO_ID: '0500000US01021',
    STATE: '01',
    COUNTY: '021',
    NAME: 'Chilton',
    LSAD: 'County',
    CENSUSAREA: 692.854,
    FIPS: 'AL021',
    fips: '01021',
    StateAbbr: 'AL',
    StateName: 'Alabama',
    lat: 32.84771004,
    lng: -86.71887934,
  },
  {
    GEO_ID: '0500000US01033',
    STATE: '01',
    COUNTY: '033',
    NAME: 'Colbert',
    LSAD: 'County',
    CENSUSAREA: 592.619,
    FIPS: 'AL033',
    fips: '01033',
    StateAbbr: 'AL',
    StateName: 'Alabama',
    lat: 34.70085284,
    lng: -87.80463371,
  },
  {
    GEO_ID: '0500000US01045',
    STATE: '01',
    COUNTY: '045',
    NAME: 'Dale',
    LSAD: 'County',
    CENSUSAREA: 561.15,
    FIPS: 'AL045',
    fips: '01045',
    StateAbbr: 'AL',
    StateName: 'Alabama',
    lat: 31.43204363,
    lng: -85.611081,
  },
  {
    GEO_ID: '0500000US01051',
    STATE: '01',
    COUNTY: '051',
    NAME: 'Elmore',
    LSAD: 'County',
    CENSUSAREA: 618.485,
    FIPS: 'AL051',
    fips: '01051',
    StateAbbr: 'AL',
    StateName: 'Alabama',
    lat: 32.59724126,
    lng: -86.14884036,
  },
  {
    GEO_ID: '0500000US01065',
    STATE: '01',
    COUNTY: '065',
    NAME: 'Hale',
    LSAD: 'County',
    CENSUSAREA: 643.943,
    FIPS: 'AL065',
    fips: '01065',
    StateAbbr: 'AL',
    StateName: 'Alabama',
    lat: 32.7625948,
    lng: -87.62930576,
  },
  {
    GEO_ID: '0500000US01079',
    STATE: '01',
    COUNTY: '079',
    NAME: 'Lawrence',
    LSAD: 'County',
    CENSUSAREA: 690.678,
    FIPS: 'AL079',
    fips: '01079',
    StateAbbr: 'AL',
    StateName: 'Alabama',
    lat: 34.52223687,
    lng: -87.31085381,
  },
  {
    GEO_ID: '0500000US01083',
    STATE: '01',
    COUNTY: '083',
    NAME: 'Limestone',
    LSAD: 'County',
    CENSUSAREA: 559.936,
    FIPS: 'AL083',
    fips: '01083',
    StateAbbr: 'AL',
    StateName: 'Alabama',
    lat: 34.81013487,
    lng: -86.98207203,
  },
  {
    GEO_ID: '0500000US01099',
    STATE: '01',
    COUNTY: '099',
    NAME: 'Monroe',
    LSAD: 'County',
    CENSUSAREA: 1025.675,
    FIPS: 'AL099',
    fips: '01099',
    StateAbbr: 'AL',
    StateName: 'Alabama',
    lat: 31.57107175,
    lng: -87.36529615,
  },
  {
    GEO_ID: '0500000US01107',
    STATE: '01',
    COUNTY: '107',
    NAME: 'Pickens',
    LSAD: 'County',
    CENSUSAREA: 881.408,
    FIPS: 'AL107',
    fips: '01107',
    StateAbbr: 'AL',
    StateName: 'Alabama',
    lat: 33.28058298,
    lng: -88.08923504,
  },
  {
    GEO_ID: '0500000US01121',
    STATE: '01',
    COUNTY: '121',
    NAME: 'Talladega',
    LSAD: 'County',
    CENSUSAREA: 736.775,
    FIPS: 'AL121',
    fips: '01121',
    StateAbbr: 'AL',
    StateName: 'Alabama',
    lat: 33.37992421,
    lng: -86.16587666,
  },
  {
    GEO_ID: '0500000US02050',
    STATE: '02',
    COUNTY: '050',
    NAME: 'Bethel',
    LSAD: 'CA',
    CENSUSAREA: 40570.004,
    FIPS: 'AK050',
    fips: '02050',
    StateAbbr: 'AK',
    StateName: 'Alaska',
    lat: 60.91342739,
    lng: -159.8255997,
  },
  {
    GEO_ID: '0500000US02105',
    STATE: '02',
    COUNTY: '105',
    NAME: 'Hoonah-Angoon',
    LSAD: 'CA',
    CENSUSAREA: 7524.915,
    FIPS: 'AK105',
    fips: '02105',
    StateAbbr: 'AK',
    StateName: 'Alaska',
    lat: 58.28575804,
    lng: -135.6386611,
  },
  {
    GEO_ID: '0500000US02122',
    STATE: '02',
    COUNTY: '122',
    NAME: 'Kenai Peninsula',
    LSAD: 'Borough',
    CENSUSAREA: 16075.331,
    FIPS: 'AK122',
    fips: '02122',
    StateAbbr: 'AK',
    StateName: 'Alaska',
    lat: 60.2537748,
    lng: -151.5611779,
  },
  {
    GEO_ID: '0500000US02150',
    STATE: '02',
    COUNTY: '150',
    NAME: 'Kodiak Island',
    LSAD: 'Borough',
    CENSUSAREA: 6549.579,
    FIPS: 'AK150',
    fips: '02150',
    StateAbbr: 'AK',
    StateName: 'Alaska',
    lat: 57.66653702,
    lng: -153.7750279,
  },
  {
    GEO_ID: '0500000US02164',
    STATE: '02',
    COUNTY: '164',
    NAME: 'Lake and Peninsula',
    LSAD: 'Borough',
    CENSUSAREA: 23652.009,
    FIPS: 'AK164',
    fips: '02164',
    StateAbbr: 'AK',
    StateName: 'Alaska',
    lat: 58.63664843,
    lng: -156.1888735,
  },
  {
    GEO_ID: '0500000US02180',
    STATE: '02',
    COUNTY: '180',
    NAME: 'Nome',
    LSAD: 'CA',
    CENSUSAREA: 22961.761,
    FIPS: 'AK180',
    fips: '02180',
    StateAbbr: 'AK',
    StateName: 'Alaska',
    lat: 64.91040617,
    lng: -164.0315808,
  },
  {
    GEO_ID: '0500000US02188',
    STATE: '02',
    COUNTY: '188',
    NAME: 'Northwest Arctic',
    LSAD: 'Borough',
    CENSUSAREA: 35572.584,
    FIPS: 'AK188',
    fips: '02188',
    StateAbbr: 'AK',
    StateName: 'Alaska',
    lat: 67.05555844,
    lng: -159.724644,
  },
  {
    GEO_ID: '0500000US02198',
    STATE: '02',
    COUNTY: '198',
    NAME: 'Prince of Wales-Hyder',
    LSAD: 'CA',
    CENSUSAREA: 3922.873,
    FIPS: 'AK198',
    fips: '02198',
    StateAbbr: 'AK',
    StateName: 'Alaska',
    lat: 55.81638031,
    lng: -133.0585096,
  },
  {
    GEO_ID: '0500000US05141',
    STATE: '05',
    COUNTY: '141',
    NAME: 'Van Buren',
    LSAD: 'County',
    CENSUSAREA: 708.143,
    FIPS: 'AR141',
    fips: '05141',
    StateAbbr: 'AR',
    StateName: 'Arkansas',
    lat: 35.58054667,
    lng: -92.51610324,
  },
  {
    GEO_ID: '0500000US05145',
    STATE: '05',
    COUNTY: '145',
    NAME: 'White',
    LSAD: 'County',
    CENSUSAREA: 1035.075,
    FIPS: 'AR145',
    fips: '05145',
    StateAbbr: 'AR',
    StateName: 'Arkansas',
    lat: 35.25612459,
    lng: -91.74548804,
  },
  {
    GEO_ID: '0500000US06005',
    STATE: '06',
    COUNTY: '005',
    NAME: 'Amador',
    LSAD: 'County',
    CENSUSAREA: 594.583,
    FIPS: 'CA005',
    fips: '06005',
    StateAbbr: 'CA',
    StateName: 'California',
    lat: 38.44638702,
    lng: -120.651296,
  },
  {
    GEO_ID: '0500000US06021',
    STATE: '06',
    COUNTY: '021',
    NAME: 'Glenn',
    LSAD: 'County',
    CENSUSAREA: 1313.947,
    FIPS: 'CA021',
    fips: '06021',
    StateAbbr: 'CA',
    StateName: 'California',
    lat: 39.59788378,
    lng: -122.3921982,
  },
  {
    GEO_ID: '0500000US06033',
    STATE: '06',
    COUNTY: '033',
    NAME: 'Lake',
    LSAD: 'County',
    CENSUSAREA: 1256.464,
    FIPS: 'CA033',
    fips: '06033',
    StateAbbr: 'CA',
    StateName: 'California',
    lat: 39.0996887,
    lng: -122.7532352,
  },
  {
    GEO_ID: '0500000US06043',
    STATE: '06',
    COUNTY: '043',
    NAME: 'Mariposa',
    LSAD: 'County',
    CENSUSAREA: 1448.816,
    FIPS: 'CA043',
    fips: '06043',
    StateAbbr: 'CA',
    StateName: 'California',
    lat: 37.58143472,
    lng: -119.9053183,
  },
  {
    GEO_ID: '0500000US06055',
    STATE: '06',
    COUNTY: '055',
    NAME: 'Napa',
    LSAD: 'County',
    CENSUSAREA: 748.362,
    FIPS: 'CA055',
    fips: '06055',
    StateAbbr: 'CA',
    StateName: 'California',
    lat: 38.5065458,
    lng: -122.3306328,
  },
  {
    GEO_ID: '0500000US06089',
    STATE: '06',
    COUNTY: '089',
    NAME: 'Shasta',
    LSAD: 'County',
    CENSUSAREA: 3775.402,
    FIPS: 'CA089',
    fips: '06089',
    StateAbbr: 'CA',
    StateName: 'California',
    lat: 40.76391106,
    lng: -122.0409443,
  },
  {
    GEO_ID: '0500000US06099',
    STATE: '06',
    COUNTY: '099',
    NAME: 'Stanislaus',
    LSAD: 'County',
    CENSUSAREA: 1494.827,
    FIPS: 'CA099',
    fips: '06099',
    StateAbbr: 'CA',
    StateName: 'California',
    lat: 37.55851635,
    lng: -120.9979524,
  },
  {
    GEO_ID: '0500000US06115',
    STATE: '06',
    COUNTY: '115',
    NAME: 'Yuba',
    LSAD: 'County',
    CENSUSAREA: 631.839,
    FIPS: 'CA115',
    fips: '06115',
    StateAbbr: 'CA',
    StateName: 'California',
    lat: 39.26930746,
    lng: -121.3508296,
  },
  {
    GEO_ID: '0500000US08003',
    STATE: '08',
    COUNTY: '003',
    NAME: 'Alamosa',
    LSAD: 'County',
    CENSUSAREA: 722.643,
    FIPS: 'CO003',
    fips: '08003',
    StateAbbr: 'CO',
    StateName: 'Colorado',
    lat: 37.57261807,
    lng: -105.788359,
  },
  {
    GEO_ID: '0500000US08013',
    STATE: '08',
    COUNTY: '013',
    NAME: 'Boulder',
    LSAD: 'County',
    CENSUSAREA: 726.289,
    FIPS: 'CO013',
    fips: '08013',
    StateAbbr: 'CO',
    StateName: 'Colorado',
    lat: 40.09241482,
    lng: -105.3574574,
  },
  {
    GEO_ID: '0500000US08014',
    STATE: '08',
    COUNTY: '014',
    NAME: 'Broomfield',
    LSAD: 'County',
    CENSUSAREA: 33.034,
    FIPS: 'CO014',
    fips: '08014',
    StateAbbr: 'CO',
    StateName: 'Colorado',
    lat: 39.95343909,
    lng: -105.0532651,
  },
  {
    GEO_ID: '0500000US08025',
    STATE: '08',
    COUNTY: '025',
    NAME: 'Crowley',
    LSAD: 'County',
    CENSUSAREA: 787.421,
    FIPS: 'CO025',
    fips: '08025',
    StateAbbr: 'CO',
    StateName: 'Colorado',
    lat: 38.32685938,
    lng: -103.7838298,
  },
  {
    GEO_ID: '0500000US08031',
    STATE: '08',
    COUNTY: '031',
    NAME: 'Denver',
    LSAD: 'County',
    CENSUSAREA: 153,
    FIPS: 'CO031',
    fips: '08031',
    StateAbbr: 'CO',
    StateName: 'Colorado',
    lat: 39.76129114,
    lng: -104.8760248,
  },
  {
    GEO_ID: '0500000US08035',
    STATE: '08',
    COUNTY: '035',
    NAME: 'Douglas',
    LSAD: 'County',
    CENSUSAREA: 840.248,
    FIPS: 'CO035',
    fips: '08035',
    StateAbbr: 'CO',
    StateName: 'Colorado',
    lat: 39.32970828,
    lng: -104.92921,
  },
  {
    GEO_ID: '0500000US08047',
    STATE: '08',
    COUNTY: '047',
    NAME: 'Gilpin',
    LSAD: 'County',
    CENSUSAREA: 149.896,
    FIPS: 'CO047',
    fips: '08047',
    StateAbbr: 'CO',
    StateName: 'Colorado',
    lat: 39.85721134,
    lng: -105.5221395,
  },
  {
    GEO_ID: '0500000US12083',
    STATE: '12',
    COUNTY: '083',
    NAME: 'Marion',
    LSAD: 'County',
    CENSUSAREA: 1584.546,
    FIPS: 'FL083',
    fips: '12083',
    StateAbbr: 'FL',
    StateName: 'Florida',
    lat: 29.21028272,
    lng: -82.05662463,
  },
  {
    GEO_ID: '0500000US12087',
    STATE: '12',
    COUNTY: '087',
    NAME: 'Monroe',
    LSAD: 'County',
    CENSUSAREA: 983.282,
    FIPS: 'FL087',
    fips: '12087',
    StateAbbr: 'FL',
    StateName: 'Florida',
    lat: 25.27277607,
    lng: -81.12021765,
  },
  {
    GEO_ID: '0500000US12095',
    STATE: '12',
    COUNTY: '095',
    NAME: 'Orange',
    LSAD: 'County',
    CENSUSAREA: 903.429,
    FIPS: 'FL095',
    fips: '12095',
    StateAbbr: 'FL',
    StateName: 'Florida',
    lat: 28.51429239,
    lng: -81.32311592,
  },
  {
    GEO_ID: '0500000US12111',
    STATE: '12',
    COUNTY: '111',
    NAME: 'St. Lucie',
    LSAD: 'County',
    CENSUSAREA: 571.926,
    FIPS: 'FL111',
    fips: '12111',
    StateAbbr: 'FL',
    StateName: 'Florida',
    lat: 27.377528,
    lng: -80.47111892,
  },
  {
    GEO_ID: '0500000US12119',
    STATE: '12',
    COUNTY: '119',
    NAME: 'Sumter',
    LSAD: 'County',
    CENSUSAREA: 546.933,
    FIPS: 'FL119',
    fips: '12119',
    StateAbbr: 'FL',
    StateName: 'Florida',
    lat: 28.70520781,
    lng: -82.08128977,
  },
  {
    GEO_ID: '0500000US12125',
    STATE: '12',
    COUNTY: '125',
    NAME: 'Union',
    LSAD: 'County',
    CENSUSAREA: 243.556,
    FIPS: 'FL125',
    fips: '12125',
    StateAbbr: 'FL',
    StateName: 'Florida',
    lat: 30.04340869,
    lng: -82.37209886,
  },
  {
    GEO_ID: '0500000US13001',
    STATE: '13',
    COUNTY: '001',
    NAME: 'Appling',
    LSAD: 'County',
    CENSUSAREA: 507.081,
    FIPS: 'GA001',
    fips: '13001',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 31.749434,
    lng: -82.28874491,
  },
  {
    GEO_ID: '0500000US13013',
    STATE: '13',
    COUNTY: '013',
    NAME: 'Barrow',
    LSAD: 'County',
    CENSUSAREA: 160.309,
    FIPS: 'GA013',
    fips: '13013',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 33.99268452,
    lng: -83.71288108,
  },
  {
    GEO_ID: '0500000US13029',
    STATE: '13',
    COUNTY: '029',
    NAME: 'Bryan',
    LSAD: 'County',
    CENSUSAREA: 435.967,
    FIPS: 'GA029',
    fips: '13029',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 32.0140721,
    lng: -81.44364706,
  },
  {
    GEO_ID: '0500000US13043',
    STATE: '13',
    COUNTY: '043',
    NAME: 'Candler',
    LSAD: 'County',
    CENSUSAREA: 243.044,
    FIPS: 'GA043',
    fips: '13043',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 32.40345007,
    lng: -82.07387018,
  },
  {
    GEO_ID: '0500000US13055',
    STATE: '13',
    COUNTY: '055',
    NAME: 'Chattooga',
    LSAD: 'County',
    CENSUSAREA: 313.338,
    FIPS: 'GA055',
    fips: '13055',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 34.47504069,
    lng: -85.34522718,
  },
  {
    GEO_ID: '0500000US13059',
    STATE: '13',
    COUNTY: '059',
    NAME: 'Clarke',
    LSAD: 'County',
    CENSUSAREA: 119.2,
    FIPS: 'GA059',
    fips: '13059',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 33.95097841,
    lng: -83.36755873,
  },
  {
    GEO_ID: '0500000US13071',
    STATE: '13',
    COUNTY: '071',
    NAME: 'Colquitt',
    LSAD: 'County',
    CENSUSAREA: 544.153,
    FIPS: 'GA071',
    fips: '13071',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 31.18832547,
    lng: -83.76886209,
  },
  {
    GEO_ID: '0500000US13079',
    STATE: '13',
    COUNTY: '079',
    NAME: 'Crawford',
    LSAD: 'County',
    CENSUSAREA: 324.888,
    FIPS: 'GA079',
    fips: '13079',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 32.71431882,
    lng: -83.98612506,
  },
  {
    GEO_ID: '0500000US13091',
    STATE: '13',
    COUNTY: '091',
    NAME: 'Dodge',
    LSAD: 'County',
    CENSUSAREA: 495.89,
    FIPS: 'GA091',
    fips: '13091',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 32.17208946,
    lng: -83.16860598,
  },
  {
    GEO_ID: '0500000US13107',
    STATE: '13',
    COUNTY: '107',
    NAME: 'Emanuel',
    LSAD: 'County',
    CENSUSAREA: 680.604,
    FIPS: 'GA107',
    fips: '13107',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 32.58963191,
    lng: -82.30173104,
  },
  {
    GEO_ID: '0500000US13113',
    STATE: '13',
    COUNTY: '113',
    NAME: 'Fayette',
    LSAD: 'County',
    CENSUSAREA: 194.342,
    FIPS: 'GA113',
    fips: '13113',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 33.41303536,
    lng: -84.49428463,
  },
  {
    GEO_ID: '0500000US13123',
    STATE: '13',
    COUNTY: '123',
    NAME: 'Gilmer',
    LSAD: 'County',
    CENSUSAREA: 426.54,
    FIPS: 'GA123',
    fips: '13123',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 34.6914172,
    lng: -84.45574304,
  },
  {
    GEO_ID: '0500000US08053',
    STATE: '08',
    COUNTY: '053',
    NAME: 'Hinsdale',
    LSAD: 'County',
    CENSUSAREA: 1117.251,
    FIPS: 'CO053',
    fips: '08053',
    StateAbbr: 'CO',
    StateName: 'Colorado',
    lat: 37.82131615,
    lng: -107.3003579,
  },
  {
    GEO_ID: '0500000US08057',
    STATE: '08',
    COUNTY: '057',
    NAME: 'Jackson',
    LSAD: 'County',
    CENSUSAREA: 1613.723,
    FIPS: 'CO057',
    fips: '08057',
    StateAbbr: 'CO',
    StateName: 'Colorado',
    lat: 40.66623861,
    lng: -106.3427076,
  },
  {
    GEO_ID: '0500000US08059',
    STATE: '08',
    COUNTY: '059',
    NAME: 'Jefferson',
    LSAD: 'County',
    CENSUSAREA: 764.208,
    FIPS: 'CO059',
    fips: '08059',
    StateAbbr: 'CO',
    StateName: 'Colorado',
    lat: 39.58646067,
    lng: -105.2504738,
  },
  {
    GEO_ID: '0500000US08073',
    STATE: '08',
    COUNTY: '073',
    NAME: 'Lincoln',
    LSAD: 'County',
    CENSUSAREA: 2577.626,
    FIPS: 'CO073',
    fips: '08073',
    StateAbbr: 'CO',
    StateName: 'Colorado',
    lat: 38.98742236,
    lng: -103.5137335,
  },
  {
    GEO_ID: '0500000US08083',
    STATE: '08',
    COUNTY: '083',
    NAME: 'Montezuma',
    LSAD: 'County',
    CENSUSAREA: 2029.527,
    FIPS: 'CO083',
    fips: '08083',
    StateAbbr: 'CO',
    StateName: 'Colorado',
    lat: 37.33893986,
    lng: -108.5964924,
  },
  {
    GEO_ID: '0500000US08095',
    STATE: '08',
    COUNTY: '095',
    NAME: 'Phillips',
    LSAD: 'County',
    CENSUSAREA: 687.928,
    FIPS: 'CO095',
    fips: '08095',
    StateAbbr: 'CO',
    StateName: 'Colorado',
    lat: 40.59394842,
    lng: -102.3573987,
  },
  {
    GEO_ID: '0500000US08105',
    STATE: '08',
    COUNTY: '105',
    NAME: 'Rio Grande',
    LSAD: 'County',
    CENSUSAREA: 911.958,
    FIPS: 'CO105',
    fips: '08105',
    StateAbbr: 'CO',
    StateName: 'Colorado',
    lat: 37.58223621,
    lng: -106.3829289,
  },
  {
    GEO_ID: '0500000US08111',
    STATE: '08',
    COUNTY: '111',
    NAME: 'San Juan',
    LSAD: 'County',
    CENSUSAREA: 387.488,
    FIPS: 'CO111',
    fips: '08111',
    StateAbbr: 'CO',
    StateName: 'Colorado',
    lat: 37.7637698,
    lng: -107.6759098,
  },
  {
    GEO_ID: '0500000US08123',
    STATE: '08',
    COUNTY: '123',
    NAME: 'Weld',
    LSAD: 'County',
    CENSUSAREA: 3987.238,
    FIPS: 'CO123',
    fips: '08123',
    StateAbbr: 'CO',
    StateName: 'Colorado',
    lat: 40.55487277,
    lng: -104.3930144,
  },
  {
    GEO_ID: '0500000US09015',
    STATE: '09',
    COUNTY: '015',
    NAME: 'Windham',
    LSAD: 'County',
    CENSUSAREA: 512.91,
    FIPS: 'CT015',
    fips: '09015',
    StateAbbr: 'CT',
    StateName: 'Connecticut',
    lat: 41.83006633,
    lng: -71.98737539,
  },
  {
    GEO_ID: '0500000US12001',
    STATE: '12',
    COUNTY: '001',
    NAME: 'Alachua',
    LSAD: 'County',
    CENSUSAREA: 875.02,
    FIPS: 'FL001',
    fips: '12001',
    StateAbbr: 'FL',
    StateName: 'Florida',
    lat: 29.67497355,
    lng: -82.35823348,
  },
  {
    GEO_ID: '0500000US12013',
    STATE: '12',
    COUNTY: '013',
    NAME: 'Calhoun',
    LSAD: 'County',
    CENSUSAREA: 567.334,
    FIPS: 'FL013',
    fips: '12013',
    StateAbbr: 'FL',
    StateName: 'Florida',
    lat: 30.40604523,
    lng: -85.19679907,
  },
  {
    GEO_ID: '0500000US12023',
    STATE: '12',
    COUNTY: '023',
    NAME: 'Columbia',
    LSAD: 'County',
    CENSUSAREA: 797.574,
    FIPS: 'FL023',
    fips: '12023',
    StateAbbr: 'FL',
    StateName: 'Florida',
    lat: 30.22445638,
    lng: -82.62162029,
  },
  {
    GEO_ID: '0500000US12039',
    STATE: '12',
    COUNTY: '039',
    NAME: 'Gadsden',
    LSAD: 'County',
    CENSUSAREA: 516.332,
    FIPS: 'FL039',
    fips: '12039',
    StateAbbr: 'FL',
    StateName: 'Florida',
    lat: 30.57930999,
    lng: -84.61383904,
  },
  {
    GEO_ID: '0500000US12055',
    STATE: '12',
    COUNTY: '055',
    NAME: 'Highlands',
    LSAD: 'County',
    CENSUSAREA: 1016.615,
    FIPS: 'FL055',
    fips: '12055',
    StateAbbr: 'FL',
    StateName: 'Florida',
    lat: 27.3428855,
    lng: -81.34078347,
  },
  {
    GEO_ID: '0500000US12059',
    STATE: '12',
    COUNTY: '059',
    NAME: 'Holmes',
    LSAD: 'County',
    CENSUSAREA: 478.777,
    FIPS: 'FL059',
    fips: '12059',
    StateAbbr: 'FL',
    StateName: 'Florida',
    lat: 30.86794622,
    lng: -85.81424864,
  },
  {
    GEO_ID: '0500000US12073',
    STATE: '12',
    COUNTY: '073',
    NAME: 'Leon',
    LSAD: 'County',
    CENSUSAREA: 666.852,
    FIPS: 'FL073',
    fips: '12073',
    StateAbbr: 'FL',
    StateName: 'Florida',
    lat: 30.45806493,
    lng: -84.27761309,
  },
  {
    GEO_ID: '0500000US02261',
    STATE: '02',
    COUNTY: '261',
    NAME: 'Valdez-Cordova',
    LSAD: 'CA',
    CENSUSAREA: 34239.88,
    FIPS: 'AK261',
    fips: '02261',
    StateAbbr: 'AK',
    StateName: 'Alaska',
    lat: 61.54964404,
    lng: -144.5019516,
  },
  {
    GEO_ID: '0500000US02270',
    STATE: '02',
    COUNTY: '270',
    NAME: 'Wade Hampton',
    LSAD: 'CA',
    CENSUSAREA: 17081.433,
    FIPS: 'AK270',
    fips: '02270',
    StateAbbr: 'AK',
    StateName: 'Alaska',
    lat: 62.15704475,
    lng: -163.3844205,
  },
  {
    GEO_ID: '0500000US04003',
    STATE: '04',
    COUNTY: '003',
    NAME: 'Cochise',
    LSAD: 'County',
    CENSUSAREA: 6165.69,
    FIPS: 'AZ003',
    fips: '04003',
    StateAbbr: 'AZ',
    StateName: 'Arizona',
    lat: 31.87959362,
    lng: -109.7510129,
  },
  {
    GEO_ID: '0500000US04013',
    STATE: '04',
    COUNTY: '013',
    NAME: 'Maricopa',
    LSAD: 'County',
    CENSUSAREA: 9200.143,
    FIPS: 'AZ013',
    fips: '04013',
    StateAbbr: 'AZ',
    StateName: 'Arizona',
    lat: 33.34875974,
    lng: -112.4908888,
  },
  {
    GEO_ID: '0500000US05001',
    STATE: '05',
    COUNTY: '001',
    NAME: 'Arkansas',
    LSAD: 'County',
    CENSUSAREA: 988.77,
    FIPS: 'AR001',
    fips: '05001',
    StateAbbr: 'AR',
    StateName: 'Arkansas',
    lat: 34.29076839,
    lng: -91.37520643,
  },
  {
    GEO_ID: '0500000US05009',
    STATE: '05',
    COUNTY: '009',
    NAME: 'Boone',
    LSAD: 'County',
    CENSUSAREA: 590.231,
    FIPS: 'AR009',
    fips: '05009',
    StateAbbr: 'AR',
    StateName: 'Arkansas',
    lat: 36.30874792,
    lng: -93.09137415,
  },
  {
    GEO_ID: '0500000US05019',
    STATE: '05',
    COUNTY: '019',
    NAME: 'Clark',
    LSAD: 'County',
    CENSUSAREA: 866.074,
    FIPS: 'AR019',
    fips: '05019',
    StateAbbr: 'AR',
    StateName: 'Arkansas',
    lat: 34.05108285,
    lng: -93.17667235,
  },
  {
    GEO_ID: '0500000US05029',
    STATE: '05',
    COUNTY: '029',
    NAME: 'Conway',
    LSAD: 'County',
    CENSUSAREA: 552.25,
    FIPS: 'AR029',
    fips: '05029',
    StateAbbr: 'AR',
    StateName: 'Arkansas',
    lat: 35.26227905,
    lng: -92.70098811,
  },
  {
    GEO_ID: '0500000US05043',
    STATE: '05',
    COUNTY: '043',
    NAME: 'Drew',
    LSAD: 'County',
    CENSUSAREA: 828.356,
    FIPS: 'AR043',
    fips: '05043',
    StateAbbr: 'AR',
    StateName: 'Arkansas',
    lat: 33.58957425,
    lng: -91.71972855,
  },
  {
    GEO_ID: '0500000US05051',
    STATE: '05',
    COUNTY: '051',
    NAME: 'Garland',
    LSAD: 'County',
    CENSUSAREA: 677.781,
    FIPS: 'AR051',
    fips: '05051',
    StateAbbr: 'AR',
    StateName: 'Arkansas',
    lat: 34.57659951,
    lng: -93.15048723,
  },
  {
    GEO_ID: '0500000US05061',
    STATE: '05',
    COUNTY: '061',
    NAME: 'Howard',
    LSAD: 'County',
    CENSUSAREA: 588.554,
    FIPS: 'AR061',
    fips: '05061',
    StateAbbr: 'AR',
    StateName: 'Arkansas',
    lat: 34.08897983,
    lng: -93.99344494,
  },
  {
    GEO_ID: '0500000US05071',
    STATE: '05',
    COUNTY: '071',
    NAME: 'Johnson',
    LSAD: 'County',
    CENSUSAREA: 659.804,
    FIPS: 'AR071',
    fips: '05071',
    StateAbbr: 'AR',
    StateName: 'Arkansas',
    lat: 35.57001766,
    lng: -93.46000035,
  },
  {
    GEO_ID: '0500000US05085',
    STATE: '05',
    COUNTY: '085',
    NAME: 'Lonoke',
    LSAD: 'County',
    CENSUSAREA: 770.728,
    FIPS: 'AR085',
    fips: '05085',
    StateAbbr: 'AR',
    StateName: 'Arkansas',
    lat: 34.75441172,
    lng: -91.88791683,
  },
  {
    GEO_ID: '0500000US05097',
    STATE: '05',
    COUNTY: '097',
    NAME: 'Montgomery',
    LSAD: 'County',
    CENSUSAREA: 779.88,
    FIPS: 'AR097',
    fips: '05097',
    StateAbbr: 'AR',
    StateName: 'Arkansas',
    lat: 34.53888136,
    lng: -93.65944523,
  },
  {
    GEO_ID: '0500000US05101',
    STATE: '05',
    COUNTY: '101',
    NAME: 'Newton',
    LSAD: 'County',
    CENSUSAREA: 820.896,
    FIPS: 'AR101',
    fips: '05101',
    StateAbbr: 'AR',
    StateName: 'Arkansas',
    lat: 35.91979049,
    lng: -93.2178649,
  },
  {
    GEO_ID: '0500000US05111',
    STATE: '05',
    COUNTY: '111',
    NAME: 'Poinsett',
    LSAD: 'County',
    CENSUSAREA: 758.389,
    FIPS: 'AR111',
    fips: '05111',
    StateAbbr: 'AR',
    StateName: 'Arkansas',
    lat: 35.57408383,
    lng: -90.66282507,
  },
  {
    GEO_ID: '0500000US05123',
    STATE: '05',
    COUNTY: '123',
    NAME: 'St. Francis',
    LSAD: 'County',
    CENSUSAREA: 634.771,
    FIPS: 'AR123',
    fips: '05123',
    StateAbbr: 'AR',
    StateName: 'Arkansas',
    lat: 35.02180511,
    lng: -90.74840472,
  },
  {
    GEO_ID: '0500000US05129',
    STATE: '05',
    COUNTY: '129',
    NAME: 'Searcy',
    LSAD: 'County',
    CENSUSAREA: 666.095,
    FIPS: 'AR129',
    fips: '05129',
    StateAbbr: 'AR',
    StateName: 'Arkansas',
    lat: 35.91115674,
    lng: -92.69933958,
  },
  {
    GEO_ID: '0500000US13309',
    STATE: '13',
    COUNTY: '309',
    NAME: 'Wheeler',
    LSAD: 'County',
    CENSUSAREA: 295.484,
    FIPS: 'GA309',
    fips: '13309',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 32.11657577,
    lng: -82.72430717,
  },
  {
    GEO_ID: '0500000US13313',
    STATE: '13',
    COUNTY: '313',
    NAME: 'Whitfield',
    LSAD: 'County',
    CENSUSAREA: 290.464,
    FIPS: 'GA313',
    fips: '13313',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 34.80650119,
    lng: -84.96674982,
  },
  {
    GEO_ID: '0500000US13319',
    STATE: '13',
    COUNTY: '319',
    NAME: 'Wilkinson',
    LSAD: 'County',
    CENSUSAREA: 447.308,
    FIPS: 'GA319',
    fips: '13319',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 32.80234579,
    lng: -83.17090856,
  },
  {
    GEO_ID: '0500000US15009',
    STATE: '15',
    COUNTY: '009',
    NAME: 'Maui',
    LSAD: 'County',
    CENSUSAREA: 1161.521,
    FIPS: 'HI009',
    fips: '15009',
    StateAbbr: 'HI',
    StateName: 'Hawaii',
    lat: 20.86402572,
    lng: -156.5692176,
  },
  {
    GEO_ID: '0500000US16015',
    STATE: '16',
    COUNTY: '015',
    NAME: 'Boise',
    LSAD: 'County',
    CENSUSAREA: 1899.237,
    FIPS: 'ID015',
    fips: '16015',
    StateAbbr: 'ID',
    StateName: 'Idaho',
    lat: 43.98909247,
    lng: -115.730321,
  },
  {
    GEO_ID: '0500000US16021',
    STATE: '16',
    COUNTY: '021',
    NAME: 'Boundary',
    LSAD: 'County',
    CENSUSAREA: 1268.56,
    FIPS: 'ID021',
    fips: '16021',
    StateAbbr: 'ID',
    StateName: 'Idaho',
    lat: 48.766998,
    lng: -116.4627525,
  },
  {
    GEO_ID: '0500000US16045',
    STATE: '16',
    COUNTY: '045',
    NAME: 'Gem',
    LSAD: 'County',
    CENSUSAREA: 560.9,
    FIPS: 'ID045',
    fips: '16045',
    StateAbbr: 'ID',
    StateName: 'Idaho',
    lat: 44.0602805,
    lng: -116.3979457,
  },
  {
    GEO_ID: '0500000US16051',
    STATE: '16',
    COUNTY: '051',
    NAME: 'Jefferson',
    LSAD: 'County',
    CENSUSAREA: 1093.501,
    FIPS: 'ID051',
    fips: '16051',
    StateAbbr: 'ID',
    StateName: 'Idaho',
    lat: 43.82027224,
    lng: -112.3114205,
  },
  {
    GEO_ID: '0500000US16057',
    STATE: '16',
    COUNTY: '057',
    NAME: 'Latah',
    LSAD: 'County',
    CENSUSAREA: 1075.996,
    FIPS: 'ID057',
    fips: '16057',
    StateAbbr: 'ID',
    StateName: 'Idaho',
    lat: 46.81645625,
    lng: -116.7117113,
  },
  {
    GEO_ID: '0500000US16067',
    STATE: '16',
    COUNTY: '067',
    NAME: 'Minidoka',
    LSAD: 'County',
    CENSUSAREA: 757.591,
    FIPS: 'ID067',
    fips: '16067',
    StateAbbr: 'ID',
    StateName: 'Idaho',
    lat: 42.85441649,
    lng: -113.6374976,
  },
  {
    GEO_ID: '0500000US16081',
    STATE: '16',
    COUNTY: '081',
    NAME: 'Teton',
    LSAD: 'County',
    CENSUSAREA: 449.456,
    FIPS: 'ID081',
    fips: '16081',
    StateAbbr: 'ID',
    StateName: 'Idaho',
    lat: 43.7599238,
    lng: -111.2078712,
  },
  {
    GEO_ID: '0500000US17005',
    STATE: '17',
    COUNTY: '005',
    NAME: 'Bond',
    LSAD: 'County',
    CENSUSAREA: 380.279,
    FIPS: 'IL005',
    fips: '17005',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 38.88688243,
    lng: -89.43566372,
  },
  {
    GEO_ID: '0500000US17009',
    STATE: '17',
    COUNTY: '009',
    NAME: 'Brown',
    LSAD: 'County',
    CENSUSAREA: 305.607,
    FIPS: 'IL009',
    fips: '17009',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 39.96180493,
    lng: -90.75018804,
  },
  {
    GEO_ID: '0500000US17019',
    STATE: '17',
    COUNTY: '019',
    NAME: 'Champaign',
    LSAD: 'County',
    CENSUSAREA: 996.266,
    FIPS: 'IL019',
    fips: '17019',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 40.13984863,
    lng: -88.19947606,
  },
  {
    GEO_ID: '0500000US17029',
    STATE: '17',
    COUNTY: '029',
    NAME: 'Coles',
    LSAD: 'County',
    CENSUSAREA: 508.291,
    FIPS: 'IL029',
    fips: '17029',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 39.52086003,
    lng: -88.22141023,
  },
  {
    GEO_ID: '0500000US17035',
    STATE: '17',
    COUNTY: '035',
    NAME: 'Cumberland',
    LSAD: 'County',
    CENSUSAREA: 346.024,
    FIPS: 'IL035',
    fips: '17035',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 39.27402319,
    lng: -88.24032182,
  },
  {
    GEO_ID: '0500000US17049',
    STATE: '17',
    COUNTY: '049',
    NAME: 'Effingham',
    LSAD: 'County',
    CENSUSAREA: 478.777,
    FIPS: 'IL049',
    fips: '17049',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 39.05935691,
    lng: -88.58969703,
  },
  {
    GEO_ID: '0500000US17055',
    STATE: '17',
    COUNTY: '055',
    NAME: 'Franklin',
    LSAD: 'County',
    CENSUSAREA: 408.889,
    FIPS: 'IL055',
    fips: '17055',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 37.99202257,
    lng: -88.9239703,
  },
  {
    GEO_ID: '0500000US17065',
    STATE: '17',
    COUNTY: '065',
    NAME: 'Hamilton',
    LSAD: 'County',
    CENSUSAREA: 434.665,
    FIPS: 'IL065',
    fips: '17065',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 38.08163641,
    lng: -88.53883373,
  },
  {
    GEO_ID: '0500000US18051',
    STATE: '18',
    COUNTY: '051',
    NAME: 'Gibson',
    LSAD: 'County',
    CENSUSAREA: 487.486,
    FIPS: 'IN051',
    fips: '18051',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 38.31161078,
    lng: -87.58518363,
  },
  {
    GEO_ID: '0500000US18059',
    STATE: '18',
    COUNTY: '059',
    NAME: 'Hancock',
    LSAD: 'County',
    CENSUSAREA: 306.016,
    FIPS: 'IN059',
    fips: '18059',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 39.82296118,
    lng: -85.77325696,
  },
  {
    GEO_ID: '0500000US18069',
    STATE: '18',
    COUNTY: '069',
    NAME: 'Huntington',
    LSAD: 'County',
    CENSUSAREA: 382.651,
    FIPS: 'IN069',
    fips: '18069',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 40.82907594,
    lng: -85.48831237,
  },
  {
    GEO_ID: '0500000US18079',
    STATE: '18',
    COUNTY: '079',
    NAME: 'Jennings',
    LSAD: 'County',
    CENSUSAREA: 376.583,
    FIPS: 'IN079',
    fips: '18079',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 38.99757081,
    lng: -85.62737915,
  },
  {
    GEO_ID: '0500000US18085',
    STATE: '18',
    COUNTY: '085',
    NAME: 'Kosciusko',
    LSAD: 'County',
    CENSUSAREA: 531.381,
    FIPS: 'IN085',
    fips: '18085',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 41.24435723,
    lng: -85.86064998,
  },
  {
    GEO_ID: '0500000US18095',
    STATE: '18',
    COUNTY: '095',
    NAME: 'Madison',
    LSAD: 'County',
    CENSUSAREA: 451.915,
    FIPS: 'IN095',
    fips: '18095',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 40.16126825,
    lng: -85.71947571,
  },
  {
    GEO_ID: '0500000US18105',
    STATE: '18',
    COUNTY: '105',
    NAME: 'Monroe',
    LSAD: 'County',
    CENSUSAREA: 394.509,
    FIPS: 'IN105',
    fips: '18105',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 39.16100464,
    lng: -86.52334305,
  },
  {
    GEO_ID: '0500000US18115',
    STATE: '18',
    COUNTY: '115',
    NAME: 'Ohio',
    LSAD: 'County',
    CENSUSAREA: 86.14,
    FIPS: 'IN115',
    fips: '18115',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 38.94996711,
    lng: -84.96502468,
  },
  {
    GEO_ID: '0500000US18125',
    STATE: '18',
    COUNTY: '125',
    NAME: 'Pike',
    LSAD: 'County',
    CENSUSAREA: 334.238,
    FIPS: 'IN125',
    fips: '18125',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 38.39848154,
    lng: -87.2323432,
  },
  {
    GEO_ID: '0500000US18131',
    STATE: '18',
    COUNTY: '131',
    NAME: 'Pulaski',
    LSAD: 'County',
    CENSUSAREA: 433.649,
    FIPS: 'IN131',
    fips: '18131',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 41.04166107,
    lng: -86.69890683,
  },
  {
    GEO_ID: '0500000US18139',
    STATE: '18',
    COUNTY: '139',
    NAME: 'Rush',
    LSAD: 'County',
    CENSUSAREA: 408.124,
    FIPS: 'IN139',
    fips: '18139',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 39.62002576,
    lng: -85.46543335,
  },
  {
    GEO_ID: '0500000US18153',
    STATE: '18',
    COUNTY: '153',
    NAME: 'Sullivan',
    LSAD: 'County',
    CENSUSAREA: 447.142,
    FIPS: 'IN153',
    fips: '18153',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 39.08909886,
    lng: -87.41401466,
  },
  {
    GEO_ID: '0500000US18173',
    STATE: '18',
    COUNTY: '173',
    NAME: 'Warrick',
    LSAD: 'County',
    CENSUSAREA: 384.815,
    FIPS: 'IN173',
    fips: '18173',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 38.09237205,
    lng: -87.2717425,
  },
  {
    GEO_ID: '0500000US18179',
    STATE: '18',
    COUNTY: '179',
    NAME: 'Wells',
    LSAD: 'County',
    CENSUSAREA: 368.087,
    FIPS: 'IN179',
    fips: '18179',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 40.72931843,
    lng: -85.22119115,
  },
  {
    GEO_ID: '0500000US18183',
    STATE: '18',
    COUNTY: '183',
    NAME: 'Whitley',
    LSAD: 'County',
    CENSUSAREA: 335.569,
    FIPS: 'IN183',
    fips: '18183',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 41.13932767,
    lng: -85.50505208,
  },
  {
    GEO_ID: '0500000US19009',
    STATE: '19',
    COUNTY: '009',
    NAME: 'Audubon',
    LSAD: 'County',
    CENSUSAREA: 442.961,
    FIPS: 'IA009',
    fips: '19009',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 41.6844628,
    lng: -94.90581859,
  },
  {
    GEO_ID: '0500000US19017',
    STATE: '19',
    COUNTY: '017',
    NAME: 'Bremer',
    LSAD: 'County',
    CENSUSAREA: 435.477,
    FIPS: 'IA017',
    fips: '19017',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 42.77461496,
    lng: -92.31795937,
  },
  {
    GEO_ID: '0500000US19021',
    STATE: '19',
    COUNTY: '021',
    NAME: 'Buena Vista',
    LSAD: 'County',
    CENSUSAREA: 574.915,
    FIPS: 'IA021',
    fips: '19021',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 42.73545875,
    lng: -95.15100162,
  },
  {
    GEO_ID: '0500000US19031',
    STATE: '19',
    COUNTY: '031',
    NAME: 'Cedar',
    LSAD: 'County',
    CENSUSAREA: 579.436,
    FIPS: 'IA031',
    fips: '19031',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 41.77233777,
    lng: -91.13246684,
  },
  {
    GEO_ID: '0500000US19039',
    STATE: '19',
    COUNTY: '039',
    NAME: 'Clarke',
    LSAD: 'County',
    CENSUSAREA: 431.167,
    FIPS: 'IA039',
    fips: '19039',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 41.02892087,
    lng: -93.78516215,
  },
  {
    GEO_ID: '0500000US17073',
    STATE: '17',
    COUNTY: '073',
    NAME: 'Henry',
    LSAD: 'County',
    CENSUSAREA: 822.985,
    FIPS: 'IL073',
    fips: '17073',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 41.35314875,
    lng: -90.13161776,
  },
  {
    GEO_ID: '0500000US17077',
    STATE: '17',
    COUNTY: '077',
    NAME: 'Jackson',
    LSAD: 'County',
    CENSUSAREA: 584.081,
    FIPS: 'IL077',
    fips: '17077',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 37.78505462,
    lng: -89.38232705,
  },
  {
    GEO_ID: '0500000US17079',
    STATE: '17',
    COUNTY: '079',
    NAME: 'Jasper',
    LSAD: 'County',
    CENSUSAREA: 494.51,
    FIPS: 'IL079',
    fips: '17079',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 39.01061641,
    lng: -88.15377894,
  },
  {
    GEO_ID: '0500000US17089',
    STATE: '17',
    COUNTY: '089',
    NAME: 'Kane',
    LSAD: 'County',
    CENSUSAREA: 520.058,
    FIPS: 'IL089',
    fips: '17089',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 41.93895003,
    lng: -88.42861217,
  },
  {
    GEO_ID: '0500000US17103',
    STATE: '17',
    COUNTY: '103',
    NAME: 'Lee',
    LSAD: 'County',
    CENSUSAREA: 724.896,
    FIPS: 'IL103',
    fips: '17103',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 41.74622905,
    lng: -89.29939757,
  },
  {
    GEO_ID: '0500000US17107',
    STATE: '17',
    COUNTY: '107',
    NAME: 'Logan',
    LSAD: 'County',
    CENSUSAREA: 618.059,
    FIPS: 'IL107',
    fips: '17107',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 40.12467469,
    lng: -89.36775244,
  },
  {
    GEO_ID: '0500000US17121',
    STATE: '17',
    COUNTY: '121',
    NAME: 'Marion',
    LSAD: 'County',
    CENSUSAREA: 572.363,
    FIPS: 'IL121',
    fips: '17121',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 38.64962591,
    lng: -88.91900564,
  },
  {
    GEO_ID: '0500000US17129',
    STATE: '17',
    COUNTY: '129',
    NAME: 'Menard',
    LSAD: 'County',
    CENSUSAREA: 314.437,
    FIPS: 'IL129',
    fips: '17129',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 40.02711636,
    lng: -89.80172848,
  },
  {
    GEO_ID: '0500000US17139',
    STATE: '17',
    COUNTY: '139',
    NAME: 'Moultrie',
    LSAD: 'County',
    CENSUSAREA: 335.943,
    FIPS: 'IL139',
    fips: '17139',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 39.64136794,
    lng: -88.61929657,
  },
  {
    GEO_ID: '0500000US17145',
    STATE: '17',
    COUNTY: '145',
    NAME: 'Perry',
    LSAD: 'County',
    CENSUSAREA: 441.761,
    FIPS: 'IL145',
    fips: '17145',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 38.08436666,
    lng: -89.36728052,
  },
  {
    GEO_ID: '0500000US17155',
    STATE: '17',
    COUNTY: '155',
    NAME: 'Putnam',
    LSAD: 'County',
    CENSUSAREA: 160.161,
    FIPS: 'IL155',
    fips: '17155',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 41.2041567,
    lng: -89.28578933,
  },
  {
    GEO_ID: '0500000US17169',
    STATE: '17',
    COUNTY: '169',
    NAME: 'Schuyler',
    LSAD: 'County',
    CENSUSAREA: 437.273,
    FIPS: 'IL169',
    fips: '17169',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 40.15816304,
    lng: -90.61538755,
  },
  {
    GEO_ID: '0500000US17175',
    STATE: '17',
    COUNTY: '175',
    NAME: 'Stark',
    LSAD: 'County',
    CENSUSAREA: 288.079,
    FIPS: 'IL175',
    fips: '17175',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 41.09327074,
    lng: -89.79757636,
  },
  {
    GEO_ID: '0500000US17179',
    STATE: '17',
    COUNTY: '179',
    NAME: 'Tazewell',
    LSAD: 'County',
    CENSUSAREA: 648.974,
    FIPS: 'IL179',
    fips: '17179',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 40.50758831,
    lng: -89.51334003,
  },
  {
    GEO_ID: '0500000US17189',
    STATE: '17',
    COUNTY: '189',
    NAME: 'Washington',
    LSAD: 'County',
    CENSUSAREA: 562.572,
    FIPS: 'IL189',
    fips: '17189',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 38.35241923,
    lng: -89.41050166,
  },
  {
    GEO_ID: '0500000US17203',
    STATE: '17',
    COUNTY: '203',
    NAME: 'Woodford',
    LSAD: 'County',
    CENSUSAREA: 527.799,
    FIPS: 'IL203',
    fips: '17203',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 40.78829846,
    lng: -89.21080819,
  },
  {
    GEO_ID: '0500000US18009',
    STATE: '18',
    COUNTY: '009',
    NAME: 'Blackford',
    LSAD: 'County',
    CENSUSAREA: 165.08,
    FIPS: 'IN009',
    fips: '18009',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 40.47378705,
    lng: -85.32478758,
  },
  {
    GEO_ID: '0500000US18011',
    STATE: '18',
    COUNTY: '011',
    NAME: 'Boone',
    LSAD: 'County',
    CENSUSAREA: 422.913,
    FIPS: 'IN011',
    fips: '18011',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 40.05117643,
    lng: -86.46866856,
  },
  {
    GEO_ID: '0500000US18021',
    STATE: '18',
    COUNTY: '021',
    NAME: 'Clay',
    LSAD: 'County',
    CENSUSAREA: 357.542,
    FIPS: 'IN021',
    fips: '18021',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 39.39291835,
    lng: -87.11576766,
  },
  {
    GEO_ID: '0500000US18033',
    STATE: '18',
    COUNTY: '033',
    NAME: 'DeKalb',
    LSAD: 'County',
    CENSUSAREA: 362.824,
    FIPS: 'IN033',
    fips: '18033',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 41.39806132,
    lng: -84.99898798,
  },
  {
    GEO_ID: '0500000US13125',
    STATE: '13',
    COUNTY: '125',
    NAME: 'Glascock',
    LSAD: 'County',
    CENSUSAREA: 143.74,
    FIPS: 'GA125',
    fips: '13125',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 33.22935427,
    lng: -82.61046209,
  },
  {
    GEO_ID: '0500000US13129',
    STATE: '13',
    COUNTY: '129',
    NAME: 'Gordon',
    LSAD: 'County',
    CENSUSAREA: 355.808,
    FIPS: 'GA129',
    fips: '13129',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 34.50374908,
    lng: -84.87632767,
  },
  {
    GEO_ID: '0500000US13131',
    STATE: '13',
    COUNTY: '131',
    NAME: 'Grady',
    LSAD: 'County',
    CENSUSAREA: 454.527,
    FIPS: 'GA131',
    fips: '13131',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 30.87476853,
    lng: -84.23475065,
  },
  {
    GEO_ID: '0500000US13139',
    STATE: '13',
    COUNTY: '139',
    NAME: 'Hall',
    LSAD: 'County',
    CENSUSAREA: 392.782,
    FIPS: 'GA139',
    fips: '13139',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 34.31734176,
    lng: -83.819838,
  },
  {
    GEO_ID: '0500000US13157',
    STATE: '13',
    COUNTY: '157',
    NAME: 'Jackson',
    LSAD: 'County',
    CENSUSAREA: 339.663,
    FIPS: 'GA157',
    fips: '13157',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 34.1338612,
    lng: -83.56602206,
  },
  {
    GEO_ID: '0500000US13169',
    STATE: '13',
    COUNTY: '169',
    NAME: 'Jones',
    LSAD: 'County',
    CENSUSAREA: 393.933,
    FIPS: 'GA169',
    fips: '13169',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 33.0254599,
    lng: -83.56057299,
  },
  {
    GEO_ID: '0500000US13175',
    STATE: '13',
    COUNTY: '175',
    NAME: 'Laurens',
    LSAD: 'County',
    CENSUSAREA: 807.296,
    FIPS: 'GA175',
    fips: '13175',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 32.46355824,
    lng: -82.92233434,
  },
  {
    GEO_ID: '0500000US13187',
    STATE: '13',
    COUNTY: '187',
    NAME: 'Lumpkin',
    LSAD: 'County',
    CENSUSAREA: 282.933,
    FIPS: 'GA187',
    fips: '13187',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 34.57241644,
    lng: -84.00327535,
  },
  {
    GEO_ID: '0500000US13197',
    STATE: '13',
    COUNTY: '197',
    NAME: 'Marion',
    LSAD: 'County',
    CENSUSAREA: 366.003,
    FIPS: 'GA197',
    fips: '13197',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 32.35300898,
    lng: -84.52462573,
  },
  {
    GEO_ID: '0500000US13201',
    STATE: '13',
    COUNTY: '201',
    NAME: 'Miller',
    LSAD: 'County',
    CENSUSAREA: 282.421,
    FIPS: 'GA201',
    fips: '13201',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 31.16479534,
    lng: -84.73106803,
  },
  {
    GEO_ID: '0500000US13217',
    STATE: '13',
    COUNTY: '217',
    NAME: 'Newton',
    LSAD: 'County',
    CENSUSAREA: 272.161,
    FIPS: 'GA217',
    fips: '13217',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 33.55492575,
    lng: -83.85005873,
  },
  {
    GEO_ID: '0500000US13225',
    STATE: '13',
    COUNTY: '225',
    NAME: 'Peach',
    LSAD: 'County',
    CENSUSAREA: 150.267,
    FIPS: 'GA225',
    fips: '13225',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 32.56840679,
    lng: -83.82790492,
  },
  {
    GEO_ID: '0500000US13233',
    STATE: '13',
    COUNTY: '233',
    NAME: 'Polk',
    LSAD: 'County',
    CENSUSAREA: 310.331,
    FIPS: 'GA233',
    fips: '13233',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 34.00190077,
    lng: -85.18805562,
  },
  {
    GEO_ID: '0500000US13243',
    STATE: '13',
    COUNTY: '243',
    NAME: 'Randolph',
    LSAD: 'County',
    CENSUSAREA: 428.236,
    FIPS: 'GA243',
    fips: '13243',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 31.7621807,
    lng: -84.75379101,
  },
  {
    GEO_ID: '0500000US13253',
    STATE: '13',
    COUNTY: '253',
    NAME: 'Seminole',
    LSAD: 'County',
    CENSUSAREA: 235.226,
    FIPS: 'GA253',
    fips: '13253',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 30.93897842,
    lng: -84.86850436,
  },
  {
    GEO_ID: '0500000US13265',
    STATE: '13',
    COUNTY: '265',
    NAME: 'Taliaferro',
    LSAD: 'County',
    CENSUSAREA: 194.608,
    FIPS: 'GA265',
    fips: '13265',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 33.566347,
    lng: -82.87900052,
  },
  {
    GEO_ID: '0500000US13277',
    STATE: '13',
    COUNTY: '277',
    NAME: 'Tift',
    LSAD: 'County',
    CENSUSAREA: 258.915,
    FIPS: 'GA277',
    fips: '13277',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 31.45751029,
    lng: -83.52652261,
  },
  {
    GEO_ID: '0500000US13285',
    STATE: '13',
    COUNTY: '285',
    NAME: 'Troup',
    LSAD: 'County',
    CENSUSAREA: 413.988,
    FIPS: 'GA285',
    fips: '13285',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 33.03346388,
    lng: -85.02873581,
  },
  {
    GEO_ID: '0500000US13293',
    STATE: '13',
    COUNTY: '293',
    NAME: 'Upson',
    LSAD: 'County',
    CENSUSAREA: 323.437,
    FIPS: 'GA293',
    fips: '13293',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 32.88096475,
    lng: -84.29964447,
  },
  {
    GEO_ID: '0500000US13303',
    STATE: '13',
    COUNTY: '303',
    NAME: 'Washington',
    LSAD: 'County',
    CENSUSAREA: 678.452,
    FIPS: 'GA303',
    fips: '13303',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 32.96958544,
    lng: -82.79573433,
  },
  {
    GEO_ID: '0500000US20027',
    STATE: '20',
    COUNTY: '027',
    NAME: 'Clay',
    LSAD: 'County',
    CENSUSAREA: 645.301,
    FIPS: 'KS027',
    fips: '20027',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 39.34944765,
    lng: -97.16512523,
  },
  {
    GEO_ID: '0500000US20029',
    STATE: '20',
    COUNTY: '029',
    NAME: 'Cloud',
    LSAD: 'County',
    CENSUSAREA: 715.342,
    FIPS: 'KS029',
    fips: '20029',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 39.48027303,
    lng: -97.64919278,
  },
  {
    GEO_ID: '0500000US20039',
    STATE: '20',
    COUNTY: '039',
    NAME: 'Decatur',
    LSAD: 'County',
    CENSUSAREA: 893.517,
    FIPS: 'KS039',
    fips: '20039',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 39.78469039,
    lng: -100.4593928,
  },
  {
    GEO_ID: '0500000US20049',
    STATE: '20',
    COUNTY: '049',
    NAME: 'Elk',
    LSAD: 'County',
    CENSUSAREA: 644.265,
    FIPS: 'KS049',
    fips: '20049',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 37.45338324,
    lng: -96.24370891,
  },
  {
    GEO_ID: '0500000US20065',
    STATE: '20',
    COUNTY: '065',
    NAME: 'Graham',
    LSAD: 'County',
    CENSUSAREA: 898.522,
    FIPS: 'KS065',
    fips: '20065',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 39.34986711,
    lng: -99.88334471,
  },
  {
    GEO_ID: '0500000US20075',
    STATE: '20',
    COUNTY: '075',
    NAME: 'Hamilton',
    LSAD: 'County',
    CENSUSAREA: 996.508,
    FIPS: 'KS075',
    fips: '20075',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 37.9991797,
    lng: -101.7915769,
  },
  {
    GEO_ID: '0500000US20085',
    STATE: '20',
    COUNTY: '085',
    NAME: 'Jackson',
    LSAD: 'County',
    CENSUSAREA: 656.217,
    FIPS: 'KS085',
    fips: '20085',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 39.41641542,
    lng: -95.79433878,
  },
  {
    GEO_ID: '0500000US20095',
    STATE: '20',
    COUNTY: '095',
    NAME: 'Kingman',
    LSAD: 'County',
    CENSUSAREA: 863.363,
    FIPS: 'KS095',
    fips: '20095',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 37.55911415,
    lng: -98.13639393,
  },
  {
    GEO_ID: '0500000US20105',
    STATE: '20',
    COUNTY: '105',
    NAME: 'Lincoln',
    LSAD: 'County',
    CENSUSAREA: 719.396,
    FIPS: 'KS105',
    fips: '20105',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 39.04547506,
    lng: -98.20749,
  },
  {
    GEO_ID: '0500000US20115',
    STATE: '20',
    COUNTY: '115',
    NAME: 'Marion',
    LSAD: 'County',
    CENSUSAREA: 944.29,
    FIPS: 'KS115',
    fips: '20115',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 38.35899873,
    lng: -97.09712874,
  },
  {
    GEO_ID: '0500000US20127',
    STATE: '20',
    COUNTY: '127',
    NAME: 'Morris',
    LSAD: 'County',
    CENSUSAREA: 695.279,
    FIPS: 'KS127',
    fips: '20127',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 38.68742242,
    lng: -96.64983521,
  },
  {
    GEO_ID: '0500000US20135',
    STATE: '20',
    COUNTY: '135',
    NAME: 'Ness',
    LSAD: 'County',
    CENSUSAREA: 1074.75,
    FIPS: 'KS135',
    fips: '20135',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 38.47974157,
    lng: -99.91547333,
  },
  {
    GEO_ID: '0500000US20145',
    STATE: '20',
    COUNTY: '145',
    NAME: 'Pawnee',
    LSAD: 'County',
    CENSUSAREA: 754.263,
    FIPS: 'KS145',
    fips: '20145',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 38.18106307,
    lng: -99.23682021,
  },
  {
    GEO_ID: '0500000US20155',
    STATE: '20',
    COUNTY: '155',
    NAME: 'Reno',
    LSAD: 'County',
    CENSUSAREA: 1255.346,
    FIPS: 'KS155',
    fips: '20155',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 37.95303921,
    lng: -98.08586348,
  },
  {
    GEO_ID: '0500000US20163',
    STATE: '20',
    COUNTY: '163',
    NAME: 'Rooks',
    LSAD: 'County',
    CENSUSAREA: 890.533,
    FIPS: 'KS163',
    fips: '20163',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 39.35022485,
    lng: -99.3248686,
  },
  {
    GEO_ID: '0500000US20173',
    STATE: '20',
    COUNTY: '173',
    NAME: 'Sedgwick',
    LSAD: 'County',
    CENSUSAREA: 997.512,
    FIPS: 'KS173',
    fips: '20173',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 37.68448116,
    lng: -97.46083703,
  },
  {
    GEO_ID: '0500000US20185',
    STATE: '20',
    COUNTY: '185',
    NAME: 'Stafford',
    LSAD: 'County',
    CENSUSAREA: 792.046,
    FIPS: 'KS185',
    fips: '20185',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 38.03102509,
    lng: -98.71763136,
  },
  {
    GEO_ID: '0500000US20195',
    STATE: '20',
    COUNTY: '195',
    NAME: 'Trego',
    LSAD: 'County',
    CENSUSAREA: 889.478,
    FIPS: 'KS195',
    fips: '20195',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 38.91467218,
    lng: -99.87284448,
  },
  {
    GEO_ID: '0500000US20203',
    STATE: '20',
    COUNTY: '203',
    NAME: 'Wichita',
    LSAD: 'County',
    CENSUSAREA: 718.568,
    FIPS: 'KS203',
    fips: '20203',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 38.48178252,
    lng: -101.3471509,
  },
  {
    GEO_ID: '0500000US20205',
    STATE: '20',
    COUNTY: '205',
    NAME: 'Wilson',
    LSAD: 'County',
    CENSUSAREA: 570.418,
    FIPS: 'KS205',
    fips: '20205',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 37.55937174,
    lng: -95.74323255,
  },
  {
    GEO_ID: '0500000US21183',
    STATE: '21',
    COUNTY: '183',
    NAME: 'Ohio',
    LSAD: 'County',
    CENSUSAREA: 587.274,
    FIPS: 'KY183',
    fips: '21183',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 37.47818665,
    lng: -86.84842961,
  },
  {
    GEO_ID: '0500000US21187',
    STATE: '21',
    COUNTY: '187',
    NAME: 'Owen',
    LSAD: 'County',
    CENSUSAREA: 351.103,
    FIPS: 'KY187',
    fips: '21187',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 38.52025251,
    lng: -84.82790284,
  },
  {
    GEO_ID: '0500000US21189',
    STATE: '21',
    COUNTY: '189',
    NAME: 'Owsley',
    LSAD: 'County',
    CENSUSAREA: 197.407,
    FIPS: 'KY189',
    fips: '21189',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 37.41974744,
    lng: -83.68320054,
  },
  {
    GEO_ID: '0500000US21199',
    STATE: '21',
    COUNTY: '199',
    NAME: 'Pulaski',
    LSAD: 'County',
    CENSUSAREA: 658.408,
    FIPS: 'KY199',
    fips: '21199',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 37.10380837,
    lng: -84.57709045,
  },
  {
    GEO_ID: '0500000US21209',
    STATE: '21',
    COUNTY: '209',
    NAME: 'Scott',
    LSAD: 'County',
    CENSUSAREA: 281.766,
    FIPS: 'KY209',
    fips: '21209',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 38.29156708,
    lng: -84.58344665,
  },
  {
    GEO_ID: '0500000US21219',
    STATE: '21',
    COUNTY: '219',
    NAME: 'Todd',
    LSAD: 'County',
    CENSUSAREA: 374.496,
    FIPS: 'KY219',
    fips: '21219',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 36.8358203,
    lng: -87.17903216,
  },
  {
    GEO_ID: '0500000US21229',
    STATE: '21',
    COUNTY: '229',
    NAME: 'Washington',
    LSAD: 'County',
    CENSUSAREA: 297.266,
    FIPS: 'KY229',
    fips: '21229',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 37.75352228,
    lng: -85.17499676,
  },
  {
    GEO_ID: '0500000US22001',
    STATE: '22',
    COUNTY: '001',
    NAME: 'Acadia',
    LSAD: 'Parish',
    CENSUSAREA: 655.119,
    FIPS: 'LA001',
    fips: '22001',
    StateAbbr: 'LA',
    StateName: 'Louisiana',
    lat: 30.29064732,
    lng: -92.41163568,
  },
  {
    GEO_ID: '0500000US22013',
    STATE: '22',
    COUNTY: '013',
    NAME: 'Bienville',
    LSAD: 'Parish',
    CENSUSAREA: 811.268,
    FIPS: 'LA013',
    fips: '22013',
    StateAbbr: 'LA',
    StateName: 'Louisiana',
    lat: 32.34717134,
    lng: -93.05594858,
  },
  {
    GEO_ID: '0500000US22033',
    STATE: '22',
    COUNTY: '033',
    NAME: 'East Baton Rouge',
    LSAD: 'Parish',
    CENSUSAREA: 455.374,
    FIPS: 'LA033',
    fips: '22033',
    StateAbbr: 'LA',
    StateName: 'Louisiana',
    lat: 30.53801719,
    lng: -91.0956724,
  },
  {
    GEO_ID: '0500000US22053',
    STATE: '22',
    COUNTY: '053',
    NAME: 'Jefferson Davis',
    LSAD: 'Parish',
    CENSUSAREA: 651.328,
    FIPS: 'LA053',
    fips: '22053',
    StateAbbr: 'LA',
    StateName: 'Louisiana',
    lat: 30.26796744,
    lng: -92.8140461,
  },
  {
    GEO_ID: '0500000US22073',
    STATE: '22',
    COUNTY: '073',
    NAME: 'Ouachita',
    LSAD: 'Parish',
    CENSUSAREA: 610.406,
    FIPS: 'LA073',
    fips: '22073',
    StateAbbr: 'LA',
    StateName: 'Louisiana',
    lat: 32.47822702,
    lng: -92.15486108,
  },
  {
    GEO_ID: '0500000US22077',
    STATE: '22',
    COUNTY: '077',
    NAME: 'Pointe Coupee',
    LSAD: 'Parish',
    CENSUSAREA: 557.346,
    FIPS: 'LA077',
    fips: '22077',
    StateAbbr: 'LA',
    StateName: 'Louisiana',
    lat: 30.70893573,
    lng: -91.60044956,
  },
  {
    GEO_ID: '0500000US22089',
    STATE: '22',
    COUNTY: '089',
    NAME: 'St. Charles',
    LSAD: 'Parish',
    CENSUSAREA: 279.083,
    FIPS: 'LA089',
    fips: '22089',
    StateAbbr: 'LA',
    StateName: 'Louisiana',
    lat: 29.90559494,
    lng: -90.35781296,
  },
  {
    GEO_ID: '0500000US22105',
    STATE: '22',
    COUNTY: '105',
    NAME: 'Tangipahoa',
    LSAD: 'Parish',
    CENSUSAREA: 791.275,
    FIPS: 'LA105',
    fips: '22105',
    StateAbbr: 'LA',
    StateName: 'Louisiana',
    lat: 30.62688036,
    lng: -90.40542519,
  },
  {
    GEO_ID: '0500000US22115',
    STATE: '22',
    COUNTY: '115',
    NAME: 'Vernon',
    LSAD: 'Parish',
    CENSUSAREA: 1327.91,
    FIPS: 'LA115',
    fips: '22115',
    StateAbbr: 'LA',
    StateName: 'Louisiana',
    lat: 31.10800615,
    lng: -93.18373375,
  },
  {
    GEO_ID: '0500000US21003',
    STATE: '21',
    COUNTY: '003',
    NAME: 'Allen',
    LSAD: 'County',
    CENSUSAREA: 344.338,
    FIPS: 'KY003',
    fips: '21003',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 36.75097491,
    lng: -86.19069857,
  },
  {
    GEO_ID: '0500000US21009',
    STATE: '21',
    COUNTY: '009',
    NAME: 'Barren',
    LSAD: 'County',
    CENSUSAREA: 487.541,
    FIPS: 'KY009',
    fips: '21009',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 36.96546022,
    lng: -85.93377067,
  },
  {
    GEO_ID: '0500000US21017',
    STATE: '21',
    COUNTY: '017',
    NAME: 'Bourbon',
    LSAD: 'County',
    CENSUSAREA: 289.719,
    FIPS: 'KY017',
    fips: '21017',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 38.20656118,
    lng: -84.21645396,
  },
  {
    GEO_ID: '0500000US21025',
    STATE: '21',
    COUNTY: '025',
    NAME: 'Breathitt',
    LSAD: 'County',
    CENSUSAREA: 492.413,
    FIPS: 'KY025',
    fips: '21025',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 37.5214728,
    lng: -83.32358216,
  },
  {
    GEO_ID: '0500000US21031',
    STATE: '21',
    COUNTY: '031',
    NAME: 'Butler',
    LSAD: 'County',
    CENSUSAREA: 426.088,
    FIPS: 'KY031',
    fips: '21031',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 37.20710749,
    lng: -86.68145677,
  },
  {
    GEO_ID: '0500000US21043',
    STATE: '21',
    COUNTY: '043',
    NAME: 'Carter',
    LSAD: 'County',
    CENSUSAREA: 409.496,
    FIPS: 'KY043',
    fips: '21043',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 38.31813851,
    lng: -83.0504524,
  },
  {
    GEO_ID: '0500000US21053',
    STATE: '21',
    COUNTY: '053',
    NAME: 'Clinton',
    LSAD: 'County',
    CENSUSAREA: 197.246,
    FIPS: 'KY053',
    fips: '21053',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 36.7275894,
    lng: -85.13646367,
  },
  {
    GEO_ID: '0500000US21063',
    STATE: '21',
    COUNTY: '063',
    NAME: 'Elliott',
    LSAD: 'County',
    CENSUSAREA: 234.315,
    FIPS: 'KY063',
    fips: '21063',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 38.11810953,
    lng: -83.09731571,
  },
  {
    GEO_ID: '0500000US21077',
    STATE: '21',
    COUNTY: '077',
    NAME: 'Gallatin',
    LSAD: 'County',
    CENSUSAREA: 101.234,
    FIPS: 'KY077',
    fips: '21077',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 38.7573309,
    lng: -84.85966506,
  },
  {
    GEO_ID: '0500000US21087',
    STATE: '21',
    COUNTY: '087',
    NAME: 'Green',
    LSAD: 'County',
    CENSUSAREA: 286.034,
    FIPS: 'KY087',
    fips: '21087',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 37.26369627,
    lng: -85.55273508,
  },
  {
    GEO_ID: '0500000US21099',
    STATE: '21',
    COUNTY: '099',
    NAME: 'Hart',
    LSAD: 'County',
    CENSUSAREA: 412.086,
    FIPS: 'KY099',
    fips: '21099',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 37.29970852,
    lng: -85.88446619,
  },
  {
    GEO_ID: '0500000US21103',
    STATE: '21',
    COUNTY: '103',
    NAME: 'Henry',
    LSAD: 'County',
    CENSUSAREA: 286.276,
    FIPS: 'KY103',
    fips: '21103',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 38.44849046,
    lng: -85.11897835,
  },
  {
    GEO_ID: '0500000US21115',
    STATE: '21',
    COUNTY: '115',
    NAME: 'Johnson',
    LSAD: 'County',
    CENSUSAREA: 261.953,
    FIPS: 'KY115',
    fips: '21115',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 37.84737334,
    lng: -82.831038,
  },
  {
    GEO_ID: '0500000US21121',
    STATE: '21',
    COUNTY: '121',
    NAME: 'Knox',
    LSAD: 'County',
    CENSUSAREA: 386.297,
    FIPS: 'KY121',
    fips: '21121',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 36.89050509,
    lng: -83.8543202,
  },
  {
    GEO_ID: '0500000US21137',
    STATE: '21',
    COUNTY: '137',
    NAME: 'Lincoln',
    LSAD: 'County',
    CENSUSAREA: 334.095,
    FIPS: 'KY137',
    fips: '21137',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 37.45485594,
    lng: -84.66076388,
  },
  {
    GEO_ID: '0500000US21143',
    STATE: '21',
    COUNTY: '143',
    NAME: 'Lyon',
    LSAD: 'County',
    CENSUSAREA: 213.84,
    FIPS: 'KY143',
    fips: '21143',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 37.01910678,
    lng: -88.0827506,
  },
  {
    GEO_ID: '0500000US21151',
    STATE: '21',
    COUNTY: '151',
    NAME: 'Madison',
    LSAD: 'County',
    CENSUSAREA: 437.293,
    FIPS: 'KY151',
    fips: '21151',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 37.71989076,
    lng: -84.27808893,
  },
  {
    GEO_ID: '0500000US21157',
    STATE: '21',
    COUNTY: '157',
    NAME: 'Marshall',
    LSAD: 'County',
    CENSUSAREA: 301.253,
    FIPS: 'KY157',
    fips: '21157',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 36.88327592,
    lng: -88.32927113,
  },
  {
    GEO_ID: '0500000US21169',
    STATE: '21',
    COUNTY: '169',
    NAME: 'Metcalfe',
    LSAD: 'County',
    CENSUSAREA: 289.645,
    FIPS: 'KY169',
    fips: '21169',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 36.99052063,
    lng: -85.62919821,
  },
  {
    GEO_ID: '0500000US21175',
    STATE: '21',
    COUNTY: '175',
    NAME: 'Morgan',
    LSAD: 'County',
    CENSUSAREA: 381.127,
    FIPS: 'KY175',
    fips: '21175',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 37.92214018,
    lng: -83.25778159,
  },
  {
    GEO_ID: '0500000US19045',
    STATE: '19',
    COUNTY: '045',
    NAME: 'Clinton',
    LSAD: 'County',
    CENSUSAREA: 694.915,
    FIPS: 'IA045',
    fips: '19045',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 41.89809865,
    lng: -90.53221937,
  },
  {
    GEO_ID: '0500000US19047',
    STATE: '19',
    COUNTY: '047',
    NAME: 'Crawford',
    LSAD: 'County',
    CENSUSAREA: 714.19,
    FIPS: 'IA047',
    fips: '19047',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 42.03718237,
    lng: -95.3819718,
  },
  {
    GEO_ID: '0500000US19055',
    STATE: '19',
    COUNTY: '055',
    NAME: 'Delaware',
    LSAD: 'County',
    CENSUSAREA: 577.756,
    FIPS: 'IA055',
    fips: '19055',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 42.4713865,
    lng: -91.36741055,
  },
  {
    GEO_ID: '0500000US19063',
    STATE: '19',
    COUNTY: '063',
    NAME: 'Emmet',
    LSAD: 'County',
    CENSUSAREA: 395.883,
    FIPS: 'IA063',
    fips: '19063',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 43.37782281,
    lng: -94.67861391,
  },
  {
    GEO_ID: '0500000US19067',
    STATE: '19',
    COUNTY: '067',
    NAME: 'Floyd',
    LSAD: 'County',
    CENSUSAREA: 500.632,
    FIPS: 'IA067',
    fips: '19067',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 43.0600053,
    lng: -92.78940097,
  },
  {
    GEO_ID: '0500000US19079',
    STATE: '19',
    COUNTY: '079',
    NAME: 'Hamilton',
    LSAD: 'County',
    CENSUSAREA: 576.751,
    FIPS: 'IA079',
    fips: '19079',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 42.38395377,
    lng: -93.70672502,
  },
  {
    GEO_ID: '0500000US19087',
    STATE: '19',
    COUNTY: '087',
    NAME: 'Henry',
    LSAD: 'County',
    CENSUSAREA: 434.328,
    FIPS: 'IA087',
    fips: '19087',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 40.98760854,
    lng: -91.54434433,
  },
  {
    GEO_ID: '0500000US19095',
    STATE: '19',
    COUNTY: '095',
    NAME: 'Iowa',
    LSAD: 'County',
    CENSUSAREA: 586.457,
    FIPS: 'IA095',
    fips: '19095',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 41.68621637,
    lng: -92.06369553,
  },
  {
    GEO_ID: '0500000US19105',
    STATE: '19',
    COUNTY: '105',
    NAME: 'Jones',
    LSAD: 'County',
    CENSUSAREA: 575.621,
    FIPS: 'IA105',
    fips: '19105',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 42.12118609,
    lng: -91.13139641,
  },
  {
    GEO_ID: '0500000US19113',
    STATE: '19',
    COUNTY: '113',
    NAME: 'Linn',
    LSAD: 'County',
    CENSUSAREA: 716.88,
    FIPS: 'IA113',
    fips: '19113',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 42.07923082,
    lng: -91.59809485,
  },
  {
    GEO_ID: '0500000US19121',
    STATE: '19',
    COUNTY: '121',
    NAME: 'Madison',
    LSAD: 'County',
    CENSUSAREA: 561.006,
    FIPS: 'IA121',
    fips: '19121',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 41.33081904,
    lng: -94.01554631,
  },
  {
    GEO_ID: '0500000US19125',
    STATE: '19',
    COUNTY: '125',
    NAME: 'Marion',
    LSAD: 'County',
    CENSUSAREA: 554.534,
    FIPS: 'IA125',
    fips: '19125',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 41.33445101,
    lng: -93.09934783,
  },
  {
    GEO_ID: '0500000US19137',
    STATE: '19',
    COUNTY: '137',
    NAME: 'Montgomery',
    LSAD: 'County',
    CENSUSAREA: 424.097,
    FIPS: 'IA137',
    fips: '19137',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 41.03018714,
    lng: -95.15642896,
  },
  {
    GEO_ID: '0500000US19141',
    STATE: '19',
    COUNTY: '141',
    NAME: "O'Brien",
    LSAD: 'County',
    CENSUSAREA: 573.035,
    FIPS: 'IA141',
    fips: '19141',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 43.0836202,
    lng: -95.62474725,
  },
  {
    GEO_ID: '0500000US19151',
    STATE: '19',
    COUNTY: '151',
    NAME: 'Pocahontas',
    LSAD: 'County',
    CENSUSAREA: 577.241,
    FIPS: 'IA151',
    fips: '19151',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 42.73420133,
    lng: -94.67862564,
  },
  {
    GEO_ID: '0500000US19161',
    STATE: '19',
    COUNTY: '161',
    NAME: 'Sac',
    LSAD: 'County',
    CENSUSAREA: 575.012,
    FIPS: 'IA161',
    fips: '19161',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 42.38624071,
    lng: -95.10547892,
  },
  {
    GEO_ID: '0500000US19169',
    STATE: '19',
    COUNTY: '169',
    NAME: 'Story',
    LSAD: 'County',
    CENSUSAREA: 572.818,
    FIPS: 'IA169',
    fips: '19169',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 42.03621213,
    lng: -93.46499005,
  },
  {
    GEO_ID: '0500000US19175',
    STATE: '19',
    COUNTY: '175',
    NAME: 'Union',
    LSAD: 'County',
    CENSUSAREA: 423.645,
    FIPS: 'IA175',
    fips: '19175',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 41.0277278,
    lng: -94.24234847,
  },
  {
    GEO_ID: '0500000US19181',
    STATE: '19',
    COUNTY: '181',
    NAME: 'Warren',
    LSAD: 'County',
    CENSUSAREA: 569.827,
    FIPS: 'IA181',
    fips: '19181',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 41.33431116,
    lng: -93.56121354,
  },
  {
    GEO_ID: '0500000US19195',
    STATE: '19',
    COUNTY: '195',
    NAME: 'Worth',
    LSAD: 'County',
    CENSUSAREA: 400.123,
    FIPS: 'IA195',
    fips: '19195',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 43.37747964,
    lng: -93.26085274,
  },
  {
    GEO_ID: '0500000US20005',
    STATE: '20',
    COUNTY: '005',
    NAME: 'Atchison',
    LSAD: 'County',
    CENSUSAREA: 431.172,
    FIPS: 'KS005',
    fips: '20005',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 39.53158751,
    lng: -95.31456305,
  },
  {
    GEO_ID: '0500000US20015',
    STATE: '20',
    COUNTY: '015',
    NAME: 'Butler',
    LSAD: 'County',
    CENSUSAREA: 1429.863,
    FIPS: 'KS015',
    fips: '20015',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 37.78134351,
    lng: -96.83878537,
  },
  {
    GEO_ID: '0500000US27073',
    STATE: '27',
    COUNTY: '073',
    NAME: 'Lac qui Parle',
    LSAD: 'County',
    CENSUSAREA: 765.021,
    FIPS: 'MN073',
    fips: '27073',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 44.99583443,
    lng: -96.1738058,
  },
  {
    GEO_ID: '0500000US27083',
    STATE: '27',
    COUNTY: '083',
    NAME: 'Lyon',
    LSAD: 'County',
    CENSUSAREA: 714.559,
    FIPS: 'MN083',
    fips: '27083',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 44.41338502,
    lng: -95.83918761,
  },
  {
    GEO_ID: '0500000US27085',
    STATE: '27',
    COUNTY: '085',
    NAME: 'McLeod',
    LSAD: 'County',
    CENSUSAREA: 491.471,
    FIPS: 'MN085',
    fips: '27085',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 44.82372142,
    lng: -94.27237288,
  },
  {
    GEO_ID: '0500000US27093',
    STATE: '27',
    COUNTY: '093',
    NAME: 'Meeker',
    LSAD: 'County',
    CENSUSAREA: 608.178,
    FIPS: 'MN093',
    fips: '27093',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 45.12332877,
    lng: -94.52871063,
  },
  {
    GEO_ID: '0500000US27103',
    STATE: '27',
    COUNTY: '103',
    NAME: 'Nicollet',
    LSAD: 'County',
    CENSUSAREA: 448.494,
    FIPS: 'MN103',
    fips: '27103',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 44.34987456,
    lng: -94.24751207,
  },
  {
    GEO_ID: '0500000US27113',
    STATE: '27',
    COUNTY: '113',
    NAME: 'Pennington',
    LSAD: 'County',
    CENSUSAREA: 616.57,
    FIPS: 'MN113',
    fips: '27113',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 48.0666771,
    lng: -96.03627453,
  },
  {
    GEO_ID: '0500000US27121',
    STATE: '27',
    COUNTY: '121',
    NAME: 'Pope',
    LSAD: 'County',
    CENSUSAREA: 669.713,
    FIPS: 'MN121',
    fips: '27121',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 45.58611957,
    lng: -95.44454861,
  },
  {
    GEO_ID: '0500000US27127',
    STATE: '27',
    COUNTY: '127',
    NAME: 'Redwood',
    LSAD: 'County',
    CENSUSAREA: 878.572,
    FIPS: 'MN127',
    fips: '27127',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 44.40365018,
    lng: -95.25398841,
  },
  {
    GEO_ID: '0500000US27147',
    STATE: '27',
    COUNTY: '147',
    NAME: 'Steele',
    LSAD: 'County',
    CENSUSAREA: 429.645,
    FIPS: 'MN147',
    fips: '27147',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 44.02240351,
    lng: -93.22627088,
  },
  {
    GEO_ID: '0500000US27153',
    STATE: '27',
    COUNTY: '153',
    NAME: 'Todd',
    LSAD: 'County',
    CENSUSAREA: 944.982,
    FIPS: 'MN153',
    fips: '27153',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 46.07014445,
    lng: -94.89728962,
  },
  {
    GEO_ID: '0500000US27161',
    STATE: '27',
    COUNTY: '161',
    NAME: 'Waseca',
    LSAD: 'County',
    CENSUSAREA: 423.356,
    FIPS: 'MN161',
    fips: '27161',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 44.0220714,
    lng: -93.58720505,
  },
  {
    GEO_ID: '0500000US28003',
    STATE: '28',
    COUNTY: '003',
    NAME: 'Alcorn',
    LSAD: 'County',
    CENSUSAREA: 400.038,
    FIPS: 'MS003',
    fips: '28003',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 34.88083218,
    lng: -88.57997736,
  },
  {
    GEO_ID: '0500000US28013',
    STATE: '28',
    COUNTY: '013',
    NAME: 'Calhoun',
    LSAD: 'County',
    CENSUSAREA: 586.573,
    FIPS: 'MS013',
    fips: '28013',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 33.9364711,
    lng: -89.33648515,
  },
  {
    GEO_ID: '0500000US28017',
    STATE: '28',
    COUNTY: '017',
    NAME: 'Chickasaw',
    LSAD: 'County',
    CENSUSAREA: 501.779,
    FIPS: 'MS017',
    fips: '28017',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 33.92084863,
    lng: -88.94784369,
  },
  {
    GEO_ID: '0500000US28029',
    STATE: '28',
    COUNTY: '029',
    NAME: 'Copiah',
    LSAD: 'County',
    CENSUSAREA: 777.244,
    FIPS: 'MS029',
    fips: '28029',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 31.86883689,
    lng: -90.44883293,
  },
  {
    GEO_ID: '0500000US28037',
    STATE: '28',
    COUNTY: '037',
    NAME: 'Franklin',
    LSAD: 'County',
    CENSUSAREA: 563.779,
    FIPS: 'MS037',
    fips: '28037',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 31.47675129,
    lng: -90.89833873,
  },
  {
    GEO_ID: '0500000US28049',
    STATE: '28',
    COUNTY: '049',
    NAME: 'Hinds',
    LSAD: 'County',
    CENSUSAREA: 869.741,
    FIPS: 'MS049',
    fips: '28049',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 32.26628404,
    lng: -90.44251629,
  },
  {
    GEO_ID: '0500000US28061',
    STATE: '28',
    COUNTY: '061',
    NAME: 'Jasper',
    LSAD: 'County',
    CENSUSAREA: 676.243,
    FIPS: 'MS061',
    fips: '28061',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 32.01901366,
    lng: -89.11866575,
  },
  {
    GEO_ID: '0500000US28065',
    STATE: '28',
    COUNTY: '065',
    NAME: 'Jefferson Davis',
    LSAD: 'County',
    CENSUSAREA: 408.443,
    FIPS: 'MS065',
    fips: '28065',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 31.56973198,
    lng: -89.82311087,
  },
  {
    GEO_ID: '0500000US28075',
    STATE: '28',
    COUNTY: '075',
    NAME: 'Lauderdale',
    LSAD: 'County',
    CENSUSAREA: 703.634,
    FIPS: 'MS075',
    fips: '28075',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 32.40429857,
    lng: -88.66247435,
  },
  {
    GEO_ID: '0500000US22121',
    STATE: '22',
    COUNTY: '121',
    NAME: 'West Baton Rouge',
    LSAD: 'Parish',
    CENSUSAREA: 192.394,
    FIPS: 'LA121',
    fips: '22121',
    StateAbbr: 'LA',
    StateName: 'Louisiana',
    lat: 30.46264338,
    lng: -91.3125095,
  },
  {
    GEO_ID: '0500000US22127',
    STATE: '22',
    COUNTY: '127',
    NAME: 'Winn',
    LSAD: 'Parish',
    CENSUSAREA: 950.086,
    FIPS: 'LA127',
    fips: '22127',
    StateAbbr: 'LA',
    StateName: 'Louisiana',
    lat: 31.94438827,
    lng: -92.63657688,
  },
  {
    GEO_ID: '0500000US23009',
    STATE: '23',
    COUNTY: '009',
    NAME: 'Hancock',
    LSAD: 'County',
    CENSUSAREA: 1586.893,
    FIPS: 'ME009',
    fips: '23009',
    StateAbbr: 'ME',
    StateName: 'Maine',
    lat: 44.64373173,
    lng: -68.35862848,
  },
  {
    GEO_ID: '0500000US23029',
    STATE: '23',
    COUNTY: '029',
    NAME: 'Washington',
    LSAD: 'County',
    CENSUSAREA: 2562.66,
    FIPS: 'ME029',
    fips: '23029',
    StateAbbr: 'ME',
    StateName: 'Maine',
    lat: 45.02177186,
    lng: -67.62910779,
  },
  {
    GEO_ID: '0500000US24001',
    STATE: '24',
    COUNTY: '001',
    NAME: 'Allegany',
    LSAD: 'County',
    CENSUSAREA: 424.158,
    FIPS: 'MD001',
    fips: '24001',
    StateAbbr: 'MD',
    StateName: 'Maryland',
    lat: 39.62117263,
    lng: -78.69871136,
  },
  {
    GEO_ID: '0500000US24003',
    STATE: '24',
    COUNTY: '003',
    NAME: 'Anne Arundel',
    LSAD: 'County',
    CENSUSAREA: 414.902,
    FIPS: 'MD003',
    fips: '24003',
    StateAbbr: 'MD',
    StateName: 'Maryland',
    lat: 39.0057724,
    lng: -76.60335105,
  },
  {
    GEO_ID: '0500000US24009',
    STATE: '24',
    COUNTY: '009',
    NAME: 'Calvert',
    LSAD: 'County',
    CENSUSAREA: 213.152,
    FIPS: 'MD009',
    fips: '24009',
    StateAbbr: 'MD',
    StateName: 'Maryland',
    lat: 38.5437742,
    lng: -76.56882392,
  },
  {
    GEO_ID: '0500000US24017',
    STATE: '24',
    COUNTY: '017',
    NAME: 'Charles',
    LSAD: 'County',
    CENSUSAREA: 457.749,
    FIPS: 'MD017',
    fips: '24017',
    StateAbbr: 'MD',
    StateName: 'Maryland',
    lat: 38.50706766,
    lng: -76.9921999,
  },
  {
    GEO_ID: '0500000US24019',
    STATE: '24',
    COUNTY: '019',
    NAME: 'Dorchester',
    LSAD: 'County',
    CENSUSAREA: 540.765,
    FIPS: 'MD019',
    fips: '24019',
    StateAbbr: 'MD',
    StateName: 'Maryland',
    lat: 38.46896686,
    lng: -76.02355952,
  },
  {
    GEO_ID: '0500000US24029',
    STATE: '24',
    COUNTY: '029',
    NAME: 'Kent',
    LSAD: 'County',
    CENSUSAREA: 277.03,
    FIPS: 'MD029',
    fips: '24029',
    StateAbbr: 'MD',
    StateName: 'Maryland',
    lat: 39.25416924,
    lng: -76.0404081,
  },
  {
    GEO_ID: '0500000US24035',
    STATE: '24',
    COUNTY: '035',
    NAME: "Queen Anne's",
    LSAD: 'County',
    CENSUSAREA: 371.908,
    FIPS: 'MD035',
    fips: '24035',
    StateAbbr: 'MD',
    StateName: 'Maryland',
    lat: 39.06631908,
    lng: -76.0237387,
  },
  {
    GEO_ID: '0500000US24037',
    STATE: '24',
    COUNTY: '037',
    NAME: "St. Mary's",
    LSAD: 'County',
    CENSUSAREA: 357.18,
    FIPS: 'MD037',
    fips: '24037',
    StateAbbr: 'MD',
    StateName: 'Maryland',
    lat: 38.3016848,
    lng: -76.60613989,
  },
  {
    GEO_ID: '0500000US24039',
    STATE: '24',
    COUNTY: '039',
    NAME: 'Somerset',
    LSAD: 'County',
    CENSUSAREA: 319.723,
    FIPS: 'MD039',
    fips: '24039',
    StateAbbr: 'MD',
    StateName: 'Maryland',
    lat: 38.11381821,
    lng: -75.75887041,
  },
  {
    GEO_ID: '0500000US24041',
    STATE: '24',
    COUNTY: '041',
    NAME: 'Talbot',
    LSAD: 'County',
    CENSUSAREA: 268.538,
    FIPS: 'MD041',
    fips: '24041',
    StateAbbr: 'MD',
    StateName: 'Maryland',
    lat: 38.77026257,
    lng: -76.10341962,
  },
  {
    GEO_ID: '0500000US25011',
    STATE: '25',
    COUNTY: '011',
    NAME: 'Franklin',
    LSAD: 'County',
    CENSUSAREA: 699.319,
    FIPS: 'MA011',
    fips: '25011',
    StateAbbr: 'MA',
    StateName: 'Massachusetts',
    lat: 42.58309231,
    lng: -72.59174125,
  },
  {
    GEO_ID: '0500000US26009',
    STATE: '26',
    COUNTY: '009',
    NAME: 'Antrim',
    LSAD: 'County',
    CENSUSAREA: 475.703,
    FIPS: 'MI009',
    fips: '26009',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 44.99904124,
    lng: -85.14135875,
  },
  {
    GEO_ID: '0500000US26015',
    STATE: '26',
    COUNTY: '015',
    NAME: 'Barry',
    LSAD: 'County',
    CENSUSAREA: 553.092,
    FIPS: 'MI015',
    fips: '26015',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 42.59513984,
    lng: -85.30834519,
  },
  {
    GEO_ID: '0500000US26023',
    STATE: '26',
    COUNTY: '023',
    NAME: 'Branch',
    LSAD: 'County',
    CENSUSAREA: 506.369,
    FIPS: 'MI023',
    fips: '26023',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 41.91588904,
    lng: -85.05923475,
  },
  {
    GEO_ID: '0500000US26035',
    STATE: '26',
    COUNTY: '035',
    NAME: 'Clare',
    LSAD: 'County',
    CENSUSAREA: 564.315,
    FIPS: 'MI035',
    fips: '26035',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 43.9879198,
    lng: -84.84810751,
  },
  {
    GEO_ID: '0500000US26037',
    STATE: '26',
    COUNTY: '037',
    NAME: 'Clinton',
    LSAD: 'County',
    CENSUSAREA: 566.408,
    FIPS: 'MI037',
    fips: '26037',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 42.9439517,
    lng: -84.60138451,
  },
  {
    GEO_ID: '0500000US26045',
    STATE: '26',
    COUNTY: '045',
    NAME: 'Eaton',
    LSAD: 'County',
    CENSUSAREA: 575.175,
    FIPS: 'MI045',
    fips: '26045',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 42.59599185,
    lng: -84.83740035,
  },
  {
    GEO_ID: '0500000US26057',
    STATE: '26',
    COUNTY: '057',
    NAME: 'Gratiot',
    LSAD: 'County',
    CENSUSAREA: 568.464,
    FIPS: 'MI057',
    fips: '26057',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 43.29296993,
    lng: -84.60482908,
  },
  {
    GEO_ID: '0500000US26061',
    STATE: '26',
    COUNTY: '061',
    NAME: 'Houghton',
    LSAD: 'County',
    CENSUSAREA: 1009.099,
    FIPS: 'MI061',
    fips: '26061',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 46.89801075,
    lng: -88.68742993,
  },
  {
    GEO_ID: '0500000US26073',
    STATE: '26',
    COUNTY: '073',
    NAME: 'Isabella',
    LSAD: 'County',
    CENSUSAREA: 572.677,
    FIPS: 'MI073',
    fips: '26073',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 43.64060976,
    lng: -84.84664923,
  },
  {
    GEO_ID: '0500000US26077',
    STATE: '26',
    COUNTY: '077',
    NAME: 'Kalamazoo',
    LSAD: 'County',
    CENSUSAREA: 561.658,
    FIPS: 'MI077',
    fips: '26077',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 42.24536002,
    lng: -85.53044157,
  },
  {
    GEO_ID: '0500000US26083',
    STATE: '26',
    COUNTY: '083',
    NAME: 'Keweenaw',
    LSAD: 'County',
    CENSUSAREA: 540.112,
    FIPS: 'MI083',
    fips: '26083',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 47.62662557,
    lng: -88.43593439,
  },
  {
    GEO_ID: '0500000US26085',
    STATE: '26',
    COUNTY: '085',
    NAME: 'Lake',
    LSAD: 'County',
    CENSUSAREA: 567.37,
    FIPS: 'MI085',
    fips: '26085',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 43.99041242,
    lng: -85.80209274,
  },
  {
    GEO_ID: '0500000US26091',
    STATE: '26',
    COUNTY: '091',
    NAME: 'Lenawee',
    LSAD: 'County',
    CENSUSAREA: 749.555,
    FIPS: 'MI091',
    fips: '26091',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 41.89469406,
    lng: -84.06641246,
  },
  {
    GEO_ID: '0500000US26097',
    STATE: '26',
    COUNTY: '097',
    NAME: 'Mackinac',
    LSAD: 'County',
    CENSUSAREA: 1021.569,
    FIPS: 'MI097',
    fips: '26097',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 46.07858665,
    lng: -85.07704103,
  },
  {
    GEO_ID: '0500000US26103',
    STATE: '26',
    COUNTY: '103',
    NAME: 'Marquette',
    LSAD: 'County',
    CENSUSAREA: 1808.401,
    FIPS: 'MI103',
    fips: '26103',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 46.43139896,
    lng: -87.64138782,
  },
  {
    GEO_ID: '0500000US26107',
    STATE: '26',
    COUNTY: '107',
    NAME: 'Mecosta',
    LSAD: 'County',
    CENSUSAREA: 555.071,
    FIPS: 'MI107',
    fips: '26107',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 43.64070731,
    lng: -85.32464875,
  },
  {
    GEO_ID: '0500000US26111',
    STATE: '26',
    COUNTY: '111',
    NAME: 'Midland',
    LSAD: 'County',
    CENSUSAREA: 516.253,
    FIPS: 'MI111',
    fips: '26111',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 43.64674881,
    lng: -84.38804837,
  },
  {
    GEO_ID: '0500000US26117',
    STATE: '26',
    COUNTY: '117',
    NAME: 'Montcalm',
    LSAD: 'County',
    CENSUSAREA: 705.399,
    FIPS: 'MI117',
    fips: '26117',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 43.31058414,
    lng: -85.15230226,
  },
  {
    GEO_ID: '0500000US26123',
    STATE: '26',
    COUNTY: '123',
    NAME: 'Newaygo',
    LSAD: 'County',
    CENSUSAREA: 813.204,
    FIPS: 'MI123',
    fips: '26123',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 43.5544124,
    lng: -85.80070154,
  },
  {
    GEO_ID: '0500000US26133',
    STATE: '26',
    COUNTY: '133',
    NAME: 'Osceola',
    LSAD: 'County',
    CENSUSAREA: 566.391,
    FIPS: 'MI133',
    fips: '26133',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 43.98998373,
    lng: -85.32556471,
  },
  {
    GEO_ID: '0500000US26143',
    STATE: '26',
    COUNTY: '143',
    NAME: 'Roscommon',
    LSAD: 'County',
    CENSUSAREA: 519.636,
    FIPS: 'MI143',
    fips: '26143',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 44.33547916,
    lng: -84.61141537,
  },
  {
    GEO_ID: '0500000US26155',
    STATE: '26',
    COUNTY: '155',
    NAME: 'Shiawassee',
    LSAD: 'County',
    CENSUSAREA: 530.668,
    FIPS: 'MI155',
    fips: '26155',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 42.95402135,
    lng: -84.14662058,
  },
  {
    GEO_ID: '0500000US26161',
    STATE: '26',
    COUNTY: '161',
    NAME: 'Washtenaw',
    LSAD: 'County',
    CENSUSAREA: 705.965,
    FIPS: 'MI161',
    fips: '26161',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 42.25344402,
    lng: -83.83879891,
  },
  {
    GEO_ID: '0500000US26165',
    STATE: '26',
    COUNTY: '165',
    NAME: 'Wexford',
    LSAD: 'County',
    CENSUSAREA: 565.002,
    FIPS: 'MI165',
    fips: '26165',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 44.33841768,
    lng: -85.5781774,
  },
  {
    GEO_ID: '0500000US27009',
    STATE: '27',
    COUNTY: '009',
    NAME: 'Benton',
    LSAD: 'County',
    CENSUSAREA: 408.301,
    FIPS: 'MN009',
    fips: '27009',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 45.69827623,
    lng: -93.9984359,
  },
  {
    GEO_ID: '0500000US27019',
    STATE: '27',
    COUNTY: '019',
    NAME: 'Carver',
    LSAD: 'County',
    CENSUSAREA: 354.325,
    FIPS: 'MN019',
    fips: '27019',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 44.82089558,
    lng: -93.8027205,
  },
  {
    GEO_ID: '0500000US27029',
    STATE: '27',
    COUNTY: '029',
    NAME: 'Clearwater',
    LSAD: 'County',
    CENSUSAREA: 998.94,
    FIPS: 'MN029',
    fips: '27029',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 47.57848837,
    lng: -95.37844018,
  },
  {
    GEO_ID: '0500000US27039',
    STATE: '27',
    COUNTY: '039',
    NAME: 'Dodge',
    LSAD: 'County',
    CENSUSAREA: 439.276,
    FIPS: 'MN039',
    fips: '27039',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 44.02229546,
    lng: -92.86236786,
  },
  {
    GEO_ID: '0500000US27051',
    STATE: '27',
    COUNTY: '051',
    NAME: 'Grant',
    LSAD: 'County',
    CENSUSAREA: 548.16,
    FIPS: 'MN051',
    fips: '27051',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 45.93402352,
    lng: -96.01216405,
  },
  {
    GEO_ID: '0500000US27057',
    STATE: '27',
    COUNTY: '057',
    NAME: 'Hubbard',
    LSAD: 'County',
    CENSUSAREA: 925.675,
    FIPS: 'MN057',
    fips: '27057',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 47.10785166,
    lng: -94.91640615,
  },
  {
    GEO_ID: '0500000US27067',
    STATE: '27',
    COUNTY: '067',
    NAME: 'Kandiyohi',
    LSAD: 'County',
    CENSUSAREA: 796.785,
    FIPS: 'MN067',
    fips: '27067',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 45.15252304,
    lng: -95.00576302,
  },
  {
    GEO_ID: '0500000US29097',
    STATE: '29',
    COUNTY: '097',
    NAME: 'Jasper',
    LSAD: 'County',
    CENSUSAREA: 638.487,
    FIPS: 'MO097',
    fips: '29097',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 37.20351967,
    lng: -94.34047486,
  },
  {
    GEO_ID: '0500000US29103',
    STATE: '29',
    COUNTY: '103',
    NAME: 'Knox',
    LSAD: 'County',
    CENSUSAREA: 504.011,
    FIPS: 'MO103',
    fips: '29103',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 40.12754125,
    lng: -92.14789043,
  },
  {
    GEO_ID: '0500000US29109',
    STATE: '29',
    COUNTY: '109',
    NAME: 'Lawrence',
    LSAD: 'County',
    CENSUSAREA: 611.737,
    FIPS: 'MO109',
    fips: '29109',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 37.10627971,
    lng: -93.83291444,
  },
  {
    GEO_ID: '0500000US29117',
    STATE: '29',
    COUNTY: '117',
    NAME: 'Livingston',
    LSAD: 'County',
    CENSUSAREA: 532.329,
    FIPS: 'MO117',
    fips: '29117',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 39.78202789,
    lng: -93.54864835,
  },
  {
    GEO_ID: '0500000US29131',
    STATE: '29',
    COUNTY: '131',
    NAME: 'Miller',
    LSAD: 'County',
    CENSUSAREA: 592.595,
    FIPS: 'MO131',
    fips: '29131',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 38.21438575,
    lng: -92.42809571,
  },
  {
    GEO_ID: '0500000US29137',
    STATE: '29',
    COUNTY: '137',
    NAME: 'Monroe',
    LSAD: 'County',
    CENSUSAREA: 647.653,
    FIPS: 'MO137',
    fips: '29137',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 39.49588453,
    lng: -92.00087572,
  },
  {
    GEO_ID: '0500000US29151',
    STATE: '29',
    COUNTY: '151',
    NAME: 'Osage',
    LSAD: 'County',
    CENSUSAREA: 604.346,
    FIPS: 'MO151',
    fips: '29151',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 38.46015852,
    lng: -91.86178101,
  },
  {
    GEO_ID: '0500000US29159',
    STATE: '29',
    COUNTY: '159',
    NAME: 'Pettis',
    LSAD: 'County',
    CENSUSAREA: 682.222,
    FIPS: 'MO159',
    fips: '29159',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 38.72825898,
    lng: -93.28474912,
  },
  {
    GEO_ID: '0500000US29169',
    STATE: '29',
    COUNTY: '169',
    NAME: 'Pulaski',
    LSAD: 'County',
    CENSUSAREA: 547.099,
    FIPS: 'MO169',
    fips: '29169',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 37.82458658,
    lng: -92.20749866,
  },
  {
    GEO_ID: '0500000US29179',
    STATE: '29',
    COUNTY: '179',
    NAME: 'Reynolds',
    LSAD: 'County',
    CENSUSAREA: 808.478,
    FIPS: 'MO179',
    fips: '29179',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 37.36244864,
    lng: -90.96899002,
  },
  {
    GEO_ID: '0500000US29195',
    STATE: '29',
    COUNTY: '195',
    NAME: 'Saline',
    LSAD: 'County',
    CENSUSAREA: 755.504,
    FIPS: 'MO195',
    fips: '29195',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 39.13696633,
    lng: -93.20185128,
  },
  {
    GEO_ID: '0500000US29205',
    STATE: '29',
    COUNTY: '205',
    NAME: 'Shelby',
    LSAD: 'County',
    CENSUSAREA: 500.864,
    FIPS: 'MO205',
    fips: '29205',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 39.7979959,
    lng: -92.07658198,
  },
  {
    GEO_ID: '0500000US29211',
    STATE: '29',
    COUNTY: '211',
    NAME: 'Sullivan',
    LSAD: 'County',
    CENSUSAREA: 647.981,
    FIPS: 'MO211',
    fips: '29211',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 40.21033053,
    lng: -93.11159068,
  },
  {
    GEO_ID: '0500000US29221',
    STATE: '29',
    COUNTY: '221',
    NAME: 'Washington',
    LSAD: 'County',
    CENSUSAREA: 759.913,
    FIPS: 'MO221',
    fips: '29221',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 37.96173417,
    lng: -90.8786674,
  },
  {
    GEO_ID: '0500000US29229',
    STATE: '29',
    COUNTY: '229',
    NAME: 'Wright',
    LSAD: 'County',
    CENSUSAREA: 681.771,
    FIPS: 'MO229',
    fips: '29229',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 37.27078998,
    lng: -92.4694931,
  },
  {
    GEO_ID: '0500000US30013',
    STATE: '30',
    COUNTY: '013',
    NAME: 'Cascade',
    LSAD: 'County',
    CENSUSAREA: 2698.158,
    FIPS: 'MT013',
    fips: '30013',
    StateAbbr: 'MT',
    StateName: 'Montana',
    lat: 47.30823753,
    lng: -111.3474099,
  },
  {
    GEO_ID: '0500000US30037',
    STATE: '30',
    COUNTY: '037',
    NAME: 'Golden Valley',
    LSAD: 'County',
    CENSUSAREA: 1175.342,
    FIPS: 'MT037',
    fips: '30037',
    StateAbbr: 'MT',
    StateName: 'Montana',
    lat: 46.38114583,
    lng: -109.1749672,
  },
  {
    GEO_ID: '0500000US30043',
    STATE: '30',
    COUNTY: '043',
    NAME: 'Jefferson',
    LSAD: 'County',
    CENSUSAREA: 1656.257,
    FIPS: 'MT043',
    fips: '30043',
    StateAbbr: 'MT',
    StateName: 'Montana',
    lat: 46.14859974,
    lng: -112.0940513,
  },
  {
    GEO_ID: '0500000US31121',
    STATE: '31',
    COUNTY: '121',
    NAME: 'Merrick',
    LSAD: 'County',
    CENSUSAREA: 484.881,
    FIPS: 'NE121',
    fips: '31121',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 41.16916952,
    lng: -98.03760529,
  },
  {
    GEO_ID: '0500000US31125',
    STATE: '31',
    COUNTY: '125',
    NAME: 'Nance',
    LSAD: 'County',
    CENSUSAREA: 441.633,
    FIPS: 'NE125',
    fips: '31125',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 41.3972847,
    lng: -97.99248173,
  },
  {
    GEO_ID: '0500000US31135',
    STATE: '31',
    COUNTY: '135',
    NAME: 'Perkins',
    LSAD: 'County',
    CENSUSAREA: 883.335,
    FIPS: 'NE135',
    fips: '31135',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 40.85082266,
    lng: -101.6502998,
  },
  {
    GEO_ID: '0500000US31137',
    STATE: '31',
    COUNTY: '137',
    NAME: 'Phelps',
    LSAD: 'County',
    CENSUSAREA: 539.79,
    FIPS: 'NE137',
    fips: '31137',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 40.51115428,
    lng: -99.41426701,
  },
  {
    GEO_ID: '0500000US31147',
    STATE: '31',
    COUNTY: '147',
    NAME: 'Richardson',
    LSAD: 'County',
    CENSUSAREA: 551.84,
    FIPS: 'NE147',
    fips: '31147',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 40.12499676,
    lng: -95.71768995,
  },
  {
    GEO_ID: '0500000US31159',
    STATE: '31',
    COUNTY: '159',
    NAME: 'Seward',
    LSAD: 'County',
    CENSUSAREA: 571.428,
    FIPS: 'NE159',
    fips: '31159',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 40.8724014,
    lng: -97.13966029,
  },
  {
    GEO_ID: '0500000US31163',
    STATE: '31',
    COUNTY: '163',
    NAME: 'Sherman',
    LSAD: 'County',
    CENSUSAREA: 565.828,
    FIPS: 'NE163',
    fips: '31163',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 41.22045983,
    lng: -98.97588642,
  },
  {
    GEO_ID: '0500000US31171',
    STATE: '31',
    COUNTY: '171',
    NAME: 'Thomas',
    LSAD: 'County',
    CENSUSAREA: 713.236,
    FIPS: 'NE171',
    fips: '31171',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 41.91358968,
    lng: -100.5556632,
  },
  {
    GEO_ID: '0500000US31183',
    STATE: '31',
    COUNTY: '183',
    NAME: 'Wheeler',
    LSAD: 'County',
    CENSUSAREA: 575.182,
    FIPS: 'NE183',
    fips: '31183',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 41.91485543,
    lng: -98.52795068,
  },
  {
    GEO_ID: '0500000US32009',
    STATE: '32',
    COUNTY: '009',
    NAME: 'Esmeralda',
    LSAD: 'County',
    CENSUSAREA: 3581.876,
    FIPS: 'NV009',
    fips: '32009',
    StateAbbr: 'NV',
    StateName: 'Nevada',
    lat: 37.78456446,
    lng: -117.632191,
  },
  {
    GEO_ID: '0500000US32021',
    STATE: '32',
    COUNTY: '021',
    NAME: 'Mineral',
    LSAD: 'County',
    CENSUSAREA: 3752.844,
    FIPS: 'NV021',
    fips: '32021',
    StateAbbr: 'NV',
    StateName: 'Nevada',
    lat: 38.53880917,
    lng: -118.4346446,
  },
  {
    GEO_ID: '0500000US32033',
    STATE: '32',
    COUNTY: '033',
    NAME: 'White Pine',
    LSAD: 'County',
    CENSUSAREA: 8875.648,
    FIPS: 'NV033',
    fips: '32033',
    StateAbbr: 'NV',
    StateName: 'Nevada',
    lat: 39.44238145,
    lng: -114.9015438,
  },
  {
    GEO_ID: '0500000US34007',
    STATE: '34',
    COUNTY: '007',
    NAME: 'Camden',
    LSAD: 'County',
    CENSUSAREA: 221.263,
    FIPS: 'NJ007',
    fips: '34007',
    StateAbbr: 'NJ',
    StateName: 'New Jersey',
    lat: 39.80401748,
    lng: -74.96012063,
  },
  {
    GEO_ID: '0500000US34015',
    STATE: '34',
    COUNTY: '015',
    NAME: 'Gloucester',
    LSAD: 'County',
    CENSUSAREA: 322.005,
    FIPS: 'NJ015',
    fips: '34015',
    StateAbbr: 'NJ',
    StateName: 'New Jersey',
    lat: 39.71694144,
    lng: -75.14071747,
  },
  {
    GEO_ID: '0500000US34031',
    STATE: '34',
    COUNTY: '031',
    NAME: 'Passaic',
    LSAD: 'County',
    CENSUSAREA: 184.593,
    FIPS: 'NJ031',
    fips: '34031',
    StateAbbr: 'NJ',
    StateName: 'New Jersey',
    lat: 41.03446344,
    lng: -74.30115633,
  },
  {
    GEO_ID: '0500000US35001',
    STATE: '35',
    COUNTY: '001',
    NAME: 'Bernalillo',
    LSAD: 'County',
    CENSUSAREA: 1160.828,
    FIPS: 'NM001',
    fips: '35001',
    StateAbbr: 'NM',
    StateName: 'New Mexico',
    lat: 35.05121905,
    lng: -106.6705759,
  },
  {
    GEO_ID: '0500000US35006',
    STATE: '35',
    COUNTY: '006',
    NAME: 'Cibola',
    LSAD: 'County',
    CENSUSAREA: 4539.48,
    FIPS: 'NM006',
    fips: '35006',
    StateAbbr: 'NM',
    StateName: 'New Mexico',
    lat: 34.91240949,
    lng: -107.9992972,
  },
  {
    GEO_ID: '0500000US30051',
    STATE: '30',
    COUNTY: '051',
    NAME: 'Liberty',
    LSAD: 'County',
    CENSUSAREA: 1430.045,
    FIPS: 'MT051',
    fips: '30051',
    StateAbbr: 'MT',
    StateName: 'Montana',
    lat: 48.56122467,
    lng: -111.0252038,
  },
  {
    GEO_ID: '0500000US30059',
    STATE: '30',
    COUNTY: '059',
    NAME: 'Meagher',
    LSAD: 'County',
    CENSUSAREA: 2391.913,
    FIPS: 'MT059',
    fips: '30059',
    StateAbbr: 'MT',
    StateName: 'Montana',
    lat: 46.59797522,
    lng: -110.8856024,
  },
  {
    GEO_ID: '0500000US30069',
    STATE: '30',
    COUNTY: '069',
    NAME: 'Petroleum',
    LSAD: 'County',
    CENSUSAREA: 1654.871,
    FIPS: 'MT069',
    fips: '30069',
    StateAbbr: 'MT',
    StateName: 'Montana',
    lat: 47.11768458,
    lng: -108.2504732,
  },
  {
    GEO_ID: '0500000US30093',
    STATE: '30',
    COUNTY: '093',
    NAME: 'Silver Bow',
    LSAD: 'County',
    CENSUSAREA: 718.477,
    FIPS: 'MT093',
    fips: '30093',
    StateAbbr: 'MT',
    StateName: 'Montana',
    lat: 45.90234114,
    lng: -112.6566556,
  },
  {
    GEO_ID: '0500000US30099',
    STATE: '30',
    COUNTY: '099',
    NAME: 'Teton',
    LSAD: 'County',
    CENSUSAREA: 2272.375,
    FIPS: 'MT099',
    fips: '30099',
    StateAbbr: 'MT',
    StateName: 'Montana',
    lat: 47.83722455,
    lng: -112.2407056,
  },
  {
    GEO_ID: '0500000US30107',
    STATE: '30',
    COUNTY: '107',
    NAME: 'Wheatland',
    LSAD: 'County',
    CENSUSAREA: 1423.195,
    FIPS: 'MT107',
    fips: '30107',
    StateAbbr: 'MT',
    StateName: 'Montana',
    lat: 46.46630385,
    lng: -109.8444012,
  },
  {
    GEO_ID: '0500000US31003',
    STATE: '31',
    COUNTY: '003',
    NAME: 'Antelope',
    LSAD: 'County',
    CENSUSAREA: 857.218,
    FIPS: 'NE003',
    fips: '31003',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 42.17696571,
    lng: -98.06660249,
  },
  {
    GEO_ID: '0500000US31009',
    STATE: '31',
    COUNTY: '009',
    NAME: 'Blaine',
    LSAD: 'County',
    CENSUSAREA: 710.874,
    FIPS: 'NE009',
    fips: '31009',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 41.91311716,
    lng: -99.97677845,
  },
  {
    GEO_ID: '0500000US31023',
    STATE: '31',
    COUNTY: '023',
    NAME: 'Butler',
    LSAD: 'County',
    CENSUSAREA: 584.907,
    FIPS: 'NE023',
    fips: '31023',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 41.22599383,
    lng: -97.13156172,
  },
  {
    GEO_ID: '0500000US31035',
    STATE: '31',
    COUNTY: '035',
    NAME: 'Clay',
    LSAD: 'County',
    CENSUSAREA: 572.289,
    FIPS: 'NE035',
    fips: '31035',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 40.52439441,
    lng: -98.05126296,
  },
  {
    GEO_ID: '0500000US31037',
    STATE: '31',
    COUNTY: '037',
    NAME: 'Colfax',
    LSAD: 'County',
    CENSUSAREA: 411.66,
    FIPS: 'NE037',
    fips: '31037',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 41.57404386,
    lng: -97.086359,
  },
  {
    GEO_ID: '0500000US31047',
    STATE: '31',
    COUNTY: '047',
    NAME: 'Dawson',
    LSAD: 'County',
    CENSUSAREA: 1013.096,
    FIPS: 'NE047',
    fips: '31047',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 40.86987625,
    lng: -99.81946635,
  },
  {
    GEO_ID: '0500000US31053',
    STATE: '31',
    COUNTY: '053',
    NAME: 'Dodge',
    LSAD: 'County',
    CENSUSAREA: 528.706,
    FIPS: 'NE053',
    fips: '31053',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 41.57781563,
    lng: -96.6539044,
  },
  {
    GEO_ID: '0500000US31063',
    STATE: '31',
    COUNTY: '063',
    NAME: 'Frontier',
    LSAD: 'County',
    CENSUSAREA: 974.592,
    FIPS: 'NE063',
    fips: '31063',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 40.53024724,
    lng: -100.3936643,
  },
  {
    GEO_ID: '0500000US31073',
    STATE: '31',
    COUNTY: '073',
    NAME: 'Gosper',
    LSAD: 'County',
    CENSUSAREA: 458.155,
    FIPS: 'NE073',
    fips: '31073',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 40.51481965,
    lng: -99.83020279,
  },
  {
    GEO_ID: '0500000US31085',
    STATE: '31',
    COUNTY: '085',
    NAME: 'Hayes',
    LSAD: 'County',
    CENSUSAREA: 713.062,
    FIPS: 'NE085',
    fips: '31085',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 40.52447865,
    lng: -101.0616334,
  },
  {
    GEO_ID: '0500000US31093',
    STATE: '31',
    COUNTY: '093',
    NAME: 'Howard',
    LSAD: 'County',
    CENSUSAREA: 569.337,
    FIPS: 'NE093',
    fips: '31093',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 41.2200529,
    lng: -98.5166499,
  },
  {
    GEO_ID: '0500000US31097',
    STATE: '31',
    COUNTY: '097',
    NAME: 'Johnson',
    LSAD: 'County',
    CENSUSAREA: 376.051,
    FIPS: 'NE097',
    fips: '31097',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 40.39252282,
    lng: -96.26520163,
  },
  {
    GEO_ID: '0500000US31117',
    STATE: '31',
    COUNTY: '117',
    NAME: 'McPherson',
    LSAD: 'County',
    CENSUSAREA: 858.976,
    FIPS: 'NE117',
    fips: '31117',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 41.56796544,
    lng: -101.0603939,
  },
  {
    GEO_ID: '0500000US28079',
    STATE: '28',
    COUNTY: '079',
    NAME: 'Leake',
    LSAD: 'County',
    CENSUSAREA: 583,
    FIPS: 'MS079',
    fips: '28079',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 32.7536828,
    lng: -89.52402041,
  },
  {
    GEO_ID: '0500000US28083',
    STATE: '28',
    COUNTY: '083',
    NAME: 'Leflore',
    LSAD: 'County',
    CENSUSAREA: 592.539,
    FIPS: 'MS083',
    fips: '28083',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 33.55070467,
    lng: -90.30095715,
  },
  {
    GEO_ID: '0500000US28095',
    STATE: '28',
    COUNTY: '095',
    NAME: 'Monroe',
    LSAD: 'County',
    CENSUSAREA: 765.094,
    FIPS: 'MS095',
    fips: '28095',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 33.8922846,
    lng: -88.48037059,
  },
  {
    GEO_ID: '0500000US28099',
    STATE: '28',
    COUNTY: '099',
    NAME: 'Neshoba',
    LSAD: 'County',
    CENSUSAREA: 570.141,
    FIPS: 'MS099',
    fips: '28099',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 32.7532893,
    lng: -89.11732685,
  },
  {
    GEO_ID: '0500000US28103',
    STATE: '28',
    COUNTY: '103',
    NAME: 'Noxubee',
    LSAD: 'County',
    CENSUSAREA: 695.138,
    FIPS: 'MS103',
    fips: '28103',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 33.1101406,
    lng: -88.56962273,
  },
  {
    GEO_ID: '0500000US28115',
    STATE: '28',
    COUNTY: '115',
    NAME: 'Pontotoc',
    LSAD: 'County',
    CENSUSAREA: 497.689,
    FIPS: 'MS115',
    fips: '28115',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 34.22521097,
    lng: -89.03719509,
  },
  {
    GEO_ID: '0500000US28117',
    STATE: '28',
    COUNTY: '117',
    NAME: 'Prentiss',
    LSAD: 'County',
    CENSUSAREA: 414.98,
    FIPS: 'MS117',
    fips: '28117',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 34.61811436,
    lng: -88.51976883,
  },
  {
    GEO_ID: '0500000US28127',
    STATE: '28',
    COUNTY: '127',
    NAME: 'Simpson',
    LSAD: 'County',
    CENSUSAREA: 589.163,
    FIPS: 'MS127',
    fips: '28127',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 31.9133629,
    lng: -89.9199315,
  },
  {
    GEO_ID: '0500000US28139',
    STATE: '28',
    COUNTY: '139',
    NAME: 'Tippah',
    LSAD: 'County',
    CENSUSAREA: 457.815,
    FIPS: 'MS139',
    fips: '28139',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 34.76830318,
    lng: -88.90883067,
  },
  {
    GEO_ID: '0500000US28151',
    STATE: '28',
    COUNTY: '151',
    NAME: 'Washington',
    LSAD: 'County',
    CENSUSAREA: 724.741,
    FIPS: 'MS151',
    fips: '28151',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 33.28374517,
    lng: -90.94787195,
  },
  {
    GEO_ID: '0500000US29001',
    STATE: '29',
    COUNTY: '001',
    NAME: 'Adair',
    LSAD: 'County',
    CENSUSAREA: 567.324,
    FIPS: 'MO001',
    fips: '29001',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 40.19054871,
    lng: -92.6007527,
  },
  {
    GEO_ID: '0500000US29009',
    STATE: '29',
    COUNTY: '009',
    NAME: 'Barry',
    LSAD: 'County',
    CENSUSAREA: 778.251,
    FIPS: 'MO009',
    fips: '29009',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 36.70980207,
    lng: -93.82898627,
  },
  {
    GEO_ID: '0500000US29021',
    STATE: '29',
    COUNTY: '021',
    NAME: 'Buchanan',
    LSAD: 'County',
    CENSUSAREA: 408.027,
    FIPS: 'MO021',
    fips: '29021',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 39.65989328,
    lng: -94.80618288,
  },
  {
    GEO_ID: '0500000US29033',
    STATE: '29',
    COUNTY: '033',
    NAME: 'Carroll',
    LSAD: 'County',
    CENSUSAREA: 694.621,
    FIPS: 'MO033',
    fips: '29033',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 39.42694823,
    lng: -93.50521161,
  },
  {
    GEO_ID: '0500000US29043',
    STATE: '29',
    COUNTY: '043',
    NAME: 'Christian',
    LSAD: 'County',
    CENSUSAREA: 562.645,
    FIPS: 'MO043',
    fips: '29043',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 36.96906632,
    lng: -93.18847844,
  },
  {
    GEO_ID: '0500000US29053',
    STATE: '29',
    COUNTY: '053',
    NAME: 'Cooper',
    LSAD: 'County',
    CENSUSAREA: 564.765,
    FIPS: 'MO053',
    fips: '29053',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 38.84333073,
    lng: -92.80985416,
  },
  {
    GEO_ID: '0500000US29065',
    STATE: '29',
    COUNTY: '065',
    NAME: 'Dent',
    LSAD: 'County',
    CENSUSAREA: 752.79,
    FIPS: 'MO065',
    fips: '29065',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 37.60643508,
    lng: -91.50772249,
  },
  {
    GEO_ID: '0500000US29075',
    STATE: '29',
    COUNTY: '075',
    NAME: 'Gentry',
    LSAD: 'County',
    CENSUSAREA: 491.422,
    FIPS: 'MO075',
    fips: '29075',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 40.21170604,
    lng: -94.4096279,
  },
  {
    GEO_ID: '0500000US29085',
    STATE: '29',
    COUNTY: '085',
    NAME: 'Hickory',
    LSAD: 'County',
    CENSUSAREA: 399.091,
    FIPS: 'MO085',
    fips: '29085',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 37.94153217,
    lng: -93.32051495,
  },
  {
    GEO_ID: '0500000US37001',
    STATE: '37',
    COUNTY: '001',
    NAME: 'Alamance',
    LSAD: 'County',
    CENSUSAREA: 423.943,
    FIPS: 'NC001',
    fips: '37001',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 36.04378576,
    lng: -79.39913194,
  },
  {
    GEO_ID: '0500000US37005',
    STATE: '37',
    COUNTY: '005',
    NAME: 'Alleghany',
    LSAD: 'County',
    CENSUSAREA: 235.059,
    FIPS: 'NC005',
    fips: '37005',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 36.49135989,
    lng: -81.1277104,
  },
  {
    GEO_ID: '0500000US37017',
    STATE: '37',
    COUNTY: '017',
    NAME: 'Bladen',
    LSAD: 'County',
    CENSUSAREA: 874.328,
    FIPS: 'NC017',
    fips: '37017',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 34.61418037,
    lng: -78.56283195,
  },
  {
    GEO_ID: '0500000US37021',
    STATE: '37',
    COUNTY: '021',
    NAME: 'Buncombe',
    LSAD: 'County',
    CENSUSAREA: 656.672,
    FIPS: 'NC021',
    fips: '37021',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 35.6112775,
    lng: -82.53035424,
  },
  {
    GEO_ID: '0500000US37027',
    STATE: '37',
    COUNTY: '027',
    NAME: 'Caldwell',
    LSAD: 'County',
    CENSUSAREA: 471.566,
    FIPS: 'NC027',
    fips: '37027',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 35.95288519,
    lng: -81.5469666,
  },
  {
    GEO_ID: '0500000US37055',
    STATE: '37',
    COUNTY: '055',
    NAME: 'Dare',
    LSAD: 'County',
    CENSUSAREA: 383.42,
    FIPS: 'NC055',
    fips: '37055',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 35.7632084,
    lng: -75.78245555,
  },
  {
    GEO_ID: '0500000US37057',
    STATE: '37',
    COUNTY: '057',
    NAME: 'Davidson',
    LSAD: 'County',
    CENSUSAREA: 552.675,
    FIPS: 'NC057',
    fips: '37057',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 35.79311799,
    lng: -80.21253309,
  },
  {
    GEO_ID: '0500000US37067',
    STATE: '37',
    COUNTY: '067',
    NAME: 'Forsyth',
    LSAD: 'County',
    CENSUSAREA: 408.148,
    FIPS: 'NC067',
    fips: '37067',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 36.13057844,
    lng: -80.25626566,
  },
  {
    GEO_ID: '0500000US37081',
    STATE: '37',
    COUNTY: '081',
    NAME: 'Guilford',
    LSAD: 'County',
    CENSUSAREA: 645.704,
    FIPS: 'NC081',
    fips: '37081',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 36.07964464,
    lng: -79.78852575,
  },
  {
    GEO_ID: '0500000US37091',
    STATE: '37',
    COUNTY: '091',
    NAME: 'Hertford',
    LSAD: 'County',
    CENSUSAREA: 353.06,
    FIPS: 'NC091',
    fips: '37091',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 36.35892018,
    lng: -76.98162611,
  },
  {
    GEO_ID: '0500000US37103',
    STATE: '37',
    COUNTY: '103',
    NAME: 'Jones',
    LSAD: 'County',
    CENSUSAREA: 470.708,
    FIPS: 'NC103',
    fips: '37103',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 35.02185042,
    lng: -77.35562096,
  },
  {
    GEO_ID: '0500000US37117',
    STATE: '37',
    COUNTY: '117',
    NAME: 'Martin',
    LSAD: 'County',
    CENSUSAREA: 461.217,
    FIPS: 'NC117',
    fips: '37117',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 35.84087616,
    lng: -77.10604281,
  },
  {
    GEO_ID: '0500000US37127',
    STATE: '37',
    COUNTY: '127',
    NAME: 'Nash',
    LSAD: 'County',
    CENSUSAREA: 540.407,
    FIPS: 'NC127',
    fips: '37127',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 35.96771851,
    lng: -77.9861842,
  },
  {
    GEO_ID: '0500000US37141',
    STATE: '37',
    COUNTY: '141',
    NAME: 'Pender',
    LSAD: 'County',
    CENSUSAREA: 869.795,
    FIPS: 'NC141',
    fips: '37141',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 34.52447523,
    lng: -77.90598182,
  },
  {
    GEO_ID: '0500000US37151',
    STATE: '37',
    COUNTY: '151',
    NAME: 'Randolph',
    LSAD: 'County',
    CENSUSAREA: 782.517,
    FIPS: 'NC151',
    fips: '37151',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 35.71023333,
    lng: -79.8058818,
  },
  {
    GEO_ID: '0500000US37165',
    STATE: '37',
    COUNTY: '165',
    NAME: 'Scotland',
    LSAD: 'County',
    CENSUSAREA: 318.845,
    FIPS: 'NC165',
    fips: '37165',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 34.84114162,
    lng: -79.48032175,
  },
  {
    GEO_ID: '0500000US37167',
    STATE: '37',
    COUNTY: '167',
    NAME: 'Stanly',
    LSAD: 'County',
    CENSUSAREA: 395.086,
    FIPS: 'NC167',
    fips: '37167',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 35.31173842,
    lng: -80.25088954,
  },
  {
    GEO_ID: '0500000US39055',
    STATE: '39',
    COUNTY: '055',
    NAME: 'Geauga',
    LSAD: 'County',
    CENSUSAREA: 400.164,
    FIPS: 'OH055',
    fips: '39055',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 41.49972479,
    lng: -81.17872133,
  },
  {
    GEO_ID: '0500000US39063',
    STATE: '39',
    COUNTY: '063',
    NAME: 'Hancock',
    LSAD: 'County',
    CENSUSAREA: 531.358,
    FIPS: 'OH063',
    fips: '39063',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 41.00178146,
    lng: -83.66630827,
  },
  {
    GEO_ID: '0500000US39065',
    STATE: '39',
    COUNTY: '065',
    NAME: 'Hardin',
    LSAD: 'County',
    CENSUSAREA: 470.405,
    FIPS: 'OH065',
    fips: '39065',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 40.66129428,
    lng: -83.65939867,
  },
  {
    GEO_ID: '0500000US39075',
    STATE: '39',
    COUNTY: '075',
    NAME: 'Holmes',
    LSAD: 'County',
    CENSUSAREA: 422.533,
    FIPS: 'OH075',
    fips: '39075',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 40.5614819,
    lng: -81.93067102,
  },
  {
    GEO_ID: '0500000US39089',
    STATE: '39',
    COUNTY: '089',
    NAME: 'Licking',
    LSAD: 'County',
    CENSUSAREA: 682.5,
    FIPS: 'OH089',
    fips: '39089',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 40.09170112,
    lng: -82.48290477,
  },
  {
    GEO_ID: '0500000US39097',
    STATE: '39',
    COUNTY: '097',
    NAME: 'Madison',
    LSAD: 'County',
    CENSUSAREA: 465.875,
    FIPS: 'OH097',
    fips: '39097',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 39.89429692,
    lng: -83.40012747,
  },
  {
    GEO_ID: '0500000US39109',
    STATE: '39',
    COUNTY: '109',
    NAME: 'Miami',
    LSAD: 'County',
    CENSUSAREA: 406.58,
    FIPS: 'OH109',
    fips: '39109',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 40.05414393,
    lng: -84.22868895,
  },
  {
    GEO_ID: '0500000US39121',
    STATE: '39',
    COUNTY: '121',
    NAME: 'Noble',
    LSAD: 'County',
    CENSUSAREA: 398.012,
    FIPS: 'OH121',
    fips: '39121',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 39.7660752,
    lng: -81.45612561,
  },
  {
    GEO_ID: '0500000US39131',
    STATE: '39',
    COUNTY: '131',
    NAME: 'Pike',
    LSAD: 'County',
    CENSUSAREA: 440.282,
    FIPS: 'OH131',
    fips: '39131',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 39.07705515,
    lng: -83.06678951,
  },
  {
    GEO_ID: '0500000US39143',
    STATE: '39',
    COUNTY: '143',
    NAME: 'Sandusky',
    LSAD: 'County',
    CENSUSAREA: 408.453,
    FIPS: 'OH143',
    fips: '39143',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 41.35730372,
    lng: -83.14419942,
  },
  {
    GEO_ID: '0500000US39147',
    STATE: '39',
    COUNTY: '147',
    NAME: 'Seneca',
    LSAD: 'County',
    CENSUSAREA: 551.017,
    FIPS: 'OH147',
    fips: '39147',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 41.12378254,
    lng: -83.12759613,
  },
  {
    GEO_ID: '0500000US39159',
    STATE: '39',
    COUNTY: '159',
    NAME: 'Union',
    LSAD: 'County',
    CENSUSAREA: 431.73,
    FIPS: 'OH159',
    fips: '39159',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 40.29926661,
    lng: -83.37130174,
  },
  {
    GEO_ID: '0500000US39169',
    STATE: '39',
    COUNTY: '169',
    NAME: 'Wayne',
    LSAD: 'County',
    CENSUSAREA: 554.929,
    FIPS: 'OH169',
    fips: '39169',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 40.82926705,
    lng: -81.88780787,
  },
  {
    GEO_ID: '0500000US39173',
    STATE: '39',
    COUNTY: '173',
    NAME: 'Wood',
    LSAD: 'County',
    CENSUSAREA: 617.205,
    FIPS: 'OH173',
    fips: '39173',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 41.36160162,
    lng: -83.62311366,
  },
  {
    GEO_ID: '0500000US40009',
    STATE: '40',
    COUNTY: '009',
    NAME: 'Beckham',
    LSAD: 'County',
    CENSUSAREA: 901.805,
    FIPS: 'OK009',
    fips: '40009',
    StateAbbr: 'OK',
    StateName: 'Oklahoma',
    lat: 35.26792649,
    lng: -99.68042577,
  },
  {
    GEO_ID: '0500000US40019',
    STATE: '40',
    COUNTY: '019',
    NAME: 'Carter',
    LSAD: 'County',
    CENSUSAREA: 822.175,
    FIPS: 'OK019',
    fips: '40019',
    StateAbbr: 'OK',
    StateName: 'Oklahoma',
    lat: 34.25090173,
    lng: -97.28589218,
  },
  {
    GEO_ID: '0500000US40031',
    STATE: '40',
    COUNTY: '031',
    NAME: 'Comanche',
    LSAD: 'County',
    CENSUSAREA: 1069.287,
    FIPS: 'OK031',
    fips: '40031',
    StateAbbr: 'OK',
    StateName: 'Oklahoma',
    lat: 34.6620099,
    lng: -98.47164344,
  },
  {
    GEO_ID: '0500000US40043',
    STATE: '40',
    COUNTY: '043',
    NAME: 'Dewey',
    LSAD: 'County',
    CENSUSAREA: 999.476,
    FIPS: 'OK043',
    fips: '40043',
    StateAbbr: 'OK',
    StateName: 'Oklahoma',
    lat: 35.98768882,
    lng: -99.00647121,
  },
  {
    GEO_ID: '0500000US40053',
    STATE: '40',
    COUNTY: '053',
    NAME: 'Grant',
    LSAD: 'County',
    CENSUSAREA: 1000.871,
    FIPS: 'OK053',
    fips: '40053',
    StateAbbr: 'OK',
    StateName: 'Oklahoma',
    lat: 36.79651364,
    lng: -97.78493404,
  },
  {
    GEO_ID: '0500000US37181',
    STATE: '37',
    COUNTY: '181',
    NAME: 'Vance',
    LSAD: 'County',
    CENSUSAREA: 253.517,
    FIPS: 'NC181',
    fips: '37181',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 36.36465914,
    lng: -78.40836693,
  },
  {
    GEO_ID: '0500000US37191',
    STATE: '37',
    COUNTY: '191',
    NAME: 'Wayne',
    LSAD: 'County',
    CENSUSAREA: 553.087,
    FIPS: 'NC191',
    fips: '37191',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 35.36389637,
    lng: -78.00407767,
  },
  {
    GEO_ID: '0500000US37197',
    STATE: '37',
    COUNTY: '197',
    NAME: 'Yadkin',
    LSAD: 'County',
    CENSUSAREA: 334.829,
    FIPS: 'NC197',
    fips: '37197',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 36.1604416,
    lng: -80.66532457,
  },
  {
    GEO_ID: '0500000US38007',
    STATE: '38',
    COUNTY: '007',
    NAME: 'Billings',
    LSAD: 'County',
    CENSUSAREA: 1148.853,
    FIPS: 'ND007',
    fips: '38007',
    StateAbbr: 'ND',
    StateName: 'North Dakota',
    lat: 47.02366884,
    lng: -103.3762965,
  },
  {
    GEO_ID: '0500000US38019',
    STATE: '38',
    COUNTY: '019',
    NAME: 'Cavalier',
    LSAD: 'County',
    CENSUSAREA: 1488.751,
    FIPS: 'ND019',
    fips: '38019',
    StateAbbr: 'ND',
    StateName: 'North Dakota',
    lat: 48.77240346,
    lng: -98.46469965,
  },
  {
    GEO_ID: '0500000US38031',
    STATE: '38',
    COUNTY: '031',
    NAME: 'Foster',
    LSAD: 'County',
    CENSUSAREA: 635.453,
    FIPS: 'ND031',
    fips: '38031',
    StateAbbr: 'ND',
    StateName: 'North Dakota',
    lat: 47.4571084,
    lng: -98.88279923,
  },
  {
    GEO_ID: '0500000US38035',
    STATE: '38',
    COUNTY: '035',
    NAME: 'Grand Forks',
    LSAD: 'County',
    CENSUSAREA: 1436.376,
    FIPS: 'ND035',
    fips: '38035',
    StateAbbr: 'ND',
    StateName: 'North Dakota',
    lat: 47.92166578,
    lng: -97.45708074,
  },
  {
    GEO_ID: '0500000US38045',
    STATE: '38',
    COUNTY: '045',
    NAME: 'LaMoure',
    LSAD: 'County',
    CENSUSAREA: 1145.943,
    FIPS: 'ND045',
    fips: '38045',
    StateAbbr: 'ND',
    StateName: 'North Dakota',
    lat: 46.45686514,
    lng: -98.53552136,
  },
  {
    GEO_ID: '0500000US38061',
    STATE: '38',
    COUNTY: '061',
    NAME: 'Mountrail',
    LSAD: 'County',
    CENSUSAREA: 1825.298,
    FIPS: 'ND061',
    fips: '38061',
    StateAbbr: 'ND',
    StateName: 'North Dakota',
    lat: 48.20129569,
    lng: -102.3557043,
  },
  {
    GEO_ID: '0500000US38073',
    STATE: '38',
    COUNTY: '073',
    NAME: 'Ransom',
    LSAD: 'County',
    CENSUSAREA: 862.357,
    FIPS: 'ND073',
    fips: '38073',
    StateAbbr: 'ND',
    StateName: 'North Dakota',
    lat: 46.45621782,
    lng: -97.65742504,
  },
  {
    GEO_ID: '0500000US38079',
    STATE: '38',
    COUNTY: '079',
    NAME: 'Rolette',
    LSAD: 'County',
    CENSUSAREA: 903.079,
    FIPS: 'ND079',
    fips: '38079',
    StateAbbr: 'ND',
    StateName: 'North Dakota',
    lat: 48.77247566,
    lng: -99.84096449,
  },
  {
    GEO_ID: '0500000US38091',
    STATE: '38',
    COUNTY: '091',
    NAME: 'Steele',
    LSAD: 'County',
    CENSUSAREA: 712.215,
    FIPS: 'ND091',
    fips: '38091',
    StateAbbr: 'ND',
    StateName: 'North Dakota',
    lat: 47.45618207,
    lng: -97.72479507,
  },
  {
    GEO_ID: '0500000US38103',
    STATE: '38',
    COUNTY: '103',
    NAME: 'Wells',
    LSAD: 'County',
    CENSUSAREA: 1271.047,
    FIPS: 'ND103',
    fips: '38103',
    StateAbbr: 'ND',
    StateName: 'North Dakota',
    lat: 47.58750012,
    lng: -99.66092922,
  },
  {
    GEO_ID: '0500000US39003',
    STATE: '39',
    COUNTY: '003',
    NAME: 'Allen',
    LSAD: 'County',
    CENSUSAREA: 402.496,
    FIPS: 'OH003',
    fips: '39003',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 40.77151716,
    lng: -84.10584177,
  },
  {
    GEO_ID: '0500000US39015',
    STATE: '39',
    COUNTY: '015',
    NAME: 'Brown',
    LSAD: 'County',
    CENSUSAREA: 490.016,
    FIPS: 'OH015',
    fips: '39015',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 38.93410315,
    lng: -83.86741596,
  },
  {
    GEO_ID: '0500000US39027',
    STATE: '39',
    COUNTY: '027',
    NAME: 'Clinton',
    LSAD: 'County',
    CENSUSAREA: 408.684,
    FIPS: 'OH027',
    fips: '39027',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 39.41489655,
    lng: -83.80825529,
  },
  {
    GEO_ID: '0500000US39039',
    STATE: '39',
    COUNTY: '039',
    NAME: 'Defiance',
    LSAD: 'County',
    CENSUSAREA: 411.46,
    FIPS: 'OH039',
    fips: '39039',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 41.3239854,
    lng: -84.49071987,
  },
  {
    GEO_ID: '0500000US39047',
    STATE: '39',
    COUNTY: '047',
    NAME: 'Fayette',
    LSAD: 'County',
    CENSUSAREA: 406.357,
    FIPS: 'OH047',
    fips: '39047',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 39.56023827,
    lng: -83.4560853,
  },
  {
    GEO_ID: '0500000US35015',
    STATE: '35',
    COUNTY: '015',
    NAME: 'Eddy',
    LSAD: 'County',
    CENSUSAREA: 4175.726,
    FIPS: 'NM015',
    fips: '35015',
    StateAbbr: 'NM',
    StateName: 'New Mexico',
    lat: 32.47155056,
    lng: -104.3041733,
  },
  {
    GEO_ID: '0500000US35027',
    STATE: '35',
    COUNTY: '027',
    NAME: 'Lincoln',
    LSAD: 'County',
    CENSUSAREA: 4831.091,
    FIPS: 'NM027',
    fips: '35027',
    StateAbbr: 'NM',
    StateName: 'New Mexico',
    lat: 33.74510826,
    lng: -105.4585461,
  },
  {
    GEO_ID: '0500000US35028',
    STATE: '35',
    COUNTY: '028',
    NAME: 'Los Alamos',
    LSAD: 'County',
    CENSUSAREA: 109.167,
    FIPS: 'NM028',
    fips: '35028',
    StateAbbr: 'NM',
    StateName: 'New Mexico',
    lat: 35.86926339,
    lng: -106.3065081,
  },
  {
    GEO_ID: '0500000US35045',
    STATE: '35',
    COUNTY: '045',
    NAME: 'San Juan',
    LSAD: 'County',
    CENSUSAREA: 5513.067,
    FIPS: 'NM045',
    fips: '35045',
    StateAbbr: 'NM',
    StateName: 'New Mexico',
    lat: 36.50824605,
    lng: -108.3206012,
  },
  {
    GEO_ID: '0500000US35053',
    STATE: '35',
    COUNTY: '053',
    NAME: 'Socorro',
    LSAD: 'County',
    CENSUSAREA: 6646.679,
    FIPS: 'NM053',
    fips: '35053',
    StateAbbr: 'NM',
    StateName: 'New Mexico',
    lat: 34.00682919,
    lng: -106.9307263,
  },
  {
    GEO_ID: '0500000US36003',
    STATE: '36',
    COUNTY: '003',
    NAME: 'Allegany',
    LSAD: 'County',
    CENSUSAREA: 1029.308,
    FIPS: 'NY003',
    fips: '36003',
    StateAbbr: 'NY',
    StateName: 'New York',
    lat: 42.25731952,
    lng: -78.02730689,
  },
  {
    GEO_ID: '0500000US36021',
    STATE: '36',
    COUNTY: '021',
    NAME: 'Columbia',
    LSAD: 'County',
    CENSUSAREA: 634.705,
    FIPS: 'NY021',
    fips: '36021',
    StateAbbr: 'NY',
    StateName: 'New York',
    lat: 42.25016297,
    lng: -73.63186276,
  },
  {
    GEO_ID: '0500000US36037',
    STATE: '36',
    COUNTY: '037',
    NAME: 'Genesee',
    LSAD: 'County',
    CENSUSAREA: 492.936,
    FIPS: 'NY037',
    fips: '36037',
    StateAbbr: 'NY',
    StateName: 'New York',
    lat: 43.00056703,
    lng: -78.19407819,
  },
  {
    GEO_ID: '0500000US36043',
    STATE: '36',
    COUNTY: '043',
    NAME: 'Herkimer',
    LSAD: 'County',
    CENSUSAREA: 1411.47,
    FIPS: 'NY043',
    fips: '36043',
    StateAbbr: 'NY',
    StateName: 'New York',
    lat: 43.41936241,
    lng: -74.96255405,
  },
  {
    GEO_ID: '0500000US36057',
    STATE: '36',
    COUNTY: '057',
    NAME: 'Montgomery',
    LSAD: 'County',
    CENSUSAREA: 403.043,
    FIPS: 'NY057',
    fips: '36057',
    StateAbbr: 'NY',
    StateName: 'New York',
    lat: 42.90217659,
    lng: -74.43985937,
  },
  {
    GEO_ID: '0500000US36069',
    STATE: '36',
    COUNTY: '069',
    NAME: 'Ontario',
    LSAD: 'County',
    CENSUSAREA: 644.065,
    FIPS: 'NY069',
    fips: '36069',
    StateAbbr: 'NY',
    StateName: 'New York',
    lat: 42.85304122,
    lng: -77.29986178,
  },
  {
    GEO_ID: '0500000US36083',
    STATE: '36',
    COUNTY: '083',
    NAME: 'Rensselaer',
    LSAD: 'County',
    CENSUSAREA: 652.431,
    FIPS: 'NY083',
    fips: '36083',
    StateAbbr: 'NY',
    StateName: 'New York',
    lat: 42.71103722,
    lng: -73.50952678,
  },
  {
    GEO_ID: '0500000US36095',
    STATE: '36',
    COUNTY: '095',
    NAME: 'Schoharie',
    LSAD: 'County',
    CENSUSAREA: 621.819,
    FIPS: 'NY095',
    fips: '36095',
    StateAbbr: 'NY',
    StateName: 'New York',
    lat: 42.58814833,
    lng: -74.44200509,
  },
  {
    GEO_ID: '0500000US36109',
    STATE: '36',
    COUNTY: '109',
    NAME: 'Tompkins',
    LSAD: 'County',
    CENSUSAREA: 474.649,
    FIPS: 'NY109',
    fips: '36109',
    StateAbbr: 'NY',
    StateName: 'New York',
    lat: 42.45222263,
    lng: -76.47381763,
  },
  {
    GEO_ID: '0500000US36121',
    STATE: '36',
    COUNTY: '121',
    NAME: 'Wyoming',
    LSAD: 'County',
    CENSUSAREA: 592.746,
    FIPS: 'NY121',
    fips: '36121',
    StateAbbr: 'NY',
    StateName: 'New York',
    lat: 42.70226414,
    lng: -78.2237766,
  },
  {
    GEO_ID: '0500000US36123',
    STATE: '36',
    COUNTY: '123',
    NAME: 'Yates',
    LSAD: 'County',
    CENSUSAREA: 338.143,
    FIPS: 'NY123',
    fips: '36123',
    StateAbbr: 'NY',
    StateName: 'New York',
    lat: 42.63362823,
    lng: -77.10558958,
  },
  {
    GEO_ID: '0500000US42013',
    STATE: '42',
    COUNTY: '013',
    NAME: 'Blair',
    LSAD: 'County',
    CENSUSAREA: 525.8,
    FIPS: 'PA013',
    fips: '42013',
    StateAbbr: 'PA',
    StateName: 'Pennsylvania',
    lat: 40.48075545,
    lng: -78.34882696,
  },
  {
    GEO_ID: '0500000US42015',
    STATE: '42',
    COUNTY: '015',
    NAME: 'Bradford',
    LSAD: 'County',
    CENSUSAREA: 1147.399,
    FIPS: 'PA015',
    fips: '42015',
    StateAbbr: 'PA',
    StateName: 'Pennsylvania',
    lat: 41.78867556,
    lng: -76.515651,
  },
  {
    GEO_ID: '0500000US42021',
    STATE: '42',
    COUNTY: '021',
    NAME: 'Cambria',
    LSAD: 'County',
    CENSUSAREA: 688.351,
    FIPS: 'PA021',
    fips: '42021',
    StateAbbr: 'PA',
    StateName: 'Pennsylvania',
    lat: 40.49518786,
    lng: -78.71388554,
  },
  {
    GEO_ID: '0500000US42031',
    STATE: '42',
    COUNTY: '031',
    NAME: 'Clarion',
    LSAD: 'County',
    CENSUSAREA: 600.834,
    FIPS: 'PA031',
    fips: '42031',
    StateAbbr: 'PA',
    StateName: 'Pennsylvania',
    lat: 41.19261355,
    lng: -79.42093979,
  },
  {
    GEO_ID: '0500000US42047',
    STATE: '42',
    COUNTY: '047',
    NAME: 'Elk',
    LSAD: 'County',
    CENSUSAREA: 827.358,
    FIPS: 'PA047',
    fips: '42047',
    StateAbbr: 'PA',
    StateName: 'Pennsylvania',
    lat: 41.42548728,
    lng: -78.64916765,
  },
  {
    GEO_ID: '0500000US42063',
    STATE: '42',
    COUNTY: '063',
    NAME: 'Indiana',
    LSAD: 'County',
    CENSUSAREA: 827.03,
    FIPS: 'PA063',
    fips: '42063',
    StateAbbr: 'PA',
    StateName: 'Pennsylvania',
    lat: 40.65207806,
    lng: -79.08763255,
  },
  {
    GEO_ID: '0500000US42069',
    STATE: '42',
    COUNTY: '069',
    NAME: 'Lackawanna',
    LSAD: 'County',
    CENSUSAREA: 459.078,
    FIPS: 'PA069',
    fips: '42069',
    StateAbbr: 'PA',
    StateName: 'Pennsylvania',
    lat: 41.43666224,
    lng: -75.60919397,
  },
  {
    GEO_ID: '0500000US42087',
    STATE: '42',
    COUNTY: '087',
    NAME: 'Mifflin',
    LSAD: 'County',
    CENSUSAREA: 411.031,
    FIPS: 'PA087',
    fips: '42087',
    StateAbbr: 'PA',
    StateName: 'Pennsylvania',
    lat: 40.61051314,
    lng: -77.6170294,
  },
  {
    GEO_ID: '0500000US42093',
    STATE: '42',
    COUNTY: '093',
    NAME: 'Montour',
    LSAD: 'County',
    CENSUSAREA: 130.242,
    FIPS: 'PA093',
    fips: '42093',
    StateAbbr: 'PA',
    StateName: 'Pennsylvania',
    lat: 41.02752391,
    lng: -76.65904667,
  },
  {
    GEO_ID: '0500000US42105',
    STATE: '42',
    COUNTY: '105',
    NAME: 'Potter',
    LSAD: 'County',
    CENSUSAREA: 1081.323,
    FIPS: 'PA105',
    fips: '42105',
    StateAbbr: 'PA',
    StateName: 'Pennsylvania',
    lat: 41.74480517,
    lng: -77.89566563,
  },
  {
    GEO_ID: '0500000US42113',
    STATE: '42',
    COUNTY: '113',
    NAME: 'Sullivan',
    LSAD: 'County',
    CENSUSAREA: 449.94,
    FIPS: 'PA113',
    fips: '42113',
    StateAbbr: 'PA',
    StateName: 'Pennsylvania',
    lat: 41.44598276,
    lng: -76.51238042,
  },
  {
    GEO_ID: '0500000US42123',
    STATE: '42',
    COUNTY: '123',
    NAME: 'Warren',
    LSAD: 'County',
    CENSUSAREA: 884.135,
    FIPS: 'PA123',
    fips: '42123',
    StateAbbr: 'PA',
    StateName: 'Pennsylvania',
    lat: 41.81377056,
    lng: -79.2740453,
  },
  {
    GEO_ID: '0500000US45005',
    STATE: '45',
    COUNTY: '005',
    NAME: 'Allendale',
    LSAD: 'County',
    CENSUSAREA: 408.09,
    FIPS: 'SC005',
    fips: '45005',
    StateAbbr: 'SC',
    StateName: 'South Carolina',
    lat: 32.98833364,
    lng: -81.35758839,
  },
  {
    GEO_ID: '0500000US45009',
    STATE: '45',
    COUNTY: '009',
    NAME: 'Bamberg',
    LSAD: 'County',
    CENSUSAREA: 393.37,
    FIPS: 'SC009',
    fips: '45009',
    StateAbbr: 'SC',
    StateName: 'South Carolina',
    lat: 33.21508217,
    lng: -81.05432857,
  },
  {
    GEO_ID: '0500000US45027',
    STATE: '45',
    COUNTY: '027',
    NAME: 'Clarendon',
    LSAD: 'County',
    CENSUSAREA: 606.936,
    FIPS: 'SC027',
    fips: '45027',
    StateAbbr: 'SC',
    StateName: 'South Carolina',
    lat: 33.66542745,
    lng: -80.21662078,
  },
  {
    GEO_ID: '0500000US45039',
    STATE: '45',
    COUNTY: '039',
    NAME: 'Fairfield',
    LSAD: 'County',
    CENSUSAREA: 686.277,
    FIPS: 'SC039',
    fips: '45039',
    StateAbbr: 'SC',
    StateName: 'South Carolina',
    lat: 34.39533447,
    lng: -81.12125577,
  },
  {
    GEO_ID: '0500000US47005',
    STATE: '47',
    COUNTY: '005',
    NAME: 'Benton',
    LSAD: 'County',
    CENSUSAREA: 394.142,
    FIPS: 'TN005',
    fips: '47005',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 36.06961024,
    lng: -88.06869913,
  },
  {
    GEO_ID: '0500000US47007',
    STATE: '47',
    COUNTY: '007',
    NAME: 'Bledsoe',
    LSAD: 'County',
    CENSUSAREA: 406.425,
    FIPS: 'TN007',
    fips: '47007',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 35.59663055,
    lng: -85.20533487,
  },
  {
    GEO_ID: '0500000US47013',
    STATE: '47',
    COUNTY: '013',
    NAME: 'Campbell',
    LSAD: 'County',
    CENSUSAREA: 480.191,
    FIPS: 'TN013',
    fips: '47013',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 36.40334413,
    lng: -84.14937651,
  },
  {
    GEO_ID: '0500000US47027',
    STATE: '47',
    COUNTY: '027',
    NAME: 'Clay',
    LSAD: 'County',
    CENSUSAREA: 236.536,
    FIPS: 'TN027',
    fips: '47027',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 36.55111287,
    lng: -85.5429723,
  },
  {
    GEO_ID: '0500000US47031',
    STATE: '47',
    COUNTY: '031',
    NAME: 'Coffee',
    LSAD: 'County',
    CENSUSAREA: 428.957,
    FIPS: 'TN031',
    fips: '47031',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 35.49021487,
    lng: -86.07559709,
  },
  {
    GEO_ID: '0500000US47045',
    STATE: '47',
    COUNTY: '045',
    NAME: 'Dyer',
    LSAD: 'County',
    CENSUSAREA: 512.327,
    FIPS: 'TN045',
    fips: '47045',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 36.05918296,
    lng: -89.4139363,
  },
  {
    GEO_ID: '0500000US47057',
    STATE: '47',
    COUNTY: '057',
    NAME: 'Grainger',
    LSAD: 'County',
    CENSUSAREA: 280.6,
    FIPS: 'TN057',
    fips: '47057',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 36.2754882,
    lng: -83.51084097,
  },
  {
    GEO_ID: '0500000US47069',
    STATE: '47',
    COUNTY: '069',
    NAME: 'Hardeman',
    LSAD: 'County',
    CENSUSAREA: 667.768,
    FIPS: 'TN069',
    fips: '47069',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 35.2067611,
    lng: -88.99308826,
  },
  {
    GEO_ID: '0500000US47081',
    STATE: '47',
    COUNTY: '081',
    NAME: 'Hickman',
    LSAD: 'County',
    CENSUSAREA: 612.499,
    FIPS: 'TN081',
    fips: '47081',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 35.80374486,
    lng: -87.47317001,
  },
  {
    GEO_ID: '0500000US47093',
    STATE: '47',
    COUNTY: '093',
    NAME: 'Knox',
    LSAD: 'County',
    CENSUSAREA: 508.215,
    FIPS: 'TN093',
    fips: '47093',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 35.99332876,
    lng: -83.93759106,
  },
  {
    GEO_ID: '0500000US47105',
    STATE: '47',
    COUNTY: '105',
    NAME: 'Loudon',
    LSAD: 'County',
    CENSUSAREA: 229.216,
    FIPS: 'TN105',
    fips: '47105',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 35.73514674,
    lng: -84.31125324,
  },
  {
    GEO_ID: '0500000US47117',
    STATE: '47',
    COUNTY: '117',
    NAME: 'Marshall',
    LSAD: 'County',
    CENSUSAREA: 375.46,
    FIPS: 'TN117',
    fips: '47117',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 35.46843385,
    lng: -86.76591028,
  },
  {
    GEO_ID: '0500000US47129',
    STATE: '47',
    COUNTY: '129',
    NAME: 'Morgan',
    LSAD: 'County',
    CENSUSAREA: 522.18,
    FIPS: 'TN129',
    fips: '47129',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 36.13501561,
    lng: -84.64901358,
  },
  {
    GEO_ID: '0500000US47139',
    STATE: '47',
    COUNTY: '139',
    NAME: 'Polk',
    LSAD: 'County',
    CENSUSAREA: 434.676,
    FIPS: 'TN139',
    fips: '47139',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 35.11993556,
    lng: -84.52298798,
  },
  {
    GEO_ID: '0500000US47151',
    STATE: '47',
    COUNTY: '151',
    NAME: 'Scott',
    LSAD: 'County',
    CENSUSAREA: 532.297,
    FIPS: 'TN151',
    fips: '47151',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 36.42885328,
    lng: -84.50369331,
  },
  {
    GEO_ID: '0500000US47173',
    STATE: '47',
    COUNTY: '173',
    NAME: 'Union',
    LSAD: 'County',
    CENSUSAREA: 223.549,
    FIPS: 'TN173',
    fips: '47173',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 36.28814616,
    lng: -83.83725225,
  },
  {
    GEO_ID: '0500000US47179',
    STATE: '47',
    COUNTY: '179',
    NAME: 'Washington',
    LSAD: 'County',
    CENSUSAREA: 326.465,
    FIPS: 'TN179',
    fips: '47179',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 36.293414,
    lng: -82.49711101,
  },
  {
    GEO_ID: '0500000US45049',
    STATE: '45',
    COUNTY: '049',
    NAME: 'Hampton',
    LSAD: 'County',
    CENSUSAREA: 559.896,
    FIPS: 'SC049',
    fips: '45049',
    StateAbbr: 'SC',
    StateName: 'South Carolina',
    lat: 32.7764169,
    lng: -81.14110731,
  },
  {
    GEO_ID: '0500000US45053',
    STATE: '45',
    COUNTY: '053',
    NAME: 'Jasper',
    LSAD: 'County',
    CENSUSAREA: 655.318,
    FIPS: 'SC053',
    fips: '45053',
    StateAbbr: 'SC',
    StateName: 'South Carolina',
    lat: 32.43633197,
    lng: -81.03177638,
  },
  {
    GEO_ID: '0500000US45075',
    STATE: '45',
    COUNTY: '075',
    NAME: 'Orangeburg',
    LSAD: 'County',
    CENSUSAREA: 1106.101,
    FIPS: 'SC075',
    fips: '45075',
    StateAbbr: 'SC',
    StateName: 'South Carolina',
    lat: 33.43921301,
    lng: -80.80086358,
  },
  {
    GEO_ID: '0500000US45081',
    STATE: '45',
    COUNTY: '081',
    NAME: 'Saluda',
    LSAD: 'County',
    CENSUSAREA: 452.778,
    FIPS: 'SC081',
    fips: '45081',
    StateAbbr: 'SC',
    StateName: 'South Carolina',
    lat: 34.00631213,
    lng: -81.72732002,
  },
  {
    GEO_ID: '0500000US46003',
    STATE: '46',
    COUNTY: '003',
    NAME: 'Aurora',
    LSAD: 'County',
    CENSUSAREA: 708.425,
    FIPS: 'SD003',
    fips: '46003',
    StateAbbr: 'SD',
    StateName: 'South Dakota',
    lat: 43.7180875,
    lng: -98.56175096,
  },
  {
    GEO_ID: '0500000US46007',
    STATE: '46',
    COUNTY: '007',
    NAME: 'Bennett',
    LSAD: 'County',
    CENSUSAREA: 1184.709,
    FIPS: 'SD007',
    fips: '46007',
    StateAbbr: 'SD',
    StateName: 'South Dakota',
    lat: 43.19506714,
    lng: -101.6641253,
  },
  {
    GEO_ID: '0500000US46017',
    STATE: '46',
    COUNTY: '017',
    NAME: 'Buffalo',
    LSAD: 'County',
    CENSUSAREA: 471.384,
    FIPS: 'SD017',
    fips: '46017',
    StateAbbr: 'SD',
    StateName: 'South Dakota',
    lat: 44.07640555,
    lng: -99.20485567,
  },
  {
    GEO_ID: '0500000US46029',
    STATE: '46',
    COUNTY: '029',
    NAME: 'Codington',
    LSAD: 'County',
    CENSUSAREA: 688.495,
    FIPS: 'SD029',
    fips: '46029',
    StateAbbr: 'SD',
    StateName: 'South Dakota',
    lat: 44.97790512,
    lng: -97.18860384,
  },
  {
    GEO_ID: '0500000US46035',
    STATE: '46',
    COUNTY: '035',
    NAME: 'Davison',
    LSAD: 'County',
    CENSUSAREA: 435.555,
    FIPS: 'SD035',
    fips: '46035',
    StateAbbr: 'SD',
    StateName: 'South Dakota',
    lat: 43.67475843,
    lng: -98.14543668,
  },
  {
    GEO_ID: '0500000US46043',
    STATE: '46',
    COUNTY: '043',
    NAME: 'Douglas',
    LSAD: 'County',
    CENSUSAREA: 431.802,
    FIPS: 'SD043',
    fips: '46043',
    StateAbbr: 'SD',
    StateName: 'South Dakota',
    lat: 43.38705519,
    lng: -98.36606932,
  },
  {
    GEO_ID: '0500000US46055',
    STATE: '46',
    COUNTY: '055',
    NAME: 'Haakon',
    LSAD: 'County',
    CENSUSAREA: 1810.534,
    FIPS: 'SD055',
    fips: '46055',
    StateAbbr: 'SD',
    StateName: 'South Dakota',
    lat: 44.29463659,
    lng: -101.5397482,
  },
  {
    GEO_ID: '0500000US46061',
    STATE: '46',
    COUNTY: '061',
    NAME: 'Hanson',
    LSAD: 'County',
    CENSUSAREA: 434.513,
    FIPS: 'SD061',
    fips: '46061',
    StateAbbr: 'SD',
    StateName: 'South Dakota',
    lat: 43.67458361,
    lng: -97.78704306,
  },
  {
    GEO_ID: '0500000US46087',
    STATE: '46',
    COUNTY: '087',
    NAME: 'McCook',
    LSAD: 'County',
    CENSUSAREA: 574.204,
    FIPS: 'SD087',
    fips: '46087',
    StateAbbr: 'SD',
    StateName: 'South Dakota',
    lat: 43.67426214,
    lng: -97.36854569,
  },
  {
    GEO_ID: '0500000US46095',
    STATE: '46',
    COUNTY: '095',
    NAME: 'Mellette',
    LSAD: 'County',
    CENSUSAREA: 1307.306,
    FIPS: 'SD095',
    fips: '46095',
    StateAbbr: 'SD',
    StateName: 'South Dakota',
    lat: 43.58135895,
    lng: -100.7598474,
  },
  {
    GEO_ID: '0500000US46103',
    STATE: '46',
    COUNTY: '103',
    NAME: 'Pennington',
    LSAD: 'County',
    CENSUSAREA: 2776.553,
    FIPS: 'SD103',
    fips: '46103',
    StateAbbr: 'SD',
    StateName: 'South Dakota',
    lat: 44.00397449,
    lng: -102.8239659,
  },
  {
    GEO_ID: '0500000US46111',
    STATE: '46',
    COUNTY: '111',
    NAME: 'Sanborn',
    LSAD: 'County',
    CENSUSAREA: 569.321,
    FIPS: 'SD111',
    fips: '46111',
    StateAbbr: 'SD',
    StateName: 'South Dakota',
    lat: 44.02345807,
    lng: -98.09105658,
  },
  {
    GEO_ID: '0500000US46125',
    STATE: '46',
    COUNTY: '125',
    NAME: 'Turner',
    LSAD: 'County',
    CENSUSAREA: 617.057,
    FIPS: 'SD125',
    fips: '46125',
    StateAbbr: 'SD',
    StateName: 'South Dakota',
    lat: 43.3109081,
    lng: -97.14865776,
  },
  {
    GEO_ID: '0500000US46137',
    STATE: '46',
    COUNTY: '137',
    NAME: 'Ziebach',
    LSAD: 'County',
    CENSUSAREA: 1961.272,
    FIPS: 'SD137',
    fips: '46137',
    StateAbbr: 'SD',
    StateName: 'South Dakota',
    lat: 44.98058236,
    lng: -101.6659766,
  },
  {
    GEO_ID: '0500000US40059',
    STATE: '40',
    COUNTY: '059',
    NAME: 'Harper',
    LSAD: 'County',
    CENSUSAREA: 1039.017,
    FIPS: 'OK059',
    fips: '40059',
    StateAbbr: 'OK',
    StateName: 'Oklahoma',
    lat: 36.78871253,
    lng: -99.66696611,
  },
  {
    GEO_ID: '0500000US40063',
    STATE: '40',
    COUNTY: '063',
    NAME: 'Hughes',
    LSAD: 'County',
    CENSUSAREA: 804.647,
    FIPS: 'OK063',
    fips: '40063',
    StateAbbr: 'OK',
    StateName: 'Oklahoma',
    lat: 35.04854478,
    lng: -96.25062134,
  },
  {
    GEO_ID: '0500000US40073',
    STATE: '40',
    COUNTY: '073',
    NAME: 'Kingfisher',
    LSAD: 'County',
    CENSUSAREA: 898.161,
    FIPS: 'OK073',
    fips: '40073',
    StateAbbr: 'OK',
    StateName: 'Oklahoma',
    lat: 35.94535722,
    lng: -97.94153042,
  },
  {
    GEO_ID: '0500000US40081',
    STATE: '40',
    COUNTY: '081',
    NAME: 'Lincoln',
    LSAD: 'County',
    CENSUSAREA: 952.314,
    FIPS: 'OK081',
    fips: '40081',
    StateAbbr: 'OK',
    StateName: 'Oklahoma',
    lat: 35.7027284,
    lng: -96.88123961,
  },
  {
    GEO_ID: '0500000US40087',
    STATE: '40',
    COUNTY: '087',
    NAME: 'McClain',
    LSAD: 'County',
    CENSUSAREA: 570.701,
    FIPS: 'OK087',
    fips: '40087',
    StateAbbr: 'OK',
    StateName: 'Oklahoma',
    lat: 35.00888538,
    lng: -97.44298086,
  },
  {
    GEO_ID: '0500000US40097',
    STATE: '40',
    COUNTY: '097',
    NAME: 'Mayes',
    LSAD: 'County',
    CENSUSAREA: 655.386,
    FIPS: 'OK097',
    fips: '40097',
    StateAbbr: 'OK',
    StateName: 'Oklahoma',
    lat: 36.30176119,
    lng: -95.23132466,
  },
  {
    GEO_ID: '0500000US40109',
    STATE: '40',
    COUNTY: '109',
    NAME: 'Oklahoma',
    LSAD: 'County',
    CENSUSAREA: 708.819,
    FIPS: 'OK109',
    fips: '40109',
    StateAbbr: 'OK',
    StateName: 'Oklahoma',
    lat: 35.55141319,
    lng: -97.4075036,
  },
  {
    GEO_ID: '0500000US40123',
    STATE: '40',
    COUNTY: '123',
    NAME: 'Pontotoc',
    LSAD: 'County',
    CENSUSAREA: 720.435,
    FIPS: 'OK123',
    fips: '40123',
    StateAbbr: 'OK',
    StateName: 'Oklahoma',
    lat: 34.72795889,
    lng: -96.68450952,
  },
  {
    GEO_ID: '0500000US40127',
    STATE: '40',
    COUNTY: '127',
    NAME: 'Pushmataha',
    LSAD: 'County',
    CENSUSAREA: 1395.836,
    FIPS: 'OK127',
    fips: '40127',
    StateAbbr: 'OK',
    StateName: 'Oklahoma',
    lat: 34.41635817,
    lng: -95.37595261,
  },
  {
    GEO_ID: '0500000US40147',
    STATE: '40',
    COUNTY: '147',
    NAME: 'Washington',
    LSAD: 'County',
    CENSUSAREA: 415.454,
    FIPS: 'OK147',
    fips: '40147',
    StateAbbr: 'OK',
    StateName: 'Oklahoma',
    lat: 36.71527849,
    lng: -95.90449238,
  },
  {
    GEO_ID: '0500000US40153',
    STATE: '40',
    COUNTY: '153',
    NAME: 'Woodward',
    LSAD: 'County',
    CENSUSAREA: 1242.399,
    FIPS: 'OK153',
    fips: '40153',
    StateAbbr: 'OK',
    StateName: 'Oklahoma',
    lat: 36.42251383,
    lng: -99.26427063,
  },
  {
    GEO_ID: '0500000US41017',
    STATE: '41',
    COUNTY: '017',
    NAME: 'Deschutes',
    LSAD: 'County',
    CENSUSAREA: 3018.186,
    FIPS: 'OR017',
    fips: '41017',
    StateAbbr: 'OR',
    StateName: 'Oregon',
    lat: 43.91469217,
    lng: -121.227881,
  },
  {
    GEO_ID: '0500000US41031',
    STATE: '41',
    COUNTY: '031',
    NAME: 'Jefferson',
    LSAD: 'County',
    CENSUSAREA: 1780.785,
    FIPS: 'OR031',
    fips: '41031',
    StateAbbr: 'OR',
    StateName: 'Oregon',
    lat: 44.62935891,
    lng: -121.176431,
  },
  {
    GEO_ID: '0500000US41037',
    STATE: '41',
    COUNTY: '037',
    NAME: 'Lake',
    LSAD: 'County',
    CENSUSAREA: 8138.984,
    FIPS: 'OR037',
    fips: '41037',
    StateAbbr: 'OR',
    StateName: 'Oregon',
    lat: 42.79350818,
    lng: -120.3873864,
  },
  {
    GEO_ID: '0500000US41053',
    STATE: '41',
    COUNTY: '053',
    NAME: 'Polk',
    LSAD: 'County',
    CENSUSAREA: 740.787,
    FIPS: 'OR053',
    fips: '41053',
    StateAbbr: 'OR',
    StateName: 'Oregon',
    lat: 44.90338514,
    lng: -123.4132525,
  },
  {
    GEO_ID: '0500000US41069',
    STATE: '41',
    COUNTY: '069',
    NAME: 'Wheeler',
    LSAD: 'County',
    CENSUSAREA: 1714.749,
    FIPS: 'OR069',
    fips: '41069',
    StateAbbr: 'OR',
    StateName: 'Oregon',
    lat: 44.72584844,
    lng: -120.0273912,
  },
  {
    GEO_ID: '0500000US42005',
    STATE: '42',
    COUNTY: '005',
    NAME: 'Armstrong',
    LSAD: 'County',
    CENSUSAREA: 653.203,
    FIPS: 'PA005',
    fips: '42005',
    StateAbbr: 'PA',
    StateName: 'Pennsylvania',
    lat: 40.81298372,
    lng: -79.46505466,
  },
  {
    GEO_ID: '0500000US48357',
    STATE: '48',
    COUNTY: '357',
    NAME: 'Ochiltree',
    LSAD: 'County',
    CENSUSAREA: 917.627,
    FIPS: 'TX357',
    fips: '48357',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 36.27830534,
    lng: -100.8158413,
  },
  {
    GEO_ID: '0500000US48359',
    STATE: '48',
    COUNTY: '359',
    NAME: 'Oldham',
    LSAD: 'County',
    CENSUSAREA: 1500.533,
    FIPS: 'TX359',
    fips: '48359',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 35.40425708,
    lng: -102.6027421,
  },
  {
    GEO_ID: '0500000US48367',
    STATE: '48',
    COUNTY: '367',
    NAME: 'Parker',
    LSAD: 'County',
    CENSUSAREA: 903.478,
    FIPS: 'TX367',
    fips: '48367',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 32.77760907,
    lng: -97.80496943,
  },
  {
    GEO_ID: '0500000US48379',
    STATE: '48',
    COUNTY: '379',
    NAME: 'Rains',
    LSAD: 'County',
    CENSUSAREA: 229.452,
    FIPS: 'TX379',
    fips: '48379',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 32.87032705,
    lng: -95.79355891,
  },
  {
    GEO_ID: '0500000US48381',
    STATE: '48',
    COUNTY: '381',
    NAME: 'Randall',
    LSAD: 'County',
    CENSUSAREA: 911.544,
    FIPS: 'TX381',
    fips: '48381',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 34.96585996,
    lng: -101.8971322,
  },
  {
    GEO_ID: '0500000US48393',
    STATE: '48',
    COUNTY: '393',
    NAME: 'Roberts',
    LSAD: 'County',
    CENSUSAREA: 924.058,
    FIPS: 'TX393',
    fips: '48393',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 35.83809685,
    lng: -100.813175,
  },
  {
    GEO_ID: '0500000US48397',
    STATE: '48',
    COUNTY: '397',
    NAME: 'Rockwall',
    LSAD: 'County',
    CENSUSAREA: 127.036,
    FIPS: 'TX397',
    fips: '48397',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 32.89785884,
    lng: -96.40769484,
  },
  {
    GEO_ID: '0500000US48411',
    STATE: '48',
    COUNTY: '411',
    NAME: 'San Saba',
    LSAD: 'County',
    CENSUSAREA: 1135.297,
    FIPS: 'TX411',
    fips: '48411',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 31.15502115,
    lng: -98.81731955,
  },
  {
    GEO_ID: '0500000US48425',
    STATE: '48',
    COUNTY: '425',
    NAME: 'Somervell',
    LSAD: 'County',
    CENSUSAREA: 186.463,
    FIPS: 'TX425',
    fips: '48425',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 32.22235362,
    lng: -97.77458843,
  },
  {
    GEO_ID: '0500000US48429',
    STATE: '48',
    COUNTY: '429',
    NAME: 'Stephens',
    LSAD: 'County',
    CENSUSAREA: 896.719,
    FIPS: 'TX429',
    fips: '48429',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 32.73553157,
    lng: -98.83631615,
  },
  {
    GEO_ID: '0500000US48433',
    STATE: '48',
    COUNTY: '433',
    NAME: 'Stonewall',
    LSAD: 'County',
    CENSUSAREA: 916.313,
    FIPS: 'TX433',
    fips: '48433',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 33.17906744,
    lng: -100.2538401,
  },
  {
    GEO_ID: '0500000US48445',
    STATE: '48',
    COUNTY: '445',
    NAME: 'Terry',
    LSAD: 'County',
    CENSUSAREA: 888.839,
    FIPS: 'TX445',
    fips: '48445',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 33.17402785,
    lng: -102.3354521,
  },
  {
    GEO_ID: '0500000US48449',
    STATE: '48',
    COUNTY: '449',
    NAME: 'Titus',
    LSAD: 'County',
    CENSUSAREA: 406.054,
    FIPS: 'TX449',
    fips: '48449',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 33.21673924,
    lng: -94.96656946,
  },
  {
    GEO_ID: '0500000US48459',
    STATE: '48',
    COUNTY: '459',
    NAME: 'Upshur',
    LSAD: 'County',
    CENSUSAREA: 582.947,
    FIPS: 'TX459',
    fips: '48459',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 32.73670474,
    lng: -94.94114818,
  },
  {
    GEO_ID: '0500000US48463',
    STATE: '48',
    COUNTY: '463',
    NAME: 'Uvalde',
    LSAD: 'County',
    CENSUSAREA: 1551.946,
    FIPS: 'TX463',
    fips: '48463',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 29.35739637,
    lng: -99.76229172,
  },
  {
    GEO_ID: '0500000US48475',
    STATE: '48',
    COUNTY: '475',
    NAME: 'Ward',
    LSAD: 'County',
    CENSUSAREA: 835.602,
    FIPS: 'TX475',
    fips: '48475',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 31.50920785,
    lng: -103.1024815,
  },
  {
    GEO_ID: '0500000US48491',
    STATE: '48',
    COUNTY: '491',
    NAME: 'Williamson',
    LSAD: 'County',
    CENSUSAREA: 1118.303,
    FIPS: 'TX491',
    fips: '48491',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 30.64812769,
    lng: -97.60120361,
  },
  {
    GEO_ID: '0500000US48497',
    STATE: '48',
    COUNTY: '497',
    NAME: 'Wise',
    LSAD: 'County',
    CENSUSAREA: 904.422,
    FIPS: 'TX497',
    fips: '48497',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 33.215766,
    lng: -97.65458625,
  },
  {
    GEO_ID: '0500000US48507',
    STATE: '48',
    COUNTY: '507',
    NAME: 'Zavala',
    LSAD: 'County',
    CENSUSAREA: 1297.406,
    FIPS: 'TX507',
    fips: '48507',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 28.86623446,
    lng: -99.76049166,
  },
  {
    GEO_ID: '0500000US49011',
    STATE: '49',
    COUNTY: '011',
    NAME: 'Davis',
    LSAD: 'County',
    CENSUSAREA: 298.778,
    FIPS: 'UT011',
    fips: '49011',
    StateAbbr: 'UT',
    StateName: 'Utah',
    lat: 40.99072955,
    lng: -112.1116607,
  },
  {
    GEO_ID: '0500000US47185',
    STATE: '47',
    COUNTY: '185',
    NAME: 'White',
    LSAD: 'County',
    CENSUSAREA: 376.673,
    FIPS: 'TN185',
    fips: '47185',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 35.92662264,
    lng: -85.45476978,
  },
  {
    GEO_ID: '0500000US47189',
    STATE: '47',
    COUNTY: '189',
    NAME: 'Wilson',
    LSAD: 'County',
    CENSUSAREA: 570.826,
    FIPS: 'TN189',
    fips: '47189',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 36.15428192,
    lng: -86.29778739,
  },
  {
    GEO_ID: '0500000US48009',
    STATE: '48',
    COUNTY: '009',
    NAME: 'Archer',
    LSAD: 'County',
    CENSUSAREA: 903.11,
    FIPS: 'TX009',
    fips: '48009',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 33.61569967,
    lng: -98.68754589,
  },
  {
    GEO_ID: '0500000US48011',
    STATE: '48',
    COUNTY: '011',
    NAME: 'Armstrong',
    LSAD: 'County',
    CENSUSAREA: 909.109,
    FIPS: 'TX011',
    fips: '48011',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 34.96507426,
    lng: -101.3574874,
  },
  {
    GEO_ID: '0500000US48021',
    STATE: '48',
    COUNTY: '021',
    NAME: 'Bastrop',
    LSAD: 'County',
    CENSUSAREA: 888.15,
    FIPS: 'TX021',
    fips: '48021',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 30.10367452,
    lng: -97.31201625,
  },
  {
    GEO_ID: '0500000US48031',
    STATE: '48',
    COUNTY: '031',
    NAME: 'Blanco',
    LSAD: 'County',
    CENSUSAREA: 709.251,
    FIPS: 'TX031',
    fips: '48031',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 30.26640707,
    lng: -98.3999852,
  },
  {
    GEO_ID: '0500000US48047',
    STATE: '48',
    COUNTY: '047',
    NAME: 'Brooks',
    LSAD: 'County',
    CENSUSAREA: 943.364,
    FIPS: 'TX047',
    fips: '48047',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 27.03154713,
    lng: -98.21866528,
  },
  {
    GEO_ID: '0500000US48053',
    STATE: '48',
    COUNTY: '053',
    NAME: 'Burnet',
    LSAD: 'County',
    CENSUSAREA: 994.258,
    FIPS: 'TX053',
    fips: '48053',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 30.78852336,
    lng: -98.18244138,
  },
  {
    GEO_ID: '0500000US48063',
    STATE: '48',
    COUNTY: '063',
    NAME: 'Camp',
    LSAD: 'County',
    CENSUSAREA: 195.826,
    FIPS: 'TX063',
    fips: '48063',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 32.97374349,
    lng: -94.97868645,
  },
  {
    GEO_ID: '0500000US48069',
    STATE: '48',
    COUNTY: '069',
    NAME: 'Castro',
    LSAD: 'County',
    CENSUSAREA: 894.434,
    FIPS: 'TX069',
    fips: '48069',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 34.53004051,
    lng: -102.2617081,
  },
  {
    GEO_ID: '0500000US48079',
    STATE: '48',
    COUNTY: '079',
    NAME: 'Cochran',
    LSAD: 'County',
    CENSUSAREA: 775.15,
    FIPS: 'TX079',
    fips: '48079',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 33.60426094,
    lng: -102.8285179,
  },
  {
    GEO_ID: '0500000US48095',
    STATE: '48',
    COUNTY: '095',
    NAME: 'Concho',
    LSAD: 'County',
    CENSUSAREA: 983.799,
    FIPS: 'TX095',
    fips: '48095',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 31.32686635,
    lng: -99.86428507,
  },
  {
    GEO_ID: '0500000US48099',
    STATE: '48',
    COUNTY: '099',
    NAME: 'Coryell',
    LSAD: 'County',
    CENSUSAREA: 1052.066,
    FIPS: 'TX099',
    fips: '48099',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 31.39078397,
    lng: -97.79936155,
  },
  {
    GEO_ID: '0500000US48103',
    STATE: '48',
    COUNTY: '103',
    NAME: 'Crane',
    LSAD: 'County',
    CENSUSAREA: 785.069,
    FIPS: 'TX103',
    fips: '48103',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 31.4287137,
    lng: -102.5151699,
  },
  {
    GEO_ID: '0500000US48115',
    STATE: '48',
    COUNTY: '115',
    NAME: 'Dawson',
    LSAD: 'County',
    CENSUSAREA: 900.306,
    FIPS: 'TX115',
    fips: '48115',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 32.74255572,
    lng: -101.9477242,
  },
  {
    GEO_ID: '0500000US48123',
    STATE: '48',
    COUNTY: '123',
    NAME: 'DeWitt',
    LSAD: 'County',
    CENSUSAREA: 908.975,
    FIPS: 'TX123',
    fips: '48123',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 29.08147376,
    lng: -97.35694033,
  },
  {
    GEO_ID: '0500000US48127',
    STATE: '48',
    COUNTY: '127',
    NAME: 'Dimmit',
    LSAD: 'County',
    CENSUSAREA: 1328.884,
    FIPS: 'TX127',
    fips: '48127',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 28.42276169,
    lng: -99.75646833,
  },
  {
    GEO_ID: '0500000US48137',
    STATE: '48',
    COUNTY: '137',
    NAME: 'Edwards',
    LSAD: 'County',
    CENSUSAREA: 2117.858,
    FIPS: 'TX137',
    fips: '48137',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 29.98273014,
    lng: -100.3050897,
  },
  {
    GEO_ID: '0500000US48149',
    STATE: '48',
    COUNTY: '149',
    NAME: 'Fayette',
    LSAD: 'County',
    CENSUSAREA: 950.008,
    FIPS: 'TX149',
    fips: '48149',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 29.87663549,
    lng: -96.91986981,
  },
  {
    GEO_ID: '0500000US48159',
    STATE: '48',
    COUNTY: '159',
    NAME: 'Franklin',
    LSAD: 'County',
    CENSUSAREA: 284.391,
    FIPS: 'TX159',
    fips: '48159',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 33.17532732,
    lng: -95.21903806,
  },
  {
    GEO_ID: '0500000US48169',
    STATE: '48',
    COUNTY: '169',
    NAME: 'Garza',
    LSAD: 'County',
    CENSUSAREA: 893.412,
    FIPS: 'TX169',
    fips: '48169',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 33.17969345,
    lng: -101.2984114,
  },
  {
    GEO_ID: '0500000US48177',
    STATE: '48',
    COUNTY: '177',
    NAME: 'Gonzales',
    LSAD: 'County',
    CENSUSAREA: 1066.688,
    FIPS: 'TX177',
    fips: '48177',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 29.45652075,
    lng: -97.49248742,
  },
  {
    GEO_ID: '0500000US48179',
    STATE: '48',
    COUNTY: '179',
    NAME: 'Gray',
    LSAD: 'County',
    CENSUSAREA: 925.974,
    FIPS: 'TX179',
    fips: '48179',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 35.40081878,
    lng: -100.8129222,
  },
  {
    GEO_ID: '0500000US48185',
    STATE: '48',
    COUNTY: '185',
    NAME: 'Grimes',
    LSAD: 'County',
    CENSUSAREA: 787.459,
    FIPS: 'TX185',
    fips: '48185',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 30.5437066,
    lng: -95.98558082,
  },
  {
    GEO_ID: '0500000US48191',
    STATE: '48',
    COUNTY: '191',
    NAME: 'Hall',
    LSAD: 'County',
    CENSUSAREA: 883.492,
    FIPS: 'TX191',
    fips: '48191',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 34.53073368,
    lng: -100.6813647,
  },
  {
    GEO_ID: '0500000US48195',
    STATE: '48',
    COUNTY: '195',
    NAME: 'Hansford',
    LSAD: 'County',
    CENSUSAREA: 919.81,
    FIPS: 'TX195',
    fips: '48195',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 36.27743284,
    lng: -101.3545796,
  },
  {
    GEO_ID: '0500000US48217',
    STATE: '48',
    COUNTY: '217',
    NAME: 'Hill',
    LSAD: 'County',
    CENSUSAREA: 958.864,
    FIPS: 'TX217',
    fips: '48217',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 31.99059876,
    lng: -97.13216715,
  },
  {
    GEO_ID: '0500000US48227',
    STATE: '48',
    COUNTY: '227',
    NAME: 'Howard',
    LSAD: 'County',
    CENSUSAREA: 900.791,
    FIPS: 'TX227',
    fips: '48227',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 32.30621324,
    lng: -101.4356444,
  },
  {
    GEO_ID: '0500000US48233',
    STATE: '48',
    COUNTY: '233',
    NAME: 'Hutchinson',
    LSAD: 'County',
    CENSUSAREA: 887.419,
    FIPS: 'TX233',
    fips: '48233',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 35.84006574,
    lng: -101.3546643,
  },
  {
    GEO_ID: '0500000US48247',
    STATE: '48',
    COUNTY: '247',
    NAME: 'Jim Hogg',
    LSAD: 'County',
    CENSUSAREA: 1136.138,
    FIPS: 'TX247',
    fips: '48247',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 27.04354651,
    lng: -98.69730012,
  },
  {
    GEO_ID: '0500000US48251',
    STATE: '48',
    COUNTY: '251',
    NAME: 'Johnson',
    LSAD: 'County',
    CENSUSAREA: 724.692,
    FIPS: 'TX251',
    fips: '48251',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 32.3788142,
    lng: -97.36618817,
  },
  {
    GEO_ID: '0500000US48263',
    STATE: '48',
    COUNTY: '263',
    NAME: 'Kent',
    LSAD: 'County',
    CENSUSAREA: 902.507,
    FIPS: 'TX263',
    fips: '48263',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 33.18104889,
    lng: -100.7780898,
  },
  {
    GEO_ID: '0500000US48265',
    STATE: '48',
    COUNTY: '265',
    NAME: 'Kerr',
    LSAD: 'County',
    CENSUSAREA: 1103.318,
    FIPS: 'TX265',
    fips: '48265',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 30.06116361,
    lng: -99.34928454,
  },
  {
    GEO_ID: '0500000US48275',
    STATE: '48',
    COUNTY: '275',
    NAME: 'Knox',
    LSAD: 'County',
    CENSUSAREA: 850.621,
    FIPS: 'TX275',
    fips: '48275',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 33.60611596,
    lng: -99.74139253,
  },
  {
    GEO_ID: '0500000US48283',
    STATE: '48',
    COUNTY: '283',
    NAME: 'La Salle',
    LSAD: 'County',
    CENSUSAREA: 1486.691,
    FIPS: 'TX283',
    fips: '48283',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 28.34445432,
    lng: -99.0992434,
  },
  {
    GEO_ID: '0500000US48297',
    STATE: '48',
    COUNTY: '297',
    NAME: 'Live Oak',
    LSAD: 'County',
    CENSUSAREA: 1039.696,
    FIPS: 'TX297',
    fips: '48297',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 28.35137771,
    lng: -98.12498408,
  },
  {
    GEO_ID: '0500000US48303',
    STATE: '48',
    COUNTY: '303',
    NAME: 'Lubbock',
    LSAD: 'County',
    CENSUSAREA: 895.599,
    FIPS: 'TX303',
    fips: '48303',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 33.61008596,
    lng: -101.8204789,
  },
  {
    GEO_ID: '0500000US48309',
    STATE: '48',
    COUNTY: '309',
    NAME: 'McLennan',
    LSAD: 'County',
    CENSUSAREA: 1037.101,
    FIPS: 'TX309',
    fips: '48309',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 31.5524976,
    lng: -97.20199931,
  },
  {
    GEO_ID: '0500000US48319',
    STATE: '48',
    COUNTY: '319',
    NAME: 'Mason',
    LSAD: 'County',
    CENSUSAREA: 928.803,
    FIPS: 'TX319',
    fips: '48319',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 30.7177995,
    lng: -99.22629725,
  },
  {
    GEO_ID: '0500000US48329',
    STATE: '48',
    COUNTY: '329',
    NAME: 'Midland',
    LSAD: 'County',
    CENSUSAREA: 900.299,
    FIPS: 'TX329',
    fips: '48329',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 31.86904128,
    lng: -102.0315682,
  },
  {
    GEO_ID: '0500000US48339',
    STATE: '48',
    COUNTY: '339',
    NAME: 'Montgomery',
    LSAD: 'County',
    CENSUSAREA: 1041.735,
    FIPS: 'TX339',
    fips: '48339',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 30.30019889,
    lng: -95.50331613,
  },
  {
    GEO_ID: '0500000US48349',
    STATE: '48',
    COUNTY: '349',
    NAME: 'Navarro',
    LSAD: 'County',
    CENSUSAREA: 1009.627,
    FIPS: 'TX349',
    fips: '48349',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 32.04696933,
    lng: -96.47280759,
  },
  {
    GEO_ID: '0500000US48353',
    STATE: '48',
    COUNTY: '353',
    NAME: 'Nolan',
    LSAD: 'County',
    CENSUSAREA: 911.997,
    FIPS: 'TX353',
    fips: '48353',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 32.30390399,
    lng: -100.4060382,
  },
  {
    GEO_ID: '0500000US51121',
    STATE: '51',
    COUNTY: '121',
    NAME: 'Montgomery',
    LSAD: 'County',
    CENSUSAREA: 387.014,
    FIPS: 'VA121',
    fips: '51121',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 37.17483569,
    lng: -80.38642559,
  },
  {
    GEO_ID: '0500000US51125',
    STATE: '51',
    COUNTY: '125',
    NAME: 'Nelson',
    LSAD: 'County',
    CENSUSAREA: 470.856,
    FIPS: 'VA125',
    fips: '51125',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 37.7874546,
    lng: -78.88692463,
  },
  {
    GEO_ID: '0500000US51141',
    STATE: '51',
    COUNTY: '141',
    NAME: 'Patrick',
    LSAD: 'County',
    CENSUSAREA: 483.096,
    FIPS: 'VA141',
    fips: '51141',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 36.67809987,
    lng: -80.28481637,
  },
  {
    GEO_ID: '0500000US51145',
    STATE: '51',
    COUNTY: '145',
    NAME: 'Powhatan',
    LSAD: 'County',
    CENSUSAREA: 260.221,
    FIPS: 'VA145',
    fips: '51145',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 37.55029696,
    lng: -77.915315,
  },
  {
    GEO_ID: '0500000US51155',
    STATE: '51',
    COUNTY: '155',
    NAME: 'Pulaski',
    LSAD: 'County',
    CENSUSAREA: 319.858,
    FIPS: 'VA155',
    fips: '51155',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 37.0632927,
    lng: -80.71348404,
  },
  {
    GEO_ID: '0500000US51157',
    STATE: '51',
    COUNTY: '157',
    NAME: 'Rappahannock',
    LSAD: 'County',
    CENSUSAREA: 266.227,
    FIPS: 'VA157',
    fips: '51157',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 38.68461794,
    lng: -78.15943614,
  },
  {
    GEO_ID: '0500000US51173',
    STATE: '51',
    COUNTY: '173',
    NAME: 'Smyth',
    LSAD: 'County',
    CENSUSAREA: 450.928,
    FIPS: 'VA173',
    fips: '51173',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 36.84393147,
    lng: -81.53692387,
  },
  {
    GEO_ID: '0500000US51187',
    STATE: '51',
    COUNTY: '187',
    NAME: 'Warren',
    LSAD: 'County',
    CENSUSAREA: 213.465,
    FIPS: 'VA187',
    fips: '51187',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 38.90870136,
    lng: -78.20860922,
  },
  {
    GEO_ID: '0500000US51197',
    STATE: '51',
    COUNTY: '197',
    NAME: 'Wythe',
    LSAD: 'County',
    CENSUSAREA: 461.824,
    FIPS: 'VA197',
    fips: '51197',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 36.91686677,
    lng: -81.07803317,
  },
  {
    GEO_ID: '0500000US51510',
    STATE: '51',
    COUNTY: '510',
    NAME: 'Alexandria',
    LSAD: 'city',
    CENSUSAREA: 15.027,
    FIPS: 'VA510',
    fips: '51510',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 38.8171835,
    lng: -77.08548989,
  },
  {
    GEO_ID: '0500000US51530',
    STATE: '51',
    COUNTY: '530',
    NAME: 'Buena Vista',
    LSAD: 'city',
    CENSUSAREA: 6.704,
    FIPS: 'VA530',
    fips: '51530',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 37.73052263,
    lng: -79.35459481,
  },
  {
    GEO_ID: '0500000US51540',
    STATE: '51',
    COUNTY: '540',
    NAME: 'Charlottesville',
    LSAD: 'city',
    CENSUSAREA: 10.238,
    FIPS: 'VA540',
    fips: '51540',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 38.03742163,
    lng: -78.48233004,
  },
  {
    GEO_ID: '0500000US51595',
    STATE: '51',
    COUNTY: '595',
    NAME: 'Emporia',
    LSAD: 'city',
    CENSUSAREA: 6.894,
    FIPS: 'VA595',
    fips: '51595',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 36.69543863,
    lng: -77.53622483,
  },
  {
    GEO_ID: '0500000US51660',
    STATE: '51',
    COUNTY: '660',
    NAME: 'Harrisonburg',
    LSAD: 'city',
    CENSUSAREA: 17.418,
    FIPS: 'VA660',
    fips: '51660',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 38.43833151,
    lng: -78.87397667,
  },
  {
    GEO_ID: '0500000US51670',
    STATE: '51',
    COUNTY: '670',
    NAME: 'Hopewell',
    LSAD: 'city',
    CENSUSAREA: 10.278,
    FIPS: 'VA670',
    fips: '51670',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 37.29122918,
    lng: -77.2969381,
  },
  {
    GEO_ID: '0500000US51760',
    STATE: '51',
    COUNTY: '760',
    NAME: 'Richmond',
    LSAD: 'city',
    CENSUSAREA: 59.805,
    FIPS: 'VA760',
    fips: '51760',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 37.53041663,
    lng: -77.47493293,
  },
  {
    GEO_ID: '0500000US51775',
    STATE: '51',
    COUNTY: '775',
    NAME: 'Salem',
    LSAD: 'city',
    CENSUSAREA: 14.437,
    FIPS: 'VA775',
    fips: '51775',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 37.28655753,
    lng: -80.05325441,
  },
  {
    GEO_ID: '0500000US51820',
    STATE: '51',
    COUNTY: '820',
    NAME: 'Waynesboro',
    LSAD: 'city',
    CENSUSAREA: 15.039,
    FIPS: 'VA820',
    fips: '51820',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 38.06830271,
    lng: -78.90109101,
  },
  {
    GEO_ID: '0500000US53013',
    STATE: '53',
    COUNTY: '013',
    NAME: 'Columbia',
    LSAD: 'County',
    CENSUSAREA: 868.629,
    FIPS: 'WA013',
    fips: '53013',
    StateAbbr: 'WA',
    StateName: 'Washington',
    lat: 46.29796069,
    lng: -117.9078252,
  },
  {
    GEO_ID: '0500000US53021',
    STATE: '53',
    COUNTY: '021',
    NAME: 'Franklin',
    LSAD: 'County',
    CENSUSAREA: 1242.171,
    FIPS: 'WA021',
    fips: '53021',
    StateAbbr: 'WA',
    StateName: 'Washington',
    lat: 46.5350455,
    lng: -118.8984754,
  },
  {
    GEO_ID: '0500000US55029',
    STATE: '55',
    COUNTY: '029',
    NAME: 'Door',
    LSAD: 'County',
    CENSUSAREA: 481.978,
    FIPS: 'WI029',
    fips: '55029',
    StateAbbr: 'WI',
    StateName: 'Wisconsin',
    lat: 44.95030522,
    lng: -87.31112969,
  },
  {
    GEO_ID: '0500000US55033',
    STATE: '55',
    COUNTY: '033',
    NAME: 'Dunn',
    LSAD: 'County',
    CENSUSAREA: 850.105,
    FIPS: 'WI033',
    fips: '55033',
    StateAbbr: 'WI',
    StateName: 'Wisconsin',
    lat: 44.94674851,
    lng: -91.89653391,
  },
  {
    GEO_ID: '0500000US55039',
    STATE: '55',
    COUNTY: '039',
    NAME: 'Fond du Lac',
    LSAD: 'County',
    CENSUSAREA: 719.553,
    FIPS: 'WI039',
    fips: '55039',
    StateAbbr: 'WI',
    StateName: 'Wisconsin',
    lat: 43.75354908,
    lng: -88.48885325,
  },
  {
    GEO_ID: '0500000US55045',
    STATE: '55',
    COUNTY: '045',
    NAME: 'Green',
    LSAD: 'County',
    CENSUSAREA: 583.957,
    FIPS: 'WI045',
    fips: '55045',
    StateAbbr: 'WI',
    StateName: 'Wisconsin',
    lat: 42.67994609,
    lng: -89.60216504,
  },
  {
    GEO_ID: '0500000US55063',
    STATE: '55',
    COUNTY: '063',
    NAME: 'La Crosse',
    LSAD: 'County',
    CENSUSAREA: 451.686,
    FIPS: 'WI063',
    fips: '55063',
    StateAbbr: 'WI',
    StateName: 'Wisconsin',
    lat: 43.9066398,
    lng: -91.11543876,
  },
  {
    GEO_ID: '0500000US55069',
    STATE: '55',
    COUNTY: '069',
    NAME: 'Lincoln',
    LSAD: 'County',
    CENSUSAREA: 878.973,
    FIPS: 'WI069',
    fips: '55069',
    StateAbbr: 'WI',
    StateName: 'Wisconsin',
    lat: 45.33724242,
    lng: -89.73474025,
  },
  {
    GEO_ID: '0500000US55073',
    STATE: '55',
    COUNTY: '073',
    NAME: 'Marathon',
    LSAD: 'County',
    CENSUSAREA: 1544.983,
    FIPS: 'WI073',
    fips: '55073',
    StateAbbr: 'WI',
    StateName: 'Wisconsin',
    lat: 44.89792533,
    lng: -89.75863384,
  },
  {
    GEO_ID: '0500000US55081',
    STATE: '55',
    COUNTY: '081',
    NAME: 'Monroe',
    LSAD: 'County',
    CENSUSAREA: 900.776,
    FIPS: 'WI081',
    fips: '55081',
    StateAbbr: 'WI',
    StateName: 'Wisconsin',
    lat: 43.94576215,
    lng: -90.61820195,
  },
  {
    GEO_ID: '0500000US55095',
    STATE: '55',
    COUNTY: '095',
    NAME: 'Polk',
    LSAD: 'County',
    CENSUSAREA: 913.962,
    FIPS: 'WI095',
    fips: '55095',
    StateAbbr: 'WI',
    StateName: 'Wisconsin',
    lat: 45.4615393,
    lng: -92.44123483,
  },
  {
    GEO_ID: '0500000US55103',
    STATE: '55',
    COUNTY: '103',
    NAME: 'Richland',
    LSAD: 'County',
    CENSUSAREA: 586.151,
    FIPS: 'WI103',
    fips: '55103',
    StateAbbr: 'WI',
    StateName: 'Wisconsin',
    lat: 43.37567167,
    lng: -90.42949677,
  },
  {
    GEO_ID: '0500000US55115',
    STATE: '55',
    COUNTY: '115',
    NAME: 'Shawano',
    LSAD: 'County',
    CENSUSAREA: 893.057,
    FIPS: 'WI115',
    fips: '55115',
    StateAbbr: 'WI',
    StateName: 'Wisconsin',
    lat: 44.78886999,
    lng: -88.76489325,
  },
  {
    GEO_ID: '0500000US55129',
    STATE: '55',
    COUNTY: '129',
    NAME: 'Washburn',
    LSAD: 'County',
    CENSUSAREA: 797.113,
    FIPS: 'WI129',
    fips: '55129',
    StateAbbr: 'WI',
    StateName: 'Wisconsin',
    lat: 45.89909725,
    lng: -91.79131495,
  },
  {
    GEO_ID: '0500000US55141',
    STATE: '55',
    COUNTY: '141',
    NAME: 'Wood',
    LSAD: 'County',
    CENSUSAREA: 793.116,
    FIPS: 'WI141',
    fips: '55141',
    StateAbbr: 'WI',
    StateName: 'Wisconsin',
    lat: 44.45535331,
    lng: -90.0416185,
  },
  {
    GEO_ID: '0500000US56025',
    STATE: '56',
    COUNTY: '025',
    NAME: 'Natrona',
    LSAD: 'County',
    CENSUSAREA: 5340.352,
    FIPS: 'WY025',
    fips: '56025',
    StateAbbr: 'WY',
    StateName: 'Wyoming',
    lat: 42.96194049,
    lng: -106.7988764,
  },
  {
    GEO_ID: '0500000US56031',
    STATE: '56',
    COUNTY: '031',
    NAME: 'Platte',
    LSAD: 'County',
    CENSUSAREA: 2084.208,
    FIPS: 'WY031',
    fips: '56031',
    StateAbbr: 'WY',
    StateName: 'Wyoming',
    lat: 42.13239072,
    lng: -104.9661319,
  },
  {
    GEO_ID: '0500000US56033',
    STATE: '56',
    COUNTY: '033',
    NAME: 'Sheridan',
    LSAD: 'County',
    CENSUSAREA: 2523.993,
    FIPS: 'WY033',
    fips: '56033',
    StateAbbr: 'WY',
    StateName: 'Wyoming',
    lat: 44.79068012,
    lng: -106.8799546,
  },
  {
    GEO_ID: '0500000US56045',
    STATE: '56',
    COUNTY: '045',
    NAME: 'Weston',
    LSAD: 'County',
    CENSUSAREA: 2398.089,
    FIPS: 'WY045',
    fips: '56045',
    StateAbbr: 'WY',
    StateName: 'Wyoming',
    lat: 43.84031497,
    lng: -104.5674037,
  },
  {
    GEO_ID: '0500000US72013',
    STATE: '72',
    COUNTY: '013',
    NAME: 'Arecibo',
    LSAD: 'Muno',
    CENSUSAREA: 125.947,
    FIPS: 'PR013',
    fips: '72013',
    StateAbbr: 'PR',
    StateName: 'Puerto Rico',
    lat: 18.40653954,
    lng: -66.6740025,
  },
  {
    GEO_ID: '0500000US53041',
    STATE: '53',
    COUNTY: '041',
    NAME: 'Lewis',
    LSAD: 'County',
    CENSUSAREA: 2402.802,
    FIPS: 'WA041',
    fips: '53041',
    StateAbbr: 'WA',
    StateName: 'Washington',
    lat: 46.57770709,
    lng: -122.3933441,
  },
  {
    GEO_ID: '0500000US53045',
    STATE: '53',
    COUNTY: '045',
    NAME: 'Mason',
    LSAD: 'County',
    CENSUSAREA: 959.417,
    FIPS: 'WA045',
    fips: '53045',
    StateAbbr: 'WA',
    StateName: 'Washington',
    lat: 47.34793175,
    lng: -123.1920342,
  },
  {
    GEO_ID: '0500000US53065',
    STATE: '53',
    COUNTY: '065',
    NAME: 'Stevens',
    LSAD: 'County',
    CENSUSAREA: 2477.765,
    FIPS: 'WA065',
    fips: '53065',
    StateAbbr: 'WA',
    StateName: 'Washington',
    lat: 48.39899156,
    lng: -117.8550713,
  },
  {
    GEO_ID: '0500000US54001',
    STATE: '54',
    COUNTY: '001',
    NAME: 'Barbour',
    LSAD: 'County',
    CENSUSAREA: 341.059,
    FIPS: 'WV001',
    fips: '54001',
    StateAbbr: 'WV',
    StateName: 'West Virginia',
    lat: 39.13281051,
    lng: -80.00360456,
  },
  {
    GEO_ID: '0500000US54007',
    STATE: '54',
    COUNTY: '007',
    NAME: 'Braxton',
    LSAD: 'County',
    CENSUSAREA: 510.812,
    FIPS: 'WV007',
    fips: '54007',
    StateAbbr: 'WV',
    StateName: 'West Virginia',
    lat: 38.70024736,
    lng: -80.71925498,
  },
  {
    GEO_ID: '0500000US54013',
    STATE: '54',
    COUNTY: '013',
    NAME: 'Calhoun',
    LSAD: 'County',
    CENSUSAREA: 279.249,
    FIPS: 'WV013',
    fips: '54013',
    StateAbbr: 'WV',
    StateName: 'West Virginia',
    lat: 38.84382602,
    lng: -81.11738243,
  },
  {
    GEO_ID: '0500000US54021',
    STATE: '54',
    COUNTY: '021',
    NAME: 'Gilmer',
    LSAD: 'County',
    CENSUSAREA: 338.503,
    FIPS: 'WV021',
    fips: '54021',
    StateAbbr: 'WV',
    StateName: 'West Virginia',
    lat: 38.92492424,
    lng: -80.85664593,
  },
  {
    GEO_ID: '0500000US54039',
    STATE: '54',
    COUNTY: '039',
    NAME: 'Kanawha',
    LSAD: 'County',
    CENSUSAREA: 901.587,
    FIPS: 'WV039',
    fips: '54039',
    StateAbbr: 'WV',
    StateName: 'West Virginia',
    lat: 38.33643035,
    lng: -81.52827161,
  },
  {
    GEO_ID: '0500000US54049',
    STATE: '54',
    COUNTY: '049',
    NAME: 'Marion',
    LSAD: 'County',
    CENSUSAREA: 308.735,
    FIPS: 'WV049',
    fips: '54049',
    StateAbbr: 'WV',
    StateName: 'West Virginia',
    lat: 39.51008055,
    lng: -80.24323834,
  },
  {
    GEO_ID: '0500000US54061',
    STATE: '54',
    COUNTY: '061',
    NAME: 'Monongalia',
    LSAD: 'County',
    CENSUSAREA: 360.062,
    FIPS: 'WV061',
    fips: '54061',
    StateAbbr: 'WV',
    StateName: 'West Virginia',
    lat: 39.63024065,
    lng: -80.04558938,
  },
  {
    GEO_ID: '0500000US54079',
    STATE: '54',
    COUNTY: '079',
    NAME: 'Putnam',
    LSAD: 'County',
    CENSUSAREA: 345.669,
    FIPS: 'WV079',
    fips: '54079',
    StateAbbr: 'WV',
    StateName: 'West Virginia',
    lat: 38.5085024,
    lng: -81.90915525,
  },
  {
    GEO_ID: '0500000US54089',
    STATE: '54',
    COUNTY: '089',
    NAME: 'Summers',
    LSAD: 'County',
    CENSUSAREA: 360.46,
    FIPS: 'WV089',
    fips: '54089',
    StateAbbr: 'WV',
    StateName: 'West Virginia',
    lat: 37.65596695,
    lng: -80.85840283,
  },
  {
    GEO_ID: '0500000US54101',
    STATE: '54',
    COUNTY: '101',
    NAME: 'Webster',
    LSAD: 'County',
    CENSUSAREA: 553.468,
    FIPS: 'WV101',
    fips: '54101',
    StateAbbr: 'WV',
    StateName: 'West Virginia',
    lat: 38.49472856,
    lng: -80.4218655,
  },
  {
    GEO_ID: '0500000US55003',
    STATE: '55',
    COUNTY: '003',
    NAME: 'Ashland',
    LSAD: 'County',
    CENSUSAREA: 1045.035,
    FIPS: 'WI003',
    fips: '55003',
    StateAbbr: 'WI',
    StateName: 'Wisconsin',
    lat: 46.33463725,
    lng: -90.67745058,
  },
  {
    GEO_ID: '0500000US55005',
    STATE: '55',
    COUNTY: '005',
    NAME: 'Barron',
    LSAD: 'County',
    CENSUSAREA: 862.711,
    FIPS: 'WI005',
    fips: '55005',
    StateAbbr: 'WI',
    StateName: 'Wisconsin',
    lat: 45.42372695,
    lng: -91.84833604,
  },
  {
    GEO_ID: '0500000US55007',
    STATE: '55',
    COUNTY: '007',
    NAME: 'Bayfield',
    LSAD: 'County',
    CENSUSAREA: 1477.863,
    FIPS: 'WI007',
    fips: '55007',
    StateAbbr: 'WI',
    StateName: 'Wisconsin',
    lat: 46.52418284,
    lng: -91.20077484,
  },
  {
    GEO_ID: '0500000US55017',
    STATE: '55',
    COUNTY: '017',
    NAME: 'Chippewa',
    LSAD: 'County',
    CENSUSAREA: 1008.373,
    FIPS: 'WI017',
    fips: '55017',
    StateAbbr: 'WI',
    StateName: 'Wisconsin',
    lat: 45.06921882,
    lng: -91.28017125,
  },
  {
    GEO_ID: '0500000US55025',
    STATE: '55',
    COUNTY: '025',
    NAME: 'Dane',
    LSAD: 'County',
    CENSUSAREA: 1197.239,
    FIPS: 'WI025',
    fips: '55025',
    StateAbbr: 'WI',
    StateName: 'Wisconsin',
    lat: 43.06746565,
    lng: -89.41786325,
  },
  {
    GEO_ID: '0500000US49029',
    STATE: '49',
    COUNTY: '029',
    NAME: 'Morgan',
    LSAD: 'County',
    CENSUSAREA: 609.196,
    FIPS: 'UT029',
    fips: '49029',
    StateAbbr: 'UT',
    StateName: 'Utah',
    lat: 41.08889094,
    lng: -111.5734451,
  },
  {
    GEO_ID: '0500000US49031',
    STATE: '49',
    COUNTY: '031',
    NAME: 'Piute',
    LSAD: 'County',
    CENSUSAREA: 757.791,
    FIPS: 'UT031',
    fips: '49031',
    StateAbbr: 'UT',
    StateName: 'Utah',
    lat: 38.336223,
    lng: -112.1276059,
  },
  {
    GEO_ID: '0500000US49035',
    STATE: '49',
    COUNTY: '035',
    NAME: 'Salt Lake',
    LSAD: 'County',
    CENSUSAREA: 742.282,
    FIPS: 'UT035',
    fips: '49035',
    StateAbbr: 'UT',
    StateName: 'Utah',
    lat: 40.66728813,
    lng: -111.9241028,
  },
  {
    GEO_ID: '0500000US49051',
    STATE: '49',
    COUNTY: '051',
    NAME: 'Wasatch',
    LSAD: 'County',
    CENSUSAREA: 1175.501,
    FIPS: 'UT051',
    fips: '49051',
    StateAbbr: 'UT',
    StateName: 'Utah',
    lat: 40.33064897,
    lng: -111.169609,
  },
  {
    GEO_ID: '0500000US50005',
    STATE: '50',
    COUNTY: '005',
    NAME: 'Caledonia',
    LSAD: 'County',
    CENSUSAREA: 648.864,
    FIPS: 'VT005',
    fips: '50005',
    StateAbbr: 'VT',
    StateName: 'Vermont',
    lat: 44.46487758,
    lng: -72.10243367,
  },
  {
    GEO_ID: '0500000US50019',
    STATE: '50',
    COUNTY: '019',
    NAME: 'Orleans',
    LSAD: 'County',
    CENSUSAREA: 693.268,
    FIPS: 'VT019',
    fips: '50019',
    StateAbbr: 'VT',
    StateName: 'Vermont',
    lat: 44.82900254,
    lng: -72.24360335,
  },
  {
    GEO_ID: '0500000US51007',
    STATE: '51',
    COUNTY: '007',
    NAME: 'Amelia',
    LSAD: 'County',
    CENSUSAREA: 355.269,
    FIPS: 'VA007',
    fips: '51007',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 37.33550025,
    lng: -77.9752747,
  },
  {
    GEO_ID: '0500000US51017',
    STATE: '51',
    COUNTY: '017',
    NAME: 'Bath',
    LSAD: 'County',
    CENSUSAREA: 529.158,
    FIPS: 'VA017',
    fips: '51017',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 38.05836054,
    lng: -79.74123354,
  },
  {
    GEO_ID: '0500000US51029',
    STATE: '51',
    COUNTY: '029',
    NAME: 'Buckingham',
    LSAD: 'County',
    CENSUSAREA: 579.657,
    FIPS: 'VA029',
    fips: '51029',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 37.57196671,
    lng: -78.52851211,
  },
  {
    GEO_ID: '0500000US51043',
    STATE: '51',
    COUNTY: '043',
    NAME: 'Clarke',
    LSAD: 'County',
    CENSUSAREA: 176.177,
    FIPS: 'VA043',
    fips: '51043',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 39.11236047,
    lng: -77.99656139,
  },
  {
    GEO_ID: '0500000US51047',
    STATE: '51',
    COUNTY: '047',
    NAME: 'Culpeper',
    LSAD: 'County',
    CENSUSAREA: 379.234,
    FIPS: 'VA047',
    fips: '51047',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 38.48620048,
    lng: -77.95541958,
  },
  {
    GEO_ID: '0500000US51063',
    STATE: '51',
    COUNTY: '063',
    NAME: 'Floyd',
    LSAD: 'County',
    CENSUSAREA: 380.42,
    FIPS: 'VA063',
    fips: '51063',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 36.93162812,
    lng: -80.36184172,
  },
  {
    GEO_ID: '0500000US51075',
    STATE: '51',
    COUNTY: '075',
    NAME: 'Goochland',
    LSAD: 'County',
    CENSUSAREA: 281.415,
    FIPS: 'VA075',
    fips: '51075',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 37.72200089,
    lng: -77.91594304,
  },
  {
    GEO_ID: '0500000US51101',
    STATE: '51',
    COUNTY: '101',
    NAME: 'King William',
    LSAD: 'County',
    CENSUSAREA: 273.941,
    FIPS: 'VA101',
    fips: '51101',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 37.70635361,
    lng: -77.08804071,
  },
  {
    GEO_ID: '0500000US51111',
    STATE: '51',
    COUNTY: '111',
    NAME: 'Lunenburg',
    LSAD: 'County',
    CENSUSAREA: 431.683,
    FIPS: 'VA111',
    fips: '51111',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 36.94606214,
    lng: -78.24084587,
  },
  {
    GEO_ID: '0500000US51113',
    STATE: '51',
    COUNTY: '113',
    NAME: 'Madison',
    LSAD: 'County',
    CENSUSAREA: 320.683,
    FIPS: 'VA113',
    fips: '51113',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 38.41366284,
    lng: -78.27950884,
  },
  {
    GEO_ID: '0500000US01023',
    STATE: '01',
    COUNTY: '023',
    NAME: 'Choctaw',
    LSAD: 'County',
    CENSUSAREA: 913.5,
    FIPS: 'AL023',
    fips: '01023',
    StateAbbr: 'AL',
    StateName: 'Alabama',
    lat: 32.02019311,
    lng: -88.26305322,
  },
  {
    GEO_ID: '0500000US01025',
    STATE: '01',
    COUNTY: '025',
    NAME: 'Clarke',
    LSAD: 'County',
    CENSUSAREA: 1238.465,
    FIPS: 'AL025',
    fips: '01025',
    StateAbbr: 'AL',
    StateName: 'Alabama',
    lat: 31.67695451,
    lng: -87.83077181,
  },
  {
    GEO_ID: '0500000US01029',
    STATE: '01',
    COUNTY: '029',
    NAME: 'Cleburne',
    LSAD: 'County',
    CENSUSAREA: 560.1,
    FIPS: 'AL029',
    fips: '01029',
    StateAbbr: 'AL',
    StateName: 'Alabama',
    lat: 33.67495934,
    lng: -85.51786948,
  },
  {
    GEO_ID: '0500000US01031',
    STATE: '01',
    COUNTY: '031',
    NAME: 'Coffee',
    LSAD: 'County',
    CENSUSAREA: 678.972,
    FIPS: 'AL031',
    fips: '01031',
    StateAbbr: 'AL',
    StateName: 'Alabama',
    lat: 31.40188396,
    lng: -85.98828821,
  },
  {
    GEO_ID: '0500000US01037',
    STATE: '01',
    COUNTY: '037',
    NAME: 'Coosa',
    LSAD: 'County',
    CENSUSAREA: 650.926,
    FIPS: 'AL037',
    fips: '01037',
    StateAbbr: 'AL',
    StateName: 'Alabama',
    lat: 32.9362956,
    lng: -86.24789409,
  },
  {
    GEO_ID: '0500000US01047',
    STATE: '01',
    COUNTY: '047',
    NAME: 'Dallas',
    LSAD: 'County',
    CENSUSAREA: 978.695,
    FIPS: 'AL047',
    fips: '01047',
    StateAbbr: 'AL',
    StateName: 'Alabama',
    lat: 32.32606741,
    lng: -87.10649231,
  },
  {
    GEO_ID: '0500000US01055',
    STATE: '01',
    COUNTY: '055',
    NAME: 'Etowah',
    LSAD: 'County',
    CENSUSAREA: 534.991,
    FIPS: 'AL055',
    fips: '01055',
    StateAbbr: 'AL',
    StateName: 'Alabama',
    lat: 34.04518886,
    lng: -86.03476854,
  },
  {
    GEO_ID: '0500000US01059',
    STATE: '01',
    COUNTY: '059',
    NAME: 'Franklin',
    LSAD: 'County',
    CENSUSAREA: 633.821,
    FIPS: 'AL059',
    fips: '01059',
    StateAbbr: 'AL',
    StateName: 'Alabama',
    lat: 34.44238135,
    lng: -87.843283,
  },
  {
    GEO_ID: '0500000US01061',
    STATE: '01',
    COUNTY: '061',
    NAME: 'Geneva',
    LSAD: 'County',
    CENSUSAREA: 574.408,
    FIPS: 'AL061',
    fips: '01061',
    StateAbbr: 'AL',
    StateName: 'Alabama',
    lat: 31.09486905,
    lng: -85.83932953,
  },
  {
    GEO_ID: '0500000US01067',
    STATE: '01',
    COUNTY: '067',
    NAME: 'Henry',
    LSAD: 'County',
    CENSUSAREA: 561.75,
    FIPS: 'AL067',
    fips: '01067',
    StateAbbr: 'AL',
    StateName: 'Alabama',
    lat: 31.51487991,
    lng: -85.24094152,
  },
  {
    GEO_ID: '0500000US01071',
    STATE: '01',
    COUNTY: '071',
    NAME: 'Jackson',
    LSAD: 'County',
    CENSUSAREA: 1077.868,
    FIPS: 'AL071',
    fips: '01071',
    StateAbbr: 'AL',
    StateName: 'Alabama',
    lat: 34.77954231,
    lng: -85.99947584,
  },
  {
    GEO_ID: '0500000US01081',
    STATE: '01',
    COUNTY: '081',
    NAME: 'Lee',
    LSAD: 'County',
    CENSUSAREA: 607.536,
    FIPS: 'AL081',
    fips: '01081',
    StateAbbr: 'AL',
    StateName: 'Alabama',
    lat: 32.60106116,
    lng: -85.35522451,
  },
  {
    GEO_ID: '0500000US01085',
    STATE: '01',
    COUNTY: '085',
    NAME: 'Lowndes',
    LSAD: 'County',
    CENSUSAREA: 715.911,
    FIPS: 'AL085',
    fips: '01085',
    StateAbbr: 'AL',
    StateName: 'Alabama',
    lat: 32.1550404,
    lng: -86.65002353,
  },
  {
    GEO_ID: '0500000US01087',
    STATE: '01',
    COUNTY: '087',
    NAME: 'Macon',
    LSAD: 'County',
    CENSUSAREA: 608.885,
    FIPS: 'AL087',
    fips: '01087',
    StateAbbr: 'AL',
    StateName: 'Alabama',
    lat: 32.38585029,
    lng: -85.69260703,
  },
  {
    GEO_ID: '0500000US01089',
    STATE: '01',
    COUNTY: '089',
    NAME: 'Madison',
    LSAD: 'County',
    CENSUSAREA: 801.593,
    FIPS: 'AL089',
    fips: '01089',
    StateAbbr: 'AL',
    StateName: 'Alabama',
    lat: 34.76292257,
    lng: -86.55056927,
  },
  {
    GEO_ID: '0500000US02070',
    STATE: '02',
    COUNTY: '070',
    NAME: 'Dillingham',
    LSAD: 'CA',
    CENSUSAREA: 18568.778,
    FIPS: 'AK070',
    fips: '02070',
    StateAbbr: 'AK',
    StateName: 'Alaska',
    lat: 59.79832305,
    lng: -158.2141634,
  },
  {
    GEO_ID: '0500000US02110',
    STATE: '02',
    COUNTY: '110',
    NAME: 'Juneau',
    LSAD: 'Cty&Bor',
    CENSUSAREA: 2701.931,
    FIPS: 'AK110',
    fips: '02110',
    StateAbbr: 'AK',
    StateName: 'Alaska',
    lat: 58.45091866,
    lng: -134.1764399,
  },
  {
    GEO_ID: '0500000US02130',
    STATE: '02',
    COUNTY: '130',
    NAME: 'Ketchikan Gateway',
    LSAD: 'Borough',
    CENSUSAREA: 4858.414,
    FIPS: 'AK130',
    fips: '02130',
    StateAbbr: 'AK',
    StateName: 'Alaska',
    lat: 55.58672276,
    lng: -130.9369511,
  },
  {
    GEO_ID: '0500000US02185',
    STATE: '02',
    COUNTY: '185',
    NAME: 'North Slope',
    LSAD: 'Borough',
    CENSUSAREA: 88695.413,
    FIPS: 'AK185',
    fips: '02185',
    StateAbbr: 'AK',
    StateName: 'Alaska',
    lat: 69.31507516,
    lng: -153.4702536,
  },
  {
    GEO_ID: '0500000US02195',
    STATE: '02',
    COUNTY: '195',
    NAME: 'Petersburg',
    LSAD: 'CA',
    CENSUSAREA: 3281.982,
    FIPS: 'AK195',
    fips: '02195',
    StateAbbr: 'AK',
    StateName: 'Alaska',
    lat: 57.11988808,
    lng: -132.9333517,
  },
  {
    GEO_ID: '0500000US02220',
    STATE: '02',
    COUNTY: '220',
    NAME: 'Sitka',
    LSAD: 'Cty&Bor',
    CENSUSAREA: 2870.339,
    FIPS: 'AK220',
    fips: '02220',
    StateAbbr: 'AK',
    StateName: 'Alaska',
    lat: 57.23393263,
    lng: -135.3151746,
  },
  {
    GEO_ID: '0500000US02230',
    STATE: '02',
    COUNTY: '230',
    NAME: 'Skagway',
    LSAD: 'Muny',
    CENSUSAREA: 452.325,
    FIPS: 'AK230',
    fips: '02230',
    StateAbbr: 'AK',
    StateName: 'Alaska',
    lat: 59.55833766,
    lng: -135.3374592,
  },
  {
    GEO_ID: '0500000US05143',
    STATE: '05',
    COUNTY: '143',
    NAME: 'Washington',
    LSAD: 'County',
    CENSUSAREA: 941.966,
    FIPS: 'AR143',
    fips: '05143',
    StateAbbr: 'AR',
    StateName: 'Arkansas',
    lat: 35.97899668,
    lng: -94.21571712,
  },
  {
    GEO_ID: '0500000US05147',
    STATE: '05',
    COUNTY: '147',
    NAME: 'Woodruff',
    LSAD: 'County',
    CENSUSAREA: 586.791,
    FIPS: 'AR147',
    fips: '05147',
    StateAbbr: 'AR',
    StateName: 'Arkansas',
    lat: 35.18620495,
    lng: -91.24302721,
  },
  {
    GEO_ID: '0500000US06003',
    STATE: '06',
    COUNTY: '003',
    NAME: 'Alpine',
    LSAD: 'County',
    CENSUSAREA: 738.332,
    FIPS: 'CA003',
    fips: '06003',
    StateAbbr: 'CA',
    StateName: 'California',
    lat: 38.59721624,
    lng: -119.8206263,
  },
  {
    GEO_ID: '0500000US06007',
    STATE: '06',
    COUNTY: '007',
    NAME: 'Butte',
    LSAD: 'County',
    CENSUSAREA: 1636.464,
    FIPS: 'CA007',
    fips: '06007',
    StateAbbr: 'CA',
    StateName: 'California',
    lat: 39.66681952,
    lng: -121.6008508,
  },
  {
    GEO_ID: '0500000US01093',
    STATE: '01',
    COUNTY: '093',
    NAME: 'Marion',
    LSAD: 'County',
    CENSUSAREA: 742.292,
    FIPS: 'AL093',
    fips: '01093',
    StateAbbr: 'AL',
    StateName: 'Alabama',
    lat: 34.13691856,
    lng: -87.88740606,
  },
  {
    GEO_ID: '0500000US01095',
    STATE: '01',
    COUNTY: '095',
    NAME: 'Marshall',
    LSAD: 'County',
    CENSUSAREA: 565.842,
    FIPS: 'AL095',
    fips: '01095',
    StateAbbr: 'AL',
    StateName: 'Alabama',
    lat: 34.36690867,
    lng: -86.30615623,
  },
  {
    GEO_ID: '0500000US01097',
    STATE: '01',
    COUNTY: '097',
    NAME: 'Mobile',
    LSAD: 'County',
    CENSUSAREA: 1229.435,
    FIPS: 'AL097',
    fips: '01097',
    StateAbbr: 'AL',
    StateName: 'Alabama',
    lat: 30.77909854,
    lng: -88.20659057,
  },
  {
    GEO_ID: '0500000US01101',
    STATE: '01',
    COUNTY: '101',
    NAME: 'Montgomery',
    LSAD: 'County',
    CENSUSAREA: 784.247,
    FIPS: 'AL101',
    fips: '01101',
    StateAbbr: 'AL',
    StateName: 'Alabama',
    lat: 32.22089414,
    lng: -86.20787706,
  },
  {
    GEO_ID: '0500000US01117',
    STATE: '01',
    COUNTY: '117',
    NAME: 'Shelby',
    LSAD: 'County',
    CENSUSAREA: 784.93,
    FIPS: 'AL117',
    fips: '01117',
    StateAbbr: 'AL',
    StateName: 'Alabama',
    lat: 33.26405258,
    lng: -86.66137476,
  },
  {
    GEO_ID: '0500000US01119',
    STATE: '01',
    COUNTY: '119',
    NAME: 'Sumter',
    LSAD: 'County',
    CENSUSAREA: 903.887,
    FIPS: 'AL119',
    fips: '01119',
    StateAbbr: 'AL',
    StateName: 'Alabama',
    lat: 32.59104949,
    lng: -88.19904039,
  },
  {
    GEO_ID: '0500000US01125',
    STATE: '01',
    COUNTY: '125',
    NAME: 'Tuscaloosa',
    LSAD: 'County',
    CENSUSAREA: 1321.755,
    FIPS: 'AL125',
    fips: '01125',
    StateAbbr: 'AL',
    StateName: 'Alabama',
    lat: 33.28952146,
    lng: -87.52558359,
  },
  {
    GEO_ID: '0500000US01131',
    STATE: '01',
    COUNTY: '131',
    NAME: 'Wilcox',
    LSAD: 'County',
    CENSUSAREA: 888.498,
    FIPS: 'AL131',
    fips: '01131',
    StateAbbr: 'AL',
    StateName: 'Alabama',
    lat: 31.98928301,
    lng: -87.30786547,
  },
  {
    GEO_ID: '0500000US02020',
    STATE: '02',
    COUNTY: '020',
    NAME: 'Anchorage',
    LSAD: 'Muny',
    CENSUSAREA: 1704.683,
    FIPS: 'AK020',
    fips: '02020',
    StateAbbr: 'AK',
    StateName: 'Alaska',
    lat: 61.15068906,
    lng: -149.1118883,
  },
  {
    GEO_ID: '0500000US02068',
    STATE: '02',
    COUNTY: '068',
    NAME: 'Denali',
    LSAD: 'Borough',
    CENSUSAREA: 12751.411,
    FIPS: 'AK068',
    fips: '02068',
    StateAbbr: 'AK',
    StateName: 'Alaska',
    lat: 63.67343339,
    lng: -150.0094238,
  },
  {
    GEO_ID: '0500000US72019',
    STATE: '72',
    COUNTY: '019',
    NAME: 'Barranquitas',
    LSAD: 'Muno',
    CENSUSAREA: 34.253,
    FIPS: 'PR019',
    fips: '72019',
    StateAbbr: 'PR',
    StateName: 'Puerto Rico',
    lat: 18.20096897,
    lng: -66.31070199,
  },
  {
    GEO_ID: '0500000US72025',
    STATE: '72',
    COUNTY: '025',
    NAME: 'Caguas',
    LSAD: 'Muno',
    CENSUSAREA: 58.597,
    FIPS: 'PR025',
    fips: '72025',
    StateAbbr: 'PR',
    StateName: 'Puerto Rico',
    lat: 18.21191794,
    lng: -66.05078088,
  },
  {
    GEO_ID: '0500000US72031',
    STATE: '72',
    COUNTY: '031',
    NAME: 'Carolina',
    LSAD: 'Muno',
    CENSUSAREA: 45.321,
    FIPS: 'PR031',
    fips: '72031',
    StateAbbr: 'PR',
    StateName: 'Puerto Rico',
    lat: 18.37503034,
    lng: -65.95606154,
  },
  {
    GEO_ID: '0500000US72045',
    STATE: '72',
    COUNTY: '045',
    NAME: 'Comerío',
    LSAD: 'Muno',
    CENSUSAREA: 28.401,
    FIPS: 'PR045',
    fips: '72045',
    StateAbbr: 'PR',
    StateName: 'Puerto Rico',
    lat: 18.223828,
    lng: -66.22191082,
  },
  {
    GEO_ID: '0500000US72054',
    STATE: '72',
    COUNTY: '054',
    NAME: 'Florida',
    LSAD: 'Muno',
    CENSUSAREA: 15.209,
    FIPS: 'PR054',
    fips: '72054',
    StateAbbr: 'PR',
    StateName: 'Puerto Rico',
    lat: 18.37330319,
    lng: -66.5598922,
  },
  {
    GEO_ID: '0500000US72061',
    STATE: '72',
    COUNTY: '061',
    NAME: 'Guaynabo',
    LSAD: 'Muno',
    CENSUSAREA: 27.579,
    FIPS: 'PR061',
    fips: '72061',
    StateAbbr: 'PR',
    StateName: 'Puerto Rico',
    lat: 18.3439498,
    lng: -66.11368632,
  },
  {
    GEO_ID: '0500000US72069',
    STATE: '72',
    COUNTY: '069',
    NAME: 'Humacao',
    LSAD: 'Muno',
    CENSUSAREA: 44.755,
    FIPS: 'PR069',
    fips: '72069',
    StateAbbr: 'PR',
    StateName: 'Puerto Rico',
    lat: 18.14521821,
    lng: -65.81103116,
  },
  {
    GEO_ID: '0500000US72083',
    STATE: '72',
    COUNTY: '083',
    NAME: 'Las Marías',
    LSAD: 'Muno',
    CENSUSAREA: 46.36,
    FIPS: 'PR083',
    fips: '72083',
    StateAbbr: 'PR',
    StateName: 'Puerto Rico',
    lat: 18.2371973,
    lng: -66.98385766,
  },
  {
    GEO_ID: '0500000US72093',
    STATE: '72',
    COUNTY: '093',
    NAME: 'Maricao',
    LSAD: 'Muno',
    CENSUSAREA: 36.623,
    FIPS: 'PR093',
    fips: '72093',
    StateAbbr: 'PR',
    StateName: 'Puerto Rico',
    lat: 18.1724996,
    lng: -66.94265627,
  },
  {
    GEO_ID: '0500000US72105',
    STATE: '72',
    COUNTY: '105',
    NAME: 'Naranjito',
    LSAD: 'Muno',
    CENSUSAREA: 27.401,
    FIPS: 'PR105',
    fips: '72105',
    StateAbbr: 'PR',
    StateName: 'Puerto Rico',
    lat: 18.28716348,
    lng: -66.25362073,
  },
  {
    GEO_ID: '0500000US72115',
    STATE: '72',
    COUNTY: '115',
    NAME: 'Quebradillas',
    LSAD: 'Muno',
    CENSUSAREA: 22.683,
    FIPS: 'PR115',
    fips: '72115',
    StateAbbr: 'PR',
    StateName: 'Puerto Rico',
    lat: 18.44045509,
    lng: -66.92632992,
  },
  {
    GEO_ID: '0500000US72129',
    STATE: '72',
    COUNTY: '129',
    NAME: 'San Lorenzo',
    LSAD: 'Muno',
    CENSUSAREA: 53.108,
    FIPS: 'PR129',
    fips: '72129',
    StateAbbr: 'PR',
    StateName: 'Puerto Rico',
    lat: 18.14792462,
    lng: -65.97552597,
  },
  {
    GEO_ID: '0500000US72135',
    STATE: '72',
    COUNTY: '135',
    NAME: 'Toa Alta',
    LSAD: 'Muno',
    CENSUSAREA: 27.016,
    FIPS: 'PR135',
    fips: '72135',
    StateAbbr: 'PR',
    StateName: 'Puerto Rico',
    lat: 18.36223592,
    lng: -66.2462674,
  },
  {
    GEO_ID: '0500000US72145',
    STATE: '72',
    COUNTY: '145',
    NAME: 'Vega Baja',
    LSAD: 'Muno',
    CENSUSAREA: 45.856,
    FIPS: 'PR145',
    fips: '72145',
    StateAbbr: 'PR',
    StateName: 'Puerto Rico',
    lat: 18.42873078,
    lng: -66.39731281,
  },
  {
    GEO_ID: '0500000US01007',
    STATE: '01',
    COUNTY: '007',
    NAME: 'Bibb',
    LSAD: 'County',
    CENSUSAREA: 622.582,
    FIPS: 'AL007',
    fips: '01007',
    StateAbbr: 'AL',
    StateName: 'Alabama',
    lat: 32.99837607,
    lng: -87.12681433,
  },
  {
    GEO_ID: '0500000US01011',
    STATE: '01',
    COUNTY: '011',
    NAME: 'Bullock',
    LSAD: 'County',
    CENSUSAREA: 622.805,
    FIPS: 'AL011',
    fips: '01011',
    StateAbbr: 'AL',
    StateName: 'Alabama',
    lat: 32.10045868,
    lng: -85.71572943,
  },
  {
    GEO_ID: '0500000US01015',
    STATE: '01',
    COUNTY: '015',
    NAME: 'Calhoun',
    LSAD: 'County',
    CENSUSAREA: 605.868,
    FIPS: 'AL015',
    fips: '01015',
    StateAbbr: 'AL',
    StateName: 'Alabama',
    lat: 33.77141549,
    lng: -85.82574705,
  },
  {
    GEO_ID: '0500000US06045',
    STATE: '06',
    COUNTY: '045',
    NAME: 'Mendocino',
    LSAD: 'County',
    CENSUSAREA: 3506.343,
    FIPS: 'CA045',
    fips: '06045',
    StateAbbr: 'CA',
    StateName: 'California',
    lat: 39.44027921,
    lng: -123.3914743,
  },
  {
    GEO_ID: '0500000US06049',
    STATE: '06',
    COUNTY: '049',
    NAME: 'Modoc',
    LSAD: 'County',
    CENSUSAREA: 3917.77,
    FIPS: 'CA049',
    fips: '06049',
    StateAbbr: 'CA',
    StateName: 'California',
    lat: 41.58969344,
    lng: -120.7251192,
  },
  {
    GEO_ID: '0500000US06051',
    STATE: '06',
    COUNTY: '051',
    NAME: 'Mono',
    LSAD: 'County',
    CENSUSAREA: 3048.982,
    FIPS: 'CA051',
    fips: '06051',
    StateAbbr: 'CA',
    StateName: 'California',
    lat: 37.93940061,
    lng: -118.8872447,
  },
  {
    GEO_ID: '0500000US06057',
    STATE: '06',
    COUNTY: '057',
    NAME: 'Nevada',
    LSAD: 'County',
    CENSUSAREA: 957.772,
    FIPS: 'CA057',
    fips: '06057',
    StateAbbr: 'CA',
    StateName: 'California',
    lat: 39.30150048,
    lng: -120.7682923,
  },
  {
    GEO_ID: '0500000US06059',
    STATE: '06',
    COUNTY: '059',
    NAME: 'Orange',
    LSAD: 'County',
    CENSUSAREA: 790.568,
    FIPS: 'CA059',
    fips: '06059',
    StateAbbr: 'CA',
    StateName: 'California',
    lat: 33.70311134,
    lng: -117.760976,
  },
  {
    GEO_ID: '0500000US06065',
    STATE: '06',
    COUNTY: '065',
    NAME: 'Riverside',
    LSAD: 'County',
    CENSUSAREA: 7206.48,
    FIPS: 'CA065',
    fips: '06065',
    StateAbbr: 'CA',
    StateName: 'California',
    lat: 33.74329781,
    lng: -115.9939021,
  },
  {
    GEO_ID: '0500000US06069',
    STATE: '06',
    COUNTY: '069',
    NAME: 'San Benito',
    LSAD: 'County',
    CENSUSAREA: 1388.71,
    FIPS: 'CA069',
    fips: '06069',
    StateAbbr: 'CA',
    StateName: 'California',
    lat: 36.60559815,
    lng: -121.0752215,
  },
  {
    GEO_ID: '0500000US06073',
    STATE: '06',
    COUNTY: '073',
    NAME: 'San Diego',
    LSAD: 'County',
    CENSUSAREA: 4206.63,
    FIPS: 'CA073',
    fips: '06073',
    StateAbbr: 'CA',
    StateName: 'California',
    lat: 33.03386576,
    lng: -116.7353982,
  },
  {
    GEO_ID: '0500000US06075',
    STATE: '06',
    COUNTY: '075',
    NAME: 'San Francisco',
    LSAD: 'County',
    CENSUSAREA: 46.873,
    FIPS: 'CA075',
    fips: '06075',
    StateAbbr: 'CA',
    StateName: 'California',
    lat: 37.75668116,
    lng: -122.4396708,
  },
  {
    GEO_ID: '0500000US06113',
    STATE: '06',
    COUNTY: '113',
    NAME: 'Yolo',
    LSAD: 'County',
    CENSUSAREA: 1014.689,
    FIPS: 'CA113',
    fips: '06113',
    StateAbbr: 'CA',
    StateName: 'California',
    lat: 38.68648941,
    lng: -121.9015118,
  },
  {
    GEO_ID: '0500000US08001',
    STATE: '08',
    COUNTY: '001',
    NAME: 'Adams',
    LSAD: 'County',
    CENSUSAREA: 1167.653,
    FIPS: 'CO001',
    fips: '08001',
    StateAbbr: 'CO',
    StateName: 'Colorado',
    lat: 39.87357077,
    lng: -104.3375808,
  },
  {
    GEO_ID: '0500000US08007',
    STATE: '08',
    COUNTY: '007',
    NAME: 'Archuleta',
    LSAD: 'County',
    CENSUSAREA: 1350.175,
    FIPS: 'CO007',
    fips: '08007',
    StateAbbr: 'CO',
    StateName: 'Colorado',
    lat: 37.19365584,
    lng: -107.0481757,
  },
  {
    GEO_ID: '0500000US08009',
    STATE: '08',
    COUNTY: '009',
    NAME: 'Baca',
    LSAD: 'County',
    CENSUSAREA: 2554.967,
    FIPS: 'CO009',
    fips: '08009',
    StateAbbr: 'CO',
    StateName: 'Colorado',
    lat: 37.31923997,
    lng: -102.5601186,
  },
  {
    GEO_ID: '0500000US08015',
    STATE: '08',
    COUNTY: '015',
    NAME: 'Chaffee',
    LSAD: 'County',
    CENSUSAREA: 1013.403,
    FIPS: 'CO015',
    fips: '08015',
    StateAbbr: 'CO',
    StateName: 'Colorado',
    lat: 38.74728839,
    lng: -106.1940269,
  },
  {
    GEO_ID: '0500000US08017',
    STATE: '08',
    COUNTY: '017',
    NAME: 'Cheyenne',
    LSAD: 'County',
    CENSUSAREA: 1778.276,
    FIPS: 'CO017',
    fips: '08017',
    StateAbbr: 'CO',
    StateName: 'Colorado',
    lat: 38.82780723,
    lng: -102.6036365,
  },
  {
    GEO_ID: '0500000US08021',
    STATE: '08',
    COUNTY: '021',
    NAME: 'Conejos',
    LSAD: 'County',
    CENSUSAREA: 1287.391,
    FIPS: 'CO021',
    fips: '08021',
    StateAbbr: 'CO',
    StateName: 'Colorado',
    lat: 37.20059578,
    lng: -106.1911108,
  },
  {
    GEO_ID: '0500000US08023',
    STATE: '08',
    COUNTY: '023',
    NAME: 'Costilla',
    LSAD: 'County',
    CENSUSAREA: 1226.952,
    FIPS: 'CO023',
    fips: '08023',
    StateAbbr: 'CO',
    StateName: 'Colorado',
    lat: 37.27823118,
    lng: -105.4278551,
  },
  {
    GEO_ID: '0500000US08029',
    STATE: '08',
    COUNTY: '029',
    NAME: 'Delta',
    LSAD: 'County',
    CENSUSAREA: 1142.05,
    FIPS: 'CO029',
    fips: '08029',
    StateAbbr: 'CO',
    StateName: 'Colorado',
    lat: 38.86135647,
    lng: -107.8629449,
  },
  {
    GEO_ID: '0500000US08037',
    STATE: '08',
    COUNTY: '037',
    NAME: 'Eagle',
    LSAD: 'County',
    CENSUSAREA: 1684.53,
    FIPS: 'CO037',
    fips: '08037',
    StateAbbr: 'CO',
    StateName: 'Colorado',
    lat: 39.62801893,
    lng: -106.6953848,
  },
  {
    GEO_ID: '0500000US08043',
    STATE: '08',
    COUNTY: '043',
    NAME: 'Fremont',
    LSAD: 'County',
    CENSUSAREA: 1533.068,
    FIPS: 'CO043',
    fips: '08043',
    StateAbbr: 'CO',
    StateName: 'Colorado',
    lat: 38.47306423,
    lng: -105.4399703,
  },
  {
    GEO_ID: '0500000US06081',
    STATE: '06',
    COUNTY: '081',
    NAME: 'San Mateo',
    LSAD: 'County',
    CENSUSAREA: 448.408,
    FIPS: 'CA081',
    fips: '06081',
    StateAbbr: 'CA',
    StateName: 'California',
    lat: 37.42298018,
    lng: -122.328899,
  },
  {
    GEO_ID: '0500000US06083',
    STATE: '06',
    COUNTY: '083',
    NAME: 'Santa Barbara',
    LSAD: 'County',
    CENSUSAREA: 2735.085,
    FIPS: 'CA083',
    fips: '06083',
    StateAbbr: 'CA',
    StateName: 'California',
    lat: 34.67142103,
    lng: -120.0150919,
  },
  {
    GEO_ID: '0500000US06087',
    STATE: '06',
    COUNTY: '087',
    NAME: 'Santa Cruz',
    LSAD: 'County',
    CENSUSAREA: 445.17,
    FIPS: 'CA087',
    fips: '06087',
    StateAbbr: 'CA',
    StateName: 'California',
    lat: 37.0561908,
    lng: -122.0017742,
  },
  {
    GEO_ID: '0500000US06095',
    STATE: '06',
    COUNTY: '095',
    NAME: 'Solano',
    LSAD: 'County',
    CENSUSAREA: 821.765,
    FIPS: 'CA095',
    fips: '06095',
    StateAbbr: 'CA',
    StateName: 'California',
    lat: 38.27025048,
    lng: -121.9331593,
  },
  {
    GEO_ID: '0500000US06101',
    STATE: '06',
    COUNTY: '101',
    NAME: 'Sutter',
    LSAD: 'County',
    CENSUSAREA: 602.41,
    FIPS: 'CA101',
    fips: '06101',
    StateAbbr: 'CA',
    StateName: 'California',
    lat: 39.03464654,
    lng: -121.6946189,
  },
  {
    GEO_ID: '0500000US06103',
    STATE: '06',
    COUNTY: '103',
    NAME: 'Tehama',
    LSAD: 'County',
    CENSUSAREA: 2949.709,
    FIPS: 'CA103',
    fips: '06103',
    StateAbbr: 'CA',
    StateName: 'California',
    lat: 40.12559985,
    lng: -122.2338547,
  },
  {
    GEO_ID: '0500000US06109',
    STATE: '06',
    COUNTY: '109',
    NAME: 'Tuolumne',
    LSAD: 'County',
    CENSUSAREA: 2220.884,
    FIPS: 'CA109',
    fips: '06109',
    StateAbbr: 'CA',
    StateName: 'California',
    lat: 38.02748982,
    lng: -119.9551317,
  },
  {
    GEO_ID: '0500000US06009',
    STATE: '06',
    COUNTY: '009',
    NAME: 'Calaveras',
    LSAD: 'County',
    CENSUSAREA: 1020.012,
    FIPS: 'CA009',
    fips: '06009',
    StateAbbr: 'CA',
    StateName: 'California',
    lat: 38.20478056,
    lng: -120.554132,
  },
  {
    GEO_ID: '0500000US06013',
    STATE: '06',
    COUNTY: '013',
    NAME: 'Contra Costa',
    LSAD: 'County',
    CENSUSAREA: 715.937,
    FIPS: 'CA013',
    fips: '06013',
    StateAbbr: 'CA',
    StateName: 'California',
    lat: 37.91933375,
    lng: -121.9281273,
  },
  {
    GEO_ID: '0500000US06015',
    STATE: '06',
    COUNTY: '015',
    NAME: 'Del Norte',
    LSAD: 'County',
    CENSUSAREA: 1006.373,
    FIPS: 'CA015',
    fips: '06015',
    StateAbbr: 'CA',
    StateName: 'California',
    lat: 41.74323775,
    lng: -123.8971513,
  },
  {
    GEO_ID: '0500000US02013',
    STATE: '02',
    COUNTY: '013',
    NAME: 'Aleutians East',
    LSAD: 'Borough',
    CENSUSAREA: 6981.943,
    FIPS: 'AK013',
    fips: '02013',
    StateAbbr: 'AK',
    StateName: 'Alaska',
    lat: 55.36032516,
    lng: -161.9642606,
  },
  {
    GEO_ID: '0500000US13149',
    STATE: '13',
    COUNTY: '149',
    NAME: 'Heard',
    LSAD: 'County',
    CENSUSAREA: 296.03,
    FIPS: 'GA149',
    fips: '13149',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 33.296853,
    lng: -85.12848262,
  },
  {
    GEO_ID: '0500000US20059',
    STATE: '20',
    COUNTY: '059',
    NAME: 'Franklin',
    LSAD: 'County',
    CENSUSAREA: 571.756,
    FIPS: 'KS059',
    fips: '20059',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 38.56442768,
    lng: -95.28621552,
  },
  {
    GEO_ID: '0500000US06025',
    STATE: '06',
    COUNTY: '025',
    NAME: 'Imperial',
    LSAD: 'County',
    CENSUSAREA: 4176.603,
    FIPS: 'CA025',
    fips: '06025',
    StateAbbr: 'CA',
    StateName: 'California',
    lat: 33.03935669,
    lng: -115.3657218,
  },
  {
    GEO_ID: '0500000US06029',
    STATE: '06',
    COUNTY: '029',
    NAME: 'Kern',
    LSAD: 'County',
    CENSUSAREA: 8131.916,
    FIPS: 'CA029',
    fips: '06029',
    StateAbbr: 'CA',
    StateName: 'California',
    lat: 35.34292819,
    lng: -118.72959,
  },
  {
    GEO_ID: '0500000US06031',
    STATE: '06',
    COUNTY: '031',
    NAME: 'Kings',
    LSAD: 'County',
    CENSUSAREA: 1389.42,
    FIPS: 'CA031',
    fips: '06031',
    StateAbbr: 'CA',
    StateName: 'California',
    lat: 36.07520008,
    lng: -119.8151995,
  },
  {
    GEO_ID: '0500000US06039',
    STATE: '06',
    COUNTY: '039',
    NAME: 'Madera',
    LSAD: 'County',
    CENSUSAREA: 2137.069,
    FIPS: 'CA039',
    fips: '06039',
    StateAbbr: 'CA',
    StateName: 'California',
    lat: 37.21825802,
    lng: -119.7624357,
  },
  {
    GEO_ID: '0500000US13067',
    STATE: '13',
    COUNTY: '067',
    NAME: 'Cobb',
    LSAD: 'County',
    CENSUSAREA: 339.549,
    FIPS: 'GA067',
    fips: '13067',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 33.94183776,
    lng: -84.57652963,
  },
  {
    GEO_ID: '0500000US13069',
    STATE: '13',
    COUNTY: '069',
    NAME: 'Coffee',
    LSAD: 'County',
    CENSUSAREA: 575.097,
    FIPS: 'GA069',
    fips: '13069',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 31.54918005,
    lng: -82.84967311,
  },
  {
    GEO_ID: '0500000US13073',
    STATE: '13',
    COUNTY: '073',
    NAME: 'Columbia',
    LSAD: 'County',
    CENSUSAREA: 290.09,
    FIPS: 'GA073',
    fips: '13073',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 33.54404762,
    lng: -82.26371951,
  },
  {
    GEO_ID: '0500000US13077',
    STATE: '13',
    COUNTY: '077',
    NAME: 'Coweta',
    LSAD: 'County',
    CENSUSAREA: 440.892,
    FIPS: 'GA077',
    fips: '13077',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 33.35346702,
    lng: -84.76342787,
  },
  {
    GEO_ID: '0500000US13081',
    STATE: '13',
    COUNTY: '081',
    NAME: 'Crisp',
    LSAD: 'County',
    CENSUSAREA: 272.583,
    FIPS: 'GA081',
    fips: '13081',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 31.92287244,
    lng: -83.76727919,
  },
  {
    GEO_ID: '0500000US13087',
    STATE: '13',
    COUNTY: '087',
    NAME: 'Decatur',
    LSAD: 'County',
    CENSUSAREA: 597.14,
    FIPS: 'GA087',
    fips: '13087',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 30.87807864,
    lng: -84.5795682,
  },
  {
    GEO_ID: '0500000US13093',
    STATE: '13',
    COUNTY: '093',
    NAME: 'Dooly',
    LSAD: 'County',
    CENSUSAREA: 391.94,
    FIPS: 'GA093',
    fips: '13093',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 32.15728777,
    lng: -83.79901736,
  },
  {
    GEO_ID: '0500000US13095',
    STATE: '13',
    COUNTY: '095',
    NAME: 'Dougherty',
    LSAD: 'County',
    CENSUSAREA: 328.689,
    FIPS: 'GA095',
    fips: '13095',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 31.53329476,
    lng: -84.21604918,
  },
  {
    GEO_ID: '0500000US13101',
    STATE: '13',
    COUNTY: '101',
    NAME: 'Echols',
    LSAD: 'County',
    CENSUSAREA: 414.894,
    FIPS: 'GA101',
    fips: '13101',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 30.70991003,
    lng: -82.89413878,
  },
  {
    GEO_ID: '0500000US13103',
    STATE: '13',
    COUNTY: '103',
    NAME: 'Effingham',
    LSAD: 'County',
    CENSUSAREA: 477.7,
    FIPS: 'GA103',
    fips: '13103',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 32.3672031,
    lng: -81.34134143,
  },
  {
    GEO_ID: '0500000US13109',
    STATE: '13',
    COUNTY: '109',
    NAME: 'Evans',
    LSAD: 'County',
    CENSUSAREA: 182.853,
    FIPS: 'GA109',
    fips: '13109',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 32.1569194,
    lng: -81.88687929,
  },
  {
    GEO_ID: '0500000US13115',
    STATE: '13',
    COUNTY: '115',
    NAME: 'Floyd',
    LSAD: 'County',
    CENSUSAREA: 509.911,
    FIPS: 'GA115',
    fips: '13115',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 34.26315113,
    lng: -85.21440801,
  },
  {
    GEO_ID: '0500000US13119',
    STATE: '13',
    COUNTY: '119',
    NAME: 'Franklin',
    LSAD: 'County',
    CENSUSAREA: 261.497,
    FIPS: 'GA119',
    fips: '13119',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 34.37559112,
    lng: -83.2291908,
  },
  {
    GEO_ID: '0500000US08061',
    STATE: '08',
    COUNTY: '061',
    NAME: 'Kiowa',
    LSAD: 'County',
    CENSUSAREA: 1767.767,
    FIPS: 'CO061',
    fips: '08061',
    StateAbbr: 'CO',
    StateName: 'Colorado',
    lat: 38.43263854,
    lng: -102.7389041,
  },
  {
    GEO_ID: '0500000US08063',
    STATE: '08',
    COUNTY: '063',
    NAME: 'Kit Carson',
    LSAD: 'County',
    CENSUSAREA: 2160.822,
    FIPS: 'CO063',
    fips: '08063',
    StateAbbr: 'CO',
    StateName: 'Colorado',
    lat: 39.30507096,
    lng: -102.60233,
  },
  {
    GEO_ID: '0500000US08069',
    STATE: '08',
    COUNTY: '069',
    NAME: 'Larimer',
    LSAD: 'County',
    CENSUSAREA: 2596.002,
    FIPS: 'CO069',
    fips: '08069',
    StateAbbr: 'CO',
    StateName: 'Colorado',
    lat: 40.6665145,
    lng: -105.4615545,
  },
  {
    GEO_ID: '0500000US12089',
    STATE: '12',
    COUNTY: '089',
    NAME: 'Nassau',
    LSAD: 'County',
    CENSUSAREA: 648.639,
    FIPS: 'FL089',
    fips: '12089',
    StateAbbr: 'FL',
    StateName: 'Florida',
    lat: 30.61062054,
    lng: -81.80190989,
  },
  {
    GEO_ID: '0500000US12091',
    STATE: '12',
    COUNTY: '091',
    NAME: 'Okaloosa',
    LSAD: 'County',
    CENSUSAREA: 930.247,
    FIPS: 'FL091',
    fips: '12091',
    StateAbbr: 'FL',
    StateName: 'Florida',
    lat: 30.69101554,
    lng: -86.59219666,
  },
  {
    GEO_ID: '0500000US12097',
    STATE: '12',
    COUNTY: '097',
    NAME: 'Osceola',
    LSAD: 'County',
    CENSUSAREA: 1327.453,
    FIPS: 'FL097',
    fips: '12097',
    StateAbbr: 'FL',
    StateName: 'Florida',
    lat: 28.06245875,
    lng: -81.14924253,
  },
  {
    GEO_ID: '0500000US12099',
    STATE: '12',
    COUNTY: '099',
    NAME: 'Palm Beach',
    LSAD: 'County',
    CENSUSAREA: 1969.763,
    FIPS: 'FL099',
    fips: '12099',
    StateAbbr: 'FL',
    StateName: 'Florida',
    lat: 26.64741122,
    lng: -80.46503899,
  },
  {
    GEO_ID: '0500000US12101',
    STATE: '12',
    COUNTY: '101',
    NAME: 'Pasco',
    LSAD: 'County',
    CENSUSAREA: 746.886,
    FIPS: 'FL101',
    fips: '12101',
    StateAbbr: 'FL',
    StateName: 'Florida',
    lat: 28.30833195,
    lng: -82.3964731,
  },
  {
    GEO_ID: '0500000US12107',
    STATE: '12',
    COUNTY: '107',
    NAME: 'Putnam',
    LSAD: 'County',
    CENSUSAREA: 727.622,
    FIPS: 'FL107',
    fips: '12107',
    StateAbbr: 'FL',
    StateName: 'Florida',
    lat: 29.60884076,
    lng: -81.7442726,
  },
  {
    GEO_ID: '0500000US12109',
    STATE: '12',
    COUNTY: '109',
    NAME: 'St. Johns',
    LSAD: 'County',
    CENSUSAREA: 600.655,
    FIPS: 'FL109',
    fips: '12109',
    StateAbbr: 'FL',
    StateName: 'Florida',
    lat: 29.90168542,
    lng: -81.4405388,
  },
  {
    GEO_ID: '0500000US12113',
    STATE: '12',
    COUNTY: '113',
    NAME: 'Santa Rosa',
    LSAD: 'County',
    CENSUSAREA: 1011.605,
    FIPS: 'FL113',
    fips: '12113',
    StateAbbr: 'FL',
    StateName: 'Florida',
    lat: 30.70016907,
    lng: -87.02194752,
  },
  {
    GEO_ID: '0500000US12115',
    STATE: '12',
    COUNTY: '115',
    NAME: 'Sarasota',
    LSAD: 'County',
    CENSUSAREA: 555.868,
    FIPS: 'FL115',
    fips: '12115',
    StateAbbr: 'FL',
    StateName: 'Florida',
    lat: 27.18432942,
    lng: -82.33141981,
  },
  {
    GEO_ID: '0500000US12117',
    STATE: '12',
    COUNTY: '117',
    NAME: 'Seminole',
    LSAD: 'County',
    CENSUSAREA: 309.221,
    FIPS: 'FL117',
    fips: '12117',
    StateAbbr: 'FL',
    StateName: 'Florida',
    lat: 28.71682625,
    lng: -81.23634611,
  },
  {
    GEO_ID: '0500000US12127',
    STATE: '12',
    COUNTY: '127',
    NAME: 'Volusia',
    LSAD: 'County',
    CENSUSAREA: 1101.032,
    FIPS: 'FL127',
    fips: '12127',
    StateAbbr: 'FL',
    StateName: 'Florida',
    lat: 29.05852431,
    lng: -81.18197434,
  },
  {
    GEO_ID: '0500000US12131',
    STATE: '12',
    COUNTY: '131',
    NAME: 'Walton',
    LSAD: 'County',
    CENSUSAREA: 1037.625,
    FIPS: 'FL131',
    fips: '12131',
    StateAbbr: 'FL',
    StateName: 'Florida',
    lat: 30.64341925,
    lng: -86.17009489,
  },
  {
    GEO_ID: '0500000US13003',
    STATE: '13',
    COUNTY: '003',
    NAME: 'Atkinson',
    LSAD: 'County',
    CENSUSAREA: 339.382,
    FIPS: 'GA003',
    fips: '13003',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 31.29704319,
    lng: -82.88008089,
  },
  {
    GEO_ID: '0500000US13005',
    STATE: '13',
    COUNTY: '005',
    NAME: 'Bacon',
    LSAD: 'County',
    CENSUSAREA: 258.576,
    FIPS: 'GA005',
    fips: '13005',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 31.55377644,
    lng: -82.45269728,
  },
  {
    GEO_ID: '0500000US13009',
    STATE: '13',
    COUNTY: '009',
    NAME: 'Baldwin',
    LSAD: 'County',
    CENSUSAREA: 257.844,
    FIPS: 'GA009',
    fips: '13009',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 33.06940962,
    lng: -83.24970322,
  },
  {
    GEO_ID: '0500000US13015',
    STATE: '13',
    COUNTY: '015',
    NAME: 'Bartow',
    LSAD: 'County',
    CENSUSAREA: 459.544,
    FIPS: 'GA015',
    fips: '13015',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 34.23786814,
    lng: -84.84068937,
  },
  {
    GEO_ID: '0500000US13023',
    STATE: '13',
    COUNTY: '023',
    NAME: 'Bleckley',
    LSAD: 'County',
    CENSUSAREA: 215.87,
    FIPS: 'GA023',
    fips: '13023',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 32.43444176,
    lng: -83.32769512,
  },
  {
    GEO_ID: '0500000US13025',
    STATE: '13',
    COUNTY: '025',
    NAME: 'Brantley',
    LSAD: 'County',
    CENSUSAREA: 442.362,
    FIPS: 'GA025',
    fips: '13025',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 31.19714296,
    lng: -81.98175207,
  },
  {
    GEO_ID: '0500000US13031',
    STATE: '13',
    COUNTY: '031',
    NAME: 'Bulloch',
    LSAD: 'County',
    CENSUSAREA: 672.809,
    FIPS: 'GA031',
    fips: '13031',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 32.39689006,
    lng: -81.74304725,
  },
  {
    GEO_ID: '0500000US13037',
    STATE: '13',
    COUNTY: '037',
    NAME: 'Calhoun',
    LSAD: 'County',
    CENSUSAREA: 280.367,
    FIPS: 'GA037',
    fips: '13037',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 31.52887387,
    lng: -84.62376804,
  },
  {
    GEO_ID: '0500000US13049',
    STATE: '13',
    COUNTY: '049',
    NAME: 'Charlton',
    LSAD: 'County',
    CENSUSAREA: 773.576,
    FIPS: 'GA049',
    fips: '13049',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 30.7815722,
    lng: -82.13757286,
  },
  {
    GEO_ID: '0500000US13053',
    STATE: '13',
    COUNTY: '053',
    NAME: 'Chattahoochee',
    LSAD: 'County',
    CENSUSAREA: 248.736,
    FIPS: 'GA053',
    fips: '13053',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 32.34694534,
    lng: -84.78680953,
  },
  {
    GEO_ID: '0500000US13057',
    STATE: '13',
    COUNTY: '057',
    NAME: 'Cherokee',
    LSAD: 'County',
    CENSUSAREA: 421.674,
    FIPS: 'GA057',
    fips: '13057',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 34.24473392,
    lng: -84.47621286,
  },
  {
    GEO_ID: '0500000US13063',
    STATE: '13',
    COUNTY: '063',
    NAME: 'Clayton',
    LSAD: 'County',
    CENSUSAREA: 141.57,
    FIPS: 'GA063',
    fips: '13063',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 33.54043935,
    lng: -84.35751271,
  },
  {
    GEO_ID: '0500000US09013',
    STATE: '09',
    COUNTY: '013',
    NAME: 'Tolland',
    LSAD: 'County',
    CENSUSAREA: 410.214,
    FIPS: 'CT013',
    fips: '09013',
    StateAbbr: 'CT',
    StateName: 'Connecticut',
    lat: 41.85510643,
    lng: -72.33642171,
  },
  {
    GEO_ID: '0500000US10005',
    STATE: '10',
    COUNTY: '005',
    NAME: 'Sussex',
    LSAD: 'County',
    CENSUSAREA: 936.079,
    FIPS: 'DE005',
    fips: '10005',
    StateAbbr: 'DE',
    StateName: 'Delaware',
    lat: 38.66089142,
    lng: -75.38998106,
  },
  {
    GEO_ID: '0500000US11001',
    STATE: '11',
    COUNTY: '001',
    NAME: 'District of Columbia',
    LSAD: '',
    CENSUSAREA: 61.048,
    FIPS: 'DC001',
    fips: '11001',
    StateAbbr: 'DC',
    StateName: 'District of Columbia',
    lat: 38.90478149,
    lng: -77.01629654,
  },
  {
    GEO_ID: '0500000US12003',
    STATE: '12',
    COUNTY: '003',
    NAME: 'Baker',
    LSAD: 'County',
    CENSUSAREA: 585.231,
    FIPS: 'FL003',
    fips: '12003',
    StateAbbr: 'FL',
    StateName: 'Florida',
    lat: 30.3309289,
    lng: -82.28452499,
  },
  {
    GEO_ID: '0500000US12005',
    STATE: '12',
    COUNTY: '005',
    NAME: 'Bay',
    LSAD: 'County',
    CENSUSAREA: 758.459,
    FIPS: 'FL005',
    fips: '12005',
    StateAbbr: 'FL',
    StateName: 'Florida',
    lat: 30.26520819,
    lng: -85.62005473,
  },
  {
    GEO_ID: '0500000US12011',
    STATE: '12',
    COUNTY: '011',
    NAME: 'Broward',
    LSAD: 'County',
    CENSUSAREA: 1209.785,
    FIPS: 'FL011',
    fips: '12011',
    StateAbbr: 'FL',
    StateName: 'Florida',
    lat: 26.15220703,
    lng: -80.48723143,
  },
  {
    GEO_ID: '0500000US12015',
    STATE: '12',
    COUNTY: '015',
    NAME: 'Charlotte',
    LSAD: 'County',
    CENSUSAREA: 680.279,
    FIPS: 'FL015',
    fips: '12015',
    StateAbbr: 'FL',
    StateName: 'Florida',
    lat: 26.90579274,
    lng: -81.91185513,
  },
  {
    GEO_ID: '0500000US12071',
    STATE: '12',
    COUNTY: '071',
    NAME: 'Lee',
    LSAD: 'County',
    CENSUSAREA: 784.513,
    FIPS: 'FL071',
    fips: '12071',
    StateAbbr: 'FL',
    StateName: 'Florida',
    lat: 26.57801557,
    lng: -81.84068841,
  },
  {
    GEO_ID: '0500000US12079',
    STATE: '12',
    COUNTY: '079',
    NAME: 'Madison',
    LSAD: 'County',
    CENSUSAREA: 695.948,
    FIPS: 'FL079',
    fips: '12079',
    StateAbbr: 'FL',
    StateName: 'Florida',
    lat: 30.44431386,
    lng: -83.46989512,
  },
  {
    GEO_ID: '0500000US12081',
    STATE: '12',
    COUNTY: '081',
    NAME: 'Manatee',
    LSAD: 'County',
    CENSUSAREA: 742.931,
    FIPS: 'FL081',
    fips: '12081',
    StateAbbr: 'FL',
    StateName: 'Florida',
    lat: 27.47189899,
    lng: -82.31571914,
  },
  {
    GEO_ID: '0500000US02275',
    STATE: '02',
    COUNTY: '275',
    NAME: 'Wrangell',
    LSAD: 'Cty&Bor',
    CENSUSAREA: 2541.483,
    FIPS: 'AK275',
    fips: '02275',
    StateAbbr: 'AK',
    StateName: 'Alaska',
    lat: 56.32537949,
    lng: -132.0310434,
  },
  {
    GEO_ID: '0500000US08085',
    STATE: '08',
    COUNTY: '085',
    NAME: 'Montrose',
    LSAD: 'County',
    CENSUSAREA: 2240.695,
    FIPS: 'CO085',
    fips: '08085',
    StateAbbr: 'CO',
    StateName: 'Colorado',
    lat: 38.4024194,
    lng: -108.2688129,
  },
  {
    GEO_ID: '0500000US12017',
    STATE: '12',
    COUNTY: '017',
    NAME: 'Citrus',
    LSAD: 'County',
    CENSUSAREA: 581.696,
    FIPS: 'FL017',
    fips: '12017',
    StateAbbr: 'FL',
    StateName: 'Florida',
    lat: 28.84873662,
    lng: -82.47793438,
  },
  {
    GEO_ID: '0500000US12029',
    STATE: '12',
    COUNTY: '029',
    NAME: 'Dixie',
    LSAD: 'County',
    CENSUSAREA: 705.053,
    FIPS: 'FL029',
    fips: '12029',
    StateAbbr: 'FL',
    StateName: 'Florida',
    lat: 29.60806814,
    lng: -83.15870461,
  },
  {
    GEO_ID: '0500000US12031',
    STATE: '12',
    COUNTY: '031',
    NAME: 'Duval',
    LSAD: 'County',
    CENSUSAREA: 762.192,
    FIPS: 'FL031',
    fips: '12031',
    StateAbbr: 'FL',
    StateName: 'Florida',
    lat: 30.33214142,
    lng: -81.67063889,
  },
  {
    GEO_ID: '0500000US12041',
    STATE: '12',
    COUNTY: '041',
    NAME: 'Gilchrist',
    LSAD: 'County',
    CENSUSAREA: 349.677,
    FIPS: 'FL041',
    fips: '12041',
    StateAbbr: 'FL',
    StateName: 'Florida',
    lat: 29.72600841,
    lng: -82.80043459,
  },
  {
    GEO_ID: '0500000US12043',
    STATE: '12',
    COUNTY: '043',
    NAME: 'Glades',
    LSAD: 'County',
    CENSUSAREA: 806.009,
    FIPS: 'FL043',
    fips: '12043',
    StateAbbr: 'FL',
    StateName: 'Florida',
    lat: 26.95642237,
    lng: -81.1889838,
  },
  {
    GEO_ID: '0500000US12045',
    STATE: '12',
    COUNTY: '045',
    NAME: 'Gulf',
    LSAD: 'County',
    CENSUSAREA: 564.012,
    FIPS: 'FL045',
    fips: '12045',
    StateAbbr: 'FL',
    StateName: 'Florida',
    lat: 29.95068747,
    lng: -85.23056011,
  },
  {
    GEO_ID: '0500000US12049',
    STATE: '12',
    COUNTY: '049',
    NAME: 'Hardee',
    LSAD: 'County',
    CENSUSAREA: 637.783,
    FIPS: 'FL049',
    fips: '12049',
    StateAbbr: 'FL',
    StateName: 'Florida',
    lat: 27.49319733,
    lng: -81.80953792,
  },
  {
    GEO_ID: '0500000US12051',
    STATE: '12',
    COUNTY: '051',
    NAME: 'Hendry',
    LSAD: 'County',
    CENSUSAREA: 1152.75,
    FIPS: 'FL051',
    fips: '12051',
    StateAbbr: 'FL',
    StateName: 'Florida',
    lat: 26.55374425,
    lng: -81.16511547,
  },
  {
    GEO_ID: '0500000US12053',
    STATE: '12',
    COUNTY: '053',
    NAME: 'Hernando',
    LSAD: 'County',
    CENSUSAREA: 472.539,
    FIPS: 'FL053',
    fips: '12053',
    StateAbbr: 'FL',
    StateName: 'Florida',
    lat: 28.55404893,
    lng: -82.42535431,
  },
  {
    GEO_ID: '0500000US12061',
    STATE: '12',
    COUNTY: '061',
    NAME: 'Indian River',
    LSAD: 'County',
    CENSUSAREA: 502.872,
    FIPS: 'FL061',
    fips: '12061',
    StateAbbr: 'FL',
    StateName: 'Florida',
    lat: 27.69396994,
    lng: -80.60559316,
  },
  {
    GEO_ID: '0500000US12063',
    STATE: '12',
    COUNTY: '063',
    NAME: 'Jackson',
    LSAD: 'County',
    CENSUSAREA: 917.76,
    FIPS: 'FL063',
    fips: '12063',
    StateAbbr: 'FL',
    StateName: 'Florida',
    lat: 30.79547704,
    lng: -85.21596518,
  },
  {
    GEO_ID: '0500000US12069',
    STATE: '12',
    COUNTY: '069',
    NAME: 'Lake',
    LSAD: 'County',
    CENSUSAREA: 938.381,
    FIPS: 'FL069',
    fips: '12069',
    StateAbbr: 'FL',
    StateName: 'Florida',
    lat: 28.76121706,
    lng: -81.71135257,
  },
  {
    GEO_ID: '0500000US08075',
    STATE: '08',
    COUNTY: '075',
    NAME: 'Logan',
    LSAD: 'County',
    CENSUSAREA: 1838.547,
    FIPS: 'CO075',
    fips: '08075',
    StateAbbr: 'CO',
    StateName: 'Colorado',
    lat: 40.72463929,
    lng: -103.1101132,
  },
  {
    GEO_ID: '0500000US08077',
    STATE: '08',
    COUNTY: '077',
    NAME: 'Mesa',
    LSAD: 'County',
    CENSUSAREA: 3328.974,
    FIPS: 'CO077',
    fips: '08077',
    StateAbbr: 'CO',
    StateName: 'Colorado',
    lat: 39.01825229,
    lng: -108.4664844,
  },
  {
    GEO_ID: '0500000US08081',
    STATE: '08',
    COUNTY: '081',
    NAME: 'Moffat',
    LSAD: 'County',
    CENSUSAREA: 4743.29,
    FIPS: 'CO081',
    fips: '08081',
    StateAbbr: 'CO',
    StateName: 'Colorado',
    lat: 40.61858161,
    lng: -108.2075581,
  },
  {
    GEO_ID: '0500000US08087',
    STATE: '08',
    COUNTY: '087',
    NAME: 'Morgan',
    LSAD: 'County',
    CENSUSAREA: 1280.433,
    FIPS: 'CO087',
    fips: '08087',
    StateAbbr: 'CO',
    StateName: 'Colorado',
    lat: 40.26279879,
    lng: -103.8102221,
  },
  {
    GEO_ID: '0500000US08091',
    STATE: '08',
    COUNTY: '091',
    NAME: 'Ouray',
    LSAD: 'County',
    CENSUSAREA: 541.593,
    FIPS: 'CO091',
    fips: '08091',
    StateAbbr: 'CO',
    StateName: 'Colorado',
    lat: 38.15498465,
    lng: -107.7693048,
  },
  {
    GEO_ID: '0500000US08097',
    STATE: '08',
    COUNTY: '097',
    NAME: 'Pitkin',
    LSAD: 'County',
    CENSUSAREA: 970.697,
    FIPS: 'CO097',
    fips: '08097',
    StateAbbr: 'CO',
    StateName: 'Colorado',
    lat: 39.21732141,
    lng: -106.9159345,
  },
  {
    GEO_ID: '0500000US08103',
    STATE: '08',
    COUNTY: '103',
    NAME: 'Rio Blanco',
    LSAD: 'County',
    CENSUSAREA: 3220.934,
    FIPS: 'CO103',
    fips: '08103',
    StateAbbr: 'CO',
    StateName: 'Colorado',
    lat: 39.97993245,
    lng: -108.2172628,
  },
  {
    GEO_ID: '0500000US08109',
    STATE: '08',
    COUNTY: '109',
    NAME: 'Saguache',
    LSAD: 'County',
    CENSUSAREA: 3168.525,
    FIPS: 'CO109',
    fips: '08109',
    StateAbbr: 'CO',
    StateName: 'Colorado',
    lat: 38.08034572,
    lng: -106.2809773,
  },
  {
    GEO_ID: '0500000US08119',
    STATE: '08',
    COUNTY: '119',
    NAME: 'Teller',
    LSAD: 'County',
    CENSUSAREA: 557.055,
    FIPS: 'CO119',
    fips: '08119',
    StateAbbr: 'CO',
    StateName: 'Colorado',
    lat: 38.88227296,
    lng: -105.1619203,
  },
  {
    GEO_ID: '0500000US08121',
    STATE: '08',
    COUNTY: '121',
    NAME: 'Washington',
    LSAD: 'County',
    CENSUSAREA: 2518.031,
    FIPS: 'CO121',
    fips: '08121',
    StateAbbr: 'CO',
    StateName: 'Colorado',
    lat: 39.97109881,
    lng: -103.2015041,
  },
  {
    GEO_ID: '0500000US08125',
    STATE: '08',
    COUNTY: '125',
    NAME: 'Yuma',
    LSAD: 'County',
    CENSUSAREA: 2364.405,
    FIPS: 'CO125',
    fips: '08125',
    StateAbbr: 'CO',
    StateName: 'Colorado',
    lat: 40.00284508,
    lng: -102.4246098,
  },
  {
    GEO_ID: '0500000US09003',
    STATE: '09',
    COUNTY: '003',
    NAME: 'Hartford',
    LSAD: 'County',
    CENSUSAREA: 735.097,
    FIPS: 'CT003',
    fips: '09003',
    StateAbbr: 'CT',
    StateName: 'Connecticut',
    lat: 41.80669859,
    lng: -72.73301048,
  },
  {
    GEO_ID: '0500000US09007',
    STATE: '09',
    COUNTY: '007',
    NAME: 'Middlesex',
    LSAD: 'County',
    CENSUSAREA: 369.301,
    FIPS: 'CT007',
    fips: '09007',
    StateAbbr: 'CT',
    StateName: 'Connecticut',
    lat: 41.46358412,
    lng: -72.5354823,
  },
  {
    GEO_ID: '0500000US05015',
    STATE: '05',
    COUNTY: '015',
    NAME: 'Carroll',
    LSAD: 'County',
    CENSUSAREA: 630.087,
    FIPS: 'AR015',
    fips: '05015',
    StateAbbr: 'AR',
    StateName: 'Arkansas',
    lat: 36.34107901,
    lng: -93.53832319,
  },
  {
    GEO_ID: '0500000US05017',
    STATE: '05',
    COUNTY: '017',
    NAME: 'Chicot',
    LSAD: 'County',
    CENSUSAREA: 644.304,
    FIPS: 'AR017',
    fips: '05017',
    StateAbbr: 'AR',
    StateName: 'Arkansas',
    lat: 33.2673446,
    lng: -91.29365079,
  },
  {
    GEO_ID: '0500000US05025',
    STATE: '05',
    COUNTY: '025',
    NAME: 'Cleveland',
    LSAD: 'County',
    CENSUSAREA: 597.783,
    FIPS: 'AR025',
    fips: '05025',
    StateAbbr: 'AR',
    StateName: 'Arkansas',
    lat: 33.89823609,
    lng: -92.18489948,
  },
  {
    GEO_ID: '0500000US05027',
    STATE: '05',
    COUNTY: '027',
    NAME: 'Columbia',
    LSAD: 'County',
    CENSUSAREA: 766.053,
    FIPS: 'AR027',
    fips: '05027',
    StateAbbr: 'AR',
    StateName: 'Arkansas',
    lat: 33.2142428,
    lng: -93.22737493,
  },
  {
    GEO_ID: '0500000US05033',
    STATE: '05',
    COUNTY: '033',
    NAME: 'Crawford',
    LSAD: 'County',
    CENSUSAREA: 593.094,
    FIPS: 'AR033',
    fips: '05033',
    StateAbbr: 'AR',
    StateName: 'Arkansas',
    lat: 35.588841,
    lng: -94.24296995,
  },
  {
    GEO_ID: '0500000US05045',
    STATE: '05',
    COUNTY: '045',
    NAME: 'Faulkner',
    LSAD: 'County',
    CENSUSAREA: 647.884,
    FIPS: 'AR045',
    fips: '05045',
    StateAbbr: 'AR',
    StateName: 'Arkansas',
    lat: 35.14732521,
    lng: -92.33201857,
  },
  {
    GEO_ID: '0500000US05047',
    STATE: '05',
    COUNTY: '047',
    NAME: 'Franklin',
    LSAD: 'County',
    CENSUSAREA: 608.857,
    FIPS: 'AR047',
    fips: '05047',
    StateAbbr: 'AR',
    StateName: 'Arkansas',
    lat: 35.51229968,
    lng: -93.89070916,
  },
  {
    GEO_ID: '0500000US05053',
    STATE: '05',
    COUNTY: '053',
    NAME: 'Grant',
    LSAD: 'County',
    CENSUSAREA: 631.809,
    FIPS: 'AR053',
    fips: '05053',
    StateAbbr: 'AR',
    StateName: 'Arkansas',
    lat: 34.29002049,
    lng: -92.42371463,
  },
  {
    GEO_ID: '0500000US05059',
    STATE: '05',
    COUNTY: '059',
    NAME: 'Hot Spring',
    LSAD: 'County',
    CENSUSAREA: 615.202,
    FIPS: 'AR059',
    fips: '05059',
    StateAbbr: 'AR',
    StateName: 'Arkansas',
    lat: 34.31751189,
    lng: -92.94560125,
  },
  {
    GEO_ID: '0500000US05067',
    STATE: '05',
    COUNTY: '067',
    NAME: 'Jackson',
    LSAD: 'County',
    CENSUSAREA: 633.938,
    FIPS: 'AR067',
    fips: '05067',
    StateAbbr: 'AR',
    StateName: 'Arkansas',
    lat: 35.59923757,
    lng: -91.21423884,
  },
  {
    GEO_ID: '0500000US05073',
    STATE: '05',
    COUNTY: '073',
    NAME: 'Lafayette',
    LSAD: 'County',
    CENSUSAREA: 528.268,
    FIPS: 'AR073',
    fips: '05073',
    StateAbbr: 'AR',
    StateName: 'Arkansas',
    lat: 33.24091819,
    lng: -93.60684679,
  },
  {
    GEO_ID: '0500000US05081',
    STATE: '05',
    COUNTY: '081',
    NAME: 'Little River',
    LSAD: 'County',
    CENSUSAREA: 532.253,
    FIPS: 'AR081',
    fips: '05081',
    StateAbbr: 'AR',
    StateName: 'Arkansas',
    lat: 33.70228691,
    lng: -94.2339787,
  },
  {
    GEO_ID: '0500000US05083',
    STATE: '05',
    COUNTY: '083',
    NAME: 'Logan',
    LSAD: 'County',
    CENSUSAREA: 708.125,
    FIPS: 'AR083',
    fips: '05083',
    StateAbbr: 'AR',
    StateName: 'Arkansas',
    lat: 35.21550403,
    lng: -93.71630518,
  },
  {
    GEO_ID: '0500000US05087',
    STATE: '05',
    COUNTY: '087',
    NAME: 'Madison',
    LSAD: 'County',
    CENSUSAREA: 834.255,
    FIPS: 'AR087',
    fips: '05087',
    StateAbbr: 'AR',
    StateName: 'Arkansas',
    lat: 36.01103565,
    lng: -93.72465597,
  },
  {
    GEO_ID: '0500000US15001',
    STATE: '15',
    COUNTY: '001',
    NAME: 'Hawaii',
    LSAD: 'County',
    CENSUSAREA: 4028.417,
    FIPS: 'HI001',
    fips: '15001',
    StateAbbr: 'HI',
    StateName: 'Hawaii',
    lat: 19.60123507,
    lng: -155.5211676,
  },
  {
    GEO_ID: '0500000US15007',
    STATE: '15',
    COUNTY: '007',
    NAME: 'Kauai',
    LSAD: 'County',
    CENSUSAREA: 619.956,
    FIPS: 'HI007',
    fips: '15007',
    StateAbbr: 'HI',
    StateName: 'Hawaii',
    lat: 22.03978487,
    lng: -159.5957496,
  },
  {
    GEO_ID: '0500000US16001',
    STATE: '16',
    COUNTY: '001',
    NAME: 'Ada',
    LSAD: 'County',
    CENSUSAREA: 1052.576,
    FIPS: 'ID001',
    fips: '16001',
    StateAbbr: 'ID',
    StateName: 'Idaho',
    lat: 43.45084054,
    lng: -116.241035,
  },
  {
    GEO_ID: '0500000US16003',
    STATE: '16',
    COUNTY: '003',
    NAME: 'Adams',
    LSAD: 'County',
    CENSUSAREA: 1363.056,
    FIPS: 'ID003',
    fips: '16003',
    StateAbbr: 'ID',
    StateName: 'Idaho',
    lat: 44.88931492,
    lng: -116.4539483,
  },
  {
    GEO_ID: '0500000US16009',
    STATE: '16',
    COUNTY: '009',
    NAME: 'Benewah',
    LSAD: 'County',
    CENSUSAREA: 776.617,
    FIPS: 'ID009',
    fips: '16009',
    StateAbbr: 'ID',
    StateName: 'Idaho',
    lat: 47.21772961,
    lng: -116.6586207,
  },
  {
    GEO_ID: '0500000US16017',
    STATE: '16',
    COUNTY: '017',
    NAME: 'Bonner',
    LSAD: 'County',
    CENSUSAREA: 1734.573,
    FIPS: 'ID017',
    fips: '16017',
    StateAbbr: 'ID',
    StateName: 'Idaho',
    lat: 48.2996896,
    lng: -116.6009928,
  },
  {
    GEO_ID: '0500000US16019',
    STATE: '16',
    COUNTY: '019',
    NAME: 'Bonneville',
    LSAD: 'County',
    CENSUSAREA: 1866.077,
    FIPS: 'ID019',
    fips: '16019',
    StateAbbr: 'ID',
    StateName: 'Idaho',
    lat: 43.38792191,
    lng: -111.6147928,
  },
  {
    GEO_ID: '0500000US05089',
    STATE: '05',
    COUNTY: '089',
    NAME: 'Marion',
    LSAD: 'County',
    CENSUSAREA: 597.009,
    FIPS: 'AR089',
    fips: '05089',
    StateAbbr: 'AR',
    StateName: 'Arkansas',
    lat: 36.26842934,
    lng: -92.68397295,
  },
  {
    GEO_ID: '0500000US05091',
    STATE: '05',
    COUNTY: '091',
    NAME: 'Miller',
    LSAD: 'County',
    CENSUSAREA: 625.578,
    FIPS: 'AR091',
    fips: '05091',
    StateAbbr: 'AR',
    StateName: 'Arkansas',
    lat: 33.31200171,
    lng: -93.89142003,
  },
  {
    GEO_ID: '0500000US05093',
    STATE: '05',
    COUNTY: '093',
    NAME: 'Mississippi',
    LSAD: 'County',
    CENSUSAREA: 900.574,
    FIPS: 'AR093',
    fips: '05093',
    StateAbbr: 'AR',
    StateName: 'Arkansas',
    lat: 35.76420127,
    lng: -90.05430077,
  },
  {
    GEO_ID: '0500000US05099',
    STATE: '05',
    COUNTY: '099',
    NAME: 'Nevada',
    LSAD: 'County',
    CENSUSAREA: 617.837,
    FIPS: 'AR099',
    fips: '05099',
    StateAbbr: 'AR',
    StateName: 'Arkansas',
    lat: 33.66338522,
    lng: -93.30697684,
  },
  {
    GEO_ID: '0500000US05103',
    STATE: '05',
    COUNTY: '103',
    NAME: 'Ouachita',
    LSAD: 'County',
    CENSUSAREA: 732.783,
    FIPS: 'AR103',
    fips: '05103',
    StateAbbr: 'AR',
    StateName: 'Arkansas',
    lat: 33.59337533,
    lng: -92.88191217,
  },
  {
    GEO_ID: '0500000US05113',
    STATE: '05',
    COUNTY: '113',
    NAME: 'Polk',
    LSAD: 'County',
    CENSUSAREA: 857.679,
    FIPS: 'AR113',
    fips: '05113',
    StateAbbr: 'AR',
    StateName: 'Arkansas',
    lat: 34.48595315,
    lng: -94.2279123,
  },
  {
    GEO_ID: '0500000US05115',
    STATE: '05',
    COUNTY: '115',
    NAME: 'Pope',
    LSAD: 'County',
    CENSUSAREA: 812.548,
    FIPS: 'AR115',
    fips: '05115',
    StateAbbr: 'AR',
    StateName: 'Arkansas',
    lat: 35.4476948,
    lng: -93.03400145,
  },
  {
    GEO_ID: '0500000US05121',
    STATE: '05',
    COUNTY: '121',
    NAME: 'Randolph',
    LSAD: 'County',
    CENSUSAREA: 652.191,
    FIPS: 'AR121',
    fips: '05121',
    StateAbbr: 'AR',
    StateName: 'Arkansas',
    lat: 36.34154412,
    lng: -91.02752205,
  },
  {
    GEO_ID: '0500000US05133',
    STATE: '05',
    COUNTY: '133',
    NAME: 'Sevier',
    LSAD: 'County',
    CENSUSAREA: 565.126,
    FIPS: 'AR133',
    fips: '05133',
    StateAbbr: 'AR',
    StateName: 'Arkansas',
    lat: 33.9971421,
    lng: -94.24072249,
  },
  {
    GEO_ID: '0500000US05137',
    STATE: '05',
    COUNTY: '137',
    NAME: 'Stone',
    LSAD: 'County',
    CENSUSAREA: 606.405,
    FIPS: 'AR137',
    fips: '05137',
    StateAbbr: 'AR',
    StateName: 'Arkansas',
    lat: 35.86003074,
    lng: -92.15689859,
  },
  {
    GEO_ID: '0500000US13317',
    STATE: '13',
    COUNTY: '317',
    NAME: 'Wilkes',
    LSAD: 'County',
    CENSUSAREA: 469.49,
    FIPS: 'GA317',
    fips: '13317',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 33.78219274,
    lng: -82.74345686,
  },
  {
    GEO_ID: '0500000US13321',
    STATE: '13',
    COUNTY: '321',
    NAME: 'Worth',
    LSAD: 'County',
    CENSUSAREA: 570.702,
    FIPS: 'GA321',
    fips: '13321',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 31.55124636,
    lng: -83.8509648,
  },
  {
    GEO_ID: '0500000US04005',
    STATE: '04',
    COUNTY: '005',
    NAME: 'Coconino',
    LSAD: 'County',
    CENSUSAREA: 18618.885,
    FIPS: 'AZ005',
    fips: '04005',
    StateAbbr: 'AZ',
    StateName: 'Arizona',
    lat: 35.83875351,
    lng: -111.7705493,
  },
  {
    GEO_ID: '0500000US04009',
    STATE: '04',
    COUNTY: '009',
    NAME: 'Graham',
    LSAD: 'County',
    CENSUSAREA: 4622.597,
    FIPS: 'AZ009',
    fips: '04009',
    StateAbbr: 'AZ',
    StateName: 'Arizona',
    lat: 32.93295414,
    lng: -109.8875198,
  },
  {
    GEO_ID: '0500000US04011',
    STATE: '04',
    COUNTY: '011',
    NAME: 'Greenlee',
    LSAD: 'County',
    CENSUSAREA: 1843.126,
    FIPS: 'AZ011',
    fips: '04011',
    StateAbbr: 'AZ',
    StateName: 'Arizona',
    lat: 33.21537146,
    lng: -109.2401561,
  },
  {
    GEO_ID: '0500000US04012',
    STATE: '04',
    COUNTY: '012',
    NAME: 'La Paz',
    LSAD: 'County',
    CENSUSAREA: 4499.629,
    FIPS: 'AZ012',
    fips: '04012',
    StateAbbr: 'AZ',
    StateName: 'Arizona',
    lat: 33.7291609,
    lng: -113.981207,
  },
  {
    GEO_ID: '0500000US04017',
    STATE: '04',
    COUNTY: '017',
    NAME: 'Navajo',
    LSAD: 'County',
    CENSUSAREA: 9950.422,
    FIPS: 'AZ017',
    fips: '04017',
    StateAbbr: 'AZ',
    StateName: 'Arizona',
    lat: 35.39960537,
    lng: -110.3213121,
  },
  {
    GEO_ID: '0500000US04021',
    STATE: '04',
    COUNTY: '021',
    NAME: 'Pinal',
    LSAD: 'County',
    CENSUSAREA: 5365.612,
    FIPS: 'AZ021',
    fips: '04021',
    StateAbbr: 'AZ',
    StateName: 'Arizona',
    lat: 32.90415087,
    lng: -111.34488,
  },
  {
    GEO_ID: '0500000US04023',
    STATE: '04',
    COUNTY: '023',
    NAME: 'Santa Cruz',
    LSAD: 'County',
    CENSUSAREA: 1236.916,
    FIPS: 'AZ023',
    fips: '04023',
    StateAbbr: 'AZ',
    StateName: 'Arizona',
    lat: 31.52598116,
    lng: -110.8466869,
  },
  {
    GEO_ID: '0500000US04027',
    STATE: '04',
    COUNTY: '027',
    NAME: 'Yuma',
    LSAD: 'County',
    CENSUSAREA: 5513.992,
    FIPS: 'AZ027',
    fips: '04027',
    StateAbbr: 'AZ',
    StateName: 'Arizona',
    lat: 32.76933672,
    lng: -113.9058099,
  },
  {
    GEO_ID: '0500000US05003',
    STATE: '05',
    COUNTY: '003',
    NAME: 'Ashley',
    LSAD: 'County',
    CENSUSAREA: 925.349,
    FIPS: 'AR003',
    fips: '05003',
    StateAbbr: 'AR',
    StateName: 'Arkansas',
    lat: 33.19120398,
    lng: -91.76814114,
  },
  {
    GEO_ID: '0500000US05013',
    STATE: '05',
    COUNTY: '013',
    NAME: 'Calhoun',
    LSAD: 'County',
    CENSUSAREA: 628.581,
    FIPS: 'AR013',
    fips: '05013',
    StateAbbr: 'AR',
    StateName: 'Arkansas',
    lat: 33.55786388,
    lng: -92.50253376,
  },
  {
    GEO_ID: '0500000US16071',
    STATE: '16',
    COUNTY: '071',
    NAME: 'Oneida',
    LSAD: 'County',
    CENSUSAREA: 1200.061,
    FIPS: 'ID071',
    fips: '16071',
    StateAbbr: 'ID',
    StateName: 'Idaho',
    lat: 42.19490877,
    lng: -112.5395749,
  },
  {
    GEO_ID: '0500000US16077',
    STATE: '16',
    COUNTY: '077',
    NAME: 'Power',
    LSAD: 'County',
    CENSUSAREA: 1404.244,
    FIPS: 'ID077',
    fips: '16077',
    StateAbbr: 'ID',
    StateName: 'Idaho',
    lat: 42.69368252,
    lng: -112.8410539,
  },
  {
    GEO_ID: '0500000US16079',
    STATE: '16',
    COUNTY: '079',
    NAME: 'Shoshone',
    LSAD: 'County',
    CENSUSAREA: 2629.665,
    FIPS: 'ID079',
    fips: '16079',
    StateAbbr: 'ID',
    StateName: 'Idaho',
    lat: 47.35176251,
    lng: -115.8911361,
  },
  {
    GEO_ID: '0500000US16083',
    STATE: '16',
    COUNTY: '083',
    NAME: 'Twin Falls',
    LSAD: 'County',
    CENSUSAREA: 1921.213,
    FIPS: 'ID083',
    fips: '16083',
    StateAbbr: 'ID',
    StateName: 'Idaho',
    lat: 42.35608428,
    lng: -114.6681729,
  },
  {
    GEO_ID: '0500000US17003',
    STATE: '17',
    COUNTY: '003',
    NAME: 'Alexander',
    LSAD: 'County',
    CENSUSAREA: 235.509,
    FIPS: 'IL003',
    fips: '17003',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 37.19162505,
    lng: -89.33759146,
  },
  {
    GEO_ID: '0500000US17007',
    STATE: '17',
    COUNTY: '007',
    NAME: 'Boone',
    LSAD: 'County',
    CENSUSAREA: 280.719,
    FIPS: 'IL007',
    fips: '17007',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 42.32294195,
    lng: -88.82348512,
  },
  {
    GEO_ID: '0500000US17011',
    STATE: '17',
    COUNTY: '011',
    NAME: 'Bureau',
    LSAD: 'County',
    CENSUSAREA: 869.031,
    FIPS: 'IL011',
    fips: '17011',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 41.40406966,
    lng: -89.52841534,
  },
  {
    GEO_ID: '0500000US17015',
    STATE: '17',
    COUNTY: '015',
    NAME: 'Carroll',
    LSAD: 'County',
    CENSUSAREA: 444.807,
    FIPS: 'IL015',
    fips: '17015',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 42.06794659,
    lng: -89.93380816,
  },
  {
    GEO_ID: '0500000US17023',
    STATE: '17',
    COUNTY: '023',
    NAME: 'Clark',
    LSAD: 'County',
    CENSUSAREA: 501.418,
    FIPS: 'IL023',
    fips: '17023',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 39.33402321,
    lng: -87.7878617,
  },
  {
    GEO_ID: '0500000US17033',
    STATE: '17',
    COUNTY: '033',
    NAME: 'Crawford',
    LSAD: 'County',
    CENSUSAREA: 443.629,
    FIPS: 'IL033',
    fips: '17033',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 39.00382453,
    lng: -87.75932466,
  },
  {
    GEO_ID: '0500000US17037',
    STATE: '17',
    COUNTY: '037',
    NAME: 'DeKalb',
    LSAD: 'County',
    CENSUSAREA: 631.307,
    FIPS: 'IL037',
    fips: '17037',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 41.8933691,
    lng: -88.76979995,
  },
  {
    GEO_ID: '0500000US17041',
    STATE: '17',
    COUNTY: '041',
    NAME: 'Douglas',
    LSAD: 'County',
    CENSUSAREA: 416.665,
    FIPS: 'IL041',
    fips: '17041',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 39.7695478,
    lng: -88.21756136,
  },
  {
    GEO_ID: '0500000US17043',
    STATE: '17',
    COUNTY: '043',
    NAME: 'DuPage',
    LSAD: 'County',
    CENSUSAREA: 327.499,
    FIPS: 'IL043',
    fips: '17043',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 41.85173736,
    lng: -88.08563421,
  },
  {
    GEO_ID: '0500000US18117',
    STATE: '18',
    COUNTY: '117',
    NAME: 'Orange',
    LSAD: 'County',
    CENSUSAREA: 398.387,
    FIPS: 'IN117',
    fips: '18117',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 38.5412136,
    lng: -86.49522904,
  },
  {
    GEO_ID: '0500000US18121',
    STATE: '18',
    COUNTY: '121',
    NAME: 'Parke',
    LSAD: 'County',
    CENSUSAREA: 444.663,
    FIPS: 'IN121',
    fips: '18121',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 39.77360728,
    lng: -87.20678147,
  },
  {
    GEO_ID: '0500000US18127',
    STATE: '18',
    COUNTY: '127',
    NAME: 'Porter',
    LSAD: 'County',
    CENSUSAREA: 418.153,
    FIPS: 'IN127',
    fips: '18127',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 41.46066332,
    lng: -87.06793187,
  },
  {
    GEO_ID: '0500000US18135',
    STATE: '18',
    COUNTY: '135',
    NAME: 'Randolph',
    LSAD: 'County',
    CENSUSAREA: 452.379,
    FIPS: 'IN135',
    fips: '18135',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 40.15748383,
    lng: -85.0114051,
  },
  {
    GEO_ID: '0500000US18137',
    STATE: '18',
    COUNTY: '137',
    NAME: 'Ripley',
    LSAD: 'County',
    CENSUSAREA: 446.426,
    FIPS: 'IN137',
    fips: '18137',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 39.10311351,
    lng: -85.2624907,
  },
  {
    GEO_ID: '0500000US18141',
    STATE: '18',
    COUNTY: '141',
    NAME: 'St. Joseph',
    LSAD: 'County',
    CENSUSAREA: 457.85,
    FIPS: 'IN141',
    fips: '18141',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 41.61670349,
    lng: -86.29001543,
  },
  {
    GEO_ID: '0500000US18149',
    STATE: '18',
    COUNTY: '149',
    NAME: 'Starke',
    LSAD: 'County',
    CENSUSAREA: 309.134,
    FIPS: 'IN149',
    fips: '18149',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 41.28077022,
    lng: -86.6477887,
  },
  {
    GEO_ID: '0500000US18151',
    STATE: '18',
    COUNTY: '151',
    NAME: 'Steuben',
    LSAD: 'County',
    CENSUSAREA: 308.939,
    FIPS: 'IN151',
    fips: '18151',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 41.64408329,
    lng: -85.00106921,
  },
  {
    GEO_ID: '0500000US18159',
    STATE: '18',
    COUNTY: '159',
    NAME: 'Tipton',
    LSAD: 'County',
    CENSUSAREA: 260.541,
    FIPS: 'IN159',
    fips: '18159',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 40.31121117,
    lng: -86.05195096,
  },
  {
    GEO_ID: '0500000US18161',
    STATE: '18',
    COUNTY: '161',
    NAME: 'Union',
    LSAD: 'County',
    CENSUSAREA: 161.224,
    FIPS: 'IN161',
    fips: '18161',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 39.62515829,
    lng: -84.9249309,
  },
  {
    GEO_ID: '0500000US18167',
    STATE: '18',
    COUNTY: '167',
    NAME: 'Vigo',
    LSAD: 'County',
    CENSUSAREA: 403.313,
    FIPS: 'IN167',
    fips: '18167',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 39.43073114,
    lng: -87.38969715,
  },
  {
    GEO_ID: '0500000US18169',
    STATE: '18',
    COUNTY: '169',
    NAME: 'Wabash',
    LSAD: 'County',
    CENSUSAREA: 412.434,
    FIPS: 'IN169',
    fips: '18169',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 40.84573494,
    lng: -85.79427017,
  },
  {
    GEO_ID: '0500000US18175',
    STATE: '18',
    COUNTY: '175',
    NAME: 'Washington',
    LSAD: 'County',
    CENSUSAREA: 513.725,
    FIPS: 'IN175',
    fips: '18175',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 38.59978187,
    lng: -86.10543054,
  },
  {
    GEO_ID: '0500000US18181',
    STATE: '18',
    COUNTY: '181',
    NAME: 'White',
    LSAD: 'County',
    CENSUSAREA: 505.125,
    FIPS: 'IN181',
    fips: '18181',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 40.74977914,
    lng: -86.8655579,
  },
  {
    GEO_ID: '0500000US19001',
    STATE: '19',
    COUNTY: '001',
    NAME: 'Adair',
    LSAD: 'County',
    CENSUSAREA: 569.271,
    FIPS: 'IA001',
    fips: '19001',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 41.33075609,
    lng: -94.47105874,
  },
  {
    GEO_ID: '0500000US17047',
    STATE: '17',
    COUNTY: '047',
    NAME: 'Edwards',
    LSAD: 'County',
    CENSUSAREA: 222.416,
    FIPS: 'IL047',
    fips: '17047',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 38.41619684,
    lng: -88.05369238,
  },
  {
    GEO_ID: '0500000US17051',
    STATE: '17',
    COUNTY: '051',
    NAME: 'Fayette',
    LSAD: 'County',
    CENSUSAREA: 716.482,
    FIPS: 'IL051',
    fips: '17051',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 39.0000587,
    lng: -89.02441763,
  },
  {
    GEO_ID: '0500000US17053',
    STATE: '17',
    COUNTY: '053',
    NAME: 'Ford',
    LSAD: 'County',
    CENSUSAREA: 485.616,
    FIPS: 'IL053',
    fips: '17053',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 40.59686492,
    lng: -88.22313426,
  },
  {
    GEO_ID: '0500000US17057',
    STATE: '17',
    COUNTY: '057',
    NAME: 'Fulton',
    LSAD: 'County',
    CENSUSAREA: 865.595,
    FIPS: 'IL057',
    fips: '17057',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 40.47248529,
    lng: -90.20749434,
  },
  {
    GEO_ID: '0500000US17063',
    STATE: '17',
    COUNTY: '063',
    NAME: 'Grundy',
    LSAD: 'County',
    CENSUSAREA: 418.043,
    FIPS: 'IL063',
    fips: '17063',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 41.2856038,
    lng: -88.41891328,
  },
  {
    GEO_ID: '0500000US18055',
    STATE: '18',
    COUNTY: '055',
    NAME: 'Greene',
    LSAD: 'County',
    CENSUSAREA: 542.495,
    FIPS: 'IN055',
    fips: '18055',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 39.03630547,
    lng: -86.96223202,
  },
  {
    GEO_ID: '0500000US18057',
    STATE: '18',
    COUNTY: '057',
    NAME: 'Hamilton',
    LSAD: 'County',
    CENSUSAREA: 394.267,
    FIPS: 'IN057',
    fips: '18057',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 40.07229856,
    lng: -86.05184193,
  },
  {
    GEO_ID: '0500000US18065',
    STATE: '18',
    COUNTY: '065',
    NAME: 'Henry',
    LSAD: 'County',
    CENSUSAREA: 391.876,
    FIPS: 'IN065',
    fips: '18065',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 39.93112507,
    lng: -85.39654344,
  },
  {
    GEO_ID: '0500000US18067',
    STATE: '18',
    COUNTY: '067',
    NAME: 'Howard',
    LSAD: 'County',
    CENSUSAREA: 293.057,
    FIPS: 'IN067',
    fips: '18067',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 40.48362355,
    lng: -86.1167092,
  },
  {
    GEO_ID: '0500000US18071',
    STATE: '18',
    COUNTY: '071',
    NAME: 'Jackson',
    LSAD: 'County',
    CENSUSAREA: 509.312,
    FIPS: 'IN071',
    fips: '18071',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 38.90634215,
    lng: -86.03756074,
  },
  {
    GEO_ID: '0500000US18077',
    STATE: '18',
    COUNTY: '077',
    NAME: 'Jefferson',
    LSAD: 'County',
    CENSUSAREA: 360.631,
    FIPS: 'IN077',
    fips: '18077',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 38.78558559,
    lng: -85.43830308,
  },
  {
    GEO_ID: '0500000US18087',
    STATE: '18',
    COUNTY: '087',
    NAME: 'LaGrange',
    LSAD: 'County',
    CENSUSAREA: 379.624,
    FIPS: 'IN087',
    fips: '18087',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 41.64234778,
    lng: -85.42669106,
  },
  {
    GEO_ID: '0500000US18091',
    STATE: '18',
    COUNTY: '091',
    NAME: 'LaPorte',
    LSAD: 'County',
    CENSUSAREA: 598.299,
    FIPS: 'IN091',
    fips: '18091',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 41.5459356,
    lng: -86.74045399,
  },
  {
    GEO_ID: '0500000US18097',
    STATE: '18',
    COUNTY: '097',
    NAME: 'Marion',
    LSAD: 'County',
    CENSUSAREA: 396.298,
    FIPS: 'IN097',
    fips: '18097',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 39.78163624,
    lng: -86.13826325,
  },
  {
    GEO_ID: '0500000US18101',
    STATE: '18',
    COUNTY: '101',
    NAME: 'Martin',
    LSAD: 'County',
    CENSUSAREA: 335.737,
    FIPS: 'IN101',
    fips: '18101',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 38.7079435,
    lng: -86.80307723,
  },
  {
    GEO_ID: '0500000US18103',
    STATE: '18',
    COUNTY: '103',
    NAME: 'Miami',
    LSAD: 'County',
    CENSUSAREA: 373.842,
    FIPS: 'IN103',
    fips: '18103',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 40.76954805,
    lng: -86.04523681,
  },
  {
    GEO_ID: '0500000US18109',
    STATE: '18',
    COUNTY: '109',
    NAME: 'Morgan',
    LSAD: 'County',
    CENSUSAREA: 403.969,
    FIPS: 'IN109',
    fips: '18109',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 39.48166964,
    lng: -86.44647098,
  },
  {
    GEO_ID: '0500000US16023',
    STATE: '16',
    COUNTY: '023',
    NAME: 'Butte',
    LSAD: 'County',
    CENSUSAREA: 2231.667,
    FIPS: 'ID023',
    fips: '16023',
    StateAbbr: 'ID',
    StateName: 'Idaho',
    lat: 43.72266581,
    lng: -113.1719663,
  },
  {
    GEO_ID: '0500000US16025',
    STATE: '16',
    COUNTY: '025',
    NAME: 'Camas',
    LSAD: 'County',
    CENSUSAREA: 1074.493,
    FIPS: 'ID025',
    fips: '16025',
    StateAbbr: 'ID',
    StateName: 'Idaho',
    lat: 43.46356926,
    lng: -114.8053772,
  },
  {
    GEO_ID: '0500000US16029',
    STATE: '16',
    COUNTY: '029',
    NAME: 'Caribou',
    LSAD: 'County',
    CENSUSAREA: 1764.146,
    FIPS: 'ID029',
    fips: '16029',
    StateAbbr: 'ID',
    StateName: 'Idaho',
    lat: 42.77049766,
    lng: -111.5621762,
  },
  {
    GEO_ID: '0500000US16033',
    STATE: '16',
    COUNTY: '033',
    NAME: 'Clark',
    LSAD: 'County',
    CENSUSAREA: 1764.185,
    FIPS: 'ID033',
    fips: '16033',
    StateAbbr: 'ID',
    StateName: 'Idaho',
    lat: 44.28409003,
    lng: -112.3520136,
  },
  {
    GEO_ID: '0500000US16041',
    STATE: '16',
    COUNTY: '041',
    NAME: 'Franklin',
    LSAD: 'County',
    CENSUSAREA: 663.645,
    FIPS: 'ID041',
    fips: '16041',
    StateAbbr: 'ID',
    StateName: 'Idaho',
    lat: 42.18121994,
    lng: -111.8131009,
  },
  {
    GEO_ID: '0500000US16043',
    STATE: '16',
    COUNTY: '043',
    NAME: 'Fremont',
    LSAD: 'County',
    CENSUSAREA: 1863.525,
    FIPS: 'ID043',
    fips: '16043',
    StateAbbr: 'ID',
    StateName: 'Idaho',
    lat: 44.22898675,
    lng: -111.4821032,
  },
  {
    GEO_ID: '0500000US16047',
    STATE: '16',
    COUNTY: '047',
    NAME: 'Gooding',
    LSAD: 'County',
    CENSUSAREA: 728.973,
    FIPS: 'ID047',
    fips: '16047',
    StateAbbr: 'ID',
    StateName: 'Idaho',
    lat: 42.97094353,
    lng: -114.8116615,
  },
  {
    GEO_ID: '0500000US16053',
    STATE: '16',
    COUNTY: '053',
    NAME: 'Jerome',
    LSAD: 'County',
    CENSUSAREA: 597.185,
    FIPS: 'ID053',
    fips: '16053',
    StateAbbr: 'ID',
    StateName: 'Idaho',
    lat: 42.68974614,
    lng: -114.2642648,
  },
  {
    GEO_ID: '0500000US16055',
    STATE: '16',
    COUNTY: '055',
    NAME: 'Kootenai',
    LSAD: 'County',
    CENSUSAREA: 1244.125,
    FIPS: 'ID055',
    fips: '16055',
    StateAbbr: 'ID',
    StateName: 'Idaho',
    lat: 47.6744641,
    lng: -116.7000308,
  },
  {
    GEO_ID: '0500000US16061',
    STATE: '16',
    COUNTY: '061',
    NAME: 'Lewis',
    LSAD: 'County',
    CENSUSAREA: 478.795,
    FIPS: 'ID061',
    fips: '16061',
    StateAbbr: 'ID',
    StateName: 'Idaho',
    lat: 46.2366682,
    lng: -116.4267621,
  },
  {
    GEO_ID: '0500000US16065',
    STATE: '16',
    COUNTY: '065',
    NAME: 'Madison',
    LSAD: 'County',
    CENSUSAREA: 469.206,
    FIPS: 'ID065',
    fips: '16065',
    StateAbbr: 'ID',
    StateName: 'Idaho',
    lat: 43.78411982,
    lng: -111.6592182,
  },
  {
    GEO_ID: '0500000US16069',
    STATE: '16',
    COUNTY: '069',
    NAME: 'Nez Perce',
    LSAD: 'County',
    CENSUSAREA: 848.092,
    FIPS: 'ID069',
    fips: '16069',
    StateAbbr: 'ID',
    StateName: 'Idaho',
    lat: 46.32729495,
    lng: -116.7498104,
  },
  {
    GEO_ID: '0500000US18007',
    STATE: '18',
    COUNTY: '007',
    NAME: 'Benton',
    LSAD: 'County',
    CENSUSAREA: 406.418,
    FIPS: 'IN007',
    fips: '18007',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 40.60631635,
    lng: -87.31082597,
  },
  {
    GEO_ID: '0500000US18013',
    STATE: '18',
    COUNTY: '013',
    NAME: 'Brown',
    LSAD: 'County',
    CENSUSAREA: 311.981,
    FIPS: 'IN013',
    fips: '18013',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 39.19645662,
    lng: -86.22810056,
  },
  {
    GEO_ID: '0500000US18015',
    STATE: '18',
    COUNTY: '015',
    NAME: 'Carroll',
    LSAD: 'County',
    CENSUSAREA: 372.224,
    FIPS: 'IN015',
    fips: '18015',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 40.58306044,
    lng: -86.5635188,
  },
  {
    GEO_ID: '0500000US18019',
    STATE: '18',
    COUNTY: '019',
    NAME: 'Clark',
    LSAD: 'County',
    CENSUSAREA: 372.855,
    FIPS: 'IN019',
    fips: '18019',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 38.47743705,
    lng: -85.70765032,
  },
  {
    GEO_ID: '0500000US18025',
    STATE: '18',
    COUNTY: '025',
    NAME: 'Crawford',
    LSAD: 'County',
    CENSUSAREA: 305.643,
    FIPS: 'IN025',
    fips: '18025',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 38.29202088,
    lng: -86.45126977,
  },
  {
    GEO_ID: '0500000US18031',
    STATE: '18',
    COUNTY: '031',
    NAME: 'Decatur',
    LSAD: 'County',
    CENSUSAREA: 372.568,
    FIPS: 'IN031',
    fips: '18031',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 39.30715055,
    lng: -85.50090226,
  },
  {
    GEO_ID: '0500000US18035',
    STATE: '18',
    COUNTY: '035',
    NAME: 'Delaware',
    LSAD: 'County',
    CENSUSAREA: 392.124,
    FIPS: 'IN035',
    fips: '18035',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 40.22787247,
    lng: -85.39732772,
  },
  {
    GEO_ID: '0500000US18045',
    STATE: '18',
    COUNTY: '045',
    NAME: 'Fountain',
    LSAD: 'County',
    CENSUSAREA: 395.656,
    FIPS: 'IN045',
    fips: '18045',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 40.12120145,
    lng: -87.24192118,
  },
  {
    GEO_ID: '0500000US13137',
    STATE: '13',
    COUNTY: '137',
    NAME: 'Habersham',
    LSAD: 'County',
    CENSUSAREA: 276.74,
    FIPS: 'GA137',
    fips: '13137',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 34.63090057,
    lng: -83.53054815,
  },
  {
    GEO_ID: '0500000US13141',
    STATE: '13',
    COUNTY: '141',
    NAME: 'Hancock',
    LSAD: 'County',
    CENSUSAREA: 471.841,
    FIPS: 'GA141',
    fips: '13141',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 33.27020552,
    lng: -83.00098883,
  },
  {
    GEO_ID: '0500000US13147',
    STATE: '13',
    COUNTY: '147',
    NAME: 'Hart',
    LSAD: 'County',
    CENSUSAREA: 232.394,
    FIPS: 'GA147',
    fips: '13147',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 34.35058893,
    lng: -82.96402556,
  },
  {
    GEO_ID: '0500000US13155',
    STATE: '13',
    COUNTY: '155',
    NAME: 'Irwin',
    LSAD: 'County',
    CENSUSAREA: 354.343,
    FIPS: 'GA155',
    fips: '13155',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 31.60235727,
    lng: -83.2762524,
  },
  {
    GEO_ID: '0500000US13159',
    STATE: '13',
    COUNTY: '159',
    NAME: 'Jasper',
    LSAD: 'County',
    CENSUSAREA: 368.165,
    FIPS: 'GA159',
    fips: '13159',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 33.31633785,
    lng: -83.68792053,
  },
  {
    GEO_ID: '0500000US13165',
    STATE: '13',
    COUNTY: '165',
    NAME: 'Jenkins',
    LSAD: 'County',
    CENSUSAREA: 347.279,
    FIPS: 'GA165',
    fips: '13165',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 32.79247176,
    lng: -81.96350449,
  },
  {
    GEO_ID: '0500000US13171',
    STATE: '13',
    COUNTY: '171',
    NAME: 'Lamar',
    LSAD: 'County',
    CENSUSAREA: 183.5,
    FIPS: 'GA171',
    fips: '13171',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 33.07619286,
    lng: -84.13960587,
  },
  {
    GEO_ID: '0500000US13173',
    STATE: '13',
    COUNTY: '173',
    NAME: 'Lanier',
    LSAD: 'County',
    CENSUSAREA: 185.261,
    FIPS: 'GA173',
    fips: '13173',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 31.03799605,
    lng: -83.06330638,
  },
  {
    GEO_ID: '0500000US20091',
    STATE: '20',
    COUNTY: '091',
    NAME: 'Johnson',
    LSAD: 'County',
    CENSUSAREA: 473.375,
    FIPS: 'KS091',
    fips: '20091',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 38.88370015,
    lng: -94.82256945,
  },
  {
    GEO_ID: '0500000US20097',
    STATE: '20',
    COUNTY: '097',
    NAME: 'Kiowa',
    LSAD: 'County',
    CENSUSAREA: 722.639,
    FIPS: 'KS097',
    fips: '20097',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 37.55841651,
    lng: -99.28576492,
  },
  {
    GEO_ID: '0500000US20099',
    STATE: '20',
    COUNTY: '099',
    NAME: 'Labette',
    LSAD: 'County',
    CENSUSAREA: 645.295,
    FIPS: 'KS099',
    fips: '20099',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 37.19127872,
    lng: -95.29765463,
  },
  {
    GEO_ID: '0500000US20103',
    STATE: '20',
    COUNTY: '103',
    NAME: 'Leavenworth',
    LSAD: 'County',
    CENSUSAREA: 462.831,
    FIPS: 'KS103',
    fips: '20103',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 39.19957457,
    lng: -95.03795118,
  },
  {
    GEO_ID: '0500000US20109',
    STATE: '20',
    COUNTY: '109',
    NAME: 'Logan',
    LSAD: 'County',
    CENSUSAREA: 1072.994,
    FIPS: 'KS109',
    fips: '20109',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 38.91735806,
    lng: -101.148475,
  },
  {
    GEO_ID: '0500000US20113',
    STATE: '20',
    COUNTY: '113',
    NAME: 'McPherson',
    LSAD: 'County',
    CENSUSAREA: 898.274,
    FIPS: 'KS113',
    fips: '20113',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 38.39187087,
    lng: -97.64761598,
  },
  {
    GEO_ID: '0500000US20119',
    STATE: '20',
    COUNTY: '119',
    NAME: 'Meade',
    LSAD: 'County',
    CENSUSAREA: 978.088,
    FIPS: 'KS119',
    fips: '20119',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 37.23826363,
    lng: -100.3655617,
  },
  {
    GEO_ID: '0500000US20125',
    STATE: '20',
    COUNTY: '125',
    NAME: 'Montgomery',
    LSAD: 'County',
    CENSUSAREA: 643.527,
    FIPS: 'KS125',
    fips: '20125',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 37.19252895,
    lng: -95.74280727,
  },
  {
    GEO_ID: '0500000US20131',
    STATE: '20',
    COUNTY: '131',
    NAME: 'Nemaha',
    LSAD: 'County',
    CENSUSAREA: 717.427,
    FIPS: 'KS131',
    fips: '20131',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 39.78343169,
    lng: -96.01393095,
  },
  {
    GEO_ID: '0500000US20141',
    STATE: '20',
    COUNTY: '141',
    NAME: 'Osborne',
    LSAD: 'County',
    CENSUSAREA: 892.504,
    FIPS: 'KS141',
    fips: '20141',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 39.3503437,
    lng: -98.76753828,
  },
  {
    GEO_ID: '0500000US20143',
    STATE: '20',
    COUNTY: '143',
    NAME: 'Ottawa',
    LSAD: 'County',
    CENSUSAREA: 720.733,
    FIPS: 'KS143',
    fips: '20143',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 39.13237408,
    lng: -97.65020313,
  },
  {
    GEO_ID: '0500000US20157',
    STATE: '20',
    COUNTY: '157',
    NAME: 'Republic',
    LSAD: 'County',
    CENSUSAREA: 717.371,
    FIPS: 'KS157',
    fips: '20157',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 39.8279368,
    lng: -97.65034285,
  },
  {
    GEO_ID: '0500000US20165',
    STATE: '20',
    COUNTY: '165',
    NAME: 'Rush',
    LSAD: 'County',
    CENSUSAREA: 717.763,
    FIPS: 'KS165',
    fips: '20165',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 38.52275276,
    lng: -99.30863983,
  },
  {
    GEO_ID: '0500000US20167',
    STATE: '20',
    COUNTY: '167',
    NAME: 'Russell',
    LSAD: 'County',
    CENSUSAREA: 886.26,
    FIPS: 'KS167',
    fips: '20167',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 38.91467637,
    lng: -98.76247878,
  },
  {
    GEO_ID: '0500000US20171',
    STATE: '20',
    COUNTY: '171',
    NAME: 'Scott',
    LSAD: 'County',
    CENSUSAREA: 717.544,
    FIPS: 'KS171',
    fips: '20171',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 38.48220778,
    lng: -100.9066618,
  },
  {
    GEO_ID: '0500000US20177',
    STATE: '20',
    COUNTY: '177',
    NAME: 'Shawnee',
    LSAD: 'County',
    CENSUSAREA: 544.016,
    FIPS: 'KS177',
    fips: '20177',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 39.04138192,
    lng: -95.75652721,
  },
  {
    GEO_ID: '0500000US22057',
    STATE: '22',
    COUNTY: '057',
    NAME: 'Lafourche',
    LSAD: 'Parish',
    CENSUSAREA: 1068.214,
    FIPS: 'LA057',
    fips: '22057',
    StateAbbr: 'LA',
    StateName: 'Louisiana',
    lat: 29.55797842,
    lng: -90.41865517,
  },
  {
    GEO_ID: '0500000US22061',
    STATE: '22',
    COUNTY: '061',
    NAME: 'Lincoln',
    LSAD: 'Parish',
    CENSUSAREA: 471.742,
    FIPS: 'LA061',
    fips: '22061',
    StateAbbr: 'LA',
    StateName: 'Louisiana',
    lat: 32.60149394,
    lng: -92.66473671,
  },
  {
    GEO_ID: '0500000US22063',
    STATE: '22',
    COUNTY: '063',
    NAME: 'Livingston',
    LSAD: 'Parish',
    CENSUSAREA: 648.169,
    FIPS: 'LA063',
    fips: '22063',
    StateAbbr: 'LA',
    StateName: 'Louisiana',
    lat: 30.44023061,
    lng: -90.72765311,
  },
  {
    GEO_ID: '0500000US22069',
    STATE: '22',
    COUNTY: '069',
    NAME: 'Natchitoches',
    LSAD: 'Parish',
    CENSUSAREA: 1252.25,
    FIPS: 'LA069',
    fips: '22069',
    StateAbbr: 'LA',
    StateName: 'Louisiana',
    lat: 31.72373065,
    lng: -93.09649471,
  },
  {
    GEO_ID: '0500000US22075',
    STATE: '22',
    COUNTY: '075',
    NAME: 'Plaquemines',
    LSAD: 'Parish',
    CENSUSAREA: 779.911,
    FIPS: 'LA075',
    fips: '22075',
    StateAbbr: 'LA',
    StateName: 'Louisiana',
    lat: 29.43435182,
    lng: -89.62329285,
  },
  {
    GEO_ID: '0500000US22079',
    STATE: '22',
    COUNTY: '079',
    NAME: 'Rapides',
    LSAD: 'Parish',
    CENSUSAREA: 1317.962,
    FIPS: 'LA079',
    fips: '22079',
    StateAbbr: 'LA',
    StateName: 'Louisiana',
    lat: 31.19810087,
    lng: -92.53365886,
  },
  {
    GEO_ID: '0500000US22081',
    STATE: '22',
    COUNTY: '081',
    NAME: 'Red River',
    LSAD: 'Parish',
    CENSUSAREA: 389.09,
    FIPS: 'LA081',
    fips: '22081',
    StateAbbr: 'LA',
    StateName: 'Louisiana',
    lat: 32.09302665,
    lng: -93.33992874,
  },
  {
    GEO_ID: '0500000US22085',
    STATE: '22',
    COUNTY: '085',
    NAME: 'Sabine',
    LSAD: 'Parish',
    CENSUSAREA: 866.659,
    FIPS: 'LA085',
    fips: '22085',
    StateAbbr: 'LA',
    StateName: 'Louisiana',
    lat: 31.56283023,
    lng: -93.55521453,
  },
  {
    GEO_ID: '0500000US21111',
    STATE: '21',
    COUNTY: '111',
    NAME: 'Jefferson',
    LSAD: 'County',
    CENSUSAREA: 380.416,
    FIPS: 'KY111',
    fips: '21111',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 38.18834895,
    lng: -85.65772828,
  },
  {
    GEO_ID: '0500000US21113',
    STATE: '21',
    COUNTY: '113',
    NAME: 'Jessamine',
    LSAD: 'County',
    CENSUSAREA: 172.116,
    FIPS: 'KY113',
    fips: '21113',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 37.87235886,
    lng: -84.58031888,
  },
  {
    GEO_ID: '0500000US21123',
    STATE: '21',
    COUNTY: '123',
    NAME: 'Larue',
    LSAD: 'County',
    CENSUSAREA: 261.519,
    FIPS: 'KY123',
    fips: '21123',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 37.54557718,
    lng: -85.69858421,
  },
  {
    GEO_ID: '0500000US21129',
    STATE: '21',
    COUNTY: '129',
    NAME: 'Lee',
    LSAD: 'County',
    CENSUSAREA: 208.857,
    FIPS: 'KY129',
    fips: '21129',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 37.59456493,
    lng: -83.71573518,
  },
  {
    GEO_ID: '0500000US21135',
    STATE: '21',
    COUNTY: '135',
    NAME: 'Lewis',
    LSAD: 'County',
    CENSUSAREA: 482.836,
    FIPS: 'KY135',
    fips: '21135',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 38.53155019,
    lng: -83.37804652,
  },
  {
    GEO_ID: '0500000US21139',
    STATE: '21',
    COUNTY: '139',
    NAME: 'Livingston',
    LSAD: 'County',
    CENSUSAREA: 313.127,
    FIPS: 'KY139',
    fips: '21139',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 37.20997298,
    lng: -88.35351775,
  },
  {
    GEO_ID: '0500000US21145',
    STATE: '21',
    COUNTY: '145',
    NAME: 'McCracken',
    LSAD: 'County',
    CENSUSAREA: 248.744,
    FIPS: 'KY145',
    fips: '21145',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 37.05384925,
    lng: -88.71249008,
  },
  {
    GEO_ID: '0500000US21153',
    STATE: '21',
    COUNTY: '153',
    NAME: 'Magoffin',
    LSAD: 'County',
    CENSUSAREA: 308.444,
    FIPS: 'KY153',
    fips: '21153',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 37.7063116,
    lng: -83.06414674,
  },
  {
    GEO_ID: '0500000US21159',
    STATE: '21',
    COUNTY: '159',
    NAME: 'Martin',
    LSAD: 'County',
    CENSUSAREA: 229.605,
    FIPS: 'KY159',
    fips: '21159',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 37.80090718,
    lng: -82.51414497,
  },
  {
    GEO_ID: '0500000US21167',
    STATE: '21',
    COUNTY: '167',
    NAME: 'Mercer',
    LSAD: 'County',
    CENSUSAREA: 248.797,
    FIPS: 'KY167',
    fips: '21167',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 37.81116654,
    lng: -84.87436107,
  },
  {
    GEO_ID: '0500000US21171',
    STATE: '21',
    COUNTY: '171',
    NAME: 'Monroe',
    LSAD: 'County',
    CENSUSAREA: 329.373,
    FIPS: 'KY171',
    fips: '21171',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 36.71217333,
    lng: -85.71679611,
  },
  {
    GEO_ID: '0500000US21173',
    STATE: '21',
    COUNTY: '173',
    NAME: 'Montgomery',
    LSAD: 'County',
    CENSUSAREA: 197.366,
    FIPS: 'KY173',
    fips: '21173',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 38.03256472,
    lng: -83.91291351,
  },
  {
    GEO_ID: '0500000US21179',
    STATE: '21',
    COUNTY: '179',
    NAME: 'Nelson',
    LSAD: 'County',
    CENSUSAREA: 417.512,
    FIPS: 'KY179',
    fips: '21179',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 37.80489242,
    lng: -85.46601953,
  },
  {
    GEO_ID: '0500000US19053',
    STATE: '19',
    COUNTY: '053',
    NAME: 'Decatur',
    LSAD: 'County',
    CENSUSAREA: 531.881,
    FIPS: 'IA053',
    fips: '19053',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 40.73762219,
    lng: -93.78630885,
  },
  {
    GEO_ID: '0500000US18003',
    STATE: '18',
    COUNTY: '003',
    NAME: 'Allen',
    LSAD: 'County',
    CENSUSAREA: 657.308,
    FIPS: 'IN003',
    fips: '18003',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 41.0912565,
    lng: -85.06652248,
  },
  {
    GEO_ID: '0500000US13181',
    STATE: '13',
    COUNTY: '181',
    NAME: 'Lincoln',
    LSAD: 'County',
    CENSUSAREA: 210.38,
    FIPS: 'GA181',
    fips: '13181',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 33.79324552,
    lng: -82.451183,
  },
  {
    GEO_ID: '0500000US19061',
    STATE: '19',
    COUNTY: '061',
    NAME: 'Dubuque',
    LSAD: 'County',
    CENSUSAREA: 608.305,
    FIPS: 'IA061',
    fips: '19061',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 42.46858503,
    lng: -90.88262627,
  },
  {
    GEO_ID: '0500000US19069',
    STATE: '19',
    COUNTY: '069',
    NAME: 'Franklin',
    LSAD: 'County',
    CENSUSAREA: 581.972,
    FIPS: 'IA069',
    fips: '19069',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 42.73270384,
    lng: -93.26256925,
  },
  {
    GEO_ID: '0500000US19075',
    STATE: '19',
    COUNTY: '075',
    NAME: 'Grundy',
    LSAD: 'County',
    CENSUSAREA: 501.858,
    FIPS: 'IA075',
    fips: '19075',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 42.40195716,
    lng: -92.7914653,
  },
  {
    GEO_ID: '0500000US19077',
    STATE: '19',
    COUNTY: '077',
    NAME: 'Guthrie',
    LSAD: 'County',
    CENSUSAREA: 590.62,
    FIPS: 'IA077',
    fips: '19077',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 41.68378965,
    lng: -94.50111014,
  },
  {
    GEO_ID: '0500000US19089',
    STATE: '19',
    COUNTY: '089',
    NAME: 'Howard',
    LSAD: 'County',
    CENSUSAREA: 473.248,
    FIPS: 'IA089',
    fips: '19089',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 43.35677003,
    lng: -92.31710205,
  },
  {
    GEO_ID: '0500000US19093',
    STATE: '19',
    COUNTY: '093',
    NAME: 'Ida',
    LSAD: 'County',
    CENSUSAREA: 431.508,
    FIPS: 'IA093',
    fips: '19093',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 42.38696136,
    lng: -95.51355387,
  },
  {
    GEO_ID: '0500000US19101',
    STATE: '19',
    COUNTY: '101',
    NAME: 'Jefferson',
    LSAD: 'County',
    CENSUSAREA: 435.51,
    FIPS: 'IA101',
    fips: '19101',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 41.03165465,
    lng: -91.94862204,
  },
  {
    GEO_ID: '0500000US19103',
    STATE: '19',
    COUNTY: '103',
    NAME: 'Johnson',
    LSAD: 'County',
    CENSUSAREA: 614.04,
    FIPS: 'IA103',
    fips: '19103',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 41.67150873,
    lng: -91.58679259,
  },
  {
    GEO_ID: '0500000US19107',
    STATE: '19',
    COUNTY: '107',
    NAME: 'Keokuk',
    LSAD: 'County',
    CENSUSAREA: 579.179,
    FIPS: 'IA107',
    fips: '19107',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 41.33649833,
    lng: -92.1785067,
  },
  {
    GEO_ID: '0500000US19111',
    STATE: '19',
    COUNTY: '111',
    NAME: 'Lee',
    LSAD: 'County',
    CENSUSAREA: 517.518,
    FIPS: 'IA111',
    fips: '19111',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 40.64219925,
    lng: -91.47949968,
  },
  {
    GEO_ID: '0500000US19127',
    STATE: '19',
    COUNTY: '127',
    NAME: 'Marshall',
    LSAD: 'County',
    CENSUSAREA: 572.501,
    FIPS: 'IA127',
    fips: '19127',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 42.03586837,
    lng: -92.9990819,
  },
  {
    GEO_ID: '0500000US19129',
    STATE: '19',
    COUNTY: '129',
    NAME: 'Mills',
    LSAD: 'County',
    CENSUSAREA: 437.437,
    FIPS: 'IA129',
    fips: '19129',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 41.03348848,
    lng: -95.62136125,
  },
  {
    GEO_ID: '0500000US19135',
    STATE: '19',
    COUNTY: '135',
    NAME: 'Monroe',
    LSAD: 'County',
    CENSUSAREA: 433.715,
    FIPS: 'IA135',
    fips: '19135',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 41.02988815,
    lng: -92.8690667,
  },
  {
    GEO_ID: '0500000US19139',
    STATE: '19',
    COUNTY: '139',
    NAME: 'Muscatine',
    LSAD: 'County',
    CENSUSAREA: 437.469,
    FIPS: 'IA139',
    fips: '19139',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 41.48388139,
    lng: -91.11279575,
  },
  {
    GEO_ID: '0500000US19145',
    STATE: '19',
    COUNTY: '145',
    NAME: 'Page',
    LSAD: 'County',
    CENSUSAREA: 534.942,
    FIPS: 'IA145',
    fips: '19145',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 40.73917437,
    lng: -95.15010031,
  },
  {
    GEO_ID: '0500000US20181',
    STATE: '20',
    COUNTY: '181',
    NAME: 'Sherman',
    LSAD: 'County',
    CENSUSAREA: 1056.066,
    FIPS: 'KS181',
    fips: '20181',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 39.35147377,
    lng: -101.7197684,
  },
  {
    GEO_ID: '0500000US19153',
    STATE: '19',
    COUNTY: '153',
    NAME: 'Polk',
    LSAD: 'County',
    CENSUSAREA: 573.795,
    FIPS: 'IA153',
    fips: '19153',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 41.68547042,
    lng: -93.57365396,
  },
  {
    GEO_ID: '0500000US19155',
    STATE: '19',
    COUNTY: '155',
    NAME: 'Pottawattamie',
    LSAD: 'County',
    CENSUSAREA: 950.28,
    FIPS: 'IA155',
    fips: '19155',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 41.33661346,
    lng: -95.54290277,
  },
  {
    GEO_ID: '0500000US19159',
    STATE: '19',
    COUNTY: '159',
    NAME: 'Ringgold',
    LSAD: 'County',
    CENSUSAREA: 535.498,
    FIPS: 'IA159',
    fips: '19159',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 40.7351498,
    lng: -94.24367465,
  },
  {
    GEO_ID: '0500000US19163',
    STATE: '19',
    COUNTY: '163',
    NAME: 'Scott',
    LSAD: 'County',
    CENSUSAREA: 458.089,
    FIPS: 'IA163',
    fips: '19163',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 41.63706131,
    lng: -90.62318822,
  },
  {
    GEO_ID: '0500000US19171',
    STATE: '19',
    COUNTY: '171',
    NAME: 'Tama',
    LSAD: 'County',
    CENSUSAREA: 721.011,
    FIPS: 'IA171',
    fips: '19171',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 42.07991767,
    lng: -92.53266945,
  },
  {
    GEO_ID: '0500000US19177',
    STATE: '19',
    COUNTY: '177',
    NAME: 'Van Buren',
    LSAD: 'County',
    CENSUSAREA: 484.789,
    FIPS: 'IA177',
    fips: '19177',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 40.75308722,
    lng: -91.95043301,
  },
  {
    GEO_ID: '0500000US19183',
    STATE: '19',
    COUNTY: '183',
    NAME: 'Washington',
    LSAD: 'County',
    CENSUSAREA: 568.835,
    FIPS: 'IA183',
    fips: '19183',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 41.33548743,
    lng: -91.71758189,
  },
  {
    GEO_ID: '0500000US19189',
    STATE: '19',
    COUNTY: '189',
    NAME: 'Winnebago',
    LSAD: 'County',
    CENSUSAREA: 400.489,
    FIPS: 'IA189',
    fips: '19189',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 43.3775066,
    lng: -93.73407175,
  },
  {
    GEO_ID: '0500000US19193',
    STATE: '19',
    COUNTY: '193',
    NAME: 'Woodbury',
    LSAD: 'County',
    CENSUSAREA: 872.834,
    FIPS: 'IA193',
    fips: '19193',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 42.38966116,
    lng: -96.04432637,
  },
  {
    GEO_ID: '0500000US20003',
    STATE: '20',
    COUNTY: '003',
    NAME: 'Anderson',
    LSAD: 'County',
    CENSUSAREA: 579.646,
    FIPS: 'KS003',
    fips: '20003',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 38.21396508,
    lng: -95.29324204,
  },
  {
    GEO_ID: '0500000US20009',
    STATE: '20',
    COUNTY: '009',
    NAME: 'Barton',
    LSAD: 'County',
    CENSUSAREA: 895.4,
    FIPS: 'KS009',
    fips: '20009',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 38.478934,
    lng: -98.75632156,
  },
  {
    GEO_ID: '0500000US20017',
    STATE: '20',
    COUNTY: '017',
    NAME: 'Chase',
    LSAD: 'County',
    CENSUSAREA: 773.06,
    FIPS: 'KS017',
    fips: '20017',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 38.30203945,
    lng: -96.59423335,
  },
  {
    GEO_ID: '0500000US20019',
    STATE: '20',
    COUNTY: '019',
    NAME: 'Chautauqua',
    LSAD: 'County',
    CENSUSAREA: 638.881,
    FIPS: 'KS019',
    fips: '20019',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 37.14997344,
    lng: -96.24531969,
  },
  {
    GEO_ID: '0500000US13211',
    STATE: '13',
    COUNTY: '211',
    NAME: 'Morgan',
    LSAD: 'County',
    CENSUSAREA: 347.349,
    FIPS: 'GA211',
    fips: '13211',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 33.5909479,
    lng: -83.49227858,
  },
  {
    GEO_ID: '0500000US27081',
    STATE: '27',
    COUNTY: '081',
    NAME: 'Lincoln',
    LSAD: 'County',
    CENSUSAREA: 536.757,
    FIPS: 'MN081',
    fips: '27081',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 44.4123209,
    lng: -96.26720134,
  },
  {
    GEO_ID: '0500000US27089',
    STATE: '27',
    COUNTY: '089',
    NAME: 'Marshall',
    LSAD: 'County',
    CENSUSAREA: 1775.068,
    FIPS: 'MN089',
    fips: '27089',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 48.35830232,
    lng: -96.3692954,
  },
  {
    GEO_ID: '0500000US27091',
    STATE: '27',
    COUNTY: '091',
    NAME: 'Martin',
    LSAD: 'County',
    CENSUSAREA: 712.347,
    FIPS: 'MN091',
    fips: '27091',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 43.67430059,
    lng: -94.55099667,
  },
  {
    GEO_ID: '0500000US27095',
    STATE: '27',
    COUNTY: '095',
    NAME: 'Mille Lacs',
    LSAD: 'County',
    CENSUSAREA: 572.31,
    FIPS: 'MN095',
    fips: '27095',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 45.9373852,
    lng: -93.62993103,
  },
  {
    GEO_ID: '0500000US27097',
    STATE: '27',
    COUNTY: '097',
    NAME: 'Morrison',
    LSAD: 'County',
    CENSUSAREA: 1125.062,
    FIPS: 'MN097',
    fips: '27097',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 46.01207437,
    lng: -94.26690418,
  },
  {
    GEO_ID: '0500000US27099',
    STATE: '27',
    COUNTY: '099',
    NAME: 'Mower',
    LSAD: 'County',
    CENSUSAREA: 711.329,
    FIPS: 'MN099',
    fips: '27099',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 43.67138759,
    lng: -92.75235164,
  },
  {
    GEO_ID: '0500000US27105',
    STATE: '27',
    COUNTY: '105',
    NAME: 'Nobles',
    LSAD: 'County',
    CENSUSAREA: 715.106,
    FIPS: 'MN105',
    fips: '27105',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 43.67428858,
    lng: -95.75326093,
  },
  {
    GEO_ID: '0500000US27109',
    STATE: '27',
    COUNTY: '109',
    NAME: 'Olmsted',
    LSAD: 'County',
    CENSUSAREA: 653.349,
    FIPS: 'MN109',
    fips: '27109',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 44.00380771,
    lng: -92.40209709,
  },
  {
    GEO_ID: '0500000US27111',
    STATE: '27',
    COUNTY: '111',
    NAME: 'Otter Tail',
    LSAD: 'County',
    CENSUSAREA: 1972.068,
    FIPS: 'MN111',
    fips: '27111',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 46.4090783,
    lng: -95.70748857,
  },
  {
    GEO_ID: '0500000US27115',
    STATE: '27',
    COUNTY: '115',
    NAME: 'Pine',
    LSAD: 'County',
    CENSUSAREA: 1411.289,
    FIPS: 'MN115',
    fips: '27115',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 46.12071071,
    lng: -92.74140502,
  },
  {
    GEO_ID: '0500000US27117',
    STATE: '27',
    COUNTY: '117',
    NAME: 'Pipestone',
    LSAD: 'County',
    CENSUSAREA: 465.055,
    FIPS: 'MN117',
    fips: '27117',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 44.02300689,
    lng: -96.258643,
  },
  {
    GEO_ID: '0500000US27119',
    STATE: '27',
    COUNTY: '119',
    NAME: 'Polk',
    LSAD: 'County',
    CENSUSAREA: 1971.128,
    FIPS: 'MN119',
    fips: '27119',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 47.77395991,
    lng: -96.40222842,
  },
  {
    GEO_ID: '0500000US27129',
    STATE: '27',
    COUNTY: '129',
    NAME: 'Renville',
    LSAD: 'County',
    CENSUSAREA: 982.906,
    FIPS: 'MN129',
    fips: '27129',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 44.72672673,
    lng: -94.94679511,
  },
  {
    GEO_ID: '0500000US27133',
    STATE: '27',
    COUNTY: '133',
    NAME: 'Rock',
    LSAD: 'County',
    CENSUSAREA: 482.455,
    FIPS: 'MN133',
    fips: '27133',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 43.67485346,
    lng: -96.25305709,
  },
  {
    GEO_ID: '0500000US19059',
    STATE: '19',
    COUNTY: '059',
    NAME: 'Dickinson',
    LSAD: 'County',
    CENSUSAREA: 380.606,
    FIPS: 'IA059',
    fips: '19059',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 43.37782493,
    lng: -95.15099401,
  },
  {
    GEO_ID: '0500000US27141',
    STATE: '27',
    COUNTY: '141',
    NAME: 'Sherburne',
    LSAD: 'County',
    CENSUSAREA: 432.917,
    FIPS: 'MN141',
    fips: '27141',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 45.44403172,
    lng: -93.77496918,
  },
  {
    GEO_ID: '0500000US27145',
    STATE: '27',
    COUNTY: '145',
    NAME: 'Stearns',
    LSAD: 'County',
    CENSUSAREA: 1343.133,
    FIPS: 'MN145',
    fips: '27145',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 45.55199481,
    lng: -94.61299658,
  },
  {
    GEO_ID: '0500000US27149',
    STATE: '27',
    COUNTY: '149',
    NAME: 'Stevens',
    LSAD: 'County',
    CENSUSAREA: 563.604,
    FIPS: 'MN149',
    fips: '27149',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 45.58615985,
    lng: -96.00022786,
  },
  {
    GEO_ID: '0500000US27157',
    STATE: '27',
    COUNTY: '157',
    NAME: 'Wabasha',
    LSAD: 'County',
    CENSUSAREA: 522.982,
    FIPS: 'MN157',
    fips: '27157',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 44.28428259,
    lng: -92.23023021,
  },
  {
    GEO_ID: '0500000US27163',
    STATE: '27',
    COUNTY: '163',
    NAME: 'Washington',
    LSAD: 'County',
    CENSUSAREA: 384.282,
    FIPS: 'MN163',
    fips: '27163',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 45.03911139,
    lng: -92.88374367,
  },
  {
    GEO_ID: '0500000US27171',
    STATE: '27',
    COUNTY: '171',
    NAME: 'Wright',
    LSAD: 'County',
    CENSUSAREA: 661.457,
    FIPS: 'MN171',
    fips: '27171',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 45.1738141,
    lng: -93.96334763,
  },
  {
    GEO_ID: '0500000US28005',
    STATE: '28',
    COUNTY: '005',
    NAME: 'Amite',
    LSAD: 'County',
    CENSUSAREA: 730.1,
    FIPS: 'MS005',
    fips: '28005',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 31.17425244,
    lng: -90.80453378,
  },
  {
    GEO_ID: '0500000US28009',
    STATE: '28',
    COUNTY: '009',
    NAME: 'Benton',
    LSAD: 'County',
    CENSUSAREA: 406.616,
    FIPS: 'MS009',
    fips: '28009',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 34.8172204,
    lng: -89.18844118,
  },
  {
    GEO_ID: '0500000US28011',
    STATE: '28',
    COUNTY: '011',
    NAME: 'Bolivar',
    LSAD: 'County',
    CENSUSAREA: 876.573,
    FIPS: 'MS011',
    fips: '28011',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 33.79549267,
    lng: -90.8802491,
  },
  {
    GEO_ID: '0500000US28015',
    STATE: '28',
    COUNTY: '015',
    NAME: 'Carroll',
    LSAD: 'County',
    CENSUSAREA: 628.243,
    FIPS: 'MS015',
    fips: '28015',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 33.44884455,
    lng: -89.92031051,
  },
  {
    GEO_ID: '0500000US28021',
    STATE: '28',
    COUNTY: '021',
    NAME: 'Claiborne',
    LSAD: 'County',
    CENSUSAREA: 487.406,
    FIPS: 'MS021',
    fips: '28021',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 31.9736924,
    lng: -90.91182829,
  },
  {
    GEO_ID: '0500000US28031',
    STATE: '28',
    COUNTY: '031',
    NAME: 'Covington',
    LSAD: 'County',
    CENSUSAREA: 413.792,
    FIPS: 'MS031',
    fips: '28031',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 31.6331285,
    lng: -89.55267845,
  },
  {
    GEO_ID: '0500000US28039',
    STATE: '28',
    COUNTY: '039',
    NAME: 'George',
    LSAD: 'County',
    CENSUSAREA: 478.708,
    FIPS: 'MS039',
    fips: '28039',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 30.86260363,
    lng: -88.64422567,
  },
  {
    GEO_ID: '0500000US19149',
    STATE: '19',
    COUNTY: '149',
    NAME: 'Plymouth',
    LSAD: 'County',
    CENSUSAREA: 862.893,
    FIPS: 'IA149',
    fips: '19149',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 42.73735704,
    lng: -96.21424951,
  },
  {
    GEO_ID: '0500000US28045',
    STATE: '28',
    COUNTY: '045',
    NAME: 'Hancock',
    LSAD: 'County',
    CENSUSAREA: 473.748,
    FIPS: 'MS045',
    fips: '28045',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 30.41595395,
    lng: -89.48847814,
  },
  {
    GEO_ID: '0500000US28051',
    STATE: '28',
    COUNTY: '051',
    NAME: 'Holmes',
    LSAD: 'County',
    CENSUSAREA: 756.699,
    FIPS: 'MS051',
    fips: '28051',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 33.12367405,
    lng: -90.09171669,
  },
  {
    GEO_ID: '0500000US28053',
    STATE: '28',
    COUNTY: '053',
    NAME: 'Humphreys',
    LSAD: 'County',
    CENSUSAREA: 418.491,
    FIPS: 'MS053',
    fips: '28053',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 33.12856998,
    lng: -90.52684783,
  },
  {
    GEO_ID: '0500000US28059',
    STATE: '28',
    COUNTY: '059',
    NAME: 'Jackson',
    LSAD: 'County',
    CENSUSAREA: 722.754,
    FIPS: 'MS059',
    fips: '28059',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 30.53467952,
    lng: -88.6353787,
  },
  {
    GEO_ID: '0500000US28067',
    STATE: '28',
    COUNTY: '067',
    NAME: 'Jones',
    LSAD: 'County',
    CENSUSAREA: 694.795,
    FIPS: 'MS067',
    fips: '28067',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 31.62257767,
    lng: -89.168969,
  },
  {
    GEO_ID: '0500000US28073',
    STATE: '28',
    COUNTY: '073',
    NAME: 'Lamar',
    LSAD: 'County',
    CENSUSAREA: 497.055,
    FIPS: 'MS073',
    fips: '28073',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 31.20573333,
    lng: -89.50870961,
  },
  {
    GEO_ID: '0500000US23007',
    STATE: '23',
    COUNTY: '007',
    NAME: 'Franklin',
    LSAD: 'County',
    CENSUSAREA: 1696.609,
    FIPS: 'ME007',
    fips: '23007',
    StateAbbr: 'ME',
    StateName: 'Maine',
    lat: 44.97424486,
    lng: -70.44421037,
  },
  {
    GEO_ID: '0500000US13215',
    STATE: '13',
    COUNTY: '215',
    NAME: 'Muscogee',
    LSAD: 'County',
    CENSUSAREA: 216.385,
    FIPS: 'GA215',
    fips: '13215',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 32.51001825,
    lng: -84.87620281,
  },
  {
    GEO_ID: '0500000US13239',
    STATE: '13',
    COUNTY: '239',
    NAME: 'Quitman',
    LSAD: 'County',
    CENSUSAREA: 151.237,
    FIPS: 'GA239',
    fips: '13239',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 31.86629277,
    lng: -85.01684071,
  },
  {
    GEO_ID: '0500000US27077',
    STATE: '27',
    COUNTY: '077',
    NAME: 'Lake of the Woods',
    LSAD: 'County',
    CENSUSAREA: 1297.869,
    FIPS: 'MN077',
    fips: '27077',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 48.77043756,
    lng: -94.90489792,
  },
  {
    GEO_ID: '0500000US45031',
    STATE: '45',
    COUNTY: '031',
    NAME: 'Darlington',
    LSAD: 'County',
    CENSUSAREA: 561.153,
    FIPS: 'SC031',
    fips: '45031',
    StateAbbr: 'SC',
    StateName: 'South Carolina',
    lat: 34.33234414,
    lng: -79.95766082,
  },
  {
    GEO_ID: '0500000US45035',
    STATE: '45',
    COUNTY: '035',
    NAME: 'Dorchester',
    LSAD: 'County',
    CENSUSAREA: 573.233,
    FIPS: 'SC035',
    fips: '45035',
    StateAbbr: 'SC',
    StateName: 'South Carolina',
    lat: 33.07948049,
    lng: -80.40502258,
  },
  {
    GEO_ID: '0500000US45037',
    STATE: '45',
    COUNTY: '037',
    NAME: 'Edgefield',
    LSAD: 'County',
    CENSUSAREA: 500.406,
    FIPS: 'SC037',
    fips: '45037',
    StateAbbr: 'SC',
    StateName: 'South Carolina',
    lat: 33.77224819,
    lng: -81.9661657,
  },
  {
    GEO_ID: '0500000US47011',
    STATE: '47',
    COUNTY: '011',
    NAME: 'Bradley',
    LSAD: 'County',
    CENSUSAREA: 328.762,
    FIPS: 'TN011',
    fips: '47011',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 35.15369028,
    lng: -84.85962176,
  },
  {
    GEO_ID: '0500000US47017',
    STATE: '47',
    COUNTY: '017',
    NAME: 'Carroll',
    LSAD: 'County',
    CENSUSAREA: 599.252,
    FIPS: 'TN017',
    fips: '47017',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 35.97307462,
    lng: -88.45060499,
  },
  {
    GEO_ID: '0500000US47021',
    STATE: '47',
    COUNTY: '021',
    NAME: 'Cheatham',
    LSAD: 'County',
    CENSUSAREA: 302.437,
    FIPS: 'TN021',
    fips: '47021',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 36.26140435,
    lng: -87.08648326,
  },
  {
    GEO_ID: '0500000US47025',
    STATE: '47',
    COUNTY: '025',
    NAME: 'Claiborne',
    LSAD: 'County',
    CENSUSAREA: 434.58,
    FIPS: 'TN025',
    fips: '47025',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 36.48575666,
    lng: -83.66072369,
  },
  {
    GEO_ID: '0500000US47033',
    STATE: '47',
    COUNTY: '033',
    NAME: 'Crockett',
    LSAD: 'County',
    CENSUSAREA: 265.535,
    FIPS: 'TN033',
    fips: '47033',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 35.81376271,
    lng: -89.13938582,
  },
  {
    GEO_ID: '0500000US47035',
    STATE: '47',
    COUNTY: '035',
    NAME: 'Cumberland',
    LSAD: 'County',
    CENSUSAREA: 681.025,
    FIPS: 'TN035',
    fips: '47035',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 35.95070017,
    lng: -84.9986148,
  },
  {
    GEO_ID: '0500000US47041',
    STATE: '47',
    COUNTY: '041',
    NAME: 'DeKalb',
    LSAD: 'County',
    CENSUSAREA: 304.347,
    FIPS: 'TN041',
    fips: '47041',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 35.98021146,
    lng: -85.83288678,
  },
  {
    GEO_ID: '0500000US47047',
    STATE: '47',
    COUNTY: '047',
    NAME: 'Fayette',
    LSAD: 'County',
    CENSUSAREA: 704.786,
    FIPS: 'TN047',
    fips: '47047',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 35.19709994,
    lng: -89.41493045,
  },
  {
    GEO_ID: '0500000US47049',
    STATE: '47',
    COUNTY: '049',
    NAME: 'Fentress',
    LSAD: 'County',
    CENSUSAREA: 498.612,
    FIPS: 'TN049',
    fips: '47049',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 36.3805182,
    lng: -84.9326155,
  },
  {
    GEO_ID: '0500000US47059',
    STATE: '47',
    COUNTY: '059',
    NAME: 'Greene',
    LSAD: 'County',
    CENSUSAREA: 622.165,
    FIPS: 'TN059',
    fips: '47059',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 36.17587226,
    lng: -82.84564638,
  },
  {
    GEO_ID: '0500000US47061',
    STATE: '47',
    COUNTY: '061',
    NAME: 'Grundy',
    LSAD: 'County',
    CENSUSAREA: 360.534,
    FIPS: 'TN061',
    fips: '47061',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 35.3884299,
    lng: -85.72237029,
  },
  {
    GEO_ID: '0500000US27137',
    STATE: '27',
    COUNTY: '137',
    NAME: 'St. Louis',
    LSAD: 'County',
    CENSUSAREA: 6247.401,
    FIPS: 'MN137',
    fips: '27137',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 47.60334656,
    lng: -92.46951183,
  },
  {
    GEO_ID: '0500000US23015',
    STATE: '23',
    COUNTY: '015',
    NAME: 'Lincoln',
    LSAD: 'County',
    CENSUSAREA: 455.818,
    FIPS: 'ME015',
    fips: '23015',
    StateAbbr: 'ME',
    StateName: 'Maine',
    lat: 44.06333351,
    lng: -69.54198777,
  },
  {
    GEO_ID: '0500000US23021',
    STATE: '23',
    COUNTY: '021',
    NAME: 'Piscataquis',
    LSAD: 'County',
    CENSUSAREA: 3960.856,
    FIPS: 'ME021',
    fips: '23021',
    StateAbbr: 'ME',
    StateName: 'Maine',
    lat: 45.83789196,
    lng: -69.28442666,
  },
  {
    GEO_ID: '0500000US23023',
    STATE: '23',
    COUNTY: '023',
    NAME: 'Sagadahoc',
    LSAD: 'County',
    CENSUSAREA: 253.695,
    FIPS: 'ME023',
    fips: '23023',
    StateAbbr: 'ME',
    StateName: 'Maine',
    lat: 43.95888365,
    lng: -69.85358192,
  },
  {
    GEO_ID: '0500000US23031',
    STATE: '23',
    COUNTY: '031',
    NAME: 'York',
    LSAD: 'County',
    CENSUSAREA: 990.713,
    FIPS: 'ME031',
    fips: '23031',
    StateAbbr: 'ME',
    StateName: 'Maine',
    lat: 43.47829059,
    lng: -70.7143165,
  },
  {
    GEO_ID: '0500000US28041',
    STATE: '28',
    COUNTY: '041',
    NAME: 'Greene',
    LSAD: 'County',
    CENSUSAREA: 712.755,
    FIPS: 'MS041',
    fips: '28041',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 31.21420839,
    lng: -88.63921416,
  },
  {
    GEO_ID: '0500000US31149',
    STATE: '31',
    COUNTY: '149',
    NAME: 'Rock',
    LSAD: 'County',
    CENSUSAREA: 1008.318,
    FIPS: 'NE149',
    fips: '31149',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 42.42142393,
    lng: -99.44982378,
  },
  {
    GEO_ID: '0500000US31151',
    STATE: '31',
    COUNTY: '151',
    NAME: 'Saline',
    LSAD: 'County',
    CENSUSAREA: 574.018,
    FIPS: 'NE151',
    fips: '31151',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 40.52415215,
    lng: -97.14080062,
  },
  {
    GEO_ID: '0500000US31155',
    STATE: '31',
    COUNTY: '155',
    NAME: 'Saunders',
    LSAD: 'County',
    CENSUSAREA: 750.234,
    FIPS: 'NE155',
    fips: '31155',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 41.22629371,
    lng: -96.63733984,
  },
  {
    GEO_ID: '0500000US31161',
    STATE: '31',
    COUNTY: '161',
    NAME: 'Sheridan',
    LSAD: 'County',
    CENSUSAREA: 2440.862,
    FIPS: 'NE161',
    fips: '31161',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 42.50454159,
    lng: -102.4088866,
  },
  {
    GEO_ID: '0500000US31169',
    STATE: '31',
    COUNTY: '169',
    NAME: 'Thayer',
    LSAD: 'County',
    CENSUSAREA: 573.807,
    FIPS: 'NE169',
    fips: '31169',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 40.17621044,
    lng: -97.59506372,
  },
  {
    GEO_ID: '0500000US31173',
    STATE: '31',
    COUNTY: '173',
    NAME: 'Thurston',
    LSAD: 'County',
    CENSUSAREA: 393.584,
    FIPS: 'NE173',
    fips: '31173',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 42.15819496,
    lng: -96.54400362,
  },
  {
    GEO_ID: '0500000US31181',
    STATE: '31',
    COUNTY: '181',
    NAME: 'Webster',
    LSAD: 'County',
    CENSUSAREA: 574.913,
    FIPS: 'NE181',
    fips: '31181',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 40.17643706,
    lng: -98.50021623,
  },
  {
    GEO_ID: '0500000US32005',
    STATE: '32',
    COUNTY: '005',
    NAME: 'Douglas',
    LSAD: 'County',
    CENSUSAREA: 709.719,
    FIPS: 'NV005',
    fips: '32005',
    StateAbbr: 'NV',
    StateName: 'Nevada',
    lat: 38.91213378,
    lng: -119.6162086,
  },
  {
    GEO_ID: '0500000US32007',
    STATE: '32',
    COUNTY: '007',
    NAME: 'Elko',
    LSAD: 'County',
    CENSUSAREA: 17169.834,
    FIPS: 'NV007',
    fips: '32007',
    StateAbbr: 'NV',
    StateName: 'Nevada',
    lat: 41.14601583,
    lng: -115.3578839,
  },
  {
    GEO_ID: '0500000US32013',
    STATE: '32',
    COUNTY: '013',
    NAME: 'Humboldt',
    LSAD: 'County',
    CENSUSAREA: 9640.757,
    FIPS: 'NV013',
    fips: '32013',
    StateAbbr: 'NV',
    StateName: 'Nevada',
    lat: 41.4063802,
    lng: -118.1118195,
  },
  {
    GEO_ID: '0500000US32017',
    STATE: '32',
    COUNTY: '017',
    NAME: 'Lincoln',
    LSAD: 'County',
    CENSUSAREA: 10633.202,
    FIPS: 'NV017',
    fips: '32017',
    StateAbbr: 'NV',
    StateName: 'Nevada',
    lat: 37.64342469,
    lng: -114.8771351,
  },
  {
    GEO_ID: '0500000US32023',
    STATE: '32',
    COUNTY: '023',
    NAME: 'Nye',
    LSAD: 'County',
    CENSUSAREA: 18181.924,
    FIPS: 'NV023',
    fips: '32023',
    StateAbbr: 'NV',
    StateName: 'Nevada',
    lat: 38.04212846,
    lng: -116.47176,
  },
  {
    GEO_ID: '0500000US23011',
    STATE: '23',
    COUNTY: '011',
    NAME: 'Kennebec',
    LSAD: 'County',
    CENSUSAREA: 867.524,
    FIPS: 'ME011',
    fips: '23011',
    StateAbbr: 'ME',
    StateName: 'Maine',
    lat: 44.40901704,
    lng: -69.76757384,
  },
  {
    GEO_ID: '0500000US39105',
    STATE: '39',
    COUNTY: '105',
    NAME: 'Meigs',
    LSAD: 'County',
    CENSUSAREA: 430.098,
    FIPS: 'OH105',
    fips: '39105',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 39.08233336,
    lng: -82.02295352,
  },
  {
    GEO_ID: '0500000US39107',
    STATE: '39',
    COUNTY: '107',
    NAME: 'Mercer',
    LSAD: 'County',
    CENSUSAREA: 462.449,
    FIPS: 'OH107',
    fips: '39107',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 40.54030771,
    lng: -84.62929783,
  },
  {
    GEO_ID: '0500000US39117',
    STATE: '39',
    COUNTY: '117',
    NAME: 'Morrow',
    LSAD: 'County',
    CENSUSAREA: 406.079,
    FIPS: 'OH117',
    fips: '39117',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 40.52405935,
    lng: -82.79458938,
  },
  {
    GEO_ID: '0500000US39119',
    STATE: '39',
    COUNTY: '119',
    NAME: 'Muskingum',
    LSAD: 'County',
    CENSUSAREA: 664.579,
    FIPS: 'OH119',
    fips: '39119',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 39.96581387,
    lng: -81.94452332,
  },
  {
    GEO_ID: '0500000US39125',
    STATE: '39',
    COUNTY: '125',
    NAME: 'Paulding',
    LSAD: 'County',
    CENSUSAREA: 416.438,
    FIPS: 'OH125',
    fips: '39125',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 41.11674251,
    lng: -84.58018842,
  },
  {
    GEO_ID: '0500000US39129',
    STATE: '39',
    COUNTY: '129',
    NAME: 'Pickaway',
    LSAD: 'County',
    CENSUSAREA: 501.32,
    FIPS: 'OH129',
    fips: '39129',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 39.64185964,
    lng: -83.02450193,
  },
  {
    GEO_ID: '0500000US39133',
    STATE: '39',
    COUNTY: '133',
    NAME: 'Portage',
    LSAD: 'County',
    CENSUSAREA: 487.381,
    FIPS: 'OH133',
    fips: '39133',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 41.16790847,
    lng: -81.19736816,
  },
  {
    GEO_ID: '0500000US39135',
    STATE: '39',
    COUNTY: '135',
    NAME: 'Preble',
    LSAD: 'County',
    CENSUSAREA: 424.12,
    FIPS: 'OH135',
    fips: '39135',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 39.74220535,
    lng: -84.64804448,
  },
  {
    GEO_ID: '0500000US39139',
    STATE: '39',
    COUNTY: '139',
    NAME: 'Richland',
    LSAD: 'County',
    CENSUSAREA: 495.269,
    FIPS: 'OH139',
    fips: '39139',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 40.77482478,
    lng: -82.53676639,
  },
  {
    GEO_ID: '0500000US39141',
    STATE: '39',
    COUNTY: '141',
    NAME: 'Ross',
    LSAD: 'County',
    CENSUSAREA: 689.188,
    FIPS: 'OH141',
    fips: '39141',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 39.33753008,
    lng: -83.0569591,
  },
  {
    GEO_ID: '0500000US39149',
    STATE: '39',
    COUNTY: '149',
    NAME: 'Shelby',
    LSAD: 'County',
    CENSUSAREA: 407.675,
    FIPS: 'OH149',
    fips: '39149',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 40.33212183,
    lng: -84.20440063,
  },
  {
    GEO_ID: '0500000US39151',
    STATE: '39',
    COUNTY: '151',
    NAME: 'Stark',
    LSAD: 'County',
    CENSUSAREA: 575.271,
    FIPS: 'OH151',
    fips: '39151',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 40.8138382,
    lng: -81.36571811,
  },
  {
    GEO_ID: '0500000US39157',
    STATE: '39',
    COUNTY: '157',
    NAME: 'Tuscarawas',
    LSAD: 'County',
    CENSUSAREA: 567.636,
    FIPS: 'OH157',
    fips: '39157',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 40.44131431,
    lng: -81.47450488,
  },
  {
    GEO_ID: '0500000US39161',
    STATE: '39',
    COUNTY: '161',
    NAME: 'Van Wert',
    LSAD: 'County',
    CENSUSAREA: 409.158,
    FIPS: 'OH161',
    fips: '39161',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 40.8555416,
    lng: -84.58607604,
  },
  {
    GEO_ID: '0500000US47065',
    STATE: '47',
    COUNTY: '065',
    NAME: 'Hamilton',
    LSAD: 'County',
    CENSUSAREA: 542.431,
    FIPS: 'TN065',
    fips: '47065',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 35.18133492,
    lng: -85.1644476,
  },
  {
    GEO_ID: '0500000US47067',
    STATE: '47',
    COUNTY: '067',
    NAME: 'Hancock',
    LSAD: 'County',
    CENSUSAREA: 222.34,
    FIPS: 'TN067',
    fips: '47067',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 36.52355034,
    lng: -83.2217117,
  },
  {
    GEO_ID: '0500000US47071',
    STATE: '47',
    COUNTY: '071',
    NAME: 'Hardin',
    LSAD: 'County',
    CENSUSAREA: 577.318,
    FIPS: 'TN071',
    fips: '47071',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 35.19856007,
    lng: -88.1845967,
  },
  {
    GEO_ID: '0500000US47077',
    STATE: '47',
    COUNTY: '077',
    NAME: 'Henderson',
    LSAD: 'County',
    CENSUSAREA: 520.073,
    FIPS: 'TN077',
    fips: '47077',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 35.65381663,
    lng: -88.38771258,
  },
  {
    GEO_ID: '0500000US47083',
    STATE: '47',
    COUNTY: '083',
    NAME: 'Houston',
    LSAD: 'County',
    CENSUSAREA: 200.286,
    FIPS: 'TN083',
    fips: '47083',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 36.28616631,
    lng: -87.71768095,
  },
  {
    GEO_ID: '0500000US47087',
    STATE: '47',
    COUNTY: '087',
    NAME: 'Jackson',
    LSAD: 'County',
    CENSUSAREA: 308.32,
    FIPS: 'TN087',
    fips: '47087',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 36.35953777,
    lng: -85.67355848,
  },
  {
    GEO_ID: '0500000US47095',
    STATE: '47',
    COUNTY: '095',
    NAME: 'Lake',
    LSAD: 'County',
    CENSUSAREA: 165.784,
    FIPS: 'TN095',
    fips: '47095',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 36.33529914,
    lng: -89.49340328,
  },
  {
    GEO_ID: '0500000US47101',
    STATE: '47',
    COUNTY: '101',
    NAME: 'Lewis',
    LSAD: 'County',
    CENSUSAREA: 282.089,
    FIPS: 'TN101',
    fips: '47101',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 35.52726999,
    lng: -87.49365286,
  },
  {
    GEO_ID: '0500000US47107',
    STATE: '47',
    COUNTY: '107',
    NAME: 'McMinn',
    LSAD: 'County',
    CENSUSAREA: 430.125,
    FIPS: 'TN107',
    fips: '47107',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 35.4243615,
    lng: -84.61781822,
  },
  {
    GEO_ID: '0500000US47109',
    STATE: '47',
    COUNTY: '109',
    NAME: 'McNairy',
    LSAD: 'County',
    CENSUSAREA: 562.86,
    FIPS: 'TN109',
    fips: '47109',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 35.17583563,
    lng: -88.56332928,
  },
  {
    GEO_ID: '0500000US47111',
    STATE: '47',
    COUNTY: '111',
    NAME: 'Macon',
    LSAD: 'County',
    CENSUSAREA: 307.144,
    FIPS: 'TN111',
    fips: '47111',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 36.53211833,
    lng: -86.00775763,
  },
  {
    GEO_ID: '0500000US47119',
    STATE: '47',
    COUNTY: '119',
    NAME: 'Maury',
    LSAD: 'County',
    CENSUSAREA: 613.138,
    FIPS: 'TN119',
    fips: '47119',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 35.6177569,
    lng: -87.0771147,
  },
  {
    GEO_ID: '0500000US47125',
    STATE: '47',
    COUNTY: '125',
    NAME: 'Montgomery',
    LSAD: 'County',
    CENSUSAREA: 539.177,
    FIPS: 'TN125',
    fips: '47125',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 36.49650951,
    lng: -87.38247263,
  },
  {
    GEO_ID: '0500000US47135',
    STATE: '47',
    COUNTY: '135',
    NAME: 'Perry',
    LSAD: 'County',
    CENSUSAREA: 414.731,
    FIPS: 'TN135',
    fips: '47135',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 35.64313951,
    lng: -87.85894071,
  },
  {
    GEO_ID: '0500000US47137',
    STATE: '47',
    COUNTY: '137',
    NAME: 'Pickett',
    LSAD: 'County',
    CENSUSAREA: 162.979,
    FIPS: 'TN137',
    fips: '47137',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 36.55818853,
    lng: -85.07476517,
  },
  {
    GEO_ID: '0500000US24011',
    STATE: '24',
    COUNTY: '011',
    NAME: 'Caroline',
    LSAD: 'County',
    CENSUSAREA: 319.419,
    FIPS: 'MD011',
    fips: '24011',
    StateAbbr: 'MD',
    StateName: 'Maryland',
    lat: 38.87198968,
    lng: -75.83180727,
  },
  {
    GEO_ID: '0500000US24013',
    STATE: '24',
    COUNTY: '013',
    NAME: 'Carroll',
    LSAD: 'County',
    CENSUSAREA: 447.595,
    FIPS: 'MD013',
    fips: '24013',
    StateAbbr: 'MD',
    StateName: 'Maryland',
    lat: 39.56320174,
    lng: -77.02256972,
  },
  {
    GEO_ID: '0500000US24021',
    STATE: '24',
    COUNTY: '021',
    NAME: 'Frederick',
    LSAD: 'County',
    CENSUSAREA: 660.221,
    FIPS: 'MD021',
    fips: '24021',
    StateAbbr: 'MD',
    StateName: 'Maryland',
    lat: 39.4720692,
    lng: -77.3978074,
  },
  {
    GEO_ID: '0500000US24023',
    STATE: '24',
    COUNTY: '023',
    NAME: 'Garrett',
    LSAD: 'County',
    CENSUSAREA: 647.103,
    FIPS: 'MD023',
    fips: '24023',
    StateAbbr: 'MD',
    StateName: 'Maryland',
    lat: 39.52849306,
    lng: -79.27376451,
  },
  {
    GEO_ID: '0500000US24031',
    STATE: '24',
    COUNTY: '031',
    NAME: 'Montgomery',
    LSAD: 'County',
    CENSUSAREA: 491.254,
    FIPS: 'MD031',
    fips: '24031',
    StateAbbr: 'MD',
    StateName: 'Maryland',
    lat: 39.13634899,
    lng: -77.20428439,
  },
  {
    GEO_ID: '0500000US24033',
    STATE: '24',
    COUNTY: '033',
    NAME: "Prince George's",
    LSAD: 'County',
    CENSUSAREA: 482.691,
    FIPS: 'MD033',
    fips: '24033',
    StateAbbr: 'MD',
    StateName: 'Maryland',
    lat: 38.8299677,
    lng: -76.84739072,
  },
  {
    GEO_ID: '0500000US24043',
    STATE: '24',
    COUNTY: '043',
    NAME: 'Washington',
    LSAD: 'County',
    CENSUSAREA: 457.78,
    FIPS: 'MD043',
    fips: '24043',
    StateAbbr: 'MD',
    StateName: 'Maryland',
    lat: 39.60413752,
    lng: -77.81329014,
  },
  {
    GEO_ID: '0500000US32031',
    STATE: '32',
    COUNTY: '031',
    NAME: 'Washoe',
    LSAD: 'County',
    CENSUSAREA: 6302.366,
    FIPS: 'NV031',
    fips: '32031',
    StateAbbr: 'NV',
    StateName: 'Nevada',
    lat: 40.66566815,
    lng: -119.6643222,
  },
  {
    GEO_ID: '0500000US32510',
    STATE: '32',
    COUNTY: '510',
    NAME: 'Carson City',
    LSAD: '',
    CENSUSAREA: 144.662,
    FIPS: 'NV510',
    fips: '32510',
    StateAbbr: 'NV',
    StateName: 'Nevada',
    lat: 39.15108405,
    lng: -119.7473502,
  },
  {
    GEO_ID: '0500000US33003',
    STATE: '33',
    COUNTY: '003',
    NAME: 'Carroll',
    LSAD: 'County',
    CENSUSAREA: 931.061,
    FIPS: 'NH003',
    fips: '33003',
    StateAbbr: 'NH',
    StateName: 'New Hampshire',
    lat: 43.87378859,
    lng: -71.20268868,
  },
  {
    GEO_ID: '0500000US33009',
    STATE: '33',
    COUNTY: '009',
    NAME: 'Grafton',
    LSAD: 'County',
    CENSUSAREA: 1708.749,
    FIPS: 'NH009',
    fips: '33009',
    StateAbbr: 'NH',
    StateName: 'New Hampshire',
    lat: 43.94073661,
    lng: -71.82064764,
  },
  {
    GEO_ID: '0500000US33015',
    STATE: '33',
    COUNTY: '015',
    NAME: 'Rockingham',
    LSAD: 'County',
    CENSUSAREA: 694.72,
    FIPS: 'NH015',
    fips: '33015',
    StateAbbr: 'NH',
    StateName: 'New Hampshire',
    lat: 42.98749699,
    lng: -71.12556353,
  },
  {
    GEO_ID: '0500000US33019',
    STATE: '33',
    COUNTY: '019',
    NAME: 'Sullivan',
    LSAD: 'County',
    CENSUSAREA: 537.313,
    FIPS: 'NH019',
    fips: '33019',
    StateAbbr: 'NH',
    StateName: 'New Hampshire',
    lat: 43.36150987,
    lng: -72.22243767,
  },
  {
    GEO_ID: '0500000US34003',
    STATE: '34',
    COUNTY: '003',
    NAME: 'Bergen',
    LSAD: 'County',
    CENSUSAREA: 233.009,
    FIPS: 'NJ003',
    fips: '34003',
    StateAbbr: 'NJ',
    StateName: 'New Jersey',
    lat: 40.96008351,
    lng: -74.07441147,
  },
  {
    GEO_ID: '0500000US34009',
    STATE: '34',
    COUNTY: '009',
    NAME: 'Cape May',
    LSAD: 'County',
    CENSUSAREA: 251.425,
    FIPS: 'NJ009',
    fips: '34009',
    StateAbbr: 'NJ',
    StateName: 'New Jersey',
    lat: 39.14901203,
    lng: -74.80053334,
  },
  {
    GEO_ID: '0500000US34013',
    STATE: '34',
    COUNTY: '013',
    NAME: 'Essex',
    LSAD: 'County',
    CENSUSAREA: 126.212,
    FIPS: 'NJ013',
    fips: '34013',
    StateAbbr: 'NJ',
    StateName: 'New Jersey',
    lat: 40.78746195,
    lng: -74.24718209,
  },
  {
    GEO_ID: '0500000US39167',
    STATE: '39',
    COUNTY: '167',
    NAME: 'Washington',
    LSAD: 'County',
    CENSUSAREA: 631.972,
    FIPS: 'OH167',
    fips: '39167',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 39.45557887,
    lng: -81.49547648,
  },
  {
    GEO_ID: '0500000US39171',
    STATE: '39',
    COUNTY: '171',
    NAME: 'Williams',
    LSAD: 'County',
    CENSUSAREA: 420.965,
    FIPS: 'OH171',
    fips: '39171',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 41.56027968,
    lng: -84.58816167,
  },
  {
    GEO_ID: '0500000US39175',
    STATE: '39',
    COUNTY: '175',
    NAME: 'Wyandot',
    LSAD: 'County',
    CENSUSAREA: 406.865,
    FIPS: 'OH175',
    fips: '39175',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 40.84236699,
    lng: -83.3042663,
  },
  {
    GEO_ID: '0500000US40003',
    STATE: '40',
    COUNTY: '003',
    NAME: 'Alfalfa',
    LSAD: 'County',
    CENSUSAREA: 866.455,
    FIPS: 'OK003',
    fips: '40003',
    StateAbbr: 'OK',
    StateName: 'Oklahoma',
    lat: 36.73091839,
    lng: -98.32176345,
  },
  {
    GEO_ID: '0500000US40011',
    STATE: '40',
    COUNTY: '011',
    NAME: 'Blaine',
    LSAD: 'County',
    CENSUSAREA: 928.424,
    FIPS: 'OK011',
    fips: '40011',
    StateAbbr: 'OK',
    StateName: 'Oklahoma',
    lat: 35.87536168,
    lng: -98.432652,
  },
  {
    GEO_ID: '0500000US40013',
    STATE: '40',
    COUNTY: '013',
    NAME: 'Bryan',
    LSAD: 'County',
    CENSUSAREA: 904.471,
    FIPS: 'OK013',
    fips: '40013',
    StateAbbr: 'OK',
    StateName: 'Oklahoma',
    lat: 33.96278604,
    lng: -96.26246372,
  },
  {
    GEO_ID: '0500000US40017',
    STATE: '40',
    COUNTY: '017',
    NAME: 'Canadian',
    LSAD: 'County',
    CENSUSAREA: 896.626,
    FIPS: 'OK017',
    fips: '40017',
    StateAbbr: 'OK',
    StateName: 'Oklahoma',
    lat: 35.54233821,
    lng: -97.98241218,
  },
  {
    GEO_ID: '0500000US40025',
    STATE: '40',
    COUNTY: '025',
    NAME: 'Cimarron',
    LSAD: 'County',
    CENSUSAREA: 1834.738,
    FIPS: 'OK025',
    fips: '40025',
    StateAbbr: 'OK',
    StateName: 'Oklahoma',
    lat: 36.74826632,
    lng: -102.5178111,
  },
  {
    GEO_ID: '0500000US40029',
    STATE: '40',
    COUNTY: '029',
    NAME: 'Coal',
    LSAD: 'County',
    CENSUSAREA: 516.682,
    FIPS: 'OK029',
    fips: '40029',
    StateAbbr: 'OK',
    StateName: 'Oklahoma',
    lat: 34.58840127,
    lng: -96.29798747,
  },
  {
    GEO_ID: '0500000US40035',
    STATE: '40',
    COUNTY: '035',
    NAME: 'Craig',
    LSAD: 'County',
    CENSUSAREA: 761.354,
    FIPS: 'OK035',
    fips: '40035',
    StateAbbr: 'OK',
    StateName: 'Oklahoma',
    lat: 36.76178038,
    lng: -95.20866115,
  },
  {
    GEO_ID: '0500000US40039',
    STATE: '40',
    COUNTY: '039',
    NAME: 'Custer',
    LSAD: 'County',
    CENSUSAREA: 988.817,
    FIPS: 'OK039',
    fips: '40039',
    StateAbbr: 'OK',
    StateName: 'Oklahoma',
    lat: 35.63856198,
    lng: -99.0014123,
  },
  {
    GEO_ID: '0500000US40045',
    STATE: '40',
    COUNTY: '045',
    NAME: 'Ellis',
    LSAD: 'County',
    CENSUSAREA: 1231.515,
    FIPS: 'OK045',
    fips: '40045',
    StateAbbr: 'OK',
    StateName: 'Oklahoma',
    lat: 36.21835085,
    lng: -99.75451143,
  },
  {
    GEO_ID: '0500000US40049',
    STATE: '40',
    COUNTY: '049',
    NAME: 'Garvin',
    LSAD: 'County',
    CENSUSAREA: 802.121,
    FIPS: 'OK049',
    fips: '40049',
    StateAbbr: 'OK',
    StateName: 'Oklahoma',
    lat: 34.7044021,
    lng: -97.30937006,
  },
  {
    GEO_ID: '0500000US40055',
    STATE: '40',
    COUNTY: '055',
    NAME: 'Greer',
    LSAD: 'County',
    CENSUSAREA: 639.324,
    FIPS: 'OK055',
    fips: '40055',
    StateAbbr: 'OK',
    StateName: 'Oklahoma',
    lat: 34.93429868,
    lng: -99.56059648,
  },
  {
    GEO_ID: '0500000US37193',
    STATE: '37',
    COUNTY: '193',
    NAME: 'Wilkes',
    LSAD: 'County',
    CENSUSAREA: 754.278,
    FIPS: 'NC193',
    fips: '37193',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 36.20617699,
    lng: -81.16305367,
  },
  {
    GEO_ID: '0500000US47141',
    STATE: '47',
    COUNTY: '141',
    NAME: 'Putnam',
    LSAD: 'County',
    CENSUSAREA: 401.103,
    FIPS: 'TN141',
    fips: '47141',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 36.14080321,
    lng: -85.49633518,
  },
  {
    GEO_ID: '0500000US48045',
    STATE: '48',
    COUNTY: '045',
    NAME: 'Briscoe',
    LSAD: 'County',
    CENSUSAREA: 900.001,
    FIPS: 'TX045',
    fips: '48045',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 34.53026962,
    lng: -101.2084947,
  },
  {
    GEO_ID: '0500000US48049',
    STATE: '48',
    COUNTY: '049',
    NAME: 'Brown',
    LSAD: 'County',
    CENSUSAREA: 944.433,
    FIPS: 'TX049',
    fips: '48049',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 31.77417703,
    lng: -98.99881071,
  },
  {
    GEO_ID: '0500000US48055',
    STATE: '48',
    COUNTY: '055',
    NAME: 'Caldwell',
    LSAD: 'County',
    CENSUSAREA: 545.257,
    FIPS: 'TX055',
    fips: '48055',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 29.83714515,
    lng: -97.61976797,
  },
  {
    GEO_ID: '0500000US48061',
    STATE: '48',
    COUNTY: '061',
    NAME: 'Cameron',
    LSAD: 'County',
    CENSUSAREA: 890.922,
    FIPS: 'TX061',
    fips: '48061',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 26.13427178,
    lng: -97.51397873,
  },
  {
    GEO_ID: '0500000US48065',
    STATE: '48',
    COUNTY: '065',
    NAME: 'Carson',
    LSAD: 'County',
    CENSUSAREA: 920.22,
    FIPS: 'TX065',
    fips: '48065',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 35.40365929,
    lng: -101.3542669,
  },
  {
    GEO_ID: '0500000US48067',
    STATE: '48',
    COUNTY: '067',
    NAME: 'Cass',
    LSAD: 'County',
    CENSUSAREA: 936.963,
    FIPS: 'TX067',
    fips: '48067',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 33.07772099,
    lng: -94.34317524,
  },
  {
    GEO_ID: '0500000US48073',
    STATE: '48',
    COUNTY: '073',
    NAME: 'Cherokee',
    LSAD: 'County',
    CENSUSAREA: 1052.913,
    FIPS: 'TX073',
    fips: '48073',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 31.83752856,
    lng: -95.16565693,
  },
  {
    GEO_ID: '0500000US48077',
    STATE: '48',
    COUNTY: '077',
    NAME: 'Clay',
    LSAD: 'County',
    CENSUSAREA: 1088.721,
    FIPS: 'TX077',
    fips: '48077',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 33.78558981,
    lng: -98.20837415,
  },
  {
    GEO_ID: '0500000US48081',
    STATE: '48',
    COUNTY: '081',
    NAME: 'Coke',
    LSAD: 'County',
    CENSUSAREA: 911.47,
    FIPS: 'TX081',
    fips: '48081',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 31.88933528,
    lng: -100.5295971,
  },
  {
    GEO_ID: '0500000US48085',
    STATE: '48',
    COUNTY: '085',
    NAME: 'Collin',
    LSAD: 'County',
    CENSUSAREA: 841.225,
    FIPS: 'TX085',
    fips: '48085',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 33.18822716,
    lng: -96.57266803,
  },
  {
    GEO_ID: '0500000US48087',
    STATE: '48',
    COUNTY: '087',
    NAME: 'Collingsworth',
    LSAD: 'County',
    CENSUSAREA: 918.441,
    FIPS: 'TX087',
    fips: '48087',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 34.96489439,
    lng: -100.2700105,
  },
  {
    GEO_ID: '0500000US48091',
    STATE: '48',
    COUNTY: '091',
    NAME: 'Comal',
    LSAD: 'County',
    CENSUSAREA: 559.476,
    FIPS: 'TX091',
    fips: '48091',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 29.80840142,
    lng: -98.2785174,
  },
  {
    GEO_ID: '0500000US24047',
    STATE: '24',
    COUNTY: '047',
    NAME: 'Worcester',
    LSAD: 'County',
    CENSUSAREA: 468.281,
    FIPS: 'MD047',
    fips: '24047',
    StateAbbr: 'MD',
    StateName: 'Maryland',
    lat: 38.212837,
    lng: -75.33389427,
  },
  {
    GEO_ID: '0500000US25007',
    STATE: '25',
    COUNTY: '007',
    NAME: 'Dukes',
    LSAD: 'County',
    CENSUSAREA: 103.245,
    FIPS: 'MA007',
    fips: '25007',
    StateAbbr: 'MA',
    StateName: 'Massachusetts',
    lat: 41.39790643,
    lng: -70.65382633,
  },
  {
    GEO_ID: '0500000US25013',
    STATE: '25',
    COUNTY: '013',
    NAME: 'Hampden',
    LSAD: 'County',
    CENSUSAREA: 617.14,
    FIPS: 'MA013',
    fips: '25013',
    StateAbbr: 'MA',
    StateName: 'Massachusetts',
    lat: 42.13509064,
    lng: -72.63119338,
  },
  {
    GEO_ID: '0500000US25015',
    STATE: '25',
    COUNTY: '015',
    NAME: 'Hampshire',
    LSAD: 'County',
    CENSUSAREA: 527.255,
    FIPS: 'MA015',
    fips: '25015',
    StateAbbr: 'MA',
    StateName: 'Massachusetts',
    lat: 42.34022511,
    lng: -72.66378659,
  },
  {
    GEO_ID: '0500000US25017',
    STATE: '25',
    COUNTY: '017',
    NAME: 'Middlesex',
    LSAD: 'County',
    CENSUSAREA: 817.817,
    FIPS: 'MA017',
    fips: '25017',
    StateAbbr: 'MA',
    StateName: 'Massachusetts',
    lat: 42.48584344,
    lng: -71.39262102,
  },
  {
    GEO_ID: '0500000US34017',
    STATE: '34',
    COUNTY: '017',
    NAME: 'Hudson',
    LSAD: 'County',
    CENSUSAREA: 46.191,
    FIPS: 'NJ017',
    fips: '34017',
    StateAbbr: 'NJ',
    StateName: 'New Jersey',
    lat: 40.73476975,
    lng: -74.07753388,
  },
  {
    GEO_ID: '0500000US34019',
    STATE: '34',
    COUNTY: '019',
    NAME: 'Hunterdon',
    LSAD: 'County',
    CENSUSAREA: 427.819,
    FIPS: 'NJ019',
    fips: '34019',
    StateAbbr: 'NJ',
    StateName: 'New Jersey',
    lat: 40.56697742,
    lng: -74.91261058,
  },
  {
    GEO_ID: '0500000US34021',
    STATE: '34',
    COUNTY: '021',
    NAME: 'Mercer',
    LSAD: 'County',
    CENSUSAREA: 224.557,
    FIPS: 'NJ021',
    fips: '34021',
    StateAbbr: 'NJ',
    StateName: 'New Jersey',
    lat: 40.28339139,
    lng: -74.70164497,
  },
  {
    GEO_ID: '0500000US34029',
    STATE: '34',
    COUNTY: '029',
    NAME: 'Ocean',
    LSAD: 'County',
    CENSUSAREA: 628.781,
    FIPS: 'NJ029',
    fips: '34029',
    StateAbbr: 'NJ',
    StateName: 'New Jersey',
    lat: 39.88494891,
    lng: -74.28147189,
  },
  {
    GEO_ID: '0500000US34033',
    STATE: '34',
    COUNTY: '033',
    NAME: 'Salem',
    LSAD: 'County',
    CENSUSAREA: 331.899,
    FIPS: 'NJ033',
    fips: '34033',
    StateAbbr: 'NJ',
    StateName: 'New Jersey',
    lat: 39.58787069,
    lng: -75.34910669,
  },
  {
    GEO_ID: '0500000US34035',
    STATE: '34',
    COUNTY: '035',
    NAME: 'Somerset',
    LSAD: 'County',
    CENSUSAREA: 301.813,
    FIPS: 'NJ035',
    fips: '34035',
    StateAbbr: 'NJ',
    StateName: 'New Jersey',
    lat: 40.56375707,
    lng: -74.61645904,
  },
  {
    GEO_ID: '0500000US34041',
    STATE: '34',
    COUNTY: '041',
    NAME: 'Warren',
    LSAD: 'County',
    CENSUSAREA: 356.918,
    FIPS: 'NJ041',
    fips: '34041',
    StateAbbr: 'NJ',
    StateName: 'New Jersey',
    lat: 40.85746195,
    lng: -74.99647914,
  },
  {
    GEO_ID: '0500000US35007',
    STATE: '35',
    COUNTY: '007',
    NAME: 'Colfax',
    LSAD: 'County',
    CENSUSAREA: 3758.06,
    FIPS: 'NM007',
    fips: '35007',
    StateAbbr: 'NM',
    StateName: 'New Mexico',
    lat: 36.60619925,
    lng: -104.6468731,
  },
  {
    GEO_ID: '0500000US30055',
    STATE: '30',
    COUNTY: '055',
    NAME: 'McCone',
    LSAD: 'County',
    CENSUSAREA: 2643.172,
    FIPS: 'MT055',
    fips: '30055',
    StateAbbr: 'MT',
    StateName: 'Montana',
    lat: 47.64515532,
    lng: -105.7952945,
  },
  {
    GEO_ID: '0500000US30057',
    STATE: '30',
    COUNTY: '057',
    NAME: 'Madison',
    LSAD: 'County',
    CENSUSAREA: 3587.484,
    FIPS: 'MT057',
    fips: '30057',
    StateAbbr: 'MT',
    StateName: 'Montana',
    lat: 45.30105558,
    lng: -111.9213142,
  },
  {
    GEO_ID: '0500000US37195',
    STATE: '37',
    COUNTY: '195',
    NAME: 'Wilson',
    LSAD: 'County',
    CENSUSAREA: 368.174,
    FIPS: 'NC195',
    fips: '37195',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 35.70531169,
    lng: -77.9189122,
  },
  {
    GEO_ID: '0500000US37199',
    STATE: '37',
    COUNTY: '199',
    NAME: 'Yancey',
    LSAD: 'County',
    CENSUSAREA: 312.597,
    FIPS: 'NC199',
    fips: '37199',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 35.8984235,
    lng: -82.30812844,
  },
  {
    GEO_ID: '0500000US38005',
    STATE: '38',
    COUNTY: '005',
    NAME: 'Benson',
    LSAD: 'County',
    CENSUSAREA: 1388.708,
    FIPS: 'ND005',
    fips: '38005',
    StateAbbr: 'ND',
    StateName: 'North Dakota',
    lat: 48.06933808,
    lng: -99.36573478,
  },
  {
    GEO_ID: '0500000US38009',
    STATE: '38',
    COUNTY: '009',
    NAME: 'Bottineau',
    LSAD: 'County',
    CENSUSAREA: 1668.421,
    FIPS: 'ND009',
    fips: '38009',
    StateAbbr: 'ND',
    StateName: 'North Dakota',
    lat: 48.792102,
    lng: -100.8333086,
  },
  {
    GEO_ID: '0500000US38017',
    STATE: '38',
    COUNTY: '017',
    NAME: 'Cass',
    LSAD: 'County',
    CENSUSAREA: 1764.937,
    FIPS: 'ND017',
    fips: '38017',
    StateAbbr: 'ND',
    StateName: 'North Dakota',
    lat: 46.93292237,
    lng: -97.24822334,
  },
  {
    GEO_ID: '0500000US38027',
    STATE: '38',
    COUNTY: '027',
    NAME: 'Eddy',
    LSAD: 'County',
    CENSUSAREA: 630.171,
    FIPS: 'ND027',
    fips: '38027',
    StateAbbr: 'ND',
    StateName: 'North Dakota',
    lat: 47.71748723,
    lng: -98.90182925,
  },
  {
    GEO_ID: '0500000US38029',
    STATE: '38',
    COUNTY: '029',
    NAME: 'Emmons',
    LSAD: 'County',
    CENSUSAREA: 1510.435,
    FIPS: 'ND029',
    fips: '38029',
    StateAbbr: 'ND',
    StateName: 'North Dakota',
    lat: 46.28495441,
    lng: -100.2386194,
  },
  {
    GEO_ID: '0500000US38037',
    STATE: '38',
    COUNTY: '037',
    NAME: 'Grant',
    LSAD: 'County',
    CENSUSAREA: 1659.139,
    FIPS: 'ND037',
    fips: '38037',
    StateAbbr: 'ND',
    StateName: 'North Dakota',
    lat: 46.35813751,
    lng: -101.6397779,
  },
  {
    GEO_ID: '0500000US38039',
    STATE: '38',
    COUNTY: '039',
    NAME: 'Griggs',
    LSAD: 'County',
    CENSUSAREA: 708.815,
    FIPS: 'ND039',
    fips: '38039',
    StateAbbr: 'ND',
    StateName: 'North Dakota',
    lat: 47.4570984,
    lng: -98.23703207,
  },
  {
    GEO_ID: '0500000US38043',
    STATE: '38',
    COUNTY: '043',
    NAME: 'Kidder',
    LSAD: 'County',
    CENSUSAREA: 1351.189,
    FIPS: 'ND043',
    fips: '38043',
    StateAbbr: 'ND',
    StateName: 'North Dakota',
    lat: 46.98013909,
    lng: -99.78013842,
  },
  {
    GEO_ID: '0500000US38047',
    STATE: '38',
    COUNTY: '047',
    NAME: 'Logan',
    LSAD: 'County',
    CENSUSAREA: 992.818,
    FIPS: 'ND047',
    fips: '38047',
    StateAbbr: 'ND',
    StateName: 'North Dakota',
    lat: 46.45733694,
    lng: -99.47712748,
  },
  {
    GEO_ID: '0500000US38053',
    STATE: '38',
    COUNTY: '053',
    NAME: 'McKenzie',
    LSAD: 'County',
    CENSUSAREA: 2760.323,
    FIPS: 'ND053',
    fips: '38053',
    StateAbbr: 'ND',
    StateName: 'North Dakota',
    lat: 47.74027674,
    lng: -103.3954265,
  },
  {
    GEO_ID: '0500000US38059',
    STATE: '38',
    COUNTY: '059',
    NAME: 'Morton',
    LSAD: 'County',
    CENSUSAREA: 1926.27,
    FIPS: 'ND059',
    fips: '38059',
    StateAbbr: 'ND',
    StateName: 'North Dakota',
    lat: 46.71620349,
    lng: -101.2808428,
  },
  {
    GEO_ID: '0500000US38063',
    STATE: '38',
    COUNTY: '063',
    NAME: 'Nelson',
    LSAD: 'County',
    CENSUSAREA: 981.775,
    FIPS: 'ND063',
    fips: '38063',
    StateAbbr: 'ND',
    StateName: 'North Dakota',
    lat: 47.92171706,
    lng: -98.19212605,
  },
  {
    GEO_ID: '0500000US47001',
    STATE: '47',
    COUNTY: '001',
    NAME: 'Anderson',
    LSAD: 'County',
    CENSUSAREA: 337.162,
    FIPS: 'TN001',
    fips: '47001',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 36.11862903,
    lng: -84.19904346,
  },
  {
    GEO_ID: '0500000US47003',
    STATE: '47',
    COUNTY: '003',
    NAME: 'Bedford',
    LSAD: 'County',
    CENSUSAREA: 473.635,
    FIPS: 'TN003',
    fips: '47003',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 35.51388628,
    lng: -86.4596155,
  },
  {
    GEO_ID: '0500000US40065',
    STATE: '40',
    COUNTY: '065',
    NAME: 'Jackson',
    LSAD: 'County',
    CENSUSAREA: 802.651,
    FIPS: 'OK065',
    fips: '40065',
    StateAbbr: 'OK',
    StateName: 'Oklahoma',
    lat: 34.58800041,
    lng: -99.41545292,
  },
  {
    GEO_ID: '0500000US40071',
    STATE: '40',
    COUNTY: '071',
    NAME: 'Kay',
    LSAD: 'County',
    CENSUSAREA: 919.729,
    FIPS: 'OK071',
    fips: '40071',
    StateAbbr: 'OK',
    StateName: 'Oklahoma',
    lat: 36.81795089,
    lng: -97.1438723,
  },
  {
    GEO_ID: '0500000US40083',
    STATE: '40',
    COUNTY: '083',
    NAME: 'Logan',
    LSAD: 'County',
    CENSUSAREA: 743.834,
    FIPS: 'OK083',
    fips: '40083',
    StateAbbr: 'OK',
    StateName: 'Oklahoma',
    lat: 35.91913897,
    lng: -97.44308101,
  },
  {
    GEO_ID: '0500000US40085',
    STATE: '40',
    COUNTY: '085',
    NAME: 'Love',
    LSAD: 'County',
    CENSUSAREA: 513.995,
    FIPS: 'OK085',
    fips: '40085',
    StateAbbr: 'OK',
    StateName: 'Oklahoma',
    lat: 33.95005271,
    lng: -97.24422829,
  },
  {
    GEO_ID: '0500000US40091',
    STATE: '40',
    COUNTY: '091',
    NAME: 'McIntosh',
    LSAD: 'County',
    CENSUSAREA: 618.496,
    FIPS: 'OK091',
    fips: '40091',
    StateAbbr: 'OK',
    StateName: 'Oklahoma',
    lat: 35.37351598,
    lng: -95.66695805,
  },
  {
    GEO_ID: '0500000US40099',
    STATE: '40',
    COUNTY: '099',
    NAME: 'Murray',
    LSAD: 'County',
    CENSUSAREA: 416.458,
    FIPS: 'OK099',
    fips: '40099',
    StateAbbr: 'OK',
    StateName: 'Oklahoma',
    lat: 34.48236468,
    lng: -97.06798798,
  },
  {
    GEO_ID: '0500000US40101',
    STATE: '40',
    COUNTY: '101',
    NAME: 'Muskogee',
    LSAD: 'County',
    CENSUSAREA: 810.45,
    FIPS: 'OK101',
    fips: '40101',
    StateAbbr: 'OK',
    StateName: 'Oklahoma',
    lat: 35.61612948,
    lng: -95.37949187,
  },
  {
    GEO_ID: '0500000US40107',
    STATE: '40',
    COUNTY: '107',
    NAME: 'Okfuskee',
    LSAD: 'County',
    CENSUSAREA: 618.567,
    FIPS: 'OK107',
    fips: '40107',
    StateAbbr: 'OK',
    StateName: 'Oklahoma',
    lat: 35.46544123,
    lng: -96.3228392,
  },
  {
    GEO_ID: '0500000US40111',
    STATE: '40',
    COUNTY: '111',
    NAME: 'Okmulgee',
    LSAD: 'County',
    CENSUSAREA: 697.349,
    FIPS: 'OK111',
    fips: '40111',
    StateAbbr: 'OK',
    StateName: 'Oklahoma',
    lat: 35.64662667,
    lng: -95.96434123,
  },
  {
    GEO_ID: '0500000US40117',
    STATE: '40',
    COUNTY: '117',
    NAME: 'Pawnee',
    LSAD: 'County',
    CENSUSAREA: 567.955,
    FIPS: 'OK117',
    fips: '40117',
    StateAbbr: 'OK',
    StateName: 'Oklahoma',
    lat: 36.31680135,
    lng: -96.69954973,
  },
  {
    GEO_ID: '0500000US40119',
    STATE: '40',
    COUNTY: '119',
    NAME: 'Payne',
    LSAD: 'County',
    CENSUSAREA: 684.703,
    FIPS: 'OK119',
    fips: '40119',
    StateAbbr: 'OK',
    StateName: 'Oklahoma',
    lat: 36.07716359,
    lng: -96.97542338,
  },
  {
    GEO_ID: '0500000US40125',
    STATE: '40',
    COUNTY: '125',
    NAME: 'Pottawatomie',
    LSAD: 'County',
    CENSUSAREA: 787.672,
    FIPS: 'OK125',
    fips: '40125',
    StateAbbr: 'OK',
    StateName: 'Oklahoma',
    lat: 35.20684527,
    lng: -96.94828061,
  },
  {
    GEO_ID: '0500000US25021',
    STATE: '25',
    COUNTY: '021',
    NAME: 'Norfolk',
    LSAD: 'County',
    CENSUSAREA: 396.105,
    FIPS: 'MA021',
    fips: '25021',
    StateAbbr: 'MA',
    StateName: 'Massachusetts',
    lat: 42.1624614,
    lng: -71.20690516,
  },
  {
    GEO_ID: '0500000US25023',
    STATE: '25',
    COUNTY: '023',
    NAME: 'Plymouth',
    LSAD: 'County',
    CENSUSAREA: 659.075,
    FIPS: 'MA023',
    fips: '25023',
    StateAbbr: 'MA',
    StateName: 'Massachusetts',
    lat: 41.95331777,
    lng: -70.81296132,
  },
  {
    GEO_ID: '0500000US26003',
    STATE: '26',
    COUNTY: '003',
    NAME: 'Alger',
    LSAD: 'County',
    CENSUSAREA: 915.07,
    FIPS: 'MI003',
    fips: '26003',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 46.40902031,
    lng: -86.60408109,
  },
  {
    GEO_ID: '0500000US26005',
    STATE: '26',
    COUNTY: '005',
    NAME: 'Allegan',
    LSAD: 'County',
    CENSUSAREA: 825.231,
    FIPS: 'MI005',
    fips: '26005',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 42.59147142,
    lng: -85.88845897,
  },
  {
    GEO_ID: '0500000US26007',
    STATE: '26',
    COUNTY: '007',
    NAME: 'Alpena',
    LSAD: 'County',
    CENSUSAREA: 571.86,
    FIPS: 'MI007',
    fips: '26007',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 45.03457851,
    lng: -83.62606894,
  },
  {
    GEO_ID: '0500000US30063',
    STATE: '30',
    COUNTY: '063',
    NAME: 'Missoula',
    LSAD: 'County',
    CENSUSAREA: 2593.424,
    FIPS: 'MT063',
    fips: '30063',
    StateAbbr: 'MT',
    StateName: 'Montana',
    lat: 47.03651185,
    lng: -113.9237814,
  },
  {
    GEO_ID: '0500000US30065',
    STATE: '30',
    COUNTY: '065',
    NAME: 'Musselshell',
    LSAD: 'County',
    CENSUSAREA: 1868.155,
    FIPS: 'MT065',
    fips: '30065',
    StateAbbr: 'MT',
    StateName: 'Montana',
    lat: 46.49659376,
    lng: -108.3975013,
  },
  {
    GEO_ID: '0500000US30071',
    STATE: '30',
    COUNTY: '071',
    NAME: 'Phillips',
    LSAD: 'County',
    CENSUSAREA: 5140.035,
    FIPS: 'MT071',
    fips: '30071',
    StateAbbr: 'MT',
    StateName: 'Montana',
    lat: 48.25942665,
    lng: -107.9138518,
  },
  {
    GEO_ID: '0500000US30077',
    STATE: '30',
    COUNTY: '077',
    NAME: 'Powell',
    LSAD: 'County',
    CENSUSAREA: 2326.392,
    FIPS: 'MT077',
    fips: '30077',
    StateAbbr: 'MT',
    StateName: 'Montana',
    lat: 46.85654866,
    lng: -112.936294,
  },
  {
    GEO_ID: '0500000US30083',
    STATE: '30',
    COUNTY: '083',
    NAME: 'Richland',
    LSAD: 'County',
    CENSUSAREA: 2084.143,
    FIPS: 'MT083',
    fips: '30083',
    StateAbbr: 'MT',
    StateName: 'Montana',
    lat: 47.78794828,
    lng: -104.5616375,
  },
  {
    GEO_ID: '0500000US30087',
    STATE: '30',
    COUNTY: '087',
    NAME: 'Rosebud',
    LSAD: 'County',
    CENSUSAREA: 5010.402,
    FIPS: 'MT087',
    fips: '30087',
    StateAbbr: 'MT',
    StateName: 'Montana',
    lat: 46.22979285,
    lng: -106.7307531,
  },
  {
    GEO_ID: '0500000US30091',
    STATE: '30',
    COUNTY: '091',
    NAME: 'Sheridan',
    LSAD: 'County',
    CENSUSAREA: 1677.08,
    FIPS: 'MT091',
    fips: '30091',
    StateAbbr: 'MT',
    StateName: 'Montana',
    lat: 48.72121163,
    lng: -104.5046932,
  },
  {
    GEO_ID: '0500000US30097',
    STATE: '30',
    COUNTY: '097',
    NAME: 'Sweet Grass',
    LSAD: 'County',
    CENSUSAREA: 1855.205,
    FIPS: 'MT097',
    fips: '30097',
    StateAbbr: 'MT',
    StateName: 'Montana',
    lat: 45.813756,
    lng: -109.939664,
  },
  {
    GEO_ID: '0500000US30101',
    STATE: '30',
    COUNTY: '101',
    NAME: 'Toole',
    LSAD: 'County',
    CENSUSAREA: 1915.647,
    FIPS: 'MT101',
    fips: '30101',
    StateAbbr: 'MT',
    StateName: 'Montana',
    lat: 48.65534485,
    lng: -111.6959501,
  },
  {
    GEO_ID: '0500000US38065',
    STATE: '38',
    COUNTY: '065',
    NAME: 'Oliver',
    LSAD: 'County',
    CENSUSAREA: 722.511,
    FIPS: 'ND065',
    fips: '38065',
    StateAbbr: 'ND',
    StateName: 'North Dakota',
    lat: 47.115508,
    lng: -101.3403504,
  },
  {
    GEO_ID: '0500000US38067',
    STATE: '38',
    COUNTY: '067',
    NAME: 'Pembina',
    LSAD: 'County',
    CENSUSAREA: 1118.694,
    FIPS: 'ND067',
    fips: '38067',
    StateAbbr: 'ND',
    StateName: 'North Dakota',
    lat: 48.76726978,
    lng: -97.55156514,
  },
  {
    GEO_ID: '0500000US38071',
    STATE: '38',
    COUNTY: '071',
    NAME: 'Ramsey',
    LSAD: 'County',
    CENSUSAREA: 1186.854,
    FIPS: 'ND071',
    fips: '38071',
    StateAbbr: 'ND',
    StateName: 'North Dakota',
    lat: 48.2690641,
    lng: -98.72015658,
  },
  {
    GEO_ID: '0500000US38075',
    STATE: '38',
    COUNTY: '075',
    NAME: 'Renville',
    LSAD: 'County',
    CENSUSAREA: 877.053,
    FIPS: 'ND075',
    fips: '38075',
    StateAbbr: 'ND',
    StateName: 'North Dakota',
    lat: 48.71910481,
    lng: -101.6577138,
  },
  {
    GEO_ID: '0500000US38081',
    STATE: '38',
    COUNTY: '081',
    NAME: 'Sargent',
    LSAD: 'County',
    CENSUSAREA: 858.513,
    FIPS: 'ND081',
    fips: '38081',
    StateAbbr: 'ND',
    StateName: 'North Dakota',
    lat: 46.1078458,
    lng: -97.63059676,
  },
  {
    GEO_ID: '0500000US38089',
    STATE: '38',
    COUNTY: '089',
    NAME: 'Stark',
    LSAD: 'County',
    CENSUSAREA: 1334.735,
    FIPS: 'ND089',
    fips: '38089',
    StateAbbr: 'ND',
    StateName: 'North Dakota',
    lat: 46.81078851,
    lng: -102.6550438,
  },
  {
    GEO_ID: '0500000US38093',
    STATE: '38',
    COUNTY: '093',
    NAME: 'Stutsman',
    LSAD: 'County',
    CENSUSAREA: 2221.724,
    FIPS: 'ND093',
    fips: '38093',
    StateAbbr: 'ND',
    StateName: 'North Dakota',
    lat: 46.97927215,
    lng: -98.95894925,
  },
  {
    GEO_ID: '0500000US38097',
    STATE: '38',
    COUNTY: '097',
    NAME: 'Traill',
    LSAD: 'County',
    CENSUSAREA: 861.949,
    FIPS: 'ND097',
    fips: '38097',
    StateAbbr: 'ND',
    StateName: 'North Dakota',
    lat: 47.45400419,
    lng: -97.16169937,
  },
  {
    GEO_ID: '0500000US38101',
    STATE: '38',
    COUNTY: '101',
    NAME: 'Ward',
    LSAD: 'County',
    CENSUSAREA: 2013.279,
    FIPS: 'ND101',
    fips: '38101',
    StateAbbr: 'ND',
    StateName: 'North Dakota',
    lat: 48.22179884,
    lng: -101.541865,
  },
  {
    GEO_ID: '0500000US39001',
    STATE: '39',
    COUNTY: '001',
    NAME: 'Adams',
    LSAD: 'County',
    CENSUSAREA: 583.867,
    FIPS: 'OH001',
    fips: '39001',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 38.84544021,
    lng: -83.47239908,
  },
  {
    GEO_ID: '0500000US39005',
    STATE: '39',
    COUNTY: '005',
    NAME: 'Ashland',
    LSAD: 'County',
    CENSUSAREA: 422.95,
    FIPS: 'OH005',
    fips: '39005',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 40.84592901,
    lng: -82.27061922,
  },
  {
    GEO_ID: '0500000US39011',
    STATE: '39',
    COUNTY: '011',
    NAME: 'Auglaize',
    LSAD: 'County',
    CENSUSAREA: 401.386,
    FIPS: 'OH011',
    fips: '39011',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 40.56085701,
    lng: -84.22168058,
  },
  {
    GEO_ID: '0500000US39013',
    STATE: '39',
    COUNTY: '013',
    NAME: 'Belmont',
    LSAD: 'County',
    CENSUSAREA: 532.129,
    FIPS: 'OH013',
    fips: '39013',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 40.01576203,
    lng: -80.98861998,
  },
  {
    GEO_ID: '0500000US39017',
    STATE: '39',
    COUNTY: '017',
    NAME: 'Butler',
    LSAD: 'County',
    CENSUSAREA: 467.056,
    FIPS: 'OH017',
    fips: '39017',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 39.43897921,
    lng: -84.57607723,
  },
  {
    GEO_ID: '0500000US39019',
    STATE: '39',
    COUNTY: '019',
    NAME: 'Carroll',
    LSAD: 'County',
    CENSUSAREA: 394.609,
    FIPS: 'OH019',
    fips: '39019',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 40.579572,
    lng: -81.08973346,
  },
  {
    GEO_ID: '0500000US42003',
    STATE: '42',
    COUNTY: '003',
    NAME: 'Allegheny',
    LSAD: 'County',
    CENSUSAREA: 730.075,
    FIPS: 'PA003',
    fips: '42003',
    StateAbbr: 'PA',
    StateName: 'Pennsylvania',
    lat: 40.46880438,
    lng: -79.9817588,
  },
  {
    GEO_ID: '0500000US42007',
    STATE: '42',
    COUNTY: '007',
    NAME: 'Beaver',
    LSAD: 'County',
    CENSUSAREA: 434.712,
    FIPS: 'PA007',
    fips: '42007',
    StateAbbr: 'PA',
    StateName: 'Pennsylvania',
    lat: 40.68241496,
    lng: -80.34928676,
  },
  {
    GEO_ID: '0500000US48361',
    STATE: '48',
    COUNTY: '361',
    NAME: 'Orange',
    LSAD: 'County',
    CENSUSAREA: 333.669,
    FIPS: 'TX361',
    fips: '48361',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 30.12099964,
    lng: -93.89437408,
  },
  {
    GEO_ID: '0500000US48369',
    STATE: '48',
    COUNTY: '369',
    NAME: 'Parmer',
    LSAD: 'County',
    CENSUSAREA: 880.778,
    FIPS: 'TX369',
    fips: '48369',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 34.52993922,
    lng: -102.7844494,
  },
  {
    GEO_ID: '0500000US48383',
    STATE: '48',
    COUNTY: '383',
    NAME: 'Reagan',
    LSAD: 'County',
    CENSUSAREA: 1175.301,
    FIPS: 'TX383',
    fips: '48383',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 31.36607041,
    lng: -101.5232659,
  },
  {
    GEO_ID: '0500000US48385',
    STATE: '48',
    COUNTY: '385',
    NAME: 'Real',
    LSAD: 'County',
    CENSUSAREA: 699.195,
    FIPS: 'TX385',
    fips: '48385',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 29.8324893,
    lng: -99.82222259,
  },
  {
    GEO_ID: '0500000US48387',
    STATE: '48',
    COUNTY: '387',
    NAME: 'Red River',
    LSAD: 'County',
    CENSUSAREA: 1036.578,
    FIPS: 'TX387',
    fips: '48387',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 33.62099176,
    lng: -95.04966416,
  },
  {
    GEO_ID: '0500000US48395',
    STATE: '48',
    COUNTY: '395',
    NAME: 'Robertson',
    LSAD: 'County',
    CENSUSAREA: 855.683,
    FIPS: 'TX395',
    fips: '48395',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 31.02665136,
    lng: -96.51322902,
  },
  {
    GEO_ID: '0500000US48399',
    STATE: '48',
    COUNTY: '399',
    NAME: 'Runnels',
    LSAD: 'County',
    CENSUSAREA: 1050.945,
    FIPS: 'TX399',
    fips: '48399',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 31.8314342,
    lng: -99.97616148,
  },
  {
    GEO_ID: '0500000US48403',
    STATE: '48',
    COUNTY: '403',
    NAME: 'Sabine',
    LSAD: 'County',
    CENSUSAREA: 491.39,
    FIPS: 'TX403',
    fips: '48403',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 31.34269549,
    lng: -93.85288661,
  },
  {
    GEO_ID: '0500000US48415',
    STATE: '48',
    COUNTY: '415',
    NAME: 'Scurry',
    LSAD: 'County',
    CENSUSAREA: 905.444,
    FIPS: 'TX415',
    fips: '48415',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 32.74614567,
    lng: -100.9162656,
  },
  {
    GEO_ID: '0500000US48417',
    STATE: '48',
    COUNTY: '417',
    NAME: 'Shackelford',
    LSAD: 'County',
    CENSUSAREA: 914.286,
    FIPS: 'TX417',
    fips: '48417',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 32.73587756,
    lng: -99.35401337,
  },
  {
    GEO_ID: '0500000US48419',
    STATE: '48',
    COUNTY: '419',
    NAME: 'Shelby',
    LSAD: 'County',
    CENSUSAREA: 795.583,
    FIPS: 'TX419',
    fips: '48419',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 31.79223785,
    lng: -94.14557404,
  },
  {
    GEO_ID: '0500000US48423',
    STATE: '48',
    COUNTY: '423',
    NAME: 'Smith',
    LSAD: 'County',
    CENSUSAREA: 921.454,
    FIPS: 'TX423',
    fips: '48423',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 32.37521162,
    lng: -95.26908279,
  },
  {
    GEO_ID: '0500000US48427',
    STATE: '48',
    COUNTY: '427',
    NAME: 'Starr',
    LSAD: 'County',
    CENSUSAREA: 1223.179,
    FIPS: 'TX427',
    fips: '48427',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 26.56223662,
    lng: -98.73826312,
  },
  {
    GEO_ID: '0500000US48431',
    STATE: '48',
    COUNTY: '431',
    NAME: 'Sterling',
    LSAD: 'County',
    CENSUSAREA: 923.451,
    FIPS: 'TX431',
    fips: '48431',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 31.82781912,
    lng: -101.0500746,
  },
  {
    GEO_ID: '0500000US26017',
    STATE: '26',
    COUNTY: '017',
    NAME: 'Bay',
    LSAD: 'County',
    CENSUSAREA: 442.302,
    FIPS: 'MI017',
    fips: '26017',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 43.70716549,
    lng: -83.99005144,
  },
  {
    GEO_ID: '0500000US26019',
    STATE: '26',
    COUNTY: '019',
    NAME: 'Benzie',
    LSAD: 'County',
    CENSUSAREA: 319.704,
    FIPS: 'MI019',
    fips: '26019',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 44.63910932,
    lng: -86.01505847,
  },
  {
    GEO_ID: '0500000US26021',
    STATE: '26',
    COUNTY: '021',
    NAME: 'Berrien',
    LSAD: 'County',
    CENSUSAREA: 567.747,
    FIPS: 'MI021',
    fips: '26021',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 41.95468812,
    lng: -86.41252941,
  },
  {
    GEO_ID: '0500000US26027',
    STATE: '26',
    COUNTY: '027',
    NAME: 'Cass',
    LSAD: 'County',
    CENSUSAREA: 490.062,
    FIPS: 'MI027',
    fips: '26027',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 41.91545547,
    lng: -85.99403741,
  },
  {
    GEO_ID: '0500000US26031',
    STATE: '26',
    COUNTY: '031',
    NAME: 'Cheboygan',
    LSAD: 'County',
    CENSUSAREA: 715.264,
    FIPS: 'MI031',
    fips: '26031',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 45.44701265,
    lng: -84.50065611,
  },
  {
    GEO_ID: '0500000US26033',
    STATE: '26',
    COUNTY: '033',
    NAME: 'Chippewa',
    LSAD: 'County',
    CENSUSAREA: 1558.42,
    FIPS: 'MI033',
    fips: '26033',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 46.30032406,
    lng: -84.56284345,
  },
  {
    GEO_ID: '0500000US30109',
    STATE: '30',
    COUNTY: '109',
    NAME: 'Wibaux',
    LSAD: 'County',
    CENSUSAREA: 889.265,
    FIPS: 'MT109',
    fips: '30109',
    StateAbbr: 'MT',
    StateName: 'Montana',
    lat: 46.96556514,
    lng: -104.2490289,
  },
  {
    GEO_ID: '0500000US31007',
    STATE: '31',
    COUNTY: '007',
    NAME: 'Banner',
    LSAD: 'County',
    CENSUSAREA: 746.114,
    FIPS: 'NE007',
    fips: '31007',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 41.54616353,
    lng: -103.7109624,
  },
  {
    GEO_ID: '0500000US31011',
    STATE: '31',
    COUNTY: '011',
    NAME: 'Boone',
    LSAD: 'County',
    CENSUSAREA: 686.554,
    FIPS: 'NE011',
    fips: '31011',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 41.7067951,
    lng: -98.06703102,
  },
  {
    GEO_ID: '0500000US31013',
    STATE: '31',
    COUNTY: '013',
    NAME: 'Box Butte',
    LSAD: 'County',
    CENSUSAREA: 1075.293,
    FIPS: 'NE013',
    fips: '31013',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 42.22084585,
    lng: -103.0860173,
  },
  {
    GEO_ID: '0500000US31019',
    STATE: '31',
    COUNTY: '019',
    NAME: 'Buffalo',
    LSAD: 'County',
    CENSUSAREA: 968.112,
    FIPS: 'NE019',
    fips: '31019',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 40.85504881,
    lng: -99.07510068,
  },
  {
    GEO_ID: '0500000US31025',
    STATE: '31',
    COUNTY: '025',
    NAME: 'Cass',
    LSAD: 'County',
    CENSUSAREA: 557.446,
    FIPS: 'NE025',
    fips: '31025',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 40.90976087,
    lng: -96.14069252,
  },
  {
    GEO_ID: '0500000US31029',
    STATE: '31',
    COUNTY: '029',
    NAME: 'Chase',
    LSAD: 'County',
    CENSUSAREA: 894.419,
    FIPS: 'NE029',
    fips: '31029',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 40.52371008,
    lng: -101.6979407,
  },
  {
    GEO_ID: '0500000US31033',
    STATE: '31',
    COUNTY: '033',
    NAME: 'Cheyenne',
    LSAD: 'County',
    CENSUSAREA: 1196.287,
    FIPS: 'NE033',
    fips: '31033',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 41.21970871,
    lng: -102.9949509,
  },
  {
    GEO_ID: '0500000US31039',
    STATE: '31',
    COUNTY: '039',
    NAME: 'Cuming',
    LSAD: 'County',
    CENSUSAREA: 570.619,
    FIPS: 'NE039',
    fips: '31039',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 41.91637624,
    lng: -96.78730772,
  },
  {
    GEO_ID: '0500000US31045',
    STATE: '31',
    COUNTY: '045',
    NAME: 'Dawes',
    LSAD: 'County',
    CENSUSAREA: 1396.464,
    FIPS: 'NE045',
    fips: '31045',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 42.72007965,
    lng: -103.1354423,
  },
  {
    GEO_ID: '0500000US31051',
    STATE: '31',
    COUNTY: '051',
    NAME: 'Dixon',
    LSAD: 'County',
    CENSUSAREA: 476.232,
    FIPS: 'NE051',
    fips: '31051',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 42.49349461,
    lng: -96.86826909,
  },
  {
    GEO_ID: '0500000US31057',
    STATE: '31',
    COUNTY: '057',
    NAME: 'Dundy',
    LSAD: 'County',
    CENSUSAREA: 919.678,
    FIPS: 'NE057',
    fips: '31057',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 40.17625316,
    lng: -101.6878043,
  },
  {
    GEO_ID: '0500000US31071',
    STATE: '31',
    COUNTY: '071',
    NAME: 'Garfield',
    LSAD: 'County',
    CENSUSAREA: 569.79,
    FIPS: 'NE071',
    fips: '31071',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 41.91428955,
    lng: -98.99111827,
  },
  {
    GEO_ID: '0500000US31075',
    STATE: '31',
    COUNTY: '075',
    NAME: 'Grant',
    LSAD: 'County',
    CENSUSAREA: 776.224,
    FIPS: 'NE075',
    fips: '31075',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 41.91528587,
    lng: -101.740701,
  },
  {
    GEO_ID: '0500000US39023',
    STATE: '39',
    COUNTY: '023',
    NAME: 'Clark',
    LSAD: 'County',
    CENSUSAREA: 397.473,
    FIPS: 'OH023',
    fips: '39023',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 39.91660044,
    lng: -83.78354054,
  },
  {
    GEO_ID: '0500000US39025',
    STATE: '39',
    COUNTY: '025',
    NAME: 'Clermont',
    LSAD: 'County',
    CENSUSAREA: 452.1,
    FIPS: 'OH025',
    fips: '39025',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 39.04742036,
    lng: -84.15193439,
  },
  {
    GEO_ID: '0500000US39029',
    STATE: '39',
    COUNTY: '029',
    NAME: 'Columbiana',
    LSAD: 'County',
    CENSUSAREA: 531.893,
    FIPS: 'OH029',
    fips: '39029',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 40.76853311,
    lng: -80.77709087,
  },
  {
    GEO_ID: '0500000US39033',
    STATE: '39',
    COUNTY: '033',
    NAME: 'Crawford',
    LSAD: 'County',
    CENSUSAREA: 401.786,
    FIPS: 'OH033',
    fips: '39033',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 40.85061678,
    lng: -82.91997484,
  },
  {
    GEO_ID: '0500000US39037',
    STATE: '39',
    COUNTY: '037',
    NAME: 'Darke',
    LSAD: 'County',
    CENSUSAREA: 598.1,
    FIPS: 'OH037',
    fips: '39037',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 40.13381861,
    lng: -84.61945793,
  },
  {
    GEO_ID: '0500000US39041',
    STATE: '39',
    COUNTY: '041',
    NAME: 'Delaware',
    LSAD: 'County',
    CENSUSAREA: 443.098,
    FIPS: 'OH041',
    fips: '39041',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 40.27841348,
    lng: -83.00450275,
  },
  {
    GEO_ID: '0500000US39043',
    STATE: '39',
    COUNTY: '043',
    NAME: 'Erie',
    LSAD: 'County',
    CENSUSAREA: 251.558,
    FIPS: 'OH043',
    fips: '39043',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 41.37421463,
    lng: -82.63799692,
  },
  {
    GEO_ID: '0500000US39049',
    STATE: '39',
    COUNTY: '049',
    NAME: 'Franklin',
    LSAD: 'County',
    CENSUSAREA: 532.188,
    FIPS: 'OH049',
    fips: '39049',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 39.96927249,
    lng: -83.0091231,
  },
  {
    GEO_ID: '0500000US35019',
    STATE: '35',
    COUNTY: '019',
    NAME: 'Guadalupe',
    LSAD: 'County',
    CENSUSAREA: 3030.482,
    FIPS: 'NM019',
    fips: '35019',
    StateAbbr: 'NM',
    StateName: 'New Mexico',
    lat: 34.86315461,
    lng: -104.7904138,
  },
  {
    GEO_ID: '0500000US35021',
    STATE: '35',
    COUNTY: '021',
    NAME: 'Harding',
    LSAD: 'County',
    CENSUSAREA: 2125.442,
    FIPS: 'NM021',
    fips: '35021',
    StateAbbr: 'NM',
    StateName: 'New Mexico',
    lat: 35.85799759,
    lng: -103.820322,
  },
  {
    GEO_ID: '0500000US35025',
    STATE: '35',
    COUNTY: '025',
    NAME: 'Lea',
    LSAD: 'County',
    CENSUSAREA: 4390.932,
    FIPS: 'NM025',
    fips: '35025',
    StateAbbr: 'NM',
    StateName: 'New Mexico',
    lat: 32.79215735,
    lng: -103.4124539,
  },
  {
    GEO_ID: '0500000US35033',
    STATE: '35',
    COUNTY: '033',
    NAME: 'Mora',
    LSAD: 'County',
    CENSUSAREA: 1931.274,
    FIPS: 'NM033',
    fips: '35033',
    StateAbbr: 'NM',
    StateName: 'New Mexico',
    lat: 36.00972846,
    lng: -104.944561,
  },
  {
    GEO_ID: '0500000US35039',
    STATE: '35',
    COUNTY: '039',
    NAME: 'Rio Arriba',
    LSAD: 'County',
    CENSUSAREA: 5860.837,
    FIPS: 'NM039',
    fips: '35039',
    StateAbbr: 'NM',
    StateName: 'New Mexico',
    lat: 36.50984697,
    lng: -106.6929637,
  },
  {
    GEO_ID: '0500000US47143',
    STATE: '47',
    COUNTY: '143',
    NAME: 'Rhea',
    LSAD: 'County',
    CENSUSAREA: 315.377,
    FIPS: 'TN143',
    fips: '47143',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 35.60911854,
    lng: -84.92418399,
  },
  {
    GEO_ID: '0500000US47147',
    STATE: '47',
    COUNTY: '147',
    NAME: 'Robertson',
    LSAD: 'County',
    CENSUSAREA: 476.287,
    FIPS: 'TN147',
    fips: '47147',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 36.52536378,
    lng: -86.87047841,
  },
  {
    GEO_ID: '0500000US47149',
    STATE: '47',
    COUNTY: '149',
    NAME: 'Rutherford',
    LSAD: 'County',
    CENSUSAREA: 619.364,
    FIPS: 'TN149',
    fips: '47149',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 35.84260355,
    lng: -86.41692683,
  },
  {
    GEO_ID: '0500000US47155',
    STATE: '47',
    COUNTY: '155',
    NAME: 'Sevier',
    LSAD: 'County',
    CENSUSAREA: 592.5,
    FIPS: 'TN155',
    fips: '47155',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 35.78475751,
    lng: -83.52376973,
  },
  {
    GEO_ID: '0500000US47161',
    STATE: '47',
    COUNTY: '161',
    NAME: 'Stewart',
    LSAD: 'County',
    CENSUSAREA: 459.33,
    FIPS: 'TN161',
    fips: '47161',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 36.50150068,
    lng: -87.83755715,
  },
  {
    GEO_ID: '0500000US47167',
    STATE: '47',
    COUNTY: '167',
    NAME: 'Tipton',
    LSAD: 'County',
    CENSUSAREA: 458.366,
    FIPS: 'TN167',
    fips: '47167',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 35.49692909,
    lng: -89.75925274,
  },
  {
    GEO_ID: '0500000US47175',
    STATE: '47',
    COUNTY: '175',
    NAME: 'Van Buren',
    LSAD: 'County',
    CENSUSAREA: 273.415,
    FIPS: 'TN175',
    fips: '47175',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 35.6961661,
    lng: -85.45359622,
  },
  {
    GEO_ID: '0500000US47177',
    STATE: '47',
    COUNTY: '177',
    NAME: 'Warren',
    LSAD: 'County',
    CENSUSAREA: 432.68,
    FIPS: 'TN177',
    fips: '47177',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 35.67900378,
    lng: -85.77914118,
  },
  {
    GEO_ID: '0500000US45055',
    STATE: '45',
    COUNTY: '055',
    NAME: 'Kershaw',
    LSAD: 'County',
    CENSUSAREA: 726.562,
    FIPS: 'SC055',
    fips: '45055',
    StateAbbr: 'SC',
    StateName: 'South Carolina',
    lat: 34.33859234,
    lng: -80.5903514,
  },
  {
    GEO_ID: '0500000US45061',
    STATE: '45',
    COUNTY: '061',
    NAME: 'Lee',
    LSAD: 'County',
    CENSUSAREA: 410.184,
    FIPS: 'SC061',
    fips: '45061',
    StateAbbr: 'SC',
    StateName: 'South Carolina',
    lat: 34.16360573,
    lng: -80.25465278,
  },
  {
    GEO_ID: '0500000US45065',
    STATE: '45',
    COUNTY: '065',
    NAME: 'McCormick',
    LSAD: 'County',
    CENSUSAREA: 359.13,
    FIPS: 'SC065',
    fips: '45065',
    StateAbbr: 'SC',
    StateName: 'South Carolina',
    lat: 33.89941841,
    lng: -82.30961363,
  },
  {
    GEO_ID: '0500000US45071',
    STATE: '45',
    COUNTY: '071',
    NAME: 'Newberry',
    LSAD: 'County',
    CENSUSAREA: 630.037,
    FIPS: 'SC071',
    fips: '45071',
    StateAbbr: 'SC',
    StateName: 'South Carolina',
    lat: 34.28974754,
    lng: -81.59990705,
  },
  {
    GEO_ID: '0500000US45073',
    STATE: '45',
    COUNTY: '073',
    NAME: 'Oconee',
    LSAD: 'County',
    CENSUSAREA: 626.334,
    FIPS: 'SC073',
    fips: '45073',
    StateAbbr: 'SC',
    StateName: 'South Carolina',
    lat: 34.75329772,
    lng: -83.06576373,
  },
  {
    GEO_ID: '0500000US26041',
    STATE: '26',
    COUNTY: '041',
    NAME: 'Delta',
    LSAD: 'County',
    CENSUSAREA: 1171.096,
    FIPS: 'MI041',
    fips: '26041',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 45.91736311,
    lng: -86.92347486,
  },
  {
    GEO_ID: '0500000US26043',
    STATE: '26',
    COUNTY: '043',
    NAME: 'Dickinson',
    LSAD: 'County',
    CENSUSAREA: 761.4,
    FIPS: 'MI043',
    fips: '26043',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 46.00907005,
    lng: -87.87011459,
  },
  {
    GEO_ID: '0500000US26047',
    STATE: '26',
    COUNTY: '047',
    NAME: 'Emmet',
    LSAD: 'County',
    CENSUSAREA: 467.494,
    FIPS: 'MI047',
    fips: '26047',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 45.51996676,
    lng: -84.89116957,
  },
  {
    GEO_ID: '0500000US26051',
    STATE: '26',
    COUNTY: '051',
    NAME: 'Gladwin',
    LSAD: 'County',
    CENSUSAREA: 501.779,
    FIPS: 'MI051',
    fips: '26051',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 43.99062122,
    lng: -84.38860114,
  },
  {
    GEO_ID: '0500000US26065',
    STATE: '26',
    COUNTY: '065',
    NAME: 'Ingham',
    LSAD: 'County',
    CENSUSAREA: 556.12,
    FIPS: 'MI065',
    fips: '26065',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 42.59737278,
    lng: -84.37346607,
  },
  {
    GEO_ID: '0500000US26069',
    STATE: '26',
    COUNTY: '069',
    NAME: 'Iosco',
    LSAD: 'County',
    CENSUSAREA: 549.096,
    FIPS: 'MI069',
    fips: '26069',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 44.35578542,
    lng: -83.63669002,
  },
  {
    GEO_ID: '0500000US17131',
    STATE: '17',
    COUNTY: '131',
    NAME: 'Mercer',
    LSAD: 'County',
    CENSUSAREA: 561.202,
    FIPS: 'IL131',
    fips: '17131',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 41.20543704,
    lng: -90.74108384,
  },
  {
    GEO_ID: '0500000US13191',
    STATE: '13',
    COUNTY: '191',
    NAME: 'McIntosh',
    LSAD: 'County',
    CENSUSAREA: 424.299,
    FIPS: 'GA191',
    fips: '13191',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 31.49489855,
    lng: -81.40719089,
  },
  {
    GEO_ID: '0500000US31079',
    STATE: '31',
    COUNTY: '079',
    NAME: 'Hall',
    LSAD: 'County',
    CENSUSAREA: 546.289,
    FIPS: 'NE079',
    fips: '31079',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 40.87261328,
    lng: -98.50212614,
  },
  {
    GEO_ID: '0500000US31081',
    STATE: '31',
    COUNTY: '081',
    NAME: 'Hamilton',
    LSAD: 'County',
    CENSUSAREA: 542.878,
    FIPS: 'NE081',
    fips: '31081',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 40.87318318,
    lng: -98.02280046,
  },
  {
    GEO_ID: '0500000US31083',
    STATE: '31',
    COUNTY: '083',
    NAME: 'Harlan',
    LSAD: 'County',
    CENSUSAREA: 553.466,
    FIPS: 'NE083',
    fips: '31083',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 40.17656663,
    lng: -99.40436605,
  },
  {
    GEO_ID: '0500000US31087',
    STATE: '31',
    COUNTY: '087',
    NAME: 'Hitchcock',
    LSAD: 'County',
    CENSUSAREA: 709.943,
    FIPS: 'NE087',
    fips: '31087',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 40.17616691,
    lng: -101.0419762,
  },
  {
    GEO_ID: '0500000US31099',
    STATE: '31',
    COUNTY: '099',
    NAME: 'Kearney',
    LSAD: 'County',
    CENSUSAREA: 516.244,
    FIPS: 'NE099',
    fips: '31099',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 40.50675437,
    lng: -98.94803186,
  },
  {
    GEO_ID: '0500000US31101',
    STATE: '31',
    COUNTY: '101',
    NAME: 'Keith',
    LSAD: 'County',
    CENSUSAREA: 1061.6,
    FIPS: 'NE101',
    fips: '31101',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 41.19906619,
    lng: -101.6614388,
  },
  {
    GEO_ID: '0500000US31105',
    STATE: '31',
    COUNTY: '105',
    NAME: 'Kimball',
    LSAD: 'County',
    CENSUSAREA: 951.846,
    FIPS: 'NE105',
    fips: '31105',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 41.19760039,
    lng: -103.7149939,
  },
  {
    GEO_ID: '0500000US31109',
    STATE: '31',
    COUNTY: '109',
    NAME: 'Lancaster',
    LSAD: 'County',
    CENSUSAREA: 837.548,
    FIPS: 'NE109',
    fips: '31109',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 40.78416865,
    lng: -96.68776297,
  },
  {
    GEO_ID: '0500000US31115',
    STATE: '31',
    COUNTY: '115',
    NAME: 'Loup',
    LSAD: 'County',
    CENSUSAREA: 568.29,
    FIPS: 'NE115',
    fips: '31115',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 41.91371961,
    lng: -99.45440415,
  },
  {
    GEO_ID: '0500000US31119',
    STATE: '31',
    COUNTY: '119',
    NAME: 'Madison',
    LSAD: 'County',
    CENSUSAREA: 572.743,
    FIPS: 'NE119',
    fips: '31119',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 41.9166434,
    lng: -97.60078361,
  },
  {
    GEO_ID: '0500000US28091',
    STATE: '28',
    COUNTY: '091',
    NAME: 'Marion',
    LSAD: 'County',
    CENSUSAREA: 542.384,
    FIPS: 'MS091',
    fips: '28091',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 31.23086459,
    lng: -89.8225233,
  },
  {
    GEO_ID: '0500000US28097',
    STATE: '28',
    COUNTY: '097',
    NAME: 'Montgomery',
    LSAD: 'County',
    CENSUSAREA: 406.981,
    FIPS: 'MS097',
    fips: '28097',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 33.49402718,
    lng: -89.61664559,
  },
  {
    GEO_ID: '0500000US28101',
    STATE: '28',
    COUNTY: '101',
    NAME: 'Newton',
    LSAD: 'County',
    CENSUSAREA: 578.104,
    FIPS: 'MS101',
    fips: '28101',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 32.40013897,
    lng: -89.11871108,
  },
  {
    GEO_ID: '0500000US28109',
    STATE: '28',
    COUNTY: '109',
    NAME: 'Pearl River',
    LSAD: 'County',
    CENSUSAREA: 810.863,
    FIPS: 'MS109',
    fips: '28109',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 30.76882496,
    lng: -89.58952146,
  },
  {
    GEO_ID: '0500000US28119',
    STATE: '28',
    COUNTY: '119',
    NAME: 'Quitman',
    LSAD: 'County',
    CENSUSAREA: 405.012,
    FIPS: 'MS119',
    fips: '28119',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 34.25120737,
    lng: -90.28933634,
  },
  {
    GEO_ID: '0500000US28121',
    STATE: '28',
    COUNTY: '121',
    NAME: 'Rankin',
    LSAD: 'County',
    CENSUSAREA: 775.485,
    FIPS: 'MS121',
    fips: '28121',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 32.26422272,
    lng: -89.94594839,
  },
  {
    GEO_ID: '0500000US28129',
    STATE: '28',
    COUNTY: '129',
    NAME: 'Smith',
    LSAD: 'County',
    CENSUSAREA: 636.252,
    FIPS: 'MS129',
    fips: '28129',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 32.01792861,
    lng: -89.50664426,
  },
  {
    GEO_ID: '0500000US35041',
    STATE: '35',
    COUNTY: '041',
    NAME: 'Roosevelt',
    LSAD: 'County',
    CENSUSAREA: 2447.431,
    FIPS: 'NM041',
    fips: '35041',
    StateAbbr: 'NM',
    StateName: 'New Mexico',
    lat: 34.02127232,
    lng: -103.4801987,
  },
  {
    GEO_ID: '0500000US35043',
    STATE: '35',
    COUNTY: '043',
    NAME: 'Sandoval',
    LSAD: 'County',
    CENSUSAREA: 3710.65,
    FIPS: 'NM043',
    fips: '35043',
    StateAbbr: 'NM',
    StateName: 'New Mexico',
    lat: 35.68909544,
    lng: -106.8658272,
  },
  {
    GEO_ID: '0500000US35049',
    STATE: '35',
    COUNTY: '049',
    NAME: 'Santa Fe',
    LSAD: 'County',
    CENSUSAREA: 1909.414,
    FIPS: 'NM049',
    fips: '35049',
    StateAbbr: 'NM',
    StateName: 'New Mexico',
    lat: 35.50670942,
    lng: -105.9759986,
  },
  {
    GEO_ID: '0500000US35057',
    STATE: '35',
    COUNTY: '057',
    NAME: 'Torrance',
    LSAD: 'County',
    CENSUSAREA: 3344.845,
    FIPS: 'NM057',
    fips: '35057',
    StateAbbr: 'NM',
    StateName: 'New Mexico',
    lat: 34.64043207,
    lng: -105.8505392,
  },
  {
    GEO_ID: '0500000US35061',
    STATE: '35',
    COUNTY: '061',
    NAME: 'Valencia',
    LSAD: 'County',
    CENSUSAREA: 1066.175,
    FIPS: 'NM061',
    fips: '35061',
    StateAbbr: 'NM',
    StateName: 'New Mexico',
    lat: 34.71536166,
    lng: -106.8090108,
  },
  {
    GEO_ID: '0500000US36007',
    STATE: '36',
    COUNTY: '007',
    NAME: 'Broome',
    LSAD: 'County',
    CENSUSAREA: 705.766,
    FIPS: 'NY007',
    fips: '36007',
    StateAbbr: 'NY',
    StateName: 'New York',
    lat: 42.16037158,
    lng: -75.8198592,
  },
  {
    GEO_ID: '0500000US36011',
    STATE: '36',
    COUNTY: '011',
    NAME: 'Cayuga',
    LSAD: 'County',
    CENSUSAREA: 691.582,
    FIPS: 'NY011',
    fips: '36011',
    StateAbbr: 'NY',
    StateName: 'New York',
    lat: 42.91811235,
    lng: -76.55468471,
  },
  {
    GEO_ID: '0500000US36013',
    STATE: '36',
    COUNTY: '013',
    NAME: 'Chautauqua',
    LSAD: 'County',
    CENSUSAREA: 1060.226,
    FIPS: 'NY013',
    fips: '36013',
    StateAbbr: 'NY',
    StateName: 'New York',
    lat: 42.22817594,
    lng: -79.36664149,
  },
  {
    GEO_ID: '0500000US36019',
    STATE: '36',
    COUNTY: '019',
    NAME: 'Clinton',
    LSAD: 'County',
    CENSUSAREA: 1037.852,
    FIPS: 'NY019',
    fips: '36019',
    StateAbbr: 'NY',
    StateName: 'New York',
    lat: 44.74606721,
    lng: -73.67828248,
  },
  {
    GEO_ID: '0500000US36027',
    STATE: '36',
    COUNTY: '027',
    NAME: 'Dutchess',
    LSAD: 'County',
    CENSUSAREA: 795.63,
    FIPS: 'NY027',
    fips: '36027',
    StateAbbr: 'NY',
    StateName: 'New York',
    lat: 41.76513375,
    lng: -73.74303603,
  },
  {
    GEO_ID: '0500000US36033',
    STATE: '36',
    COUNTY: '033',
    NAME: 'Franklin',
    LSAD: 'County',
    CENSUSAREA: 1629.119,
    FIPS: 'NY033',
    fips: '36033',
    StateAbbr: 'NY',
    StateName: 'New York',
    lat: 44.59274379,
    lng: -74.30395769,
  },
  {
    GEO_ID: '0500000US36035',
    STATE: '36',
    COUNTY: '035',
    NAME: 'Fulton',
    LSAD: 'County',
    CENSUSAREA: 495.469,
    FIPS: 'NY035',
    fips: '36035',
    StateAbbr: 'NY',
    StateName: 'New York',
    lat: 43.11377432,
    lng: -74.42194258,
  },
  {
    GEO_ID: '0500000US36039',
    STATE: '36',
    COUNTY: '039',
    NAME: 'Greene',
    LSAD: 'County',
    CENSUSAREA: 647.161,
    FIPS: 'NY039',
    fips: '36039',
    StateAbbr: 'NY',
    StateName: 'New York',
    lat: 42.27638026,
    lng: -74.12279549,
  },
  {
    GEO_ID: '0500000US40131',
    STATE: '40',
    COUNTY: '131',
    NAME: 'Rogers',
    LSAD: 'County',
    CENSUSAREA: 675.632,
    FIPS: 'OK131',
    fips: '40131',
    StateAbbr: 'OK',
    StateName: 'Oklahoma',
    lat: 36.37196654,
    lng: -95.60476511,
  },
  {
    GEO_ID: '0500000US40135',
    STATE: '40',
    COUNTY: '135',
    NAME: 'Sequoyah',
    LSAD: 'County',
    CENSUSAREA: 673.271,
    FIPS: 'OK135',
    fips: '40135',
    StateAbbr: 'OK',
    StateName: 'Oklahoma',
    lat: 35.49549888,
    lng: -94.75507825,
  },
  {
    GEO_ID: '0500000US40137',
    STATE: '40',
    COUNTY: '137',
    NAME: 'Stephens',
    LSAD: 'County',
    CENSUSAREA: 870.244,
    FIPS: 'OK137',
    fips: '40137',
    StateAbbr: 'OK',
    StateName: 'Oklahoma',
    lat: 34.48542286,
    lng: -97.85148899,
  },
  {
    GEO_ID: '0500000US40143',
    STATE: '40',
    COUNTY: '143',
    NAME: 'Tulsa',
    LSAD: 'County',
    CENSUSAREA: 570.246,
    FIPS: 'OK143',
    fips: '40143',
    StateAbbr: 'OK',
    StateName: 'Oklahoma',
    lat: 36.12121947,
    lng: -95.94143178,
  },
  {
    GEO_ID: '0500000US40145',
    STATE: '40',
    COUNTY: '145',
    NAME: 'Wagoner',
    LSAD: 'County',
    CENSUSAREA: 561.555,
    FIPS: 'OK145',
    fips: '40145',
    StateAbbr: 'OK',
    StateName: 'Oklahoma',
    lat: 35.96138606,
    lng: -95.52115154,
  },
  {
    GEO_ID: '0500000US40149',
    STATE: '40',
    COUNTY: '149',
    NAME: 'Washita',
    LSAD: 'County',
    CENSUSAREA: 1003.167,
    FIPS: 'OK149',
    fips: '40149',
    StateAbbr: 'OK',
    StateName: 'Oklahoma',
    lat: 35.29031759,
    lng: -98.99166621,
  },
  {
    GEO_ID: '0500000US41003',
    STATE: '41',
    COUNTY: '003',
    NAME: 'Benton',
    LSAD: 'County',
    CENSUSAREA: 675.941,
    FIPS: 'OR003',
    fips: '41003',
    StateAbbr: 'OR',
    StateName: 'Oregon',
    lat: 44.49161157,
    lng: -123.428743,
  },
  {
    GEO_ID: '0500000US41005',
    STATE: '41',
    COUNTY: '005',
    NAME: 'Clackamas',
    LSAD: 'County',
    CENSUSAREA: 1870.322,
    FIPS: 'OR005',
    fips: '41005',
    StateAbbr: 'OR',
    StateName: 'Oregon',
    lat: 45.18820387,
    lng: -122.2205544,
  },
  {
    GEO_ID: '0500000US41011',
    STATE: '41',
    COUNTY: '011',
    NAME: 'Coos',
    LSAD: 'County',
    CENSUSAREA: 1596.173,
    FIPS: 'OR011',
    fips: '41011',
    StateAbbr: 'OR',
    StateName: 'Oregon',
    lat: 43.17396579,
    lng: -124.0595871,
  },
  {
    GEO_ID: '0500000US41013',
    STATE: '41',
    COUNTY: '013',
    NAME: 'Crook',
    LSAD: 'County',
    CENSUSAREA: 2979.086,
    FIPS: 'OR013',
    fips: '41013',
    StateAbbr: 'OR',
    StateName: 'Oregon',
    lat: 44.14229491,
    lng: -120.3567062,
  },
  {
    GEO_ID: '0500000US41021',
    STATE: '41',
    COUNTY: '021',
    NAME: 'Gilliam',
    LSAD: 'County',
    CENSUSAREA: 1204.809,
    FIPS: 'OR021',
    fips: '41021',
    StateAbbr: 'OR',
    StateName: 'Oregon',
    lat: 45.37780558,
    lng: -120.210458,
  },
  {
    GEO_ID: '0500000US41027',
    STATE: '41',
    COUNTY: '027',
    NAME: 'Hood River',
    LSAD: 'County',
    CENSUSAREA: 521.949,
    FIPS: 'OR027',
    fips: '41027',
    StateAbbr: 'OR',
    StateName: 'Oregon',
    lat: 45.51931999,
    lng: -121.6512892,
  },
  {
    GEO_ID: '0500000US26075',
    STATE: '26',
    COUNTY: '075',
    NAME: 'Jackson',
    LSAD: 'County',
    CENSUSAREA: 701.666,
    FIPS: 'MI075',
    fips: '26075',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 42.24852059,
    lng: -84.42251355,
  },
  {
    GEO_ID: '0500000US26079',
    STATE: '26',
    COUNTY: '079',
    NAME: 'Kalkaska',
    LSAD: 'County',
    CENSUSAREA: 559.865,
    FIPS: 'MI079',
    fips: '26079',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 44.68488514,
    lng: -85.0904614,
  },
  {
    GEO_ID: '0500000US26081',
    STATE: '26',
    COUNTY: '081',
    NAME: 'Kent',
    LSAD: 'County',
    CENSUSAREA: 846.948,
    FIPS: 'MI081',
    fips: '26081',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 43.03204611,
    lng: -85.54918115,
  },
  {
    GEO_ID: '0500000US26087',
    STATE: '26',
    COUNTY: '087',
    NAME: 'Lapeer',
    LSAD: 'County',
    CENSUSAREA: 643.014,
    FIPS: 'MI087',
    fips: '26087',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 43.09052506,
    lng: -83.22153148,
  },
  {
    GEO_ID: '0500000US26093',
    STATE: '26',
    COUNTY: '093',
    NAME: 'Livingston',
    LSAD: 'County',
    CENSUSAREA: 565.254,
    FIPS: 'MI093',
    fips: '26093',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 42.60389287,
    lng: -83.9117077,
  },
  {
    GEO_ID: '0500000US26095',
    STATE: '26',
    COUNTY: '095',
    NAME: 'Luce',
    LSAD: 'County',
    CENSUSAREA: 899.078,
    FIPS: 'MI095',
    fips: '26095',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 46.4704998,
    lng: -85.54389048,
  },
  {
    GEO_ID: '0500000US26099',
    STATE: '26',
    COUNTY: '099',
    NAME: 'Macomb',
    LSAD: 'County',
    CENSUSAREA: 479.223,
    FIPS: 'MI099',
    fips: '26099',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 42.69584067,
    lng: -82.93255517,
  },
  {
    GEO_ID: '0500000US26101',
    STATE: '26',
    COUNTY: '101',
    NAME: 'Manistee',
    LSAD: 'County',
    CENSUSAREA: 542.149,
    FIPS: 'MI101',
    fips: '26101',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 44.33345321,
    lng: -86.05635345,
  },
  {
    GEO_ID: '0500000US26115',
    STATE: '26',
    COUNTY: '115',
    NAME: 'Monroe',
    LSAD: 'County',
    CENSUSAREA: 549.394,
    FIPS: 'MI115',
    fips: '26115',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 41.92832154,
    lng: -83.53769332,
  },
  {
    GEO_ID: '0500000US28137',
    STATE: '28',
    COUNTY: '137',
    NAME: 'Tate',
    LSAD: 'County',
    CENSUSAREA: 404.759,
    FIPS: 'MS137',
    fips: '28137',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 34.65069529,
    lng: -89.94520436,
  },
  {
    GEO_ID: '0500000US28141',
    STATE: '28',
    COUNTY: '141',
    NAME: 'Tishomingo',
    LSAD: 'County',
    CENSUSAREA: 424.254,
    FIPS: 'MS141',
    fips: '28141',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 34.74037117,
    lng: -88.23908046,
  },
  {
    GEO_ID: '0500000US28143',
    STATE: '28',
    COUNTY: '143',
    NAME: 'Tunica',
    LSAD: 'County',
    CENSUSAREA: 454.668,
    FIPS: 'MS143',
    fips: '28143',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 34.65198616,
    lng: -90.37532903,
  },
  {
    GEO_ID: '0500000US28153',
    STATE: '28',
    COUNTY: '153',
    NAME: 'Wayne',
    LSAD: 'County',
    CENSUSAREA: 810.75,
    FIPS: 'MS153',
    fips: '28153',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 31.64077954,
    lng: -88.69588404,
  },
  {
    GEO_ID: '0500000US28155',
    STATE: '28',
    COUNTY: '155',
    NAME: 'Webster',
    LSAD: 'County',
    CENSUSAREA: 420.94,
    FIPS: 'MS155',
    fips: '28155',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 33.61326385,
    lng: -89.28510404,
  },
  {
    GEO_ID: '0500000US28157',
    STATE: '28',
    COUNTY: '157',
    NAME: 'Wilkinson',
    LSAD: 'County',
    CENSUSAREA: 678.106,
    FIPS: 'MS157',
    fips: '28157',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 31.16072415,
    lng: -91.3114768,
  },
  {
    GEO_ID: '0500000US28163',
    STATE: '28',
    COUNTY: '163',
    NAME: 'Yazoo',
    LSAD: 'County',
    CENSUSAREA: 922.947,
    FIPS: 'MS163',
    fips: '28163',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 32.78007214,
    lng: -90.39655652,
  },
  {
    GEO_ID: '0500000US29007',
    STATE: '29',
    COUNTY: '007',
    NAME: 'Audrain',
    LSAD: 'County',
    CENSUSAREA: 692.235,
    FIPS: 'MO007',
    fips: '29007',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 39.21595536,
    lng: -91.84163742,
  },
  {
    GEO_ID: '0500000US29017',
    STATE: '29',
    COUNTY: '017',
    NAME: 'Bollinger',
    LSAD: 'County',
    CENSUSAREA: 617.906,
    FIPS: 'MO017',
    fips: '29017',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 37.3219182,
    lng: -90.02562128,
  },
  {
    GEO_ID: '0500000US29023',
    STATE: '29',
    COUNTY: '023',
    NAME: 'Butler',
    LSAD: 'County',
    CENSUSAREA: 694.669,
    FIPS: 'MO023',
    fips: '29023',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 36.71638784,
    lng: -90.40672985,
  },
  {
    GEO_ID: '0500000US29029',
    STATE: '29',
    COUNTY: '029',
    NAME: 'Camden',
    LSAD: 'County',
    CENSUSAREA: 655.923,
    FIPS: 'MO029',
    fips: '29029',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 38.02703414,
    lng: -92.76600464,
  },
  {
    GEO_ID: '0500000US29035',
    STATE: '29',
    COUNTY: '035',
    NAME: 'Carter',
    LSAD: 'County',
    CENSUSAREA: 507.361,
    FIPS: 'MO035',
    fips: '29035',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 36.94111292,
    lng: -90.96231959,
  },
  {
    GEO_ID: '0500000US29041',
    STATE: '29',
    COUNTY: '041',
    NAME: 'Chariton',
    LSAD: 'County',
    CENSUSAREA: 751.181,
    FIPS: 'MO041',
    fips: '29041',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 39.51548794,
    lng: -92.96305582,
  },
  {
    GEO_ID: '0500000US29045',
    STATE: '29',
    COUNTY: '045',
    NAME: 'Clark',
    LSAD: 'County',
    CENSUSAREA: 504.692,
    FIPS: 'MO045',
    fips: '29045',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 40.41027674,
    lng: -91.73823977,
  },
  {
    GEO_ID: '0500000US36041',
    STATE: '36',
    COUNTY: '041',
    NAME: 'Hamilton',
    LSAD: 'County',
    CENSUSAREA: 1717.373,
    FIPS: 'NY041',
    fips: '36041',
    StateAbbr: 'NY',
    StateName: 'New York',
    lat: 43.66134129,
    lng: -74.49745908,
  },
  {
    GEO_ID: '0500000US36045',
    STATE: '36',
    COUNTY: '045',
    NAME: 'Jefferson',
    LSAD: 'County',
    CENSUSAREA: 1268.59,
    FIPS: 'NY045',
    fips: '36045',
    StateAbbr: 'NY',
    StateName: 'New York',
    lat: 44.04796973,
    lng: -75.93125006,
  },
  {
    GEO_ID: '0500000US36051',
    STATE: '36',
    COUNTY: '051',
    NAME: 'Livingston',
    LSAD: 'County',
    CENSUSAREA: 631.763,
    FIPS: 'NY051',
    fips: '36051',
    StateAbbr: 'NY',
    StateName: 'New York',
    lat: 42.72855751,
    lng: -77.77517835,
  },
  {
    GEO_ID: '0500000US36055',
    STATE: '36',
    COUNTY: '055',
    NAME: 'Monroe',
    LSAD: 'County',
    CENSUSAREA: 657.205,
    FIPS: 'NY055',
    fips: '36055',
    StateAbbr: 'NY',
    StateName: 'New York',
    lat: 43.14694904,
    lng: -77.69595887,
  },
  {
    GEO_ID: '0500000US36059',
    STATE: '36',
    COUNTY: '059',
    NAME: 'Nassau',
    LSAD: 'County',
    CENSUSAREA: 284.716,
    FIPS: 'NY059',
    fips: '36059',
    StateAbbr: 'NY',
    StateName: 'New York',
    lat: 40.73761782,
    lng: -73.58622623,
  },
  {
    GEO_ID: '0500000US36063',
    STATE: '36',
    COUNTY: '063',
    NAME: 'Niagara',
    LSAD: 'County',
    CENSUSAREA: 522.359,
    FIPS: 'NY063',
    fips: '36063',
    StateAbbr: 'NY',
    StateName: 'New York',
    lat: 43.20013175,
    lng: -78.7453209,
  },
  {
    GEO_ID: '0500000US36071',
    STATE: '36',
    COUNTY: '071',
    NAME: 'Orange',
    LSAD: 'County',
    CENSUSAREA: 811.686,
    FIPS: 'NY071',
    fips: '36071',
    StateAbbr: 'NY',
    StateName: 'New York',
    lat: 41.40198158,
    lng: -74.30558896,
  },
  {
    GEO_ID: '0500000US36073',
    STATE: '36',
    COUNTY: '073',
    NAME: 'Orleans',
    LSAD: 'County',
    CENSUSAREA: 391.259,
    FIPS: 'NY073',
    fips: '36073',
    StateAbbr: 'NY',
    StateName: 'New York',
    lat: 43.25158537,
    lng: -78.23141929,
  },
  {
    GEO_ID: '0500000US36075',
    STATE: '36',
    COUNTY: '075',
    NAME: 'Oswego',
    LSAD: 'County',
    CENSUSAREA: 951.65,
    FIPS: 'NY075',
    fips: '36075',
    StateAbbr: 'NY',
    StateName: 'New York',
    lat: 43.42718293,
    lng: -76.14177407,
  },
  {
    GEO_ID: '0500000US48435',
    STATE: '48',
    COUNTY: '435',
    NAME: 'Sutton',
    LSAD: 'County',
    CENSUSAREA: 1453.926,
    FIPS: 'TX435',
    fips: '48435',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 30.4985468,
    lng: -100.5382117,
  },
  {
    GEO_ID: '0500000US48437',
    STATE: '48',
    COUNTY: '437',
    NAME: 'Swisher',
    LSAD: 'County',
    CENSUSAREA: 890.159,
    FIPS: 'TX437',
    fips: '48437',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 34.53028891,
    lng: -101.7349174,
  },
  {
    GEO_ID: '0500000US48441',
    STATE: '48',
    COUNTY: '441',
    NAME: 'Taylor',
    LSAD: 'County',
    CENSUSAREA: 915.552,
    FIPS: 'TX441',
    fips: '48441',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 32.30175703,
    lng: -99.88996827,
  },
  {
    GEO_ID: '0500000US48447',
    STATE: '48',
    COUNTY: '447',
    NAME: 'Throckmorton',
    LSAD: 'County',
    CENSUSAREA: 912.552,
    FIPS: 'TX447',
    fips: '48447',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 33.17751417,
    lng: -99.21226795,
  },
  {
    GEO_ID: '0500000US48451',
    STATE: '48',
    COUNTY: '451',
    NAME: 'Tom Green',
    LSAD: 'County',
    CENSUSAREA: 1521.972,
    FIPS: 'TX451',
    fips: '48451',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 31.40455473,
    lng: -100.4624522,
  },
  {
    GEO_ID: '0500000US48453',
    STATE: '48',
    COUNTY: '453',
    NAME: 'Travis',
    LSAD: 'County',
    CENSUSAREA: 990.202,
    FIPS: 'TX453',
    fips: '48453',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 30.33471479,
    lng: -97.78169164,
  },
  {
    GEO_ID: '0500000US48457',
    STATE: '48',
    COUNTY: '457',
    NAME: 'Tyler',
    LSAD: 'County',
    CENSUSAREA: 924.501,
    FIPS: 'TX457',
    fips: '48457',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 30.77118478,
    lng: -94.37649281,
  },
  {
    GEO_ID: '0500000US48467',
    STATE: '48',
    COUNTY: '467',
    NAME: 'Van Zandt',
    LSAD: 'County',
    CENSUSAREA: 842.557,
    FIPS: 'TX467',
    fips: '48467',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 32.56379892,
    lng: -95.83674215,
  },
  {
    GEO_ID: '0500000US48469',
    STATE: '48',
    COUNTY: '469',
    NAME: 'Victoria',
    LSAD: 'County',
    CENSUSAREA: 882.143,
    FIPS: 'TX469',
    fips: '48469',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 28.7960712,
    lng: -96.97135956,
  },
  {
    GEO_ID: '0500000US48477',
    STATE: '48',
    COUNTY: '477',
    NAME: 'Washington',
    LSAD: 'County',
    CENSUSAREA: 603.954,
    FIPS: 'TX477',
    fips: '48477',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 30.21441875,
    lng: -96.40341594,
  },
  {
    GEO_ID: '0500000US48483',
    STATE: '48',
    COUNTY: '483',
    NAME: 'Wheeler',
    LSAD: 'County',
    CENSUSAREA: 914.523,
    FIPS: 'TX483',
    fips: '48483',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 35.40114335,
    lng: -100.2699514,
  },
  {
    GEO_ID: '0500000US48485',
    STATE: '48',
    COUNTY: '485',
    NAME: 'Wichita',
    LSAD: 'County',
    CENSUSAREA: 627.776,
    FIPS: 'TX485',
    fips: '48485',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 33.98806865,
    lng: -98.7035838,
  },
  {
    GEO_ID: '0500000US48489',
    STATE: '48',
    COUNTY: '489',
    NAME: 'Willacy',
    LSAD: 'County',
    CENSUSAREA: 590.554,
    FIPS: 'TX489',
    fips: '48489',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 26.47040624,
    lng: -97.65724482,
  },
  {
    GEO_ID: '0500000US48499',
    STATE: '48',
    COUNTY: '499',
    NAME: 'Wood',
    LSAD: 'County',
    CENSUSAREA: 645.234,
    FIPS: 'TX499',
    fips: '48499',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 32.78643978,
    lng: -95.38204393,
  },
  {
    GEO_ID: '0500000US49001',
    STATE: '49',
    COUNTY: '001',
    NAME: 'Beaver',
    LSAD: 'County',
    CENSUSAREA: 2589.88,
    FIPS: 'UT001',
    fips: '49001',
    StateAbbr: 'UT',
    StateName: 'Utah',
    lat: 38.35770202,
    lng: -113.2353778,
  },
  {
    GEO_ID: '0500000US49007',
    STATE: '49',
    COUNTY: '007',
    NAME: 'Carbon',
    LSAD: 'County',
    CENSUSAREA: 1478.493,
    FIPS: 'UT007',
    fips: '49007',
    StateAbbr: 'UT',
    StateName: 'Utah',
    lat: 39.64773138,
    lng: -110.5891155,
  },
  {
    GEO_ID: '0500000US26071',
    STATE: '26',
    COUNTY: '071',
    NAME: 'Iron',
    LSAD: 'County',
    CENSUSAREA: 1166.149,
    FIPS: 'MI071',
    fips: '26071',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 46.20865908,
    lng: -88.53028044,
  },
  {
    GEO_ID: '0500000US26125',
    STATE: '26',
    COUNTY: '125',
    NAME: 'Oakland',
    LSAD: 'County',
    CENSUSAREA: 867.663,
    FIPS: 'MI125',
    fips: '26125',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 42.66090645,
    lng: -83.38594391,
  },
  {
    GEO_ID: '0500000US26131',
    STATE: '26',
    COUNTY: '131',
    NAME: 'Ontonagon',
    LSAD: 'County',
    CENSUSAREA: 1311.225,
    FIPS: 'MI131',
    fips: '26131',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 46.66430808,
    lng: -89.31491201,
  },
  {
    GEO_ID: '0500000US26135',
    STATE: '26',
    COUNTY: '135',
    NAME: 'Oscoda',
    LSAD: 'County',
    CENSUSAREA: 565.731,
    FIPS: 'MI135',
    fips: '26135',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 44.68170135,
    lng: -84.12938765,
  },
  {
    GEO_ID: '0500000US26145',
    STATE: '26',
    COUNTY: '145',
    NAME: 'Saginaw',
    LSAD: 'County',
    CENSUSAREA: 800.114,
    FIPS: 'MI145',
    fips: '26145',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 43.33526107,
    lng: -84.0526996,
  },
  {
    GEO_ID: '0500000US26147',
    STATE: '26',
    COUNTY: '147',
    NAME: 'St. Clair',
    LSAD: 'County',
    CENSUSAREA: 721.17,
    FIPS: 'MI147',
    fips: '26147',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 42.93188855,
    lng: -82.68083292,
  },
  {
    GEO_ID: '0500000US26157',
    STATE: '26',
    COUNTY: '157',
    NAME: 'Tuscola',
    LSAD: 'County',
    CENSUSAREA: 803.127,
    FIPS: 'MI157',
    fips: '26157',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 43.46515989,
    lng: -83.41747147,
  },
  {
    GEO_ID: '0500000US29047',
    STATE: '29',
    COUNTY: '047',
    NAME: 'Clay',
    LSAD: 'County',
    CENSUSAREA: 397.298,
    FIPS: 'MO047',
    fips: '29047',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 39.31045251,
    lng: -94.42122576,
  },
  {
    GEO_ID: '0500000US29051',
    STATE: '29',
    COUNTY: '051',
    NAME: 'Cole',
    LSAD: 'County',
    CENSUSAREA: 393.75,
    FIPS: 'MO051',
    fips: '29051',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 38.50579587,
    lng: -92.28161601,
  },
  {
    GEO_ID: '0500000US29055',
    STATE: '29',
    COUNTY: '055',
    NAME: 'Crawford',
    LSAD: 'County',
    CENSUSAREA: 742.517,
    FIPS: 'MO055',
    fips: '29055',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 37.97649081,
    lng: -91.30516204,
  },
  {
    GEO_ID: '0500000US29057',
    STATE: '29',
    COUNTY: '057',
    NAME: 'Dade',
    LSAD: 'County',
    CENSUSAREA: 490.012,
    FIPS: 'MO057',
    fips: '29057',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 37.431814,
    lng: -93.8503345,
  },
  {
    GEO_ID: '0500000US29061',
    STATE: '29',
    COUNTY: '061',
    NAME: 'Daviess',
    LSAD: 'County',
    CENSUSAREA: 563.24,
    FIPS: 'MO061',
    fips: '29061',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 39.96169076,
    lng: -93.98514508,
  },
  {
    GEO_ID: '0500000US29067',
    STATE: '29',
    COUNTY: '067',
    NAME: 'Douglas',
    LSAD: 'County',
    CENSUSAREA: 813.632,
    FIPS: 'MO067',
    fips: '29067',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 36.93253661,
    lng: -92.49898814,
  },
  {
    GEO_ID: '0500000US29073',
    STATE: '29',
    COUNTY: '073',
    NAME: 'Gasconade',
    LSAD: 'County',
    CENSUSAREA: 517.803,
    FIPS: 'MO073',
    fips: '29073',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 38.44075158,
    lng: -91.50783063,
  },
  {
    GEO_ID: '0500000US29077',
    STATE: '29',
    COUNTY: '077',
    NAME: 'Greene',
    LSAD: 'County',
    CENSUSAREA: 675.304,
    FIPS: 'MO077',
    fips: '29077',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 37.25758647,
    lng: -93.34188307,
  },
  {
    GEO_ID: '0500000US29079',
    STATE: '29',
    COUNTY: '079',
    NAME: 'Grundy',
    LSAD: 'County',
    CENSUSAREA: 435.276,
    FIPS: 'MO079',
    fips: '29079',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 40.1140924,
    lng: -93.56516333,
  },
  {
    GEO_ID: '0500000US29087',
    STATE: '29',
    COUNTY: '087',
    NAME: 'Holt',
    LSAD: 'County',
    CENSUSAREA: 462.693,
    FIPS: 'MO087',
    fips: '29087',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 40.0944922,
    lng: -95.21504754,
  },
  {
    GEO_ID: '0500000US37011',
    STATE: '37',
    COUNTY: '011',
    NAME: 'Avery',
    LSAD: 'County',
    CENSUSAREA: 247.087,
    FIPS: 'NC011',
    fips: '37011',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 36.07667708,
    lng: -81.92267092,
  },
  {
    GEO_ID: '0500000US37013',
    STATE: '37',
    COUNTY: '013',
    NAME: 'Beaufort',
    LSAD: 'County',
    CENSUSAREA: 827.192,
    FIPS: 'NC013',
    fips: '37013',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 35.49296573,
    lng: -76.85887749,
  },
  {
    GEO_ID: '0500000US37019',
    STATE: '37',
    COUNTY: '019',
    NAME: 'Brunswick',
    LSAD: 'County',
    CENSUSAREA: 846.973,
    FIPS: 'NC019',
    fips: '37019',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 34.07037851,
    lng: -78.23791614,
  },
  {
    GEO_ID: '0500000US37025',
    STATE: '37',
    COUNTY: '025',
    NAME: 'Cabarrus',
    LSAD: 'County',
    CENSUSAREA: 361.749,
    FIPS: 'NC025',
    fips: '37025',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 35.38756942,
    lng: -80.55133048,
  },
  {
    GEO_ID: '0500000US13223',
    STATE: '13',
    COUNTY: '223',
    NAME: 'Paulding',
    LSAD: 'County',
    CENSUSAREA: 312.219,
    FIPS: 'GA223',
    fips: '13223',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 33.92082846,
    lng: -84.86736714,
  },
  {
    GEO_ID: '0500000US36081',
    STATE: '36',
    COUNTY: '081',
    NAME: 'Queens',
    LSAD: 'County',
    CENSUSAREA: 108.532,
    FIPS: 'NY081',
    fips: '36081',
    StateAbbr: 'NY',
    StateName: 'New York',
    lat: 40.70829859,
    lng: -73.81734511,
  },
  {
    GEO_ID: '0500000US36087',
    STATE: '36',
    COUNTY: '087',
    NAME: 'Rockland',
    LSAD: 'County',
    CENSUSAREA: 173.55,
    FIPS: 'NY087',
    fips: '36087',
    StateAbbr: 'NY',
    StateName: 'New York',
    lat: 41.15225744,
    lng: -74.02389861,
  },
  {
    GEO_ID: '0500000US36089',
    STATE: '36',
    COUNTY: '089',
    NAME: 'St. Lawrence',
    LSAD: 'County',
    CENSUSAREA: 2680.377,
    FIPS: 'NY089',
    fips: '36089',
    StateAbbr: 'NY',
    StateName: 'New York',
    lat: 44.49647794,
    lng: -75.06907698,
  },
  {
    GEO_ID: '0500000US36093',
    STATE: '36',
    COUNTY: '093',
    NAME: 'Schenectady',
    LSAD: 'County',
    CENSUSAREA: 204.516,
    FIPS: 'NY093',
    fips: '36093',
    StateAbbr: 'NY',
    StateName: 'New York',
    lat: 42.81832249,
    lng: -74.05833636,
  },
  {
    GEO_ID: '0500000US36099',
    STATE: '36',
    COUNTY: '099',
    NAME: 'Seneca',
    LSAD: 'County',
    CENSUSAREA: 323.705,
    FIPS: 'NY099',
    fips: '36099',
    StateAbbr: 'NY',
    StateName: 'New York',
    lat: 42.78068586,
    lng: -76.82362358,
  },
  {
    GEO_ID: '0500000US36103',
    STATE: '36',
    COUNTY: '103',
    NAME: 'Suffolk',
    LSAD: 'County',
    CENSUSAREA: 912.051,
    FIPS: 'NY103',
    fips: '36103',
    StateAbbr: 'NY',
    StateName: 'New York',
    lat: 40.87024178,
    lng: -72.84423227,
  },
  {
    GEO_ID: '0500000US36107',
    STATE: '36',
    COUNTY: '107',
    NAME: 'Tioga',
    LSAD: 'County',
    CENSUSAREA: 518.602,
    FIPS: 'NY107',
    fips: '36107',
    StateAbbr: 'NY',
    StateName: 'New York',
    lat: 42.17034639,
    lng: -76.3061895,
  },
  {
    GEO_ID: '0500000US36117',
    STATE: '36',
    COUNTY: '117',
    NAME: 'Wayne',
    LSAD: 'County',
    CENSUSAREA: 603.826,
    FIPS: 'NY117',
    fips: '36117',
    StateAbbr: 'NY',
    StateName: 'New York',
    lat: 43.15652579,
    lng: -77.02965084,
  },
  {
    GEO_ID: '0500000US42017',
    STATE: '42',
    COUNTY: '017',
    NAME: 'Bucks',
    LSAD: 'County',
    CENSUSAREA: 604.307,
    FIPS: 'PA017',
    fips: '42017',
    StateAbbr: 'PA',
    StateName: 'Pennsylvania',
    lat: 40.33694355,
    lng: -75.10684724,
  },
  {
    GEO_ID: '0500000US42023',
    STATE: '42',
    COUNTY: '023',
    NAME: 'Cameron',
    LSAD: 'County',
    CENSUSAREA: 396.231,
    FIPS: 'PA023',
    fips: '42023',
    StateAbbr: 'PA',
    StateName: 'Pennsylvania',
    lat: 41.43674888,
    lng: -78.20384508,
  },
  {
    GEO_ID: '0500000US45079',
    STATE: '45',
    COUNTY: '079',
    NAME: 'Richland',
    LSAD: 'County',
    CENSUSAREA: 757.068,
    FIPS: 'SC079',
    fips: '45079',
    StateAbbr: 'SC',
    StateName: 'South Carolina',
    lat: 34.02226754,
    lng: -80.90384361,
  },
  {
    GEO_ID: '0500000US45083',
    STATE: '45',
    COUNTY: '083',
    NAME: 'Spartanburg',
    LSAD: 'County',
    CENSUSAREA: 807.926,
    FIPS: 'SC083',
    fips: '45083',
    StateAbbr: 'SC',
    StateName: 'South Carolina',
    lat: 34.93148726,
    lng: -81.99110742,
  },
  {
    GEO_ID: '0500000US45085',
    STATE: '45',
    COUNTY: '085',
    NAME: 'Sumter',
    LSAD: 'County',
    CENSUSAREA: 665.066,
    FIPS: 'SC085',
    fips: '45085',
    StateAbbr: 'SC',
    StateName: 'South Carolina',
    lat: 33.91600797,
    lng: -80.38227873,
  },
  {
    GEO_ID: '0500000US45089',
    STATE: '45',
    COUNTY: '089',
    NAME: 'Williamsburg',
    LSAD: 'County',
    CENSUSAREA: 934.159,
    FIPS: 'SC089',
    fips: '45089',
    StateAbbr: 'SC',
    StateName: 'South Carolina',
    lat: 33.62020834,
    lng: -79.72785637,
  },
  {
    GEO_ID: '0500000US46005',
    STATE: '46',
    COUNTY: '005',
    NAME: 'Beadle',
    LSAD: 'County',
    CENSUSAREA: 1258.71,
    FIPS: 'SD005',
    fips: '46005',
    StateAbbr: 'SD',
    StateName: 'South Dakota',
    lat: 44.41467039,
    lng: -98.27883711,
  },
  {
    GEO_ID: '0500000US46011',
    STATE: '46',
    COUNTY: '011',
    NAME: 'Brookings',
    LSAD: 'County',
    CENSUSAREA: 792.206,
    FIPS: 'SD011',
    fips: '46011',
    StateAbbr: 'SD',
    StateName: 'South Dakota',
    lat: 44.36981684,
    lng: -96.79064836,
  },
  {
    GEO_ID: '0500000US46019',
    STATE: '46',
    COUNTY: '019',
    NAME: 'Butte',
    LSAD: 'County',
    CENSUSAREA: 2249.899,
    FIPS: 'SD019',
    fips: '46019',
    StateAbbr: 'SD',
    StateName: 'South Dakota',
    lat: 44.905694,
    lng: -103.507491,
  },
  {
    GEO_ID: '0500000US46023',
    STATE: '46',
    COUNTY: '023',
    NAME: 'Charles Mix',
    LSAD: 'County',
    CENSUSAREA: 1097.487,
    FIPS: 'SD023',
    fips: '46023',
    StateAbbr: 'SD',
    StateName: 'South Dakota',
    lat: 43.20792726,
    lng: -98.58785039,
  },
  {
    GEO_ID: '0500000US46033',
    STATE: '46',
    COUNTY: '033',
    NAME: 'Custer',
    LSAD: 'County',
    CENSUSAREA: 1557.002,
    FIPS: 'SD033',
    fips: '46033',
    StateAbbr: 'SD',
    StateName: 'South Dakota',
    lat: 43.67736837,
    lng: -103.4514908,
  },
  {
    GEO_ID: '0500000US46039',
    STATE: '46',
    COUNTY: '039',
    NAME: 'Deuel',
    LSAD: 'County',
    CENSUSAREA: 622.686,
    FIPS: 'SD039',
    fips: '46039',
    StateAbbr: 'SD',
    StateName: 'South Dakota',
    lat: 44.76015949,
    lng: -96.66802862,
  },
  {
    GEO_ID: '0500000US46041',
    STATE: '46',
    COUNTY: '041',
    NAME: 'Dewey',
    LSAD: 'County',
    CENSUSAREA: 2302.494,
    FIPS: 'SD041',
    fips: '46041',
    StateAbbr: 'SD',
    StateName: 'South Dakota',
    lat: 45.15658384,
    lng: -100.8716993,
  },
  {
    GEO_ID: '0500000US46045',
    STATE: '46',
    COUNTY: '045',
    NAME: 'Edmunds',
    LSAD: 'County',
    CENSUSAREA: 1125.956,
    FIPS: 'SD045',
    fips: '46045',
    StateAbbr: 'SD',
    StateName: 'South Dakota',
    lat: 45.41847898,
    lng: -99.21540939,
  },
  {
    GEO_ID: '0500000US46051',
    STATE: '46',
    COUNTY: '051',
    NAME: 'Grant',
    LSAD: 'County',
    CENSUSAREA: 681.458,
    FIPS: 'SD051',
    fips: '46051',
    StateAbbr: 'SD',
    StateName: 'South Dakota',
    lat: 45.17218979,
    lng: -96.76789112,
  },
  {
    GEO_ID: '0500000US46059',
    STATE: '46',
    COUNTY: '059',
    NAME: 'Hand',
    LSAD: 'County',
    CENSUSAREA: 1436.613,
    FIPS: 'SD059',
    fips: '46059',
    StateAbbr: 'SD',
    StateName: 'South Dakota',
    lat: 44.54798838,
    lng: -99.00538393,
  },
  {
    GEO_ID: '0500000US26159',
    STATE: '26',
    COUNTY: '159',
    NAME: 'Van Buren',
    LSAD: 'County',
    CENSUSAREA: 607.474,
    FIPS: 'MI159',
    fips: '26159',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 42.25166438,
    lng: -86.01839482,
  },
  {
    GEO_ID: '0500000US26163',
    STATE: '26',
    COUNTY: '163',
    NAME: 'Wayne',
    LSAD: 'County',
    CENSUSAREA: 612.08,
    FIPS: 'MI163',
    fips: '26163',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 42.28172038,
    lng: -83.28221321,
  },
  {
    GEO_ID: '0500000US27001',
    STATE: '27',
    COUNTY: '001',
    NAME: 'Aitkin',
    LSAD: 'County',
    CENSUSAREA: 1821.663,
    FIPS: 'MN001',
    fips: '27001',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 46.60787445,
    lng: -93.4156134,
  },
  {
    GEO_ID: '0500000US27003',
    STATE: '27',
    COUNTY: '003',
    NAME: 'Anoka',
    LSAD: 'County',
    CENSUSAREA: 423.01,
    FIPS: 'MN003',
    fips: '27003',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 45.27314304,
    lng: -93.24708307,
  },
  {
    GEO_ID: '0500000US27011',
    STATE: '27',
    COUNTY: '011',
    NAME: 'Big Stone',
    LSAD: 'County',
    CENSUSAREA: 499.022,
    FIPS: 'MN011',
    fips: '27011',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 45.42651259,
    lng: -96.41134505,
  },
  {
    GEO_ID: '0500000US27017',
    STATE: '27',
    COUNTY: '017',
    NAME: 'Carlton',
    LSAD: 'County',
    CENSUSAREA: 861.381,
    FIPS: 'MN017',
    fips: '27017',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 46.592321,
    lng: -92.67716012,
  },
  {
    GEO_ID: '0500000US27025',
    STATE: '27',
    COUNTY: '025',
    NAME: 'Chisago',
    LSAD: 'County',
    CENSUSAREA: 414.862,
    FIPS: 'MN025',
    fips: '27025',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 45.5024111,
    lng: -92.90829767,
  },
  {
    GEO_ID: '0500000US27035',
    STATE: '27',
    COUNTY: '035',
    NAME: 'Crow Wing',
    LSAD: 'County',
    CENSUSAREA: 999.095,
    FIPS: 'MN035',
    fips: '27035',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 46.48265818,
    lng: -94.07070376,
  },
  {
    GEO_ID: '0500000US27037',
    STATE: '27',
    COUNTY: '037',
    NAME: 'Dakota',
    LSAD: 'County',
    CENSUSAREA: 562.168,
    FIPS: 'MN037',
    fips: '27037',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 44.67245057,
    lng: -93.06556878,
  },
  {
    GEO_ID: '0500000US27041',
    STATE: '27',
    COUNTY: '041',
    NAME: 'Douglas',
    LSAD: 'County',
    CENSUSAREA: 637.3,
    FIPS: 'MN041',
    fips: '27041',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 45.93372693,
    lng: -95.45422901,
  },
  {
    GEO_ID: '0500000US27043',
    STATE: '27',
    COUNTY: '043',
    NAME: 'Faribault',
    LSAD: 'County',
    CENSUSAREA: 712.476,
    FIPS: 'MN043',
    fips: '27043',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 43.67387282,
    lng: -93.94781493,
  },
  {
    GEO_ID: '0500000US27045',
    STATE: '27',
    COUNTY: '045',
    NAME: 'Fillmore',
    LSAD: 'County',
    CENSUSAREA: 861.3,
    FIPS: 'MN045',
    fips: '27045',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 43.6739576,
    lng: -92.08990525,
  },
  {
    GEO_ID: '0500000US27053',
    STATE: '27',
    COUNTY: '053',
    NAME: 'Hennepin',
    LSAD: 'County',
    CENSUSAREA: 553.591,
    FIPS: 'MN053',
    fips: '27053',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 45.00479678,
    lng: -93.47715121,
  },
  {
    GEO_ID: '0500000US27059',
    STATE: '27',
    COUNTY: '059',
    NAME: 'Isanti',
    LSAD: 'County',
    CENSUSAREA: 435.794,
    FIPS: 'MN059',
    fips: '27059',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 45.56125565,
    lng: -93.29470209,
  },
  {
    GEO_ID: '0500000US37035',
    STATE: '37',
    COUNTY: '035',
    NAME: 'Catawba',
    LSAD: 'County',
    CENSUSAREA: 398.721,
    FIPS: 'NC035',
    fips: '37035',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 35.66260115,
    lng: -81.21481079,
  },
  {
    GEO_ID: '0500000US37037',
    STATE: '37',
    COUNTY: '037',
    NAME: 'Chatham',
    LSAD: 'County',
    CENSUSAREA: 682.185,
    FIPS: 'NC037',
    fips: '37037',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 35.70266277,
    lng: -79.25545829,
  },
  {
    GEO_ID: '0500000US37039',
    STATE: '37',
    COUNTY: '039',
    NAME: 'Cherokee',
    LSAD: 'County',
    CENSUSAREA: 455.426,
    FIPS: 'NC039',
    fips: '37039',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 35.13376757,
    lng: -84.06424533,
  },
  {
    GEO_ID: '0500000US37041',
    STATE: '37',
    COUNTY: '041',
    NAME: 'Chowan',
    LSAD: 'County',
    CENSUSAREA: 172.473,
    FIPS: 'NC041',
    fips: '37041',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 36.14854015,
    lng: -76.60802425,
  },
  {
    GEO_ID: '0500000US37047',
    STATE: '37',
    COUNTY: '047',
    NAME: 'Columbus',
    LSAD: 'County',
    CENSUSAREA: 937.293,
    FIPS: 'NC047',
    fips: '37047',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 34.26573301,
    lng: -78.65514472,
  },
  {
    GEO_ID: '0500000US37059',
    STATE: '37',
    COUNTY: '059',
    NAME: 'Davie',
    LSAD: 'County',
    CENSUSAREA: 264.105,
    FIPS: 'NC059',
    fips: '37059',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 35.92957297,
    lng: -80.54428142,
  },
  {
    GEO_ID: '0500000US37061',
    STATE: '37',
    COUNTY: '061',
    NAME: 'Duplin',
    LSAD: 'County',
    CENSUSAREA: 816.219,
    FIPS: 'NC061',
    fips: '37061',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 34.93627171,
    lng: -77.93295681,
  },
  {
    GEO_ID: '0500000US37065',
    STATE: '37',
    COUNTY: '065',
    NAME: 'Edgecombe',
    LSAD: 'County',
    CENSUSAREA: 505.336,
    FIPS: 'NC065',
    fips: '37065',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 35.91303781,
    lng: -77.59635385,
  },
  {
    GEO_ID: '0500000US37069',
    STATE: '37',
    COUNTY: '069',
    NAME: 'Franklin',
    LSAD: 'County',
    CENSUSAREA: 491.682,
    FIPS: 'NC069',
    fips: '37069',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 36.08251637,
    lng: -78.2853416,
  },
  {
    GEO_ID: '0500000US37071',
    STATE: '37',
    COUNTY: '071',
    NAME: 'Gaston',
    LSAD: 'County',
    CENSUSAREA: 356.027,
    FIPS: 'NC071',
    fips: '37071',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 35.29432138,
    lng: -81.18014844,
  },
  {
    GEO_ID: '0500000US37075',
    STATE: '37',
    COUNTY: '075',
    NAME: 'Graham',
    LSAD: 'County',
    CENSUSAREA: 292.079,
    FIPS: 'NC075',
    fips: '37075',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 35.35024285,
    lng: -83.83311406,
  },
  {
    GEO_ID: '0500000US37077',
    STATE: '37',
    COUNTY: '077',
    NAME: 'Granville',
    LSAD: 'County',
    CENSUSAREA: 531.57,
    FIPS: 'NC077',
    fips: '37077',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 36.30365992,
    lng: -78.65301,
  },
  {
    GEO_ID: '0500000US37083',
    STATE: '37',
    COUNTY: '083',
    NAME: 'Halifax',
    LSAD: 'County',
    CENSUSAREA: 724.086,
    FIPS: 'NC083',
    fips: '37083',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 36.25737352,
    lng: -77.65158415,
  },
  {
    GEO_ID: '0500000US37087',
    STATE: '37',
    COUNTY: '087',
    NAME: 'Haywood',
    LSAD: 'County',
    CENSUSAREA: 553.692,
    FIPS: 'NC087',
    fips: '37087',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 35.55576195,
    lng: -82.98251028,
  },
  {
    GEO_ID: '0500000US42027',
    STATE: '42',
    COUNTY: '027',
    NAME: 'Centre',
    LSAD: 'County',
    CENSUSAREA: 1109.921,
    FIPS: 'PA027',
    fips: '42027',
    StateAbbr: 'PA',
    StateName: 'Pennsylvania',
    lat: 40.91933775,
    lng: -77.81986792,
  },
  {
    GEO_ID: '0500000US42029',
    STATE: '42',
    COUNTY: '029',
    NAME: 'Chester',
    LSAD: 'County',
    CENSUSAREA: 750.508,
    FIPS: 'PA029',
    fips: '42029',
    StateAbbr: 'PA',
    StateName: 'Pennsylvania',
    lat: 39.97329798,
    lng: -75.74843926,
  },
  {
    GEO_ID: '0500000US42033',
    STATE: '42',
    COUNTY: '033',
    NAME: 'Clearfield',
    LSAD: 'County',
    CENSUSAREA: 1144.722,
    FIPS: 'PA033',
    fips: '42033',
    StateAbbr: 'PA',
    StateName: 'Pennsylvania',
    lat: 41.00013648,
    lng: -78.4740474,
  },
  {
    GEO_ID: '0500000US42039',
    STATE: '42',
    COUNTY: '039',
    NAME: 'Crawford',
    LSAD: 'County',
    CENSUSAREA: 1012.298,
    FIPS: 'PA039',
    fips: '42039',
    StateAbbr: 'PA',
    StateName: 'Pennsylvania',
    lat: 41.68442887,
    lng: -80.10676906,
  },
  {
    GEO_ID: '0500000US42045',
    STATE: '42',
    COUNTY: '045',
    NAME: 'Delaware',
    LSAD: 'County',
    CENSUSAREA: 183.843,
    FIPS: 'PA045',
    fips: '42045',
    StateAbbr: 'PA',
    StateName: 'Pennsylvania',
    lat: 39.9166501,
    lng: -75.39915513,
  },
  {
    GEO_ID: '0500000US42049',
    STATE: '42',
    COUNTY: '049',
    NAME: 'Erie',
    LSAD: 'County',
    CENSUSAREA: 799.154,
    FIPS: 'PA049',
    fips: '42049',
    StateAbbr: 'PA',
    StateName: 'Pennsylvania',
    lat: 41.9921752,
    lng: -80.03257439,
  },
  {
    GEO_ID: '0500000US42051',
    STATE: '42',
    COUNTY: '051',
    NAME: 'Fayette',
    LSAD: 'County',
    CENSUSAREA: 790.339,
    FIPS: 'PA051',
    fips: '42051',
    StateAbbr: 'PA',
    StateName: 'Pennsylvania',
    lat: 39.91984935,
    lng: -79.64746996,
  },
  {
    GEO_ID: '0500000US42055',
    STATE: '42',
    COUNTY: '055',
    NAME: 'Franklin',
    LSAD: 'County',
    CENSUSAREA: 772.224,
    FIPS: 'PA055',
    fips: '42055',
    StateAbbr: 'PA',
    StateName: 'Pennsylvania',
    lat: 39.92745705,
    lng: -77.72158008,
  },
  {
    GEO_ID: '0500000US42061',
    STATE: '42',
    COUNTY: '061',
    NAME: 'Huntingdon',
    LSAD: 'County',
    CENSUSAREA: 874.639,
    FIPS: 'PA061',
    fips: '42061',
    StateAbbr: 'PA',
    StateName: 'Pennsylvania',
    lat: 40.41688617,
    lng: -77.98140696,
  },
  {
    GEO_ID: '0500000US42067',
    STATE: '42',
    COUNTY: '067',
    NAME: 'Juniata',
    LSAD: 'County',
    CENSUSAREA: 391.35,
    FIPS: 'PA067',
    fips: '42067',
    StateAbbr: 'PA',
    StateName: 'Pennsylvania',
    lat: 40.5308105,
    lng: -77.40277415,
  },
  {
    GEO_ID: '0500000US42073',
    STATE: '42',
    COUNTY: '073',
    NAME: 'Lawrence',
    LSAD: 'County',
    CENSUSAREA: 358.175,
    FIPS: 'PA073',
    fips: '42073',
    StateAbbr: 'PA',
    StateName: 'Pennsylvania',
    lat: 40.99133874,
    lng: -80.33434051,
  },
  {
    GEO_ID: '0500000US42079',
    STATE: '42',
    COUNTY: '079',
    NAME: 'Luzerne',
    LSAD: 'County',
    CENSUSAREA: 890.333,
    FIPS: 'PA079',
    fips: '42079',
    StateAbbr: 'PA',
    StateName: 'Pennsylvania',
    lat: 41.17686973,
    lng: -75.98893638,
  },
  {
    GEO_ID: '0500000US41029',
    STATE: '41',
    COUNTY: '029',
    NAME: 'Jackson',
    LSAD: 'County',
    CENSUSAREA: 2783.548,
    FIPS: 'OR029',
    fips: '41029',
    StateAbbr: 'OR',
    StateName: 'Oregon',
    lat: 42.43216537,
    lng: -122.728377,
  },
  {
    GEO_ID: '0500000US41033',
    STATE: '41',
    COUNTY: '033',
    NAME: 'Josephine',
    LSAD: 'County',
    CENSUSAREA: 1639.668,
    FIPS: 'OR033',
    fips: '41033',
    StateAbbr: 'OR',
    StateName: 'Oregon',
    lat: 42.36551155,
    lng: -123.5552595,
  },
  {
    GEO_ID: '0500000US41039',
    STATE: '41',
    COUNTY: '039',
    NAME: 'Lane',
    LSAD: 'County',
    CENSUSAREA: 4553.119,
    FIPS: 'OR039',
    fips: '41039',
    StateAbbr: 'OR',
    StateName: 'Oregon',
    lat: 43.93889441,
    lng: -122.8473327,
  },
  {
    GEO_ID: '0500000US41043',
    STATE: '41',
    COUNTY: '043',
    NAME: 'Linn',
    LSAD: 'County',
    CENSUSAREA: 2290.129,
    FIPS: 'OR043',
    fips: '41043',
    StateAbbr: 'OR',
    StateName: 'Oregon',
    lat: 44.48880768,
    lng: -122.5341835,
  },
  {
    GEO_ID: '0500000US41049',
    STATE: '41',
    COUNTY: '049',
    NAME: 'Morrow',
    LSAD: 'County',
    CENSUSAREA: 2031.609,
    FIPS: 'OR049',
    fips: '41049',
    StateAbbr: 'OR',
    StateName: 'Oregon',
    lat: 45.41897957,
    lng: -119.5837591,
  },
  {
    GEO_ID: '0500000US41051',
    STATE: '41',
    COUNTY: '051',
    NAME: 'Multnomah',
    LSAD: 'County',
    CENSUSAREA: 431.297,
    FIPS: 'OR051',
    fips: '41051',
    StateAbbr: 'OR',
    StateName: 'Oregon',
    lat: 45.54693681,
    lng: -122.4157429,
  },
  {
    GEO_ID: '0500000US41059',
    STATE: '41',
    COUNTY: '059',
    NAME: 'Umatilla',
    LSAD: 'County',
    CENSUSAREA: 3215.509,
    FIPS: 'OR059',
    fips: '41059',
    StateAbbr: 'OR',
    StateName: 'Oregon',
    lat: 45.59148273,
    lng: -118.736681,
  },
  {
    GEO_ID: '0500000US41065',
    STATE: '41',
    COUNTY: '065',
    NAME: 'Wasco',
    LSAD: 'County',
    CENSUSAREA: 2381.519,
    FIPS: 'OR065',
    fips: '41065',
    StateAbbr: 'OR',
    StateName: 'Oregon',
    lat: 45.16003768,
    lng: -121.1678419,
  },
  {
    GEO_ID: '0500000US41071',
    STATE: '41',
    COUNTY: '071',
    NAME: 'Yamhill',
    LSAD: 'County',
    CENSUSAREA: 715.86,
    FIPS: 'OR071',
    fips: '41071',
    StateAbbr: 'OR',
    StateName: 'Oregon',
    lat: 45.23269019,
    lng: -123.3078799,
  },
  {
    GEO_ID: '0500000US27063',
    STATE: '27',
    COUNTY: '063',
    NAME: 'Jackson',
    LSAD: 'County',
    CENSUSAREA: 702.982,
    FIPS: 'MN063',
    fips: '27063',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 43.67418723,
    lng: -95.15400031,
  },
  {
    GEO_ID: '0500000US27065',
    STATE: '27',
    COUNTY: '065',
    NAME: 'Kanabec',
    LSAD: 'County',
    CENSUSAREA: 521.586,
    FIPS: 'MN065',
    fips: '27065',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 45.94534057,
    lng: -93.29290464,
  },
  {
    GEO_ID: '0500000US27069',
    STATE: '27',
    COUNTY: '069',
    NAME: 'Kittson',
    LSAD: 'County',
    CENSUSAREA: 1098.804,
    FIPS: 'MN069',
    fips: '27069',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 48.77698427,
    lng: -96.78256113,
  },
  {
    GEO_ID: '0500000US29105',
    STATE: '29',
    COUNTY: '105',
    NAME: 'Laclede',
    LSAD: 'County',
    CENSUSAREA: 764.717,
    FIPS: 'MO105',
    fips: '29105',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 37.65865829,
    lng: -92.59043082,
  },
  {
    GEO_ID: '0500000US29111',
    STATE: '29',
    COUNTY: '111',
    NAME: 'Lewis',
    LSAD: 'County',
    CENSUSAREA: 505.042,
    FIPS: 'MO111',
    fips: '29111',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 40.09673548,
    lng: -91.72178943,
  },
  {
    GEO_ID: '0500000US29119',
    STATE: '29',
    COUNTY: '119',
    NAME: 'McDonald',
    LSAD: 'County',
    CENSUSAREA: 539.48,
    FIPS: 'MO119',
    fips: '29119',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 36.6286562,
    lng: -94.34823345,
  },
  {
    GEO_ID: '0500000US29125',
    STATE: '29',
    COUNTY: '125',
    NAME: 'Maries',
    LSAD: 'County',
    CENSUSAREA: 526.979,
    FIPS: 'MO125',
    fips: '29125',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 38.16167922,
    lng: -91.92492582,
  },
  {
    GEO_ID: '0500000US29129',
    STATE: '29',
    COUNTY: '129',
    NAME: 'Mercer',
    LSAD: 'County',
    CENSUSAREA: 453.835,
    FIPS: 'MO129',
    fips: '29129',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 40.42251333,
    lng: -93.56850616,
  },
  {
    GEO_ID: '0500000US29139',
    STATE: '29',
    COUNTY: '139',
    NAME: 'Montgomery',
    LSAD: 'County',
    CENSUSAREA: 536.247,
    FIPS: 'MO139',
    fips: '29139',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 38.94128519,
    lng: -91.47020839,
  },
  {
    GEO_ID: '0500000US29141',
    STATE: '29',
    COUNTY: '141',
    NAME: 'Morgan',
    LSAD: 'County',
    CENSUSAREA: 597.631,
    FIPS: 'MO141',
    fips: '29141',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 38.42350791,
    lng: -92.88597122,
  },
  {
    GEO_ID: '0500000US29147',
    STATE: '29',
    COUNTY: '147',
    NAME: 'Nodaway',
    LSAD: 'County',
    CENSUSAREA: 876.964,
    FIPS: 'MO147',
    fips: '29147',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 40.36098014,
    lng: -94.88335354,
  },
  {
    GEO_ID: '0500000US29153',
    STATE: '29',
    COUNTY: '153',
    NAME: 'Ozark',
    LSAD: 'County',
    CENSUSAREA: 744.972,
    FIPS: 'MO153',
    fips: '29153',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 36.64922253,
    lng: -92.44469447,
  },
  {
    GEO_ID: '0500000US29157',
    STATE: '29',
    COUNTY: '157',
    NAME: 'Perry',
    LSAD: 'County',
    CENSUSAREA: 474.354,
    FIPS: 'MO157',
    fips: '29157',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 37.70718892,
    lng: -89.82456269,
  },
  {
    GEO_ID: '0500000US29161',
    STATE: '29',
    COUNTY: '161',
    NAME: 'Phelps',
    LSAD: 'County',
    CENSUSAREA: 671.784,
    FIPS: 'MO161',
    fips: '29161',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 37.87731447,
    lng: -91.79238502,
  },
  {
    GEO_ID: '0500000US29167',
    STATE: '29',
    COUNTY: '167',
    NAME: 'Polk',
    LSAD: 'County',
    CENSUSAREA: 635.523,
    FIPS: 'MO167',
    fips: '29167',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 37.61649452,
    lng: -93.40034649,
  },
  {
    GEO_ID: '0500000US37095',
    STATE: '37',
    COUNTY: '095',
    NAME: 'Hyde',
    LSAD: 'County',
    CENSUSAREA: 612.7,
    FIPS: 'NC095',
    fips: '37095',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 35.51917533,
    lng: -76.24647047,
  },
  {
    GEO_ID: '0500000US37099',
    STATE: '37',
    COUNTY: '099',
    NAME: 'Jackson',
    LSAD: 'County',
    CENSUSAREA: 490.755,
    FIPS: 'NC099',
    fips: '37099',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 35.28733908,
    lng: -83.14111074,
  },
  {
    GEO_ID: '0500000US37105',
    STATE: '37',
    COUNTY: '105',
    NAME: 'Lee',
    LSAD: 'County',
    CENSUSAREA: 254.959,
    FIPS: 'NC105',
    fips: '37105',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 35.47515626,
    lng: -79.17158398,
  },
  {
    GEO_ID: '0500000US37111',
    STATE: '37',
    COUNTY: '111',
    NAME: 'McDowell',
    LSAD: 'County',
    CENSUSAREA: 440.608,
    FIPS: 'NC111',
    fips: '37111',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 35.68110614,
    lng: -82.04845774,
  },
  {
    GEO_ID: '0500000US37115',
    STATE: '37',
    COUNTY: '115',
    NAME: 'Madison',
    LSAD: 'County',
    CENSUSAREA: 449.57,
    FIPS: 'NC115',
    fips: '37115',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 35.85793329,
    lng: -82.70612535,
  },
  {
    GEO_ID: '0500000US37121',
    STATE: '37',
    COUNTY: '121',
    NAME: 'Mitchell',
    LSAD: 'County',
    CENSUSAREA: 221.425,
    FIPS: 'NC121',
    fips: '37121',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 36.01324162,
    lng: -82.16391398,
  },
  {
    GEO_ID: '0500000US37129',
    STATE: '37',
    COUNTY: '129',
    NAME: 'New Hanover',
    LSAD: 'County',
    CENSUSAREA: 191.534,
    FIPS: 'NC129',
    fips: '37129',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 34.2322858,
    lng: -77.88474375,
  },
  {
    GEO_ID: '0500000US37135',
    STATE: '37',
    COUNTY: '135',
    NAME: 'Orange',
    LSAD: 'County',
    CENSUSAREA: 397.958,
    FIPS: 'NC135',
    fips: '37135',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 36.06127743,
    lng: -79.12071407,
  },
  {
    GEO_ID: '0500000US37139',
    STATE: '37',
    COUNTY: '139',
    NAME: 'Pasquotank',
    LSAD: 'County',
    CENSUSAREA: 226.88,
    FIPS: 'NC139',
    fips: '37139',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 36.29472462,
    lng: -76.28360001,
  },
  {
    GEO_ID: '0500000US37143',
    STATE: '37',
    COUNTY: '143',
    NAME: 'Perquimans',
    LSAD: 'County',
    CENSUSAREA: 247.089,
    FIPS: 'NC143',
    fips: '37143',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 36.20508496,
    lng: -76.4395049,
  },
  {
    GEO_ID: '0500000US37147',
    STATE: '37',
    COUNTY: '147',
    NAME: 'Pitt',
    LSAD: 'County',
    CENSUSAREA: 651.975,
    FIPS: 'NC147',
    fips: '37147',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 35.59325583,
    lng: -77.37489067,
  },
  {
    GEO_ID: '0500000US37153',
    STATE: '37',
    COUNTY: '153',
    NAME: 'Richmond',
    LSAD: 'County',
    CENSUSAREA: 473.821,
    FIPS: 'NC153',
    fips: '37153',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 35.00590629,
    lng: -79.74711644,
  },
  {
    GEO_ID: '0500000US42085',
    STATE: '42',
    COUNTY: '085',
    NAME: 'Mercer',
    LSAD: 'County',
    CENSUSAREA: 672.575,
    FIPS: 'PA085',
    fips: '42085',
    StateAbbr: 'PA',
    StateName: 'Pennsylvania',
    lat: 41.30228665,
    lng: -80.25773634,
  },
  {
    GEO_ID: '0500000US42089',
    STATE: '42',
    COUNTY: '089',
    NAME: 'Monroe',
    LSAD: 'County',
    CENSUSAREA: 608.286,
    FIPS: 'PA089',
    fips: '42089',
    StateAbbr: 'PA',
    StateName: 'Pennsylvania',
    lat: 41.05812363,
    lng: -75.33930212,
  },
  {
    GEO_ID: '0500000US42095',
    STATE: '42',
    COUNTY: '095',
    NAME: 'Northampton',
    LSAD: 'County',
    CENSUSAREA: 369.671,
    FIPS: 'PA095',
    fips: '42095',
    StateAbbr: 'PA',
    StateName: 'Pennsylvania',
    lat: 40.75446063,
    lng: -75.30735363,
  },
  {
    GEO_ID: '0500000US42101',
    STATE: '42',
    COUNTY: '101',
    NAME: 'Philadelphia',
    LSAD: 'County',
    CENSUSAREA: 134.101,
    FIPS: 'PA101',
    fips: '42101',
    StateAbbr: 'PA',
    StateName: 'Pennsylvania',
    lat: 40.00806441,
    lng: -75.13324284,
  },
  {
    GEO_ID: '0500000US42109',
    STATE: '42',
    COUNTY: '109',
    NAME: 'Snyder',
    LSAD: 'County',
    CENSUSAREA: 328.705,
    FIPS: 'PA109',
    fips: '42109',
    StateAbbr: 'PA',
    StateName: 'Pennsylvania',
    lat: 40.76993359,
    lng: -77.06984318,
  },
  {
    GEO_ID: '0500000US42111',
    STATE: '42',
    COUNTY: '111',
    NAME: 'Somerset',
    LSAD: 'County',
    CENSUSAREA: 1074.374,
    FIPS: 'PA111',
    fips: '42111',
    StateAbbr: 'PA',
    StateName: 'Pennsylvania',
    lat: 39.9723843,
    lng: -79.02838302,
  },
  {
    GEO_ID: '0500000US42115',
    STATE: '42',
    COUNTY: '115',
    NAME: 'Susquehanna',
    LSAD: 'County',
    CENSUSAREA: 823.435,
    FIPS: 'PA115',
    fips: '42115',
    StateAbbr: 'PA',
    StateName: 'Pennsylvania',
    lat: 41.82127676,
    lng: -75.80090451,
  },
  {
    GEO_ID: '0500000US42119',
    STATE: '42',
    COUNTY: '119',
    NAME: 'Union',
    LSAD: 'County',
    CENSUSAREA: 315.982,
    FIPS: 'PA119',
    fips: '42119',
    StateAbbr: 'PA',
    StateName: 'Pennsylvania',
    lat: 40.9633376,
    lng: -77.06213019,
  },
  {
    GEO_ID: '0500000US42121',
    STATE: '42',
    COUNTY: '121',
    NAME: 'Venango',
    LSAD: 'County',
    CENSUSAREA: 674.284,
    FIPS: 'PA121',
    fips: '42121',
    StateAbbr: 'PA',
    StateName: 'Pennsylvania',
    lat: 41.40101962,
    lng: -79.75792032,
  },
  {
    GEO_ID: '0500000US42125',
    STATE: '42',
    COUNTY: '125',
    NAME: 'Washington',
    LSAD: 'County',
    CENSUSAREA: 856.989,
    FIPS: 'PA125',
    fips: '42125',
    StateAbbr: 'PA',
    StateName: 'Pennsylvania',
    lat: 40.18935898,
    lng: -80.24855447,
  },
  {
    GEO_ID: '0500000US42133',
    STATE: '42',
    COUNTY: '133',
    NAME: 'York',
    LSAD: 'County',
    CENSUSAREA: 904.181,
    FIPS: 'PA133',
    fips: '42133',
    StateAbbr: 'PA',
    StateName: 'Pennsylvania',
    lat: 39.92001283,
    lng: -76.72634152,
  },
  {
    GEO_ID: '0500000US49009',
    STATE: '49',
    COUNTY: '009',
    NAME: 'Daggett',
    LSAD: 'County',
    CENSUSAREA: 696.979,
    FIPS: 'UT009',
    fips: '49009',
    StateAbbr: 'UT',
    StateName: 'Utah',
    lat: 40.88725861,
    lng: -109.5076481,
  },
  {
    GEO_ID: '0500000US49013',
    STATE: '49',
    COUNTY: '013',
    NAME: 'Duchesne',
    LSAD: 'County',
    CENSUSAREA: 3240.945,
    FIPS: 'UT013',
    fips: '49013',
    StateAbbr: 'UT',
    StateName: 'Utah',
    lat: 40.29770877,
    lng: -110.4255449,
  },
  {
    GEO_ID: '0500000US49019',
    STATE: '49',
    COUNTY: '019',
    NAME: 'Grand',
    LSAD: 'County',
    CENSUSAREA: 3671.543,
    FIPS: 'UT019',
    fips: '49019',
    StateAbbr: 'UT',
    StateName: 'Utah',
    lat: 38.98190066,
    lng: -109.5698239,
  },
  {
    GEO_ID: '0500000US49023',
    STATE: '49',
    COUNTY: '023',
    NAME: 'Juab',
    LSAD: 'County',
    CENSUSAREA: 3392.278,
    FIPS: 'UT023',
    fips: '49023',
    StateAbbr: 'UT',
    StateName: 'Utah',
    lat: 39.70284831,
    lng: -112.7847328,
  },
  {
    GEO_ID: '0500000US48001',
    STATE: '48',
    COUNTY: '001',
    NAME: 'Anderson',
    LSAD: 'County',
    CENSUSAREA: 1062.602,
    FIPS: 'TX001',
    fips: '48001',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 31.81341571,
    lng: -95.65255946,
  },
  {
    GEO_ID: '0500000US48013',
    STATE: '48',
    COUNTY: '013',
    NAME: 'Atascosa',
    LSAD: 'County',
    CENSUSAREA: 1219.544,
    FIPS: 'TX013',
    fips: '48013',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 28.89331115,
    lng: -98.52716292,
  },
  {
    GEO_ID: '0500000US48015',
    STATE: '48',
    COUNTY: '015',
    NAME: 'Austin',
    LSAD: 'County',
    CENSUSAREA: 646.508,
    FIPS: 'TX015',
    fips: '48015',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 29.88707804,
    lng: -96.27800561,
  },
  {
    GEO_ID: '0500000US48017',
    STATE: '48',
    COUNTY: '017',
    NAME: 'Bailey',
    LSAD: 'County',
    CENSUSAREA: 826.797,
    FIPS: 'TX017',
    fips: '48017',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 34.06860316,
    lng: -102.8298267,
  },
  {
    GEO_ID: '0500000US48023',
    STATE: '48',
    COUNTY: '023',
    NAME: 'Baylor',
    LSAD: 'County',
    CENSUSAREA: 867.482,
    FIPS: 'TX023',
    fips: '48023',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 33.61640794,
    lng: -99.21352901,
  },
  {
    GEO_ID: '0500000US48025',
    STATE: '48',
    COUNTY: '025',
    NAME: 'Bee',
    LSAD: 'County',
    CENSUSAREA: 880.239,
    FIPS: 'TX025',
    fips: '48025',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 28.41736711,
    lng: -97.74102624,
  },
  {
    GEO_ID: '0500000US48029',
    STATE: '48',
    COUNTY: '029',
    NAME: 'Bexar',
    LSAD: 'County',
    CENSUSAREA: 1239.82,
    FIPS: 'TX029',
    fips: '48029',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 29.44911665,
    lng: -98.52016013,
  },
  {
    GEO_ID: '0500000US48033',
    STATE: '48',
    COUNTY: '033',
    NAME: 'Borden',
    LSAD: 'County',
    CENSUSAREA: 897.443,
    FIPS: 'TX033',
    fips: '48033',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 32.74363984,
    lng: -101.4316509,
  },
  {
    GEO_ID: '0500000US48035',
    STATE: '48',
    COUNTY: '035',
    NAME: 'Bosque',
    LSAD: 'County',
    CENSUSAREA: 982.978,
    FIPS: 'TX035',
    fips: '48035',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 31.90035901,
    lng: -97.63435711,
  },
  {
    GEO_ID: '0500000US48039',
    STATE: '48',
    COUNTY: '039',
    NAME: 'Brazoria',
    LSAD: 'County',
    CENSUSAREA: 1357.701,
    FIPS: 'TX039',
    fips: '48039',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 29.18949785,
    lng: -95.45158629,
  },
  {
    GEO_ID: '0500000US29171',
    STATE: '29',
    COUNTY: '171',
    NAME: 'Putnam',
    LSAD: 'County',
    CENSUSAREA: 517.317,
    FIPS: 'MO171',
    fips: '29171',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 40.47877624,
    lng: -93.01666575,
  },
  {
    GEO_ID: '0500000US29175',
    STATE: '29',
    COUNTY: '175',
    NAME: 'Randolph',
    LSAD: 'County',
    CENSUSAREA: 482.684,
    FIPS: 'MO175',
    fips: '29175',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 39.44009232,
    lng: -92.49714212,
  },
  {
    GEO_ID: '0500000US29177',
    STATE: '29',
    COUNTY: '177',
    NAME: 'Ray',
    LSAD: 'County',
    CENSUSAREA: 568.805,
    FIPS: 'MO177',
    fips: '29177',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 39.35287295,
    lng: -93.98990821,
  },
  {
    GEO_ID: '0500000US29181',
    STATE: '29',
    COUNTY: '181',
    NAME: 'Ripley',
    LSAD: 'County',
    CENSUSAREA: 629.537,
    FIPS: 'MO181',
    fips: '29181',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 36.65269053,
    lng: -90.86381088,
  },
  {
    GEO_ID: '0500000US29186',
    STATE: '29',
    COUNTY: '186',
    NAME: 'Ste. Genevieve',
    LSAD: 'County',
    CENSUSAREA: 499.153,
    FIPS: 'MO186',
    fips: '29186',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 37.89454581,
    lng: -90.1946731,
  },
  {
    GEO_ID: '0500000US29187',
    STATE: '29',
    COUNTY: '187',
    NAME: 'St. Francois',
    LSAD: 'County',
    CENSUSAREA: 451.887,
    FIPS: 'MO187',
    fips: '29187',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 37.81055607,
    lng: -90.47255556,
  },
  {
    GEO_ID: '0500000US29197',
    STATE: '29',
    COUNTY: '197',
    NAME: 'Schuyler',
    LSAD: 'County',
    CENSUSAREA: 307.303,
    FIPS: 'MO197',
    fips: '29197',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 40.47023216,
    lng: -92.5207783,
  },
  {
    GEO_ID: '0500000US29203',
    STATE: '29',
    COUNTY: '203',
    NAME: 'Shannon',
    LSAD: 'County',
    CENSUSAREA: 1003.82,
    FIPS: 'MO203',
    fips: '29203',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 37.15713986,
    lng: -91.39993659,
  },
  {
    GEO_ID: '0500000US29207',
    STATE: '29',
    COUNTY: '207',
    NAME: 'Stoddard',
    LSAD: 'County',
    CENSUSAREA: 823.222,
    FIPS: 'MO207',
    fips: '29207',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 36.85565464,
    lng: -89.94436038,
  },
  {
    GEO_ID: '0500000US29213',
    STATE: '29',
    COUNTY: '213',
    NAME: 'Taney',
    LSAD: 'County',
    CENSUSAREA: 632.436,
    FIPS: 'MO213',
    fips: '29213',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 36.65473646,
    lng: -93.04127586,
  },
  {
    GEO_ID: '0500000US29219',
    STATE: '29',
    COUNTY: '219',
    NAME: 'Warren',
    LSAD: 'County',
    CENSUSAREA: 428.601,
    FIPS: 'MO219',
    fips: '29219',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 38.76446133,
    lng: -91.16024978,
  },
  {
    GEO_ID: '0500000US29225',
    STATE: '29',
    COUNTY: '225',
    NAME: 'Webster',
    LSAD: 'County',
    CENSUSAREA: 592.562,
    FIPS: 'MO225',
    fips: '29225',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 37.28085752,
    lng: -92.87591692,
  },
  {
    GEO_ID: '0500000US29510',
    STATE: '29',
    COUNTY: '510',
    NAME: 'St. Louis',
    LSAD: 'city',
    CENSUSAREA: 61.909,
    FIPS: 'MO510',
    fips: '29510',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 38.63654694,
    lng: -90.24484413,
  },
  {
    GEO_ID: '0500000US30003',
    STATE: '30',
    COUNTY: '003',
    NAME: 'Big Horn',
    LSAD: 'County',
    CENSUSAREA: 4995.462,
    FIPS: 'MT003',
    fips: '30003',
    StateAbbr: 'MT',
    StateName: 'Montana',
    lat: 45.42332459,
    lng: -107.4892228,
  },
  {
    GEO_ID: '0500000US37159',
    STATE: '37',
    COUNTY: '159',
    NAME: 'Rowan',
    LSAD: 'County',
    CENSUSAREA: 511.374,
    FIPS: 'NC159',
    fips: '37159',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 35.64030608,
    lng: -80.52501519,
  },
  {
    GEO_ID: '0500000US37163',
    STATE: '37',
    COUNTY: '163',
    NAME: 'Sampson',
    LSAD: 'County',
    CENSUSAREA: 944.74,
    FIPS: 'NC163',
    fips: '37163',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 34.99203592,
    lng: -78.37163083,
  },
  {
    GEO_ID: '0500000US37169',
    STATE: '37',
    COUNTY: '169',
    NAME: 'Stokes',
    LSAD: 'County',
    CENSUSAREA: 448.857,
    FIPS: 'NC169',
    fips: '37169',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 36.40186627,
    lng: -80.23912302,
  },
  {
    GEO_ID: '0500000US37175',
    STATE: '37',
    COUNTY: '175',
    NAME: 'Transylvania',
    LSAD: 'County',
    CENSUSAREA: 378.528,
    FIPS: 'NC175',
    fips: '37175',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 35.20208661,
    lng: -82.79867801,
  },
  {
    GEO_ID: '0500000US39061',
    STATE: '39',
    COUNTY: '061',
    NAME: 'Hamilton',
    LSAD: 'County',
    CENSUSAREA: 405.91,
    FIPS: 'OH061',
    fips: '39061',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 39.19557749,
    lng: -84.54309843,
  },
  {
    GEO_ID: '0500000US39071',
    STATE: '39',
    COUNTY: '071',
    NAME: 'Highland',
    LSAD: 'County',
    CENSUSAREA: 553.084,
    FIPS: 'OH071',
    fips: '39071',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 39.18468334,
    lng: -83.60078719,
  },
  {
    GEO_ID: '0500000US39077',
    STATE: '39',
    COUNTY: '077',
    NAME: 'Huron',
    LSAD: 'County',
    CENSUSAREA: 491.495,
    FIPS: 'OH077',
    fips: '39077',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 41.14651716,
    lng: -82.59842269,
  },
  {
    GEO_ID: '0500000US39081',
    STATE: '39',
    COUNTY: '081',
    NAME: 'Jefferson',
    LSAD: 'County',
    CENSUSAREA: 408.329,
    FIPS: 'OH081',
    fips: '39081',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 40.38470042,
    lng: -80.76113054,
  },
  {
    GEO_ID: '0500000US39087',
    STATE: '39',
    COUNTY: '087',
    NAME: 'Lawrence',
    LSAD: 'County',
    CENSUSAREA: 453.371,
    FIPS: 'OH087',
    fips: '39087',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 38.59849502,
    lng: -82.53675197,
  },
  {
    GEO_ID: '0500000US39093',
    STATE: '39',
    COUNTY: '093',
    NAME: 'Lorain',
    LSAD: 'County',
    CENSUSAREA: 491.101,
    FIPS: 'OH093',
    fips: '39093',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 41.29557931,
    lng: -82.1511144,
  },
  {
    GEO_ID: '0500000US39099',
    STATE: '39',
    COUNTY: '099',
    NAME: 'Mahoning',
    LSAD: 'County',
    CENSUSAREA: 411.623,
    FIPS: 'OH099',
    fips: '39099',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 41.01467607,
    lng: -80.7763711,
  },
  {
    GEO_ID: '0500000US39101',
    STATE: '39',
    COUNTY: '101',
    NAME: 'Marion',
    LSAD: 'County',
    CENSUSAREA: 403.757,
    FIPS: 'OH101',
    fips: '39101',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 40.58744702,
    lng: -83.1598587,
  },
  {
    GEO_ID: '0500000US44001',
    STATE: '44',
    COUNTY: '001',
    NAME: 'Bristol',
    LSAD: 'County',
    CENSUSAREA: 24.164,
    FIPS: 'RI001',
    fips: '44001',
    StateAbbr: 'RI',
    StateName: 'Rhode Island',
    lat: 41.71169465,
    lng: -71.28385194,
  },
  {
    GEO_ID: '0500000US44003',
    STATE: '44',
    COUNTY: '003',
    NAME: 'Kent',
    LSAD: 'County',
    CENSUSAREA: 168.528,
    FIPS: 'RI003',
    fips: '44003',
    StateAbbr: 'RI',
    StateName: 'Rhode Island',
    lat: 41.6720081,
    lng: -71.59305645,
  },
  {
    GEO_ID: '0500000US44005',
    STATE: '44',
    COUNTY: '005',
    NAME: 'Newport',
    LSAD: 'County',
    CENSUSAREA: 102.386,
    FIPS: 'RI005',
    fips: '44005',
    StateAbbr: 'RI',
    StateName: 'Rhode Island',
    lat: 41.55667416,
    lng: -71.23906841,
  },
  {
    GEO_ID: '0500000US45001',
    STATE: '45',
    COUNTY: '001',
    NAME: 'Abbeville',
    LSAD: 'County',
    CENSUSAREA: 490.484,
    FIPS: 'SC001',
    fips: '45001',
    StateAbbr: 'SC',
    StateName: 'South Carolina',
    lat: 34.22269468,
    lng: -82.45872709,
  },
  {
    GEO_ID: '0500000US45003',
    STATE: '45',
    COUNTY: '003',
    NAME: 'Aiken',
    LSAD: 'County',
    CENSUSAREA: 1071.034,
    FIPS: 'SC003',
    fips: '45003',
    StateAbbr: 'SC',
    StateName: 'South Carolina',
    lat: 33.544095,
    lng: -81.63493042,
  },
  {
    GEO_ID: '0500000US45007',
    STATE: '45',
    COUNTY: '007',
    NAME: 'Anderson',
    LSAD: 'County',
    CENSUSAREA: 715.426,
    FIPS: 'SC007',
    fips: '45007',
    StateAbbr: 'SC',
    StateName: 'South Carolina',
    lat: 34.5189668,
    lng: -82.63793115,
  },
  {
    GEO_ID: '0500000US45017',
    STATE: '45',
    COUNTY: '017',
    NAME: 'Calhoun',
    LSAD: 'County',
    CENSUSAREA: 381.151,
    FIPS: 'SC017',
    fips: '45017',
    StateAbbr: 'SC',
    StateName: 'South Carolina',
    lat: 33.67482869,
    lng: -80.78101991,
  },
  {
    GEO_ID: '0500000US45023',
    STATE: '45',
    COUNTY: '023',
    NAME: 'Chester',
    LSAD: 'County',
    CENSUSAREA: 580.657,
    FIPS: 'SC023',
    fips: '45023',
    StateAbbr: 'SC',
    StateName: 'South Carolina',
    lat: 34.69173842,
    lng: -81.15923162,
  },
  {
    GEO_ID: '0500000US45025',
    STATE: '45',
    COUNTY: '025',
    NAME: 'Chesterfield',
    LSAD: 'County',
    CENSUSAREA: 799.075,
    FIPS: 'SC025',
    fips: '45025',
    StateAbbr: 'SC',
    StateName: 'South Carolina',
    lat: 34.63979804,
    lng: -80.15867356,
  },
  {
    GEO_ID: '0500000US46063',
    STATE: '46',
    COUNTY: '063',
    NAME: 'Harding',
    LSAD: 'County',
    CENSUSAREA: 2671.375,
    FIPS: 'SD063',
    fips: '46063',
    StateAbbr: 'SD',
    StateName: 'South Dakota',
    lat: 45.58027156,
    lng: -103.4943846,
  },
  {
    GEO_ID: '0500000US46065',
    STATE: '46',
    COUNTY: '065',
    NAME: 'Hughes',
    LSAD: 'County',
    CENSUSAREA: 741.56,
    FIPS: 'SD065',
    fips: '46065',
    StateAbbr: 'SD',
    StateName: 'South Dakota',
    lat: 44.38906584,
    lng: -99.99599706,
  },
  {
    GEO_ID: '0500000US46069',
    STATE: '46',
    COUNTY: '069',
    NAME: 'Hyde',
    LSAD: 'County',
    CENSUSAREA: 860.524,
    FIPS: 'SD069',
    fips: '46069',
    StateAbbr: 'SD',
    StateName: 'South Dakota',
    lat: 44.54709825,
    lng: -99.48692012,
  },
  {
    GEO_ID: '0500000US46075',
    STATE: '46',
    COUNTY: '075',
    NAME: 'Jones',
    LSAD: 'County',
    CENSUSAREA: 969.678,
    FIPS: 'SD075',
    fips: '46075',
    StateAbbr: 'SD',
    StateName: 'South Dakota',
    lat: 43.96077423,
    lng: -100.6897488,
  },
  {
    GEO_ID: '0500000US46079',
    STATE: '46',
    COUNTY: '079',
    NAME: 'Lake',
    LSAD: 'County',
    CENSUSAREA: 563.277,
    FIPS: 'SD079',
    fips: '46079',
    StateAbbr: 'SD',
    StateName: 'South Dakota',
    lat: 44.02193107,
    lng: -97.12926365,
  },
  {
    GEO_ID: '0500000US46085',
    STATE: '46',
    COUNTY: '085',
    NAME: 'Lyman',
    LSAD: 'County',
    CENSUSAREA: 1641.936,
    FIPS: 'SD085',
    fips: '46085',
    StateAbbr: 'SD',
    StateName: 'South Dakota',
    lat: 43.89597621,
    lng: -99.84735699,
  },
  {
    GEO_ID: '0500000US46093',
    STATE: '46',
    COUNTY: '093',
    NAME: 'Meade',
    LSAD: 'County',
    CENSUSAREA: 3470.984,
    FIPS: 'SD093',
    fips: '46093',
    StateAbbr: 'SD',
    StateName: 'South Dakota',
    lat: 44.56667531,
    lng: -102.7166458,
  },
  {
    GEO_ID: '0500000US46097',
    STATE: '46',
    COUNTY: '097',
    NAME: 'Miner',
    LSAD: 'County',
    CENSUSAREA: 570.311,
    FIPS: 'SD097',
    fips: '46097',
    StateAbbr: 'SD',
    StateName: 'South Dakota',
    lat: 44.02197882,
    lng: -97.60992207,
  },
  {
    GEO_ID: '0500000US46101',
    STATE: '46',
    COUNTY: '101',
    NAME: 'Moody',
    LSAD: 'County',
    CENSUSAREA: 519.386,
    FIPS: 'SD101',
    fips: '46101',
    StateAbbr: 'SD',
    StateName: 'South Dakota',
    lat: 44.02224521,
    lng: -96.67091097,
  },
  {
    GEO_ID: '0500000US46113',
    STATE: '46',
    COUNTY: '113',
    NAME: 'Shannon',
    LSAD: 'County',
    CENSUSAREA: 2093.898,
    FIPS: 'SD113',
    fips: '46113',
    StateAbbr: 'SD',
    StateName: 'South Dakota',
    lat: 43.3357008,
    lng: -102.5516416,
  },
  {
    GEO_ID: '0500000US46115',
    STATE: '46',
    COUNTY: '115',
    NAME: 'Spink',
    LSAD: 'County',
    CENSUSAREA: 1503.925,
    FIPS: 'SD115',
    fips: '46115',
    StateAbbr: 'SD',
    StateName: 'South Dakota',
    lat: 44.93824835,
    lng: -98.34623659,
  },
  {
    GEO_ID: '0500000US46117',
    STATE: '46',
    COUNTY: '117',
    NAME: 'Stanley',
    LSAD: 'County',
    CENSUSAREA: 1444.432,
    FIPS: 'SD117',
    fips: '46117',
    StateAbbr: 'SD',
    StateName: 'South Dakota',
    lat: 44.41245198,
    lng: -100.7360867,
  },
  {
    GEO_ID: '0500000US46121',
    STATE: '46',
    COUNTY: '121',
    NAME: 'Todd',
    LSAD: 'County',
    CENSUSAREA: 1388.556,
    FIPS: 'SD121',
    fips: '46121',
    StateAbbr: 'SD',
    StateName: 'South Dakota',
    lat: 43.1933652,
    lng: -100.7186033,
  },
  {
    GEO_ID: '0500000US46127',
    STATE: '46',
    COUNTY: '127',
    NAME: 'Union',
    LSAD: 'County',
    CENSUSAREA: 460.542,
    FIPS: 'SD127',
    fips: '46127',
    StateAbbr: 'SD',
    StateName: 'South Dakota',
    lat: 42.83182818,
    lng: -96.65571268,
  },
  {
    GEO_ID: '0500000US30005',
    STATE: '30',
    COUNTY: '005',
    NAME: 'Blaine',
    LSAD: 'County',
    CENSUSAREA: 4227.546,
    FIPS: 'MT005',
    fips: '30005',
    StateAbbr: 'MT',
    StateName: 'Montana',
    lat: 48.43208097,
    lng: -108.9584002,
  },
  {
    GEO_ID: '0500000US30009',
    STATE: '30',
    COUNTY: '009',
    NAME: 'Carbon',
    LSAD: 'County',
    CENSUSAREA: 2048.788,
    FIPS: 'MT009',
    fips: '30009',
    StateAbbr: 'MT',
    StateName: 'Montana',
    lat: 45.22736746,
    lng: -109.0283184,
  },
  {
    GEO_ID: '0500000US30015',
    STATE: '30',
    COUNTY: '015',
    NAME: 'Chouteau',
    LSAD: 'County',
    CENSUSAREA: 3972.488,
    FIPS: 'MT015',
    fips: '30015',
    StateAbbr: 'MT',
    StateName: 'Montana',
    lat: 47.88028152,
    lng: -110.4356833,
  },
  {
    GEO_ID: '0500000US30019',
    STATE: '30',
    COUNTY: '019',
    NAME: 'Daniels',
    LSAD: 'County',
    CENSUSAREA: 1426.105,
    FIPS: 'MT019',
    fips: '30019',
    StateAbbr: 'MT',
    StateName: 'Montana',
    lat: 48.78377804,
    lng: -105.5486488,
  },
  {
    GEO_ID: '0500000US30023',
    STATE: '30',
    COUNTY: '023',
    NAME: 'Deer Lodge',
    LSAD: 'County',
    CENSUSAREA: 736.53,
    FIPS: 'MT023',
    fips: '30023',
    StateAbbr: 'MT',
    StateName: 'Montana',
    lat: 46.06059035,
    lng: -113.0672423,
  },
  {
    GEO_ID: '0500000US30029',
    STATE: '30',
    COUNTY: '029',
    NAME: 'Flathead',
    LSAD: 'County',
    CENSUSAREA: 5087.66,
    FIPS: 'MT029',
    fips: '30029',
    StateAbbr: 'MT',
    StateName: 'Montana',
    lat: 48.29520088,
    lng: -114.0497122,
  },
  {
    GEO_ID: '0500000US30035',
    STATE: '30',
    COUNTY: '035',
    NAME: 'Glacier',
    LSAD: 'County',
    CENSUSAREA: 2995.937,
    FIPS: 'MT035',
    fips: '30035',
    StateAbbr: 'MT',
    StateName: 'Montana',
    lat: 48.70514099,
    lng: -112.9959167,
  },
  {
    GEO_ID: '0500000US30041',
    STATE: '30',
    COUNTY: '041',
    NAME: 'Hill',
    LSAD: 'County',
    CENSUSAREA: 2898.955,
    FIPS: 'MT041',
    fips: '30041',
    StateAbbr: 'MT',
    StateName: 'Montana',
    lat: 48.62840165,
    lng: -110.1111845,
  },
  {
    GEO_ID: '0500000US31129',
    STATE: '31',
    COUNTY: '129',
    NAME: 'Nuckolls',
    LSAD: 'County',
    CENSUSAREA: 575.155,
    FIPS: 'NE129',
    fips: '31129',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 40.17636285,
    lng: -98.04739392,
  },
  {
    GEO_ID: '0500000US31133',
    STATE: '31',
    COUNTY: '133',
    NAME: 'Pawnee',
    LSAD: 'County',
    CENSUSAREA: 431.075,
    FIPS: 'NE133',
    fips: '31133',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 40.13139323,
    lng: -96.23706382,
  },
  {
    GEO_ID: '0500000US31141',
    STATE: '31',
    COUNTY: '141',
    NAME: 'Platte',
    LSAD: 'County',
    CENSUSAREA: 674.059,
    FIPS: 'NE141',
    fips: '31141',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 41.57115154,
    lng: -97.52073009,
  },
  {
    GEO_ID: '0500000US19007',
    STATE: '19',
    COUNTY: '007',
    NAME: 'Appanoose',
    LSAD: 'County',
    CENSUSAREA: 497.292,
    FIPS: 'IA007',
    fips: '19007',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 40.74323979,
    lng: -92.86861174,
  },
  {
    GEO_ID: '0500000US19011',
    STATE: '19',
    COUNTY: '011',
    NAME: 'Benton',
    LSAD: 'County',
    CENSUSAREA: 716.265,
    FIPS: 'IA011',
    fips: '19011',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 42.08011739,
    lng: -92.06463573,
  },
  {
    GEO_ID: '0500000US19015',
    STATE: '19',
    COUNTY: '015',
    NAME: 'Boone',
    LSAD: 'County',
    CENSUSAREA: 571.569,
    FIPS: 'IA015',
    fips: '19015',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 42.0366177,
    lng: -93.93139995,
  },
  {
    GEO_ID: '0500000US19019',
    STATE: '19',
    COUNTY: '019',
    NAME: 'Buchanan',
    LSAD: 'County',
    CENSUSAREA: 571.02,
    FIPS: 'IA019',
    fips: '19019',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 42.47108244,
    lng: -91.83745828,
  },
  {
    GEO_ID: '0500000US19023',
    STATE: '19',
    COUNTY: '023',
    NAME: 'Butler',
    LSAD: 'County',
    CENSUSAREA: 580.131,
    FIPS: 'IA023',
    fips: '19023',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 42.73172833,
    lng: -92.79015971,
  },
  {
    GEO_ID: '0500000US19027',
    STATE: '19',
    COUNTY: '027',
    NAME: 'Carroll',
    LSAD: 'County',
    CENSUSAREA: 569.437,
    FIPS: 'IA027',
    fips: '19027',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 42.03617207,
    lng: -94.86032705,
  },
  {
    GEO_ID: '0500000US19033',
    STATE: '19',
    COUNTY: '033',
    NAME: 'Cerro Gordo',
    LSAD: 'County',
    CENSUSAREA: 568.313,
    FIPS: 'IA033',
    fips: '19033',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 43.08177563,
    lng: -93.2612239,
  },
  {
    GEO_ID: '0500000US17081',
    STATE: '17',
    COUNTY: '081',
    NAME: 'Jefferson',
    LSAD: 'County',
    CENSUSAREA: 571.169,
    FIPS: 'IL081',
    fips: '17081',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 38.30019275,
    lng: -88.92397205,
  },
  {
    GEO_ID: '0500000US17091',
    STATE: '17',
    COUNTY: '091',
    NAME: 'Kankakee',
    LSAD: 'County',
    CENSUSAREA: 676.556,
    FIPS: 'IL091',
    fips: '17091',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 41.13747976,
    lng: -87.86189197,
  },
  {
    GEO_ID: '0500000US17093',
    STATE: '17',
    COUNTY: '093',
    NAME: 'Kendall',
    LSAD: 'County',
    CENSUSAREA: 320.335,
    FIPS: 'IL093',
    fips: '17093',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 41.59088826,
    lng: -88.42879892,
  },
  {
    GEO_ID: '0500000US17099',
    STATE: '17',
    COUNTY: '099',
    NAME: 'LaSalle',
    LSAD: 'County',
    CENSUSAREA: 1135.124,
    FIPS: 'IL099',
    fips: '17099',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 41.34406943,
    lng: -88.88623869,
  },
  {
    GEO_ID: '0500000US17101',
    STATE: '17',
    COUNTY: '101',
    NAME: 'Lawrence',
    LSAD: 'County',
    CENSUSAREA: 372.178,
    FIPS: 'IL101',
    fips: '17101',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 38.72048612,
    lng: -87.72698819,
  },
  {
    GEO_ID: '0500000US17105',
    STATE: '17',
    COUNTY: '105',
    NAME: 'Livingston',
    LSAD: 'County',
    CENSUSAREA: 1044.285,
    FIPS: 'IL105',
    fips: '17105',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 40.89167342,
    lng: -88.55786997,
  },
  {
    GEO_ID: '0500000US17109',
    STATE: '17',
    COUNTY: '109',
    NAME: 'McDonough',
    LSAD: 'County',
    CENSUSAREA: 589.407,
    FIPS: 'IL109',
    fips: '17109',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 40.45674722,
    lng: -90.67775541,
  },
  {
    GEO_ID: '0500000US17111',
    STATE: '17',
    COUNTY: '111',
    NAME: 'McHenry',
    LSAD: 'County',
    CENSUSAREA: 603.171,
    FIPS: 'IL111',
    fips: '17111',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 42.32444631,
    lng: -88.45263983,
  },
  {
    GEO_ID: '0500000US17117',
    STATE: '17',
    COUNTY: '117',
    NAME: 'Macoupin',
    LSAD: 'County',
    CENSUSAREA: 862.906,
    FIPS: 'IL117',
    fips: '17117',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 39.26136043,
    lng: -89.92459485,
  },
  {
    GEO_ID: '0500000US17125',
    STATE: '17',
    COUNTY: '125',
    NAME: 'Mason',
    LSAD: 'County',
    CENSUSAREA: 539.238,
    FIPS: 'IL125',
    fips: '17125',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 40.23934407,
    lng: -89.91665526,
  },
  {
    GEO_ID: '0500000US17127',
    STATE: '17',
    COUNTY: '127',
    NAME: 'Massac',
    LSAD: 'County',
    CENSUSAREA: 237.218,
    FIPS: 'IL127',
    fips: '17127',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 37.21923954,
    lng: -88.70754316,
  },
  {
    GEO_ID: '0500000US13185',
    STATE: '13',
    COUNTY: '185',
    NAME: 'Lowndes',
    LSAD: 'County',
    CENSUSAREA: 496.066,
    FIPS: 'GA185',
    fips: '13185',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 30.83391096,
    lng: -83.26738819,
  },
  {
    GEO_ID: '0500000US13189',
    STATE: '13',
    COUNTY: '189',
    NAME: 'McDuffie',
    LSAD: 'County',
    CENSUSAREA: 257.461,
    FIPS: 'GA189',
    fips: '13189',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 33.48313346,
    lng: -82.4818329,
  },
  {
    GEO_ID: '0500000US13195',
    STATE: '13',
    COUNTY: '195',
    NAME: 'Madison',
    LSAD: 'County',
    CENSUSAREA: 282.311,
    FIPS: 'GA195',
    fips: '13195',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 34.12798628,
    lng: -83.20833143,
  },
  {
    GEO_ID: '0500000US13199',
    STATE: '13',
    COUNTY: '199',
    NAME: 'Meriwether',
    LSAD: 'County',
    CENSUSAREA: 501.223,
    FIPS: 'GA199',
    fips: '13199',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 33.04118311,
    lng: -84.68849214,
  },
  {
    GEO_ID: '0500000US13205',
    STATE: '13',
    COUNTY: '205',
    NAME: 'Mitchell',
    LSAD: 'County',
    CENSUSAREA: 512.085,
    FIPS: 'GA205',
    fips: '13205',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 31.22513235,
    lng: -84.19466894,
  },
  {
    GEO_ID: '0500000US13209',
    STATE: '13',
    COUNTY: '209',
    NAME: 'Montgomery',
    LSAD: 'County',
    CENSUSAREA: 239.523,
    FIPS: 'GA209',
    fips: '13209',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 32.17325981,
    lng: -82.53452747,
  },
  {
    GEO_ID: '0500000US13213',
    STATE: '13',
    COUNTY: '213',
    NAME: 'Murray',
    LSAD: 'County',
    CENSUSAREA: 344.466,
    FIPS: 'GA213',
    fips: '13213',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 34.78861644,
    lng: -84.74799528,
  },
  {
    GEO_ID: '0500000US13221',
    STATE: '13',
    COUNTY: '221',
    NAME: 'Oglethorpe',
    LSAD: 'County',
    CENSUSAREA: 439.012,
    FIPS: 'GA221',
    fips: '13221',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 33.88064398,
    lng: -83.08144741,
  },
  {
    GEO_ID: '0500000US13229',
    STATE: '13',
    COUNTY: '229',
    NAME: 'Pierce',
    LSAD: 'County',
    CENSUSAREA: 316.494,
    FIPS: 'GA229',
    fips: '13229',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 31.35837358,
    lng: -82.2134274,
  },
  {
    GEO_ID: '0500000US13231',
    STATE: '13',
    COUNTY: '231',
    NAME: 'Pike',
    LSAD: 'County',
    CENSUSAREA: 216.086,
    FIPS: 'GA231',
    fips: '13231',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 33.09206899,
    lng: -84.38908484,
  },
  {
    GEO_ID: '0500000US13235',
    STATE: '13',
    COUNTY: '235',
    NAME: 'Pulaski',
    LSAD: 'County',
    CENSUSAREA: 249.031,
    FIPS: 'GA235',
    fips: '13235',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 32.23213302,
    lng: -83.47580638,
  },
  {
    GEO_ID: '0500000US13241',
    STATE: '13',
    COUNTY: '241',
    NAME: 'Rabun',
    LSAD: 'County',
    CENSUSAREA: 369.993,
    FIPS: 'GA241',
    fips: '13241',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 34.88165754,
    lng: -83.40264155,
  },
  {
    GEO_ID: '0500000US13247',
    STATE: '13',
    COUNTY: '247',
    NAME: 'Rockdale',
    LSAD: 'County',
    CENSUSAREA: 129.793,
    FIPS: 'GA247',
    fips: '13247',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 33.65414105,
    lng: -84.02657025,
  },
  {
    GEO_ID: '0500000US13255',
    STATE: '13',
    COUNTY: '255',
    NAME: 'Spalding',
    LSAD: 'County',
    CENSUSAREA: 196.469,
    FIPS: 'GA255',
    fips: '13255',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 33.26065533,
    lng: -84.28381621,
  },
  {
    GEO_ID: '0500000US13257',
    STATE: '13',
    COUNTY: '257',
    NAME: 'Stephens',
    LSAD: 'County',
    CENSUSAREA: 179.131,
    FIPS: 'GA257',
    fips: '13257',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 34.55397748,
    lng: -83.29303308,
  },
  {
    GEO_ID: '0500000US13267',
    STATE: '13',
    COUNTY: '267',
    NAME: 'Tattnall',
    LSAD: 'County',
    CENSUSAREA: 479.403,
    FIPS: 'GA267',
    fips: '13267',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 32.04578293,
    lng: -82.05794341,
  },
  {
    GEO_ID: '0500000US13269',
    STATE: '13',
    COUNTY: '269',
    NAME: 'Taylor',
    LSAD: 'County',
    CENSUSAREA: 376.686,
    FIPS: 'GA269',
    fips: '13269',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 32.55610793,
    lng: -84.25102911,
  },
  {
    GEO_ID: '0500000US20183',
    STATE: '20',
    COUNTY: '183',
    NAME: 'Smith',
    LSAD: 'County',
    CENSUSAREA: 895.465,
    FIPS: 'KS183',
    fips: '20183',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 39.78517466,
    lng: -98.78566877,
  },
  {
    GEO_ID: '0500000US20187',
    STATE: '20',
    COUNTY: '187',
    NAME: 'Stanton',
    LSAD: 'County',
    CENSUSAREA: 680.345,
    FIPS: 'KS187',
    fips: '20187',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 37.56304856,
    lng: -101.7842716,
  },
  {
    GEO_ID: '0500000US20189',
    STATE: '20',
    COUNTY: '189',
    NAME: 'Stevens',
    LSAD: 'County',
    CENSUSAREA: 727.293,
    FIPS: 'KS189',
    fips: '20189',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 37.19230822,
    lng: -101.3119048,
  },
  {
    GEO_ID: '0500000US20197',
    STATE: '20',
    COUNTY: '197',
    NAME: 'Wabaunsee',
    LSAD: 'County',
    CENSUSAREA: 794.302,
    FIPS: 'KS197',
    fips: '20197',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 38.95324678,
    lng: -96.20480435,
  },
  {
    GEO_ID: '0500000US20207',
    STATE: '20',
    COUNTY: '207',
    NAME: 'Woodson',
    LSAD: 'County',
    CENSUSAREA: 497.82,
    FIPS: 'KS207',
    fips: '20207',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 37.88668091,
    lng: -95.74071995,
  },
  {
    GEO_ID: '0500000US21191',
    STATE: '21',
    COUNTY: '191',
    NAME: 'Pendleton',
    LSAD: 'County',
    CENSUSAREA: 277.157,
    FIPS: 'KY191',
    fips: '21191',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 38.69570469,
    lng: -84.36004407,
  },
  {
    GEO_ID: '0500000US21193',
    STATE: '21',
    COUNTY: '193',
    NAME: 'Perry',
    LSAD: 'County',
    CENSUSAREA: 339.67,
    FIPS: 'KY193',
    fips: '21193',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 37.24414459,
    lng: -83.22147321,
  },
  {
    GEO_ID: '0500000US21201',
    STATE: '21',
    COUNTY: '201',
    NAME: 'Robertson',
    LSAD: 'County',
    CENSUSAREA: 99.911,
    FIPS: 'KY201',
    fips: '21201',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 38.51778393,
    lng: -84.0518799,
  },
  {
    GEO_ID: '0500000US21205',
    STATE: '21',
    COUNTY: '205',
    NAME: 'Rowan',
    LSAD: 'County',
    CENSUSAREA: 279.795,
    FIPS: 'KY205',
    fips: '21205',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 38.19625131,
    lng: -83.42085187,
  },
  {
    GEO_ID: '0500000US21207',
    STATE: '21',
    COUNTY: '207',
    NAME: 'Russell',
    LSAD: 'County',
    CENSUSAREA: 253.657,
    FIPS: 'KY207',
    fips: '21207',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 36.99115809,
    lng: -85.05838705,
  },
  {
    GEO_ID: '0500000US21217',
    STATE: '21',
    COUNTY: '217',
    NAME: 'Taylor',
    LSAD: 'County',
    CENSUSAREA: 266.327,
    FIPS: 'KY217',
    fips: '21217',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 37.36660665,
    lng: -85.32791474,
  },
  {
    GEO_ID: '0500000US21221',
    STATE: '21',
    COUNTY: '221',
    NAME: 'Trigg',
    LSAD: 'County',
    CENSUSAREA: 441.433,
    FIPS: 'KY221',
    fips: '21221',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 36.80638303,
    lng: -87.87327174,
  },
  {
    GEO_ID: '0500000US21231',
    STATE: '21',
    COUNTY: '231',
    NAME: 'Wayne',
    LSAD: 'County',
    CENSUSAREA: 458.17,
    FIPS: 'KY231',
    fips: '21231',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 36.80107637,
    lng: -84.8286438,
  },
  {
    GEO_ID: '0500000US21233',
    STATE: '21',
    COUNTY: '233',
    NAME: 'Webster',
    LSAD: 'County',
    CENSUSAREA: 331.943,
    FIPS: 'KY233',
    fips: '21233',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 37.51850288,
    lng: -87.68319621,
  },
  {
    GEO_ID: '0500000US21237',
    STATE: '21',
    COUNTY: '237',
    NAME: 'Wolfe',
    LSAD: 'County',
    CENSUSAREA: 222.171,
    FIPS: 'KY237',
    fips: '21237',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 37.73906786,
    lng: -83.49332409,
  },
  {
    GEO_ID: '0500000US21239',
    STATE: '21',
    COUNTY: '239',
    NAME: 'Woodford',
    LSAD: 'County',
    CENSUSAREA: 188.784,
    FIPS: 'KY239',
    fips: '21239',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 38.04257558,
    lng: -84.74341301,
  },
  {
    GEO_ID: '0500000US22005',
    STATE: '22',
    COUNTY: '005',
    NAME: 'Ascension',
    LSAD: 'Parish',
    CENSUSAREA: 289.981,
    FIPS: 'LA005',
    fips: '22005',
    StateAbbr: 'LA',
    StateName: 'Louisiana',
    lat: 30.20335229,
    lng: -90.91101939,
  },
  {
    GEO_ID: '0500000US22091',
    STATE: '22',
    COUNTY: '091',
    NAME: 'St. Helena',
    LSAD: 'Parish',
    CENSUSAREA: 408.403,
    FIPS: 'LA091',
    fips: '22091',
    StateAbbr: 'LA',
    StateName: 'Louisiana',
    lat: 30.82171866,
    lng: -90.71045886,
  },
  {
    GEO_ID: '0500000US22093',
    STATE: '22',
    COUNTY: '093',
    NAME: 'St. James',
    LSAD: 'Parish',
    CENSUSAREA: 241.537,
    FIPS: 'LA093',
    fips: '22093',
    StateAbbr: 'LA',
    StateName: 'Louisiana',
    lat: 30.02623427,
    lng: -90.79581459,
  },
  {
    GEO_ID: '0500000US22097',
    STATE: '22',
    COUNTY: '097',
    NAME: 'St. Landry',
    LSAD: 'Parish',
    CENSUSAREA: 923.879,
    FIPS: 'LA097',
    fips: '22097',
    StateAbbr: 'LA',
    StateName: 'Louisiana',
    lat: 30.59908084,
    lng: -92.00579532,
  },
  {
    GEO_ID: '0500000US22099',
    STATE: '22',
    COUNTY: '099',
    NAME: 'St. Martin',
    LSAD: 'Parish',
    CENSUSAREA: 737.648,
    FIPS: 'LA099',
    fips: '22099',
    StateAbbr: 'LA',
    StateName: 'Louisiana',
    lat: 30.12930997,
    lng: -91.60785079,
  },
  {
    GEO_ID: '0500000US22103',
    STATE: '22',
    COUNTY: '103',
    NAME: 'St. Tammany',
    LSAD: 'Parish',
    CENSUSAREA: 845.551,
    FIPS: 'LA103',
    fips: '22103',
    StateAbbr: 'LA',
    StateName: 'Louisiana',
    lat: 30.40987792,
    lng: -89.95737111,
  },
  {
    GEO_ID: '0500000US22113',
    STATE: '22',
    COUNTY: '113',
    NAME: 'Vermilion',
    LSAD: 'Parish',
    CENSUSAREA: 1173.199,
    FIPS: 'LA113',
    fips: '22113',
    StateAbbr: 'LA',
    StateName: 'Louisiana',
    lat: 29.84621938,
    lng: -92.32457583,
  },
  {
    GEO_ID: '0500000US22117',
    STATE: '22',
    COUNTY: '117',
    NAME: 'Washington',
    LSAD: 'Parish',
    CENSUSAREA: 669.525,
    FIPS: 'LA117',
    fips: '22117',
    StateAbbr: 'LA',
    StateName: 'Louisiana',
    lat: 30.85339081,
    lng: -90.04035989,
  },
  {
    GEO_ID: '0500000US21011',
    STATE: '21',
    COUNTY: '011',
    NAME: 'Bath',
    LSAD: 'County',
    CENSUSAREA: 278.792,
    FIPS: 'KY011',
    fips: '21011',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 38.14480167,
    lng: -83.74265272,
  },
  {
    GEO_ID: '0500000US21013',
    STATE: '21',
    COUNTY: '013',
    NAME: 'Bell',
    LSAD: 'County',
    CENSUSAREA: 359,
    FIPS: 'KY013',
    fips: '21013',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 36.73094679,
    lng: -83.67369514,
  },
  {
    GEO_ID: '0500000US21015',
    STATE: '21',
    COUNTY: '015',
    NAME: 'Boone',
    LSAD: 'County',
    CENSUSAREA: 246.359,
    FIPS: 'KY015',
    fips: '21015',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 38.96984373,
    lng: -84.72794553,
  },
  {
    GEO_ID: '0500000US21027',
    STATE: '21',
    COUNTY: '027',
    NAME: 'Breckinridge',
    LSAD: 'County',
    CENSUSAREA: 567.172,
    FIPS: 'KY027',
    fips: '21027',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 37.7732385,
    lng: -86.42936466,
  },
  {
    GEO_ID: '0500000US21029',
    STATE: '21',
    COUNTY: '029',
    NAME: 'Bullitt',
    LSAD: 'County',
    CENSUSAREA: 297.024,
    FIPS: 'KY029',
    fips: '21029',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 37.97000785,
    lng: -85.69808753,
  },
  {
    GEO_ID: '0500000US17133',
    STATE: '17',
    COUNTY: '133',
    NAME: 'Monroe',
    LSAD: 'County',
    CENSUSAREA: 385.011,
    FIPS: 'IL133',
    fips: '17133',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 38.27853951,
    lng: -90.17743157,
  },
  {
    GEO_ID: '0500000US17137',
    STATE: '17',
    COUNTY: '137',
    NAME: 'Morgan',
    LSAD: 'County',
    CENSUSAREA: 568.791,
    FIPS: 'IL137',
    fips: '17137',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 39.71580417,
    lng: -90.20121837,
  },
  {
    GEO_ID: '0500000US17141',
    STATE: '17',
    COUNTY: '141',
    NAME: 'Ogle',
    LSAD: 'County',
    CENSUSAREA: 758.574,
    FIPS: 'IL141',
    fips: '17141',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 42.04257451,
    lng: -89.32021067,
  },
  {
    GEO_ID: '0500000US17143',
    STATE: '17',
    COUNTY: '143',
    NAME: 'Peoria',
    LSAD: 'County',
    CENSUSAREA: 619.209,
    FIPS: 'IL143',
    fips: '17143',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 40.78797055,
    lng: -89.75986508,
  },
  {
    GEO_ID: '0500000US17147',
    STATE: '17',
    COUNTY: '147',
    NAME: 'Piatt',
    LSAD: 'County',
    CENSUSAREA: 439.202,
    FIPS: 'IL147',
    fips: '17147',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 40.01024723,
    lng: -88.59111102,
  },
  {
    GEO_ID: '0500000US17153',
    STATE: '17',
    COUNTY: '153',
    NAME: 'Pulaski',
    LSAD: 'County',
    CENSUSAREA: 199.185,
    FIPS: 'IL153',
    fips: '17153',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 37.22269456,
    lng: -89.12738626,
  },
  {
    GEO_ID: '0500000US17159',
    STATE: '17',
    COUNTY: '159',
    NAME: 'Richland',
    LSAD: 'County',
    CENSUSAREA: 359.99,
    FIPS: 'IL159',
    fips: '17159',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 38.71228739,
    lng: -88.08560224,
  },
  {
    GEO_ID: '0500000US17165',
    STATE: '17',
    COUNTY: '165',
    NAME: 'Saline',
    LSAD: 'County',
    CENSUSAREA: 379.819,
    FIPS: 'IL165',
    fips: '17165',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 37.75332999,
    lng: -88.54119706,
  },
  {
    GEO_ID: '0500000US17167',
    STATE: '17',
    COUNTY: '167',
    NAME: 'Sangamon',
    LSAD: 'County',
    CENSUSAREA: 868.302,
    FIPS: 'IL167',
    fips: '17167',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 39.75818327,
    lng: -89.65883945,
  },
  {
    GEO_ID: '0500000US17177',
    STATE: '17',
    COUNTY: '177',
    NAME: 'Stephenson',
    LSAD: 'County',
    CENSUSAREA: 564.523,
    FIPS: 'IL177',
    fips: '17177',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 42.35166558,
    lng: -89.66268151,
  },
  {
    GEO_ID: '0500000US17183',
    STATE: '17',
    COUNTY: '183',
    NAME: 'Vermilion',
    LSAD: 'County',
    CENSUSAREA: 898.368,
    FIPS: 'IL183',
    fips: '17183',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 40.18371674,
    lng: -87.73292603,
  },
  {
    GEO_ID: '0500000US17191',
    STATE: '17',
    COUNTY: '191',
    NAME: 'Wayne',
    LSAD: 'County',
    CENSUSAREA: 713.814,
    FIPS: 'IL191',
    fips: '17191',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 38.42955355,
    lng: -88.42586459,
  },
  {
    GEO_ID: '0500000US17195',
    STATE: '17',
    COUNTY: '195',
    NAME: 'Whiteside',
    LSAD: 'County',
    CENSUSAREA: 684.25,
    FIPS: 'IL195',
    fips: '17195',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 41.75598396,
    lng: -89.91362191,
  },
  {
    GEO_ID: '0500000US17197',
    STATE: '17',
    COUNTY: '197',
    NAME: 'Will',
    LSAD: 'County',
    CENSUSAREA: 836.908,
    FIPS: 'IL197',
    fips: '17197',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 41.44485087,
    lng: -87.97829555,
  },
  {
    GEO_ID: '0500000US18001',
    STATE: '18',
    COUNTY: '001',
    NAME: 'Adams',
    LSAD: 'County',
    CENSUSAREA: 339.028,
    FIPS: 'IN001',
    fips: '18001',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 40.7457744,
    lng: -84.93658249,
  },
  {
    GEO_ID: '0500000US13275',
    STATE: '13',
    COUNTY: '275',
    NAME: 'Thomas',
    LSAD: 'County',
    CENSUSAREA: 544.596,
    FIPS: 'GA275',
    fips: '13275',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 30.86359281,
    lng: -83.91853341,
  },
  {
    GEO_ID: '0500000US13279',
    STATE: '13',
    COUNTY: '279',
    NAME: 'Toombs',
    LSAD: 'County',
    CENSUSAREA: 364.005,
    FIPS: 'GA279',
    fips: '13279',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 32.12156708,
    lng: -82.33096121,
  },
  {
    GEO_ID: '0500000US13281',
    STATE: '13',
    COUNTY: '281',
    NAME: 'Towns',
    LSAD: 'County',
    CENSUSAREA: 166.563,
    FIPS: 'GA281',
    fips: '13281',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 34.91666684,
    lng: -83.73739141,
  },
  {
    GEO_ID: '0500000US13289',
    STATE: '13',
    COUNTY: '289',
    NAME: 'Twiggs',
    LSAD: 'County',
    CENSUSAREA: 358.398,
    FIPS: 'GA289',
    fips: '13289',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 32.66724559,
    lng: -83.42729345,
  },
  {
    GEO_ID: '0500000US13295',
    STATE: '13',
    COUNTY: '295',
    NAME: 'Walker',
    LSAD: 'County',
    CENSUSAREA: 446.379,
    FIPS: 'GA295',
    fips: '13295',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 34.73561638,
    lng: -85.30084531,
  },
  {
    GEO_ID: '0500000US13301',
    STATE: '13',
    COUNTY: '301',
    NAME: 'Warren',
    LSAD: 'County',
    CENSUSAREA: 284.298,
    FIPS: 'GA301',
    fips: '13301',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 33.40868082,
    lng: -82.67696672,
  },
  {
    GEO_ID: '0500000US20035',
    STATE: '20',
    COUNTY: '035',
    NAME: 'Cowley',
    LSAD: 'County',
    CENSUSAREA: 1125.75,
    FIPS: 'KS035',
    fips: '20035',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 37.23777562,
    lng: -96.83732848,
  },
  {
    GEO_ID: '0500000US20041',
    STATE: '20',
    COUNTY: '041',
    NAME: 'Dickinson',
    LSAD: 'County',
    CENSUSAREA: 847.072,
    FIPS: 'KS041',
    fips: '20041',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 38.86611825,
    lng: -97.15266036,
  },
  {
    GEO_ID: '0500000US20047',
    STATE: '20',
    COUNTY: '047',
    NAME: 'Edwards',
    LSAD: 'County',
    CENSUSAREA: 621.894,
    FIPS: 'KS047',
    fips: '20047',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 37.88763961,
    lng: -99.31204845,
  },
  {
    GEO_ID: '0500000US20051',
    STATE: '20',
    COUNTY: '051',
    NAME: 'Ellis',
    LSAD: 'County',
    CENSUSAREA: 899.912,
    FIPS: 'KS051',
    fips: '20051',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 38.91458712,
    lng: -99.31751551,
  },
  {
    GEO_ID: '0500000US20053',
    STATE: '20',
    COUNTY: '053',
    NAME: 'Ellsworth',
    LSAD: 'County',
    CENSUSAREA: 715.847,
    FIPS: 'KS053',
    fips: '20053',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 38.6967292,
    lng: -98.20501891,
  },
  {
    GEO_ID: '0500000US20057',
    STATE: '20',
    COUNTY: '057',
    NAME: 'Ford',
    LSAD: 'County',
    CENSUSAREA: 1098.271,
    FIPS: 'KS057',
    fips: '20057',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 37.69182131,
    lng: -99.88814767,
  },
  {
    GEO_ID: '0500000US20063',
    STATE: '20',
    COUNTY: '063',
    NAME: 'Gove',
    LSAD: 'County',
    CENSUSAREA: 1071.665,
    FIPS: 'KS063',
    fips: '20063',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 38.91587321,
    lng: -100.4829436,
  },
  {
    GEO_ID: '0500000US20073',
    STATE: '20',
    COUNTY: '073',
    NAME: 'Greenwood',
    LSAD: 'County',
    CENSUSAREA: 1143.3,
    FIPS: 'KS073',
    fips: '20073',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 37.87744513,
    lng: -96.23264864,
  },
  {
    GEO_ID: '0500000US20077',
    STATE: '20',
    COUNTY: '077',
    NAME: 'Harper',
    LSAD: 'County',
    CENSUSAREA: 801.269,
    FIPS: 'KS077',
    fips: '20077',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 37.1917471,
    lng: -98.07579639,
  },
  {
    GEO_ID: '0500000US20079',
    STATE: '20',
    COUNTY: '079',
    NAME: 'Harvey',
    LSAD: 'County',
    CENSUSAREA: 539.754,
    FIPS: 'KS079',
    fips: '20079',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 38.04321442,
    lng: -97.42729333,
  },
  {
    GEO_ID: '0500000US20087',
    STATE: '20',
    COUNTY: '087',
    NAME: 'Jefferson',
    LSAD: 'County',
    CENSUSAREA: 532.574,
    FIPS: 'KS087',
    fips: '20087',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 39.23530705,
    lng: -95.38360115,
  },
  {
    GEO_ID: '0500000US22011',
    STATE: '22',
    COUNTY: '011',
    NAME: 'Beauregard',
    LSAD: 'Parish',
    CENSUSAREA: 1157.342,
    FIPS: 'LA011',
    fips: '22011',
    StateAbbr: 'LA',
    StateName: 'Louisiana',
    lat: 30.64776149,
    lng: -93.3436646,
  },
  {
    GEO_ID: '0500000US22015',
    STATE: '22',
    COUNTY: '015',
    NAME: 'Bossier',
    LSAD: 'Parish',
    CENSUSAREA: 840.057,
    FIPS: 'LA015',
    fips: '22015',
    StateAbbr: 'LA',
    StateName: 'Louisiana',
    lat: 32.67882745,
    lng: -93.60428557,
  },
  {
    GEO_ID: '0500000US22017',
    STATE: '22',
    COUNTY: '017',
    NAME: 'Caddo',
    LSAD: 'Parish',
    CENSUSAREA: 878.539,
    FIPS: 'LA017',
    fips: '22017',
    StateAbbr: 'LA',
    StateName: 'Louisiana',
    lat: 32.5802177,
    lng: -93.88236667,
  },
  {
    GEO_ID: '0500000US22023',
    STATE: '22',
    COUNTY: '023',
    NAME: 'Cameron',
    LSAD: 'Parish',
    CENSUSAREA: 1284.887,
    FIPS: 'LA023',
    fips: '22023',
    StateAbbr: 'LA',
    StateName: 'Louisiana',
    lat: 29.87556075,
    lng: -93.19458209,
  },
  {
    GEO_ID: '0500000US22031',
    STATE: '22',
    COUNTY: '031',
    NAME: 'De Soto',
    LSAD: 'Parish',
    CENSUSAREA: 875.579,
    FIPS: 'LA031',
    fips: '22031',
    StateAbbr: 'LA',
    StateName: 'Louisiana',
    lat: 32.05510725,
    lng: -93.73783029,
  },
  {
    GEO_ID: '0500000US22035',
    STATE: '22',
    COUNTY: '035',
    NAME: 'East Carroll',
    LSAD: 'Parish',
    CENSUSAREA: 420.704,
    FIPS: 'LA035',
    fips: '22035',
    StateAbbr: 'LA',
    StateName: 'Louisiana',
    lat: 32.73239582,
    lng: -91.2350757,
  },
  {
    GEO_ID: '0500000US22041',
    STATE: '22',
    COUNTY: '041',
    NAME: 'Franklin',
    LSAD: 'Parish',
    CENSUSAREA: 624.585,
    FIPS: 'LA041',
    fips: '22041',
    StateAbbr: 'LA',
    StateName: 'Louisiana',
    lat: 32.13329548,
    lng: -91.67348178,
  },
  {
    GEO_ID: '0500000US22043',
    STATE: '22',
    COUNTY: '043',
    NAME: 'Grant',
    LSAD: 'Parish',
    CENSUSAREA: 643.026,
    FIPS: 'LA043',
    fips: '22043',
    StateAbbr: 'LA',
    StateName: 'Louisiana',
    lat: 31.59947973,
    lng: -92.55919242,
  },
  {
    GEO_ID: '0500000US22049',
    STATE: '22',
    COUNTY: '049',
    NAME: 'Jackson',
    LSAD: 'Parish',
    CENSUSAREA: 569.183,
    FIPS: 'LA049',
    fips: '22049',
    StateAbbr: 'LA',
    StateName: 'Louisiana',
    lat: 32.30210344,
    lng: -92.55780791,
  },
  {
    GEO_ID: '0500000US22051',
    STATE: '22',
    COUNTY: '051',
    NAME: 'Jefferson',
    LSAD: 'Parish',
    CENSUSAREA: 295.632,
    FIPS: 'LA051',
    fips: '22051',
    StateAbbr: 'LA',
    StateName: 'Louisiana',
    lat: 29.74131251,
    lng: -90.11275154,
  },
  {
    GEO_ID: '0500000US22055',
    STATE: '22',
    COUNTY: '055',
    NAME: 'Lafayette',
    LSAD: 'Parish',
    CENSUSAREA: 268.72,
    FIPS: 'LA055',
    fips: '22055',
    StateAbbr: 'LA',
    StateName: 'Louisiana',
    lat: 30.20738593,
    lng: -92.0636004,
  },
  {
    GEO_ID: '0500000US21035',
    STATE: '21',
    COUNTY: '035',
    NAME: 'Calloway',
    LSAD: 'County',
    CENSUSAREA: 385.021,
    FIPS: 'KY035',
    fips: '21035',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 36.62088413,
    lng: -88.27216962,
  },
  {
    GEO_ID: '0500000US21039',
    STATE: '21',
    COUNTY: '039',
    NAME: 'Carlisle',
    LSAD: 'County',
    CENSUSAREA: 189.434,
    FIPS: 'KY039',
    fips: '21039',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 36.85297172,
    lng: -88.97055515,
  },
  {
    GEO_ID: '0500000US21041',
    STATE: '21',
    COUNTY: '041',
    NAME: 'Carroll',
    LSAD: 'County',
    CENSUSAREA: 128.569,
    FIPS: 'KY041',
    fips: '21041',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 38.66814643,
    lng: -85.12373313,
  },
  {
    GEO_ID: '0500000US21045',
    STATE: '21',
    COUNTY: '045',
    NAME: 'Casey',
    LSAD: 'County',
    CENSUSAREA: 444.23,
    FIPS: 'KY045',
    fips: '21045',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 37.32208002,
    lng: -84.92860405,
  },
  {
    GEO_ID: '0500000US21057',
    STATE: '21',
    COUNTY: '057',
    NAME: 'Cumberland',
    LSAD: 'County',
    CENSUSAREA: 305.182,
    FIPS: 'KY057',
    fips: '21057',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 36.78661047,
    lng: -85.38857248,
  },
  {
    GEO_ID: '0500000US21065',
    STATE: '21',
    COUNTY: '065',
    NAME: 'Estill',
    LSAD: 'County',
    CENSUSAREA: 253.078,
    FIPS: 'KY065',
    fips: '21065',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 37.6925357,
    lng: -83.96441737,
  },
  {
    GEO_ID: '0500000US21067',
    STATE: '21',
    COUNTY: '067',
    NAME: 'Fayette',
    LSAD: 'County',
    CENSUSAREA: 283.649,
    FIPS: 'KY067',
    fips: '21067',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 38.04287124,
    lng: -84.4589719,
  },
  {
    GEO_ID: '0500000US21071',
    STATE: '21',
    COUNTY: '071',
    NAME: 'Floyd',
    LSAD: 'County',
    CENSUSAREA: 393.345,
    FIPS: 'KY071',
    fips: '21071',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 37.55729538,
    lng: -82.74545562,
  },
  {
    GEO_ID: '0500000US21079',
    STATE: '21',
    COUNTY: '079',
    NAME: 'Garrard',
    LSAD: 'County',
    CENSUSAREA: 230.077,
    FIPS: 'KY079',
    fips: '21079',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 37.63999075,
    lng: -84.53826255,
  },
  {
    GEO_ID: '0500000US21083',
    STATE: '21',
    COUNTY: '083',
    NAME: 'Graves',
    LSAD: 'County',
    CENSUSAREA: 551.743,
    FIPS: 'KY083',
    fips: '21083',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 36.72303717,
    lng: -88.65114084,
  },
  {
    GEO_ID: '0500000US21085',
    STATE: '21',
    COUNTY: '085',
    NAME: 'Grayson',
    LSAD: 'County',
    CENSUSAREA: 496.698,
    FIPS: 'KY085',
    fips: '21085',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 37.46094872,
    lng: -86.34376166,
  },
  {
    GEO_ID: '0500000US21091',
    STATE: '21',
    COUNTY: '091',
    NAME: 'Hancock',
    LSAD: 'County',
    CENSUSAREA: 187.652,
    FIPS: 'KY091',
    fips: '21091',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 37.84153525,
    lng: -86.77804483,
  },
  {
    GEO_ID: '0500000US21097',
    STATE: '21',
    COUNTY: '097',
    NAME: 'Harrison',
    LSAD: 'County',
    CENSUSAREA: 306.364,
    FIPS: 'KY097',
    fips: '21097',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 38.44203171,
    lng: -84.33134658,
  },
  {
    GEO_ID: '0500000US21101',
    STATE: '21',
    COUNTY: '101',
    NAME: 'Henderson',
    LSAD: 'County',
    CENSUSAREA: 436.669,
    FIPS: 'KY101',
    fips: '21101',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 37.79602818,
    lng: -87.57345471,
  },
  {
    GEO_ID: '0500000US21107',
    STATE: '21',
    COUNTY: '107',
    NAME: 'Hopkins',
    LSAD: 'County',
    CENSUSAREA: 541.995,
    FIPS: 'KY107',
    fips: '21107',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 37.30862563,
    lng: -87.54131507,
  },
  {
    GEO_ID: '0500000US48267',
    STATE: '48',
    COUNTY: '267',
    NAME: 'Kimble',
    LSAD: 'County',
    CENSUSAREA: 1250.987,
    FIPS: 'TX267',
    fips: '48267',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 30.48678918,
    lng: -99.74856473,
  },
  {
    GEO_ID: '0500000US48271',
    STATE: '48',
    COUNTY: '271',
    NAME: 'Kinney',
    LSAD: 'County',
    CENSUSAREA: 1360.055,
    FIPS: 'TX271',
    fips: '48271',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 29.35014052,
    lng: -100.4179055,
  },
  {
    GEO_ID: '0500000US48277',
    STATE: '48',
    COUNTY: '277',
    NAME: 'Lamar',
    LSAD: 'County',
    CENSUSAREA: 907.19,
    FIPS: 'TX277',
    fips: '48277',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 33.6672152,
    lng: -95.57108168,
  },
  {
    GEO_ID: '0500000US48281',
    STATE: '48',
    COUNTY: '281',
    NAME: 'Lampasas',
    LSAD: 'County',
    CENSUSAREA: 712.84,
    FIPS: 'TX281',
    fips: '48281',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 31.19624807,
    lng: -98.2416756,
  },
  {
    GEO_ID: '0500000US48285',
    STATE: '48',
    COUNTY: '285',
    NAME: 'Lavaca',
    LSAD: 'County',
    CENSUSAREA: 969.708,
    FIPS: 'TX285',
    fips: '48285',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 29.38395052,
    lng: -96.93033343,
  },
  {
    GEO_ID: '0500000US48287',
    STATE: '48',
    COUNTY: '287',
    NAME: 'Lee',
    LSAD: 'County',
    CENSUSAREA: 629.016,
    FIPS: 'TX287',
    fips: '48287',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 30.31077667,
    lng: -96.96605425,
  },
  {
    GEO_ID: '0500000US48291',
    STATE: '48',
    COUNTY: '291',
    NAME: 'Liberty',
    LSAD: 'County',
    CENSUSAREA: 1158.417,
    FIPS: 'TX291',
    fips: '48291',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 30.15161146,
    lng: -94.81225285,
  },
  {
    GEO_ID: '0500000US48293',
    STATE: '48',
    COUNTY: '293',
    NAME: 'Limestone',
    LSAD: 'County',
    CENSUSAREA: 905.288,
    FIPS: 'TX293',
    fips: '48293',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 31.5454767,
    lng: -96.58095444,
  },
  {
    GEO_ID: '0500000US48295',
    STATE: '48',
    COUNTY: '295',
    NAME: 'Lipscomb',
    LSAD: 'County',
    CENSUSAREA: 932.179,
    FIPS: 'TX295',
    fips: '48295',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 36.27778375,
    lng: -100.2732972,
  },
  {
    GEO_ID: '0500000US48301',
    STATE: '48',
    COUNTY: '301',
    NAME: 'Loving',
    LSAD: 'County',
    CENSUSAREA: 668.925,
    FIPS: 'TX301',
    fips: '48301',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 31.8491553,
    lng: -103.5798367,
  },
  {
    GEO_ID: '0500000US48305',
    STATE: '48',
    COUNTY: '305',
    NAME: 'Lynn',
    LSAD: 'County',
    CENSUSAREA: 891.867,
    FIPS: 'TX305',
    fips: '48305',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 33.17657705,
    lng: -101.8162849,
  },
  {
    GEO_ID: '0500000US48307',
    STATE: '48',
    COUNTY: '307',
    NAME: 'McCulloch',
    LSAD: 'County',
    CENSUSAREA: 1065.598,
    FIPS: 'TX307',
    fips: '48307',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 31.1988896,
    lng: -99.34743941,
  },
  {
    GEO_ID: '0500000US48317',
    STATE: '48',
    COUNTY: '317',
    NAME: 'Martin',
    LSAD: 'County',
    CENSUSAREA: 914.944,
    FIPS: 'TX317',
    fips: '48317',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 32.30600051,
    lng: -101.9513518,
  },
  {
    GEO_ID: '0500000US48323',
    STATE: '48',
    COUNTY: '323',
    NAME: 'Maverick',
    LSAD: 'County',
    CENSUSAREA: 1279.258,
    FIPS: 'TX323',
    fips: '48323',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 28.74262555,
    lng: -100.3147083,
  },
  {
    GEO_ID: '0500000US48327',
    STATE: '48',
    COUNTY: '327',
    NAME: 'Menard',
    LSAD: 'County',
    CENSUSAREA: 902.03,
    FIPS: 'TX327',
    fips: '48327',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 30.88990665,
    lng: -99.8204794,
  },
  {
    GEO_ID: '0500000US48331',
    STATE: '48',
    COUNTY: '331',
    NAME: 'Milam',
    LSAD: 'County',
    CENSUSAREA: 1016.93,
    FIPS: 'TX331',
    fips: '48331',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 30.7861553,
    lng: -96.97728156,
  },
  {
    GEO_ID: '0500000US48333',
    STATE: '48',
    COUNTY: '333',
    NAME: 'Mills',
    LSAD: 'County',
    CENSUSAREA: 748.261,
    FIPS: 'TX333',
    fips: '48333',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 31.49516229,
    lng: -98.59562176,
  },
  {
    GEO_ID: '0500000US51800',
    STATE: '51',
    COUNTY: '800',
    NAME: 'Suffolk',
    LSAD: 'city',
    CENSUSAREA: 400.168,
    FIPS: 'VA800',
    fips: '51800',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 36.69549937,
    lng: -76.63944938,
  },
  {
    GEO_ID: '0500000US51810',
    STATE: '51',
    COUNTY: '810',
    NAME: 'Virginia Beach',
    LSAD: 'city',
    CENSUSAREA: 249.016,
    FIPS: 'VA810',
    fips: '51810',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 36.73349545,
    lng: -76.04367462,
  },
  {
    GEO_ID: '0500000US51840',
    STATE: '51',
    COUNTY: '840',
    NAME: 'Winchester',
    LSAD: 'city',
    CENSUSAREA: 9.233,
    FIPS: 'VA840',
    fips: '51840',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 39.17278399,
    lng: -78.17299993,
  },
  {
    GEO_ID: '0500000US53001',
    STATE: '53',
    COUNTY: '001',
    NAME: 'Adams',
    LSAD: 'County',
    CENSUSAREA: 1924.978,
    FIPS: 'WA001',
    fips: '53001',
    StateAbbr: 'WA',
    StateName: 'Washington',
    lat: 46.98352425,
    lng: -118.5603559,
  },
  {
    GEO_ID: '0500000US53003',
    STATE: '53',
    COUNTY: '003',
    NAME: 'Asotin',
    LSAD: 'County',
    CENSUSAREA: 636.213,
    FIPS: 'WA003',
    fips: '53003',
    StateAbbr: 'WA',
    StateName: 'Washington',
    lat: 46.19177089,
    lng: -117.2027907,
  },
  {
    GEO_ID: '0500000US53005',
    STATE: '53',
    COUNTY: '005',
    NAME: 'Benton',
    LSAD: 'County',
    CENSUSAREA: 1700.379,
    FIPS: 'WA005',
    fips: '53005',
    StateAbbr: 'WA',
    StateName: 'Washington',
    lat: 46.23976031,
    lng: -119.5113335,
  },
  {
    GEO_ID: '0500000US53015',
    STATE: '53',
    COUNTY: '015',
    NAME: 'Cowlitz',
    LSAD: 'County',
    CENSUSAREA: 1140.125,
    FIPS: 'WA015',
    fips: '53015',
    StateAbbr: 'WA',
    StateName: 'Washington',
    lat: 46.19321499,
    lng: -122.6802732,
  },
  {
    GEO_ID: '0500000US53017',
    STATE: '53',
    COUNTY: '017',
    NAME: 'Douglas',
    LSAD: 'County',
    CENSUSAREA: 1819.264,
    FIPS: 'WA017',
    fips: '53017',
    StateAbbr: 'WA',
    StateName: 'Washington',
    lat: 47.73589576,
    lng: -119.6917069,
  },
  {
    GEO_ID: '0500000US53019',
    STATE: '53',
    COUNTY: '019',
    NAME: 'Ferry',
    LSAD: 'County',
    CENSUSAREA: 2203.159,
    FIPS: 'WA019',
    fips: '53019',
    StateAbbr: 'WA',
    StateName: 'Washington',
    lat: 48.47039666,
    lng: -118.5163244,
  },
  {
    GEO_ID: '0500000US53023',
    STATE: '53',
    COUNTY: '023',
    NAME: 'Garfield',
    LSAD: 'County',
    CENSUSAREA: 710.686,
    FIPS: 'WA023',
    fips: '53023',
    StateAbbr: 'WA',
    StateName: 'Washington',
    lat: 46.43130985,
    lng: -117.5446476,
  },
  {
    GEO_ID: '0500000US53025',
    STATE: '53',
    COUNTY: '025',
    NAME: 'Grant',
    LSAD: 'County',
    CENSUSAREA: 2679.513,
    FIPS: 'WA025',
    fips: '53025',
    StateAbbr: 'WA',
    StateName: 'Washington',
    lat: 47.20582642,
    lng: -119.4513964,
  },
  {
    GEO_ID: '0500000US51600',
    STATE: '51',
    COUNTY: '600',
    NAME: 'Fairfax',
    LSAD: 'city',
    CENSUSAREA: 6.239,
    FIPS: 'VA600',
    fips: '51600',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 38.8534472,
    lng: -77.29836489,
  },
  {
    GEO_ID: '0500000US55101',
    STATE: '55',
    COUNTY: '101',
    NAME: 'Racine',
    LSAD: 'County',
    CENSUSAREA: 332.501,
    FIPS: 'WI101',
    fips: '55101',
    StateAbbr: 'WI',
    StateName: 'Wisconsin',
    lat: 42.74731284,
    lng: -88.06111861,
  },
  {
    GEO_ID: '0500000US55107',
    STATE: '55',
    COUNTY: '107',
    NAME: 'Rusk',
    LSAD: 'County',
    CENSUSAREA: 913.585,
    FIPS: 'WI107',
    fips: '55107',
    StateAbbr: 'WI',
    StateName: 'Wisconsin',
    lat: 45.47489408,
    lng: -91.1333376,
  },
  {
    GEO_ID: '0500000US55111',
    STATE: '55',
    COUNTY: '111',
    NAME: 'Sauk',
    LSAD: 'County',
    CENSUSAREA: 830.902,
    FIPS: 'WI111',
    fips: '55111',
    StateAbbr: 'WI',
    StateName: 'Wisconsin',
    lat: 43.42667177,
    lng: -89.94819801,
  },
  {
    GEO_ID: '0500000US55113',
    STATE: '55',
    COUNTY: '113',
    NAME: 'Sawyer',
    LSAD: 'County',
    CENSUSAREA: 1257.305,
    FIPS: 'WI113',
    fips: '55113',
    StateAbbr: 'WI',
    StateName: 'Wisconsin',
    lat: 45.87995292,
    lng: -91.14489019,
  },
  {
    GEO_ID: '0500000US55119',
    STATE: '55',
    COUNTY: '119',
    NAME: 'Taylor',
    LSAD: 'County',
    CENSUSAREA: 974.876,
    FIPS: 'WI119',
    fips: '55119',
    StateAbbr: 'WI',
    StateName: 'Wisconsin',
    lat: 45.21158608,
    lng: -90.50164471,
  },
  {
    GEO_ID: '0500000US55123',
    STATE: '55',
    COUNTY: '123',
    NAME: 'Vernon',
    LSAD: 'County',
    CENSUSAREA: 791.579,
    FIPS: 'WI123',
    fips: '55123',
    StateAbbr: 'WI',
    StateName: 'Wisconsin',
    lat: 43.59402052,
    lng: -90.83463661,
  },
  {
    GEO_ID: '0500000US55125',
    STATE: '55',
    COUNTY: '125',
    NAME: 'Vilas',
    LSAD: 'County',
    CENSUSAREA: 856.604,
    FIPS: 'WI125',
    fips: '55125',
    StateAbbr: 'WI',
    StateName: 'Wisconsin',
    lat: 46.05275508,
    lng: -89.51508258,
  },
  {
    GEO_ID: '0500000US55133',
    STATE: '55',
    COUNTY: '133',
    NAME: 'Waukesha',
    LSAD: 'County',
    CENSUSAREA: 549.574,
    FIPS: 'WI133',
    fips: '55133',
    StateAbbr: 'WI',
    StateName: 'Wisconsin',
    lat: 43.01833546,
    lng: -88.30430125,
  },
  {
    GEO_ID: '0500000US55139',
    STATE: '55',
    COUNTY: '139',
    NAME: 'Winnebago',
    LSAD: 'County',
    CENSUSAREA: 434.487,
    FIPS: 'WI139',
    fips: '55139',
    StateAbbr: 'WI',
    StateName: 'Wisconsin',
    lat: 44.06886922,
    lng: -88.64477096,
  },
  {
    GEO_ID: '0500000US56003',
    STATE: '56',
    COUNTY: '003',
    NAME: 'Big Horn',
    LSAD: 'County',
    CENSUSAREA: 3137.097,
    FIPS: 'WY003',
    fips: '56003',
    StateAbbr: 'WY',
    StateName: 'Wyoming',
    lat: 44.52636084,
    lng: -107.9948912,
  },
  {
    GEO_ID: '0500000US56009',
    STATE: '56',
    COUNTY: '009',
    NAME: 'Converse',
    LSAD: 'County',
    CENSUSAREA: 4254.883,
    FIPS: 'WY009',
    fips: '56009',
    StateAbbr: 'WY',
    StateName: 'Wyoming',
    lat: 42.9722826,
    lng: -105.5077277,
  },
  {
    GEO_ID: '0500000US56017',
    STATE: '56',
    COUNTY: '017',
    NAME: 'Hot Springs',
    LSAD: 'County',
    CENSUSAREA: 2004.092,
    FIPS: 'WY017',
    fips: '56017',
    StateAbbr: 'WY',
    StateName: 'Wyoming',
    lat: 43.71897841,
    lng: -108.4422853,
  },
  {
    GEO_ID: '0500000US56019',
    STATE: '56',
    COUNTY: '019',
    NAME: 'Johnson',
    LSAD: 'County',
    CENSUSAREA: 4154.153,
    FIPS: 'WY019',
    fips: '56019',
    StateAbbr: 'WY',
    StateName: 'Wyoming',
    lat: 44.03996832,
    lng: -106.5849297,
  },
  {
    GEO_ID: '0500000US54023',
    STATE: '54',
    COUNTY: '023',
    NAME: 'Grant',
    LSAD: 'County',
    CENSUSAREA: 477.373,
    FIPS: 'WV023',
    fips: '54023',
    StateAbbr: 'WV',
    StateName: 'West Virginia',
    lat: 39.10496268,
    lng: -79.19584305,
  },
  {
    GEO_ID: '0500000US54025',
    STATE: '54',
    COUNTY: '025',
    NAME: 'Greenbrier',
    LSAD: 'County',
    CENSUSAREA: 1019.569,
    FIPS: 'WV025',
    fips: '54025',
    StateAbbr: 'WV',
    StateName: 'West Virginia',
    lat: 37.94721189,
    lng: -80.45282888,
  },
  {
    GEO_ID: '0500000US54027',
    STATE: '54',
    COUNTY: '027',
    NAME: 'Hampshire',
    LSAD: 'County',
    CENSUSAREA: 640.249,
    FIPS: 'WV027',
    fips: '54027',
    StateAbbr: 'WV',
    StateName: 'West Virginia',
    lat: 39.31712105,
    lng: -78.61389875,
  },
  {
    GEO_ID: '0500000US54033',
    STATE: '54',
    COUNTY: '033',
    NAME: 'Harrison',
    LSAD: 'County',
    CENSUSAREA: 416.008,
    FIPS: 'WV033',
    fips: '54033',
    StateAbbr: 'WV',
    StateName: 'West Virginia',
    lat: 39.28363307,
    lng: -80.37983086,
  },
  {
    GEO_ID: '0500000US54037',
    STATE: '54',
    COUNTY: '037',
    NAME: 'Jefferson',
    LSAD: 'County',
    CENSUSAREA: 209.635,
    FIPS: 'WV037',
    fips: '54037',
    StateAbbr: 'WV',
    StateName: 'West Virginia',
    lat: 39.30849565,
    lng: -77.86242458,
  },
  {
    GEO_ID: '0500000US54045',
    STATE: '54',
    COUNTY: '045',
    NAME: 'Logan',
    LSAD: 'County',
    CENSUSAREA: 453.737,
    FIPS: 'WV045',
    fips: '54045',
    StateAbbr: 'WV',
    StateName: 'West Virginia',
    lat: 37.8319353,
    lng: -81.9351906,
  },
  {
    GEO_ID: '0500000US54051',
    STATE: '54',
    COUNTY: '051',
    NAME: 'Marshall',
    LSAD: 'County',
    CENSUSAREA: 305.431,
    FIPS: 'WV051',
    fips: '54051',
    StateAbbr: 'WV',
    StateName: 'West Virginia',
    lat: 39.86038842,
    lng: -80.66340417,
  },
  {
    GEO_ID: '0500000US54053',
    STATE: '54',
    COUNTY: '053',
    NAME: 'Mason',
    LSAD: 'County',
    CENSUSAREA: 430.748,
    FIPS: 'WV053',
    fips: '54053',
    StateAbbr: 'WV',
    StateName: 'West Virginia',
    lat: 38.7698088,
    lng: -82.02651429,
  },
  {
    GEO_ID: '0500000US54059',
    STATE: '54',
    COUNTY: '059',
    NAME: 'Mingo',
    LSAD: 'County',
    CENSUSAREA: 423.109,
    FIPS: 'WV059',
    fips: '54059',
    StateAbbr: 'WV',
    StateName: 'West Virginia',
    lat: 37.72729194,
    lng: -82.13519084,
  },
  {
    GEO_ID: '0500000US54065',
    STATE: '54',
    COUNTY: '065',
    NAME: 'Morgan',
    LSAD: 'County',
    CENSUSAREA: 229.073,
    FIPS: 'WV065',
    fips: '54065',
    StateAbbr: 'WV',
    StateName: 'West Virginia',
    lat: 39.5606152,
    lng: -78.25701555,
  },
  {
    GEO_ID: '0500000US54069',
    STATE: '54',
    COUNTY: '069',
    NAME: 'Ohio',
    LSAD: 'County',
    CENSUSAREA: 105.82,
    FIPS: 'WV069',
    fips: '54069',
    StateAbbr: 'WV',
    StateName: 'West Virginia',
    lat: 40.09682683,
    lng: -80.61888538,
  },
  {
    GEO_ID: '0500000US54075',
    STATE: '54',
    COUNTY: '075',
    NAME: 'Pocahontas',
    LSAD: 'County',
    CENSUSAREA: 940.284,
    FIPS: 'WV075',
    fips: '54075',
    StateAbbr: 'WV',
    StateName: 'West Virginia',
    lat: 38.33190977,
    lng: -80.00762338,
  },
  {
    GEO_ID: '0500000US48341',
    STATE: '48',
    COUNTY: '341',
    NAME: 'Moore',
    LSAD: 'County',
    CENSUSAREA: 899.691,
    FIPS: 'TX341',
    fips: '48341',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 35.83769242,
    lng: -101.8930281,
  },
  {
    GEO_ID: '0500000US54083',
    STATE: '54',
    COUNTY: '083',
    NAME: 'Randolph',
    LSAD: 'County',
    CENSUSAREA: 1039.681,
    FIPS: 'WV083',
    fips: '54083',
    StateAbbr: 'WV',
    StateName: 'West Virginia',
    lat: 38.77500824,
    lng: -79.87549565,
  },
  {
    GEO_ID: '0500000US54087',
    STATE: '54',
    COUNTY: '087',
    NAME: 'Roane',
    LSAD: 'County',
    CENSUSAREA: 483.563,
    FIPS: 'WV087',
    fips: '54087',
    StateAbbr: 'WV',
    StateName: 'West Virginia',
    lat: 38.71393058,
    lng: -81.34822568,
  },
  {
    GEO_ID: '0500000US54091',
    STATE: '54',
    COUNTY: '091',
    NAME: 'Taylor',
    LSAD: 'County',
    CENSUSAREA: 172.771,
    FIPS: 'WV091',
    fips: '54091',
    StateAbbr: 'WV',
    StateName: 'West Virginia',
    lat: 39.33588922,
    lng: -80.04598163,
  },
  {
    GEO_ID: '0500000US54093',
    STATE: '54',
    COUNTY: '093',
    NAME: 'Tucker',
    LSAD: 'County',
    CENSUSAREA: 418.922,
    FIPS: 'WV093',
    fips: '54093',
    StateAbbr: 'WV',
    StateName: 'West Virginia',
    lat: 39.11375036,
    lng: -79.56567428,
  },
  {
    GEO_ID: '0500000US54097',
    STATE: '54',
    COUNTY: '097',
    NAME: 'Upshur',
    LSAD: 'County',
    CENSUSAREA: 354.638,
    FIPS: 'WV097',
    fips: '54097',
    StateAbbr: 'WV',
    StateName: 'West Virginia',
    lat: 38.8978057,
    lng: -80.23353247,
  },
  {
    GEO_ID: '0500000US54099',
    STATE: '54',
    COUNTY: '099',
    NAME: 'Wayne',
    LSAD: 'County',
    CENSUSAREA: 505.981,
    FIPS: 'WV099',
    fips: '54099',
    StateAbbr: 'WV',
    StateName: 'West Virginia',
    lat: 38.14585251,
    lng: -82.42685819,
  },
  {
    GEO_ID: '0500000US54103',
    STATE: '54',
    COUNTY: '103',
    NAME: 'Wetzel',
    LSAD: 'County',
    CENSUSAREA: 358.063,
    FIPS: 'WV103',
    fips: '54103',
    StateAbbr: 'WV',
    StateName: 'West Virginia',
    lat: 39.60550303,
    lng: -80.63834457,
  },
  {
    GEO_ID: '0500000US54105',
    STATE: '54',
    COUNTY: '105',
    NAME: 'Wirt',
    LSAD: 'County',
    CENSUSAREA: 232.509,
    FIPS: 'WV105',
    fips: '54105',
    StateAbbr: 'WV',
    StateName: 'West Virginia',
    lat: 39.02235059,
    lng: -81.37885705,
  },
  {
    GEO_ID: '0500000US54107',
    STATE: '54',
    COUNTY: '107',
    NAME: 'Wood',
    LSAD: 'County',
    CENSUSAREA: 366.26,
    FIPS: 'WV107',
    fips: '54107',
    StateAbbr: 'WV',
    StateName: 'West Virginia',
    lat: 39.21127634,
    lng: -81.51490057,
  },
  {
    GEO_ID: '0500000US55011',
    STATE: '55',
    COUNTY: '011',
    NAME: 'Buffalo',
    LSAD: 'County',
    CENSUSAREA: 671.635,
    FIPS: 'WI011',
    fips: '55011',
    StateAbbr: 'WI',
    StateName: 'Wisconsin',
    lat: 44.38005523,
    lng: -91.75475095,
  },
  {
    GEO_ID: '0500000US55013',
    STATE: '55',
    COUNTY: '013',
    NAME: 'Burnett',
    LSAD: 'County',
    CENSUSAREA: 821.848,
    FIPS: 'WI013',
    fips: '55013',
    StateAbbr: 'WI',
    StateName: 'Wisconsin',
    lat: 45.86286431,
    lng: -92.36769812,
  },
  {
    GEO_ID: '0500000US55019',
    STATE: '55',
    COUNTY: '019',
    NAME: 'Clark',
    LSAD: 'County',
    CENSUSAREA: 1209.815,
    FIPS: 'WI019',
    fips: '55019',
    StateAbbr: 'WI',
    StateName: 'Wisconsin',
    lat: 44.73483376,
    lng: -90.61207484,
  },
  {
    GEO_ID: '0500000US55021',
    STATE: '55',
    COUNTY: '021',
    NAME: 'Columbia',
    LSAD: 'County',
    CENSUSAREA: 765.529,
    FIPS: 'WI021',
    fips: '55021',
    StateAbbr: 'WI',
    StateName: 'Wisconsin',
    lat: 43.4669904,
    lng: -89.33408259,
  },
  {
    GEO_ID: '0500000US55023',
    STATE: '55',
    COUNTY: '023',
    NAME: 'Crawford',
    LSAD: 'County',
    CENSUSAREA: 570.66,
    FIPS: 'WI023',
    fips: '55023',
    StateAbbr: 'WI',
    StateName: 'Wisconsin',
    lat: 43.23966087,
    lng: -90.93068567,
  },
  {
    GEO_ID: '0500000US53029',
    STATE: '53',
    COUNTY: '029',
    NAME: 'Island',
    LSAD: 'County',
    CENSUSAREA: 208.449,
    FIPS: 'WA029',
    fips: '53029',
    StateAbbr: 'WA',
    StateName: 'Washington',
    lat: 48.16376089,
    lng: -122.5493921,
  },
  {
    GEO_ID: '0500000US49039',
    STATE: '49',
    COUNTY: '039',
    NAME: 'Sanpete',
    LSAD: 'County',
    CENSUSAREA: 1590.152,
    FIPS: 'UT039',
    fips: '49039',
    StateAbbr: 'UT',
    StateName: 'Utah',
    lat: 39.37372605,
    lng: -111.5763852,
  },
  {
    GEO_ID: '0500000US49043',
    STATE: '49',
    COUNTY: '043',
    NAME: 'Summit',
    LSAD: 'County',
    CENSUSAREA: 1871.712,
    FIPS: 'UT043',
    fips: '49043',
    StateAbbr: 'UT',
    StateName: 'Utah',
    lat: 40.86838131,
    lng: -110.9558542,
  },
  {
    GEO_ID: '0500000US49045',
    STATE: '49',
    COUNTY: '045',
    NAME: 'Tooele',
    LSAD: 'County',
    CENSUSAREA: 6941.352,
    FIPS: 'UT045',
    fips: '49045',
    StateAbbr: 'UT',
    StateName: 'Utah',
    lat: 40.44875267,
    lng: -113.1311648,
  },
  {
    GEO_ID: '0500000US49049',
    STATE: '49',
    COUNTY: '049',
    NAME: 'Utah',
    LSAD: 'County',
    CENSUSAREA: 2003.454,
    FIPS: 'UT049',
    fips: '49049',
    StateAbbr: 'UT',
    StateName: 'Utah',
    lat: 40.11980262,
    lng: -111.670284,
  },
  {
    GEO_ID: '0500000US49057',
    STATE: '49',
    COUNTY: '057',
    NAME: 'Weber',
    LSAD: 'County',
    CENSUSAREA: 576.084,
    FIPS: 'UT057',
    fips: '49057',
    StateAbbr: 'UT',
    StateName: 'Utah',
    lat: 41.27005573,
    lng: -111.9127797,
  },
  {
    GEO_ID: '0500000US50003',
    STATE: '50',
    COUNTY: '003',
    NAME: 'Bennington',
    LSAD: 'County',
    CENSUSAREA: 674.98,
    FIPS: 'VT003',
    fips: '50003',
    StateAbbr: 'VT',
    StateName: 'Vermont',
    lat: 43.03531126,
    lng: -73.09291531,
  },
  {
    GEO_ID: '0500000US50007',
    STATE: '50',
    COUNTY: '007',
    NAME: 'Chittenden',
    LSAD: 'County',
    CENSUSAREA: 536.578,
    FIPS: 'VT007',
    fips: '50007',
    StateAbbr: 'VT',
    StateName: 'Vermont',
    lat: 44.46109238,
    lng: -73.08101275,
  },
  {
    GEO_ID: '0500000US50011',
    STATE: '50',
    COUNTY: '011',
    NAME: 'Franklin',
    LSAD: 'County',
    CENSUSAREA: 633.706,
    FIPS: 'VT011',
    fips: '50011',
    StateAbbr: 'VT',
    StateName: 'Vermont',
    lat: 44.85745972,
    lng: -72.9120833,
  },
  {
    GEO_ID: '0500000US50015',
    STATE: '50',
    COUNTY: '015',
    NAME: 'Lamoille',
    LSAD: 'County',
    CENSUSAREA: 458.797,
    FIPS: 'VT015',
    fips: '50015',
    StateAbbr: 'VT',
    StateName: 'Vermont',
    lat: 44.60594136,
    lng: -72.641331,
  },
  {
    GEO_ID: '0500000US50021',
    STATE: '50',
    COUNTY: '021',
    NAME: 'Rutland',
    LSAD: 'County',
    CENSUSAREA: 929.821,
    FIPS: 'VT021',
    fips: '50021',
    StateAbbr: 'VT',
    StateName: 'Vermont',
    lat: 43.57983752,
    lng: -73.03669897,
  },
  {
    GEO_ID: '0500000US56023',
    STATE: '56',
    COUNTY: '023',
    NAME: 'Lincoln',
    LSAD: 'County',
    CENSUSAREA: 4076.129,
    FIPS: 'WY023',
    fips: '56023',
    StateAbbr: 'WY',
    StateName: 'Wyoming',
    lat: 42.26409375,
    lng: -110.656067,
  },
  {
    GEO_ID: '0500000US51005',
    STATE: '51',
    COUNTY: '005',
    NAME: 'Alleghany',
    LSAD: 'County',
    CENSUSAREA: 445.457,
    FIPS: 'VA005',
    fips: '51005',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 37.78778327,
    lng: -80.00761323,
  },
  {
    GEO_ID: '0500000US51011',
    STATE: '51',
    COUNTY: '011',
    NAME: 'Appomattox',
    LSAD: 'County',
    CENSUSAREA: 333.494,
    FIPS: 'VA011',
    fips: '51011',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 37.37242345,
    lng: -78.81172877,
  },
  {
    GEO_ID: '0500000US51013',
    STATE: '51',
    COUNTY: '013',
    NAME: 'Arlington',
    LSAD: 'County',
    CENSUSAREA: 25.974,
    FIPS: 'VA013',
    fips: '51013',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 38.87844605,
    lng: -77.10098204,
  },
  {
    GEO_ID: '0500000US51015',
    STATE: '51',
    COUNTY: '015',
    NAME: 'Augusta',
    LSAD: 'County',
    CENSUSAREA: 966.999,
    FIPS: 'VA015',
    fips: '51015',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 38.16483645,
    lng: -79.13408568,
  },
  {
    GEO_ID: '0500000US51019',
    STATE: '51',
    COUNTY: '019',
    NAME: 'Bedford',
    LSAD: 'County',
    CENSUSAREA: 753.021,
    FIPS: 'VA019',
    fips: '51019',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 37.31502696,
    lng: -79.52391814,
  },
  {
    GEO_ID: '0500000US51025',
    STATE: '51',
    COUNTY: '025',
    NAME: 'Brunswick',
    LSAD: 'County',
    CENSUSAREA: 566.173,
    FIPS: 'VA025',
    fips: '51025',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 36.76457633,
    lng: -77.85881453,
  },
  {
    GEO_ID: '0500000US51031',
    STATE: '51',
    COUNTY: '031',
    NAME: 'Campbell',
    LSAD: 'County',
    CENSUSAREA: 503.867,
    FIPS: 'VA031',
    fips: '51031',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 37.20561193,
    lng: -79.09657032,
  },
  {
    GEO_ID: '0500000US51033',
    STATE: '51',
    COUNTY: '033',
    NAME: 'Caroline',
    LSAD: 'County',
    CENSUSAREA: 527.513,
    FIPS: 'VA033',
    fips: '51033',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 38.02701807,
    lng: -77.34662195,
  },
  {
    GEO_ID: '0500000US51036',
    STATE: '51',
    COUNTY: '036',
    NAME: 'Charles City',
    LSAD: 'County',
    CENSUSAREA: 182.817,
    FIPS: 'VA036',
    fips: '51036',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 37.35669644,
    lng: -77.06179476,
  },
  {
    GEO_ID: '0500000US51045',
    STATE: '51',
    COUNTY: '045',
    NAME: 'Craig',
    LSAD: 'County',
    CENSUSAREA: 329.534,
    FIPS: 'VA045',
    fips: '51045',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 37.48141832,
    lng: -80.21218409,
  },
  {
    GEO_ID: '0500000US51049',
    STATE: '51',
    COUNTY: '049',
    NAME: 'Cumberland',
    LSAD: 'County',
    CENSUSAREA: 297.461,
    FIPS: 'VA049',
    fips: '51049',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 37.51182231,
    lng: -78.24455011,
  },
  {
    GEO_ID: '0500000US51051',
    STATE: '51',
    COUNTY: '051',
    NAME: 'Dickenson',
    LSAD: 'County',
    CENSUSAREA: 330.532,
    FIPS: 'VA051',
    fips: '51051',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 37.12613493,
    lng: -82.35094586,
  },
  {
    GEO_ID: '0500000US54081',
    STATE: '54',
    COUNTY: '081',
    NAME: 'Raleigh',
    LSAD: 'County',
    CENSUSAREA: 605.351,
    FIPS: 'WV081',
    fips: '54081',
    StateAbbr: 'WV',
    StateName: 'West Virginia',
    lat: 37.77126746,
    lng: -81.24873953,
  },
  {
    GEO_ID: '0500000US51061',
    STATE: '51',
    COUNTY: '061',
    NAME: 'Fauquier',
    LSAD: 'County',
    CENSUSAREA: 647.449,
    FIPS: 'VA061',
    fips: '51061',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 38.7387071,
    lng: -77.80950815,
  },
  {
    GEO_ID: '0500000US51067',
    STATE: '51',
    COUNTY: '067',
    NAME: 'Franklin',
    LSAD: 'County',
    CENSUSAREA: 690.426,
    FIPS: 'VA067',
    fips: '51067',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 36.99189661,
    lng: -79.88106371,
  },
  {
    GEO_ID: '0500000US51069',
    STATE: '51',
    COUNTY: '069',
    NAME: 'Frederick',
    LSAD: 'County',
    CENSUSAREA: 413.496,
    FIPS: 'VA069',
    fips: '51069',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 39.2045848,
    lng: -78.26241473,
  },
  {
    GEO_ID: '0500000US51073',
    STATE: '51',
    COUNTY: '073',
    NAME: 'Gloucester',
    LSAD: 'County',
    CENSUSAREA: 217.807,
    FIPS: 'VA073',
    fips: '51073',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 37.41403941,
    lng: -76.54142204,
  },
  {
    GEO_ID: '0500000US51081',
    STATE: '51',
    COUNTY: '081',
    NAME: 'Greensville',
    LSAD: 'County',
    CENSUSAREA: 295.226,
    FIPS: 'VA081',
    fips: '51081',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 36.67571583,
    lng: -77.55962422,
  },
  {
    GEO_ID: '0500000US51085',
    STATE: '51',
    COUNTY: '085',
    NAME: 'Hanover',
    LSAD: 'County',
    CENSUSAREA: 468.536,
    FIPS: 'VA085',
    fips: '51085',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 37.76033617,
    lng: -77.49131526,
  },
  {
    GEO_ID: '0500000US51091',
    STATE: '51',
    COUNTY: '091',
    NAME: 'Highland',
    LSAD: 'County',
    CENSUSAREA: 415.156,
    FIPS: 'VA091',
    fips: '51091',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 38.36239367,
    lng: -79.56866954,
  },
  {
    GEO_ID: '0500000US51095',
    STATE: '51',
    COUNTY: '095',
    NAME: 'James City',
    LSAD: 'County',
    CENSUSAREA: 142.437,
    FIPS: 'VA095',
    fips: '51095',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 37.32794596,
    lng: -76.77717182,
  },
  {
    GEO_ID: '0500000US51103',
    STATE: '51',
    COUNTY: '103',
    NAME: 'Lancaster',
    LSAD: 'County',
    CENSUSAREA: 133.25,
    FIPS: 'VA103',
    fips: '51103',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 37.73239182,
    lng: -76.46151665,
  },
  {
    GEO_ID: '0500000US49037',
    STATE: '49',
    COUNTY: '037',
    NAME: 'San Juan',
    LSAD: 'County',
    CENSUSAREA: 7819.988,
    FIPS: 'UT037',
    fips: '49037',
    StateAbbr: 'UT',
    StateName: 'Utah',
    lat: 37.62607487,
    lng: -109.8043936,
  },
  {
    GEO_ID: '0500000US51119',
    STATE: '51',
    COUNTY: '119',
    NAME: 'Middlesex',
    LSAD: 'County',
    CENSUSAREA: 130.306,
    FIPS: 'VA119',
    fips: '51119',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 37.62970349,
    lng: -76.56869484,
  },
  {
    GEO_ID: '0500000US72027',
    STATE: '72',
    COUNTY: '027',
    NAME: 'Camuy',
    LSAD: 'Muno',
    CENSUSAREA: 46.355,
    FIPS: 'PR027',
    fips: '72027',
    StateAbbr: 'PR',
    StateName: 'Puerto Rico',
    lat: 18.41919751,
    lng: -66.86018176,
  },
  {
    GEO_ID: '0500000US72035',
    STATE: '72',
    COUNTY: '035',
    NAME: 'Cayey',
    LSAD: 'Muno',
    CENSUSAREA: 51.934,
    FIPS: 'PR035',
    fips: '72035',
    StateAbbr: 'PR',
    StateName: 'Puerto Rico',
    lat: 18.10378828,
    lng: -66.14868913,
  },
  {
    GEO_ID: '0500000US72041',
    STATE: '72',
    COUNTY: '041',
    NAME: 'Cidra',
    LSAD: 'Muno',
    CENSUSAREA: 36.021,
    FIPS: 'PR041',
    fips: '72041',
    StateAbbr: 'PR',
    StateName: 'Puerto Rico',
    lat: 18.17423695,
    lng: -66.16028438,
  },
  {
    GEO_ID: '0500000US72043',
    STATE: '72',
    COUNTY: '043',
    NAME: 'Coamo',
    LSAD: 'Muno',
    CENSUSAREA: 78.012,
    FIPS: 'PR043',
    fips: '72043',
    StateAbbr: 'PR',
    StateName: 'Puerto Rico',
    lat: 18.09702641,
    lng: -66.36025819,
  },
  {
    GEO_ID: '0500000US72053',
    STATE: '72',
    COUNTY: '053',
    NAME: 'Fajardo',
    LSAD: 'Muno',
    CENSUSAREA: 29.864,
    FIPS: 'PR053',
    fips: '72053',
    StateAbbr: 'PR',
    StateName: 'Puerto Rico',
    lat: 18.32347339,
    lng: -65.65965429,
  },
  {
    GEO_ID: '0500000US72055',
    STATE: '72',
    COUNTY: '055',
    NAME: 'Guánica',
    LSAD: 'Muno',
    CENSUSAREA: 37.05,
    FIPS: 'PR055',
    fips: '72055',
    StateAbbr: 'PR',
    StateName: 'Puerto Rico',
    lat: 17.98089603,
    lng: -66.91907543,
  },
  {
    GEO_ID: '0500000US72059',
    STATE: '72',
    COUNTY: '059',
    NAME: 'Guayanilla',
    LSAD: 'Muno',
    CENSUSAREA: 42.27,
    FIPS: 'PR059',
    fips: '72059',
    StateAbbr: 'PR',
    StateName: 'Puerto Rico',
    lat: 18.03985751,
    lng: -66.79209227,
  },
  {
    GEO_ID: '0500000US72067',
    STATE: '72',
    COUNTY: '067',
    NAME: 'Hormigueros',
    LSAD: 'Muno',
    CENSUSAREA: 11.344,
    FIPS: 'PR067',
    fips: '72067',
    StateAbbr: 'PR',
    StateName: 'Puerto Rico',
    lat: 18.13495788,
    lng: -67.11577339,
  },
  {
    GEO_ID: '0500000US72073',
    STATE: '72',
    COUNTY: '073',
    NAME: 'Jayuya',
    LSAD: 'Muno',
    CENSUSAREA: 44.533,
    FIPS: 'PR073',
    fips: '72073',
    StateAbbr: 'PR',
    StateName: 'Puerto Rico',
    lat: 18.20981011,
    lng: -66.58853781,
  },
  {
    GEO_ID: '0500000US50025',
    STATE: '50',
    COUNTY: '025',
    NAME: 'Windham',
    LSAD: 'County',
    CENSUSAREA: 785.305,
    FIPS: 'VT025',
    fips: '50025',
    StateAbbr: 'VT',
    StateName: 'Vermont',
    lat: 42.99053464,
    lng: -72.71377523,
  },
  {
    GEO_ID: '0500000US72005',
    STATE: '72',
    COUNTY: '005',
    NAME: 'Aguadilla',
    LSAD: 'Muno',
    CENSUSAREA: 36.529,
    FIPS: 'PR005',
    fips: '72005',
    StateAbbr: 'PR',
    StateName: 'Puerto Rico',
    lat: 18.46028414,
    lng: -67.12067807,
  },
  {
    GEO_ID: '0500000US53037',
    STATE: '53',
    COUNTY: '037',
    NAME: 'Kittitas',
    LSAD: 'County',
    CENSUSAREA: 2297.271,
    FIPS: 'WA037',
    fips: '53037',
    StateAbbr: 'WA',
    StateName: 'Washington',
    lat: 47.12448572,
    lng: -120.679712,
  },
  {
    GEO_ID: '0500000US53039',
    STATE: '53',
    COUNTY: '039',
    NAME: 'Klickitat',
    LSAD: 'County',
    CENSUSAREA: 1871.313,
    FIPS: 'WA039',
    fips: '53039',
    StateAbbr: 'WA',
    StateName: 'Washington',
    lat: 45.87361546,
    lng: -120.7895031,
  },
  {
    GEO_ID: '0500000US53059',
    STATE: '53',
    COUNTY: '059',
    NAME: 'Skamania',
    LSAD: 'County',
    CENSUSAREA: 1655.677,
    FIPS: 'WA059',
    fips: '53059',
    StateAbbr: 'WA',
    StateName: 'Washington',
    lat: 46.02280969,
    lng: -121.9148915,
  },
  {
    GEO_ID: '0500000US53075',
    STATE: '53',
    COUNTY: '075',
    NAME: 'Whitman',
    LSAD: 'County',
    CENSUSAREA: 2159.09,
    FIPS: 'WA075',
    fips: '53075',
    StateAbbr: 'WA',
    StateName: 'Washington',
    lat: 46.90119074,
    lng: -117.5229774,
  },
  {
    GEO_ID: '0500000US54017',
    STATE: '54',
    COUNTY: '017',
    NAME: 'Doddridge',
    LSAD: 'County',
    CENSUSAREA: 319.72,
    FIPS: 'WV017',
    fips: '54017',
    StateAbbr: 'WV',
    StateName: 'West Virginia',
    lat: 39.26936104,
    lng: -80.70721363,
  },
  {
    GEO_ID: '0500000US54029',
    STATE: '54',
    COUNTY: '029',
    NAME: 'Hancock',
    LSAD: 'County',
    CENSUSAREA: 82.609,
    FIPS: 'WV029',
    fips: '54029',
    StateAbbr: 'WV',
    StateName: 'West Virginia',
    lat: 40.52189394,
    lng: -80.57390126,
  },
  {
    GEO_ID: '0500000US54043',
    STATE: '54',
    COUNTY: '043',
    NAME: 'Lincoln',
    LSAD: 'County',
    CENSUSAREA: 437.039,
    FIPS: 'WV043',
    fips: '54043',
    StateAbbr: 'WV',
    StateName: 'West Virginia',
    lat: 38.17536985,
    lng: -82.07055835,
  },
  {
    GEO_ID: '0500000US54055',
    STATE: '54',
    COUNTY: '055',
    NAME: 'Mercer',
    LSAD: 'County',
    CENSUSAREA: 418.993,
    FIPS: 'WV055',
    fips: '54055',
    StateAbbr: 'WV',
    StateName: 'West Virginia',
    lat: 37.40564762,
    lng: -81.11147554,
  },
  {
    GEO_ID: '0500000US54073',
    STATE: '54',
    COUNTY: '073',
    NAME: 'Pleasants',
    LSAD: 'County',
    CENSUSAREA: 130.103,
    FIPS: 'WV073',
    fips: '54073',
    StateAbbr: 'WV',
    StateName: 'West Virginia',
    lat: 39.37099538,
    lng: -81.1606194,
  },
  {
    GEO_ID: '0500000US54085',
    STATE: '54',
    COUNTY: '085',
    NAME: 'Ritchie',
    LSAD: 'County',
    CENSUSAREA: 451.991,
    FIPS: 'WV085',
    fips: '54085',
    StateAbbr: 'WV',
    StateName: 'West Virginia',
    lat: 39.17806062,
    lng: -81.06312621,
  },
  {
    GEO_ID: '0500000US54095',
    STATE: '54',
    COUNTY: '095',
    NAME: 'Tyler',
    LSAD: 'County',
    CENSUSAREA: 256.294,
    FIPS: 'WV095',
    fips: '54095',
    StateAbbr: 'WV',
    StateName: 'West Virginia',
    lat: 39.46568,
    lng: -80.88510513,
  },
  {
    GEO_ID: '0500000US51057',
    STATE: '51',
    COUNTY: '057',
    NAME: 'Essex',
    LSAD: 'County',
    CENSUSAREA: 257.123,
    FIPS: 'VA057',
    fips: '51057',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 37.94303646,
    lng: -76.9520416,
  },
  {
    GEO_ID: '0500000US72079',
    STATE: '72',
    COUNTY: '079',
    NAME: 'Lajas',
    LSAD: 'Muno',
    CENSUSAREA: 59.953,
    FIPS: 'PR079',
    fips: '72079',
    StateAbbr: 'PR',
    StateName: 'Puerto Rico',
    lat: 18.01204007,
    lng: -67.03992341,
  },
  {
    GEO_ID: '0500000US72081',
    STATE: '72',
    COUNTY: '081',
    NAME: 'Lares',
    LSAD: 'Muno',
    CENSUSAREA: 61.45,
    FIPS: 'PR081',
    fips: '72081',
    StateAbbr: 'PR',
    StateName: 'Puerto Rico',
    lat: 18.26903434,
    lng: -66.86725626,
  },
  {
    GEO_ID: '0500000US72089',
    STATE: '72',
    COUNTY: '089',
    NAME: 'Luquillo',
    LSAD: 'Muno',
    CENSUSAREA: 25.811,
    FIPS: 'PR089',
    fips: '72089',
    StateAbbr: 'PR',
    StateName: 'Puerto Rico',
    lat: 18.34258508,
    lng: -65.72588384,
  },
  {
    GEO_ID: '0500000US72095',
    STATE: '72',
    COUNTY: '095',
    NAME: 'Maunabo',
    LSAD: 'Muno',
    CENSUSAREA: 21.066,
    FIPS: 'PR095',
    fips: '72095',
    StateAbbr: 'PR',
    StateName: 'Puerto Rico',
    lat: 18.01814338,
    lng: -65.92250967,
  },
  {
    GEO_ID: '0500000US72101',
    STATE: '72',
    COUNTY: '101',
    NAME: 'Morovis',
    LSAD: 'Muno',
    CENSUSAREA: 38.871,
    FIPS: 'PR101',
    fips: '72101',
    StateAbbr: 'PR',
    StateName: 'Puerto Rico',
    lat: 18.31742228,
    lng: -66.42009023,
  },
  {
    GEO_ID: '0500000US72103',
    STATE: '72',
    COUNTY: '103',
    NAME: 'Naguabo',
    LSAD: 'Muno',
    CENSUSAREA: 51.661,
    FIPS: 'PR103',
    fips: '72103',
    StateAbbr: 'PR',
    StateName: 'Puerto Rico',
    lat: 18.23111172,
    lng: -65.75412933,
  },
  {
    GEO_ID: '0500000US72109',
    STATE: '72',
    COUNTY: '109',
    NAME: 'Patillas',
    LSAD: 'Muno',
    CENSUSAREA: 46.699,
    FIPS: 'PR109',
    fips: '72109',
    StateAbbr: 'PR',
    StateName: 'Puerto Rico',
    lat: 18.03150854,
    lng: -66.01187172,
  },
  {
    GEO_ID: '0500000US72117',
    STATE: '72',
    COUNTY: '117',
    NAME: 'Rincón',
    LSAD: 'Muno',
    CENSUSAREA: 14.288,
    FIPS: 'PR117',
    fips: '72117',
    StateAbbr: 'PR',
    StateName: 'Puerto Rico',
    lat: 18.33498528,
    lng: -67.23189285,
  },
  {
    GEO_ID: '0500000US72119',
    STATE: '72',
    COUNTY: '119',
    NAME: 'Río Grande',
    LSAD: 'Muno',
    CENSUSAREA: 60.623,
    FIPS: 'PR119',
    fips: '72119',
    StateAbbr: 'PR',
    StateName: 'Puerto Rico',
    lat: 18.34790658,
    lng: -65.81320794,
  },
  {
    GEO_ID: '0500000US72125',
    STATE: '72',
    COUNTY: '125',
    NAME: 'San Germán',
    LSAD: 'Muno',
    CENSUSAREA: 54.498,
    FIPS: 'PR125',
    fips: '72125',
    StateAbbr: 'PR',
    StateName: 'Puerto Rico',
    lat: 18.11192588,
    lng: -67.03910309,
  },
  {
    GEO_ID: '0500000US72131',
    STATE: '72',
    COUNTY: '131',
    NAME: 'San Sebastián',
    LSAD: 'Muno',
    CENSUSAREA: 70.423,
    FIPS: 'PR131',
    fips: '72131',
    StateAbbr: 'PR',
    StateName: 'Puerto Rico',
    lat: 18.32854209,
    lng: -66.97161064,
  },
  {
    GEO_ID: '0500000US72137',
    STATE: '72',
    COUNTY: '137',
    NAME: 'Toa Baja',
    LSAD: 'Muno',
    CENSUSAREA: 23.241,
    FIPS: 'PR137',
    fips: '72137',
    StateAbbr: 'PR',
    StateName: 'Puerto Rico',
    lat: 18.4313626,
    lng: -66.21551546,
  },
  {
    GEO_ID: '0500000US72143',
    STATE: '72',
    COUNTY: '143',
    NAME: 'Vega Alta',
    LSAD: 'Muno',
    CENSUSAREA: 27.728,
    FIPS: 'PR143',
    fips: '72143',
    StateAbbr: 'PR',
    StateName: 'Puerto Rico',
    lat: 18.41003729,
    lng: -66.33676656,
  },
  {
    GEO_ID: '0500000US51115',
    STATE: '51',
    COUNTY: '115',
    NAME: 'Mathews',
    LSAD: 'County',
    CENSUSAREA: 85.929,
    FIPS: 'VA115',
    fips: '51115',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 37.43639888,
    lng: -76.34148682,
  },
  {
    GEO_ID: '0500000US22095',
    STATE: '22',
    COUNTY: '095',
    NAME: 'St. John the Baptist',
    LSAD: 'Parish',
    CENSUSAREA: 213.071,
    FIPS: 'LA095',
    fips: '22095',
    StateAbbr: 'LA',
    StateName: 'Louisiana',
    lat: 30.12694792,
    lng: -90.47063317,
  },
  {
    GEO_ID: '0500000US22111',
    STATE: '22',
    COUNTY: '111',
    NAME: 'Union',
    LSAD: 'Parish',
    CENSUSAREA: 876.988,
    FIPS: 'LA111',
    fips: '22111',
    StateAbbr: 'LA',
    StateName: 'Louisiana',
    lat: 32.83186286,
    lng: -92.3748342,
  },
  {
    GEO_ID: '0500000US21001',
    STATE: '21',
    COUNTY: '001',
    NAME: 'Adair',
    LSAD: 'County',
    CENSUSAREA: 405.283,
    FIPS: 'KY001',
    fips: '21001',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 37.1039252,
    lng: -85.28062533,
  },
  {
    GEO_ID: '0500000US21005',
    STATE: '21',
    COUNTY: '005',
    NAME: 'Anderson',
    LSAD: 'County',
    CENSUSAREA: 201.832,
    FIPS: 'KY005',
    fips: '21005',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 38.00378449,
    lng: -84.99140593,
  },
  {
    GEO_ID: '0500000US21007',
    STATE: '21',
    COUNTY: '007',
    NAME: 'Ballard',
    LSAD: 'County',
    CENSUSAREA: 246.659,
    FIPS: 'KY007',
    fips: '21007',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 37.05854425,
    lng: -88.99943791,
  },
  {
    GEO_ID: '0500000US21019',
    STATE: '21',
    COUNTY: '019',
    NAME: 'Boyd',
    LSAD: 'County',
    CENSUSAREA: 159.864,
    FIPS: 'KY019',
    fips: '21019',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 38.35908957,
    lng: -82.68775539,
  },
  {
    GEO_ID: '0500000US21037',
    STATE: '21',
    COUNTY: '037',
    NAME: 'Campbell',
    LSAD: 'County',
    CENSUSAREA: 151.308,
    FIPS: 'KY037',
    fips: '21037',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 38.94658952,
    lng: -84.3791722,
  },
  {
    GEO_ID: '0500000US21049',
    STATE: '21',
    COUNTY: '049',
    NAME: 'Clark',
    LSAD: 'County',
    CENSUSAREA: 252.463,
    FIPS: 'KY049',
    fips: '21049',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 37.97054025,
    lng: -84.14820158,
  },
  {
    GEO_ID: '0500000US21061',
    STATE: '21',
    COUNTY: '061',
    NAME: 'Edmonson',
    LSAD: 'County',
    CENSUSAREA: 302.883,
    FIPS: 'KY061',
    fips: '21061',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 37.20904677,
    lng: -86.23847919,
  },
  {
    GEO_ID: '0500000US21069',
    STATE: '21',
    COUNTY: '069',
    NAME: 'Fleming',
    LSAD: 'County',
    CENSUSAREA: 348.542,
    FIPS: 'KY069',
    fips: '21069',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 38.37035302,
    lng: -83.69658812,
  },
  {
    GEO_ID: '0500000US21081',
    STATE: '21',
    COUNTY: '081',
    NAME: 'Grant',
    LSAD: 'County',
    CENSUSAREA: 257.965,
    FIPS: 'KY081',
    fips: '21081',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 38.64914531,
    lng: -84.62407384,
  },
  {
    GEO_ID: '0500000US21093',
    STATE: '21',
    COUNTY: '093',
    NAME: 'Hardin',
    LSAD: 'County',
    CENSUSAREA: 623.278,
    FIPS: 'KY093',
    fips: '21093',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 37.69820941,
    lng: -85.96366674,
  },
  {
    GEO_ID: '0500000US21109',
    STATE: '21',
    COUNTY: '109',
    NAME: 'Jackson',
    LSAD: 'County',
    CENSUSAREA: 345.201,
    FIPS: 'KY109',
    fips: '21109',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 37.41979135,
    lng: -84.00617165,
  },
  {
    GEO_ID: '0500000US21125',
    STATE: '21',
    COUNTY: '125',
    NAME: 'Laurel',
    LSAD: 'County',
    CENSUSAREA: 433.953,
    FIPS: 'KY125',
    fips: '21125',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 37.11047425,
    lng: -84.1176508,
  },
  {
    GEO_ID: '0500000US21131',
    STATE: '21',
    COUNTY: '131',
    NAME: 'Leslie',
    LSAD: 'County',
    CENSUSAREA: 400.844,
    FIPS: 'KY131',
    fips: '21131',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 37.0939886,
    lng: -83.38100797,
  },
  {
    GEO_ID: '0500000US72075',
    STATE: '72',
    COUNTY: '075',
    NAME: 'Juana Díaz',
    LSAD: 'Muno',
    CENSUSAREA: 60.277,
    FIPS: 'PR075',
    fips: '72075',
    StateAbbr: 'PR',
    StateName: 'Puerto Rico',
    lat: 18.05157356,
    lng: -66.49544792,
  },
  {
    GEO_ID: '0500000US40037',
    STATE: '40',
    COUNTY: '037',
    NAME: 'Creek',
    LSAD: 'County',
    CENSUSAREA: 950.141,
    FIPS: 'OK037',
    fips: '40037',
    StateAbbr: 'OK',
    StateName: 'Oklahoma',
    lat: 35.90233119,
    lng: -96.37089402,
  },
  {
    GEO_ID: '0500000US40047',
    STATE: '40',
    COUNTY: '047',
    NAME: 'Garfield',
    LSAD: 'County',
    CENSUSAREA: 1058.466,
    FIPS: 'OK047',
    fips: '40047',
    StateAbbr: 'OK',
    StateName: 'Oklahoma',
    lat: 36.3791609,
    lng: -97.78263254,
  },
  {
    GEO_ID: '0500000US37183',
    STATE: '37',
    COUNTY: '183',
    NAME: 'Wake',
    LSAD: 'County',
    CENSUSAREA: 835.219,
    FIPS: 'NC183',
    fips: '37183',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 35.78982367,
    lng: -78.65050164,
  },
  {
    GEO_ID: '0500000US37187',
    STATE: '37',
    COUNTY: '187',
    NAME: 'Washington',
    LSAD: 'County',
    CENSUSAREA: 348.135,
    FIPS: 'NC187',
    fips: '37187',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 35.82268131,
    lng: -76.57653423,
  },
  {
    GEO_ID: '0500000US38003',
    STATE: '38',
    COUNTY: '003',
    NAME: 'Barnes',
    LSAD: 'County',
    CENSUSAREA: 1491.554,
    FIPS: 'ND003',
    fips: '38003',
    StateAbbr: 'ND',
    StateName: 'North Dakota',
    lat: 46.93593125,
    lng: -98.07170952,
  },
  {
    GEO_ID: '0500000US38013',
    STATE: '38',
    COUNTY: '013',
    NAME: 'Burke',
    LSAD: 'County',
    CENSUSAREA: 1103.568,
    FIPS: 'ND013',
    fips: '38013',
    StateAbbr: 'ND',
    StateName: 'North Dakota',
    lat: 48.79096757,
    lng: -102.5181759,
  },
  {
    GEO_ID: '0500000US38025',
    STATE: '38',
    COUNTY: '025',
    NAME: 'Dunn',
    LSAD: 'County',
    CENSUSAREA: 2008.46,
    FIPS: 'ND025',
    fips: '38025',
    StateAbbr: 'ND',
    StateName: 'North Dakota',
    lat: 47.35663619,
    lng: -102.6182871,
  },
  {
    GEO_ID: '0500000US38041',
    STATE: '38',
    COUNTY: '041',
    NAME: 'Hettinger',
    LSAD: 'County',
    CENSUSAREA: 1132.221,
    FIPS: 'ND041',
    fips: '38041',
    StateAbbr: 'ND',
    StateName: 'North Dakota',
    lat: 46.43276791,
    lng: -102.4607133,
  },
  {
    GEO_ID: '0500000US38055',
    STATE: '38',
    COUNTY: '055',
    NAME: 'McLean',
    LSAD: 'County',
    CENSUSAREA: 2110.876,
    FIPS: 'ND055',
    fips: '38055',
    StateAbbr: 'ND',
    StateName: 'North Dakota',
    lat: 47.60697344,
    lng: -101.3219684,
  },
  {
    GEO_ID: '0500000US38069',
    STATE: '38',
    COUNTY: '069',
    NAME: 'Pierce',
    LSAD: 'County',
    CENSUSAREA: 1018.6,
    FIPS: 'ND069',
    fips: '38069',
    StateAbbr: 'ND',
    StateName: 'North Dakota',
    lat: 48.24958064,
    lng: -99.97174953,
  },
  {
    GEO_ID: '0500000US38087',
    STATE: '38',
    COUNTY: '087',
    NAME: 'Slope',
    LSAD: 'County',
    CENSUSAREA: 1214.922,
    FIPS: 'ND087',
    fips: '38087',
    StateAbbr: 'ND',
    StateName: 'North Dakota',
    lat: 46.4473194,
    lng: -103.4602876,
  },
  {
    GEO_ID: '0500000US38095',
    STATE: '38',
    COUNTY: '095',
    NAME: 'Towner',
    LSAD: 'County',
    CENSUSAREA: 1024.561,
    FIPS: 'ND095',
    fips: '38095',
    StateAbbr: 'ND',
    StateName: 'North Dakota',
    lat: 48.68566015,
    lng: -99.24564463,
  },
  {
    GEO_ID: '0500000US39009',
    STATE: '39',
    COUNTY: '009',
    NAME: 'Athens',
    LSAD: 'County',
    CENSUSAREA: 503.598,
    FIPS: 'OH009',
    fips: '39009',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 39.3340326,
    lng: -82.0451808,
  },
  {
    GEO_ID: '0500000US39021',
    STATE: '39',
    COUNTY: '021',
    NAME: 'Champaign',
    LSAD: 'County',
    CENSUSAREA: 428.669,
    FIPS: 'OH021',
    fips: '39021',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 40.13766698,
    lng: -83.76966912,
  },
  {
    GEO_ID: '0500000US54109',
    STATE: '54',
    COUNTY: '109',
    NAME: 'Wyoming',
    LSAD: 'County',
    CENSUSAREA: 499.453,
    FIPS: 'WV109',
    fips: '54109',
    StateAbbr: 'WV',
    StateName: 'West Virginia',
    lat: 37.60951977,
    lng: -81.54940333,
  },
  {
    GEO_ID: '0500000US55015',
    STATE: '55',
    COUNTY: '015',
    NAME: 'Calumet',
    LSAD: 'County',
    CENSUSAREA: 318.236,
    FIPS: 'WI015',
    fips: '55015',
    StateAbbr: 'WI',
    StateName: 'Wisconsin',
    lat: 44.0816316,
    lng: -88.21780273,
  },
  {
    GEO_ID: '0500000US55027',
    STATE: '55',
    COUNTY: '027',
    NAME: 'Dodge',
    LSAD: 'County',
    CENSUSAREA: 875.625,
    FIPS: 'WI027',
    fips: '55027',
    StateAbbr: 'WI',
    StateName: 'Wisconsin',
    lat: 43.41581374,
    lng: -88.70770995,
  },
  {
    GEO_ID: '0500000US49027',
    STATE: '49',
    COUNTY: '027',
    NAME: 'Millard',
    LSAD: 'County',
    CENSUSAREA: 6572.425,
    FIPS: 'UT027',
    fips: '49027',
    StateAbbr: 'UT',
    StateName: 'Utah',
    lat: 39.07403971,
    lng: -113.1003083,
  },
  {
    GEO_ID: '0500000US49033',
    STATE: '49',
    COUNTY: '033',
    NAME: 'Rich',
    LSAD: 'County',
    CENSUSAREA: 1028.775,
    FIPS: 'UT033',
    fips: '49033',
    StateAbbr: 'UT',
    StateName: 'Utah',
    lat: 41.63204523,
    lng: -111.2445372,
  },
  {
    GEO_ID: '0500000US49041',
    STATE: '49',
    COUNTY: '041',
    NAME: 'Sevier',
    LSAD: 'County',
    CENSUSAREA: 1910.577,
    FIPS: 'UT041',
    fips: '49041',
    StateAbbr: 'UT',
    StateName: 'Utah',
    lat: 38.74769427,
    lng: -111.8045988,
  },
  {
    GEO_ID: '0500000US49055',
    STATE: '49',
    COUNTY: '055',
    NAME: 'Wayne',
    LSAD: 'County',
    CENSUSAREA: 2460.676,
    FIPS: 'UT055',
    fips: '49055',
    StateAbbr: 'UT',
    StateName: 'Utah',
    lat: 38.32442333,
    lng: -110.9033351,
  },
  {
    GEO_ID: '0500000US50013',
    STATE: '50',
    COUNTY: '013',
    NAME: 'Grand Isle',
    LSAD: 'County',
    CENSUSAREA: 81.811,
    FIPS: 'VT013',
    fips: '50013',
    StateAbbr: 'VT',
    StateName: 'Vermont',
    lat: 44.79689986,
    lng: -73.29488547,
  },
  {
    GEO_ID: '0500000US51003',
    STATE: '51',
    COUNTY: '003',
    NAME: 'Albemarle',
    LSAD: 'County',
    CENSUSAREA: 720.698,
    FIPS: 'VA003',
    fips: '51003',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 38.02273165,
    lng: -78.55661903,
  },
  {
    GEO_ID: '0500000US51009',
    STATE: '51',
    COUNTY: '009',
    NAME: 'Amherst',
    LSAD: 'County',
    CENSUSAREA: 473.934,
    FIPS: 'VA009',
    fips: '51009',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 37.60543548,
    lng: -79.14517223,
  },
  {
    GEO_ID: '0500000US51023',
    STATE: '51',
    COUNTY: '023',
    NAME: 'Botetourt',
    LSAD: 'County',
    CENSUSAREA: 541.202,
    FIPS: 'VA023',
    fips: '51023',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 37.5570231,
    lng: -79.8119662,
  },
  {
    GEO_ID: '0500000US51035',
    STATE: '51',
    COUNTY: '035',
    NAME: 'Carroll',
    LSAD: 'County',
    CENSUSAREA: 474.69,
    FIPS: 'VA035',
    fips: '51035',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 36.73130704,
    lng: -80.73317673,
  },
  {
    GEO_ID: '0500000US51053',
    STATE: '51',
    COUNTY: '053',
    NAME: 'Dinwiddie',
    LSAD: 'County',
    CENSUSAREA: 503.716,
    FIPS: 'VA053',
    fips: '51053',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 37.07597757,
    lng: -77.63238876,
  },
  {
    GEO_ID: '0500000US51065',
    STATE: '51',
    COUNTY: '065',
    NAME: 'Fluvanna',
    LSAD: 'County',
    CENSUSAREA: 286.005,
    FIPS: 'VA065',
    fips: '51065',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 37.84216441,
    lng: -78.27765024,
  },
  {
    GEO_ID: '0500000US72147',
    STATE: '72',
    COUNTY: '147',
    NAME: 'Vieques',
    LSAD: 'Muno',
    CENSUSAREA: 50.768,
    FIPS: 'PR147',
    fips: '72147',
    StateAbbr: 'PR',
    StateName: 'Puerto Rico',
    lat: 18.12244435,
    lng: -65.43923326,
  },
  {
    GEO_ID: '0500000US72151',
    STATE: '72',
    COUNTY: '151',
    NAME: 'Yabucoa',
    LSAD: 'Muno',
    CENSUSAREA: 55.215,
    FIPS: 'PR151',
    fips: '72151',
    StateAbbr: 'PR',
    StateName: 'Puerto Rico',
    lat: 18.07018602,
    lng: -65.89622001,
  },
  {
    GEO_ID: '0500000US72153',
    STATE: '72',
    COUNTY: '153',
    NAME: 'Yauco',
    LSAD: 'Muno',
    CENSUSAREA: 68.192,
    FIPS: 'PR153',
    fips: '72153',
    StateAbbr: 'PR',
    StateName: 'Puerto Rico',
    lat: 18.08109137,
    lng: -66.8582991,
  },
  {
    GEO_ID: '0500000US01003',
    STATE: '01',
    COUNTY: '003',
    NAME: 'Baldwin',
    LSAD: 'County',
    CENSUSAREA: 1589.784,
    FIPS: 'AL003',
    fips: '01003',
    StateAbbr: 'AL',
    StateName: 'Alabama',
    lat: 30.72782468,
    lng: -87.72274477,
  },
  {
    GEO_ID: '0500000US01027',
    STATE: '01',
    COUNTY: '027',
    NAME: 'Clay',
    LSAD: 'County',
    CENSUSAREA: 603.961,
    FIPS: 'AL027',
    fips: '01027',
    StateAbbr: 'AL',
    StateName: 'Alabama',
    lat: 33.26966941,
    lng: -85.8607443,
  },
  {
    GEO_ID: '0500000US01041',
    STATE: '01',
    COUNTY: '041',
    NAME: 'Crenshaw',
    LSAD: 'County',
    CENSUSAREA: 608.84,
    FIPS: 'AL041',
    fips: '01041',
    StateAbbr: 'AL',
    StateName: 'Alabama',
    lat: 31.73169775,
    lng: -86.31341907,
  },
  {
    GEO_ID: '0500000US01057',
    STATE: '01',
    COUNTY: '057',
    NAME: 'Fayette',
    LSAD: 'County',
    CENSUSAREA: 627.66,
    FIPS: 'AL057',
    fips: '01057',
    StateAbbr: 'AL',
    StateName: 'Alabama',
    lat: 33.72117266,
    lng: -87.73924331,
  },
  {
    GEO_ID: '0500000US01073',
    STATE: '01',
    COUNTY: '073',
    NAME: 'Jefferson',
    LSAD: 'County',
    CENSUSAREA: 1111.276,
    FIPS: 'AL073',
    fips: '01073',
    StateAbbr: 'AL',
    StateName: 'Alabama',
    lat: 33.5543433,
    lng: -86.89657084,
  },
  {
    GEO_ID: '0500000US01091',
    STATE: '01',
    COUNTY: '091',
    NAME: 'Marengo',
    LSAD: 'County',
    CENSUSAREA: 976.882,
    FIPS: 'AL091',
    fips: '01091',
    StateAbbr: 'AL',
    StateName: 'Alabama',
    lat: 32.24794915,
    lng: -87.78920946,
  },
  {
    GEO_ID: '0500000US01103',
    STATE: '01',
    COUNTY: '103',
    NAME: 'Morgan',
    LSAD: 'County',
    CENSUSAREA: 579.337,
    FIPS: 'AL103',
    fips: '01103',
    StateAbbr: 'AL',
    StateName: 'Alabama',
    lat: 34.45331009,
    lng: -86.85325062,
  },
  {
    GEO_ID: '0500000US01115',
    STATE: '01',
    COUNTY: '115',
    NAME: 'St. Clair',
    LSAD: 'County',
    CENSUSAREA: 631.902,
    FIPS: 'AL115',
    fips: '01115',
    StateAbbr: 'AL',
    StateName: 'Alabama',
    lat: 33.71540022,
    lng: -86.31476905,
  },
  {
    GEO_ID: '0500000US01129',
    STATE: '01',
    COUNTY: '129',
    NAME: 'Washington',
    LSAD: 'County',
    CENSUSAREA: 1080.207,
    FIPS: 'AL129',
    fips: '01129',
    StateAbbr: 'AL',
    StateName: 'Alabama',
    lat: 31.40759248,
    lng: -88.20819419,
  },
  {
    GEO_ID: '0500000US21147',
    STATE: '21',
    COUNTY: '147',
    NAME: 'McCreary',
    LSAD: 'County',
    CENSUSAREA: 426.803,
    FIPS: 'KY147',
    fips: '21147',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 36.73699245,
    lng: -84.48377645,
  },
  {
    GEO_ID: '0500000US21165',
    STATE: '21',
    COUNTY: '165',
    NAME: 'Menifee',
    LSAD: 'County',
    CENSUSAREA: 203.585,
    FIPS: 'KY165',
    fips: '21165',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 37.94154655,
    lng: -83.59900123,
  },
  {
    GEO_ID: '0500000US21181',
    STATE: '21',
    COUNTY: '181',
    NAME: 'Nicholas',
    LSAD: 'County',
    CENSUSAREA: 195.171,
    FIPS: 'KY181',
    fips: '21181',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 38.33536589,
    lng: -84.01542795,
  },
  {
    GEO_ID: '0500000US19041',
    STATE: '19',
    COUNTY: '041',
    NAME: 'Clay',
    LSAD: 'County',
    CENSUSAREA: 567.238,
    FIPS: 'IA041',
    fips: '19041',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 43.08247182,
    lng: -95.15096481,
  },
  {
    GEO_ID: '0500000US19043',
    STATE: '19',
    COUNTY: '043',
    NAME: 'Clayton',
    LSAD: 'County',
    CENSUSAREA: 778.539,
    FIPS: 'IA043',
    fips: '19043',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 42.84452651,
    lng: -91.34106377,
  },
  {
    GEO_ID: '0500000US19049',
    STATE: '19',
    COUNTY: '049',
    NAME: 'Dallas',
    LSAD: 'County',
    CENSUSAREA: 588.447,
    FIPS: 'IA049',
    fips: '19049',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 41.68489264,
    lng: -94.03970701,
  },
  {
    GEO_ID: '0500000US19057',
    STATE: '19',
    COUNTY: '057',
    NAME: 'Des Moines',
    LSAD: 'County',
    CENSUSAREA: 416.123,
    FIPS: 'IA057',
    fips: '19057',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 40.92294179,
    lng: -91.18142629,
  },
  {
    GEO_ID: '0500000US19073',
    STATE: '19',
    COUNTY: '073',
    NAME: 'Greene',
    LSAD: 'County',
    CENSUSAREA: 569.574,
    FIPS: 'IA073',
    fips: '19073',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 42.03614551,
    lng: -94.39658081,
  },
  {
    GEO_ID: '0500000US19081',
    STATE: '19',
    COUNTY: '081',
    NAME: 'Hancock',
    LSAD: 'County',
    CENSUSAREA: 571.005,
    FIPS: 'IA081',
    fips: '19081',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 43.08178477,
    lng: -93.73454884,
  },
  {
    GEO_ID: '0500000US19091',
    STATE: '19',
    COUNTY: '091',
    NAME: 'Humboldt',
    LSAD: 'County',
    CENSUSAREA: 434.352,
    FIPS: 'IA091',
    fips: '19091',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 42.7764426,
    lng: -94.20722537,
  },
  {
    GEO_ID: '0500000US19099',
    STATE: '19',
    COUNTY: '099',
    NAME: 'Jasper',
    LSAD: 'County',
    CENSUSAREA: 730.42,
    FIPS: 'IA099',
    fips: '19099',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 41.6860352,
    lng: -93.05376026,
  },
  {
    GEO_ID: '0500000US19115',
    STATE: '19',
    COUNTY: '115',
    NAME: 'Louisa',
    LSAD: 'County',
    CENSUSAREA: 401.766,
    FIPS: 'IA115',
    fips: '19115',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 41.21843405,
    lng: -91.25958262,
  },
  {
    GEO_ID: '0500000US19131',
    STATE: '19',
    COUNTY: '131',
    NAME: 'Mitchell',
    LSAD: 'County',
    CENSUSAREA: 469.133,
    FIPS: 'IA131',
    fips: '19131',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 43.35639379,
    lng: -92.78887576,
  },
  {
    GEO_ID: '0500000US19147',
    STATE: '19',
    COUNTY: '147',
    NAME: 'Palo Alto',
    LSAD: 'County',
    CENSUSAREA: 563.844,
    FIPS: 'IA147',
    fips: '19147',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 43.08193849,
    lng: -94.67824621,
  },
  {
    GEO_ID: '0500000US19157',
    STATE: '19',
    COUNTY: '157',
    NAME: 'Poweshiek',
    LSAD: 'County',
    CENSUSAREA: 584.932,
    FIPS: 'IA157',
    fips: '19157',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 41.68657418,
    lng: -92.53097077,
  },
  {
    GEO_ID: '0500000US19165',
    STATE: '19',
    COUNTY: '165',
    NAME: 'Shelby',
    LSAD: 'County',
    CENSUSAREA: 590.779,
    FIPS: 'IA165',
    fips: '19165',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 41.6850066,
    lng: -95.31015553,
  },
  {
    GEO_ID: '0500000US19179',
    STATE: '19',
    COUNTY: '179',
    NAME: 'Wapello',
    LSAD: 'County',
    CENSUSAREA: 431.833,
    FIPS: 'IA179',
    fips: '19179',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 41.03064984,
    lng: -92.40954728,
  },
  {
    GEO_ID: '0500000US19187',
    STATE: '19',
    COUNTY: '187',
    NAME: 'Webster',
    LSAD: 'County',
    CENSUSAREA: 715.619,
    FIPS: 'IA187',
    fips: '19187',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 42.42794934,
    lng: -94.18176099,
  },
  {
    GEO_ID: '0500000US19197',
    STATE: '19',
    COUNTY: '197',
    NAME: 'Wright',
    LSAD: 'County',
    CENSUSAREA: 580.422,
    FIPS: 'IA197',
    fips: '19197',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 42.73305373,
    lng: -93.73515113,
  },
  {
    GEO_ID: '0500000US39031',
    STATE: '39',
    COUNTY: '031',
    NAME: 'Coshocton',
    LSAD: 'County',
    CENSUSAREA: 563.913,
    FIPS: 'OH031',
    fips: '39031',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 40.30215268,
    lng: -81.91980212,
  },
  {
    GEO_ID: '0500000US39045',
    STATE: '39',
    COUNTY: '045',
    NAME: 'Fairfield',
    LSAD: 'County',
    CENSUSAREA: 504.411,
    FIPS: 'OH045',
    fips: '39045',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 39.75164711,
    lng: -82.63064275,
  },
  {
    GEO_ID: '0500000US35013',
    STATE: '35',
    COUNTY: '013',
    NAME: 'Doña Ana',
    LSAD: 'County',
    CENSUSAREA: 3807.511,
    FIPS: 'NM013',
    fips: '35013',
    StateAbbr: 'NM',
    StateName: 'New Mexico',
    lat: 32.35265229,
    lng: -106.8330858,
  },
  {
    GEO_ID: '0500000US35017',
    STATE: '35',
    COUNTY: '017',
    NAME: 'Grant',
    LSAD: 'County',
    CENSUSAREA: 3961.625,
    FIPS: 'NM017',
    fips: '35017',
    StateAbbr: 'NM',
    StateName: 'New Mexico',
    lat: 32.73885662,
    lng: -108.3823282,
  },
  {
    GEO_ID: '0500000US35037',
    STATE: '35',
    COUNTY: '037',
    NAME: 'Quay',
    LSAD: 'County',
    CENSUSAREA: 2874.349,
    FIPS: 'NM037',
    fips: '35037',
    StateAbbr: 'NM',
    StateName: 'New Mexico',
    lat: 35.10459783,
    lng: -103.5493944,
  },
  {
    GEO_ID: '0500000US35059',
    STATE: '35',
    COUNTY: '059',
    NAME: 'Union',
    LSAD: 'County',
    CENSUSAREA: 3823.744,
    FIPS: 'NM059',
    fips: '35059',
    StateAbbr: 'NM',
    StateName: 'New Mexico',
    lat: 36.48166105,
    lng: -103.4710696,
  },
  {
    GEO_ID: '0500000US36017',
    STATE: '36',
    COUNTY: '017',
    NAME: 'Chenango',
    LSAD: 'County',
    CENSUSAREA: 893.548,
    FIPS: 'NY017',
    fips: '36017',
    StateAbbr: 'NY',
    StateName: 'New York',
    lat: 42.49352629,
    lng: -75.61144344,
  },
  {
    GEO_ID: '0500000US36031',
    STATE: '36',
    COUNTY: '031',
    NAME: 'Essex',
    LSAD: 'County',
    CENSUSAREA: 1794.228,
    FIPS: 'NY031',
    fips: '36031',
    StateAbbr: 'NY',
    StateName: 'New York',
    lat: 44.11702035,
    lng: -73.77248171,
  },
  {
    GEO_ID: '0500000US36049',
    STATE: '36',
    COUNTY: '049',
    NAME: 'Lewis',
    LSAD: 'County',
    CENSUSAREA: 1274.679,
    FIPS: 'NY049',
    fips: '36049',
    StateAbbr: 'NY',
    StateName: 'New York',
    lat: 43.78465035,
    lng: -75.44886742,
  },
  {
    GEO_ID: '0500000US36065',
    STATE: '36',
    COUNTY: '065',
    NAME: 'Oneida',
    LSAD: 'County',
    CENSUSAREA: 1212.429,
    FIPS: 'NY065',
    fips: '36065',
    StateAbbr: 'NY',
    StateName: 'New York',
    lat: 43.24167426,
    lng: -75.4358883,
  },
  {
    GEO_ID: '0500000US36077',
    STATE: '36',
    COUNTY: '077',
    NAME: 'Otsego',
    LSAD: 'County',
    CENSUSAREA: 1001.7,
    FIPS: 'NY077',
    fips: '36077',
    StateAbbr: 'NY',
    StateName: 'New York',
    lat: 42.63374775,
    lng: -75.03243898,
  },
  {
    GEO_ID: '0500000US51079',
    STATE: '51',
    COUNTY: '079',
    NAME: 'Greene',
    LSAD: 'County',
    CENSUSAREA: 156.246,
    FIPS: 'VA079',
    fips: '51079',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 38.29731238,
    lng: -78.46704277,
  },
  {
    GEO_ID: '0500000US12129',
    STATE: '12',
    COUNTY: '129',
    NAME: 'Wakulla',
    LSAD: 'County',
    CENSUSAREA: 606.419,
    FIPS: 'FL129',
    fips: '12129',
    StateAbbr: 'FL',
    StateName: 'Florida',
    lat: 30.16603091,
    lng: -84.40005301,
  },
  {
    GEO_ID: '0500000US13011',
    STATE: '13',
    COUNTY: '011',
    NAME: 'Banks',
    LSAD: 'County',
    CENSUSAREA: 232.091,
    FIPS: 'GA011',
    fips: '13011',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 34.35397657,
    lng: -83.49753835,
  },
  {
    GEO_ID: '0500000US13019',
    STATE: '13',
    COUNTY: '019',
    NAME: 'Berrien',
    LSAD: 'County',
    CENSUSAREA: 451.897,
    FIPS: 'GA019',
    fips: '13019',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 31.27569067,
    lng: -83.22972091,
  },
  {
    GEO_ID: '0500000US13027',
    STATE: '13',
    COUNTY: '027',
    NAME: 'Brooks',
    LSAD: 'County',
    CENSUSAREA: 493.046,
    FIPS: 'GA027',
    fips: '13027',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 30.84191162,
    lng: -83.57966245,
  },
  {
    GEO_ID: '0500000US13033',
    STATE: '13',
    COUNTY: '033',
    NAME: 'Burke',
    LSAD: 'County',
    CENSUSAREA: 826.968,
    FIPS: 'GA033',
    fips: '13033',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 33.0611801,
    lng: -82.00066679,
  },
  {
    GEO_ID: '0500000US13039',
    STATE: '13',
    COUNTY: '039',
    NAME: 'Camden',
    LSAD: 'County',
    CENSUSAREA: 613.025,
    FIPS: 'GA039',
    fips: '13039',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 30.93041791,
    lng: -81.67060271,
  },
  {
    GEO_ID: '0500000US13051',
    STATE: '13',
    COUNTY: '051',
    NAME: 'Chatham',
    LSAD: 'County',
    CENSUSAREA: 426.437,
    FIPS: 'GA051',
    fips: '13051',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 32.00445256,
    lng: -81.13247732,
  },
  {
    GEO_ID: '0500000US13061',
    STATE: '13',
    COUNTY: '061',
    NAME: 'Clay',
    LSAD: 'County',
    CENSUSAREA: 195.381,
    FIPS: 'GA061',
    fips: '13061',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 31.62573219,
    lng: -84.97811756,
  },
  {
    GEO_ID: '0500000US02240',
    STATE: '02',
    COUNTY: '240',
    NAME: 'Southeast Fairbanks',
    LSAD: 'CA',
    CENSUSAREA: 24768.808,
    FIPS: 'AK240',
    fips: '02240',
    StateAbbr: 'AK',
    StateName: 'Alaska',
    lat: 63.87695823,
    lng: -143.2069204,
  },
  {
    GEO_ID: '0500000US05149',
    STATE: '05',
    COUNTY: '149',
    NAME: 'Yell',
    LSAD: 'County',
    CENSUSAREA: 929.984,
    FIPS: 'AR149',
    fips: '05149',
    StateAbbr: 'AR',
    StateName: 'Arkansas',
    lat: 35.00270728,
    lng: -93.41105962,
  },
  {
    GEO_ID: '0500000US06011',
    STATE: '06',
    COUNTY: '011',
    NAME: 'Colusa',
    LSAD: 'County',
    CENSUSAREA: 1150.731,
    FIPS: 'CA011',
    fips: '06011',
    StateAbbr: 'CA',
    StateName: 'California',
    lat: 39.17719198,
    lng: -122.2363987,
  },
  {
    GEO_ID: '0500000US06027',
    STATE: '06',
    COUNTY: '027',
    NAME: 'Inyo',
    LSAD: 'County',
    CENSUSAREA: 10180.879,
    FIPS: 'CA027',
    fips: '06027',
    StateAbbr: 'CA',
    StateName: 'California',
    lat: 36.51173415,
    lng: -117.4113187,
  },
  {
    GEO_ID: '0500000US06047',
    STATE: '06',
    COUNTY: '047',
    NAME: 'Merced',
    LSAD: 'County',
    CENSUSAREA: 1934.972,
    FIPS: 'CA047',
    fips: '06047',
    StateAbbr: 'CA',
    StateName: 'California',
    lat: 37.19189197,
    lng: -120.7175058,
  },
  {
    GEO_ID: '0500000US06063',
    STATE: '06',
    COUNTY: '063',
    NAME: 'Plumas',
    LSAD: 'County',
    CENSUSAREA: 2553.043,
    FIPS: 'CA063',
    fips: '06063',
    StateAbbr: 'CA',
    StateName: 'California',
    lat: 40.00422172,
    lng: -120.8382348,
  },
  {
    GEO_ID: '0500000US06077',
    STATE: '06',
    COUNTY: '077',
    NAME: 'San Joaquin',
    LSAD: 'County',
    CENSUSAREA: 1391.322,
    FIPS: 'CA077',
    fips: '06077',
    StateAbbr: 'CA',
    StateName: 'California',
    lat: 37.9345275,
    lng: -121.2712102,
  },
  {
    GEO_ID: '0500000US06091',
    STATE: '06',
    COUNTY: '091',
    NAME: 'Sierra',
    LSAD: 'County',
    CENSUSAREA: 953.214,
    FIPS: 'CA091',
    fips: '06091',
    StateAbbr: 'CA',
    StateName: 'California',
    lat: 39.58019892,
    lng: -120.5162324,
  },
  {
    GEO_ID: '0500000US06107',
    STATE: '06',
    COUNTY: '107',
    NAME: 'Tulare',
    LSAD: 'County',
    CENSUSAREA: 4824.215,
    FIPS: 'CA107',
    fips: '06107',
    StateAbbr: 'CA',
    StateName: 'California',
    lat: 36.22081997,
    lng: -118.8004216,
  },
  {
    GEO_ID: '0500000US08011',
    STATE: '08',
    COUNTY: '011',
    NAME: 'Bent',
    LSAD: 'County',
    CENSUSAREA: 1512.861,
    FIPS: 'CO011',
    fips: '08011',
    StateAbbr: 'CO',
    StateName: 'Colorado',
    lat: 37.95492416,
    lng: -103.0720227,
  },
  {
    GEO_ID: '0500000US08019',
    STATE: '08',
    COUNTY: '019',
    NAME: 'Clear Creek',
    LSAD: 'County',
    CENSUSAREA: 395.227,
    FIPS: 'CO019',
    fips: '08019',
    StateAbbr: 'CO',
    StateName: 'Colorado',
    lat: 39.68922182,
    lng: -105.6447627,
  },
  {
    GEO_ID: '0500000US08027',
    STATE: '08',
    COUNTY: '027',
    NAME: 'Custer',
    LSAD: 'County',
    CENSUSAREA: 738.625,
    FIPS: 'CO027',
    fips: '08027',
    StateAbbr: 'CO',
    StateName: 'Colorado',
    lat: 38.10882281,
    lng: -105.3670379,
  },
  {
    GEO_ID: '0500000US20013',
    STATE: '20',
    COUNTY: '013',
    NAME: 'Brown',
    LSAD: 'County',
    CENSUSAREA: 570.872,
    FIPS: 'KS013',
    fips: '20013',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 39.8265096,
    lng: -95.56422358,
  },
  {
    GEO_ID: '0500000US20023',
    STATE: '20',
    COUNTY: '023',
    NAME: 'Cheyenne',
    LSAD: 'County',
    CENSUSAREA: 1019.887,
    FIPS: 'KS023',
    fips: '20023',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 39.78580547,
    lng: -101.7318625,
  },
  {
    GEO_ID: '0500000US27071',
    STATE: '27',
    COUNTY: '071',
    NAME: 'Koochiching',
    LSAD: 'County',
    CENSUSAREA: 3104.07,
    FIPS: 'MN071',
    fips: '27071',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 48.24510754,
    lng: -93.78362729,
  },
  {
    GEO_ID: '0500000US27079',
    STATE: '27',
    COUNTY: '079',
    NAME: 'Le Sueur',
    LSAD: 'County',
    CENSUSAREA: 448.76,
    FIPS: 'MN079',
    fips: '27079',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 44.37144158,
    lng: -93.72984216,
  },
  {
    GEO_ID: '0500000US27087',
    STATE: '27',
    COUNTY: '087',
    NAME: 'Mahnomen',
    LSAD: 'County',
    CENSUSAREA: 557.878,
    FIPS: 'MN087',
    fips: '27087',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 47.32558784,
    lng: -95.8091992,
  },
  {
    GEO_ID: '0500000US27101',
    STATE: '27',
    COUNTY: '101',
    NAME: 'Murray',
    LSAD: 'County',
    CENSUSAREA: 704.702,
    FIPS: 'MN101',
    fips: '27101',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 44.02216709,
    lng: -95.76328482,
  },
  {
    GEO_ID: '0500000US27107',
    STATE: '27',
    COUNTY: '107',
    NAME: 'Norman',
    LSAD: 'County',
    CENSUSAREA: 872.789,
    FIPS: 'MN107',
    fips: '27107',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 47.32618622,
    lng: -96.45566228,
  },
  {
    GEO_ID: '0500000US27125',
    STATE: '27',
    COUNTY: '125',
    NAME: 'Red Lake',
    LSAD: 'County',
    CENSUSAREA: 432.407,
    FIPS: 'MN125',
    fips: '27125',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 47.87197525,
    lng: -96.09561104,
  },
  {
    GEO_ID: '0500000US27131',
    STATE: '27',
    COUNTY: '131',
    NAME: 'Rice',
    LSAD: 'County',
    CENSUSAREA: 495.684,
    FIPS: 'MN131',
    fips: '27131',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 44.35432778,
    lng: -93.2965986,
  },
  {
    GEO_ID: '0500000US27143',
    STATE: '27',
    COUNTY: '143',
    NAME: 'Sibley',
    LSAD: 'County',
    CENSUSAREA: 588.779,
    FIPS: 'MN143',
    fips: '27143',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 44.5793954,
    lng: -94.23165958,
  },
  {
    GEO_ID: '0500000US27159',
    STATE: '27',
    COUNTY: '159',
    NAME: 'Wadena',
    LSAD: 'County',
    CENSUSAREA: 536.268,
    FIPS: 'MN159',
    fips: '27159',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 46.5856535,
    lng: -94.9700148,
  },
  {
    GEO_ID: '0500000US27169',
    STATE: '27',
    COUNTY: '169',
    NAME: 'Winona',
    LSAD: 'County',
    CENSUSAREA: 626.206,
    FIPS: 'MN169',
    fips: '27169',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 43.98683746,
    lng: -91.77934467,
  },
  {
    GEO_ID: '0500000US28007',
    STATE: '28',
    COUNTY: '007',
    NAME: 'Attala',
    LSAD: 'County',
    CENSUSAREA: 734.982,
    FIPS: 'MS007',
    fips: '28007',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 33.08676427,
    lng: -89.58086337,
  },
  {
    GEO_ID: '0500000US28023',
    STATE: '28',
    COUNTY: '023',
    NAME: 'Clarke',
    LSAD: 'County',
    CENSUSAREA: 691.554,
    FIPS: 'MS023',
    fips: '28023',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 32.04139674,
    lng: -88.68928948,
  },
  {
    GEO_ID: '0500000US28035',
    STATE: '28',
    COUNTY: '035',
    NAME: 'Forrest',
    LSAD: 'County',
    CENSUSAREA: 466.314,
    FIPS: 'MS035',
    fips: '28035',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 31.18899065,
    lng: -89.25794407,
  },
  {
    GEO_ID: '0500000US28043',
    STATE: '28',
    COUNTY: '043',
    NAME: 'Grenada',
    LSAD: 'County',
    CENSUSAREA: 422.107,
    FIPS: 'MS043',
    fips: '28043',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 33.76997916,
    lng: -89.80171673,
  },
  {
    GEO_ID: '0500000US36091',
    STATE: '36',
    COUNTY: '091',
    NAME: 'Saratoga',
    LSAD: 'County',
    CENSUSAREA: 809.984,
    FIPS: 'NY091',
    fips: '36091',
    StateAbbr: 'NY',
    StateName: 'New York',
    lat: 43.10743063,
    lng: -73.86394518,
  },
  {
    GEO_ID: '0500000US36101',
    STATE: '36',
    COUNTY: '101',
    NAME: 'Steuben',
    LSAD: 'County',
    CENSUSAREA: 1390.559,
    FIPS: 'NY101',
    fips: '36101',
    StateAbbr: 'NY',
    StateName: 'New York',
    lat: 42.26788874,
    lng: -77.3834881,
  },
  {
    GEO_ID: '0500000US36113',
    STATE: '36',
    COUNTY: '113',
    NAME: 'Warren',
    LSAD: 'County',
    CENSUSAREA: 866.952,
    FIPS: 'NY113',
    fips: '36113',
    StateAbbr: 'NY',
    StateName: 'New York',
    lat: 43.56091887,
    lng: -73.84603737,
  },
  {
    GEO_ID: '0500000US42011',
    STATE: '42',
    COUNTY: '011',
    NAME: 'Berks',
    LSAD: 'County',
    CENSUSAREA: 856.506,
    FIPS: 'PA011',
    fips: '42011',
    StateAbbr: 'PA',
    StateName: 'Pennsylvania',
    lat: 40.41644424,
    lng: -75.92604322,
  },
  {
    GEO_ID: '0500000US42019',
    STATE: '42',
    COUNTY: '019',
    NAME: 'Butler',
    LSAD: 'County',
    CENSUSAREA: 788.604,
    FIPS: 'PA019',
    fips: '42019',
    StateAbbr: 'PA',
    StateName: 'Pennsylvania',
    lat: 40.91176277,
    lng: -79.91342345,
  },
  {
    GEO_ID: '0500000US42025',
    STATE: '42',
    COUNTY: '025',
    NAME: 'Carbon',
    LSAD: 'County',
    CENSUSAREA: 381.46,
    FIPS: 'PA025',
    fips: '42025',
    StateAbbr: 'PA',
    StateName: 'Pennsylvania',
    lat: 40.91846484,
    lng: -75.70851105,
  },
  {
    GEO_ID: '0500000US42037',
    STATE: '42',
    COUNTY: '037',
    NAME: 'Columbia',
    LSAD: 'County',
    CENSUSAREA: 483.108,
    FIPS: 'PA037',
    fips: '42037',
    StateAbbr: 'PA',
    StateName: 'Pennsylvania',
    lat: 41.04903639,
    lng: -76.4051503,
  },
  {
    GEO_ID: '0500000US42053',
    STATE: '42',
    COUNTY: '053',
    NAME: 'Forest',
    LSAD: 'County',
    CENSUSAREA: 427.185,
    FIPS: 'PA053',
    fips: '42053',
    StateAbbr: 'PA',
    StateName: 'Pennsylvania',
    lat: 41.51230258,
    lng: -79.23591856,
  },
  {
    GEO_ID: '0500000US42057',
    STATE: '42',
    COUNTY: '057',
    NAME: 'Fulton',
    LSAD: 'County',
    CENSUSAREA: 437.551,
    FIPS: 'PA057',
    fips: '42057',
    StateAbbr: 'PA',
    StateName: 'Pennsylvania',
    lat: 39.92545432,
    lng: -78.11276349,
  },
  {
    GEO_ID: '0500000US42075',
    STATE: '42',
    COUNTY: '075',
    NAME: 'Lebanon',
    LSAD: 'County',
    CENSUSAREA: 361.833,
    FIPS: 'PA075',
    fips: '42075',
    StateAbbr: 'PA',
    StateName: 'Pennsylvania',
    lat: 40.36716938,
    lng: -76.45759104,
  },
  {
    GEO_ID: '0500000US42081',
    STATE: '42',
    COUNTY: '081',
    NAME: 'Lycoming',
    LSAD: 'County',
    CENSUSAREA: 1228.594,
    FIPS: 'PA081',
    fips: '42081',
    StateAbbr: 'PA',
    StateName: 'Pennsylvania',
    lat: 41.34394419,
    lng: -77.06520269,
  },
  {
    GEO_ID: '0500000US42099',
    STATE: '42',
    COUNTY: '099',
    NAME: 'Perry',
    LSAD: 'County',
    CENSUSAREA: 551.445,
    FIPS: 'PA099',
    fips: '42099',
    StateAbbr: 'PA',
    StateName: 'Pennsylvania',
    lat: 40.39865716,
    lng: -77.2618726,
  },
  {
    GEO_ID: '0500000US42117',
    STATE: '42',
    COUNTY: '117',
    NAME: 'Tioga',
    LSAD: 'County',
    CENSUSAREA: 1133.788,
    FIPS: 'PA117',
    fips: '42117',
    StateAbbr: 'PA',
    StateName: 'Pennsylvania',
    lat: 41.77283534,
    lng: -77.25438714,
  },
  {
    GEO_ID: '0500000US42131',
    STATE: '42',
    COUNTY: '131',
    NAME: 'Wyoming',
    LSAD: 'County',
    CENSUSAREA: 397.324,
    FIPS: 'PA131',
    fips: '42131',
    StateAbbr: 'PA',
    StateName: 'Pennsylvania',
    lat: 41.51833347,
    lng: -76.01693959,
  },
  {
    GEO_ID: '0500000US72149',
    STATE: '72',
    COUNTY: '149',
    NAME: 'Villalba',
    LSAD: 'Muno',
    CENSUSAREA: 35.637,
    FIPS: 'PR149',
    fips: '72149',
    StateAbbr: 'PR',
    StateName: 'Puerto Rico',
    lat: 18.12824636,
    lng: -66.47175368,
  },
  {
    GEO_ID: '0500000US01005',
    STATE: '01',
    COUNTY: '005',
    NAME: 'Barbour',
    LSAD: 'County',
    CENSUSAREA: 884.876,
    FIPS: 'AL005',
    fips: '01005',
    StateAbbr: 'AL',
    StateName: 'Alabama',
    lat: 31.87009042,
    lng: -85.39106787,
  },
  {
    GEO_ID: '0500000US01013',
    STATE: '01',
    COUNTY: '013',
    NAME: 'Butler',
    LSAD: 'County',
    CENSUSAREA: 776.829,
    FIPS: 'AL013',
    fips: '01013',
    StateAbbr: 'AL',
    StateName: 'Alabama',
    lat: 31.75252432,
    lng: -86.68040924,
  },
  {
    GEO_ID: '0500000US01019',
    STATE: '01',
    COUNTY: '019',
    NAME: 'Cherokee',
    LSAD: 'County',
    CENSUSAREA: 553.7,
    FIPS: 'AL019',
    fips: '01019',
    StateAbbr: 'AL',
    StateName: 'Alabama',
    lat: 34.17588713,
    lng: -85.60387066,
  },
  {
    GEO_ID: '0500000US06041',
    STATE: '06',
    COUNTY: '041',
    NAME: 'Marin',
    LSAD: 'County',
    CENSUSAREA: 520.306,
    FIPS: 'CA041',
    fips: '06041',
    StateAbbr: 'CA',
    StateName: 'California',
    lat: 38.07351203,
    lng: -122.7234103,
  },
  {
    GEO_ID: '0500000US06053',
    STATE: '06',
    COUNTY: '053',
    NAME: 'Monterey',
    LSAD: 'County',
    CENSUSAREA: 3280.595,
    FIPS: 'CA053',
    fips: '06053',
    StateAbbr: 'CA',
    StateName: 'California',
    lat: 36.21711865,
    lng: -121.2387503,
  },
  {
    GEO_ID: '0500000US06061',
    STATE: '06',
    COUNTY: '061',
    NAME: 'Placer',
    LSAD: 'County',
    CENSUSAREA: 1407.009,
    FIPS: 'CA061',
    fips: '06061',
    StateAbbr: 'CA',
    StateName: 'California',
    lat: 39.06347097,
    lng: -120.717846,
  },
  {
    GEO_ID: '0500000US02090',
    STATE: '02',
    COUNTY: '090',
    NAME: 'Fairbanks North Star',
    LSAD: 'Borough',
    CENSUSAREA: 7338.209,
    FIPS: 'AK090',
    fips: '02090',
    StateAbbr: 'AK',
    StateName: 'Alaska',
    lat: 64.8083752,
    lng: -146.5623662,
  },
  {
    GEO_ID: '0500000US08041',
    STATE: '08',
    COUNTY: '041',
    NAME: 'El Paso',
    LSAD: 'County',
    CENSUSAREA: 2126.801,
    FIPS: 'CO041',
    fips: '08041',
    StateAbbr: 'CO',
    StateName: 'Colorado',
    lat: 38.83242336,
    lng: -104.5257538,
  },
  {
    GEO_ID: '0500000US08049',
    STATE: '08',
    COUNTY: '049',
    NAME: 'Grand',
    LSAD: 'County',
    CENSUSAREA: 1846.33,
    FIPS: 'CO049',
    fips: '08049',
    StateAbbr: 'CO',
    StateName: 'Colorado',
    lat: 40.10246658,
    lng: -106.1184564,
  },
  {
    GEO_ID: '0500000US12085',
    STATE: '12',
    COUNTY: '085',
    NAME: 'Martin',
    LSAD: 'County',
    CENSUSAREA: 543.464,
    FIPS: 'FL085',
    fips: '12085',
    StateAbbr: 'FL',
    StateName: 'Florida',
    lat: 27.07737178,
    lng: -80.43133534,
  },
  {
    GEO_ID: '0500000US12093',
    STATE: '12',
    COUNTY: '093',
    NAME: 'Okeechobee',
    LSAD: 'County',
    CENSUSAREA: 768.908,
    FIPS: 'FL093',
    fips: '12093',
    StateAbbr: 'FL',
    StateName: 'Florida',
    lat: 27.3866315,
    lng: -80.88873614,
  },
  {
    GEO_ID: '0500000US12105',
    STATE: '12',
    COUNTY: '105',
    NAME: 'Polk',
    LSAD: 'County',
    CENSUSAREA: 1797.837,
    FIPS: 'FL105',
    fips: '12105',
    StateAbbr: 'FL',
    StateName: 'Florida',
    lat: 27.94872638,
    lng: -81.69711072,
  },
  {
    GEO_ID: '0500000US12121',
    STATE: '12',
    COUNTY: '121',
    NAME: 'Suwannee',
    LSAD: 'County',
    CENSUSAREA: 688.552,
    FIPS: 'FL121',
    fips: '12121',
    StateAbbr: 'FL',
    StateName: 'Florida',
    lat: 30.19562156,
    lng: -82.99147597,
  },
  {
    GEO_ID: '0500000US12133',
    STATE: '12',
    COUNTY: '133',
    NAME: 'Washington',
    LSAD: 'County',
    CENSUSAREA: 582.803,
    FIPS: 'FL133',
    fips: '12133',
    StateAbbr: 'FL',
    StateName: 'Florida',
    lat: 30.61054912,
    lng: -85.66575949,
  },
  {
    GEO_ID: '0500000US13007',
    STATE: '13',
    COUNTY: '007',
    NAME: 'Baker',
    LSAD: 'County',
    CENSUSAREA: 341.944,
    FIPS: 'GA007',
    fips: '13007',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 31.32631349,
    lng: -84.44486551,
  },
  {
    GEO_ID: '0500000US13021',
    STATE: '13',
    COUNTY: '021',
    NAME: 'Bibb',
    LSAD: 'County',
    CENSUSAREA: 249.762,
    FIPS: 'GA021',
    fips: '13021',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 32.80691473,
    lng: -83.69682554,
  },
  {
    GEO_ID: '0500000US13035',
    STATE: '13',
    COUNTY: '035',
    NAME: 'Butts',
    LSAD: 'County',
    CENSUSAREA: 184.393,
    FIPS: 'GA035',
    fips: '13035',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 33.28698774,
    lng: -83.95691187,
  },
  {
    GEO_ID: '0500000US13047',
    STATE: '13',
    COUNTY: '047',
    NAME: 'Catoosa',
    LSAD: 'County',
    CENSUSAREA: 162.159,
    FIPS: 'GA047',
    fips: '13047',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 34.90377849,
    lng: -85.13865749,
  },
  {
    GEO_ID: '0500000US13065',
    STATE: '13',
    COUNTY: '065',
    NAME: 'Clinch',
    LSAD: 'County',
    CENSUSAREA: 800.222,
    FIPS: 'GA065',
    fips: '13065',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 30.91504263,
    lng: -82.7064204,
  },
  {
    GEO_ID: '0500000US13075',
    STATE: '13',
    COUNTY: '075',
    NAME: 'Cook',
    LSAD: 'County',
    CENSUSAREA: 227.162,
    FIPS: 'GA075',
    fips: '13075',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 31.15408665,
    lng: -83.43069759,
  },
  {
    GEO_ID: '0500000US28057',
    STATE: '28',
    COUNTY: '057',
    NAME: 'Itawamba',
    LSAD: 'County',
    CENSUSAREA: 532.787,
    FIPS: 'MS057',
    fips: '28057',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 34.27995113,
    lng: -88.36140611,
  },
  {
    GEO_ID: '0500000US28069',
    STATE: '28',
    COUNTY: '069',
    NAME: 'Kemper',
    LSAD: 'County',
    CENSUSAREA: 766.182,
    FIPS: 'MS069',
    fips: '28069',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 32.75459457,
    lng: -88.64097868,
  },
  {
    GEO_ID: '0500000US22119',
    STATE: '22',
    COUNTY: '119',
    NAME: 'Webster',
    LSAD: 'Parish',
    CENSUSAREA: 593.03,
    FIPS: 'LA119',
    fips: '22119',
    StateAbbr: 'LA',
    StateName: 'Louisiana',
    lat: 32.71375647,
    lng: -93.33431354,
  },
  {
    GEO_ID: '0500000US22125',
    STATE: '22',
    COUNTY: '125',
    NAME: 'West Feliciana',
    LSAD: 'Parish',
    CENSUSAREA: 403.214,
    FIPS: 'LA125',
    fips: '22125',
    StateAbbr: 'LA',
    StateName: 'Louisiana',
    lat: 30.88038848,
    lng: -91.42038292,
  },
  {
    GEO_ID: '0500000US23017',
    STATE: '23',
    COUNTY: '017',
    NAME: 'Oxford',
    LSAD: 'County',
    CENSUSAREA: 2076.84,
    FIPS: 'ME017',
    fips: '23017',
    StateAbbr: 'ME',
    StateName: 'Maine',
    lat: 44.49924446,
    lng: -70.75611419,
  },
  {
    GEO_ID: '0500000US24015',
    STATE: '24',
    COUNTY: '015',
    NAME: 'Cecil',
    LSAD: 'County',
    CENSUSAREA: 346.273,
    FIPS: 'MD015',
    fips: '24015',
    StateAbbr: 'MD',
    StateName: 'Maryland',
    lat: 39.57067423,
    lng: -75.94133759,
  },
  {
    GEO_ID: '0500000US24027',
    STATE: '24',
    COUNTY: '027',
    NAME: 'Howard',
    LSAD: 'County',
    CENSUSAREA: 250.741,
    FIPS: 'MD027',
    fips: '24027',
    StateAbbr: 'MD',
    StateName: 'Maryland',
    lat: 39.25078653,
    lng: -76.93099926,
  },
  {
    GEO_ID: '0500000US25001',
    STATE: '25',
    COUNTY: '001',
    NAME: 'Barnstable',
    LSAD: 'County',
    CENSUSAREA: 393.723,
    FIPS: 'MA001',
    fips: '25001',
    StateAbbr: 'MA',
    StateName: 'Massachusetts',
    lat: 41.7232979,
    lng: -70.2898624,
  },
  {
    GEO_ID: '0500000US25003',
    STATE: '25',
    COUNTY: '003',
    NAME: 'Berkshire',
    LSAD: 'County',
    CENSUSAREA: 926.825,
    FIPS: 'MA003',
    fips: '25003',
    StateAbbr: 'MA',
    StateName: 'Massachusetts',
    lat: 42.37045737,
    lng: -73.2062907,
  },
  {
    GEO_ID: '0500000US25027',
    STATE: '25',
    COUNTY: '027',
    NAME: 'Worcester',
    LSAD: 'County',
    CENSUSAREA: 1510.77,
    FIPS: 'MA027',
    fips: '25027',
    StateAbbr: 'MA',
    StateName: 'Massachusetts',
    lat: 42.35111931,
    lng: -71.90780992,
  },
  {
    GEO_ID: '0500000US26025',
    STATE: '26',
    COUNTY: '025',
    NAME: 'Calhoun',
    LSAD: 'County',
    CENSUSAREA: 706.233,
    FIPS: 'MI025',
    fips: '26025',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 42.24636617,
    lng: -85.00508394,
  },
  {
    GEO_ID: '0500000US26039',
    STATE: '26',
    COUNTY: '039',
    NAME: 'Crawford',
    LSAD: 'County',
    CENSUSAREA: 556.28,
    FIPS: 'MI039',
    fips: '26039',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 44.68323123,
    lng: -84.61024859,
  },
  {
    GEO_ID: '0500000US26049',
    STATE: '26',
    COUNTY: '049',
    NAME: 'Genesee',
    LSAD: 'County',
    CENSUSAREA: 636.977,
    FIPS: 'MI049',
    fips: '26049',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 43.02216976,
    lng: -83.70652806,
  },
  {
    GEO_ID: '0500000US44007',
    STATE: '44',
    COUNTY: '007',
    NAME: 'Providence',
    LSAD: 'County',
    CENSUSAREA: 409.502,
    FIPS: 'RI007',
    fips: '44007',
    StateAbbr: 'RI',
    StateName: 'Rhode Island',
    lat: 41.8721229,
    lng: -71.58004041,
  },
  {
    GEO_ID: '0500000US45021',
    STATE: '45',
    COUNTY: '021',
    NAME: 'Cherokee',
    LSAD: 'County',
    CENSUSAREA: 392.659,
    FIPS: 'SC021',
    fips: '45021',
    StateAbbr: 'SC',
    StateName: 'South Carolina',
    lat: 35.04837373,
    lng: -81.62012099,
  },
  {
    GEO_ID: '0500000US45033',
    STATE: '45',
    COUNTY: '033',
    NAME: 'Dillon',
    LSAD: 'County',
    CENSUSAREA: 404.872,
    FIPS: 'SC033',
    fips: '45033',
    StateAbbr: 'SC',
    StateName: 'South Carolina',
    lat: 34.39138915,
    lng: -79.37858369,
  },
  {
    GEO_ID: '0500000US45045',
    STATE: '45',
    COUNTY: '045',
    NAME: 'Greenville',
    LSAD: 'County',
    CENSUSAREA: 785.12,
    FIPS: 'SC045',
    fips: '45045',
    StateAbbr: 'SC',
    StateName: 'South Carolina',
    lat: 34.89387815,
    lng: -82.37063974,
  },
  {
    GEO_ID: '0500000US47009',
    STATE: '47',
    COUNTY: '009',
    NAME: 'Blount',
    LSAD: 'County',
    CENSUSAREA: 558.706,
    FIPS: 'TN009',
    fips: '47009',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 35.68735498,
    lng: -83.92484736,
  },
  {
    GEO_ID: '0500000US47019',
    STATE: '47',
    COUNTY: '019',
    NAME: 'Carter',
    LSAD: 'County',
    CENSUSAREA: 341.203,
    FIPS: 'TN019',
    fips: '47019',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 36.29228876,
    lng: -82.12769739,
  },
  {
    GEO_ID: '0500000US47037',
    STATE: '47',
    COUNTY: '037',
    NAME: 'Davidson',
    LSAD: 'County',
    CENSUSAREA: 504.033,
    FIPS: 'TN037',
    fips: '47037',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 36.16932462,
    lng: -86.78502875,
  },
  {
    GEO_ID: '0500000US47051',
    STATE: '47',
    COUNTY: '051',
    NAME: 'Franklin',
    LSAD: 'County',
    CENSUSAREA: 554.542,
    FIPS: 'TN051',
    fips: '47051',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 35.15504791,
    lng: -86.09233522,
  },
  {
    GEO_ID: '0500000US47063',
    STATE: '47',
    COUNTY: '063',
    NAME: 'Hamblen',
    LSAD: 'County',
    CENSUSAREA: 161.179,
    FIPS: 'TN063',
    fips: '47063',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 36.2171435,
    lng: -83.26632471,
  },
  {
    GEO_ID: '0500000US47075',
    STATE: '47',
    COUNTY: '075',
    NAME: 'Haywood',
    LSAD: 'County',
    CENSUSAREA: 533.112,
    FIPS: 'TN075',
    fips: '47075',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 35.58313405,
    lng: -89.28418692,
  },
  {
    GEO_ID: '0500000US47085',
    STATE: '47',
    COUNTY: '085',
    NAME: 'Humphreys',
    LSAD: 'County',
    CENSUSAREA: 530.98,
    FIPS: 'TN085',
    fips: '47085',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 36.04110957,
    lng: -87.77571312,
  },
  {
    GEO_ID: '0500000US47099',
    STATE: '47',
    COUNTY: '099',
    NAME: 'Lawrence',
    LSAD: 'County',
    CENSUSAREA: 617.128,
    FIPS: 'TN099',
    fips: '47099',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 35.21729152,
    lng: -87.39523632,
  },
  {
    GEO_ID: '0500000US47113',
    STATE: '47',
    COUNTY: '113',
    NAME: 'Madison',
    LSAD: 'County',
    CENSUSAREA: 557.117,
    FIPS: 'TN113',
    fips: '47113',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 35.60806385,
    lng: -88.83867386,
  },
  {
    GEO_ID: '0500000US47127',
    STATE: '47',
    COUNTY: '127',
    NAME: 'Moore',
    LSAD: 'County',
    CENSUSAREA: 129.223,
    FIPS: 'TN127',
    fips: '47127',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 35.28548298,
    lng: -86.35914773,
  },
  {
    GEO_ID: '0500000US06019',
    STATE: '06',
    COUNTY: '019',
    NAME: 'Fresno',
    LSAD: 'County',
    CENSUSAREA: 5957.991,
    FIPS: 'CA019',
    fips: '06019',
    StateAbbr: 'CA',
    StateName: 'California',
    lat: 36.75807672,
    lng: -119.6506833,
  },
  {
    GEO_ID: '0500000US06023',
    STATE: '06',
    COUNTY: '023',
    NAME: 'Humboldt',
    LSAD: 'County',
    CENSUSAREA: 3567.987,
    FIPS: 'CA023',
    fips: '06023',
    StateAbbr: 'CA',
    StateName: 'California',
    lat: 40.69940115,
    lng: -123.8755258,
  },
  {
    GEO_ID: '0500000US06035',
    STATE: '06',
    COUNTY: '035',
    NAME: 'Lassen',
    LSAD: 'County',
    CENSUSAREA: 4541.184,
    FIPS: 'CA035',
    fips: '06035',
    StateAbbr: 'CA',
    StateName: 'California',
    lat: 40.6733613,
    lng: -120.5947447,
  },
  {
    GEO_ID: '0500000US06037',
    STATE: '06',
    COUNTY: '037',
    NAME: 'Los Angeles',
    LSAD: 'County',
    CENSUSAREA: 4057.884,
    FIPS: 'CA037',
    fips: '06037',
    StateAbbr: 'CA',
    StateName: 'California',
    lat: 34.32134579,
    lng: -118.224703,
  },
  {
    GEO_ID: '0500000US13085',
    STATE: '13',
    COUNTY: '085',
    NAME: 'Dawson',
    LSAD: 'County',
    CENSUSAREA: 210.83,
    FIPS: 'GA085',
    fips: '13085',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 34.44409815,
    lng: -84.17073385,
  },
  {
    GEO_ID: '0500000US13099',
    STATE: '13',
    COUNTY: '099',
    NAME: 'Early',
    LSAD: 'County',
    CENSUSAREA: 512.594,
    FIPS: 'GA099',
    fips: '13099',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 31.32317406,
    lng: -84.90423978,
  },
  {
    GEO_ID: '0500000US13117',
    STATE: '13',
    COUNTY: '117',
    NAME: 'Forsyth',
    LSAD: 'County',
    CENSUSAREA: 224.021,
    FIPS: 'GA117',
    fips: '13117',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 34.2256545,
    lng: -84.12468922,
  },
  {
    GEO_ID: '0500000US08051',
    STATE: '08',
    COUNTY: '051',
    NAME: 'Gunnison',
    LSAD: 'County',
    CENSUSAREA: 3239.1,
    FIPS: 'CO051',
    fips: '08051',
    StateAbbr: 'CO',
    StateName: 'Colorado',
    lat: 38.66684745,
    lng: -107.0316959,
  },
  {
    GEO_ID: '0500000US08055',
    STATE: '08',
    COUNTY: '055',
    NAME: 'Huerfano',
    LSAD: 'County',
    CENSUSAREA: 1591.001,
    FIPS: 'CO055',
    fips: '08055',
    StateAbbr: 'CO',
    StateName: 'Colorado',
    lat: 37.68484082,
    lng: -104.9607082,
  },
  {
    GEO_ID: '0500000US08065',
    STATE: '08',
    COUNTY: '065',
    NAME: 'Lake',
    LSAD: 'County',
    CENSUSAREA: 376.911,
    FIPS: 'CO065',
    fips: '08065',
    StateAbbr: 'CO',
    StateName: 'Colorado',
    lat: 39.20226223,
    lng: -106.344854,
  },
  {
    GEO_ID: '0500000US08079',
    STATE: '08',
    COUNTY: '079',
    NAME: 'Mineral',
    LSAD: 'County',
    CENSUSAREA: 875.666,
    FIPS: 'CO079',
    fips: '08079',
    StateAbbr: 'CO',
    StateName: 'Colorado',
    lat: 37.66954135,
    lng: -106.9241901,
  },
  {
    GEO_ID: '0500000US08089',
    STATE: '08',
    COUNTY: '089',
    NAME: 'Otero',
    LSAD: 'County',
    CENSUSAREA: 1261.961,
    FIPS: 'CO089',
    fips: '08089',
    StateAbbr: 'CO',
    StateName: 'Colorado',
    lat: 37.90245342,
    lng: -103.7166632,
  },
  {
    GEO_ID: '0500000US08101',
    STATE: '08',
    COUNTY: '101',
    NAME: 'Pueblo',
    LSAD: 'County',
    CENSUSAREA: 2386.104,
    FIPS: 'CO101',
    fips: '08101',
    StateAbbr: 'CO',
    StateName: 'Colorado',
    lat: 38.17377693,
    lng: -104.5124892,
  },
  {
    GEO_ID: '0500000US08117',
    STATE: '08',
    COUNTY: '117',
    NAME: 'Summit',
    LSAD: 'County',
    CENSUSAREA: 608.358,
    FIPS: 'CO117',
    fips: '08117',
    StateAbbr: 'CO',
    StateName: 'Colorado',
    lat: 39.63420352,
    lng: -106.1163363,
  },
  {
    GEO_ID: '0500000US09005',
    STATE: '09',
    COUNTY: '005',
    NAME: 'Litchfield',
    LSAD: 'County',
    CENSUSAREA: 920.56,
    FIPS: 'CT005',
    fips: '09005',
    StateAbbr: 'CT',
    StateName: 'Connecticut',
    lat: 41.79242134,
    lng: -73.24526767,
  },
  {
    GEO_ID: '0500000US12007',
    STATE: '12',
    COUNTY: '007',
    NAME: 'Bradford',
    LSAD: 'County',
    CENSUSAREA: 293.962,
    FIPS: 'FL007',
    fips: '12007',
    StateAbbr: 'FL',
    StateName: 'Florida',
    lat: 29.94967764,
    lng: -82.16822073,
  },
  {
    GEO_ID: '0500000US12019',
    STATE: '12',
    COUNTY: '019',
    NAME: 'Clay',
    LSAD: 'County',
    CENSUSAREA: 604.36,
    FIPS: 'FL019',
    fips: '12019',
    StateAbbr: 'FL',
    StateName: 'Florida',
    lat: 29.98317912,
    lng: -81.85783045,
  },
  {
    GEO_ID: '0500000US12027',
    STATE: '12',
    COUNTY: '027',
    NAME: 'DeSoto',
    LSAD: 'County',
    CENSUSAREA: 637.059,
    FIPS: 'FL027',
    fips: '12027',
    StateAbbr: 'FL',
    StateName: 'Florida',
    lat: 27.18691656,
    lng: -81.80959835,
  },
  {
    GEO_ID: '0500000US12035',
    STATE: '12',
    COUNTY: '035',
    NAME: 'Flagler',
    LSAD: 'County',
    CENSUSAREA: 485.461,
    FIPS: 'FL035',
    fips: '12035',
    StateAbbr: 'FL',
    StateName: 'Florida',
    lat: 29.46163468,
    lng: -81.31358003,
  },
  {
    GEO_ID: '0500000US26059',
    STATE: '26',
    COUNTY: '059',
    NAME: 'Hillsdale',
    LSAD: 'County',
    CENSUSAREA: 598.133,
    FIPS: 'MI059',
    fips: '26059',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 41.88768696,
    lng: -84.59305475,
  },
  {
    GEO_ID: '0500000US26063',
    STATE: '26',
    COUNTY: '063',
    NAME: 'Huron',
    LSAD: 'County',
    CENSUSAREA: 835.706,
    FIPS: 'MI063',
    fips: '26063',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 43.83293286,
    lng: -83.03116796,
  },
  {
    GEO_ID: '0500000US26113',
    STATE: '26',
    COUNTY: '113',
    NAME: 'Missaukee',
    LSAD: 'County',
    CENSUSAREA: 564.726,
    FIPS: 'MI113',
    fips: '26113',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 44.33770935,
    lng: -85.09471001,
  },
  {
    GEO_ID: '0500000US26119',
    STATE: '26',
    COUNTY: '119',
    NAME: 'Montmorency',
    LSAD: 'County',
    CENSUSAREA: 546.663,
    FIPS: 'MI119',
    fips: '26119',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 45.02758285,
    lng: -84.12740152,
  },
  {
    GEO_ID: '0500000US26129',
    STATE: '26',
    COUNTY: '129',
    NAME: 'Ogemaw',
    LSAD: 'County',
    CENSUSAREA: 563.488,
    FIPS: 'MI129',
    fips: '26129',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 44.33486043,
    lng: -84.12710722,
  },
  {
    GEO_ID: '0500000US26137',
    STATE: '26',
    COUNTY: '137',
    NAME: 'Otsego',
    LSAD: 'County',
    CENSUSAREA: 514.971,
    FIPS: 'MI137',
    fips: '26137',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 45.02079452,
    lng: -84.59972828,
  },
  {
    GEO_ID: '0500000US26149',
    STATE: '26',
    COUNTY: '149',
    NAME: 'St. Joseph',
    LSAD: 'County',
    CENSUSAREA: 500.591,
    FIPS: 'MI149',
    fips: '26149',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 41.91439741,
    lng: -85.52810068,
  },
  {
    GEO_ID: '0500000US27005',
    STATE: '27',
    COUNTY: '005',
    NAME: 'Becker',
    LSAD: 'County',
    CENSUSAREA: 1315.203,
    FIPS: 'MN005',
    fips: '27005',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 46.93523736,
    lng: -95.67483556,
  },
  {
    GEO_ID: '0500000US27013',
    STATE: '27',
    COUNTY: '013',
    NAME: 'Blue Earth',
    LSAD: 'County',
    CENSUSAREA: 747.844,
    FIPS: 'MN013',
    fips: '27013',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 44.03450234,
    lng: -94.06697737,
  },
  {
    GEO_ID: '0500000US27023',
    STATE: '27',
    COUNTY: '023',
    NAME: 'Chippewa',
    LSAD: 'County',
    CENSUSAREA: 581.122,
    FIPS: 'MN023',
    fips: '27023',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 45.0223965,
    lng: -95.56698141,
  },
  {
    GEO_ID: '0500000US27033',
    STATE: '27',
    COUNTY: '033',
    NAME: 'Cottonwood',
    LSAD: 'County',
    CENSUSAREA: 638.61,
    FIPS: 'MN033',
    fips: '27033',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 44.00720878,
    lng: -95.18136404,
  },
  {
    GEO_ID: '0500000US27047',
    STATE: '27',
    COUNTY: '047',
    NAME: 'Freeborn',
    LSAD: 'County',
    CENSUSAREA: 707.087,
    FIPS: 'MN047',
    fips: '27047',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 43.67383179,
    lng: -93.3488462,
  },
  {
    GEO_ID: '0500000US27061',
    STATE: '27',
    COUNTY: '061',
    NAME: 'Itasca',
    LSAD: 'County',
    CENSUSAREA: 2667.722,
    FIPS: 'MN061',
    fips: '27061',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 47.50907819,
    lng: -93.63162349,
  },
  {
    GEO_ID: '0500000US29095',
    STATE: '29',
    COUNTY: '095',
    NAME: 'Jackson',
    LSAD: 'County',
    CENSUSAREA: 604.462,
    FIPS: 'MO095',
    fips: '29095',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 39.00926211,
    lng: -94.34603332,
  },
  {
    GEO_ID: '0500000US47133',
    STATE: '47',
    COUNTY: '133',
    NAME: 'Overton',
    LSAD: 'County',
    CENSUSAREA: 433.483,
    FIPS: 'TN133',
    fips: '47133',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 36.34428251,
    lng: -85.28818727,
  },
  {
    GEO_ID: '0500000US47145',
    STATE: '47',
    COUNTY: '145',
    NAME: 'Roane',
    LSAD: 'County',
    CENSUSAREA: 360.708,
    FIPS: 'TN145',
    fips: '47145',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 35.84762955,
    lng: -84.52335538,
  },
  {
    GEO_ID: '0500000US47159',
    STATE: '47',
    COUNTY: '159',
    NAME: 'Smith',
    LSAD: 'County',
    CENSUSAREA: 314.289,
    FIPS: 'TN159',
    fips: '47159',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 36.25023609,
    lng: -85.95654414,
  },
  {
    GEO_ID: '0500000US47165',
    STATE: '47',
    COUNTY: '165',
    NAME: 'Sumner',
    LSAD: 'County',
    CENSUSAREA: 529.449,
    FIPS: 'TN165',
    fips: '47165',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 36.46938867,
    lng: -86.46074089,
  },
  {
    GEO_ID: '0500000US45047',
    STATE: '45',
    COUNTY: '047',
    NAME: 'Greenwood',
    LSAD: 'County',
    CENSUSAREA: 454.726,
    FIPS: 'SC047',
    fips: '45047',
    StateAbbr: 'SC',
    StateName: 'South Carolina',
    lat: 34.15414545,
    lng: -82.12646941,
  },
  {
    GEO_ID: '0500000US45051',
    STATE: '45',
    COUNTY: '051',
    NAME: 'Horry',
    LSAD: 'County',
    CENSUSAREA: 1133.896,
    FIPS: 'SC051',
    fips: '45051',
    StateAbbr: 'SC',
    StateName: 'South Carolina',
    lat: 33.92139357,
    lng: -78.99657939,
  },
  {
    GEO_ID: '0500000US45059',
    STATE: '45',
    COUNTY: '059',
    NAME: 'Laurens',
    LSAD: 'County',
    CENSUSAREA: 713.804,
    FIPS: 'SC059',
    fips: '45059',
    StateAbbr: 'SC',
    StateName: 'South Carolina',
    lat: 34.48372258,
    lng: -82.00535135,
  },
  {
    GEO_ID: '0500000US45069',
    STATE: '45',
    COUNTY: '069',
    NAME: 'Marlboro',
    LSAD: 'County',
    CENSUSAREA: 479.674,
    FIPS: 'SC069',
    fips: '45069',
    StateAbbr: 'SC',
    StateName: 'South Carolina',
    lat: 34.60180925,
    lng: -79.67845191,
  },
  {
    GEO_ID: '0500000US45087',
    STATE: '45',
    COUNTY: '087',
    NAME: 'Union',
    LSAD: 'County',
    CENSUSAREA: 514.171,
    FIPS: 'SC087',
    fips: '45087',
    StateAbbr: 'SC',
    StateName: 'South Carolina',
    lat: 34.68978386,
    lng: -81.61926683,
  },
  {
    GEO_ID: '0500000US46015',
    STATE: '46',
    COUNTY: '015',
    NAME: 'Brule',
    LSAD: 'County',
    CENSUSAREA: 817.24,
    FIPS: 'SD015',
    fips: '46015',
    StateAbbr: 'SD',
    StateName: 'South Dakota',
    lat: 43.71816719,
    lng: -99.08125406,
  },
  {
    GEO_ID: '0500000US46025',
    STATE: '46',
    COUNTY: '025',
    NAME: 'Clark',
    LSAD: 'County',
    CENSUSAREA: 957.604,
    FIPS: 'SD025',
    fips: '46025',
    StateAbbr: 'SD',
    StateName: 'South Dakota',
    lat: 44.8584651,
    lng: -97.72949102,
  },
  {
    GEO_ID: '0500000US46037',
    STATE: '46',
    COUNTY: '037',
    NAME: 'Day',
    LSAD: 'County',
    CENSUSAREA: 1027.868,
    FIPS: 'SD037',
    fips: '46037',
    StateAbbr: 'SD',
    StateName: 'South Dakota',
    lat: 45.36728275,
    lng: -97.60737593,
  },
  {
    GEO_ID: '0500000US46049',
    STATE: '46',
    COUNTY: '049',
    NAME: 'Faulk',
    LSAD: 'County',
    CENSUSAREA: 981.75,
    FIPS: 'SD049',
    fips: '46049',
    StateAbbr: 'SD',
    StateName: 'South Dakota',
    lat: 45.07082625,
    lng: -99.14535601,
  },
  {
    GEO_ID: '0500000US46057',
    STATE: '46',
    COUNTY: '057',
    NAME: 'Hamlin',
    LSAD: 'County',
    CENSUSAREA: 507.231,
    FIPS: 'SD057',
    fips: '46057',
    StateAbbr: 'SD',
    StateName: 'South Dakota',
    lat: 44.67385954,
    lng: -97.18829593,
  },
  {
    GEO_ID: '0500000US46067',
    STATE: '46',
    COUNTY: '067',
    NAME: 'Hutchinson',
    LSAD: 'County',
    CENSUSAREA: 812.904,
    FIPS: 'SD067',
    fips: '46067',
    StateAbbr: 'SD',
    StateName: 'South Dakota',
    lat: 43.33466314,
    lng: -97.75456287,
  },
  {
    GEO_ID: '0500000US46077',
    STATE: '46',
    COUNTY: '077',
    NAME: 'Kingsbury',
    LSAD: 'County',
    CENSUSAREA: 832.236,
    FIPS: 'SD077',
    fips: '46077',
    StateAbbr: 'SD',
    StateName: 'South Dakota',
    lat: 44.36956169,
    lng: -97.4913945,
  },
  {
    GEO_ID: '0500000US51097',
    STATE: '51',
    COUNTY: '097',
    NAME: 'King and Queen',
    LSAD: 'County',
    CENSUSAREA: 315.138,
    FIPS: 'VA097',
    fips: '51097',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 37.71951331,
    lng: -76.8965089,
  },
  {
    GEO_ID: '0500000US51109',
    STATE: '51',
    COUNTY: '109',
    NAME: 'Louisa',
    LSAD: 'County',
    CENSUSAREA: 496.3,
    FIPS: 'VA109',
    fips: '51109',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 37.97807843,
    lng: -77.96275853,
  },
  {
    GEO_ID: '0500000US01035',
    STATE: '01',
    COUNTY: '035',
    NAME: 'Conecuh',
    LSAD: 'County',
    CENSUSAREA: 850.156,
    FIPS: 'AL035',
    fips: '01035',
    StateAbbr: 'AL',
    StateName: 'Alabama',
    lat: 31.42899352,
    lng: -86.9938237,
  },
  {
    GEO_ID: '0500000US01039',
    STATE: '01',
    COUNTY: '039',
    NAME: 'Covington',
    LSAD: 'County',
    CENSUSAREA: 1030.456,
    FIPS: 'AL039',
    fips: '01039',
    StateAbbr: 'AL',
    StateName: 'Alabama',
    lat: 31.24861005,
    lng: -86.45142629,
  },
  {
    GEO_ID: '0500000US01049',
    STATE: '01',
    COUNTY: '049',
    NAME: 'DeKalb',
    LSAD: 'County',
    CENSUSAREA: 777.093,
    FIPS: 'AL049',
    fips: '01049',
    StateAbbr: 'AL',
    StateName: 'Alabama',
    lat: 34.46023769,
    lng: -85.80378574,
  },
  {
    GEO_ID: '0500000US01053',
    STATE: '01',
    COUNTY: '053',
    NAME: 'Escambia',
    LSAD: 'County',
    CENSUSAREA: 945.08,
    FIPS: 'AL053',
    fips: '01053',
    StateAbbr: 'AL',
    StateName: 'Alabama',
    lat: 31.12613571,
    lng: -87.16176056,
  },
  {
    GEO_ID: '0500000US01063',
    STATE: '01',
    COUNTY: '063',
    NAME: 'Greene',
    LSAD: 'County',
    CENSUSAREA: 647.11,
    FIPS: 'AL063',
    fips: '01063',
    StateAbbr: 'AL',
    StateName: 'Alabama',
    lat: 32.853304,
    lng: -87.95276818,
  },
  {
    GEO_ID: '0500000US01069',
    STATE: '01',
    COUNTY: '069',
    NAME: 'Houston',
    LSAD: 'County',
    CENSUSAREA: 579.824,
    FIPS: 'AL069',
    fips: '01069',
    StateAbbr: 'AL',
    StateName: 'Alabama',
    lat: 31.15295179,
    lng: -85.30251402,
  },
  {
    GEO_ID: '0500000US01075',
    STATE: '01',
    COUNTY: '075',
    NAME: 'Lamar',
    LSAD: 'County',
    CENSUSAREA: 604.846,
    FIPS: 'AL075',
    fips: '01075',
    StateAbbr: 'AL',
    StateName: 'Alabama',
    lat: 33.77909349,
    lng: -88.09715315,
  },
  {
    GEO_ID: '0500000US01077',
    STATE: '01',
    COUNTY: '077',
    NAME: 'Lauderdale',
    LSAD: 'County',
    CENSUSAREA: 667.697,
    FIPS: 'AL077',
    fips: '01077',
    StateAbbr: 'AL',
    StateName: 'Alabama',
    lat: 34.9015002,
    lng: -87.65411658,
  },
  {
    GEO_ID: '0500000US12047',
    STATE: '12',
    COUNTY: '047',
    NAME: 'Hamilton',
    LSAD: 'County',
    CENSUSAREA: 513.79,
    FIPS: 'FL047',
    fips: '12047',
    StateAbbr: 'FL',
    StateName: 'Florida',
    lat: 30.49612932,
    lng: -82.94763284,
  },
  {
    GEO_ID: '0500000US12067',
    STATE: '12',
    COUNTY: '067',
    NAME: 'Lafayette',
    LSAD: 'County',
    CENSUSAREA: 543.411,
    FIPS: 'FL067',
    fips: '12067',
    StateAbbr: 'FL',
    StateName: 'Florida',
    lat: 29.98555842,
    lng: -83.18086561,
  },
  {
    GEO_ID: '0500000US12077',
    STATE: '12',
    COUNTY: '077',
    NAME: 'Liberty',
    LSAD: 'County',
    CENSUSAREA: 835.562,
    FIPS: 'FL077',
    fips: '12077',
    StateAbbr: 'FL',
    StateName: 'Florida',
    lat: 30.24145856,
    lng: -84.88318825,
  },
  {
    GEO_ID: '0500000US04001',
    STATE: '04',
    COUNTY: '001',
    NAME: 'Apache',
    LSAD: 'County',
    CENSUSAREA: 11197.521,
    FIPS: 'AZ001',
    fips: '04001',
    StateAbbr: 'AZ',
    StateName: 'Arizona',
    lat: 35.39559896,
    lng: -109.488754,
  },
  {
    GEO_ID: '0500000US04007',
    STATE: '04',
    COUNTY: '007',
    NAME: 'Gila',
    LSAD: 'County',
    CENSUSAREA: 4757.933,
    FIPS: 'AZ007',
    fips: '04007',
    StateAbbr: 'AZ',
    StateName: 'Arizona',
    lat: 33.79996932,
    lng: -110.8119677,
  },
  {
    GEO_ID: '0500000US04019',
    STATE: '04',
    COUNTY: '019',
    NAME: 'Pima',
    LSAD: 'County',
    CENSUSAREA: 9187.036,
    FIPS: 'AZ019',
    fips: '04019',
    StateAbbr: 'AZ',
    StateName: 'Arizona',
    lat: 32.09723099,
    lng: -111.7896381,
  },
  {
    GEO_ID: '0500000US05011',
    STATE: '05',
    COUNTY: '011',
    NAME: 'Bradley',
    LSAD: 'County',
    CENSUSAREA: 649.231,
    FIPS: 'AR011',
    fips: '05011',
    StateAbbr: 'AR',
    StateName: 'Arkansas',
    lat: 33.46613806,
    lng: -92.16229542,
  },
  {
    GEO_ID: '0500000US05023',
    STATE: '05',
    COUNTY: '023',
    NAME: 'Cleburne',
    LSAD: 'County',
    CENSUSAREA: 553.692,
    FIPS: 'AR023',
    fips: '05023',
    StateAbbr: 'AR',
    StateName: 'Arkansas',
    lat: 35.53853919,
    lng: -92.02725586,
  },
  {
    GEO_ID: '0500000US05037',
    STATE: '05',
    COUNTY: '037',
    NAME: 'Cross',
    LSAD: 'County',
    CENSUSAREA: 616.381,
    FIPS: 'AR037',
    fips: '05037',
    StateAbbr: 'AR',
    StateName: 'Arkansas',
    lat: 35.29547453,
    lng: -90.77181286,
  },
  {
    GEO_ID: '0500000US05039',
    STATE: '05',
    COUNTY: '039',
    NAME: 'Dallas',
    LSAD: 'County',
    CENSUSAREA: 667.386,
    FIPS: 'AR039',
    fips: '05039',
    StateAbbr: 'AR',
    StateName: 'Arkansas',
    lat: 33.96953994,
    lng: -92.65420664,
  },
  {
    GEO_ID: '0500000US05055',
    STATE: '05',
    COUNTY: '055',
    NAME: 'Greene',
    LSAD: 'County',
    CENSUSAREA: 577.699,
    FIPS: 'AR055',
    fips: '05055',
    StateAbbr: 'AR',
    StateName: 'Arkansas',
    lat: 36.11773282,
    lng: -90.55895377,
  },
  {
    GEO_ID: '0500000US05065',
    STATE: '05',
    COUNTY: '065',
    NAME: 'Izard',
    LSAD: 'County',
    CENSUSAREA: 580.577,
    FIPS: 'AR065',
    fips: '05065',
    StateAbbr: 'AR',
    StateName: 'Arkansas',
    lat: 36.09526565,
    lng: -91.91279069,
  },
  {
    GEO_ID: '0500000US05079',
    STATE: '05',
    COUNTY: '079',
    NAME: 'Lincoln',
    LSAD: 'County',
    CENSUSAREA: 561.517,
    FIPS: 'AR079',
    fips: '05079',
    StateAbbr: 'AR',
    StateName: 'Arkansas',
    lat: 33.95753882,
    lng: -91.73333878,
  },
  {
    GEO_ID: '0500000US29099',
    STATE: '29',
    COUNTY: '099',
    NAME: 'Jefferson',
    LSAD: 'County',
    CENSUSAREA: 656.629,
    FIPS: 'MO099',
    fips: '29099',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 38.26079127,
    lng: -90.53780688,
  },
  {
    GEO_ID: '0500000US29115',
    STATE: '29',
    COUNTY: '115',
    NAME: 'Linn',
    LSAD: 'County',
    CENSUSAREA: 615.564,
    FIPS: 'MO115',
    fips: '29115',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 39.870461,
    lng: -93.10705251,
  },
  {
    GEO_ID: '0500000US29121',
    STATE: '29',
    COUNTY: '121',
    NAME: 'Macon',
    LSAD: 'County',
    CENSUSAREA: 801.227,
    FIPS: 'MO121',
    fips: '29121',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 39.83049768,
    lng: -92.56453089,
  },
  {
    GEO_ID: '0500000US29135',
    STATE: '29',
    COUNTY: '135',
    NAME: 'Moniteau',
    LSAD: 'County',
    CENSUSAREA: 415.025,
    FIPS: 'MO135',
    fips: '29135',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 38.63234724,
    lng: -92.58303239,
  },
  {
    GEO_ID: '0500000US29145',
    STATE: '29',
    COUNTY: '145',
    NAME: 'Newton',
    LSAD: 'County',
    CENSUSAREA: 624.765,
    FIPS: 'MO145',
    fips: '29145',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 36.90518568,
    lng: -94.33946172,
  },
  {
    GEO_ID: '0500000US29163',
    STATE: '29',
    COUNTY: '163',
    NAME: 'Pike',
    LSAD: 'County',
    CENSUSAREA: 670.438,
    FIPS: 'MO163',
    fips: '29163',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 39.34397231,
    lng: -91.17165493,
  },
  {
    GEO_ID: '0500000US29173',
    STATE: '29',
    COUNTY: '173',
    NAME: 'Ralls',
    LSAD: 'County',
    CENSUSAREA: 469.781,
    FIPS: 'MO173',
    fips: '29173',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 39.5275593,
    lng: -91.52194283,
  },
  {
    GEO_ID: '0500000US29185',
    STATE: '29',
    COUNTY: '185',
    NAME: 'St. Clair',
    LSAD: 'County',
    CENSUSAREA: 669.983,
    FIPS: 'MO185',
    fips: '29185',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 38.03729132,
    lng: -93.77593289,
  },
  {
    GEO_ID: '0500000US29201',
    STATE: '29',
    COUNTY: '201',
    NAME: 'Scott',
    LSAD: 'County',
    CENSUSAREA: 419.99,
    FIPS: 'MO201',
    fips: '29201',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 37.05301351,
    lng: -89.56865798,
  },
  {
    GEO_ID: '0500000US29215',
    STATE: '29',
    COUNTY: '215',
    NAME: 'Texas',
    LSAD: 'County',
    CENSUSAREA: 1177.266,
    FIPS: 'MO215',
    fips: '29215',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 37.3167567,
    lng: -91.96523434,
  },
  {
    GEO_ID: '0500000US29223',
    STATE: '29',
    COUNTY: '223',
    NAME: 'Wayne',
    LSAD: 'County',
    CENSUSAREA: 759.176,
    FIPS: 'MO223',
    fips: '29223',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 37.11262197,
    lng: -90.46144508,
  },
  {
    GEO_ID: '0500000US30007',
    STATE: '30',
    COUNTY: '007',
    NAME: 'Broadwater',
    LSAD: 'County',
    CENSUSAREA: 1192.541,
    FIPS: 'MT007',
    fips: '30007',
    StateAbbr: 'MT',
    StateName: 'Montana',
    lat: 46.33259261,
    lng: -111.4961534,
  },
  {
    GEO_ID: '0500000US30021',
    STATE: '30',
    COUNTY: '021',
    NAME: 'Dawson',
    LSAD: 'County',
    CENSUSAREA: 2371.865,
    FIPS: 'MT021',
    fips: '30021',
    StateAbbr: 'MT',
    StateName: 'Montana',
    lat: 47.26636057,
    lng: -104.899515,
  },
  {
    GEO_ID: '0500000US30031',
    STATE: '30',
    COUNTY: '031',
    NAME: 'Gallatin',
    LSAD: 'County',
    CENSUSAREA: 2602.689,
    FIPS: 'MT031',
    fips: '30031',
    StateAbbr: 'MT',
    StateName: 'Montana',
    lat: 45.54097041,
    lng: -111.1703288,
  },
  {
    GEO_ID: '0500000US46091',
    STATE: '46',
    COUNTY: '091',
    NAME: 'Marshall',
    LSAD: 'County',
    CENSUSAREA: 838.066,
    FIPS: 'SD091',
    fips: '46091',
    StateAbbr: 'SD',
    StateName: 'South Dakota',
    lat: 45.75863325,
    lng: -97.59865421,
  },
  {
    GEO_ID: '0500000US46107',
    STATE: '46',
    COUNTY: '107',
    NAME: 'Potter',
    LSAD: 'County',
    CENSUSAREA: 861.14,
    FIPS: 'SD107',
    fips: '46107',
    StateAbbr: 'SD',
    StateName: 'South Dakota',
    lat: 45.06449392,
    lng: -99.9571321,
  },
  {
    GEO_ID: '0500000US46119',
    STATE: '46',
    COUNTY: '119',
    NAME: 'Sully',
    LSAD: 'County',
    CENSUSAREA: 1006.817,
    FIPS: 'SD119',
    fips: '46119',
    StateAbbr: 'SD',
    StateName: 'South Dakota',
    lat: 44.71542851,
    lng: -100.131855,
  },
  {
    GEO_ID: '0500000US46129',
    STATE: '46',
    COUNTY: '129',
    NAME: 'Walworth',
    LSAD: 'County',
    CENSUSAREA: 708.63,
    FIPS: 'SD129',
    fips: '46129',
    StateAbbr: 'SD',
    StateName: 'South Dakota',
    lat: 45.42998641,
    lng: -100.0313401,
  },
  {
    GEO_ID: '0500000US40057',
    STATE: '40',
    COUNTY: '057',
    NAME: 'Harmon',
    LSAD: 'County',
    CENSUSAREA: 537.194,
    FIPS: 'OK057',
    fips: '40057',
    StateAbbr: 'OK',
    StateName: 'Oklahoma',
    lat: 34.74409289,
    lng: -99.84630188,
  },
  {
    GEO_ID: '0500000US40061',
    STATE: '40',
    COUNTY: '061',
    NAME: 'Haskell',
    LSAD: 'County',
    CENSUSAREA: 576.521,
    FIPS: 'OK061',
    fips: '40061',
    StateAbbr: 'OK',
    StateName: 'Oklahoma',
    lat: 35.22501645,
    lng: -95.11606392,
  },
  {
    GEO_ID: '0500000US40069',
    STATE: '40',
    COUNTY: '069',
    NAME: 'Johnston',
    LSAD: 'County',
    CENSUSAREA: 642.94,
    FIPS: 'OK069',
    fips: '40069',
    StateAbbr: 'OK',
    StateName: 'Oklahoma',
    lat: 34.31652004,
    lng: -96.66080537,
  },
  {
    GEO_ID: '0500000US40075',
    STATE: '40',
    COUNTY: '075',
    NAME: 'Kiowa',
    LSAD: 'County',
    CENSUSAREA: 1015.226,
    FIPS: 'OK075',
    fips: '40075',
    StateAbbr: 'OK',
    StateName: 'Oklahoma',
    lat: 34.91625162,
    lng: -98.98054865,
  },
  {
    GEO_ID: '0500000US40093',
    STATE: '40',
    COUNTY: '093',
    NAME: 'Major',
    LSAD: 'County',
    CENSUSAREA: 954.988,
    FIPS: 'OK093',
    fips: '40093',
    StateAbbr: 'OK',
    StateName: 'Oklahoma',
    lat: 36.31174656,
    lng: -98.53456924,
  },
  {
    GEO_ID: '0500000US40103',
    STATE: '40',
    COUNTY: '103',
    NAME: 'Noble',
    LSAD: 'County',
    CENSUSAREA: 731.895,
    FIPS: 'OK103',
    fips: '40103',
    StateAbbr: 'OK',
    StateName: 'Oklahoma',
    lat: 36.3884853,
    lng: -97.23050054,
  },
  {
    GEO_ID: '0500000US40115',
    STATE: '40',
    COUNTY: '115',
    NAME: 'Ottawa',
    LSAD: 'County',
    CENSUSAREA: 470.821,
    FIPS: 'OK115',
    fips: '40115',
    StateAbbr: 'OK',
    StateName: 'Oklahoma',
    lat: 36.83587796,
    lng: -94.81058917,
  },
  {
    GEO_ID: '0500000US40133',
    STATE: '40',
    COUNTY: '133',
    NAME: 'Seminole',
    LSAD: 'County',
    CENSUSAREA: 632.837,
    FIPS: 'OK133',
    fips: '40133',
    StateAbbr: 'OK',
    StateName: 'Oklahoma',
    lat: 35.1672074,
    lng: -96.61546183,
  },
  {
    GEO_ID: '0500000US40141',
    STATE: '40',
    COUNTY: '141',
    NAME: 'Tillman',
    LSAD: 'County',
    CENSUSAREA: 871.134,
    FIPS: 'OK141',
    fips: '40141',
    StateAbbr: 'OK',
    StateName: 'Oklahoma',
    lat: 34.37312737,
    lng: -98.92460698,
  },
  {
    GEO_ID: '0500000US41009',
    STATE: '41',
    COUNTY: '009',
    NAME: 'Columbia',
    LSAD: 'County',
    CENSUSAREA: 657.357,
    FIPS: 'OR009',
    fips: '41009',
    StateAbbr: 'OR',
    StateName: 'Oregon',
    lat: 45.94376691,
    lng: -123.0888664,
  },
  {
    GEO_ID: '0500000US06067',
    STATE: '06',
    COUNTY: '067',
    NAME: 'Sacramento',
    LSAD: 'County',
    CENSUSAREA: 964.644,
    FIPS: 'CA067',
    fips: '06067',
    StateAbbr: 'CA',
    StateName: 'California',
    lat: 38.44881725,
    lng: -121.3445212,
  },
  {
    GEO_ID: '0500000US06071',
    STATE: '06',
    COUNTY: '071',
    NAME: 'San Bernardino',
    LSAD: 'County',
    CENSUSAREA: 20056.938,
    FIPS: 'CA071',
    fips: '06071',
    StateAbbr: 'CA',
    StateName: 'California',
    lat: 34.84157876,
    lng: -116.1783784,
  },
  {
    GEO_ID: '0500000US06111',
    STATE: '06',
    COUNTY: '111',
    NAME: 'Ventura',
    LSAD: 'County',
    CENSUSAREA: 1843.133,
    FIPS: 'CA111',
    fips: '06111',
    StateAbbr: 'CA',
    StateName: 'California',
    lat: 34.45474678,
    lng: -119.0848457,
  },
  {
    GEO_ID: '0500000US08005',
    STATE: '08',
    COUNTY: '005',
    NAME: 'Arapahoe',
    LSAD: 'County',
    CENSUSAREA: 798.1,
    FIPS: 'CO005',
    fips: '08005',
    StateAbbr: 'CO',
    StateName: 'Colorado',
    lat: 39.649919,
    lng: -104.3385011,
  },
  {
    GEO_ID: '0500000US05109',
    STATE: '05',
    COUNTY: '109',
    NAME: 'Pike',
    LSAD: 'County',
    CENSUSAREA: 600.618,
    FIPS: 'AR109',
    fips: '05109',
    StateAbbr: 'AR',
    StateName: 'Arkansas',
    lat: 34.16356461,
    lng: -93.65621,
  },
  {
    GEO_ID: '0500000US05117',
    STATE: '05',
    COUNTY: '117',
    NAME: 'Prairie',
    LSAD: 'County',
    CENSUSAREA: 647.958,
    FIPS: 'AR117',
    fips: '05117',
    StateAbbr: 'AR',
    StateName: 'Arkansas',
    lat: 34.83010091,
    lng: -91.55194184,
  },
  {
    GEO_ID: '0500000US05135',
    STATE: '05',
    COUNTY: '135',
    NAME: 'Sharp',
    LSAD: 'County',
    CENSUSAREA: 604.439,
    FIPS: 'AR135',
    fips: '05135',
    StateAbbr: 'AR',
    StateName: 'Arkansas',
    lat: 36.16124847,
    lng: -91.47928351,
  },
  {
    GEO_ID: '0500000US13307',
    STATE: '13',
    COUNTY: '307',
    NAME: 'Webster',
    LSAD: 'County',
    CENSUSAREA: 209.118,
    FIPS: 'GA307',
    fips: '13307',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 32.04630599,
    lng: -84.55001221,
  },
  {
    GEO_ID: '0500000US13311',
    STATE: '13',
    COUNTY: '311',
    NAME: 'White',
    LSAD: 'County',
    CENSUSAREA: 240.686,
    FIPS: 'GA311',
    fips: '13311',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 34.64590613,
    lng: -83.74657149,
  },
  {
    GEO_ID: '0500000US13315',
    STATE: '13',
    COUNTY: '315',
    NAME: 'Wilcox',
    LSAD: 'County',
    CENSUSAREA: 377.705,
    FIPS: 'GA315',
    fips: '13315',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 31.97280479,
    lng: -83.43204775,
  },
  {
    GEO_ID: '0500000US15005',
    STATE: '15',
    COUNTY: '005',
    NAME: 'Kalawao',
    LSAD: 'County',
    CENSUSAREA: 11.991,
    FIPS: 'HI005',
    fips: '15005',
    StateAbbr: 'HI',
    StateName: 'Hawaii',
    lat: 21.17295672,
    lng: -156.9493744,
  },
  {
    GEO_ID: '0500000US16005',
    STATE: '16',
    COUNTY: '005',
    NAME: 'Bannock',
    LSAD: 'County',
    CENSUSAREA: 1111.988,
    FIPS: 'ID005',
    fips: '16005',
    StateAbbr: 'ID',
    StateName: 'Idaho',
    lat: 42.6686619,
    lng: -112.2247491,
  },
  {
    GEO_ID: '0500000US16027',
    STATE: '16',
    COUNTY: '027',
    NAME: 'Canyon',
    LSAD: 'County',
    CENSUSAREA: 587.37,
    FIPS: 'ID027',
    fips: '16027',
    StateAbbr: 'ID',
    StateName: 'Idaho',
    lat: 43.6251769,
    lng: -116.7092162,
  },
  {
    GEO_ID: '0500000US16039',
    STATE: '16',
    COUNTY: '039',
    NAME: 'Elmore',
    LSAD: 'County',
    CENSUSAREA: 3074.738,
    FIPS: 'ID039',
    fips: '16039',
    StateAbbr: 'ID',
    StateName: 'Idaho',
    lat: 43.35417471,
    lng: -115.4691486,
  },
  {
    GEO_ID: '0500000US16063',
    STATE: '16',
    COUNTY: '063',
    NAME: 'Lincoln',
    LSAD: 'County',
    CENSUSAREA: 1201.405,
    FIPS: 'ID063',
    fips: '16063',
    StateAbbr: 'ID',
    StateName: 'Idaho',
    lat: 43.00232711,
    lng: -114.1383193,
  },
  {
    GEO_ID: '0500000US16075',
    STATE: '16',
    COUNTY: '075',
    NAME: 'Payette',
    LSAD: 'County',
    CENSUSAREA: 406.867,
    FIPS: 'ID075',
    fips: '16075',
    StateAbbr: 'ID',
    StateName: 'Idaho',
    lat: 44.00714137,
    lng: -116.7604309,
  },
  {
    GEO_ID: '0500000US17001',
    STATE: '17',
    COUNTY: '001',
    NAME: 'Adams',
    LSAD: 'County',
    CENSUSAREA: 855.202,
    FIPS: 'IL001',
    fips: '17001',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 39.98808185,
    lng: -91.18842478,
  },
  {
    GEO_ID: '0500000US17017',
    STATE: '17',
    COUNTY: '017',
    NAME: 'Cass',
    LSAD: 'County',
    CENSUSAREA: 375.818,
    FIPS: 'IL017',
    fips: '17017',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 39.97376092,
    lng: -90.24761202,
  },
  {
    GEO_ID: '0500000US30047',
    STATE: '30',
    COUNTY: '047',
    NAME: 'Lake',
    LSAD: 'County',
    CENSUSAREA: 1490.15,
    FIPS: 'MT047',
    fips: '30047',
    StateAbbr: 'MT',
    StateName: 'Montana',
    lat: 47.64593461,
    lng: -114.0894823,
  },
  {
    GEO_ID: '0500000US31123',
    STATE: '31',
    COUNTY: '123',
    NAME: 'Morrill',
    LSAD: 'County',
    CENSUSAREA: 1423.838,
    FIPS: 'NE123',
    fips: '31123',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 41.7164533,
    lng: -103.0105871,
  },
  {
    GEO_ID: '0500000US31127',
    STATE: '31',
    COUNTY: '127',
    NAME: 'Nemaha',
    LSAD: 'County',
    CENSUSAREA: 407.382,
    FIPS: 'NE127',
    fips: '31127',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 40.38784773,
    lng: -95.84990553,
  },
  {
    GEO_ID: '0500000US31143',
    STATE: '31',
    COUNTY: '143',
    NAME: 'Polk',
    LSAD: 'County',
    CENSUSAREA: 438.345,
    FIPS: 'NE143',
    fips: '31143',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 41.18687055,
    lng: -97.56825226,
  },
  {
    GEO_ID: '0500000US31153',
    STATE: '31',
    COUNTY: '153',
    NAME: 'Sarpy',
    LSAD: 'County',
    CENSUSAREA: 238.99,
    FIPS: 'NE153',
    fips: '31153',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 41.11287068,
    lng: -96.11218009,
  },
  {
    GEO_ID: '0500000US31167',
    STATE: '31',
    COUNTY: '167',
    NAME: 'Stanton',
    LSAD: 'County',
    CENSUSAREA: 427.949,
    FIPS: 'NE167',
    fips: '31167',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 41.9169835,
    lng: -97.19394708,
  },
  {
    GEO_ID: '0500000US31179',
    STATE: '31',
    COUNTY: '179',
    NAME: 'Wayne',
    LSAD: 'County',
    CENSUSAREA: 442.915,
    FIPS: 'NE179',
    fips: '31179',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 42.20934475,
    lng: -97.11926964,
  },
  {
    GEO_ID: '0500000US31185',
    STATE: '31',
    COUNTY: '185',
    NAME: 'York',
    LSAD: 'County',
    CENSUSAREA: 572.51,
    FIPS: 'NE185',
    fips: '31185',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 40.87272618,
    lng: -97.59710186,
  },
  {
    GEO_ID: '0500000US32011',
    STATE: '32',
    COUNTY: '011',
    NAME: 'Eureka',
    LSAD: 'County',
    CENSUSAREA: 4175.684,
    FIPS: 'NV011',
    fips: '32011',
    StateAbbr: 'NV',
    StateName: 'Nevada',
    lat: 39.98327398,
    lng: -116.2694709,
  },
  {
    GEO_ID: '0500000US32027',
    STATE: '32',
    COUNTY: '027',
    NAME: 'Pershing',
    LSAD: 'County',
    CENSUSAREA: 6036.563,
    FIPS: 'NV027',
    fips: '32027',
    StateAbbr: 'NV',
    StateName: 'Nevada',
    lat: 40.43952288,
    lng: -118.4047657,
  },
  {
    GEO_ID: '0500000US33005',
    STATE: '33',
    COUNTY: '005',
    NAME: 'Cheshire',
    LSAD: 'County',
    CENSUSAREA: 706.66,
    FIPS: 'NH005',
    fips: '33005',
    StateAbbr: 'NH',
    StateName: 'New Hampshire',
    lat: 42.91955278,
    lng: -72.25111371,
  },
  {
    GEO_ID: '0500000US33017',
    STATE: '33',
    COUNTY: '017',
    NAME: 'Strafford',
    LSAD: 'County',
    CENSUSAREA: 368.975,
    FIPS: 'NH017',
    fips: '33017',
    StateAbbr: 'NH',
    StateName: 'New Hampshire',
    lat: 43.29717653,
    lng: -71.02927319,
  },
  {
    GEO_ID: '0500000US34027',
    STATE: '34',
    COUNTY: '027',
    NAME: 'Morris',
    LSAD: 'County',
    CENSUSAREA: 460.177,
    FIPS: 'NJ027',
    fips: '34027',
    StateAbbr: 'NJ',
    StateName: 'New Jersey',
    lat: 40.86163004,
    lng: -74.54440839,
  },
  {
    GEO_ID: '0500000US34037',
    STATE: '34',
    COUNTY: '037',
    NAME: 'Sussex',
    LSAD: 'County',
    CENSUSAREA: 519.014,
    FIPS: 'NJ037',
    fips: '34037',
    StateAbbr: 'NJ',
    StateName: 'New Jersey',
    lat: 41.13935722,
    lng: -74.69082533,
  },
  {
    GEO_ID: '0500000US41023',
    STATE: '41',
    COUNTY: '023',
    NAME: 'Grant',
    LSAD: 'County',
    CENSUSAREA: 4528.541,
    FIPS: 'OR023',
    fips: '41023',
    StateAbbr: 'OR',
    StateName: 'Oregon',
    lat: 44.4907455,
    lng: -119.0074917,
  },
  {
    GEO_ID: '0500000US41047',
    STATE: '41',
    COUNTY: '047',
    NAME: 'Marion',
    LSAD: 'County',
    CENSUSAREA: 1182.326,
    FIPS: 'OR047',
    fips: '41047',
    StateAbbr: 'OR',
    StateName: 'Oregon',
    lat: 44.90322354,
    lng: -122.5849114,
  },
  {
    GEO_ID: '0500000US41061',
    STATE: '41',
    COUNTY: '061',
    NAME: 'Union',
    LSAD: 'County',
    CENSUSAREA: 2036.606,
    FIPS: 'OR061',
    fips: '41061',
    StateAbbr: 'OR',
    StateName: 'Oregon',
    lat: 45.31039084,
    lng: -118.0093556,
  },
  {
    GEO_ID: '0500000US42009',
    STATE: '42',
    COUNTY: '009',
    NAME: 'Bedford',
    LSAD: 'County',
    CENSUSAREA: 1012.296,
    FIPS: 'PA009',
    fips: '42009',
    StateAbbr: 'PA',
    StateName: 'Pennsylvania',
    lat: 40.00670558,
    lng: -78.49051194,
  },
  {
    GEO_ID: '0500000US48355',
    STATE: '48',
    COUNTY: '355',
    NAME: 'Nueces',
    LSAD: 'County',
    CENSUSAREA: 838.482,
    FIPS: 'TX355',
    fips: '48355',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 27.72543779,
    lng: -97.61091925,
  },
  {
    GEO_ID: '0500000US48363',
    STATE: '48',
    COUNTY: '363',
    NAME: 'Palo Pinto',
    LSAD: 'County',
    CENSUSAREA: 951.791,
    FIPS: 'TX363',
    fips: '48363',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 32.75310985,
    lng: -98.31319001,
  },
  {
    GEO_ID: '0500000US48373',
    STATE: '48',
    COUNTY: '373',
    NAME: 'Polk',
    LSAD: 'County',
    CENSUSAREA: 1057.093,
    FIPS: 'TX373',
    fips: '48373',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 30.79270853,
    lng: -94.82986499,
  },
  {
    GEO_ID: '0500000US48389',
    STATE: '48',
    COUNTY: '389',
    NAME: 'Reeves',
    LSAD: 'County',
    CENSUSAREA: 2635.375,
    FIPS: 'TX389',
    fips: '48389',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 31.32283311,
    lng: -103.6930786,
  },
  {
    GEO_ID: '0500000US48405',
    STATE: '48',
    COUNTY: '405',
    NAME: 'San Augustine',
    LSAD: 'County',
    CENSUSAREA: 530.657,
    FIPS: 'TX405',
    fips: '48405',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 31.39477071,
    lng: -94.16790044,
  },
  {
    GEO_ID: '0500000US48413',
    STATE: '48',
    COUNTY: '413',
    NAME: 'Schleicher',
    LSAD: 'County',
    CENSUSAREA: 1310.63,
    FIPS: 'TX413',
    fips: '48413',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 30.89734895,
    lng: -100.5384877,
  },
  {
    GEO_ID: '0500000US48421',
    STATE: '48',
    COUNTY: '421',
    NAME: 'Sherman',
    LSAD: 'County',
    CENSUSAREA: 923.035,
    FIPS: 'TX421',
    fips: '48421',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 36.27765456,
    lng: -101.8933684,
  },
  {
    GEO_ID: '0500000US48439',
    STATE: '48',
    COUNTY: '439',
    NAME: 'Tarrant',
    LSAD: 'County',
    CENSUSAREA: 863.608,
    FIPS: 'TX439',
    fips: '48439',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 32.77145377,
    lng: -97.29099289,
  },
  {
    GEO_ID: '0500000US48455',
    STATE: '48',
    COUNTY: '455',
    NAME: 'Trinity',
    LSAD: 'County',
    CENSUSAREA: 693.608,
    FIPS: 'TX455',
    fips: '48455',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 31.08828397,
    lng: -95.13547749,
  },
  {
    GEO_ID: '0500000US48471',
    STATE: '48',
    COUNTY: '471',
    NAME: 'Walker',
    LSAD: 'County',
    CENSUSAREA: 784.169,
    FIPS: 'TX471',
    fips: '48471',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 30.73883544,
    lng: -95.57247928,
  },
  {
    GEO_ID: '0500000US13083',
    STATE: '13',
    COUNTY: '083',
    NAME: 'Dade',
    LSAD: 'County',
    CENSUSAREA: 173.981,
    FIPS: 'GA083',
    fips: '13083',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 34.85458429,
    lng: -85.50458952,
  },
  {
    GEO_ID: '0500000US13089',
    STATE: '13',
    COUNTY: '089',
    NAME: 'DeKalb',
    LSAD: 'County',
    CENSUSAREA: 267.582,
    FIPS: 'GA089',
    fips: '13089',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 33.77122192,
    lng: -84.22665212,
  },
  {
    GEO_ID: '0500000US13097',
    STATE: '13',
    COUNTY: '097',
    NAME: 'Douglas',
    LSAD: 'County',
    CENSUSAREA: 200.067,
    FIPS: 'GA097',
    fips: '13097',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 33.7021135,
    lng: -84.76851394,
  },
  {
    GEO_ID: '0500000US13105',
    STATE: '13',
    COUNTY: '105',
    NAME: 'Elbert',
    LSAD: 'County',
    CENSUSAREA: 351.055,
    FIPS: 'GA105',
    fips: '13105',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 34.11635114,
    lng: -82.83992664,
  },
  {
    GEO_ID: '0500000US13111',
    STATE: '13',
    COUNTY: '111',
    NAME: 'Fannin',
    LSAD: 'County',
    CENSUSAREA: 386.723,
    FIPS: 'GA111',
    fips: '13111',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 34.86412558,
    lng: -84.31929617,
  },
  {
    GEO_ID: '0500000US13121',
    STATE: '13',
    COUNTY: '121',
    NAME: 'Fulton',
    LSAD: 'County',
    CENSUSAREA: 526.635,
    FIPS: 'GA121',
    fips: '13121',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 33.79038574,
    lng: -84.46714878,
  },
  {
    GEO_ID: '0500000US08067',
    STATE: '08',
    COUNTY: '067',
    NAME: 'La Plata',
    LSAD: 'County',
    CENSUSAREA: 1692.078,
    FIPS: 'CO067',
    fips: '08067',
    StateAbbr: 'CO',
    StateName: 'Colorado',
    lat: 37.28655123,
    lng: -107.843327,
  },
  {
    GEO_ID: '0500000US08071',
    STATE: '08',
    COUNTY: '071',
    NAME: 'Las Animas',
    LSAD: 'County',
    CENSUSAREA: 4772.672,
    FIPS: 'CO071',
    fips: '08071',
    StateAbbr: 'CO',
    StateName: 'Colorado',
    lat: 37.31577175,
    lng: -104.0385309,
  },
  {
    GEO_ID: '0500000US05095',
    STATE: '05',
    COUNTY: '095',
    NAME: 'Monroe',
    LSAD: 'County',
    CENSUSAREA: 607.122,
    FIPS: 'AR095',
    fips: '05095',
    StateAbbr: 'AR',
    StateName: 'Arkansas',
    lat: 34.67772323,
    lng: -91.20394825,
  },
  {
    GEO_ID: '0500000US17025',
    STATE: '17',
    COUNTY: '025',
    NAME: 'Clay',
    LSAD: 'County',
    CENSUSAREA: 468.316,
    FIPS: 'IL025',
    fips: '17025',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 38.75410733,
    lng: -88.49033998,
  },
  {
    GEO_ID: '0500000US17039',
    STATE: '17',
    COUNTY: '039',
    NAME: 'De Witt',
    LSAD: 'County',
    CENSUSAREA: 397.513,
    FIPS: 'IL039',
    fips: '17039',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 40.17434051,
    lng: -88.90408529,
  },
  {
    GEO_ID: '0500000US17045',
    STATE: '17',
    COUNTY: '045',
    NAME: 'Edgar',
    LSAD: 'County',
    CENSUSAREA: 623.374,
    FIPS: 'IL045',
    fips: '17045',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 39.67865165,
    lng: -87.74546531,
  },
  {
    GEO_ID: '0500000US17061',
    STATE: '17',
    COUNTY: '061',
    NAME: 'Greene',
    LSAD: 'County',
    CENSUSAREA: 543.019,
    FIPS: 'IL061',
    fips: '17061',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 39.3563746,
    lng: -90.39051245,
  },
  {
    GEO_ID: '0500000US17069',
    STATE: '17',
    COUNTY: '069',
    NAME: 'Hardin',
    LSAD: 'County',
    CENSUSAREA: 177.528,
    FIPS: 'IL069',
    fips: '17069',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 37.51807977,
    lng: -88.26669526,
  },
  {
    GEO_ID: '0500000US18049',
    STATE: '18',
    COUNTY: '049',
    NAME: 'Fulton',
    LSAD: 'County',
    CENSUSAREA: 368.388,
    FIPS: 'IN049',
    fips: '18049',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 41.04713247,
    lng: -86.26356168,
  },
  {
    GEO_ID: '0500000US18053',
    STATE: '18',
    COUNTY: '053',
    NAME: 'Grant',
    LSAD: 'County',
    CENSUSAREA: 414.074,
    FIPS: 'IN053',
    fips: '18053',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 40.51587228,
    lng: -85.65468702,
  },
  {
    GEO_ID: '0500000US18063',
    STATE: '18',
    COUNTY: '063',
    NAME: 'Hendricks',
    LSAD: 'County',
    CENSUSAREA: 406.911,
    FIPS: 'IN063',
    fips: '18063',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 39.76986908,
    lng: -86.50994544,
  },
  {
    GEO_ID: '0500000US18075',
    STATE: '18',
    COUNTY: '075',
    NAME: 'Jay',
    LSAD: 'County',
    CENSUSAREA: 383.905,
    FIPS: 'IN075',
    fips: '18075',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 40.43780769,
    lng: -85.00574703,
  },
  {
    GEO_ID: '0500000US18093',
    STATE: '18',
    COUNTY: '093',
    NAME: 'Lawrence',
    LSAD: 'County',
    CENSUSAREA: 449.168,
    FIPS: 'IN093',
    fips: '18093',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 38.84095382,
    lng: -86.48322849,
  },
  {
    GEO_ID: '0500000US18099',
    STATE: '18',
    COUNTY: '099',
    NAME: 'Marshall',
    LSAD: 'County',
    CENSUSAREA: 443.629,
    FIPS: 'IN099',
    fips: '18099',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 41.3248735,
    lng: -86.26180055,
  },
  {
    GEO_ID: '0500000US18113',
    STATE: '18',
    COUNTY: '113',
    NAME: 'Noble',
    LSAD: 'County',
    CENSUSAREA: 410.843,
    FIPS: 'IN113',
    fips: '18113',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 41.39835527,
    lng: -85.41741162,
  },
  {
    GEO_ID: '0500000US18119',
    STATE: '18',
    COUNTY: '119',
    NAME: 'Owen',
    LSAD: 'County',
    CENSUSAREA: 385.288,
    FIPS: 'IN119',
    fips: '18119',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 39.31262962,
    lng: -86.83782352,
  },
  {
    GEO_ID: '0500000US18133',
    STATE: '18',
    COUNTY: '133',
    NAME: 'Putnam',
    LSAD: 'County',
    CENSUSAREA: 480.528,
    FIPS: 'IN133',
    fips: '18133',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 39.66633516,
    lng: -86.84503425,
  },
  {
    GEO_ID: '0500000US18145',
    STATE: '18',
    COUNTY: '145',
    NAME: 'Shelby',
    LSAD: 'County',
    CENSUSAREA: 411.147,
    FIPS: 'IN145',
    fips: '18145',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 39.52312646,
    lng: -85.79146519,
  },
  {
    GEO_ID: '0500000US18157',
    STATE: '18',
    COUNTY: '157',
    NAME: 'Tippecanoe',
    LSAD: 'County',
    CENSUSAREA: 499.806,
    FIPS: 'IN157',
    fips: '18157',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 40.38871167,
    lng: -86.89407624,
  },
  {
    GEO_ID: '0500000US35011',
    STATE: '35',
    COUNTY: '011',
    NAME: 'De Baca',
    LSAD: 'County',
    CENSUSAREA: 2322.623,
    FIPS: 'NM011',
    fips: '35011',
    StateAbbr: 'NM',
    StateName: 'New Mexico',
    lat: 34.34266,
    lng: -104.4120813,
  },
  {
    GEO_ID: '0500000US30049',
    STATE: '30',
    COUNTY: '049',
    NAME: 'Lewis and Clark',
    LSAD: 'County',
    CENSUSAREA: 3458.828,
    FIPS: 'MT049',
    fips: '30049',
    StateAbbr: 'MT',
    StateName: 'Montana',
    lat: 47.12259745,
    lng: -112.3904537,
  },
  {
    GEO_ID: '0500000US30053',
    STATE: '30',
    COUNTY: '053',
    NAME: 'Lincoln',
    LSAD: 'County',
    CENSUSAREA: 3612.916,
    FIPS: 'MT053',
    fips: '30053',
    StateAbbr: 'MT',
    StateName: 'Montana',
    lat: 48.54246989,
    lng: -115.4051151,
  },
  {
    GEO_ID: '0500000US30075',
    STATE: '30',
    COUNTY: '075',
    NAME: 'Powder River',
    LSAD: 'County',
    CENSUSAREA: 3297.298,
    FIPS: 'MT075',
    fips: '30075',
    StateAbbr: 'MT',
    StateName: 'Montana',
    lat: 45.39499878,
    lng: -105.6300238,
  },
  {
    GEO_ID: '0500000US30085',
    STATE: '30',
    COUNTY: '085',
    NAME: 'Roosevelt',
    LSAD: 'County',
    CENSUSAREA: 2354.793,
    FIPS: 'MT085',
    fips: '30085',
    StateAbbr: 'MT',
    StateName: 'Montana',
    lat: 48.29447097,
    lng: -105.0162772,
  },
  {
    GEO_ID: '0500000US31001',
    STATE: '31',
    COUNTY: '001',
    NAME: 'Adams',
    LSAD: 'County',
    CENSUSAREA: 563.272,
    FIPS: 'NE001',
    fips: '31001',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 40.5244942,
    lng: -98.50117804,
  },
  {
    GEO_ID: '0500000US31005',
    STATE: '31',
    COUNTY: '005',
    NAME: 'Arthur',
    LSAD: 'County',
    CENSUSAREA: 715.355,
    FIPS: 'NE005',
    fips: '31005',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 41.56896142,
    lng: -101.6959559,
  },
  {
    GEO_ID: '0500000US31017',
    STATE: '31',
    COUNTY: '017',
    NAME: 'Brown',
    LSAD: 'County',
    CENSUSAREA: 1221.333,
    FIPS: 'NE017',
    fips: '31017',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 42.43016331,
    lng: -99.92949608,
  },
  {
    GEO_ID: '0500000US31027',
    STATE: '31',
    COUNTY: '027',
    NAME: 'Cedar',
    LSAD: 'County',
    CENSUSAREA: 740.312,
    FIPS: 'NE027',
    fips: '31027',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 42.59931244,
    lng: -97.25275919,
  },
  {
    GEO_ID: '0500000US31041',
    STATE: '31',
    COUNTY: '041',
    NAME: 'Custer',
    LSAD: 'County',
    CENSUSAREA: 2575.522,
    FIPS: 'NE041',
    fips: '31041',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 41.39416503,
    lng: -99.72614484,
  },
  {
    GEO_ID: '0500000US31059',
    STATE: '31',
    COUNTY: '059',
    NAME: 'Fillmore',
    LSAD: 'County',
    CENSUSAREA: 575.371,
    FIPS: 'NE059',
    fips: '31059',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 40.52458567,
    lng: -97.59646937,
  },
  {
    GEO_ID: '0500000US31069',
    STATE: '31',
    COUNTY: '069',
    NAME: 'Garden',
    LSAD: 'County',
    CENSUSAREA: 1704.283,
    FIPS: 'NE069',
    fips: '31069',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 41.61911486,
    lng: -102.3353283,
  },
  {
    GEO_ID: '0500000US31077',
    STATE: '31',
    COUNTY: '077',
    NAME: 'Greeley',
    LSAD: 'County',
    CENSUSAREA: 569.811,
    FIPS: 'NE077',
    fips: '31077',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 41.56747021,
    lng: -98.52144222,
  },
  {
    GEO_ID: '0500000US31089',
    STATE: '31',
    COUNTY: '089',
    NAME: 'Holt',
    LSAD: 'County',
    CENSUSAREA: 2412.399,
    FIPS: 'NE089',
    fips: '31089',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 42.45557846,
    lng: -98.78376243,
  },
  {
    GEO_ID: '0500000US48481',
    STATE: '48',
    COUNTY: '481',
    NAME: 'Wharton',
    LSAD: 'County',
    CENSUSAREA: 1086.15,
    FIPS: 'TX481',
    fips: '48481',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 29.2778845,
    lng: -96.22242975,
  },
  {
    GEO_ID: '0500000US48493',
    STATE: '48',
    COUNTY: '493',
    NAME: 'Wilson',
    LSAD: 'County',
    CENSUSAREA: 803.733,
    FIPS: 'TX493',
    fips: '48493',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 29.17420221,
    lng: -98.08694449,
  },
  {
    GEO_ID: '0500000US48503',
    STATE: '48',
    COUNTY: '503',
    NAME: 'Young',
    LSAD: 'County',
    CENSUSAREA: 914.468,
    FIPS: 'TX503',
    fips: '48503',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 33.17659707,
    lng: -98.68790885,
  },
  {
    GEO_ID: '0500000US49005',
    STATE: '49',
    COUNTY: '005',
    NAME: 'Cache',
    LSAD: 'County',
    CENSUSAREA: 1164.813,
    FIPS: 'UT005',
    fips: '49005',
    StateAbbr: 'UT',
    StateName: 'Utah',
    lat: 41.72255404,
    lng: -111.743741,
  },
  {
    GEO_ID: '0500000US49017',
    STATE: '49',
    COUNTY: '017',
    NAME: 'Garfield',
    LSAD: 'County',
    CENSUSAREA: 5175.121,
    FIPS: 'UT017',
    fips: '49017',
    StateAbbr: 'UT',
    StateName: 'Utah',
    lat: 37.85511631,
    lng: -111.4425093,
  },
  {
    GEO_ID: '0500000US47183',
    STATE: '47',
    COUNTY: '183',
    NAME: 'Weakley',
    LSAD: 'County',
    CENSUSAREA: 580.364,
    FIPS: 'TN183',
    fips: '47183',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 36.29822836,
    lng: -88.71817942,
  },
  {
    GEO_ID: '0500000US47187',
    STATE: '47',
    COUNTY: '187',
    NAME: 'Williamson',
    LSAD: 'County',
    CENSUSAREA: 582.599,
    FIPS: 'TN187',
    fips: '47187',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 35.89405608,
    lng: -86.89830897,
  },
  {
    GEO_ID: '0500000US48003',
    STATE: '48',
    COUNTY: '003',
    NAME: 'Andrews',
    LSAD: 'County',
    CENSUSAREA: 1500.713,
    FIPS: 'TX003',
    fips: '48003',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 32.30470528,
    lng: -102.6376348,
  },
  {
    GEO_ID: '0500000US48019',
    STATE: '48',
    COUNTY: '019',
    NAME: 'Bandera',
    LSAD: 'County',
    CENSUSAREA: 790.961,
    FIPS: 'TX019',
    fips: '48019',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 29.74699689,
    lng: -99.24640517,
  },
  {
    GEO_ID: '0500000US48027',
    STATE: '48',
    COUNTY: '027',
    NAME: 'Bell',
    LSAD: 'County',
    CENSUSAREA: 1051.016,
    FIPS: 'TX027',
    fips: '48027',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 31.03762665,
    lng: -97.47823134,
  },
  {
    GEO_ID: '0500000US48041',
    STATE: '48',
    COUNTY: '041',
    NAME: 'Brazos',
    LSAD: 'County',
    CENSUSAREA: 585.445,
    FIPS: 'TX041',
    fips: '48041',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 30.66074221,
    lng: -96.30208322,
  },
  {
    GEO_ID: '0500000US48059',
    STATE: '48',
    COUNTY: '059',
    NAME: 'Callahan',
    LSAD: 'County',
    CENSUSAREA: 899.372,
    FIPS: 'TX059',
    fips: '48059',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 32.29760619,
    lng: -99.37326312,
  },
  {
    GEO_ID: '0500000US48075',
    STATE: '48',
    COUNTY: '075',
    NAME: 'Childress',
    LSAD: 'County',
    CENSUSAREA: 696.405,
    FIPS: 'TX075',
    fips: '48075',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 34.52929885,
    lng: -100.2077744,
  },
  {
    GEO_ID: '0500000US48083',
    STATE: '48',
    COUNTY: '083',
    NAME: 'Coleman',
    LSAD: 'County',
    CENSUSAREA: 1261.946,
    FIPS: 'TX083',
    fips: '48083',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 31.77319682,
    lng: -99.45287706,
  },
  {
    GEO_ID: '0500000US48089',
    STATE: '48',
    COUNTY: '089',
    NAME: 'Colorado',
    LSAD: 'County',
    CENSUSAREA: 960.274,
    FIPS: 'TX089',
    fips: '48089',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 29.62080587,
    lng: -96.52613796,
  },
  {
    GEO_ID: '0500000US02100',
    STATE: '02',
    COUNTY: '100',
    NAME: 'Haines',
    LSAD: 'Borough',
    CENSUSAREA: 2318.6,
    FIPS: 'AK100',
    fips: '02100',
    StateAbbr: 'AK',
    StateName: 'Alaska',
    lat: 59.11793896,
    lng: -135.5024208,
  },
  {
    GEO_ID: '0500000US02170',
    STATE: '02',
    COUNTY: '170',
    NAME: 'Matanuska-Susitna',
    LSAD: 'Borough',
    CENSUSAREA: 24607.898,
    FIPS: 'AK170',
    fips: '02170',
    StateAbbr: 'AK',
    StateName: 'Alaska',
    lat: 62.31571716,
    lng: -149.5691006,
  },
  {
    GEO_ID: '0500000US06001',
    STATE: '06',
    COUNTY: '001',
    NAME: 'Alameda',
    LSAD: 'County',
    CENSUSAREA: 739.017,
    FIPS: 'CA001',
    fips: '06001',
    StateAbbr: 'CA',
    StateName: 'California',
    lat: 37.64642367,
    lng: -121.888612,
  },
  {
    GEO_ID: '0500000US01105',
    STATE: '01',
    COUNTY: '105',
    NAME: 'Perry',
    LSAD: 'County',
    CENSUSAREA: 719.664,
    FIPS: 'AL105',
    fips: '01105',
    StateAbbr: 'AL',
    StateName: 'Alabama',
    lat: 32.63859284,
    lng: -87.29424703,
  },
  {
    GEO_ID: '0500000US01111',
    STATE: '01',
    COUNTY: '111',
    NAME: 'Randolph',
    LSAD: 'County',
    CENSUSAREA: 580.55,
    FIPS: 'AL111',
    fips: '01111',
    StateAbbr: 'AL',
    StateName: 'Alabama',
    lat: 33.29354361,
    lng: -85.45918107,
  },
  {
    GEO_ID: '0500000US01113',
    STATE: '01',
    COUNTY: '113',
    NAME: 'Russell',
    LSAD: 'County',
    CENSUSAREA: 641.14,
    FIPS: 'AL113',
    fips: '01113',
    StateAbbr: 'AL',
    StateName: 'Alabama',
    lat: 32.28806204,
    lng: -85.18428296,
  },
  {
    GEO_ID: '0500000US01123',
    STATE: '01',
    COUNTY: '123',
    NAME: 'Tallapoosa',
    LSAD: 'County',
    CENSUSAREA: 716.521,
    FIPS: 'AL123',
    fips: '01123',
    StateAbbr: 'AL',
    StateName: 'Alabama',
    lat: 32.86280802,
    lng: -85.79746538,
  },
  {
    GEO_ID: '0500000US18165',
    STATE: '18',
    COUNTY: '165',
    NAME: 'Vermillion',
    LSAD: 'County',
    CENSUSAREA: 256.878,
    FIPS: 'IN165',
    fips: '18165',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 39.85409324,
    lng: -87.46414164,
  },
  {
    GEO_ID: '0500000US19003',
    STATE: '19',
    COUNTY: '003',
    NAME: 'Adams',
    LSAD: 'County',
    CENSUSAREA: 423.439,
    FIPS: 'IA003',
    fips: '19003',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 41.02903567,
    lng: -94.69932645,
  },
  {
    GEO_ID: '0500000US19013',
    STATE: '19',
    COUNTY: '013',
    NAME: 'Black Hawk',
    LSAD: 'County',
    CENSUSAREA: 565.769,
    FIPS: 'IA013',
    fips: '19013',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 42.47009403,
    lng: -92.30867416,
  },
  {
    GEO_ID: '0500000US19025',
    STATE: '19',
    COUNTY: '025',
    NAME: 'Calhoun',
    LSAD: 'County',
    CENSUSAREA: 569.968,
    FIPS: 'IA025',
    fips: '19025',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 42.38521079,
    lng: -94.64046046,
  },
  {
    GEO_ID: '0500000US19035',
    STATE: '19',
    COUNTY: '035',
    NAME: 'Cherokee',
    LSAD: 'County',
    CENSUSAREA: 576.906,
    FIPS: 'IA035',
    fips: '19035',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 42.73538914,
    lng: -95.62389402,
  },
  {
    GEO_ID: '0500000US17071',
    STATE: '17',
    COUNTY: '071',
    NAME: 'Henderson',
    LSAD: 'County',
    CENSUSAREA: 378.872,
    FIPS: 'IL071',
    fips: '17071',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 40.81804873,
    lng: -90.92497453,
  },
  {
    GEO_ID: '0500000US17075',
    STATE: '17',
    COUNTY: '075',
    NAME: 'Iroquois',
    LSAD: 'County',
    CENSUSAREA: 1117.316,
    FIPS: 'IL075',
    fips: '17075',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 40.74732578,
    lng: -87.82452537,
  },
  {
    GEO_ID: '0500000US17087',
    STATE: '17',
    COUNTY: '087',
    NAME: 'Johnson',
    LSAD: 'County',
    CENSUSAREA: 343.915,
    FIPS: 'IL087',
    fips: '17087',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 37.45959866,
    lng: -88.88074567,
  },
  {
    GEO_ID: '0500000US17095',
    STATE: '17',
    COUNTY: '095',
    NAME: 'Knox',
    LSAD: 'County',
    CENSUSAREA: 716.395,
    FIPS: 'IL095',
    fips: '17095',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 40.93148779,
    lng: -90.2133034,
  },
  {
    GEO_ID: '0500000US17113',
    STATE: '17',
    COUNTY: '113',
    NAME: 'McLean',
    LSAD: 'County',
    CENSUSAREA: 1183.378,
    FIPS: 'IL113',
    fips: '17113',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 40.49061752,
    lng: -88.84769482,
  },
  {
    GEO_ID: '0500000US17123',
    STATE: '17',
    COUNTY: '123',
    NAME: 'Marshall',
    LSAD: 'County',
    CENSUSAREA: 386.789,
    FIPS: 'IL123',
    fips: '17123',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 41.03327407,
    lng: -89.34481931,
  },
  {
    GEO_ID: '0500000US17135',
    STATE: '17',
    COUNTY: '135',
    NAME: 'Montgomery',
    LSAD: 'County',
    CENSUSAREA: 703.687,
    FIPS: 'IL135',
    fips: '17135',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 39.23097041,
    lng: -89.4790156,
  },
  {
    GEO_ID: '0500000US17151',
    STATE: '17',
    COUNTY: '151',
    NAME: 'Pope',
    LSAD: 'County',
    CENSUSAREA: 368.77,
    FIPS: 'IL151',
    fips: '17151',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 37.41325351,
    lng: -88.56128333,
  },
  {
    GEO_ID: '0500000US17163',
    STATE: '17',
    COUNTY: '163',
    NAME: 'St. Clair',
    LSAD: 'County',
    CENSUSAREA: 657.759,
    FIPS: 'IL163',
    fips: '17163',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 38.4703223,
    lng: -89.92848248,
  },
  {
    GEO_ID: '0500000US17187',
    STATE: '17',
    COUNTY: '187',
    NAME: 'Warren',
    LSAD: 'County',
    CENSUSAREA: 542.405,
    FIPS: 'IL187',
    fips: '17187',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 40.84888704,
    lng: -90.61478203,
  },
  {
    GEO_ID: '0500000US17199',
    STATE: '17',
    COUNTY: '199',
    NAME: 'Williamson',
    LSAD: 'County',
    CENSUSAREA: 420.149,
    FIPS: 'IL199',
    fips: '17199',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 37.73040742,
    lng: -88.93009646,
  },
  {
    GEO_ID: '0500000US31107',
    STATE: '31',
    COUNTY: '107',
    NAME: 'Knox',
    LSAD: 'County',
    CENSUSAREA: 1108.35,
    FIPS: 'NE107',
    fips: '31107',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 42.63684531,
    lng: -97.89176183,
  },
  {
    GEO_ID: '0500000US28077',
    STATE: '28',
    COUNTY: '077',
    NAME: 'Lawrence',
    LSAD: 'County',
    CENSUSAREA: 430.668,
    FIPS: 'MS077',
    fips: '28077',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 31.54996744,
    lng: -90.10693379,
  },
  {
    GEO_ID: '0500000US28081',
    STATE: '28',
    COUNTY: '081',
    NAME: 'Lee',
    LSAD: 'County',
    CENSUSAREA: 449.951,
    FIPS: 'MS081',
    fips: '28081',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 34.28973933,
    lng: -88.68047663,
  },
  {
    GEO_ID: '0500000US28085',
    STATE: '28',
    COUNTY: '085',
    NAME: 'Lincoln',
    LSAD: 'County',
    CENSUSAREA: 586.115,
    FIPS: 'MS085',
    fips: '28085',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 31.53240135,
    lng: -90.45417438,
  },
  {
    GEO_ID: '0500000US28087',
    STATE: '28',
    COUNTY: '087',
    NAME: 'Lowndes',
    LSAD: 'County',
    CENSUSAREA: 505.509,
    FIPS: 'MS087',
    fips: '28087',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 33.47293459,
    lng: -88.4432447,
  },
  {
    GEO_ID: '0500000US28107',
    STATE: '28',
    COUNTY: '107',
    NAME: 'Panola',
    LSAD: 'County',
    CENSUSAREA: 685.144,
    FIPS: 'MS107',
    fips: '28107',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 34.3639856,
    lng: -89.95079634,
  },
  {
    GEO_ID: '0500000US28123',
    STATE: '28',
    COUNTY: '123',
    NAME: 'Scott',
    LSAD: 'County',
    CENSUSAREA: 609.185,
    FIPS: 'MS123',
    fips: '28123',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 32.4067041,
    lng: -89.53789439,
  },
  {
    GEO_ID: '0500000US28133',
    STATE: '28',
    COUNTY: '133',
    NAME: 'Sunflower',
    LSAD: 'County',
    CENSUSAREA: 697.754,
    FIPS: 'MS133',
    fips: '28133',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 33.60184158,
    lng: -90.58875506,
  },
  {
    GEO_ID: '0500000US28145',
    STATE: '28',
    COUNTY: '145',
    NAME: 'Union',
    LSAD: 'County',
    CENSUSAREA: 415.598,
    FIPS: 'MS145',
    fips: '28145',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 34.49025133,
    lng: -89.00393228,
  },
  {
    GEO_ID: '0500000US28159',
    STATE: '28',
    COUNTY: '159',
    NAME: 'Winston',
    LSAD: 'County',
    CENSUSAREA: 607.245,
    FIPS: 'MS159',
    fips: '28159',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 33.0883872,
    lng: -89.03430894,
  },
  {
    GEO_ID: '0500000US29003',
    STATE: '29',
    COUNTY: '003',
    NAME: 'Andrew',
    LSAD: 'County',
    CENSUSAREA: 432.703,
    FIPS: 'MO003',
    fips: '29003',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 39.98335111,
    lng: -94.80140498,
  },
  {
    GEO_ID: '0500000US29015',
    STATE: '29',
    COUNTY: '015',
    NAME: 'Benton',
    LSAD: 'County',
    CENSUSAREA: 704.06,
    FIPS: 'MO015',
    fips: '29015',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 38.2951634,
    lng: -93.28827876,
  },
  {
    GEO_ID: '0500000US29027',
    STATE: '29',
    COUNTY: '027',
    NAME: 'Callaway',
    LSAD: 'County',
    CENSUSAREA: 834.575,
    FIPS: 'MO027',
    fips: '29027',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 38.83570509,
    lng: -91.92616939,
  },
  {
    GEO_ID: '0500000US29039',
    STATE: '29',
    COUNTY: '039',
    NAME: 'Cedar',
    LSAD: 'County',
    CENSUSAREA: 474.483,
    FIPS: 'MO039',
    fips: '29039',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 37.72350148,
    lng: -93.85592541,
  },
  {
    GEO_ID: '0500000US29049',
    STATE: '29',
    COUNTY: '049',
    NAME: 'Clinton',
    LSAD: 'County',
    CENSUSAREA: 418.956,
    FIPS: 'MO049',
    fips: '29049',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 39.60124265,
    lng: -94.40468366,
  },
  {
    GEO_ID: '0500000US29059',
    STATE: '29',
    COUNTY: '059',
    NAME: 'Dallas',
    LSAD: 'County',
    CENSUSAREA: 540.77,
    FIPS: 'MO059',
    fips: '29059',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 37.68065861,
    lng: -93.02340823,
  },
  {
    GEO_ID: '0500000US29071',
    STATE: '29',
    COUNTY: '071',
    NAME: 'Franklin',
    LSAD: 'County',
    CENSUSAREA: 922.681,
    FIPS: 'MO071',
    fips: '29071',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 38.4111314,
    lng: -91.07489027,
  },
  {
    GEO_ID: '0500000US48107',
    STATE: '48',
    COUNTY: '107',
    NAME: 'Crosby',
    LSAD: 'County',
    CENSUSAREA: 900.198,
    FIPS: 'TX107',
    fips: '48107',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 33.61431553,
    lng: -101.3000364,
  },
  {
    GEO_ID: '0500000US48119',
    STATE: '48',
    COUNTY: '119',
    NAME: 'Delta',
    LSAD: 'County',
    CENSUSAREA: 256.825,
    FIPS: 'TX119',
    fips: '48119',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 33.38664358,
    lng: -95.67114037,
  },
  {
    GEO_ID: '0500000US48131',
    STATE: '48',
    COUNTY: '131',
    NAME: 'Duval',
    LSAD: 'County',
    CENSUSAREA: 1793.481,
    FIPS: 'TX131',
    fips: '48131',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 27.68142846,
    lng: -98.50873333,
  },
  {
    GEO_ID: '0500000US48143',
    STATE: '48',
    COUNTY: '143',
    NAME: 'Erath',
    LSAD: 'County',
    CENSUSAREA: 1083.071,
    FIPS: 'TX143',
    fips: '48143',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 32.23614282,
    lng: -98.21770884,
  },
  {
    GEO_ID: '0500000US48153',
    STATE: '48',
    COUNTY: '153',
    NAME: 'Floyd',
    LSAD: 'County',
    CENSUSAREA: 992.143,
    FIPS: 'TX153',
    fips: '48153',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 34.07227468,
    lng: -101.3031546,
  },
  {
    GEO_ID: '0500000US48163',
    STATE: '48',
    COUNTY: '163',
    NAME: 'Frio',
    LSAD: 'County',
    CENSUSAREA: 1133.5,
    FIPS: 'TX163',
    fips: '48163',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 28.86751206,
    lng: -99.10804207,
  },
  {
    GEO_ID: '0500000US48171',
    STATE: '48',
    COUNTY: '171',
    NAME: 'Gillespie',
    LSAD: 'County',
    CENSUSAREA: 1058.214,
    FIPS: 'TX171',
    fips: '48171',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 30.31824095,
    lng: -98.94676915,
  },
  {
    GEO_ID: '0500000US48173',
    STATE: '48',
    COUNTY: '173',
    NAME: 'Glasscock',
    LSAD: 'County',
    CENSUSAREA: 900.218,
    FIPS: 'TX173',
    fips: '48173',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 31.86942909,
    lng: -101.5207776,
  },
  {
    GEO_ID: '0500000US48175',
    STATE: '48',
    COUNTY: '175',
    NAME: 'Goliad',
    LSAD: 'County',
    CENSUSAREA: 852.014,
    FIPS: 'TX175',
    fips: '48175',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 28.65724851,
    lng: -97.42628751,
  },
  {
    GEO_ID: '0500000US48183',
    STATE: '48',
    COUNTY: '183',
    NAME: 'Gregg',
    LSAD: 'County',
    CENSUSAREA: 273.303,
    FIPS: 'TX183',
    fips: '48183',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 32.4801034,
    lng: -94.81675582,
  },
  {
    GEO_ID: '0500000US48187',
    STATE: '48',
    COUNTY: '187',
    NAME: 'Guadalupe',
    LSAD: 'County',
    CENSUSAREA: 711.3,
    FIPS: 'TX187',
    fips: '48187',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 29.58321407,
    lng: -97.9484645,
  },
  {
    GEO_ID: '0500000US48205',
    STATE: '48',
    COUNTY: '205',
    NAME: 'Hartley',
    LSAD: 'County',
    CENSUSAREA: 1462.033,
    FIPS: 'TX205',
    fips: '48205',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 35.84008345,
    lng: -102.6029352,
  },
  {
    GEO_ID: '0500000US48209',
    STATE: '48',
    COUNTY: '209',
    NAME: 'Hays',
    LSAD: 'County',
    CENSUSAREA: 677.978,
    FIPS: 'TX209',
    fips: '48209',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 30.05810528,
    lng: -98.03105783,
  },
  {
    GEO_ID: '0500000US48219',
    STATE: '48',
    COUNTY: '219',
    NAME: 'Hockley',
    LSAD: 'County',
    CENSUSAREA: 908.392,
    FIPS: 'TX219',
    fips: '48219',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 33.60771256,
    lng: -102.3431676,
  },
  {
    GEO_ID: '0500000US48231',
    STATE: '48',
    COUNTY: '231',
    NAME: 'Hunt',
    LSAD: 'County',
    CENSUSAREA: 840.318,
    FIPS: 'TX231',
    fips: '48231',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 33.12356693,
    lng: -96.08560239,
  },
  {
    GEO_ID: '0500000US48241',
    STATE: '48',
    COUNTY: '241',
    NAME: 'Jasper',
    LSAD: 'County',
    CENSUSAREA: 938.845,
    FIPS: 'TX241',
    fips: '48241',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 30.74482946,
    lng: -94.02607231,
  },
  {
    GEO_ID: '0500000US48255',
    STATE: '48',
    COUNTY: '255',
    NAME: 'Karnes',
    LSAD: 'County',
    CENSUSAREA: 747.556,
    FIPS: 'TX255',
    fips: '48255',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 28.90564205,
    lng: -97.85944813,
  },
  {
    GEO_ID: '0500000US48269',
    STATE: '48',
    COUNTY: '269',
    NAME: 'King',
    LSAD: 'County',
    CENSUSAREA: 910.872,
    FIPS: 'TX269',
    fips: '48269',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 33.61643847,
    lng: -100.2558057,
  },
  {
    GEO_ID: '0500000US48279',
    STATE: '48',
    COUNTY: '279',
    NAME: 'Lamb',
    LSAD: 'County',
    CENSUSAREA: 1016.181,
    FIPS: 'TX279',
    fips: '48279',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 34.06859623,
    lng: -102.3516289,
  },
  {
    GEO_ID: '0500000US48289',
    STATE: '48',
    COUNTY: '289',
    NAME: 'Leon',
    LSAD: 'County',
    CENSUSAREA: 1073.151,
    FIPS: 'TX289',
    fips: '48289',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 31.29628413,
    lng: -95.9957025,
  },
  {
    GEO_ID: '0500000US08033',
    STATE: '08',
    COUNTY: '033',
    NAME: 'Dolores',
    LSAD: 'County',
    CENSUSAREA: 1067.051,
    FIPS: 'CO033',
    fips: '08033',
    StateAbbr: 'CO',
    StateName: 'Colorado',
    lat: 37.75208472,
    lng: -108.5173886,
  },
  {
    GEO_ID: '0500000US08039',
    STATE: '08',
    COUNTY: '039',
    NAME: 'Elbert',
    LSAD: 'County',
    CENSUSAREA: 1850.847,
    FIPS: 'CO039',
    fips: '08039',
    StateAbbr: 'CO',
    StateName: 'Colorado',
    lat: 39.2863996,
    lng: -104.1351629,
  },
  {
    GEO_ID: '0500000US08045',
    STATE: '08',
    COUNTY: '045',
    NAME: 'Garfield',
    LSAD: 'County',
    CENSUSAREA: 2947.563,
    FIPS: 'CO045',
    fips: '08045',
    StateAbbr: 'CO',
    StateName: 'Colorado',
    lat: 39.59941978,
    lng: -107.9036205,
  },
  {
    GEO_ID: '0500000US06079',
    STATE: '06',
    COUNTY: '079',
    NAME: 'San Luis Obispo',
    LSAD: 'County',
    CENSUSAREA: 3298.567,
    FIPS: 'CA079',
    fips: '06079',
    StateAbbr: 'CA',
    StateName: 'California',
    lat: 35.38707354,
    lng: -120.4042917,
  },
  {
    GEO_ID: '0500000US06093',
    STATE: '06',
    COUNTY: '093',
    NAME: 'Siskiyou',
    LSAD: 'County',
    CENSUSAREA: 6277.887,
    FIPS: 'CA093',
    fips: '06093',
    StateAbbr: 'CA',
    StateName: 'California',
    lat: 41.59272613,
    lng: -122.5405164,
  },
  {
    GEO_ID: '0500000US06097',
    STATE: '06',
    COUNTY: '097',
    NAME: 'Sonoma',
    LSAD: 'County',
    CENSUSAREA: 1575.849,
    FIPS: 'CA097',
    fips: '06097',
    StateAbbr: 'CA',
    StateName: 'California',
    lat: 38.52842052,
    lng: -122.8872965,
  },
  {
    GEO_ID: '0500000US06105',
    STATE: '06',
    COUNTY: '105',
    NAME: 'Trinity',
    LSAD: 'County',
    CENSUSAREA: 3179.254,
    FIPS: 'CA105',
    fips: '06105',
    StateAbbr: 'CA',
    StateName: 'California',
    lat: 40.65059444,
    lng: -123.1126773,
  },
  {
    GEO_ID: '0500000US06017',
    STATE: '06',
    COUNTY: '017',
    NAME: 'El Dorado',
    LSAD: 'County',
    CENSUSAREA: 1707.883,
    FIPS: 'CA017',
    fips: '06017',
    StateAbbr: 'CA',
    StateName: 'California',
    lat: 38.77888901,
    lng: -120.5247066,
  },
  {
    GEO_ID: '0500000US18005',
    STATE: '18',
    COUNTY: '005',
    NAME: 'Bartholomew',
    LSAD: 'County',
    CENSUSAREA: 406.908,
    FIPS: 'IN005',
    fips: '18005',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 39.20574386,
    lng: -85.89789136,
  },
  {
    GEO_ID: '0500000US18017',
    STATE: '18',
    COUNTY: '017',
    NAME: 'Cass',
    LSAD: 'County',
    CENSUSAREA: 412.155,
    FIPS: 'IN017',
    fips: '18017',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 40.76165964,
    lng: -86.34620721,
  },
  {
    GEO_ID: '0500000US18027',
    STATE: '18',
    COUNTY: '027',
    NAME: 'Daviess',
    LSAD: 'County',
    CENSUSAREA: 429.487,
    FIPS: 'IN027',
    fips: '18027',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 38.70229994,
    lng: -87.07197336,
  },
  {
    GEO_ID: '0500000US18041',
    STATE: '18',
    COUNTY: '041',
    NAME: 'Fayette',
    LSAD: 'County',
    CENSUSAREA: 215.014,
    FIPS: 'IN041',
    fips: '18041',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 39.63983379,
    lng: -85.17841116,
  },
  {
    GEO_ID: '0500000US18043',
    STATE: '18',
    COUNTY: '043',
    NAME: 'Floyd',
    LSAD: 'County',
    CENSUSAREA: 147.935,
    FIPS: 'IN043',
    fips: '18043',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 38.31902045,
    lng: -85.90699249,
  },
  {
    GEO_ID: '0500000US13127',
    STATE: '13',
    COUNTY: '127',
    NAME: 'Glynn',
    LSAD: 'County',
    CENSUSAREA: 419.753,
    FIPS: 'GA127',
    fips: '13127',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 31.23071711,
    lng: -81.53985797,
  },
  {
    GEO_ID: '0500000US13133',
    STATE: '13',
    COUNTY: '133',
    NAME: 'Greene',
    LSAD: 'County',
    CENSUSAREA: 387.435,
    FIPS: 'GA133',
    fips: '13133',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 33.57887215,
    lng: -83.16678441,
  },
  {
    GEO_ID: '0500000US13143',
    STATE: '13',
    COUNTY: '143',
    NAME: 'Haralson',
    LSAD: 'County',
    CENSUSAREA: 282.165,
    FIPS: 'GA143',
    fips: '13143',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 33.79399282,
    lng: -85.21085788,
  },
  {
    GEO_ID: '0500000US13153',
    STATE: '13',
    COUNTY: '153',
    NAME: 'Houston',
    LSAD: 'County',
    CENSUSAREA: 375.541,
    FIPS: 'GA153',
    fips: '13153',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 32.45919716,
    lng: -83.66625831,
  },
  {
    GEO_ID: '0500000US13163',
    STATE: '13',
    COUNTY: '163',
    NAME: 'Jefferson',
    LSAD: 'County',
    CENSUSAREA: 526.485,
    FIPS: 'GA163',
    fips: '13163',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 33.05432827,
    lng: -82.41823729,
  },
  {
    GEO_ID: '0500000US13183',
    STATE: '13',
    COUNTY: '183',
    NAME: 'Long',
    LSAD: 'County',
    CENSUSAREA: 400.293,
    FIPS: 'GA183',
    fips: '13183',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 31.75246506,
    lng: -81.7454462,
  },
  {
    GEO_ID: '0500000US13193',
    STATE: '13',
    COUNTY: '193',
    NAME: 'Macon',
    LSAD: 'County',
    CENSUSAREA: 400.641,
    FIPS: 'GA193',
    fips: '13193',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 32.35871148,
    lng: -84.04282299,
  },
  {
    GEO_ID: '0500000US13207',
    STATE: '13',
    COUNTY: '207',
    NAME: 'Monroe',
    LSAD: 'County',
    CENSUSAREA: 395.658,
    FIPS: 'GA207',
    fips: '13207',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 33.0139317,
    lng: -83.91905929,
  },
  {
    GEO_ID: '0500000US13219',
    STATE: '13',
    COUNTY: '219',
    NAME: 'Oconee',
    LSAD: 'County',
    CENSUSAREA: 184.29,
    FIPS: 'GA219',
    fips: '13219',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 33.83506881,
    lng: -83.43706988,
  },
  {
    GEO_ID: '0500000US13227',
    STATE: '13',
    COUNTY: '227',
    NAME: 'Pickens',
    LSAD: 'County',
    CENSUSAREA: 232.057,
    FIPS: 'GA227',
    fips: '13227',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 34.46423209,
    lng: -84.46567239,
  },
  {
    GEO_ID: '0500000US13237',
    STATE: '13',
    COUNTY: '237',
    NAME: 'Putnam',
    LSAD: 'County',
    CENSUSAREA: 344.639,
    FIPS: 'GA237',
    fips: '13237',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 33.32175332,
    lng: -83.37315585,
  },
  {
    GEO_ID: '0500000US13249',
    STATE: '13',
    COUNTY: '249',
    NAME: 'Schley',
    LSAD: 'County',
    CENSUSAREA: 166.906,
    FIPS: 'GA249',
    fips: '13249',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 32.26331654,
    lng: -84.31547114,
  },
  {
    GEO_ID: '0500000US29083',
    STATE: '29',
    COUNTY: '083',
    NAME: 'Henry',
    LSAD: 'County',
    CENSUSAREA: 696.947,
    FIPS: 'MO083',
    fips: '29083',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 38.38506942,
    lng: -93.7928174,
  },
  {
    GEO_ID: '0500000US29093',
    STATE: '29',
    COUNTY: '093',
    NAME: 'Iron',
    LSAD: 'County',
    CENSUSAREA: 550.259,
    FIPS: 'MO093',
    fips: '29093',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 37.5553541,
    lng: -90.77398853,
  },
  {
    GEO_ID: '0500000US37003',
    STATE: '37',
    COUNTY: '003',
    NAME: 'Alexander',
    LSAD: 'County',
    CENSUSAREA: 259.994,
    FIPS: 'NC003',
    fips: '37003',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 35.92074753,
    lng: -81.17692946,
  },
  {
    GEO_ID: '0500000US37007',
    STATE: '37',
    COUNTY: '007',
    NAME: 'Anson',
    LSAD: 'County',
    CENSUSAREA: 531.452,
    FIPS: 'NC007',
    fips: '37007',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 34.97378348,
    lng: -80.10243931,
  },
  {
    GEO_ID: '0500000US37009',
    STATE: '37',
    COUNTY: '009',
    NAME: 'Ashe',
    LSAD: 'County',
    CENSUSAREA: 426.135,
    FIPS: 'NC009',
    fips: '37009',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 36.43415234,
    lng: -81.50052459,
  },
  {
    GEO_ID: '0500000US37033',
    STATE: '37',
    COUNTY: '033',
    NAME: 'Caswell',
    LSAD: 'County',
    CENSUSAREA: 424.922,
    FIPS: 'NC033',
    fips: '37033',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 36.39327555,
    lng: -79.33355984,
  },
  {
    GEO_ID: '0500000US37045',
    STATE: '37',
    COUNTY: '045',
    NAME: 'Cleveland',
    LSAD: 'County',
    CENSUSAREA: 464.252,
    FIPS: 'NC045',
    fips: '37045',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 35.33410942,
    lng: -81.55570623,
  },
  {
    GEO_ID: '0500000US37063',
    STATE: '37',
    COUNTY: '063',
    NAME: 'Durham',
    LSAD: 'County',
    CENSUSAREA: 285.975,
    FIPS: 'NC063',
    fips: '37063',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 36.03605321,
    lng: -78.87606872,
  },
  {
    GEO_ID: '0500000US37073',
    STATE: '37',
    COUNTY: '073',
    NAME: 'Gates',
    LSAD: 'County',
    CENSUSAREA: 340.445,
    FIPS: 'NC073',
    fips: '37073',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 36.44475497,
    lng: -76.70046305,
  },
  {
    GEO_ID: '0500000US37085',
    STATE: '37',
    COUNTY: '085',
    NAME: 'Harnett',
    LSAD: 'County',
    CENSUSAREA: 594.987,
    FIPS: 'NC085',
    fips: '37085',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 35.368729,
    lng: -78.86964365,
  },
  {
    GEO_ID: '0500000US37097',
    STATE: '37',
    COUNTY: '097',
    NAME: 'Iredell',
    LSAD: 'County',
    CENSUSAREA: 573.833,
    FIPS: 'NC097',
    fips: '37097',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 35.8068797,
    lng: -80.87332588,
  },
  {
    GEO_ID: '0500000US37109',
    STATE: '37',
    COUNTY: '109',
    NAME: 'Lincoln',
    LSAD: 'County',
    CENSUSAREA: 297.938,
    FIPS: 'NC109',
    fips: '37109',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 35.48589556,
    lng: -81.22393811,
  },
  {
    GEO_ID: '0500000US37123',
    STATE: '37',
    COUNTY: '123',
    NAME: 'Montgomery',
    LSAD: 'County',
    CENSUSAREA: 491.76,
    FIPS: 'NC123',
    fips: '37123',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 35.3323375,
    lng: -79.90576665,
  },
  {
    GEO_ID: '0500000US37133',
    STATE: '37',
    COUNTY: '133',
    NAME: 'Onslow',
    LSAD: 'County',
    CENSUSAREA: 762.744,
    FIPS: 'NC133',
    fips: '37133',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 34.72975141,
    lng: -77.42736897,
  },
  {
    GEO_ID: '0500000US37145',
    STATE: '37',
    COUNTY: '145',
    NAME: 'Person',
    LSAD: 'County',
    CENSUSAREA: 392.322,
    FIPS: 'NC145',
    fips: '37145',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 36.38999755,
    lng: -78.9715773,
  },
  {
    GEO_ID: '0500000US48299',
    STATE: '48',
    COUNTY: '299',
    NAME: 'Llano',
    LSAD: 'County',
    CENSUSAREA: 934.034,
    FIPS: 'TX299',
    fips: '48299',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 30.70581617,
    lng: -98.68429358,
  },
  {
    GEO_ID: '0500000US48313',
    STATE: '48',
    COUNTY: '313',
    NAME: 'Madison',
    LSAD: 'County',
    CENSUSAREA: 466.065,
    FIPS: 'TX313',
    fips: '48313',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 30.96540306,
    lng: -95.92738963,
  },
  {
    GEO_ID: '0500000US48325',
    STATE: '48',
    COUNTY: '325',
    NAME: 'Medina',
    LSAD: 'County',
    CENSUSAREA: 1325.356,
    FIPS: 'TX325',
    fips: '48325',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 29.35546127,
    lng: -99.11033508,
  },
  {
    GEO_ID: '0500000US48335',
    STATE: '48',
    COUNTY: '335',
    NAME: 'Mitchell',
    LSAD: 'County',
    CENSUSAREA: 911.09,
    FIPS: 'TX335',
    fips: '48335',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 32.30623474,
    lng: -100.9212058,
  },
  {
    GEO_ID: '0500000US48343',
    STATE: '48',
    COUNTY: '343',
    NAME: 'Morris',
    LSAD: 'County',
    CENSUSAREA: 251.985,
    FIPS: 'TX343',
    fips: '48343',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 33.11284956,
    lng: -94.73282508,
  },
  {
    GEO_ID: '0500000US51127',
    STATE: '51',
    COUNTY: '127',
    NAME: 'New Kent',
    LSAD: 'County',
    CENSUSAREA: 209.729,
    FIPS: 'VA127',
    fips: '51127',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 37.50499104,
    lng: -76.9971687,
  },
  {
    GEO_ID: '0500000US51133',
    STATE: '51',
    COUNTY: '133',
    NAME: 'Northumberland',
    LSAD: 'County',
    CENSUSAREA: 191.295,
    FIPS: 'VA133',
    fips: '51133',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 37.8876167,
    lng: -76.41950915,
  },
  {
    GEO_ID: '0500000US51167',
    STATE: '51',
    COUNTY: '167',
    NAME: 'Russell',
    LSAD: 'County',
    CENSUSAREA: 473.822,
    FIPS: 'VA167',
    fips: '51167',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 36.93361023,
    lng: -82.09596509,
  },
  {
    GEO_ID: '0500000US51179',
    STATE: '51',
    COUNTY: '179',
    NAME: 'Stafford',
    LSAD: 'County',
    CENSUSAREA: 268.956,
    FIPS: 'VA179',
    fips: '51179',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 38.4206167,
    lng: -77.45796206,
  },
  {
    GEO_ID: '0500000US51515',
    STATE: '51',
    COUNTY: '515',
    NAME: 'Bedford',
    LSAD: 'city',
    CENSUSAREA: 6.878,
    FIPS: 'VA515',
    fips: '51515',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 0,
    lng: 0,
  },
  {
    GEO_ID: '0500000US51590',
    STATE: '51',
    COUNTY: '590',
    NAME: 'Danville',
    LSAD: 'city',
    CENSUSAREA: 42.934,
    FIPS: 'VA590',
    fips: '51590',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 36.58309677,
    lng: -79.40912354,
  },
  {
    GEO_ID: '0500000US51620',
    STATE: '51',
    COUNTY: '620',
    NAME: 'Franklin',
    LSAD: 'city',
    CENSUSAREA: 8.206,
    FIPS: 'VA620',
    fips: '51620',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 36.68306879,
    lng: -76.93848875,
  },
  {
    GEO_ID: '0500000US51680',
    STATE: '51',
    COUNTY: '680',
    NAME: 'Lynchburg',
    LSAD: 'city',
    CENSUSAREA: 49.128,
    FIPS: 'VA680',
    fips: '51680',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 37.40023149,
    lng: -79.19044951,
  },
  {
    GEO_ID: '0500000US51720',
    STATE: '51',
    COUNTY: '720',
    NAME: 'Norton',
    LSAD: 'city',
    CENSUSAREA: 7.481,
    FIPS: 'VA720',
    fips: '51720',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 36.9310873,
    lng: -82.62648895,
  },
  {
    GEO_ID: '0500000US51740',
    STATE: '51',
    COUNTY: '740',
    NAME: 'Portsmouth',
    LSAD: 'city',
    CENSUSAREA: 33.653,
    FIPS: 'VA740',
    fips: '51740',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 36.84646209,
    lng: -76.35396761,
  },
  {
    GEO_ID: '0500000US51830',
    STATE: '51',
    COUNTY: '830',
    NAME: 'Williamsburg',
    LSAD: 'city',
    CENSUSAREA: 9.022,
    FIPS: 'VA830',
    fips: '51830',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 37.26927809,
    lng: -76.70721344,
  },
  {
    GEO_ID: '0500000US12086',
    STATE: '12',
    COUNTY: '086',
    NAME: 'Miami-Dade',
    LSAD: 'County',
    CENSUSAREA: 1897.716,
    FIPS: 'FL086',
    fips: '12086',
    StateAbbr: 'FL',
    StateName: 'Florida',
    lat: 25.61511557,
    lng: -80.55914415,
  },
  {
    GEO_ID: '0500000US12103',
    STATE: '12',
    COUNTY: '103',
    NAME: 'Pinellas',
    LSAD: 'County',
    CENSUSAREA: 273.799,
    FIPS: 'FL103',
    fips: '12103',
    StateAbbr: 'FL',
    StateName: 'Florida',
    lat: 27.92895178,
    lng: -82.73164012,
  },
  {
    GEO_ID: '0500000US12123',
    STATE: '12',
    COUNTY: '123',
    NAME: 'Taylor',
    LSAD: 'County',
    CENSUSAREA: 1043.305,
    FIPS: 'FL123',
    fips: '12123',
    StateAbbr: 'FL',
    StateName: 'Florida',
    lat: 30.04712958,
    lng: -83.60328684,
  },
  {
    GEO_ID: '0500000US13259',
    STATE: '13',
    COUNTY: '259',
    NAME: 'Stewart',
    LSAD: 'County',
    CENSUSAREA: 458.733,
    FIPS: 'GA259',
    fips: '13259',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 32.07839601,
    lng: -84.83395276,
  },
  {
    GEO_ID: '0500000US13271',
    STATE: '13',
    COUNTY: '271',
    NAME: 'Telfair',
    LSAD: 'County',
    CENSUSAREA: 437.303,
    FIPS: 'GA271',
    fips: '13271',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 31.92997221,
    lng: -82.93880265,
  },
  {
    GEO_ID: '0500000US13283',
    STATE: '13',
    COUNTY: '283',
    NAME: 'Treutlen',
    LSAD: 'County',
    CENSUSAREA: 199.436,
    FIPS: 'GA283',
    fips: '13283',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 32.40331611,
    lng: -82.56783271,
  },
  {
    GEO_ID: '0500000US13297',
    STATE: '13',
    COUNTY: '297',
    NAME: 'Walton',
    LSAD: 'County',
    CENSUSAREA: 325.68,
    FIPS: 'GA297',
    fips: '13297',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 33.78121226,
    lng: -83.73367264,
  },
  {
    GEO_ID: '0500000US20025',
    STATE: '20',
    COUNTY: '025',
    NAME: 'Clark',
    LSAD: 'County',
    CENSUSAREA: 974.632,
    FIPS: 'KS025',
    fips: '20025',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 37.23547247,
    lng: -99.8196146,
  },
  {
    GEO_ID: '0500000US20031',
    STATE: '20',
    COUNTY: '031',
    NAME: 'Coffey',
    LSAD: 'County',
    CENSUSAREA: 626.947,
    FIPS: 'KS031',
    fips: '20031',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 38.23677253,
    lng: -95.73407587,
  },
  {
    GEO_ID: '0500000US20033',
    STATE: '20',
    COUNTY: '033',
    NAME: 'Comanche',
    LSAD: 'County',
    CENSUSAREA: 788.298,
    FIPS: 'KS033',
    fips: '20033',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 37.19121917,
    lng: -99.27105698,
  },
  {
    GEO_ID: '0500000US20045',
    STATE: '20',
    COUNTY: '045',
    NAME: 'Douglas',
    LSAD: 'County',
    CENSUSAREA: 455.871,
    FIPS: 'KS045',
    fips: '20045',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 38.88451412,
    lng: -95.29263542,
  },
  {
    GEO_ID: '0500000US20055',
    STATE: '20',
    COUNTY: '055',
    NAME: 'Finney',
    LSAD: 'County',
    CENSUSAREA: 1301.967,
    FIPS: 'KS055',
    fips: '20055',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 38.04405338,
    lng: -100.7376459,
  },
  {
    GEO_ID: '0500000US20069',
    STATE: '20',
    COUNTY: '069',
    NAME: 'Gray',
    LSAD: 'County',
    CENSUSAREA: 868.867,
    FIPS: 'KS069',
    fips: '20069',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 37.73851836,
    lng: -100.4379698,
  },
  {
    GEO_ID: '0500000US20081',
    STATE: '20',
    COUNTY: '081',
    NAME: 'Haskell',
    LSAD: 'County',
    CENSUSAREA: 577.521,
    FIPS: 'KS081',
    fips: '20081',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 37.56200432,
    lng: -100.8710456,
  },
  {
    GEO_ID: '0500000US20093',
    STATE: '20',
    COUNTY: '093',
    NAME: 'Kearny',
    LSAD: 'County',
    CENSUSAREA: 870.543,
    FIPS: 'KS093',
    fips: '20093',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 38.00024973,
    lng: -101.3203923,
  },
  {
    GEO_ID: '0500000US20101',
    STATE: '20',
    COUNTY: '101',
    NAME: 'Lane',
    LSAD: 'County',
    CENSUSAREA: 717.455,
    FIPS: 'KS101',
    fips: '20101',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 38.48119447,
    lng: -100.4660754,
  },
  {
    GEO_ID: '0500000US20111',
    STATE: '20',
    COUNTY: '111',
    NAME: 'Lyon',
    LSAD: 'County',
    CENSUSAREA: 847.474,
    FIPS: 'KS111',
    fips: '20111',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 38.45580918,
    lng: -96.15280427,
  },
  {
    GEO_ID: '0500000US20123',
    STATE: '20',
    COUNTY: '123',
    NAME: 'Mitchell',
    LSAD: 'County',
    CENSUSAREA: 701.792,
    FIPS: 'KS123',
    fips: '20123',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 39.39334518,
    lng: -98.20900663,
  },
  {
    GEO_ID: '0500000US20133',
    STATE: '20',
    COUNTY: '133',
    NAME: 'Neosho',
    LSAD: 'County',
    CENSUSAREA: 571.472,
    FIPS: 'KS133',
    fips: '20133',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 37.5582728,
    lng: -95.30682401,
  },
  {
    GEO_ID: '0500000US20139',
    STATE: '20',
    COUNTY: '139',
    NAME: 'Osage',
    LSAD: 'County',
    CENSUSAREA: 705.518,
    FIPS: 'KS139',
    fips: '20139',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 38.65235984,
    lng: -95.72707998,
  },
  {
    GEO_ID: '0500000US20151',
    STATE: '20',
    COUNTY: '151',
    NAME: 'Pratt',
    LSAD: 'County',
    CENSUSAREA: 735.045,
    FIPS: 'KS151',
    fips: '20151',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 37.6478469,
    lng: -98.73915959,
  },
  {
    GEO_ID: '0500000US20159',
    STATE: '20',
    COUNTY: '159',
    NAME: 'Rice',
    LSAD: 'County',
    CENSUSAREA: 726.237,
    FIPS: 'KS159',
    fips: '20159',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 38.34721983,
    lng: -98.20053402,
  },
  {
    GEO_ID: '0500000US20169',
    STATE: '20',
    COUNTY: '169',
    NAME: 'Saline',
    LSAD: 'County',
    CENSUSAREA: 720.225,
    FIPS: 'KS169',
    fips: '20169',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 38.78394989,
    lng: -97.65012272,
  },
  {
    GEO_ID: '0500000US37157',
    STATE: '37',
    COUNTY: '157',
    NAME: 'Rockingham',
    LSAD: 'County',
    CENSUSAREA: 565.551,
    FIPS: 'NC157',
    fips: '37157',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 36.39621434,
    lng: -79.77527984,
  },
  {
    GEO_ID: '0500000US37173',
    STATE: '37',
    COUNTY: '173',
    NAME: 'Swain',
    LSAD: 'County',
    CENSUSAREA: 527.996,
    FIPS: 'NC173',
    fips: '37173',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 35.48677176,
    lng: -83.4921876,
  },
  {
    GEO_ID: '0500000US37179',
    STATE: '37',
    COUNTY: '179',
    NAME: 'Union',
    LSAD: 'County',
    CENSUSAREA: 631.52,
    FIPS: 'NC179',
    fips: '37179',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 34.98836835,
    lng: -80.53061478,
  },
  {
    GEO_ID: '0500000US39059',
    STATE: '39',
    COUNTY: '059',
    NAME: 'Guernsey',
    LSAD: 'County',
    CENSUSAREA: 522.254,
    FIPS: 'OH059',
    fips: '39059',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 40.05191963,
    lng: -81.49400106,
  },
  {
    GEO_ID: '0500000US39069',
    STATE: '39',
    COUNTY: '069',
    NAME: 'Henry',
    LSAD: 'County',
    CENSUSAREA: 416.01,
    FIPS: 'OH069',
    fips: '39069',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 41.3339642,
    lng: -84.06830637,
  },
  {
    GEO_ID: '0500000US39083',
    STATE: '39',
    COUNTY: '083',
    NAME: 'Knox',
    LSAD: 'County',
    CENSUSAREA: 525.494,
    FIPS: 'OH083',
    fips: '39083',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 40.39902351,
    lng: -82.42287375,
  },
  {
    GEO_ID: '0500000US39091',
    STATE: '39',
    COUNTY: '091',
    NAME: 'Logan',
    LSAD: 'County',
    CENSUSAREA: 458.429,
    FIPS: 'OH091',
    fips: '39091',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 40.3883971,
    lng: -83.76526991,
  },
  {
    GEO_ID: '0500000US39103',
    STATE: '39',
    COUNTY: '103',
    NAME: 'Medina',
    LSAD: 'County',
    CENSUSAREA: 421.358,
    FIPS: 'OH103',
    fips: '39103',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 41.11770589,
    lng: -81.8998621,
  },
  {
    GEO_ID: '0500000US39115',
    STATE: '39',
    COUNTY: '115',
    NAME: 'Morgan',
    LSAD: 'County',
    CENSUSAREA: 416.423,
    FIPS: 'OH115',
    fips: '39115',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 39.62071306,
    lng: -81.85284727,
  },
  {
    GEO_ID: '0500000US39127',
    STATE: '39',
    COUNTY: '127',
    NAME: 'Perry',
    LSAD: 'County',
    CENSUSAREA: 407.971,
    FIPS: 'OH127',
    fips: '39127',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 39.73723959,
    lng: -82.23618289,
  },
  {
    GEO_ID: '0500000US39137',
    STATE: '39',
    COUNTY: '137',
    NAME: 'Putnam',
    LSAD: 'County',
    CENSUSAREA: 482.522,
    FIPS: 'OH137',
    fips: '39137',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 41.02216769,
    lng: -84.13168547,
  },
  {
    GEO_ID: '0500000US39153',
    STATE: '39',
    COUNTY: '153',
    NAME: 'Summit',
    LSAD: 'County',
    CENSUSAREA: 412.748,
    FIPS: 'OH153',
    fips: '39153',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 41.12587569,
    lng: -81.53219101,
  },
  {
    GEO_ID: '0500000US39165',
    STATE: '39',
    COUNTY: '165',
    NAME: 'Warren',
    LSAD: 'County',
    CENSUSAREA: 401.314,
    FIPS: 'OH165',
    fips: '39165',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 39.42735056,
    lng: -84.16728624,
  },
  {
    GEO_ID: '0500000US40005',
    STATE: '40',
    COUNTY: '005',
    NAME: 'Atoka',
    LSAD: 'County',
    CENSUSAREA: 975.517,
    FIPS: 'OK005',
    fips: '40005',
    StateAbbr: 'OK',
    StateName: 'Oklahoma',
    lat: 34.37367259,
    lng: -96.03794859,
  },
  {
    GEO_ID: '0500000US40015',
    STATE: '40',
    COUNTY: '015',
    NAME: 'Caddo',
    LSAD: 'County',
    CENSUSAREA: 1278.289,
    FIPS: 'OK015',
    fips: '40015',
    StateAbbr: 'OK',
    StateName: 'Oklahoma',
    lat: 35.17437642,
    lng: -98.37502407,
  },
  {
    GEO_ID: '0500000US40027',
    STATE: '40',
    COUNTY: '027',
    NAME: 'Cleveland',
    LSAD: 'County',
    CENSUSAREA: 538.766,
    FIPS: 'OK027',
    fips: '40027',
    StateAbbr: 'OK',
    StateName: 'Oklahoma',
    lat: 35.20346872,
    lng: -97.3267069,
  },
  {
    GEO_ID: '0500000US53011',
    STATE: '53',
    COUNTY: '011',
    NAME: 'Clark',
    LSAD: 'County',
    CENSUSAREA: 629.004,
    FIPS: 'WA011',
    fips: '53011',
    StateAbbr: 'WA',
    StateName: 'Washington',
    lat: 45.77919832,
    lng: -122.4823115,
  },
  {
    GEO_ID: '0500000US53035',
    STATE: '53',
    COUNTY: '035',
    NAME: 'Kitsap',
    LSAD: 'County',
    CENSUSAREA: 394.941,
    FIPS: 'WA035',
    fips: '53035',
    StateAbbr: 'WA',
    StateName: 'Washington',
    lat: 47.61342417,
    lng: -122.6734022,
  },
  {
    GEO_ID: '0500000US55053',
    STATE: '55',
    COUNTY: '053',
    NAME: 'Jackson',
    LSAD: 'County',
    CENSUSAREA: 987.72,
    FIPS: 'WI053',
    fips: '55053',
    StateAbbr: 'WI',
    StateName: 'Wisconsin',
    lat: 44.31927005,
    lng: -90.80515334,
  },
  {
    GEO_ID: '0500000US55067',
    STATE: '55',
    COUNTY: '067',
    NAME: 'Langlade',
    LSAD: 'County',
    CENSUSAREA: 870.64,
    FIPS: 'WI067',
    fips: '55067',
    StateAbbr: 'WI',
    StateName: 'Wisconsin',
    lat: 45.26219987,
    lng: -89.07193609,
  },
  {
    GEO_ID: '0500000US55078',
    STATE: '55',
    COUNTY: '078',
    NAME: 'Menominee',
    LSAD: 'County',
    CENSUSAREA: 357.608,
    FIPS: 'WI078',
    fips: '55078',
    StateAbbr: 'WI',
    StateName: 'Wisconsin',
    lat: 45.00428348,
    lng: -88.70957298,
  },
  {
    GEO_ID: '0500000US55087',
    STATE: '55',
    COUNTY: '087',
    NAME: 'Outagamie',
    LSAD: 'County',
    CENSUSAREA: 637.523,
    FIPS: 'WI087',
    fips: '55087',
    StateAbbr: 'WI',
    StateName: 'Wisconsin',
    lat: 44.41578887,
    lng: -88.46565023,
  },
  {
    GEO_ID: '0500000US55099',
    STATE: '55',
    COUNTY: '099',
    NAME: 'Price',
    LSAD: 'County',
    CENSUSAREA: 1254.376,
    FIPS: 'WI099',
    fips: '55099',
    StateAbbr: 'WI',
    StateName: 'Wisconsin',
    lat: 45.68054246,
    lng: -90.36154528,
  },
  {
    GEO_ID: '0500000US55109',
    STATE: '55',
    COUNTY: '109',
    NAME: 'St. Croix',
    LSAD: 'County',
    CENSUSAREA: 722.329,
    FIPS: 'WI109',
    fips: '55109',
    StateAbbr: 'WI',
    StateName: 'Wisconsin',
    lat: 45.03345626,
    lng: -92.4529513,
  },
  {
    GEO_ID: '0500000US55121',
    STATE: '55',
    COUNTY: '121',
    NAME: 'Trempealeau',
    LSAD: 'County',
    CENSUSAREA: 732.965,
    FIPS: 'WI121',
    fips: '55121',
    StateAbbr: 'WI',
    StateName: 'Wisconsin',
    lat: 44.30390193,
    lng: -91.35874906,
  },
  {
    GEO_ID: '0500000US55135',
    STATE: '55',
    COUNTY: '135',
    NAME: 'Waupaca',
    LSAD: 'County',
    CENSUSAREA: 747.713,
    FIPS: 'WI135',
    fips: '55135',
    StateAbbr: 'WI',
    StateName: 'Wisconsin',
    lat: 44.47070734,
    lng: -88.96532599,
  },
  {
    GEO_ID: '0500000US56005',
    STATE: '56',
    COUNTY: '005',
    NAME: 'Campbell',
    LSAD: 'County',
    CENSUSAREA: 4802.709,
    FIPS: 'WY005',
    fips: '56005',
    StateAbbr: 'WY',
    StateName: 'Wyoming',
    lat: 44.24806602,
    lng: -105.5484733,
  },
  {
    GEO_ID: '0500000US56015',
    STATE: '56',
    COUNTY: '015',
    NAME: 'Goshen',
    LSAD: 'County',
    CENSUSAREA: 2225.391,
    FIPS: 'WY015',
    fips: '56015',
    StateAbbr: 'WY',
    StateName: 'Wyoming',
    lat: 42.08772579,
    lng: -104.353338,
  },
  {
    GEO_ID: '0500000US56041',
    STATE: '56',
    COUNTY: '041',
    NAME: 'Uinta',
    LSAD: 'County',
    CENSUSAREA: 2081.264,
    FIPS: 'WY041',
    fips: '56041',
    StateAbbr: 'WY',
    StateName: 'Wyoming',
    lat: 41.28749663,
    lng: -110.5474049,
  },
  {
    GEO_ID: '0500000US01127',
    STATE: '01',
    COUNTY: '127',
    NAME: 'Walker',
    LSAD: 'County',
    CENSUSAREA: 791.192,
    FIPS: 'AL127',
    fips: '01127',
    StateAbbr: 'AL',
    StateName: 'Alabama',
    lat: 33.80316281,
    lng: -87.29727889,
  },
  {
    GEO_ID: '0500000US01133',
    STATE: '01',
    COUNTY: '133',
    NAME: 'Winston',
    LSAD: 'County',
    CENSUSAREA: 612.979,
    FIPS: 'AL133',
    fips: '01133',
    StateAbbr: 'AL',
    StateName: 'Alabama',
    lat: 34.14959585,
    lng: -87.37341176,
  },
  {
    GEO_ID: '0500000US02016',
    STATE: '02',
    COUNTY: '016',
    NAME: 'Aleutians West',
    LSAD: 'CA',
    CENSUSAREA: 4390.276,
    FIPS: 'AK016',
    fips: '02016',
    StateAbbr: 'AK',
    StateName: 'Alaska',
    lat: 52.79430361,
    lng: -110.2449123,
  },
  {
    GEO_ID: '0500000US02060',
    STATE: '02',
    COUNTY: '060',
    NAME: 'Bristol Bay',
    LSAD: 'Borough',
    CENSUSAREA: 503.843,
    FIPS: 'AK060',
    fips: '02060',
    StateAbbr: 'AK',
    StateName: 'Alaska',
    lat: 58.74220214,
    lng: -156.7026695,
  },
  {
    GEO_ID: '0500000US72017',
    STATE: '72',
    COUNTY: '017',
    NAME: 'Barceloneta',
    LSAD: 'Muno',
    CENSUSAREA: 18.693,
    FIPS: 'PR017',
    fips: '72017',
    StateAbbr: 'PR',
    StateName: 'Puerto Rico',
    lat: 18.44638293,
    lng: -66.55881898,
  },
  {
    GEO_ID: '0500000US72021',
    STATE: '72',
    COUNTY: '021',
    NAME: 'Bayamón',
    LSAD: 'Muno',
    CENSUSAREA: 44.324,
    FIPS: 'PR021',
    fips: '72021',
    StateAbbr: 'PR',
    StateName: 'Puerto Rico',
    lat: 18.34837659,
    lng: -66.16878808,
  },
  {
    GEO_ID: '0500000US72029',
    STATE: '72',
    COUNTY: '029',
    NAME: 'Canóvanas',
    LSAD: 'Muno',
    CENSUSAREA: 32.865,
    FIPS: 'PR029',
    fips: '72029',
    StateAbbr: 'PR',
    StateName: 'Puerto Rico',
    lat: 18.33030521,
    lng: -65.88710242,
  },
  {
    GEO_ID: '0500000US72039',
    STATE: '72',
    COUNTY: '039',
    NAME: 'Ciales',
    LSAD: 'Muno',
    CENSUSAREA: 66.529,
    FIPS: 'PR039',
    fips: '72039',
    StateAbbr: 'PR',
    StateName: 'Puerto Rico',
    lat: 18.29014773,
    lng: -66.51671207,
  },
  {
    GEO_ID: '0500000US72051',
    STATE: '72',
    COUNTY: '051',
    NAME: 'Dorado',
    LSAD: 'Muno',
    CENSUSAREA: 23.089,
    FIPS: 'PR051',
    fips: '72051',
    StateAbbr: 'PR',
    StateName: 'Puerto Rico',
    lat: 18.43643896,
    lng: -66.27788236,
  },
  {
    GEO_ID: '0500000US72063',
    STATE: '72',
    COUNTY: '063',
    NAME: 'Gurabo',
    LSAD: 'Muno',
    CENSUSAREA: 27.887,
    FIPS: 'PR063',
    fips: '72063',
    StateAbbr: 'PR',
    StateName: 'Puerto Rico',
    lat: 18.26586955,
    lng: -65.97856259,
  },
  {
    GEO_ID: '0500000US72077',
    STATE: '72',
    COUNTY: '077',
    NAME: 'Juncos',
    LSAD: 'Muno',
    CENSUSAREA: 26.489,
    FIPS: 'PR077',
    fips: '72077',
    StateAbbr: 'PR',
    StateName: 'Puerto Rico',
    lat: 18.22462653,
    lng: -65.90991264,
  },
  {
    GEO_ID: '0500000US72085',
    STATE: '72',
    COUNTY: '085',
    NAME: 'Las Piedras',
    LSAD: 'Muno',
    CENSUSAREA: 33.88,
    FIPS: 'PR085',
    fips: '72085',
    StateAbbr: 'PR',
    StateName: 'Puerto Rico',
    lat: 18.18839957,
    lng: -65.86959716,
  },
  {
    GEO_ID: '0500000US72099',
    STATE: '72',
    COUNTY: '099',
    NAME: 'Moca',
    LSAD: 'Muno',
    CENSUSAREA: 50.344,
    FIPS: 'PR099',
    fips: '72099',
    StateAbbr: 'PR',
    StateName: 'Puerto Rico',
    lat: 18.37783653,
    lng: -67.08090202,
  },
  {
    GEO_ID: '0500000US72111',
    STATE: '72',
    COUNTY: '111',
    NAME: 'Peñuelas',
    LSAD: 'Muno',
    CENSUSAREA: 44.617,
    FIPS: 'PR111',
    fips: '72111',
    StateAbbr: 'PR',
    StateName: 'Puerto Rico',
    lat: 18.05966556,
    lng: -66.7212463,
  },
  {
    GEO_ID: '0500000US72123',
    STATE: '72',
    COUNTY: '123',
    NAME: 'Salinas',
    LSAD: 'Muno',
    CENSUSAREA: 69.365,
    FIPS: 'PR123',
    fips: '72123',
    StateAbbr: 'PR',
    StateName: 'Puerto Rico',
    lat: 18.00693567,
    lng: -66.25486537,
  },
  {
    GEO_ID: '0500000US72139',
    STATE: '72',
    COUNTY: '139',
    NAME: 'Trujillo Alto',
    LSAD: 'Muno',
    CENSUSAREA: 20.764,
    FIPS: 'PR139',
    fips: '72139',
    StateAbbr: 'PR',
    StateName: 'Puerto Rico',
    lat: 18.33693881,
    lng: -65.9995301,
  },
  {
    GEO_ID: '0500000US20179',
    STATE: '20',
    COUNTY: '179',
    NAME: 'Sheridan',
    LSAD: 'County',
    CENSUSAREA: 895.957,
    FIPS: 'KS179',
    fips: '20179',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 39.35018161,
    lng: -100.4418259,
  },
  {
    GEO_ID: '0500000US20191',
    STATE: '20',
    COUNTY: '191',
    NAME: 'Sumner',
    LSAD: 'County',
    CENSUSAREA: 1181.941,
    FIPS: 'KS191',
    fips: '20191',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 37.2369258,
    lng: -97.476759,
  },
  {
    GEO_ID: '0500000US20209',
    STATE: '20',
    COUNTY: '209',
    NAME: 'Wyandotte',
    LSAD: 'County',
    CENSUSAREA: 151.6,
    FIPS: 'KS209',
    fips: '20209',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 39.11438143,
    lng: -94.76482929,
  },
  {
    GEO_ID: '0500000US21185',
    STATE: '21',
    COUNTY: '185',
    NAME: 'Oldham',
    LSAD: 'County',
    CENSUSAREA: 187.218,
    FIPS: 'KY185',
    fips: '21185',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 38.39974783,
    lng: -85.44866529,
  },
  {
    GEO_ID: '0500000US21197',
    STATE: '21',
    COUNTY: '197',
    NAME: 'Powell',
    LSAD: 'County',
    CENSUSAREA: 178.979,
    FIPS: 'KY197',
    fips: '21197',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 37.83036747,
    lng: -83.82298076,
  },
  {
    GEO_ID: '0500000US21203',
    STATE: '21',
    COUNTY: '203',
    NAME: 'Rockcastle',
    LSAD: 'County',
    CENSUSAREA: 316.545,
    FIPS: 'KY203',
    fips: '21203',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 37.36491193,
    lng: -84.31621513,
  },
  {
    GEO_ID: '0500000US21215',
    STATE: '21',
    COUNTY: '215',
    NAME: 'Spencer',
    LSAD: 'County',
    CENSUSAREA: 186.677,
    FIPS: 'KY215',
    fips: '21215',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 38.03276996,
    lng: -85.32910919,
  },
  {
    GEO_ID: '0500000US21227',
    STATE: '21',
    COUNTY: '227',
    NAME: 'Warren',
    LSAD: 'County',
    CENSUSAREA: 541.6,
    FIPS: 'KY227',
    fips: '21227',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 36.9932296,
    lng: -86.42358976,
  },
  {
    GEO_ID: '0500000US21235',
    STATE: '21',
    COUNTY: '235',
    NAME: 'Whitley',
    LSAD: 'County',
    CENSUSAREA: 437.83,
    FIPS: 'KY235',
    fips: '21235',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 36.75824851,
    lng: -84.14488393,
  },
  {
    GEO_ID: '0500000US22007',
    STATE: '22',
    COUNTY: '007',
    NAME: 'Assumption',
    LSAD: 'Parish',
    CENSUSAREA: 338.658,
    FIPS: 'LA007',
    fips: '22007',
    StateAbbr: 'LA',
    StateName: 'Louisiana',
    lat: 29.90081675,
    lng: -91.06263132,
  },
  {
    GEO_ID: '0500000US22021',
    STATE: '22',
    COUNTY: '021',
    NAME: 'Caldwell',
    LSAD: 'Parish',
    CENSUSAREA: 529.425,
    FIPS: 'LA021',
    fips: '22021',
    StateAbbr: 'LA',
    StateName: 'Louisiana',
    lat: 32.09226466,
    lng: -92.11667519,
  },
  {
    GEO_ID: '0500000US22025',
    STATE: '22',
    COUNTY: '025',
    NAME: 'Catahoula',
    LSAD: 'Parish',
    CENSUSAREA: 708.03,
    FIPS: 'LA025',
    fips: '22025',
    StateAbbr: 'LA',
    StateName: 'Louisiana',
    lat: 31.66623485,
    lng: -91.84692484,
  },
  {
    GEO_ID: '0500000US22047',
    STATE: '22',
    COUNTY: '047',
    NAME: 'Iberville',
    LSAD: 'Parish',
    CENSUSAREA: 618.627,
    FIPS: 'LA047',
    fips: '22047',
    StateAbbr: 'LA',
    StateName: 'Louisiana',
    lat: 30.25819586,
    lng: -91.3485086,
  },
  {
    GEO_ID: '0500000US22059',
    STATE: '22',
    COUNTY: '059',
    NAME: 'La Salle',
    LSAD: 'Parish',
    CENSUSAREA: 624.684,
    FIPS: 'LA059',
    fips: '22059',
    StateAbbr: 'LA',
    StateName: 'Louisiana',
    lat: 31.67707521,
    lng: -92.16043106,
  },
  {
    GEO_ID: '0500000US22067',
    STATE: '22',
    COUNTY: '067',
    NAME: 'Morehouse',
    LSAD: 'Parish',
    CENSUSAREA: 794.934,
    FIPS: 'LA067',
    fips: '22067',
    StateAbbr: 'LA',
    StateName: 'Louisiana',
    lat: 32.82009241,
    lng: -91.80211171,
  },
  {
    GEO_ID: '0500000US22083',
    STATE: '22',
    COUNTY: '083',
    NAME: 'Richland',
    LSAD: 'Parish',
    CENSUSAREA: 559.043,
    FIPS: 'LA083',
    fips: '22083',
    StateAbbr: 'LA',
    StateName: 'Louisiana',
    lat: 32.41758531,
    lng: -91.76336368,
  },
  {
    GEO_ID: '0500000US48101',
    STATE: '48',
    COUNTY: '101',
    NAME: 'Cottle',
    LSAD: 'County',
    CENSUSAREA: 900.563,
    FIPS: 'TX101',
    fips: '48101',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 34.07767347,
    lng: -100.2787087,
  },
  {
    GEO_ID: '0500000US48105',
    STATE: '48',
    COUNTY: '105',
    NAME: 'Crockett',
    LSAD: 'County',
    CENSUSAREA: 2807.327,
    FIPS: 'TX105',
    fips: '48105',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 30.72293884,
    lng: -101.4121116,
  },
  {
    GEO_ID: '0500000US48109',
    STATE: '48',
    COUNTY: '109',
    NAME: 'Culberson',
    LSAD: 'County',
    CENSUSAREA: 3812.798,
    FIPS: 'TX109',
    fips: '48109',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 31.44657839,
    lng: -104.5179143,
  },
  {
    GEO_ID: '0500000US48111',
    STATE: '48',
    COUNTY: '111',
    NAME: 'Dallam',
    LSAD: 'County',
    CENSUSAREA: 1503.259,
    FIPS: 'TX111',
    fips: '48111',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 36.27789187,
    lng: -102.6021107,
  },
  {
    GEO_ID: '0500000US48113',
    STATE: '48',
    COUNTY: '113',
    NAME: 'Dallas',
    LSAD: 'County',
    CENSUSAREA: 871.279,
    FIPS: 'TX113',
    fips: '48113',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 32.76665041,
    lng: -96.77788947,
  },
  {
    GEO_ID: '0500000US48121',
    STATE: '48',
    COUNTY: '121',
    NAME: 'Denton',
    LSAD: 'County',
    CENSUSAREA: 878.432,
    FIPS: 'TX121',
    fips: '48121',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 33.20528167,
    lng: -97.11685878,
  },
  {
    GEO_ID: '0500000US48125',
    STATE: '48',
    COUNTY: '125',
    NAME: 'Dickens',
    LSAD: 'County',
    CENSUSAREA: 901.724,
    FIPS: 'TX125',
    fips: '48125',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 33.61616822,
    lng: -100.7788855,
  },
  {
    GEO_ID: '0500000US48133',
    STATE: '48',
    COUNTY: '133',
    NAME: 'Eastland',
    LSAD: 'County',
    CENSUSAREA: 926.491,
    FIPS: 'TX133',
    fips: '48133',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 32.32743996,
    lng: -98.83243566,
  },
  {
    GEO_ID: '0500000US48139',
    STATE: '48',
    COUNTY: '139',
    NAME: 'Ellis',
    LSAD: 'County',
    CENSUSAREA: 935.487,
    FIPS: 'TX139',
    fips: '48139',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 32.34880007,
    lng: -96.79470819,
  },
  {
    GEO_ID: '0500000US48145',
    STATE: '48',
    COUNTY: '145',
    NAME: 'Falls',
    LSAD: 'County',
    CENSUSAREA: 765.483,
    FIPS: 'TX145',
    fips: '48145',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 31.25354356,
    lng: -96.93634455,
  },
  {
    GEO_ID: '0500000US48147',
    STATE: '48',
    COUNTY: '147',
    NAME: 'Fannin',
    LSAD: 'County',
    CENSUSAREA: 890.837,
    FIPS: 'TX147',
    fips: '48147',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 33.59387579,
    lng: -96.10669521,
  },
  {
    GEO_ID: '0500000US48151',
    STATE: '48',
    COUNTY: '151',
    NAME: 'Fisher',
    LSAD: 'County',
    CENSUSAREA: 898.941,
    FIPS: 'TX151',
    fips: '48151',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 32.74281316,
    lng: -100.4019302,
  },
  {
    GEO_ID: '0500000US48157',
    STATE: '48',
    COUNTY: '157',
    NAME: 'Fort Bend',
    LSAD: 'County',
    CENSUSAREA: 861.481,
    FIPS: 'TX157',
    fips: '48157',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 29.52747353,
    lng: -95.77093789,
  },
  {
    GEO_ID: '0500000US48181',
    STATE: '48',
    COUNTY: '181',
    NAME: 'Grayson',
    LSAD: 'County',
    CENSUSAREA: 932.804,
    FIPS: 'TX181',
    fips: '48181',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 33.62702927,
    lng: -96.67774905,
  },
  {
    GEO_ID: '0500000US48189',
    STATE: '48',
    COUNTY: '189',
    NAME: 'Hale',
    LSAD: 'County',
    CENSUSAREA: 1004.683,
    FIPS: 'TX189',
    fips: '48189',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 34.07044901,
    lng: -101.8268114,
  },
  {
    GEO_ID: '0500000US48193',
    STATE: '48',
    COUNTY: '193',
    NAME: 'Hamilton',
    LSAD: 'County',
    CENSUSAREA: 835.914,
    FIPS: 'TX193',
    fips: '48193',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 31.70470573,
    lng: -98.11083887,
  },
  {
    GEO_ID: '0500000US48345',
    STATE: '48',
    COUNTY: '345',
    NAME: 'Motley',
    LSAD: 'County',
    CENSUSAREA: 989.562,
    FIPS: 'TX345',
    fips: '48345',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 34.07408766,
    lng: -100.779722,
  },
  {
    GEO_ID: '0500000US48347',
    STATE: '48',
    COUNTY: '347',
    NAME: 'Nacogdoches',
    LSAD: 'County',
    CENSUSAREA: 946.537,
    FIPS: 'TX347',
    fips: '48347',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 31.6162358,
    lng: -94.61593169,
  },
  {
    GEO_ID: '0500000US48351',
    STATE: '48',
    COUNTY: '351',
    NAME: 'Newton',
    LSAD: 'County',
    CENSUSAREA: 933.677,
    FIPS: 'TX351',
    fips: '48351',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 30.78564527,
    lng: -93.74533244,
  },
  {
    GEO_ID: '0500000US51135',
    STATE: '51',
    COUNTY: '135',
    NAME: 'Nottoway',
    LSAD: 'County',
    CENSUSAREA: 314.392,
    FIPS: 'VA135',
    fips: '51135',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 37.14201365,
    lng: -78.05127364,
  },
  {
    GEO_ID: '0500000US51137',
    STATE: '51',
    COUNTY: '137',
    NAME: 'Orange',
    LSAD: 'County',
    CENSUSAREA: 340.783,
    FIPS: 'VA137',
    fips: '51137',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 38.24635826,
    lng: -78.01298345,
  },
  {
    GEO_ID: '0500000US51139',
    STATE: '51',
    COUNTY: '139',
    NAME: 'Page',
    LSAD: 'County',
    CENSUSAREA: 310.856,
    FIPS: 'VA139',
    fips: '51139',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 38.61983485,
    lng: -78.48428212,
  },
  {
    GEO_ID: '0500000US51143',
    STATE: '51',
    COUNTY: '143',
    NAME: 'Pittsylvania',
    LSAD: 'County',
    CENSUSAREA: 968.941,
    FIPS: 'VA143',
    fips: '51143',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 36.82128716,
    lng: -79.39718973,
  },
  {
    GEO_ID: '0500000US51153',
    STATE: '51',
    COUNTY: '153',
    NAME: 'Prince William',
    LSAD: 'County',
    CENSUSAREA: 336.402,
    FIPS: 'VA153',
    fips: '51153',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 38.70276099,
    lng: -77.48028729,
  },
  {
    GEO_ID: '0500000US51163',
    STATE: '51',
    COUNTY: '163',
    NAME: 'Rockbridge',
    LSAD: 'County',
    CENSUSAREA: 597.558,
    FIPS: 'VA163',
    fips: '51163',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 37.81505482,
    lng: -79.44774612,
  },
  {
    GEO_ID: '0500000US51169',
    STATE: '51',
    COUNTY: '169',
    NAME: 'Scott',
    LSAD: 'County',
    CENSUSAREA: 535.53,
    FIPS: 'VA169',
    fips: '51169',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 36.7143081,
    lng: -82.60311478,
  },
  {
    GEO_ID: '0500000US51171',
    STATE: '51',
    COUNTY: '171',
    NAME: 'Shenandoah',
    LSAD: 'County',
    CENSUSAREA: 508.776,
    FIPS: 'VA171',
    fips: '51171',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 38.85827071,
    lng: -78.57113544,
  },
  {
    GEO_ID: '0500000US51181',
    STATE: '51',
    COUNTY: '181',
    NAME: 'Surry',
    LSAD: 'County',
    CENSUSAREA: 278.949,
    FIPS: 'VA181',
    fips: '51181',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 37.10963661,
    lng: -76.90027517,
  },
  {
    GEO_ID: '0500000US51183',
    STATE: '51',
    COUNTY: '183',
    NAME: 'Sussex',
    LSAD: 'County',
    CENSUSAREA: 490.22,
    FIPS: 'VA183',
    fips: '51183',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 36.92136549,
    lng: -77.26234107,
  },
  {
    GEO_ID: '0500000US53033',
    STATE: '53',
    COUNTY: '033',
    NAME: 'King',
    LSAD: 'County',
    CENSUSAREA: 2115.566,
    FIPS: 'WA033',
    fips: '53033',
    StateAbbr: 'WA',
    StateName: 'Washington',
    lat: 47.49034831,
    lng: -121.8063614,
  },
  {
    GEO_ID: '0500000US55035',
    STATE: '55',
    COUNTY: '035',
    NAME: 'Eau Claire',
    LSAD: 'County',
    CENSUSAREA: 637.983,
    FIPS: 'WI035',
    fips: '55035',
    StateAbbr: 'WI',
    StateName: 'Wisconsin',
    lat: 44.726614,
    lng: -91.28609183,
  },
  {
    GEO_ID: '0500000US55037',
    STATE: '55',
    COUNTY: '037',
    NAME: 'Florence',
    LSAD: 'County',
    CENSUSAREA: 488.195,
    FIPS: 'WI037',
    fips: '55037',
    StateAbbr: 'WI',
    StateName: 'Wisconsin',
    lat: 45.8480979,
    lng: -88.39761751,
  },
  {
    GEO_ID: '0500000US55041',
    STATE: '55',
    COUNTY: '041',
    NAME: 'Forest',
    LSAD: 'County',
    CENSUSAREA: 1014.067,
    FIPS: 'WI041',
    fips: '55041',
    StateAbbr: 'WI',
    StateName: 'Wisconsin',
    lat: 45.66756253,
    lng: -88.7705944,
  },
  {
    GEO_ID: '0500000US55043',
    STATE: '55',
    COUNTY: '043',
    NAME: 'Grant',
    LSAD: 'County',
    CENSUSAREA: 1146.849,
    FIPS: 'WI043',
    fips: '55043',
    StateAbbr: 'WI',
    StateName: 'Wisconsin',
    lat: 42.86757481,
    lng: -90.70630534,
  },
  {
    GEO_ID: '0500000US55047',
    STATE: '55',
    COUNTY: '047',
    NAME: 'Green Lake',
    LSAD: 'County',
    CENSUSAREA: 349.437,
    FIPS: 'WI047',
    fips: '55047',
    StateAbbr: 'WI',
    StateName: 'Wisconsin',
    lat: 43.80037233,
    lng: -89.04505121,
  },
  {
    GEO_ID: '0500000US55049',
    STATE: '55',
    COUNTY: '049',
    NAME: 'Iowa',
    LSAD: 'County',
    CENSUSAREA: 762.58,
    FIPS: 'WI049',
    fips: '55049',
    StateAbbr: 'WI',
    StateName: 'Wisconsin',
    lat: 43.00020828,
    lng: -90.13523894,
  },
  {
    GEO_ID: '0500000US55055',
    STATE: '55',
    COUNTY: '055',
    NAME: 'Jefferson',
    LSAD: 'County',
    CENSUSAREA: 556.474,
    FIPS: 'WI055',
    fips: '55055',
    StateAbbr: 'WI',
    StateName: 'Wisconsin',
    lat: 43.02053192,
    lng: -88.77532763,
  },
  {
    GEO_ID: '0500000US55057',
    STATE: '55',
    COUNTY: '057',
    NAME: 'Juneau',
    LSAD: 'County',
    CENSUSAREA: 766.925,
    FIPS: 'WI057',
    fips: '55057',
    StateAbbr: 'WI',
    StateName: 'Wisconsin',
    lat: 43.92432055,
    lng: -90.11372082,
  },
  {
    GEO_ID: '0500000US55059',
    STATE: '55',
    COUNTY: '059',
    NAME: 'Kenosha',
    LSAD: 'County',
    CENSUSAREA: 271.99,
    FIPS: 'WI059',
    fips: '55059',
    StateAbbr: 'WI',
    StateName: 'Wisconsin',
    lat: 42.57659172,
    lng: -88.04209555,
  },
  {
    GEO_ID: '0500000US56027',
    STATE: '56',
    COUNTY: '027',
    NAME: 'Niobrara',
    LSAD: 'County',
    CENSUSAREA: 2626.037,
    FIPS: 'WY027',
    fips: '56027',
    StateAbbr: 'WY',
    StateName: 'Wyoming',
    lat: 43.05616954,
    lng: -104.4755961,
  },
  {
    GEO_ID: '0500000US56029',
    STATE: '56',
    COUNTY: '029',
    NAME: 'Park',
    LSAD: 'County',
    CENSUSAREA: 6942.078,
    FIPS: 'WY029',
    fips: '56029',
    StateAbbr: 'WY',
    StateName: 'Wyoming',
    lat: 44.52053528,
    lng: -109.5885953,
  },
  {
    GEO_ID: '0500000US56035',
    STATE: '56',
    COUNTY: '035',
    NAME: 'Sublette',
    LSAD: 'County',
    CENSUSAREA: 4886.537,
    FIPS: 'WY035',
    fips: '56035',
    StateAbbr: 'WY',
    StateName: 'Wyoming',
    lat: 42.76688453,
    lng: -109.9148412,
  },
  {
    GEO_ID: '0500000US56037',
    STATE: '56',
    COUNTY: '037',
    NAME: 'Sweetwater',
    LSAD: 'County',
    CENSUSAREA: 10426.649,
    FIPS: 'WY037',
    fips: '56037',
    StateAbbr: 'WY',
    StateName: 'Wyoming',
    lat: 41.6597625,
    lng: -108.8793882,
  },
  {
    GEO_ID: '0500000US56043',
    STATE: '56',
    COUNTY: '043',
    NAME: 'Washakie',
    LSAD: 'County',
    CENSUSAREA: 2238.549,
    FIPS: 'WY043',
    fips: '56043',
    StateAbbr: 'WY',
    StateName: 'Wyoming',
    lat: 43.90437841,
    lng: -107.6823145,
  },
  {
    GEO_ID: '0500000US72001',
    STATE: '72',
    COUNTY: '001',
    NAME: 'Adjuntas',
    LSAD: 'Muno',
    CENSUSAREA: 66.69,
    FIPS: 'PR001',
    fips: '72001',
    StateAbbr: 'PR',
    StateName: 'Puerto Rico',
    lat: 18.1796595,
    lng: -66.75351639,
  },
  {
    GEO_ID: '0500000US72007',
    STATE: '72',
    COUNTY: '007',
    NAME: 'Aguas Buenas',
    LSAD: 'Muno',
    CENSUSAREA: 30.085,
    FIPS: 'PR007',
    fips: '72007',
    StateAbbr: 'PR',
    StateName: 'Puerto Rico',
    lat: 18.25129795,
    lng: -66.12607813,
  },
  {
    GEO_ID: '0500000US72011',
    STATE: '72',
    COUNTY: '011',
    NAME: 'Añasco',
    LSAD: 'Muno',
    CENSUSAREA: 39.285,
    FIPS: 'PR011',
    fips: '72011',
    StateAbbr: 'PR',
    StateName: 'Puerto Rico',
    lat: 18.28872155,
    lng: -67.12171471,
  },
  {
    GEO_ID: '0500000US53043',
    STATE: '53',
    COUNTY: '043',
    NAME: 'Lincoln',
    LSAD: 'County',
    CENSUSAREA: 2310.494,
    FIPS: 'WA043',
    fips: '53043',
    StateAbbr: 'WA',
    StateName: 'Washington',
    lat: 47.57605403,
    lng: -118.4186556,
  },
  {
    GEO_ID: '0500000US53051',
    STATE: '53',
    COUNTY: '051',
    NAME: 'Pend Oreille',
    LSAD: 'County',
    CENSUSAREA: 1399.989,
    FIPS: 'WA051',
    fips: '53051',
    StateAbbr: 'WA',
    StateName: 'Washington',
    lat: 48.53235595,
    lng: -117.2738324,
  },
  {
    GEO_ID: '0500000US53053',
    STATE: '53',
    COUNTY: '053',
    NAME: 'Pierce',
    LSAD: 'County',
    CENSUSAREA: 1669.508,
    FIPS: 'WA053',
    fips: '53053',
    StateAbbr: 'WA',
    StateName: 'Washington',
    lat: 47.02680625,
    lng: -122.1122121,
  },
  {
    GEO_ID: '0500000US48197',
    STATE: '48',
    COUNTY: '197',
    NAME: 'Hardeman',
    LSAD: 'County',
    CENSUSAREA: 695.114,
    FIPS: 'TX197',
    fips: '48197',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 34.29029034,
    lng: -99.74572968,
  },
  {
    GEO_ID: '0500000US48199',
    STATE: '48',
    COUNTY: '199',
    NAME: 'Hardin',
    LSAD: 'County',
    CENSUSAREA: 890.567,
    FIPS: 'TX199',
    fips: '48199',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 30.33224472,
    lng: -94.3902703,
  },
  {
    GEO_ID: '0500000US48201',
    STATE: '48',
    COUNTY: '201',
    NAME: 'Harris',
    LSAD: 'County',
    CENSUSAREA: 1703.478,
    FIPS: 'TX201',
    fips: '48201',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 29.85748873,
    lng: -95.39302715,
  },
  {
    GEO_ID: '0500000US48203',
    STATE: '48',
    COUNTY: '203',
    NAME: 'Harrison',
    LSAD: 'County',
    CENSUSAREA: 899.951,
    FIPS: 'TX203',
    fips: '48203',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 32.54840734,
    lng: -94.37105127,
  },
  {
    GEO_ID: '0500000US48211',
    STATE: '48',
    COUNTY: '211',
    NAME: 'Hemphill',
    LSAD: 'County',
    CENSUSAREA: 906.286,
    FIPS: 'TX211',
    fips: '48211',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 35.83764858,
    lng: -100.2703577,
  },
  {
    GEO_ID: '0500000US48213',
    STATE: '48',
    COUNTY: '213',
    NAME: 'Henderson',
    LSAD: 'County',
    CENSUSAREA: 873.748,
    FIPS: 'TX213',
    fips: '48213',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 32.21224151,
    lng: -95.85377795,
  },
  {
    GEO_ID: '0500000US48215',
    STATE: '48',
    COUNTY: '215',
    NAME: 'Hidalgo',
    LSAD: 'County',
    CENSUSAREA: 1570.865,
    FIPS: 'TX215',
    fips: '48215',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 26.39667226,
    lng: -98.18144326,
  },
  {
    GEO_ID: '0500000US48223',
    STATE: '48',
    COUNTY: '223',
    NAME: 'Hopkins',
    LSAD: 'County',
    CENSUSAREA: 767.174,
    FIPS: 'TX223',
    fips: '48223',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 33.14945662,
    lng: -95.56432257,
  },
  {
    GEO_ID: '0500000US48235',
    STATE: '48',
    COUNTY: '235',
    NAME: 'Irion',
    LSAD: 'County',
    CENSUSAREA: 1051.563,
    FIPS: 'TX235',
    fips: '48235',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 31.30375559,
    lng: -100.982439,
  },
  {
    GEO_ID: '0500000US48237',
    STATE: '48',
    COUNTY: '237',
    NAME: 'Jack',
    LSAD: 'County',
    CENSUSAREA: 910.662,
    FIPS: 'TX237',
    fips: '48237',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 33.23377248,
    lng: -98.17283469,
  },
  {
    GEO_ID: '0500000US48239',
    STATE: '48',
    COUNTY: '239',
    NAME: 'Jackson',
    LSAD: 'County',
    CENSUSAREA: 829.435,
    FIPS: 'TX239',
    fips: '48239',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 28.95369067,
    lng: -96.5775562,
  },
  {
    GEO_ID: '0500000US48243',
    STATE: '48',
    COUNTY: '243',
    NAME: 'Jeff Davis',
    LSAD: 'County',
    CENSUSAREA: 2264.555,
    FIPS: 'TX243',
    fips: '48243',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 30.71489092,
    lng: -104.1401726,
  },
  {
    GEO_ID: '0500000US48249',
    STATE: '48',
    COUNTY: '249',
    NAME: 'Jim Wells',
    LSAD: 'County',
    CENSUSAREA: 864.966,
    FIPS: 'TX249',
    fips: '48249',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 27.73123346,
    lng: -98.08997801,
  },
  {
    GEO_ID: '0500000US48253',
    STATE: '48',
    COUNTY: '253',
    NAME: 'Jones',
    LSAD: 'County',
    CENSUSAREA: 928.554,
    FIPS: 'TX253',
    fips: '48253',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 32.7400927,
    lng: -99.87871324,
  },
  {
    GEO_ID: '0500000US48259',
    STATE: '48',
    COUNTY: '259',
    NAME: 'Kendall',
    LSAD: 'County',
    CENSUSAREA: 662.453,
    FIPS: 'TX259',
    fips: '48259',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 29.94473189,
    lng: -98.7114161,
  },
  {
    GEO_ID: '0500000US51185',
    STATE: '51',
    COUNTY: '185',
    NAME: 'Tazewell',
    LSAD: 'County',
    CENSUSAREA: 518.846,
    FIPS: 'VA185',
    fips: '51185',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 37.12490124,
    lng: -81.56046571,
  },
  {
    GEO_ID: '0500000US51191',
    STATE: '51',
    COUNTY: '191',
    NAME: 'Washington',
    LSAD: 'County',
    CENSUSAREA: 560.975,
    FIPS: 'VA191',
    fips: '51191',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 36.72455999,
    lng: -81.95959909,
  },
  {
    GEO_ID: '0500000US51195',
    STATE: '51',
    COUNTY: '195',
    NAME: 'Wise',
    LSAD: 'County',
    CENSUSAREA: 403.189,
    FIPS: 'VA195',
    fips: '51195',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 36.97556152,
    lng: -82.62118772,
  },
  {
    GEO_ID: '0500000US51520',
    STATE: '51',
    COUNTY: '520',
    NAME: 'Bristol',
    LSAD: 'city',
    CENSUSAREA: 13.013,
    FIPS: 'VA520',
    fips: '51520',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 36.61826378,
    lng: -82.1590697,
  },
  {
    GEO_ID: '0500000US51550',
    STATE: '51',
    COUNTY: '550',
    NAME: 'Chesapeake',
    LSAD: 'city',
    CENSUSAREA: 340.8,
    FIPS: 'VA550',
    fips: '51550',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 36.67787032,
    lng: -76.30244008,
  },
  {
    GEO_ID: '0500000US51580',
    STATE: '51',
    COUNTY: '580',
    NAME: 'Covington',
    LSAD: 'city',
    CENSUSAREA: 5.468,
    FIPS: 'VA580',
    fips: '51580',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 37.77830014,
    lng: -79.98671944,
  },
  {
    GEO_ID: '0500000US51610',
    STATE: '51',
    COUNTY: '610',
    NAME: 'Falls Church',
    LSAD: 'city',
    CENSUSAREA: 1.999,
    FIPS: 'VA610',
    fips: '51610',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 38.88441803,
    lng: -77.17519332,
  },
  {
    GEO_ID: '0500000US51640',
    STATE: '51',
    COUNTY: '640',
    NAME: 'Galax',
    LSAD: 'city',
    CENSUSAREA: 8.24,
    FIPS: 'VA640',
    fips: '51640',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 36.66735897,
    lng: -80.91646065,
  },
  {
    GEO_ID: '0500000US51678',
    STATE: '51',
    COUNTY: '678',
    NAME: 'Lexington',
    LSAD: 'city',
    CENSUSAREA: 2.497,
    FIPS: 'VA678',
    fips: '51678',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 37.78179822,
    lng: -79.44575305,
  },
  {
    GEO_ID: '0500000US51683',
    STATE: '51',
    COUNTY: '683',
    NAME: 'Manassas',
    LSAD: 'city',
    CENSUSAREA: 9.881,
    FIPS: 'VA683',
    fips: '51683',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 38.7478445,
    lng: -77.48515474,
  },
  {
    GEO_ID: '0500000US51685',
    STATE: '51',
    COUNTY: '685',
    NAME: 'Manassas Park',
    LSAD: 'city',
    CENSUSAREA: 2.534,
    FIPS: 'VA685',
    fips: '51685',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 38.7704423,
    lng: -77.44401094,
  },
  {
    GEO_ID: '0500000US51710',
    STATE: '51',
    COUNTY: '710',
    NAME: 'Norfolk',
    LSAD: 'city',
    CENSUSAREA: 54.12,
    FIPS: 'VA710',
    fips: '51710',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 36.89479704,
    lng: -76.25967731,
  },
  {
    GEO_ID: '0500000US51730',
    STATE: '51',
    COUNTY: '730',
    NAME: 'Petersburg',
    LSAD: 'city',
    CENSUSAREA: 22.932,
    FIPS: 'VA730',
    fips: '51730',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 37.20404153,
    lng: -77.3926205,
  },
  {
    GEO_ID: '0500000US51750',
    STATE: '51',
    COUNTY: '750',
    NAME: 'Radford',
    LSAD: 'city',
    CENSUSAREA: 9.872,
    FIPS: 'VA750',
    fips: '51750',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 37.12313924,
    lng: -80.55838396,
  },
  {
    GEO_ID: '0500000US51790',
    STATE: '51',
    COUNTY: '790',
    NAME: 'Staunton',
    LSAD: 'city',
    CENSUSAREA: 19.975,
    FIPS: 'VA790',
    fips: '51790',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 38.15909322,
    lng: -79.05960219,
  },
  {
    GEO_ID: '0500000US55065',
    STATE: '55',
    COUNTY: '065',
    NAME: 'Lafayette',
    LSAD: 'County',
    CENSUSAREA: 633.588,
    FIPS: 'WI065',
    fips: '55065',
    StateAbbr: 'WI',
    StateName: 'Wisconsin',
    lat: 42.66021077,
    lng: -90.13189524,
  },
  {
    GEO_ID: '0500000US55075',
    STATE: '55',
    COUNTY: '075',
    NAME: 'Marinette',
    LSAD: 'County',
    CENSUSAREA: 1399.347,
    FIPS: 'WI075',
    fips: '55075',
    StateAbbr: 'WI',
    StateName: 'Wisconsin',
    lat: 45.38289899,
    lng: -88.03335785,
  },
  {
    GEO_ID: '0500000US55077',
    STATE: '55',
    COUNTY: '077',
    NAME: 'Marquette',
    LSAD: 'County',
    CENSUSAREA: 455.602,
    FIPS: 'WI077',
    fips: '55077',
    StateAbbr: 'WI',
    StateName: 'Wisconsin',
    lat: 43.81971333,
    lng: -89.39896563,
  },
  {
    GEO_ID: '0500000US55083',
    STATE: '55',
    COUNTY: '083',
    NAME: 'Oconto',
    LSAD: 'County',
    CENSUSAREA: 997.988,
    FIPS: 'WI083',
    fips: '55083',
    StateAbbr: 'WI',
    StateName: 'Wisconsin',
    lat: 45.02616558,
    lng: -88.26895246,
  },
  {
    GEO_ID: '0500000US55085',
    STATE: '55',
    COUNTY: '085',
    NAME: 'Oneida',
    LSAD: 'County',
    CENSUSAREA: 1112.97,
    FIPS: 'WI085',
    fips: '55085',
    StateAbbr: 'WI',
    StateName: 'Wisconsin',
    lat: 45.70521979,
    lng: -89.52161421,
  },
  {
    GEO_ID: '0500000US55091',
    STATE: '55',
    COUNTY: '091',
    NAME: 'Pepin',
    LSAD: 'County',
    CENSUSAREA: 231.983,
    FIPS: 'WI091',
    fips: '55091',
    StateAbbr: 'WI',
    StateName: 'Wisconsin',
    lat: 44.58302681,
    lng: -92.00178921,
  },
  {
    GEO_ID: '0500000US55093',
    STATE: '55',
    COUNTY: '093',
    NAME: 'Pierce',
    LSAD: 'County',
    CENSUSAREA: 573.748,
    FIPS: 'WI093',
    fips: '55093',
    StateAbbr: 'WI',
    StateName: 'Wisconsin',
    lat: 44.71909741,
    lng: -92.42212486,
  },
  {
    GEO_ID: '0500000US55097',
    STATE: '55',
    COUNTY: '097',
    NAME: 'Portage',
    LSAD: 'County',
    CENSUSAREA: 800.679,
    FIPS: 'WI097',
    fips: '55097',
    StateAbbr: 'WI',
    StateName: 'Wisconsin',
    lat: 44.47561378,
    lng: -89.50154548,
  },
  {
    GEO_ID: '0500000US53063',
    STATE: '53',
    COUNTY: '063',
    NAME: 'Spokane',
    LSAD: 'County',
    CENSUSAREA: 1763.791,
    FIPS: 'WA063',
    fips: '53063',
    StateAbbr: 'WA',
    StateName: 'Washington',
    lat: 47.62077953,
    lng: -117.4039405,
  },
  {
    GEO_ID: '0500000US53067',
    STATE: '53',
    COUNTY: '067',
    NAME: 'Thurston',
    LSAD: 'County',
    CENSUSAREA: 721.961,
    FIPS: 'WA067',
    fips: '53067',
    StateAbbr: 'WA',
    StateName: 'Washington',
    lat: 46.92672896,
    lng: -122.8330654,
  },
  {
    GEO_ID: '0500000US53069',
    STATE: '53',
    COUNTY: '069',
    NAME: 'Wahkiakum',
    LSAD: 'County',
    CENSUSAREA: 263.375,
    FIPS: 'WA069',
    fips: '53069',
    StateAbbr: 'WA',
    StateName: 'Washington',
    lat: 46.292422,
    lng: -123.4247601,
  },
  {
    GEO_ID: '0500000US53077',
    STATE: '53',
    COUNTY: '077',
    NAME: 'Yakima',
    LSAD: 'County',
    CENSUSAREA: 4295.396,
    FIPS: 'WA077',
    fips: '53077',
    StateAbbr: 'WA',
    StateName: 'Washington',
    lat: 46.45687521,
    lng: -120.7387387,
  },
  {
    GEO_ID: '0500000US54003',
    STATE: '54',
    COUNTY: '003',
    NAME: 'Berkeley',
    LSAD: 'County',
    CENSUSAREA: 321.142,
    FIPS: 'WV003',
    fips: '54003',
    StateAbbr: 'WV',
    StateName: 'West Virginia',
    lat: 39.46408048,
    lng: -78.0274399,
  },
  {
    GEO_ID: '0500000US54005',
    STATE: '54',
    COUNTY: '005',
    NAME: 'Boone',
    LSAD: 'County',
    CENSUSAREA: 501.543,
    FIPS: 'WV005',
    fips: '54005',
    StateAbbr: 'WV',
    StateName: 'West Virginia',
    lat: 38.02264875,
    lng: -81.71114668,
  },
  {
    GEO_ID: '0500000US54009',
    STATE: '54',
    COUNTY: '009',
    NAME: 'Brooke',
    LSAD: 'County',
    CENSUSAREA: 89.205,
    FIPS: 'WV009',
    fips: '54009',
    StateAbbr: 'WV',
    StateName: 'West Virginia',
    lat: 40.27385743,
    lng: -80.57654665,
  },
  {
    GEO_ID: '0500000US54011',
    STATE: '54',
    COUNTY: '011',
    NAME: 'Cabell',
    LSAD: 'County',
    CENSUSAREA: 281.018,
    FIPS: 'WV011',
    fips: '54011',
    StateAbbr: 'WV',
    StateName: 'West Virginia',
    lat: 38.42049789,
    lng: -82.24144949,
  },
  {
    GEO_ID: '0500000US54015',
    STATE: '54',
    COUNTY: '015',
    NAME: 'Clay',
    LSAD: 'County',
    CENSUSAREA: 341.902,
    FIPS: 'WV015',
    fips: '54015',
    StateAbbr: 'WV',
    StateName: 'West Virginia',
    lat: 38.46270857,
    lng: -81.07502287,
  },
  {
    GEO_ID: '0500000US54019',
    STATE: '54',
    COUNTY: '019',
    NAME: 'Fayette',
    LSAD: 'County',
    CENSUSAREA: 661.55,
    FIPS: 'WV019',
    fips: '54019',
    StateAbbr: 'WV',
    StateName: 'West Virginia',
    lat: 38.02876546,
    lng: -81.08105939,
  },
  {
    GEO_ID: '0500000US12037',
    STATE: '12',
    COUNTY: '037',
    NAME: 'Franklin',
    LSAD: 'County',
    CENSUSAREA: 534.725,
    FIPS: 'FL037',
    fips: '12037',
    StateAbbr: 'FL',
    StateName: 'Florida',
    lat: 29.8613762,
    lng: -84.82018866,
  },
  {
    GEO_ID: '0500000US12057',
    STATE: '12',
    COUNTY: '057',
    NAME: 'Hillsborough',
    LSAD: 'County',
    CENSUSAREA: 1020.214,
    FIPS: 'FL057',
    fips: '12057',
    StateAbbr: 'FL',
    StateName: 'Florida',
    lat: 27.92948052,
    lng: -82.30867294,
  },
  {
    GEO_ID: '0500000US12065',
    STATE: '12',
    COUNTY: '065',
    NAME: 'Jefferson',
    LSAD: 'County',
    CENSUSAREA: 598.095,
    FIPS: 'FL065',
    fips: '12065',
    StateAbbr: 'FL',
    StateName: 'Florida',
    lat: 30.43779668,
    lng: -83.89500601,
  },
  {
    GEO_ID: '0500000US08093',
    STATE: '08',
    COUNTY: '093',
    NAME: 'Park',
    LSAD: 'County',
    CENSUSAREA: 2193.846,
    FIPS: 'CO093',
    fips: '08093',
    StateAbbr: 'CO',
    StateName: 'Colorado',
    lat: 39.11969049,
    lng: -105.7172304,
  },
  {
    GEO_ID: '0500000US08099',
    STATE: '08',
    COUNTY: '099',
    NAME: 'Prowers',
    LSAD: 'County',
    CENSUSAREA: 1638.395,
    FIPS: 'CO099',
    fips: '08099',
    StateAbbr: 'CO',
    StateName: 'Colorado',
    lat: 37.95519022,
    lng: -102.3932762,
  },
  {
    GEO_ID: '0500000US08107',
    STATE: '08',
    COUNTY: '107',
    NAME: 'Routt',
    LSAD: 'County',
    CENSUSAREA: 2362.026,
    FIPS: 'CO107',
    fips: '08107',
    StateAbbr: 'CO',
    StateName: 'Colorado',
    lat: 40.48549841,
    lng: -106.9915083,
  },
  {
    GEO_ID: '0500000US08113',
    STATE: '08',
    COUNTY: '113',
    NAME: 'San Miguel',
    LSAD: 'County',
    CENSUSAREA: 1286.611,
    FIPS: 'CO113',
    fips: '08113',
    StateAbbr: 'CO',
    StateName: 'Colorado',
    lat: 38.00399367,
    lng: -108.405806,
  },
  {
    GEO_ID: '0500000US08115',
    STATE: '08',
    COUNTY: '115',
    NAME: 'Sedgwick',
    LSAD: 'County',
    CENSUSAREA: 548.041,
    FIPS: 'CO115',
    fips: '08115',
    StateAbbr: 'CO',
    StateName: 'Colorado',
    lat: 40.87598057,
    lng: -102.3519638,
  },
  {
    GEO_ID: '0500000US05125',
    STATE: '05',
    COUNTY: '125',
    NAME: 'Saline',
    LSAD: 'County',
    CENSUSAREA: 723.604,
    FIPS: 'AR125',
    fips: '05125',
    StateAbbr: 'AR',
    StateName: 'Arkansas',
    lat: 34.64661779,
    lng: -92.67655329,
  },
  {
    GEO_ID: '0500000US05131',
    STATE: '05',
    COUNTY: '131',
    NAME: 'Sebastian',
    LSAD: 'County',
    CENSUSAREA: 531.911,
    FIPS: 'AR131',
    fips: '05131',
    StateAbbr: 'AR',
    StateName: 'Arkansas',
    lat: 35.19943145,
    lng: -94.27397371,
  },
  {
    GEO_ID: '0500000US05139',
    STATE: '05',
    COUNTY: '139',
    NAME: 'Union',
    LSAD: 'County',
    CENSUSAREA: 1039.214,
    FIPS: 'AR139',
    fips: '05139',
    StateAbbr: 'AR',
    StateName: 'Arkansas',
    lat: 33.17125929,
    lng: -92.59770646,
  },
  {
    GEO_ID: '0500000US02290',
    STATE: '02',
    COUNTY: '290',
    NAME: 'Yukon-Koyukuk',
    LSAD: 'CA',
    CENSUSAREA: 145504.789,
    FIPS: 'AK290',
    fips: '02290',
    StateAbbr: 'AK',
    StateName: 'Alaska',
    lat: 65.50922918,
    lng: -151.3897031,
  },
  {
    GEO_ID: '0500000US18171',
    STATE: '18',
    COUNTY: '171',
    NAME: 'Warren',
    LSAD: 'County',
    CENSUSAREA: 364.681,
    FIPS: 'IN171',
    fips: '18171',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 40.34704622,
    lng: -87.35336964,
  },
  {
    GEO_ID: '0500000US18177',
    STATE: '18',
    COUNTY: '177',
    NAME: 'Wayne',
    LSAD: 'County',
    CENSUSAREA: 401.74,
    FIPS: 'IN177',
    fips: '18177',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 39.86440062,
    lng: -85.00991888,
  },
  {
    GEO_ID: '0500000US19005',
    STATE: '19',
    COUNTY: '005',
    NAME: 'Allamakee',
    LSAD: 'County',
    CENSUSAREA: 639.082,
    FIPS: 'IA005',
    fips: '19005',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 43.28400613,
    lng: -91.37915589,
  },
  {
    GEO_ID: '0500000US17059',
    STATE: '17',
    COUNTY: '059',
    NAME: 'Gallatin',
    LSAD: 'County',
    CENSUSAREA: 323.071,
    FIPS: 'IL059',
    fips: '17059',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 37.76253711,
    lng: -88.23082016,
  },
  {
    GEO_ID: '0500000US17067',
    STATE: '17',
    COUNTY: '067',
    NAME: 'Hancock',
    LSAD: 'County',
    CENSUSAREA: 793.726,
    FIPS: 'IL067',
    fips: '17067',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 40.40370799,
    lng: -91.16472529,
  },
  {
    GEO_ID: '0500000US18061',
    STATE: '18',
    COUNTY: '061',
    NAME: 'Harrison',
    LSAD: 'County',
    CENSUSAREA: 484.516,
    FIPS: 'IN061',
    fips: '18061',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 38.19522174,
    lng: -86.11124563,
  },
  {
    GEO_ID: '0500000US18073',
    STATE: '18',
    COUNTY: '073',
    NAME: 'Jasper',
    LSAD: 'County',
    CENSUSAREA: 559.625,
    FIPS: 'IN073',
    fips: '18073',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 41.02296853,
    lng: -87.11611118,
  },
  {
    GEO_ID: '0500000US18081',
    STATE: '18',
    COUNTY: '081',
    NAME: 'Johnson',
    LSAD: 'County',
    CENSUSAREA: 320.427,
    FIPS: 'IN081',
    fips: '18081',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 39.4899568,
    lng: -86.10174496,
  },
  {
    GEO_ID: '0500000US18083',
    STATE: '18',
    COUNTY: '083',
    NAME: 'Knox',
    LSAD: 'County',
    CENSUSAREA: 516.031,
    FIPS: 'IN083',
    fips: '18083',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 38.68954718,
    lng: -87.41778158,
  },
  {
    GEO_ID: '0500000US18089',
    STATE: '18',
    COUNTY: '089',
    NAME: 'Lake',
    LSAD: 'County',
    CENSUSAREA: 498.961,
    FIPS: 'IN089',
    fips: '18089',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 41.41685156,
    lng: -87.38221633,
  },
  {
    GEO_ID: '0500000US18107',
    STATE: '18',
    COUNTY: '107',
    NAME: 'Montgomery',
    LSAD: 'County',
    CENSUSAREA: 504.613,
    FIPS: 'IN107',
    fips: '18107',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 40.04037231,
    lng: -86.89342256,
  },
  {
    GEO_ID: '0500000US20121',
    STATE: '20',
    COUNTY: '121',
    NAME: 'Miami',
    LSAD: 'County',
    CENSUSAREA: 575.661,
    FIPS: 'KS121',
    fips: '20121',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 38.56363954,
    lng: -94.83822279,
  },
  {
    GEO_ID: '0500000US20129',
    STATE: '20',
    COUNTY: '129',
    NAME: 'Morton',
    LSAD: 'County',
    CENSUSAREA: 729.731,
    FIPS: 'KS129',
    fips: '20129',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 37.19140392,
    lng: -101.7992367,
  },
  {
    GEO_ID: '0500000US20137',
    STATE: '20',
    COUNTY: '137',
    NAME: 'Norton',
    LSAD: 'County',
    CENSUSAREA: 878.134,
    FIPS: 'KS137',
    fips: '20137',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 39.78448106,
    lng: -99.90284946,
  },
  {
    GEO_ID: '0500000US20147',
    STATE: '20',
    COUNTY: '147',
    NAME: 'Phillips',
    LSAD: 'County',
    CENSUSAREA: 885.877,
    FIPS: 'KS147',
    fips: '20147',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 39.78462799,
    lng: -99.34669372,
  },
  {
    GEO_ID: '0500000US20153',
    STATE: '20',
    COUNTY: '153',
    NAME: 'Rawlins',
    LSAD: 'County',
    CENSUSAREA: 1069.416,
    FIPS: 'KS153',
    fips: '20153',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 39.78524494,
    lng: -101.07618,
  },
  {
    GEO_ID: '0500000US20161',
    STATE: '20',
    COUNTY: '161',
    NAME: 'Riley',
    LSAD: 'County',
    CENSUSAREA: 609.771,
    FIPS: 'KS161',
    fips: '20161',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 39.29629774,
    lng: -96.7350396,
  },
  {
    GEO_ID: '0500000US20175',
    STATE: '20',
    COUNTY: '175',
    NAME: 'Seward',
    LSAD: 'County',
    CENSUSAREA: 639.497,
    FIPS: 'KS175',
    fips: '20175',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 37.19327656,
    lng: -100.850601,
  },
  {
    GEO_ID: '0500000US22065',
    STATE: '22',
    COUNTY: '065',
    NAME: 'Madison',
    LSAD: 'Parish',
    CENSUSAREA: 624.437,
    FIPS: 'LA065',
    fips: '22065',
    StateAbbr: 'LA',
    StateName: 'Louisiana',
    lat: 32.36512426,
    lng: -91.24181996,
  },
  {
    GEO_ID: '0500000US09001',
    STATE: '09',
    COUNTY: '001',
    NAME: 'Fairfield',
    LSAD: 'County',
    CENSUSAREA: 624.89,
    FIPS: 'CT001',
    fips: '09001',
    StateAbbr: 'CT',
    StateName: 'Connecticut',
    lat: 41.26973285,
    lng: -73.38919037,
  },
  {
    GEO_ID: '0500000US05021',
    STATE: '05',
    COUNTY: '021',
    NAME: 'Clay',
    LSAD: 'County',
    CENSUSAREA: 639.465,
    FIPS: 'AR021',
    fips: '05021',
    StateAbbr: 'AR',
    StateName: 'Arkansas',
    lat: 36.36822542,
    lng: -90.41718919,
  },
  {
    GEO_ID: '0500000US21119',
    STATE: '21',
    COUNTY: '119',
    NAME: 'Knott',
    LSAD: 'County',
    CENSUSAREA: 351.518,
    FIPS: 'KY119',
    fips: '21119',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 37.35406197,
    lng: -82.95432904,
  },
  {
    GEO_ID: '0500000US21127',
    STATE: '21',
    COUNTY: '127',
    NAME: 'Lawrence',
    LSAD: 'County',
    CENSUSAREA: 415.595,
    FIPS: 'KY127',
    fips: '21127',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 38.06766833,
    lng: -82.73464321,
  },
  {
    GEO_ID: '0500000US21133',
    STATE: '21',
    COUNTY: '133',
    NAME: 'Letcher',
    LSAD: 'County',
    CENSUSAREA: 337.914,
    FIPS: 'KY133',
    fips: '21133',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 37.12125569,
    lng: -82.85515105,
  },
  {
    GEO_ID: '0500000US21141',
    STATE: '21',
    COUNTY: '141',
    NAME: 'Logan',
    LSAD: 'County',
    CENSUSAREA: 552.131,
    FIPS: 'KY141',
    fips: '21141',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 36.8597188,
    lng: -86.87901935,
  },
  {
    GEO_ID: '0500000US21149',
    STATE: '21',
    COUNTY: '149',
    NAME: 'McLean',
    LSAD: 'County',
    CENSUSAREA: 252.474,
    FIPS: 'KY149',
    fips: '21149',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 37.52904105,
    lng: -87.26331417,
  },
  {
    GEO_ID: '0500000US21155',
    STATE: '21',
    COUNTY: '155',
    NAME: 'Marion',
    LSAD: 'County',
    CENSUSAREA: 343.008,
    FIPS: 'KY155',
    fips: '21155',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 37.55262162,
    lng: -85.26954479,
  },
  {
    GEO_ID: '0500000US04015',
    STATE: '04',
    COUNTY: '015',
    NAME: 'Mohave',
    LSAD: 'County',
    CENSUSAREA: 13311.084,
    FIPS: 'AZ015',
    fips: '04015',
    StateAbbr: 'AZ',
    StateName: 'Arizona',
    lat: 35.70456821,
    lng: -113.7581706,
  },
  {
    GEO_ID: '0500000US21163',
    STATE: '21',
    COUNTY: '163',
    NAME: 'Meade',
    LSAD: 'County',
    CENSUSAREA: 305.423,
    FIPS: 'KY163',
    fips: '21163',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 37.96966338,
    lng: -86.21698223,
  },
  {
    GEO_ID: '0500000US21177',
    STATE: '21',
    COUNTY: '177',
    NAME: 'Muhlenberg',
    LSAD: 'County',
    CENSUSAREA: 467.079,
    FIPS: 'KY177',
    fips: '21177',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 37.21592449,
    lng: -87.1426872,
  },
  {
    GEO_ID: '0500000US19051',
    STATE: '19',
    COUNTY: '051',
    NAME: 'Davis',
    LSAD: 'County',
    CENSUSAREA: 502.188,
    FIPS: 'IA051',
    fips: '19051',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 40.74775139,
    lng: -92.41006563,
  },
  {
    GEO_ID: '0500000US19065',
    STATE: '19',
    COUNTY: '065',
    NAME: 'Fayette',
    LSAD: 'County',
    CENSUSAREA: 730.811,
    FIPS: 'IA065',
    fips: '19065',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 42.8627134,
    lng: -91.84419994,
  },
  {
    GEO_ID: '0500000US19071',
    STATE: '19',
    COUNTY: '071',
    NAME: 'Fremont',
    LSAD: 'County',
    CENSUSAREA: 511.15,
    FIPS: 'IA071',
    fips: '19071',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 40.74556682,
    lng: -95.60475618,
  },
  {
    GEO_ID: '0500000US19083',
    STATE: '19',
    COUNTY: '083',
    NAME: 'Hardin',
    LSAD: 'County',
    CENSUSAREA: 569.307,
    FIPS: 'IA083',
    fips: '19083',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 42.38393029,
    lng: -93.24040527,
  },
  {
    GEO_ID: '0500000US19085',
    STATE: '19',
    COUNTY: '085',
    NAME: 'Harrison',
    LSAD: 'County',
    CENSUSAREA: 696.848,
    FIPS: 'IA085',
    fips: '19085',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 41.68336174,
    lng: -95.81703481,
  },
  {
    GEO_ID: '0500000US19097',
    STATE: '19',
    COUNTY: '097',
    NAME: 'Jackson',
    LSAD: 'County',
    CENSUSAREA: 636.039,
    FIPS: 'IA097',
    fips: '19097',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 42.17178419,
    lng: -90.57403934,
  },
  {
    GEO_ID: '0500000US19109',
    STATE: '19',
    COUNTY: '109',
    NAME: 'Kossuth',
    LSAD: 'County',
    CENSUSAREA: 972.72,
    FIPS: 'IA109',
    fips: '19109',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 43.20413984,
    lng: -94.20689787,
  },
  {
    GEO_ID: '0500000US18111',
    STATE: '18',
    COUNTY: '111',
    NAME: 'Newton',
    LSAD: 'County',
    CENSUSAREA: 401.759,
    FIPS: 'IN111',
    fips: '18111',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 40.95571121,
    lng: -87.39778904,
  },
  {
    GEO_ID: '0500000US19119',
    STATE: '19',
    COUNTY: '119',
    NAME: 'Lyon',
    LSAD: 'County',
    CENSUSAREA: 587.65,
    FIPS: 'IA119',
    fips: '19119',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 43.38111289,
    lng: -96.2102221,
  },
  {
    GEO_ID: '0500000US19133',
    STATE: '19',
    COUNTY: '133',
    NAME: 'Monona',
    LSAD: 'County',
    CENSUSAREA: 694.072,
    FIPS: 'IA133',
    fips: '19133',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 42.05236881,
    lng: -95.95997093,
  },
  {
    GEO_ID: '0500000US19143',
    STATE: '19',
    COUNTY: '143',
    NAME: 'Osceola',
    LSAD: 'County',
    CENSUSAREA: 398.684,
    FIPS: 'IA143',
    fips: '19143',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 43.37868127,
    lng: -95.62422758,
  },
  {
    GEO_ID: '0500000US19167',
    STATE: '19',
    COUNTY: '167',
    NAME: 'Sioux',
    LSAD: 'County',
    CENSUSAREA: 768.332,
    FIPS: 'IA167',
    fips: '19167',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 43.08294203,
    lng: -96.17809424,
  },
  {
    GEO_ID: '0500000US19173',
    STATE: '19',
    COUNTY: '173',
    NAME: 'Taylor',
    LSAD: 'County',
    CENSUSAREA: 531.902,
    FIPS: 'IA173',
    fips: '19173',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 40.73748072,
    lng: -94.69599224,
  },
  {
    GEO_ID: '0500000US19185',
    STATE: '19',
    COUNTY: '185',
    NAME: 'Wayne',
    LSAD: 'County',
    CENSUSAREA: 525.437,
    FIPS: 'IA185',
    fips: '19185',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 40.73965649,
    lng: -93.32744042,
  },
  {
    GEO_ID: '0500000US19191',
    STATE: '19',
    COUNTY: '191',
    NAME: 'Winneshiek',
    LSAD: 'County',
    CENSUSAREA: 689.868,
    FIPS: 'IA191',
    fips: '19191',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 43.29085957,
    lng: -91.84475831,
  },
  {
    GEO_ID: '0500000US20001',
    STATE: '20',
    COUNTY: '001',
    NAME: 'Allen',
    LSAD: 'County',
    CENSUSAREA: 500.303,
    FIPS: 'KS001',
    fips: '20001',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 37.88555459,
    lng: -95.30115478,
  },
  {
    GEO_ID: '0500000US20007',
    STATE: '20',
    COUNTY: '007',
    NAME: 'Barber',
    LSAD: 'County',
    CENSUSAREA: 1134.073,
    FIPS: 'KS007',
    fips: '20007',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 37.22893536,
    lng: -98.68457457,
  },
  {
    GEO_ID: '0500000US20011',
    STATE: '20',
    COUNTY: '011',
    NAME: 'Bourbon',
    LSAD: 'County',
    CENSUSAREA: 635.471,
    FIPS: 'KS011',
    fips: '20011',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 37.85500237,
    lng: -94.84924767,
  },
  {
    GEO_ID: '0500000US22071',
    STATE: '22',
    COUNTY: '071',
    NAME: 'Orleans',
    LSAD: 'Parish',
    CENSUSAREA: 169.423,
    FIPS: 'LA071',
    fips: '22071',
    StateAbbr: 'LA',
    StateName: 'Louisiana',
    lat: 30.06918821,
    lng: -89.92833674,
  },
  {
    GEO_ID: '0500000US27075',
    STATE: '27',
    COUNTY: '075',
    NAME: 'Lake',
    LSAD: 'County',
    CENSUSAREA: 2109.292,
    FIPS: 'MN075',
    fips: '27075',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 47.64037177,
    lng: -91.44445826,
  },
  {
    GEO_ID: '0500000US21161',
    STATE: '21',
    COUNTY: '161',
    NAME: 'Mason',
    LSAD: 'County',
    CENSUSAREA: 240.128,
    FIPS: 'KY161',
    fips: '21161',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 38.59510651,
    lng: -83.82396293,
  },
  {
    GEO_ID: '0500000US27123',
    STATE: '27',
    COUNTY: '123',
    NAME: 'Ramsey',
    LSAD: 'County',
    CENSUSAREA: 152.212,
    FIPS: 'MN123',
    fips: '27123',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 45.01747232,
    lng: -93.09987304,
  },
  {
    GEO_ID: '0500000US27135',
    STATE: '27',
    COUNTY: '135',
    NAME: 'Roseau',
    LSAD: 'County',
    CENSUSAREA: 1671.602,
    FIPS: 'MN135',
    fips: '27135',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 48.77485638,
    lng: -95.81112475,
  },
  {
    GEO_ID: '0500000US27151',
    STATE: '27',
    COUNTY: '151',
    NAME: 'Swift',
    LSAD: 'County',
    CENSUSAREA: 742.083,
    FIPS: 'MN151',
    fips: '27151',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 45.28288933,
    lng: -95.68142474,
  },
  {
    GEO_ID: '0500000US27155',
    STATE: '27',
    COUNTY: '155',
    NAME: 'Traverse',
    LSAD: 'County',
    CENSUSAREA: 573.902,
    FIPS: 'MN155',
    fips: '27155',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 45.77230328,
    lng: -96.47144652,
  },
  {
    GEO_ID: '0500000US27165',
    STATE: '27',
    COUNTY: '165',
    NAME: 'Watonwan',
    LSAD: 'County',
    CENSUSAREA: 434.947,
    FIPS: 'MN165',
    fips: '27165',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 43.97823903,
    lng: -94.61413846,
  },
  {
    GEO_ID: '0500000US27167',
    STATE: '27',
    COUNTY: '167',
    NAME: 'Wilkin',
    LSAD: 'County',
    CENSUSAREA: 750.957,
    FIPS: 'MN167',
    fips: '27167',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 46.35705832,
    lng: -96.46820682,
  },
  {
    GEO_ID: '0500000US27173',
    STATE: '27',
    COUNTY: '173',
    NAME: 'Yellow Medicine',
    LSAD: 'County',
    CENSUSAREA: 759.101,
    FIPS: 'MN173',
    fips: '27173',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 44.71626059,
    lng: -95.86888348,
  },
  {
    GEO_ID: '0500000US28001',
    STATE: '28',
    COUNTY: '001',
    NAME: 'Adams',
    LSAD: 'County',
    CENSUSAREA: 462.407,
    FIPS: 'MS001',
    fips: '28001',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 31.48172857,
    lng: -91.35355624,
  },
  {
    GEO_ID: '0500000US28019',
    STATE: '28',
    COUNTY: '019',
    NAME: 'Choctaw',
    LSAD: 'County',
    CENSUSAREA: 418.177,
    FIPS: 'MS019',
    fips: '28019',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 33.34764715,
    lng: -89.24846009,
  },
  {
    GEO_ID: '0500000US28025',
    STATE: '28',
    COUNTY: '025',
    NAME: 'Clay',
    LSAD: 'County',
    CENSUSAREA: 410.077,
    FIPS: 'MS025',
    fips: '28025',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 33.65579797,
    lng: -88.78154439,
  },
  {
    GEO_ID: '0500000US28027',
    STATE: '28',
    COUNTY: '027',
    NAME: 'Coahoma',
    LSAD: 'County',
    CENSUSAREA: 552.442,
    FIPS: 'MS027',
    fips: '28027',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 34.22906962,
    lng: -90.60273114,
  },
  {
    GEO_ID: '0500000US19117',
    STATE: '19',
    COUNTY: '117',
    NAME: 'Lucas',
    LSAD: 'County',
    CENSUSAREA: 430.593,
    FIPS: 'IA117',
    fips: '19117',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 41.02958964,
    lng: -93.32780803,
  },
  {
    GEO_ID: '0500000US37053',
    STATE: '37',
    COUNTY: '053',
    NAME: 'Currituck',
    LSAD: 'County',
    CENSUSAREA: 261.852,
    FIPS: 'NC053',
    fips: '37053',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 36.40311519,
    lng: -76.00390334,
  },
  {
    GEO_ID: '0500000US37079',
    STATE: '37',
    COUNTY: '079',
    NAME: 'Greene',
    LSAD: 'County',
    CENSUSAREA: 265.928,
    FIPS: 'NC079',
    fips: '37079',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 35.48563343,
    lng: -77.67650726,
  },
  {
    GEO_ID: '0500000US37089',
    STATE: '37',
    COUNTY: '089',
    NAME: 'Henderson',
    LSAD: 'County',
    CENSUSAREA: 373.068,
    FIPS: 'NC089',
    fips: '37089',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 35.33655768,
    lng: -82.48021492,
  },
  {
    GEO_ID: '0500000US42035',
    STATE: '42',
    COUNTY: '035',
    NAME: 'Clinton',
    LSAD: 'County',
    CENSUSAREA: 887.984,
    FIPS: 'PA035',
    fips: '42035',
    StateAbbr: 'PA',
    StateName: 'Pennsylvania',
    lat: 41.23402092,
    lng: -77.63798759,
  },
  {
    GEO_ID: '0500000US42041',
    STATE: '42',
    COUNTY: '041',
    NAME: 'Cumberland',
    LSAD: 'County',
    CENSUSAREA: 545.459,
    FIPS: 'PA041',
    fips: '42041',
    StateAbbr: 'PA',
    StateName: 'Pennsylvania',
    lat: 40.16350914,
    lng: -77.26523042,
  },
  {
    GEO_ID: '0500000US20021',
    STATE: '20',
    COUNTY: '021',
    NAME: 'Cherokee',
    LSAD: 'County',
    CENSUSAREA: 587.568,
    FIPS: 'KS021',
    fips: '20021',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 37.16921917,
    lng: -94.84624572,
  },
  {
    GEO_ID: '0500000US28055',
    STATE: '28',
    COUNTY: '055',
    NAME: 'Issaquena',
    LSAD: 'County',
    CENSUSAREA: 413.045,
    FIPS: 'MS055',
    fips: '28055',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 32.74139849,
    lng: -90.98899611,
  },
  {
    GEO_ID: '0500000US28063',
    STATE: '28',
    COUNTY: '063',
    NAME: 'Jefferson',
    LSAD: 'County',
    CENSUSAREA: 519.933,
    FIPS: 'MS063',
    fips: '28063',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 31.73403632,
    lng: -91.03631739,
  },
  {
    GEO_ID: '0500000US28071',
    STATE: '28',
    COUNTY: '071',
    NAME: 'Lafayette',
    LSAD: 'County',
    CENSUSAREA: 631.708,
    FIPS: 'MS071',
    fips: '28071',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 34.35673647,
    lng: -89.48471446,
  },
  {
    GEO_ID: '0500000US23001',
    STATE: '23',
    COUNTY: '001',
    NAME: 'Androscoggin',
    LSAD: 'County',
    CENSUSAREA: 467.927,
    FIPS: 'ME001',
    fips: '23001',
    StateAbbr: 'ME',
    StateName: 'Maine',
    lat: 44.16543634,
    lng: -70.20524042,
  },
  {
    GEO_ID: '0500000US23003',
    STATE: '23',
    COUNTY: '003',
    NAME: 'Aroostook',
    LSAD: 'County',
    CENSUSAREA: 6671.33,
    FIPS: 'ME003',
    fips: '23003',
    StateAbbr: 'ME',
    StateName: 'Maine',
    lat: 46.65913528,
    lng: -68.5987449,
  },
  {
    GEO_ID: '0500000US22087',
    STATE: '22',
    COUNTY: '087',
    NAME: 'St. Bernard',
    LSAD: 'Parish',
    CENSUSAREA: 377.517,
    FIPS: 'LA087',
    fips: '22087',
    StateAbbr: 'LA',
    StateName: 'Louisiana',
    lat: 29.8750922,
    lng: -89.53768188,
  },
  {
    GEO_ID: '0500000US38015',
    STATE: '38',
    COUNTY: '015',
    NAME: 'Burleigh',
    LSAD: 'County',
    CENSUSAREA: 1632.653,
    FIPS: 'ND015',
    fips: '38015',
    StateAbbr: 'ND',
    StateName: 'North Dakota',
    lat: 46.97733689,
    lng: -100.4687948,
  },
  {
    GEO_ID: '0500000US38021',
    STATE: '38',
    COUNTY: '021',
    NAME: 'Dickey',
    LSAD: 'County',
    CENSUSAREA: 1131.47,
    FIPS: 'ND021',
    fips: '38021',
    StateAbbr: 'ND',
    StateName: 'North Dakota',
    lat: 46.11017237,
    lng: -98.50488391,
  },
  {
    GEO_ID: '0500000US38023',
    STATE: '38',
    COUNTY: '023',
    NAME: 'Divide',
    LSAD: 'County',
    CENSUSAREA: 1260.786,
    FIPS: 'ND023',
    fips: '38023',
    StateAbbr: 'ND',
    StateName: 'North Dakota',
    lat: 48.81486205,
    lng: -103.4871163,
  },
  {
    GEO_ID: '0500000US38033',
    STATE: '38',
    COUNTY: '033',
    NAME: 'Golden Valley',
    LSAD: 'County',
    CENSUSAREA: 1000.786,
    FIPS: 'ND033',
    fips: '38033',
    StateAbbr: 'ND',
    StateName: 'North Dakota',
    lat: 46.94026181,
    lng: -103.8465777,
  },
  {
    GEO_ID: '0500000US38049',
    STATE: '38',
    COUNTY: '049',
    NAME: 'McHenry',
    LSAD: 'County',
    CENSUSAREA: 1873.954,
    FIPS: 'ND049',
    fips: '38049',
    StateAbbr: 'ND',
    StateName: 'North Dakota',
    lat: 48.23439818,
    lng: -100.6362783,
  },
  {
    GEO_ID: '0500000US28047',
    STATE: '28',
    COUNTY: '047',
    NAME: 'Harrison',
    LSAD: 'County',
    CENSUSAREA: 573.994,
    FIPS: 'MS047',
    fips: '28047',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 30.50439082,
    lng: -89.11140872,
  },
  {
    GEO_ID: '0500000US28105',
    STATE: '28',
    COUNTY: '105',
    NAME: 'Oktibbeha',
    LSAD: 'County',
    CENSUSAREA: 458.201,
    FIPS: 'MS105',
    fips: '28105',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 33.42493738,
    lng: -88.8794268,
  },
  {
    GEO_ID: '0500000US28111',
    STATE: '28',
    COUNTY: '111',
    NAME: 'Perry',
    LSAD: 'County',
    CENSUSAREA: 647.252,
    FIPS: 'MS111',
    fips: '28111',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 31.17209382,
    lng: -88.9925273,
  },
  {
    GEO_ID: '0500000US28113',
    STATE: '28',
    COUNTY: '113',
    NAME: 'Pike',
    LSAD: 'County',
    CENSUSAREA: 409.015,
    FIPS: 'MS113',
    fips: '28113',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 31.17495895,
    lng: -90.40396978,
  },
  {
    GEO_ID: '0500000US28125',
    STATE: '28',
    COUNTY: '125',
    NAME: 'Sharkey',
    LSAD: 'County',
    CENSUSAREA: 431.72,
    FIPS: 'MS125',
    fips: '28125',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 32.88000015,
    lng: -90.81343618,
  },
  {
    GEO_ID: '0500000US28131',
    STATE: '28',
    COUNTY: '131',
    NAME: 'Stone',
    LSAD: 'County',
    CENSUSAREA: 445.485,
    FIPS: 'MS131',
    fips: '28131',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 30.7900413,
    lng: -89.11764244,
  },
  {
    GEO_ID: '0500000US35047',
    STATE: '35',
    COUNTY: '047',
    NAME: 'San Miguel',
    LSAD: 'County',
    CENSUSAREA: 4715.821,
    FIPS: 'NM047',
    fips: '35047',
    StateAbbr: 'NM',
    StateName: 'New Mexico',
    lat: 35.48004639,
    lng: -104.8156292,
  },
  {
    GEO_ID: '0500000US35051',
    STATE: '35',
    COUNTY: '051',
    NAME: 'Sierra',
    LSAD: 'County',
    CENSUSAREA: 4178.956,
    FIPS: 'NM051',
    fips: '35051',
    StateAbbr: 'NM',
    StateName: 'New Mexico',
    lat: 33.13025321,
    lng: -107.1925539,
  },
  {
    GEO_ID: '0500000US35055',
    STATE: '35',
    COUNTY: '055',
    NAME: 'Taos',
    LSAD: 'County',
    CENSUSAREA: 2203.11,
    FIPS: 'NM055',
    fips: '35055',
    StateAbbr: 'NM',
    StateName: 'New Mexico',
    lat: 36.57834262,
    lng: -105.630976,
  },
  {
    GEO_ID: '0500000US36001',
    STATE: '36',
    COUNTY: '001',
    NAME: 'Albany',
    LSAD: 'County',
    CENSUSAREA: 522.804,
    FIPS: 'NY001',
    fips: '36001',
    StateAbbr: 'NY',
    StateName: 'New York',
    lat: 42.59987237,
    lng: -73.97341306,
  },
  {
    GEO_ID: '0500000US36005',
    STATE: '36',
    COUNTY: '005',
    NAME: 'Bronx',
    LSAD: 'County',
    CENSUSAREA: 42.096,
    FIPS: 'NY005',
    fips: '36005',
    StateAbbr: 'NY',
    StateName: 'New York',
    lat: 40.85091824,
    lng: -73.8615688,
  },
  {
    GEO_ID: '0500000US42043',
    STATE: '42',
    COUNTY: '043',
    NAME: 'Dauphin',
    LSAD: 'County',
    CENSUSAREA: 525.047,
    FIPS: 'PA043',
    fips: '42043',
    StateAbbr: 'PA',
    StateName: 'Pennsylvania',
    lat: 40.41555232,
    lng: -76.77925448,
  },
  {
    GEO_ID: '0500000US42059',
    STATE: '42',
    COUNTY: '059',
    NAME: 'Greene',
    LSAD: 'County',
    CENSUSAREA: 575.949,
    FIPS: 'PA059',
    fips: '42059',
    StateAbbr: 'PA',
    StateName: 'Pennsylvania',
    lat: 39.85389048,
    lng: -80.22298108,
  },
  {
    GEO_ID: '0500000US42065',
    STATE: '42',
    COUNTY: '065',
    NAME: 'Jefferson',
    LSAD: 'County',
    CENSUSAREA: 652.429,
    FIPS: 'PA065',
    fips: '42065',
    StateAbbr: 'PA',
    StateName: 'Pennsylvania',
    lat: 41.12829657,
    lng: -78.99967226,
  },
  {
    GEO_ID: '0500000US42071',
    STATE: '42',
    COUNTY: '071',
    NAME: 'Lancaster',
    LSAD: 'County',
    CENSUSAREA: 943.81,
    FIPS: 'PA071',
    fips: '42071',
    StateAbbr: 'PA',
    StateName: 'Pennsylvania',
    lat: 40.04261494,
    lng: -76.24786695,
  },
  {
    GEO_ID: '0500000US42077',
    STATE: '42',
    COUNTY: '077',
    NAME: 'Lehigh',
    LSAD: 'County',
    CENSUSAREA: 345.166,
    FIPS: 'PA077',
    fips: '42077',
    StateAbbr: 'PA',
    StateName: 'Pennsylvania',
    lat: 40.61280627,
    lng: -75.59214444,
  },
  {
    GEO_ID: '0500000US42083',
    STATE: '42',
    COUNTY: '083',
    NAME: 'McKean',
    LSAD: 'County',
    CENSUSAREA: 979.197,
    FIPS: 'PA083',
    fips: '42083',
    StateAbbr: 'PA',
    StateName: 'Pennsylvania',
    lat: 41.80786813,
    lng: -78.56888029,
  },
  {
    GEO_ID: '0500000US41035',
    STATE: '41',
    COUNTY: '035',
    NAME: 'Klamath',
    LSAD: 'County',
    CENSUSAREA: 5941.052,
    FIPS: 'OR035',
    fips: '41035',
    StateAbbr: 'OR',
    StateName: 'Oregon',
    lat: 42.68605026,
    lng: -121.6500072,
  },
  {
    GEO_ID: '0500000US23005',
    STATE: '23',
    COUNTY: '005',
    NAME: 'Cumberland',
    LSAD: 'County',
    CENSUSAREA: 835.241,
    FIPS: 'ME005',
    fips: '23005',
    StateAbbr: 'ME',
    StateName: 'Maine',
    lat: 43.83897334,
    lng: -70.37661287,
  },
  {
    GEO_ID: '0500000US45029',
    STATE: '45',
    COUNTY: '029',
    NAME: 'Colleton',
    LSAD: 'County',
    CENSUSAREA: 1056.491,
    FIPS: 'SC029',
    fips: '45029',
    StateAbbr: 'SC',
    StateName: 'South Carolina',
    lat: 32.86353032,
    lng: -80.66691164,
  },
  {
    GEO_ID: '0500000US45041',
    STATE: '45',
    COUNTY: '041',
    NAME: 'Florence',
    LSAD: 'County',
    CENSUSAREA: 799.964,
    FIPS: 'SC041',
    fips: '45041',
    StateAbbr: 'SC',
    StateName: 'South Carolina',
    lat: 34.02447241,
    lng: -79.70224581,
  },
  {
    GEO_ID: '0500000US45043',
    STATE: '45',
    COUNTY: '043',
    NAME: 'Georgetown',
    LSAD: 'County',
    CENSUSAREA: 813.547,
    FIPS: 'SC043',
    fips: '45043',
    StateAbbr: 'SC',
    StateName: 'South Carolina',
    lat: 33.43337482,
    lng: -79.33242281,
  },
  {
    GEO_ID: '0500000US47023',
    STATE: '47',
    COUNTY: '023',
    NAME: 'Chester',
    LSAD: 'County',
    CENSUSAREA: 285.736,
    FIPS: 'TN023',
    fips: '47023',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 35.42206909,
    lng: -88.61425832,
  },
  {
    GEO_ID: '0500000US38051',
    STATE: '38',
    COUNTY: '051',
    NAME: 'McIntosh',
    LSAD: 'County',
    CENSUSAREA: 974.733,
    FIPS: 'ND051',
    fips: '38051',
    StateAbbr: 'ND',
    StateName: 'North Dakota',
    lat: 46.11180534,
    lng: -99.4414601,
  },
  {
    GEO_ID: '0500000US38057',
    STATE: '38',
    COUNTY: '057',
    NAME: 'Mercer',
    LSAD: 'County',
    CENSUSAREA: 1042.957,
    FIPS: 'ND057',
    fips: '38057',
    StateAbbr: 'ND',
    StateName: 'North Dakota',
    lat: 47.30905996,
    lng: -101.8317306,
  },
  {
    GEO_ID: '0500000US40067',
    STATE: '40',
    COUNTY: '067',
    NAME: 'Jefferson',
    LSAD: 'County',
    CENSUSAREA: 758.832,
    FIPS: 'OK067',
    fips: '40067',
    StateAbbr: 'OK',
    StateName: 'Oklahoma',
    lat: 34.11052408,
    lng: -97.83608709,
  },
  {
    GEO_ID: '0500000US40077',
    STATE: '40',
    COUNTY: '077',
    NAME: 'Latimer',
    LSAD: 'County',
    CENSUSAREA: 722.08,
    FIPS: 'OK077',
    fips: '40077',
    StateAbbr: 'OK',
    StateName: 'Oklahoma',
    lat: 34.87639948,
    lng: -95.25098995,
  },
  {
    GEO_ID: '0500000US40079',
    STATE: '40',
    COUNTY: '079',
    NAME: 'Le Flore',
    LSAD: 'County',
    CENSUSAREA: 1589.214,
    FIPS: 'OK079',
    fips: '40079',
    StateAbbr: 'OK',
    StateName: 'Oklahoma',
    lat: 34.90011477,
    lng: -94.70338293,
  },
  {
    GEO_ID: '0500000US40089',
    STATE: '40',
    COUNTY: '089',
    NAME: 'McCurtain',
    LSAD: 'County',
    CENSUSAREA: 1850.005,
    FIPS: 'OK089',
    fips: '40089',
    StateAbbr: 'OK',
    StateName: 'Oklahoma',
    lat: 34.1162239,
    lng: -94.77145458,
  },
  {
    GEO_ID: '0500000US40095',
    STATE: '40',
    COUNTY: '095',
    NAME: 'Marshall',
    LSAD: 'County',
    CENSUSAREA: 371.08,
    FIPS: 'OK095',
    fips: '40095',
    StateAbbr: 'OK',
    StateName: 'Oklahoma',
    lat: 34.02458841,
    lng: -96.76917298,
  },
  {
    GEO_ID: '0500000US40105',
    STATE: '40',
    COUNTY: '105',
    NAME: 'Nowata',
    LSAD: 'County',
    CENSUSAREA: 565.777,
    FIPS: 'OK105',
    fips: '40105',
    StateAbbr: 'OK',
    StateName: 'Oklahoma',
    lat: 36.79844119,
    lng: -95.61735986,
  },
  {
    GEO_ID: '0500000US40113',
    STATE: '40',
    COUNTY: '113',
    NAME: 'Osage',
    LSAD: 'County',
    CENSUSAREA: 2246.358,
    FIPS: 'OK113',
    fips: '40113',
    StateAbbr: 'OK',
    StateName: 'Oklahoma',
    lat: 36.62910128,
    lng: -96.3983805,
  },
  {
    GEO_ID: '0500000US36015',
    STATE: '36',
    COUNTY: '015',
    NAME: 'Chemung',
    LSAD: 'County',
    CENSUSAREA: 407.352,
    FIPS: 'NY015',
    fips: '36015',
    StateAbbr: 'NY',
    StateName: 'New York',
    lat: 42.14122122,
    lng: -76.75989461,
  },
  {
    GEO_ID: '0500000US36023',
    STATE: '36',
    COUNTY: '023',
    NAME: 'Cortland',
    LSAD: 'County',
    CENSUSAREA: 498.76,
    FIPS: 'NY023',
    fips: '36023',
    StateAbbr: 'NY',
    StateName: 'New York',
    lat: 42.59514578,
    lng: -76.07049311,
  },
  {
    GEO_ID: '0500000US36025',
    STATE: '36',
    COUNTY: '025',
    NAME: 'Delaware',
    LSAD: 'County',
    CENSUSAREA: 1442.44,
    FIPS: 'NY025',
    fips: '36025',
    StateAbbr: 'NY',
    StateName: 'New York',
    lat: 42.19791446,
    lng: -74.96687059,
  },
  {
    GEO_ID: '0500000US36029',
    STATE: '36',
    COUNTY: '029',
    NAME: 'Erie',
    LSAD: 'County',
    CENSUSAREA: 1042.693,
    FIPS: 'NY029',
    fips: '36029',
    StateAbbr: 'NY',
    StateName: 'New York',
    lat: 42.76409998,
    lng: -78.73230753,
  },
  {
    GEO_ID: '0500000US40139',
    STATE: '40',
    COUNTY: '139',
    NAME: 'Texas',
    LSAD: 'County',
    CENSUSAREA: 2041.261,
    FIPS: 'OK139',
    fips: '40139',
    StateAbbr: 'OK',
    StateName: 'Oklahoma',
    lat: 36.74789073,
    lng: -101.4901113,
  },
  {
    GEO_ID: '0500000US40151',
    STATE: '40',
    COUNTY: '151',
    NAME: 'Woods',
    LSAD: 'County',
    CENSUSAREA: 1286.454,
    FIPS: 'OK151',
    fips: '40151',
    StateAbbr: 'OK',
    StateName: 'Oklahoma',
    lat: 36.76674112,
    lng: -98.86351819,
  },
  {
    GEO_ID: '0500000US41001',
    STATE: '41',
    COUNTY: '001',
    NAME: 'Baker',
    LSAD: 'County',
    CENSUSAREA: 3068.357,
    FIPS: 'OR001',
    fips: '41001',
    StateAbbr: 'OR',
    StateName: 'Oregon',
    lat: 44.70919815,
    lng: -117.6755473,
  },
  {
    GEO_ID: '0500000US41007',
    STATE: '41',
    COUNTY: '007',
    NAME: 'Clatsop',
    LSAD: 'County',
    CENSUSAREA: 829.049,
    FIPS: 'OR007',
    fips: '41007',
    StateAbbr: 'OR',
    StateName: 'Oregon',
    lat: 45.99500314,
    lng: -123.6560294,
  },
  {
    GEO_ID: '0500000US41041',
    STATE: '41',
    COUNTY: '041',
    NAME: 'Lincoln',
    LSAD: 'County',
    CENSUSAREA: 979.765,
    FIPS: 'OR041',
    fips: '41041',
    StateAbbr: 'OR',
    StateName: 'Oregon',
    lat: 44.64147054,
    lng: -123.8682744,
  },
  {
    GEO_ID: '0500000US31139',
    STATE: '31',
    COUNTY: '139',
    NAME: 'Pierce',
    LSAD: 'County',
    CENSUSAREA: 573.249,
    FIPS: 'NE139',
    fips: '31139',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 42.26434853,
    lng: -97.60122773,
  },
  {
    GEO_ID: '0500000US31145',
    STATE: '31',
    COUNTY: '145',
    NAME: 'Red Willow',
    LSAD: 'County',
    CENSUSAREA: 716.985,
    FIPS: 'NE145',
    fips: '31145',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 40.17604429,
    lng: -100.477247,
  },
  {
    GEO_ID: '0500000US19029',
    STATE: '19',
    COUNTY: '029',
    NAME: 'Cass',
    LSAD: 'County',
    CENSUSAREA: 564.269,
    FIPS: 'IA029',
    fips: '19029',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 41.33152905,
    lng: -94.92806328,
  },
  {
    GEO_ID: '0500000US19037',
    STATE: '19',
    COUNTY: '037',
    NAME: 'Chickasaw',
    LSAD: 'County',
    CENSUSAREA: 504.38,
    FIPS: 'IA037',
    fips: '19037',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 43.06009652,
    lng: -92.31766909,
  },
  {
    GEO_ID: '0500000US17083',
    STATE: '17',
    COUNTY: '083',
    NAME: 'Jersey',
    LSAD: 'County',
    CENSUSAREA: 369.271,
    FIPS: 'IL083',
    fips: '17083',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 39.0855748,
    lng: -90.3567923,
  },
  {
    GEO_ID: '0500000US17085',
    STATE: '17',
    COUNTY: '085',
    NAME: 'Jo Daviess',
    LSAD: 'County',
    CENSUSAREA: 601.087,
    FIPS: 'IL085',
    fips: '17085',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 42.36542788,
    lng: -90.21246462,
  },
  {
    GEO_ID: '0500000US17097',
    STATE: '17',
    COUNTY: '097',
    NAME: 'Lake',
    LSAD: 'County',
    CENSUSAREA: 443.67,
    FIPS: 'IL097',
    fips: '17097',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 42.32318039,
    lng: -88.00373142,
  },
  {
    GEO_ID: '0500000US17115',
    STATE: '17',
    COUNTY: '115',
    NAME: 'Macon',
    LSAD: 'County',
    CENSUSAREA: 580.689,
    FIPS: 'IL115',
    fips: '17115',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 39.85999062,
    lng: -88.96174804,
  },
  {
    GEO_ID: '0500000US17119',
    STATE: '17',
    COUNTY: '119',
    NAME: 'Madison',
    LSAD: 'County',
    CENSUSAREA: 715.582,
    FIPS: 'IL119',
    fips: '17119',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 38.83016798,
    lng: -89.90510951,
  },
  {
    GEO_ID: '0500000US47039',
    STATE: '47',
    COUNTY: '039',
    NAME: 'Decatur',
    LSAD: 'County',
    CENSUSAREA: 333.845,
    FIPS: 'TN039',
    fips: '47039',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 35.60350601,
    lng: -88.10830091,
  },
  {
    GEO_ID: '0500000US47043',
    STATE: '47',
    COUNTY: '043',
    NAME: 'Dickson',
    LSAD: 'County',
    CENSUSAREA: 489.896,
    FIPS: 'TN043',
    fips: '47043',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 36.14932156,
    lng: -87.35658415,
  },
  {
    GEO_ID: '0500000US47053',
    STATE: '47',
    COUNTY: '053',
    NAME: 'Gibson',
    LSAD: 'County',
    CENSUSAREA: 602.742,
    FIPS: 'TN053',
    fips: '47053',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 35.99666265,
    lng: -88.93264081,
  },
  {
    GEO_ID: '0500000US47055',
    STATE: '47',
    COUNTY: '055',
    NAME: 'Giles',
    LSAD: 'County',
    CENSUSAREA: 610.927,
    FIPS: 'TN055',
    fips: '47055',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 35.20194268,
    lng: -87.03541784,
  },
  {
    GEO_ID: '0500000US23013',
    STATE: '23',
    COUNTY: '013',
    NAME: 'Knox',
    LSAD: 'County',
    CENSUSAREA: 365.134,
    FIPS: 'ME013',
    fips: '23013',
    StateAbbr: 'ME',
    StateName: 'Maine',
    lat: 44.12441708,
    lng: -69.15278458,
  },
  {
    GEO_ID: '0500000US23019',
    STATE: '23',
    COUNTY: '019',
    NAME: 'Penobscot',
    LSAD: 'County',
    CENSUSAREA: 3397.362,
    FIPS: 'ME019',
    fips: '23019',
    StateAbbr: 'ME',
    StateName: 'Maine',
    lat: 45.40038149,
    lng: -68.64952115,
  },
  {
    GEO_ID: '0500000US23025',
    STATE: '23',
    COUNTY: '025',
    NAME: 'Somerset',
    LSAD: 'County',
    CENSUSAREA: 3924.403,
    FIPS: 'ME025',
    fips: '23025',
    StateAbbr: 'ME',
    StateName: 'Maine',
    lat: 45.51360259,
    lng: -69.95882156,
  },
  {
    GEO_ID: '0500000US40121',
    STATE: '40',
    COUNTY: '121',
    NAME: 'Pittsburg',
    LSAD: 'County',
    CENSUSAREA: 1305.462,
    FIPS: 'OK121',
    fips: '40121',
    StateAbbr: 'OK',
    StateName: 'Oklahoma',
    lat: 34.92386502,
    lng: -95.74895216,
  },
  {
    GEO_ID: '0500000US40129',
    STATE: '40',
    COUNTY: '129',
    NAME: 'Roger Mills',
    LSAD: 'County',
    CENSUSAREA: 1141.136,
    FIPS: 'OK129',
    fips: '40129',
    StateAbbr: 'OK',
    StateName: 'Oklahoma',
    lat: 35.68852706,
    lng: -99.69538847,
  },
  {
    GEO_ID: '0500000US24045',
    STATE: '24',
    COUNTY: '045',
    NAME: 'Wicomico',
    LSAD: 'County',
    CENSUSAREA: 374.444,
    FIPS: 'MD045',
    fips: '24045',
    StateAbbr: 'MD',
    StateName: 'Maryland',
    lat: 38.37287229,
    lng: -75.62218411,
  },
  {
    GEO_ID: '0500000US25025',
    STATE: '25',
    COUNTY: '025',
    NAME: 'Suffolk',
    LSAD: 'County',
    CENSUSAREA: 58.154,
    FIPS: 'MA025',
    fips: '25025',
    StateAbbr: 'MA',
    StateName: 'Massachusetts',
    lat: 42.33340065,
    lng: -71.07033478,
  },
  {
    GEO_ID: '0500000US26001',
    STATE: '26',
    COUNTY: '001',
    NAME: 'Alcona',
    LSAD: 'County',
    CENSUSAREA: 674.585,
    FIPS: 'MI001',
    fips: '26001',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 44.68477825,
    lng: -83.59385271,
  },
  {
    GEO_ID: '0500000US41015',
    STATE: '41',
    COUNTY: '015',
    NAME: 'Curry',
    LSAD: 'County',
    CENSUSAREA: 1627.46,
    FIPS: 'OR015',
    fips: '41015',
    StateAbbr: 'OR',
    StateName: 'Oregon',
    lat: 42.45776177,
    lng: -124.1560919,
  },
  {
    GEO_ID: '0500000US41025',
    STATE: '41',
    COUNTY: '025',
    NAME: 'Harney',
    LSAD: 'County',
    CENSUSAREA: 10133.17,
    FIPS: 'OR025',
    fips: '41025',
    StateAbbr: 'OR',
    StateName: 'Oregon',
    lat: 43.06361843,
    lng: -118.9679625,
  },
  {
    GEO_ID: '0500000US26089',
    STATE: '26',
    COUNTY: '089',
    NAME: 'Leelanau',
    LSAD: 'County',
    CENSUSAREA: 347.171,
    FIPS: 'MI089',
    fips: '26089',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 44.93943424,
    lng: -85.81220561,
  },
  {
    GEO_ID: '0500000US26105',
    STATE: '26',
    COUNTY: '105',
    NAME: 'Mason',
    LSAD: 'County',
    CENSUSAREA: 495.073,
    FIPS: 'MI105',
    fips: '26105',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 43.99535369,
    lng: -86.25017381,
  },
  {
    GEO_ID: '0500000US28147',
    STATE: '28',
    COUNTY: '147',
    NAME: 'Walthall',
    LSAD: 'County',
    CENSUSAREA: 403.944,
    FIPS: 'MS147',
    fips: '28147',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 31.14823915,
    lng: -90.10601376,
  },
  {
    GEO_ID: '0500000US42107',
    STATE: '42',
    COUNTY: '107',
    NAME: 'Schuylkill',
    LSAD: 'County',
    CENSUSAREA: 778.634,
    FIPS: 'PA107',
    fips: '42107',
    StateAbbr: 'PA',
    StateName: 'Pennsylvania',
    lat: 40.70586881,
    lng: -76.21642765,
  },
  {
    GEO_ID: '0500000US42127',
    STATE: '42',
    COUNTY: '127',
    NAME: 'Wayne',
    LSAD: 'County',
    CENSUSAREA: 725.604,
    FIPS: 'PA127',
    fips: '42127',
    StateAbbr: 'PA',
    StateName: 'Pennsylvania',
    lat: 41.64851654,
    lng: -75.30318879,
  },
  {
    GEO_ID: '0500000US42129',
    STATE: '42',
    COUNTY: '129',
    NAME: 'Westmoreland',
    LSAD: 'County',
    CENSUSAREA: 1027.554,
    FIPS: 'PA129',
    fips: '42129',
    StateAbbr: 'PA',
    StateName: 'Pennsylvania',
    lat: 40.31090839,
    lng: -79.46732917,
  },
  {
    GEO_ID: '0500000US49015',
    STATE: '49',
    COUNTY: '015',
    NAME: 'Emery',
    LSAD: 'County',
    CENSUSAREA: 4462.314,
    FIPS: 'UT015',
    fips: '49015',
    StateAbbr: 'UT',
    StateName: 'Utah',
    lat: 38.99637448,
    lng: -110.7004925,
  },
  {
    GEO_ID: '0500000US49021',
    STATE: '49',
    COUNTY: '021',
    NAME: 'Iron',
    LSAD: 'County',
    CENSUSAREA: 3296.681,
    FIPS: 'UT021',
    fips: '49021',
    StateAbbr: 'UT',
    StateName: 'Utah',
    lat: 37.85935379,
    lng: -113.2894426,
  },
  {
    GEO_ID: '0500000US49025',
    STATE: '49',
    COUNTY: '025',
    NAME: 'Kane',
    LSAD: 'County',
    CENSUSAREA: 3990.229,
    FIPS: 'UT025',
    fips: '49025',
    StateAbbr: 'UT',
    StateName: 'Utah',
    lat: 37.28527775,
    lng: -111.8879757,
  },
  {
    GEO_ID: '0500000US47029',
    STATE: '47',
    COUNTY: '029',
    NAME: 'Cocke',
    LSAD: 'County',
    CENSUSAREA: 434.565,
    FIPS: 'TN029',
    fips: '47029',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 35.92555143,
    lng: -83.12121537,
  },
  {
    GEO_ID: '0500000US23027',
    STATE: '23',
    COUNTY: '027',
    NAME: 'Waldo',
    LSAD: 'County',
    CENSUSAREA: 729.918,
    FIPS: 'ME027',
    fips: '23027',
    StateAbbr: 'ME',
    StateName: 'Maine',
    lat: 44.50136648,
    lng: -69.14427892,
  },
  {
    GEO_ID: '0500000US31157',
    STATE: '31',
    COUNTY: '157',
    NAME: 'Scotts Bluff',
    LSAD: 'County',
    CENSUSAREA: 739.401,
    FIPS: 'NE157',
    fips: '31157',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 41.85095717,
    lng: -103.7084612,
  },
  {
    GEO_ID: '0500000US31165',
    STATE: '31',
    COUNTY: '165',
    NAME: 'Sioux',
    LSAD: 'County',
    CENSUSAREA: 2066.74,
    FIPS: 'NE165',
    fips: '31165',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 42.48797315,
    lng: -103.7588138,
  },
  {
    GEO_ID: '0500000US31175',
    STATE: '31',
    COUNTY: '175',
    NAME: 'Valley',
    LSAD: 'County',
    CENSUSAREA: 568.048,
    FIPS: 'NE175',
    fips: '31175',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 41.56723462,
    lng: -98.98208511,
  },
  {
    GEO_ID: '0500000US31177',
    STATE: '31',
    COUNTY: '177',
    NAME: 'Washington',
    LSAD: 'County',
    CENSUSAREA: 389.958,
    FIPS: 'NE177',
    fips: '31177',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 41.53110052,
    lng: -96.22188779,
  },
  {
    GEO_ID: '0500000US32001',
    STATE: '32',
    COUNTY: '001',
    NAME: 'Churchill',
    LSAD: 'County',
    CENSUSAREA: 4930.458,
    FIPS: 'NV001',
    fips: '32001',
    StateAbbr: 'NV',
    StateName: 'Nevada',
    lat: 39.58048345,
    lng: -118.3362581,
  },
  {
    GEO_ID: '0500000US32003',
    STATE: '32',
    COUNTY: '003',
    NAME: 'Clark',
    LSAD: 'County',
    CENSUSAREA: 7891.431,
    FIPS: 'NV003',
    fips: '32003',
    StateAbbr: 'NV',
    StateName: 'Nevada',
    lat: 36.21511541,
    lng: -115.0146087,
  },
  {
    GEO_ID: '0500000US26011',
    STATE: '26',
    COUNTY: '011',
    NAME: 'Arenac',
    LSAD: 'County',
    CENSUSAREA: 363.191,
    FIPS: 'MI011',
    fips: '26011',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 44.0651223,
    lng: -83.89498203,
  },
  {
    GEO_ID: '0500000US30067',
    STATE: '30',
    COUNTY: '067',
    NAME: 'Park',
    LSAD: 'County',
    CENSUSAREA: 2803.063,
    FIPS: 'MT067',
    fips: '30067',
    StateAbbr: 'MT',
    StateName: 'Montana',
    lat: 45.48913245,
    lng: -110.5249953,
  },
  {
    GEO_ID: '0500000US30073',
    STATE: '30',
    COUNTY: '073',
    NAME: 'Pondera',
    LSAD: 'County',
    CENSUSAREA: 1622.863,
    FIPS: 'MT073',
    fips: '30073',
    StateAbbr: 'MT',
    StateName: 'Montana',
    lat: 48.22793502,
    lng: -112.2266404,
  },
  {
    GEO_ID: '0500000US30079',
    STATE: '30',
    COUNTY: '079',
    NAME: 'Prairie',
    LSAD: 'County',
    CENSUSAREA: 1736.737,
    FIPS: 'MT079',
    fips: '30079',
    StateAbbr: 'MT',
    StateName: 'Montana',
    lat: 46.86049013,
    lng: -105.3780231,
  },
  {
    GEO_ID: '0500000US30081',
    STATE: '30',
    COUNTY: '081',
    NAME: 'Ravalli',
    LSAD: 'County',
    CENSUSAREA: 2390.821,
    FIPS: 'MT081',
    fips: '30081',
    StateAbbr: 'MT',
    StateName: 'Montana',
    lat: 46.08166896,
    lng: -114.120145,
  },
  {
    GEO_ID: '0500000US30089',
    STATE: '30',
    COUNTY: '089',
    NAME: 'Sanders',
    LSAD: 'County',
    CENSUSAREA: 2760.524,
    FIPS: 'MT089',
    fips: '30089',
    StateAbbr: 'MT',
    StateName: 'Montana',
    lat: 47.67490166,
    lng: -115.1333094,
  },
  {
    GEO_ID: '0500000US30095',
    STATE: '30',
    COUNTY: '095',
    NAME: 'Stillwater',
    LSAD: 'County',
    CENSUSAREA: 1795.35,
    FIPS: 'MT095',
    fips: '30095',
    StateAbbr: 'MT',
    StateName: 'Montana',
    lat: 45.66951507,
    lng: -109.394534,
  },
  {
    GEO_ID: '0500000US26109',
    STATE: '26',
    COUNTY: '109',
    NAME: 'Menominee',
    LSAD: 'County',
    CENSUSAREA: 1044.079,
    FIPS: 'MI109',
    fips: '26109',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 45.58036988,
    lng: -87.55660994,
  },
  {
    GEO_ID: '0500000US28149',
    STATE: '28',
    COUNTY: '149',
    NAME: 'Warren',
    LSAD: 'County',
    CENSUSAREA: 588.501,
    FIPS: 'MS149',
    fips: '28149',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 32.35637859,
    lng: -90.8514399,
  },
  {
    GEO_ID: '0500000US28161',
    STATE: '28',
    COUNTY: '161',
    NAME: 'Yalobusha',
    LSAD: 'County',
    CENSUSAREA: 467.129,
    FIPS: 'MS161',
    fips: '28161',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 34.02831011,
    lng: -89.70745642,
  },
  {
    GEO_ID: '0500000US29005',
    STATE: '29',
    COUNTY: '005',
    NAME: 'Atchison',
    LSAD: 'County',
    CENSUSAREA: 547.302,
    FIPS: 'MO005',
    fips: '29005',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 40.430839,
    lng: -95.42832663,
  },
  {
    GEO_ID: '0500000US29011',
    STATE: '29',
    COUNTY: '011',
    NAME: 'Barton',
    LSAD: 'County',
    CENSUSAREA: 591.921,
    FIPS: 'MO011',
    fips: '29011',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 37.50205562,
    lng: -94.34736796,
  },
  {
    GEO_ID: '0500000US29019',
    STATE: '29',
    COUNTY: '019',
    NAME: 'Boone',
    LSAD: 'County',
    CENSUSAREA: 685.414,
    FIPS: 'MO019',
    fips: '29019',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 38.99091068,
    lng: -92.30983436,
  },
  {
    GEO_ID: '0500000US29025',
    STATE: '29',
    COUNTY: '025',
    NAME: 'Caldwell',
    LSAD: 'County',
    CENSUSAREA: 426.393,
    FIPS: 'MO025',
    fips: '29025',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 39.65668445,
    lng: -93.98309623,
  },
  {
    GEO_ID: '0500000US39085',
    STATE: '39',
    COUNTY: '085',
    NAME: 'Lake',
    LSAD: 'County',
    CENSUSAREA: 227.493,
    FIPS: 'OH085',
    fips: '39085',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 41.69689265,
    lng: -81.23712921,
  },
  {
    GEO_ID: '0500000US39095',
    STATE: '39',
    COUNTY: '095',
    NAME: 'Lucas',
    LSAD: 'County',
    CENSUSAREA: 340.855,
    FIPS: 'OH095',
    fips: '39095',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 41.62064794,
    lng: -83.65545282,
  },
  {
    GEO_ID: '0500000US44009',
    STATE: '44',
    COUNTY: '009',
    NAME: 'Washington',
    LSAD: 'County',
    CENSUSAREA: 329.235,
    FIPS: 'RI009',
    fips: '44009',
    StateAbbr: 'RI',
    StateName: 'Rhode Island',
    lat: 41.46943509,
    lng: -71.62328128,
  },
  {
    GEO_ID: '0500000US45011',
    STATE: '45',
    COUNTY: '011',
    NAME: 'Barnwell',
    LSAD: 'County',
    CENSUSAREA: 548.393,
    FIPS: 'SC011',
    fips: '45011',
    StateAbbr: 'SC',
    StateName: 'South Carolina',
    lat: 33.26610854,
    lng: -81.43543848,
  },
  {
    GEO_ID: '0500000US45013',
    STATE: '45',
    COUNTY: '013',
    NAME: 'Beaufort',
    LSAD: 'County',
    CENSUSAREA: 576.28,
    FIPS: 'SC013',
    fips: '45013',
    StateAbbr: 'SC',
    StateName: 'South Carolina',
    lat: 32.3853684,
    lng: -80.73004378,
  },
  {
    GEO_ID: '0500000US32015',
    STATE: '32',
    COUNTY: '015',
    NAME: 'Lander',
    LSAD: 'County',
    CENSUSAREA: 5490.105,
    FIPS: 'NV015',
    fips: '32015',
    StateAbbr: 'NV',
    StateName: 'Nevada',
    lat: 39.93361994,
    lng: -117.0390723,
  },
  {
    GEO_ID: '0500000US32019',
    STATE: '32',
    COUNTY: '019',
    NAME: 'Lyon',
    LSAD: 'County',
    CENSUSAREA: 2001.187,
    FIPS: 'NV019',
    fips: '32019',
    StateAbbr: 'NV',
    StateName: 'Nevada',
    lat: 39.01991047,
    lng: -119.1886954,
  },
  {
    GEO_ID: '0500000US32029',
    STATE: '32',
    COUNTY: '029',
    NAME: 'Storey',
    LSAD: 'County',
    CENSUSAREA: 262.92,
    FIPS: 'NV029',
    fips: '32029',
    StateAbbr: 'NV',
    StateName: 'Nevada',
    lat: 39.44660847,
    lng: -119.5289865,
  },
  {
    GEO_ID: '0500000US39111',
    STATE: '39',
    COUNTY: '111',
    NAME: 'Monroe',
    LSAD: 'County',
    CENSUSAREA: 455.721,
    FIPS: 'OH111',
    fips: '39111',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 39.72753365,
    lng: -81.08267492,
  },
  {
    GEO_ID: '0500000US39113',
    STATE: '39',
    COUNTY: '113',
    NAME: 'Montgomery',
    LSAD: 'County',
    CENSUSAREA: 461.553,
    FIPS: 'OH113',
    fips: '39113',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 39.75442102,
    lng: -84.29037841,
  },
  {
    GEO_ID: '0500000US39123',
    STATE: '39',
    COUNTY: '123',
    NAME: 'Ottawa',
    LSAD: 'County',
    CENSUSAREA: 254.917,
    FIPS: 'OH123',
    fips: '39123',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 41.5370554,
    lng: -83.10923872,
  },
  {
    GEO_ID: '0500000US39145',
    STATE: '39',
    COUNTY: '145',
    NAME: 'Scioto',
    LSAD: 'County',
    CENSUSAREA: 610.213,
    FIPS: 'OH145',
    fips: '39145',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 38.80404083,
    lng: -82.99326888,
  },
  {
    GEO_ID: '0500000US39155',
    STATE: '39',
    COUNTY: '155',
    NAME: 'Trumbull',
    LSAD: 'County',
    CENSUSAREA: 618.297,
    FIPS: 'OH155',
    fips: '39155',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 41.31734436,
    lng: -80.76110735,
  },
  {
    GEO_ID: '0500000US30103',
    STATE: '30',
    COUNTY: '103',
    NAME: 'Treasure',
    LSAD: 'County',
    CENSUSAREA: 977.398,
    FIPS: 'MT103',
    fips: '30103',
    StateAbbr: 'MT',
    StateName: 'Montana',
    lat: 46.21135847,
    lng: -107.2718156,
  },
  {
    GEO_ID: '0500000US30105',
    STATE: '30',
    COUNTY: '105',
    NAME: 'Valley',
    LSAD: 'County',
    CENSUSAREA: 4925.819,
    FIPS: 'MT105',
    fips: '30105',
    StateAbbr: 'MT',
    StateName: 'Montana',
    lat: 48.3648329,
    lng: -106.6682222,
  },
  {
    GEO_ID: '0500000US38077',
    STATE: '38',
    COUNTY: '077',
    NAME: 'Richland',
    LSAD: 'County',
    CENSUSAREA: 1435.779,
    FIPS: 'ND077',
    fips: '38077',
    StateAbbr: 'ND',
    StateName: 'North Dakota',
    lat: 46.26461801,
    lng: -96.94827807,
  },
  {
    GEO_ID: '0500000US38085',
    STATE: '38',
    COUNTY: '085',
    NAME: 'Sioux',
    LSAD: 'County',
    CENSUSAREA: 1094.094,
    FIPS: 'ND085',
    fips: '38085',
    StateAbbr: 'ND',
    StateName: 'North Dakota',
    lat: 46.11262812,
    lng: -101.0403323,
  },
  {
    GEO_ID: '0500000US38099',
    STATE: '38',
    COUNTY: '099',
    NAME: 'Walsh',
    LSAD: 'County',
    CENSUSAREA: 1281.931,
    FIPS: 'ND099',
    fips: '38099',
    StateAbbr: 'ND',
    StateName: 'North Dakota',
    lat: 48.36930301,
    lng: -97.72134503,
  },
  {
    GEO_ID: '0500000US38105',
    STATE: '38',
    COUNTY: '105',
    NAME: 'Williams',
    LSAD: 'County',
    CENSUSAREA: 2077.401,
    FIPS: 'ND105',
    fips: '38105',
    StateAbbr: 'ND',
    StateName: 'North Dakota',
    lat: 48.34373147,
    lng: -103.4801272,
  },
  {
    GEO_ID: '0500000US29031',
    STATE: '29',
    COUNTY: '031',
    NAME: 'Cape Girardeau',
    LSAD: 'County',
    CENSUSAREA: 578.535,
    FIPS: 'MO031',
    fips: '29031',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 37.38402006,
    lng: -89.68444987,
  },
  {
    GEO_ID: '0500000US29037',
    STATE: '29',
    COUNTY: '037',
    NAME: 'Cass',
    LSAD: 'County',
    CENSUSAREA: 696.836,
    FIPS: 'MO037',
    fips: '29037',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 38.64793026,
    lng: -94.35504883,
  },
  {
    GEO_ID: '0500000US36047',
    STATE: '36',
    COUNTY: '047',
    NAME: 'Kings',
    LSAD: 'County',
    CENSUSAREA: 70.816,
    FIPS: 'NY047',
    fips: '36047',
    StateAbbr: 'NY',
    StateName: 'New York',
    lat: 40.63562226,
    lng: -73.93708255,
  },
  {
    GEO_ID: '0500000US36053',
    STATE: '36',
    COUNTY: '053',
    NAME: 'Madison',
    LSAD: 'County',
    CENSUSAREA: 654.842,
    FIPS: 'NY053',
    fips: '36053',
    StateAbbr: 'NY',
    StateName: 'New York',
    lat: 42.91279676,
    lng: -75.66993538,
  },
  {
    GEO_ID: '0500000US36061',
    STATE: '36',
    COUNTY: '061',
    NAME: 'New York',
    LSAD: 'County',
    CENSUSAREA: 22.829,
    FIPS: 'NY061',
    fips: '36061',
    StateAbbr: 'NY',
    StateName: 'New York',
    lat: 40.7721053,
    lng: -73.97166722,
  },
  {
    GEO_ID: '0500000US36067',
    STATE: '36',
    COUNTY: '067',
    NAME: 'Onondaga',
    LSAD: 'County',
    CENSUSAREA: 778.39,
    FIPS: 'NY067',
    fips: '36067',
    StateAbbr: 'NY',
    StateName: 'New York',
    lat: 43.0061269,
    lng: -76.19460186,
  },
  {
    GEO_ID: '0500000US41045',
    STATE: '41',
    COUNTY: '045',
    NAME: 'Malheur',
    LSAD: 'County',
    CENSUSAREA: 9887.532,
    FIPS: 'OR045',
    fips: '41045',
    StateAbbr: 'OR',
    StateName: 'Oregon',
    lat: 43.19355699,
    lng: -117.6231067,
  },
  {
    GEO_ID: '0500000US41055',
    STATE: '41',
    COUNTY: '055',
    NAME: 'Sherman',
    LSAD: 'County',
    CENSUSAREA: 823.694,
    FIPS: 'OR055',
    fips: '41055',
    StateAbbr: 'OR',
    StateName: 'Oregon',
    lat: 45.4052255,
    lng: -120.6892687,
  },
  {
    GEO_ID: '0500000US41057',
    STATE: '41',
    COUNTY: '057',
    NAME: 'Tillamook',
    LSAD: 'County',
    CENSUSAREA: 1102.58,
    FIPS: 'OR057',
    fips: '41057',
    StateAbbr: 'OR',
    StateName: 'Oregon',
    lat: 45.46343846,
    lng: -123.7127608,
  },
  {
    GEO_ID: '0500000US41063',
    STATE: '41',
    COUNTY: '063',
    NAME: 'Wallowa',
    LSAD: 'County',
    CENSUSAREA: 3146.189,
    FIPS: 'OR063',
    fips: '41063',
    StateAbbr: 'OR',
    StateName: 'Oregon',
    lat: 45.57979758,
    lng: -117.1810198,
  },
  {
    GEO_ID: '0500000US41067',
    STATE: '41',
    COUNTY: '067',
    NAME: 'Washington',
    LSAD: 'County',
    CENSUSAREA: 724.23,
    FIPS: 'OR067',
    fips: '41067',
    StateAbbr: 'OR',
    StateName: 'Oregon',
    lat: 45.55989124,
    lng: -123.0985728,
  },
  {
    GEO_ID: '0500000US29101',
    STATE: '29',
    COUNTY: '101',
    NAME: 'Johnson',
    LSAD: 'County',
    CENSUSAREA: 829.282,
    FIPS: 'MO101',
    fips: '29101',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 38.74399152,
    lng: -93.80588795,
  },
  {
    GEO_ID: '0500000US29107',
    STATE: '29',
    COUNTY: '107',
    NAME: 'Lafayette',
    LSAD: 'County',
    CENSUSAREA: 628.433,
    FIPS: 'MO107',
    fips: '29107',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 39.06555761,
    lng: -93.7855705,
  },
  {
    GEO_ID: '0500000US29113',
    STATE: '29',
    COUNTY: '113',
    NAME: 'Lincoln',
    LSAD: 'County',
    CENSUSAREA: 626.556,
    FIPS: 'MO113',
    fips: '29113',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 39.05829936,
    lng: -90.96009523,
  },
  {
    GEO_ID: '0500000US29123',
    STATE: '29',
    COUNTY: '123',
    NAME: 'Madison',
    LSAD: 'County',
    CENSUSAREA: 494.39,
    FIPS: 'MO123',
    fips: '29123',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 37.47823462,
    lng: -90.34465099,
  },
  {
    GEO_ID: '0500000US29127',
    STATE: '29',
    COUNTY: '127',
    NAME: 'Marion',
    LSAD: 'County',
    CENSUSAREA: 436.924,
    FIPS: 'MO127',
    fips: '29127',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 39.8059363,
    lng: -91.62231175,
  },
  {
    GEO_ID: '0500000US39163',
    STATE: '39',
    COUNTY: '163',
    NAME: 'Vinton',
    LSAD: 'County',
    CENSUSAREA: 412.36,
    FIPS: 'OH163',
    fips: '39163',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 39.25106486,
    lng: -82.48545415,
  },
  {
    GEO_ID: '0500000US47073',
    STATE: '47',
    COUNTY: '073',
    NAME: 'Hawkins',
    LSAD: 'County',
    CENSUSAREA: 486.975,
    FIPS: 'TN073',
    fips: '47073',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 36.4410474,
    lng: -82.94554982,
  },
  {
    GEO_ID: '0500000US47079',
    STATE: '47',
    COUNTY: '079',
    NAME: 'Henry',
    LSAD: 'County',
    CENSUSAREA: 562.096,
    FIPS: 'TN079',
    fips: '47079',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 36.33178059,
    lng: -88.30116646,
  },
  {
    GEO_ID: '0500000US47089',
    STATE: '47',
    COUNTY: '089',
    NAME: 'Jefferson',
    LSAD: 'County',
    CENSUSAREA: 274.078,
    FIPS: 'TN089',
    fips: '47089',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 36.0513409,
    lng: -83.44652522,
  },
  {
    GEO_ID: '0500000US47091',
    STATE: '47',
    COUNTY: '091',
    NAME: 'Johnson',
    LSAD: 'County',
    CENSUSAREA: 298.475,
    FIPS: 'TN091',
    fips: '47091',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 36.45484107,
    lng: -81.85180572,
  },
  {
    GEO_ID: '0500000US47097',
    STATE: '47',
    COUNTY: '097',
    NAME: 'Lauderdale',
    LSAD: 'County',
    CENSUSAREA: 471.992,
    FIPS: 'TN097',
    fips: '47097',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 35.76118851,
    lng: -89.63135139,
  },
  {
    GEO_ID: '0500000US47103',
    STATE: '47',
    COUNTY: '103',
    NAME: 'Lincoln',
    LSAD: 'County',
    CENSUSAREA: 570.338,
    FIPS: 'TN103',
    fips: '47103',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 35.14053022,
    lng: -86.58840969,
  },
  {
    GEO_ID: '0500000US47115',
    STATE: '47',
    COUNTY: '115',
    NAME: 'Marion',
    LSAD: 'County',
    CENSUSAREA: 498.16,
    FIPS: 'TN115',
    fips: '47115',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 35.1294263,
    lng: -85.62192494,
  },
  {
    GEO_ID: '0500000US47121',
    STATE: '47',
    COUNTY: '121',
    NAME: 'Meigs',
    LSAD: 'County',
    CENSUSAREA: 195.122,
    FIPS: 'TN121',
    fips: '47121',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 35.51294836,
    lng: -84.8129419,
  },
  {
    GEO_ID: '0500000US47123',
    STATE: '47',
    COUNTY: '123',
    NAME: 'Monroe',
    LSAD: 'County',
    CENSUSAREA: 635.565,
    FIPS: 'TN123',
    fips: '47123',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 35.44293343,
    lng: -84.25271451,
  },
  {
    GEO_ID: '0500000US47131',
    STATE: '47',
    COUNTY: '131',
    NAME: 'Obion',
    LSAD: 'County',
    CENSUSAREA: 544.728,
    FIPS: 'TN131',
    fips: '47131',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 36.35824874,
    lng: -89.1487146,
  },
  {
    GEO_ID: '0500000US39007',
    STATE: '39',
    COUNTY: '007',
    NAME: 'Ashtabula',
    LSAD: 'County',
    CENSUSAREA: 701.931,
    FIPS: 'OH007',
    fips: '39007',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 41.70753004,
    lng: -80.74863402,
  },
  {
    GEO_ID: '0500000US48371',
    STATE: '48',
    COUNTY: '371',
    NAME: 'Pecos',
    LSAD: 'County',
    CENSUSAREA: 4763.847,
    FIPS: 'TX371',
    fips: '48371',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 30.78076842,
    lng: -102.7234229,
  },
  {
    GEO_ID: '0500000US48377',
    STATE: '48',
    COUNTY: '377',
    NAME: 'Presidio',
    LSAD: 'County',
    CENSUSAREA: 3855.237,
    FIPS: 'TX377',
    fips: '48377',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 29.99962168,
    lng: -104.2408125,
  },
  {
    GEO_ID: '0500000US48391',
    STATE: '48',
    COUNTY: '391',
    NAME: 'Refugio',
    LSAD: 'County',
    CENSUSAREA: 770.444,
    FIPS: 'TX391',
    fips: '48391',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 28.32554745,
    lng: -97.16001089,
  },
  {
    GEO_ID: '0500000US48401',
    STATE: '48',
    COUNTY: '401',
    NAME: 'Rusk',
    LSAD: 'County',
    CENSUSAREA: 924.027,
    FIPS: 'TX401',
    fips: '48401',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 32.10819843,
    lng: -94.76183346,
  },
  {
    GEO_ID: '0500000US48407',
    STATE: '48',
    COUNTY: '407',
    NAME: 'San Jacinto',
    LSAD: 'County',
    CENSUSAREA: 569.243,
    FIPS: 'TX407',
    fips: '48407',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 30.57936685,
    lng: -95.1671992,
  },
  {
    GEO_ID: '0500000US48409',
    STATE: '48',
    COUNTY: '409',
    NAME: 'San Patricio',
    LSAD: 'County',
    CENSUSAREA: 693.451,
    FIPS: 'TX409',
    fips: '48409',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 28.00978811,
    lng: -97.51888305,
  },
  {
    GEO_ID: '0500000US36079',
    STATE: '36',
    COUNTY: '079',
    NAME: 'Putnam',
    LSAD: 'County',
    CENSUSAREA: 230.312,
    FIPS: 'NY079',
    fips: '36079',
    StateAbbr: 'NY',
    StateName: 'New York',
    lat: 41.42650368,
    lng: -73.74940843,
  },
  {
    GEO_ID: '0500000US48443',
    STATE: '48',
    COUNTY: '443',
    NAME: 'Terrell',
    LSAD: 'County',
    CENSUSAREA: 2358.026,
    FIPS: 'TX443',
    fips: '48443',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 30.22490816,
    lng: -102.0764353,
  },
  {
    GEO_ID: '0500000US48461',
    STATE: '48',
    COUNTY: '461',
    NAME: 'Upton',
    LSAD: 'County',
    CENSUSAREA: 1241.324,
    FIPS: 'TX461',
    fips: '48461',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 31.36864453,
    lng: -102.0428877,
  },
  {
    GEO_ID: '0500000US48465',
    STATE: '48',
    COUNTY: '465',
    NAME: 'Val Verde',
    LSAD: 'County',
    CENSUSAREA: 3144.75,
    FIPS: 'TX465',
    fips: '48465',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 29.89249412,
    lng: -101.1521556,
  },
  {
    GEO_ID: '0500000US48473',
    STATE: '48',
    COUNTY: '473',
    NAME: 'Waller',
    LSAD: 'County',
    CENSUSAREA: 513.43,
    FIPS: 'TX473',
    fips: '48473',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 30.01059467,
    lng: -95.98754906,
  },
  {
    GEO_ID: '0500000US48479',
    STATE: '48',
    COUNTY: '479',
    NAME: 'Webb',
    LSAD: 'County',
    CENSUSAREA: 3361.482,
    FIPS: 'TX479',
    fips: '48479',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 27.76103224,
    lng: -99.33173804,
  },
  {
    GEO_ID: '0500000US48487',
    STATE: '48',
    COUNTY: '487',
    NAME: 'Wilbarger',
    LSAD: 'County',
    CENSUSAREA: 970.844,
    FIPS: 'TX487',
    fips: '48487',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 34.08084256,
    lng: -99.24098891,
  },
  {
    GEO_ID: '0500000US48495',
    STATE: '48',
    COUNTY: '495',
    NAME: 'Winkler',
    LSAD: 'County',
    CENSUSAREA: 841.107,
    FIPS: 'TX495',
    fips: '48495',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 31.85000039,
    lng: -103.048449,
  },
  {
    GEO_ID: '0500000US48501',
    STATE: '48',
    COUNTY: '501',
    NAME: 'Yoakum',
    LSAD: 'County',
    CENSUSAREA: 799.708,
    FIPS: 'TX501',
    fips: '48501',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 33.1730387,
    lng: -102.8279472,
  },
  {
    GEO_ID: '0500000US48505',
    STATE: '48',
    COUNTY: '505',
    NAME: 'Zapata',
    LSAD: 'County',
    CENSUSAREA: 998.412,
    FIPS: 'TX505',
    fips: '48505',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 27.00076857,
    lng: -99.16858655,
  },
  {
    GEO_ID: '0500000US49003',
    STATE: '49',
    COUNTY: '003',
    NAME: 'Box Elder',
    LSAD: 'County',
    CENSUSAREA: 5745.551,
    FIPS: 'UT003',
    fips: '49003',
    StateAbbr: 'UT',
    StateName: 'Utah',
    lat: 41.52095018,
    lng: -113.0821456,
  },
  {
    GEO_ID: '0500000US48005',
    STATE: '48',
    COUNTY: '005',
    NAME: 'Angelina',
    LSAD: 'County',
    CENSUSAREA: 797.778,
    FIPS: 'TX005',
    fips: '48005',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 31.25457834,
    lng: -94.61100866,
  },
  {
    GEO_ID: '0500000US48007',
    STATE: '48',
    COUNTY: '007',
    NAME: 'Aransas',
    LSAD: 'County',
    CENSUSAREA: 252.074,
    FIPS: 'TX007',
    fips: '48007',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 28.11898396,
    lng: -96.99699873,
  },
  {
    GEO_ID: '0500000US48037',
    STATE: '48',
    COUNTY: '037',
    NAME: 'Bowie',
    LSAD: 'County',
    CENSUSAREA: 885.006,
    FIPS: 'TX037',
    fips: '48037',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 33.44693757,
    lng: -94.42378182,
  },
  {
    GEO_ID: '0500000US48043',
    STATE: '48',
    COUNTY: '043',
    NAME: 'Brewster',
    LSAD: 'County',
    CENSUSAREA: 6183.733,
    FIPS: 'TX043',
    fips: '48043',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 29.81174561,
    lng: -103.2522656,
  },
  {
    GEO_ID: '0500000US29183',
    STATE: '29',
    COUNTY: '183',
    NAME: 'St. Charles',
    LSAD: 'County',
    CENSUSAREA: 560.435,
    FIPS: 'MO183',
    fips: '29183',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 38.78248212,
    lng: -90.67411979,
  },
  {
    GEO_ID: '0500000US29189',
    STATE: '29',
    COUNTY: '189',
    NAME: 'St. Louis',
    LSAD: 'County',
    CENSUSAREA: 507.8,
    FIPS: 'MO189',
    fips: '29189',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 38.64048457,
    lng: -90.44344187,
  },
  {
    GEO_ID: '0500000US29199',
    STATE: '29',
    COUNTY: '199',
    NAME: 'Scotland',
    LSAD: 'County',
    CENSUSAREA: 436.671,
    FIPS: 'MO199',
    fips: '29199',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 40.45201091,
    lng: -92.1472444,
  },
  {
    GEO_ID: '0500000US24005',
    STATE: '24',
    COUNTY: '005',
    NAME: 'Baltimore',
    LSAD: 'County',
    CENSUSAREA: 598.302,
    FIPS: 'MD005',
    fips: '24005',
    StateAbbr: 'MD',
    StateName: 'Maryland',
    lat: 39.4596552,
    lng: -76.63603021,
  },
  {
    GEO_ID: '0500000US24025',
    STATE: '24',
    COUNTY: '025',
    NAME: 'Harford',
    LSAD: 'County',
    CENSUSAREA: 437.089,
    FIPS: 'MD025',
    fips: '24025',
    StateAbbr: 'MD',
    StateName: 'Maryland',
    lat: 39.56126248,
    lng: -76.31717004,
  },
  {
    GEO_ID: '0500000US26013',
    STATE: '26',
    COUNTY: '013',
    NAME: 'Baraga',
    LSAD: 'County',
    CENSUSAREA: 898.257,
    FIPS: 'MI013',
    fips: '26013',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 46.66237725,
    lng: -88.36559186,
  },
  {
    GEO_ID: '0500000US26029',
    STATE: '26',
    COUNTY: '029',
    NAME: 'Charlevoix',
    LSAD: 'County',
    CENSUSAREA: 416.339,
    FIPS: 'MI029',
    fips: '26029',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 45.30562797,
    lng: -85.12921505,
  },
  {
    GEO_ID: '0500000US30111',
    STATE: '30',
    COUNTY: '111',
    NAME: 'Yellowstone',
    LSAD: 'County',
    CENSUSAREA: 2633.294,
    FIPS: 'MT111',
    fips: '30111',
    StateAbbr: 'MT',
    StateName: 'Montana',
    lat: 45.93715306,
    lng: -108.2742553,
  },
  {
    GEO_ID: '0500000US31015',
    STATE: '31',
    COUNTY: '015',
    NAME: 'Boyd',
    LSAD: 'County',
    CENSUSAREA: 539.94,
    FIPS: 'NE015',
    fips: '31015',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 42.89966872,
    lng: -98.76608585,
  },
  {
    GEO_ID: '0500000US31021',
    STATE: '31',
    COUNTY: '021',
    NAME: 'Burt',
    LSAD: 'County',
    CENSUSAREA: 491.581,
    FIPS: 'NE021',
    fips: '31021',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 41.85154682,
    lng: -96.32857388,
  },
  {
    GEO_ID: '0500000US26121',
    STATE: '26',
    COUNTY: '121',
    NAME: 'Muskegon',
    LSAD: 'County',
    CENSUSAREA: 499.246,
    FIPS: 'MI121',
    fips: '26121',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 43.2913812,
    lng: -86.1519783,
  },
  {
    GEO_ID: '0500000US26127',
    STATE: '26',
    COUNTY: '127',
    NAME: 'Oceana',
    LSAD: 'County',
    CENSUSAREA: 512.071,
    FIPS: 'MI127',
    fips: '26127',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 43.64097781,
    lng: -86.26734566,
  },
  {
    GEO_ID: '0500000US26139',
    STATE: '26',
    COUNTY: '139',
    NAME: 'Ottawa',
    LSAD: 'County',
    CENSUSAREA: 563.467,
    FIPS: 'MI139',
    fips: '26139',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 42.95990607,
    lng: -85.9964531,
  },
  {
    GEO_ID: '0500000US26141',
    STATE: '26',
    COUNTY: '141',
    NAME: 'Presque Isle',
    LSAD: 'County',
    CENSUSAREA: 658.719,
    FIPS: 'MI141',
    fips: '26141',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 45.34035915,
    lng: -83.91721501,
  },
  {
    GEO_ID: '0500000US26151',
    STATE: '26',
    COUNTY: '151',
    NAME: 'Sanilac',
    LSAD: 'County',
    CENSUSAREA: 962.566,
    FIPS: 'MI151',
    fips: '26151',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 43.4237632,
    lng: -82.82063341,
  },
  {
    GEO_ID: '0500000US26153',
    STATE: '26',
    COUNTY: '153',
    NAME: 'Schoolcraft',
    LSAD: 'County',
    CENSUSAREA: 1171.358,
    FIPS: 'MI153',
    fips: '26153',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 46.19666734,
    lng: -86.19985664,
  },
  {
    GEO_ID: '0500000US45019',
    STATE: '45',
    COUNTY: '019',
    NAME: 'Charleston',
    LSAD: 'County',
    CENSUSAREA: 916.089,
    FIPS: 'SC019',
    fips: '45019',
    StateAbbr: 'SC',
    StateName: 'South Carolina',
    lat: 32.83417041,
    lng: -79.95226654,
  },
  {
    GEO_ID: '0500000US46071',
    STATE: '46',
    COUNTY: '071',
    NAME: 'Jackson',
    LSAD: 'County',
    CENSUSAREA: 1863.914,
    FIPS: 'SD071',
    fips: '46071',
    StateAbbr: 'SD',
    StateName: 'South Dakota',
    lat: 43.69419796,
    lng: -101.6282752,
  },
  {
    GEO_ID: '0500000US46081',
    STATE: '46',
    COUNTY: '081',
    NAME: 'Lawrence',
    LSAD: 'County',
    CENSUSAREA: 800.035,
    FIPS: 'SD081',
    fips: '46081',
    StateAbbr: 'SD',
    StateName: 'South Dakota',
    lat: 44.35866653,
    lng: -103.7921498,
  },
  {
    GEO_ID: '0500000US46083',
    STATE: '46',
    COUNTY: '083',
    NAME: 'Lincoln',
    LSAD: 'County',
    CENSUSAREA: 577.279,
    FIPS: 'SD083',
    fips: '46083',
    StateAbbr: 'SD',
    StateName: 'South Dakota',
    lat: 43.27884092,
    lng: -96.7217084,
  },
  {
    GEO_ID: '0500000US46089',
    STATE: '46',
    COUNTY: '089',
    NAME: 'McPherson',
    LSAD: 'County',
    CENSUSAREA: 1136.644,
    FIPS: 'SD089',
    fips: '46089',
    StateAbbr: 'SD',
    StateName: 'South Dakota',
    lat: 45.7662597,
    lng: -99.221197,
  },
  {
    GEO_ID: '0500000US46099',
    STATE: '46',
    COUNTY: '099',
    NAME: 'Minnehaha',
    LSAD: 'County',
    CENSUSAREA: 807.147,
    FIPS: 'SD099',
    fips: '46099',
    StateAbbr: 'SD',
    StateName: 'South Dakota',
    lat: 43.67413367,
    lng: -96.7914546,
  },
  {
    GEO_ID: '0500000US46105',
    STATE: '46',
    COUNTY: '105',
    NAME: 'Perkins',
    LSAD: 'County',
    CENSUSAREA: 2870.478,
    FIPS: 'SD105',
    fips: '46105',
    StateAbbr: 'SD',
    StateName: 'South Dakota',
    lat: 45.49024355,
    lng: -102.4744037,
  },
  {
    GEO_ID: '0500000US46109',
    STATE: '46',
    COUNTY: '109',
    NAME: 'Roberts',
    LSAD: 'County',
    CENSUSAREA: 1101.041,
    FIPS: 'SD109',
    fips: '46109',
    StateAbbr: 'SD',
    StateName: 'South Dakota',
    lat: 45.62977349,
    lng: -96.94614272,
  },
  {
    GEO_ID: '0500000US33001',
    STATE: '33',
    COUNTY: '001',
    NAME: 'Belknap',
    LSAD: 'County',
    CENSUSAREA: 400.232,
    FIPS: 'NH001',
    fips: '33001',
    StateAbbr: 'NH',
    StateName: 'New Hampshire',
    lat: 43.51832231,
    lng: -71.4226598,
  },
  {
    GEO_ID: '0500000US33007',
    STATE: '33',
    COUNTY: '007',
    NAME: 'Coos',
    LSAD: 'County',
    CENSUSAREA: 1794.685,
    FIPS: 'NH007',
    fips: '33007',
    StateAbbr: 'NH',
    StateName: 'New Hampshire',
    lat: 44.68948989,
    lng: -71.30563958,
  },
  {
    GEO_ID: '0500000US33011',
    STATE: '33',
    COUNTY: '011',
    NAME: 'Hillsborough',
    LSAD: 'County',
    CENSUSAREA: 876.139,
    FIPS: 'NH011',
    fips: '33011',
    StateAbbr: 'NH',
    StateName: 'New Hampshire',
    lat: 42.9153307,
    lng: -71.71602867,
  },
  {
    GEO_ID: '0500000US33013',
    STATE: '33',
    COUNTY: '013',
    NAME: 'Merrimack',
    LSAD: 'County',
    CENSUSAREA: 934.116,
    FIPS: 'NH013',
    fips: '33013',
    StateAbbr: 'NH',
    StateName: 'New Hampshire',
    lat: 43.2975734,
    lng: -71.68059329,
  },
  {
    GEO_ID: '0500000US34001',
    STATE: '34',
    COUNTY: '001',
    NAME: 'Atlantic',
    LSAD: 'County',
    CENSUSAREA: 555.704,
    FIPS: 'NJ001',
    fips: '34001',
    StateAbbr: 'NJ',
    StateName: 'New Jersey',
    lat: 39.47790545,
    lng: -74.66040811,
  },
  {
    GEO_ID: '0500000US34005',
    STATE: '34',
    COUNTY: '005',
    NAME: 'Burlington',
    LSAD: 'County',
    CENSUSAREA: 798.576,
    FIPS: 'NJ005',
    fips: '34005',
    StateAbbr: 'NJ',
    StateName: 'New Jersey',
    lat: 39.87782133,
    lng: -74.6680045,
  },
  {
    GEO_ID: '0500000US34011',
    STATE: '34',
    COUNTY: '011',
    NAME: 'Cumberland',
    LSAD: 'County',
    CENSUSAREA: 483.703,
    FIPS: 'NJ011',
    fips: '34011',
    StateAbbr: 'NJ',
    StateName: 'New Jersey',
    lat: 39.37323951,
    lng: -75.11135485,
  },
  {
    GEO_ID: '0500000US31031',
    STATE: '31',
    COUNTY: '031',
    NAME: 'Cherry',
    LSAD: 'County',
    CENSUSAREA: 5960.422,
    FIPS: 'NE031',
    fips: '31031',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 42.54499866,
    lng: -101.1185272,
  },
  {
    GEO_ID: '0500000US31043',
    STATE: '31',
    COUNTY: '043',
    NAME: 'Dakota',
    LSAD: 'County',
    CENSUSAREA: 264.255,
    FIPS: 'NE043',
    fips: '31043',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 42.39108493,
    lng: -96.56465437,
  },
  {
    GEO_ID: '0500000US31049',
    STATE: '31',
    COUNTY: '049',
    NAME: 'Deuel',
    LSAD: 'County',
    CENSUSAREA: 439.851,
    FIPS: 'NE049',
    fips: '31049',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 41.111546,
    lng: -102.3338627,
  },
  {
    GEO_ID: '0500000US31055',
    STATE: '31',
    COUNTY: '055',
    NAME: 'Douglas',
    LSAD: 'County',
    CENSUSAREA: 328.455,
    FIPS: 'NE055',
    fips: '31055',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 41.29533784,
    lng: -96.15494428,
  },
  {
    GEO_ID: '0500000US31061',
    STATE: '31',
    COUNTY: '061',
    NAME: 'Franklin',
    LSAD: 'County',
    CENSUSAREA: 575.816,
    FIPS: 'NE061',
    fips: '31061',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 40.17637323,
    lng: -98.95299529,
  },
  {
    GEO_ID: '0500000US31067',
    STATE: '31',
    COUNTY: '067',
    NAME: 'Gage',
    LSAD: 'County',
    CENSUSAREA: 851.491,
    FIPS: 'NE067',
    fips: '31067',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 40.26183622,
    lng: -96.68942195,
  },
  {
    GEO_ID: '0500000US39035',
    STATE: '39',
    COUNTY: '035',
    NAME: 'Cuyahoga',
    LSAD: 'County',
    CENSUSAREA: 457.191,
    FIPS: 'OH035',
    fips: '39035',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 41.42426021,
    lng: -81.6587538,
  },
  {
    GEO_ID: '0500000US39051',
    STATE: '39',
    COUNTY: '051',
    NAME: 'Fulton',
    LSAD: 'County',
    CENSUSAREA: 405.443,
    FIPS: 'OH051',
    fips: '39051',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 41.60183312,
    lng: -84.12943991,
  },
  {
    GEO_ID: '0500000US39053',
    STATE: '39',
    COUNTY: '053',
    NAME: 'Gallia',
    LSAD: 'County',
    CENSUSAREA: 466.53,
    FIPS: 'OH053',
    fips: '39053',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 38.82476088,
    lng: -82.31692618,
  },
  {
    GEO_ID: '0500000US29063',
    STATE: '29',
    COUNTY: '063',
    NAME: 'DeKalb',
    LSAD: 'County',
    CENSUSAREA: 421.356,
    FIPS: 'MO063',
    fips: '29063',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 39.89285656,
    lng: -94.40452876,
  },
  {
    GEO_ID: '0500000US29069',
    STATE: '29',
    COUNTY: '069',
    NAME: 'Dunklin',
    LSAD: 'County',
    CENSUSAREA: 541.069,
    FIPS: 'MO069',
    fips: '29069',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 36.2722822,
    lng: -90.09071406,
  },
  {
    GEO_ID: '0500000US29081',
    STATE: '29',
    COUNTY: '081',
    NAME: 'Harrison',
    LSAD: 'County',
    CENSUSAREA: 722.504,
    FIPS: 'MO081',
    fips: '29081',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 40.35497608,
    lng: -93.99193402,
  },
  {
    GEO_ID: '0500000US29089',
    STATE: '29',
    COUNTY: '089',
    NAME: 'Howard',
    LSAD: 'County',
    CENSUSAREA: 463.849,
    FIPS: 'MO089',
    fips: '29089',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 39.1425032,
    lng: -92.69630819,
  },
  {
    GEO_ID: '0500000US29091',
    STATE: '29',
    COUNTY: '091',
    NAME: 'Howell',
    LSAD: 'County',
    CENSUSAREA: 927.248,
    FIPS: 'MO091',
    fips: '29091',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 36.77371722,
    lng: -91.88649829,
  },
  {
    GEO_ID: '0500000US37015',
    STATE: '37',
    COUNTY: '015',
    NAME: 'Bertie',
    LSAD: 'County',
    CENSUSAREA: 699.27,
    FIPS: 'NC015',
    fips: '37015',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 36.0658315,
    lng: -76.9766892,
  },
  {
    GEO_ID: '0500000US37023',
    STATE: '37',
    COUNTY: '023',
    NAME: 'Burke',
    LSAD: 'County',
    CENSUSAREA: 507.099,
    FIPS: 'NC023',
    fips: '37023',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 35.74914733,
    lng: -81.70505869,
  },
  {
    GEO_ID: '0500000US37029',
    STATE: '37',
    COUNTY: '029',
    NAME: 'Camden',
    LSAD: 'County',
    CENSUSAREA: 240.556,
    FIPS: 'NC029',
    fips: '37029',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 36.38675278,
    lng: -76.20583468,
  },
  {
    GEO_ID: '0500000US37031',
    STATE: '37',
    COUNTY: '031',
    NAME: 'Carteret',
    LSAD: 'County',
    CENSUSAREA: 506.251,
    FIPS: 'NC031',
    fips: '37031',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 34.8275267,
    lng: -76.65336214,
  },
  {
    GEO_ID: '0500000US29133',
    STATE: '29',
    COUNTY: '133',
    NAME: 'Mississippi',
    LSAD: 'County',
    CENSUSAREA: 411.583,
    FIPS: 'MO133',
    fips: '29133',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 36.82805484,
    lng: -89.29117001,
  },
  {
    GEO_ID: '0500000US29143',
    STATE: '29',
    COUNTY: '143',
    NAME: 'New Madrid',
    LSAD: 'County',
    CENSUSAREA: 674.84,
    FIPS: 'MO143',
    fips: '29143',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 36.59439853,
    lng: -89.65190483,
  },
  {
    GEO_ID: '0500000US29149',
    STATE: '29',
    COUNTY: '149',
    NAME: 'Oregon',
    LSAD: 'County',
    CENSUSAREA: 789.796,
    FIPS: 'MO149',
    fips: '29149',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 36.68672248,
    lng: -91.40332146,
  },
  {
    GEO_ID: '0500000US29155',
    STATE: '29',
    COUNTY: '155',
    NAME: 'Pemiscot',
    LSAD: 'County',
    CENSUSAREA: 492.544,
    FIPS: 'MO155',
    fips: '29155',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 36.21131565,
    lng: -89.78539961,
  },
  {
    GEO_ID: '0500000US29165',
    STATE: '29',
    COUNTY: '165',
    NAME: 'Platte',
    LSAD: 'County',
    CENSUSAREA: 420.19,
    FIPS: 'MO165',
    fips: '29165',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 39.38073683,
    lng: -94.77380125,
  },
  {
    GEO_ID: '0500000US37101',
    STATE: '37',
    COUNTY: '101',
    NAME: 'Johnston',
    LSAD: 'County',
    CENSUSAREA: 791.299,
    FIPS: 'NC101',
    fips: '37101',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 35.51761602,
    lng: -78.36537336,
  },
  {
    GEO_ID: '0500000US37107',
    STATE: '37',
    COUNTY: '107',
    NAME: 'Lenoir',
    LSAD: 'County',
    CENSUSAREA: 400.591,
    FIPS: 'NC107',
    fips: '37107',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 35.23892735,
    lng: -77.64152691,
  },
  {
    GEO_ID: '0500000US37113',
    STATE: '37',
    COUNTY: '113',
    NAME: 'Macon',
    LSAD: 'County',
    CENSUSAREA: 515.558,
    FIPS: 'NC113',
    fips: '37113',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 35.15057533,
    lng: -83.42232408,
  },
  {
    GEO_ID: '0500000US37119',
    STATE: '37',
    COUNTY: '119',
    NAME: 'Mecklenburg',
    LSAD: 'County',
    CENSUSAREA: 523.842,
    FIPS: 'NC119',
    fips: '37119',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 35.24675454,
    lng: -80.83268027,
  },
  {
    GEO_ID: '0500000US37125',
    STATE: '37',
    COUNTY: '125',
    NAME: 'Moore',
    LSAD: 'County',
    CENSUSAREA: 697.843,
    FIPS: 'NC125',
    fips: '37125',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 35.31080558,
    lng: -79.48151986,
  },
  {
    GEO_ID: '0500000US40001',
    STATE: '40',
    COUNTY: '001',
    NAME: 'Adair',
    LSAD: 'County',
    CENSUSAREA: 573.477,
    FIPS: 'OK001',
    fips: '40001',
    StateAbbr: 'OK',
    StateName: 'Oklahoma',
    lat: 35.88406262,
    lng: -94.65863245,
  },
  {
    GEO_ID: '0500000US40007',
    STATE: '40',
    COUNTY: '007',
    NAME: 'Beaver',
    LSAD: 'County',
    CENSUSAREA: 1814.672,
    FIPS: 'OK007',
    fips: '40007',
    StateAbbr: 'OK',
    StateName: 'Oklahoma',
    lat: 36.74966893,
    lng: -100.4765456,
  },
  {
    GEO_ID: '0500000US40021',
    STATE: '40',
    COUNTY: '021',
    NAME: 'Cherokee',
    LSAD: 'County',
    CENSUSAREA: 749.408,
    FIPS: 'OK021',
    fips: '40021',
    StateAbbr: 'OK',
    StateName: 'Oklahoma',
    lat: 35.90664273,
    lng: -94.99948708,
  },
  {
    GEO_ID: '0500000US40023',
    STATE: '40',
    COUNTY: '023',
    NAME: 'Choctaw',
    LSAD: 'County',
    CENSUSAREA: 770.357,
    FIPS: 'OK023',
    fips: '40023',
    StateAbbr: 'OK',
    StateName: 'Oklahoma',
    lat: 34.0255707,
    lng: -95.55375412,
  },
  {
    GEO_ID: '0500000US40033',
    STATE: '40',
    COUNTY: '033',
    NAME: 'Cotton',
    LSAD: 'County',
    CENSUSAREA: 632.654,
    FIPS: 'OK033',
    fips: '40033',
    StateAbbr: 'OK',
    StateName: 'Oklahoma',
    lat: 34.2900592,
    lng: -98.37225719,
  },
  {
    GEO_ID: '0500000US40041',
    STATE: '40',
    COUNTY: '041',
    NAME: 'Delaware',
    LSAD: 'County',
    CENSUSAREA: 738.177,
    FIPS: 'OK041',
    fips: '40041',
    StateAbbr: 'OK',
    StateName: 'Oklahoma',
    lat: 36.4089821,
    lng: -94.80256963,
  },
  {
    GEO_ID: '0500000US40051',
    STATE: '40',
    COUNTY: '051',
    NAME: 'Grady',
    LSAD: 'County',
    CENSUSAREA: 1100.497,
    FIPS: 'OK051',
    fips: '40051',
    StateAbbr: 'OK',
    StateName: 'Oklahoma',
    lat: 35.01633237,
    lng: -97.88360671,
  },
  {
    GEO_ID: '0500000US37189',
    STATE: '37',
    COUNTY: '189',
    NAME: 'Watauga',
    LSAD: 'County',
    CENSUSAREA: 312.556,
    FIPS: 'NC189',
    fips: '37189',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 36.23087329,
    lng: -81.69605652,
  },
  {
    GEO_ID: '0500000US48051',
    STATE: '48',
    COUNTY: '051',
    NAME: 'Burleson',
    LSAD: 'County',
    CENSUSAREA: 659.026,
    FIPS: 'TX051',
    fips: '48051',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 30.49219576,
    lng: -96.62105771,
  },
  {
    GEO_ID: '0500000US35023',
    STATE: '35',
    COUNTY: '023',
    NAME: 'Hidalgo',
    LSAD: 'County',
    CENSUSAREA: 3436.861,
    FIPS: 'NM023',
    fips: '35023',
    StateAbbr: 'NM',
    StateName: 'New Mexico',
    lat: 31.91398466,
    lng: -108.7148057,
  },
  {
    GEO_ID: '0500000US35029',
    STATE: '35',
    COUNTY: '029',
    NAME: 'Luna',
    LSAD: 'County',
    CENSUSAREA: 2965.188,
    FIPS: 'NM029',
    fips: '35029',
    StateAbbr: 'NM',
    StateName: 'New Mexico',
    lat: 32.18233304,
    lng: -107.7498996,
  },
  {
    GEO_ID: '0500000US35031',
    STATE: '35',
    COUNTY: '031',
    NAME: 'McKinley',
    LSAD: 'County',
    CENSUSAREA: 5449.809,
    FIPS: 'NM031',
    fips: '35031',
    StateAbbr: 'NM',
    StateName: 'New Mexico',
    lat: 35.58040415,
    lng: -108.2616781,
  },
  {
    GEO_ID: '0500000US35035',
    STATE: '35',
    COUNTY: '035',
    NAME: 'Otero',
    LSAD: 'County',
    CENSUSAREA: 6613.209,
    FIPS: 'NM035',
    fips: '35035',
    StateAbbr: 'NM',
    StateName: 'New Mexico',
    lat: 32.61323163,
    lng: -105.7417441,
  },
  {
    GEO_ID: '0500000US47153',
    STATE: '47',
    COUNTY: '153',
    NAME: 'Sequatchie',
    LSAD: 'County',
    CENSUSAREA: 265.858,
    FIPS: 'TN153',
    fips: '47153',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 35.37103725,
    lng: -85.4102441,
  },
  {
    GEO_ID: '0500000US47157',
    STATE: '47',
    COUNTY: '157',
    NAME: 'Shelby',
    LSAD: 'County',
    CENSUSAREA: 763.174,
    FIPS: 'TN157',
    fips: '47157',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 35.18412715,
    lng: -89.89620223,
  },
  {
    GEO_ID: '0500000US47163',
    STATE: '47',
    COUNTY: '163',
    NAME: 'Sullivan',
    LSAD: 'County',
    CENSUSAREA: 413.363,
    FIPS: 'TN163',
    fips: '47163',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 36.51291546,
    lng: -82.30419555,
  },
  {
    GEO_ID: '0500000US47171',
    STATE: '47',
    COUNTY: '171',
    NAME: 'Unicoi',
    LSAD: 'County',
    CENSUSAREA: 186.165,
    FIPS: 'TN171',
    fips: '47171',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 36.11077416,
    lng: -82.43228458,
  },
  {
    GEO_ID: '0500000US47181',
    STATE: '47',
    COUNTY: '181',
    NAME: 'Wayne',
    LSAD: 'County',
    CENSUSAREA: 734.1,
    FIPS: 'TN181',
    fips: '47181',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 35.24027258,
    lng: -87.78755065,
  },
  {
    GEO_ID: '0500000US45057',
    STATE: '45',
    COUNTY: '057',
    NAME: 'Lancaster',
    LSAD: 'County',
    CENSUSAREA: 549.16,
    FIPS: 'SC057',
    fips: '45057',
    StateAbbr: 'SC',
    StateName: 'South Carolina',
    lat: 34.68642517,
    lng: -80.7054154,
  },
  {
    GEO_ID: '0500000US36085',
    STATE: '36',
    COUNTY: '085',
    NAME: 'Richmond',
    LSAD: 'County',
    CENSUSAREA: 58.37,
    FIPS: 'NY085',
    fips: '36085',
    StateAbbr: 'NY',
    StateName: 'New York',
    lat: 40.58183643,
    lng: -74.15390987,
  },
  {
    GEO_ID: '0500000US36097',
    STATE: '36',
    COUNTY: '097',
    NAME: 'Schuyler',
    LSAD: 'County',
    CENSUSAREA: 328.333,
    FIPS: 'NY097',
    fips: '36097',
    StateAbbr: 'NY',
    StateName: 'New York',
    lat: 42.39369114,
    lng: -76.8749143,
  },
  {
    GEO_ID: '0500000US36105',
    STATE: '36',
    COUNTY: '105',
    NAME: 'Sullivan',
    LSAD: 'County',
    CENSUSAREA: 968.132,
    FIPS: 'NY105',
    fips: '36105',
    StateAbbr: 'NY',
    StateName: 'New York',
    lat: 41.71624399,
    lng: -74.7681497,
  },
  {
    GEO_ID: '0500000US36111',
    STATE: '36',
    COUNTY: '111',
    NAME: 'Ulster',
    LSAD: 'County',
    CENSUSAREA: 1124.235,
    FIPS: 'NY111',
    fips: '36111',
    StateAbbr: 'NY',
    StateName: 'New York',
    lat: 41.888126,
    lng: -74.25878826,
  },
  {
    GEO_ID: '0500000US36115',
    STATE: '36',
    COUNTY: '115',
    NAME: 'Washington',
    LSAD: 'County',
    CENSUSAREA: 831.184,
    FIPS: 'NY115',
    fips: '36115',
    StateAbbr: 'NY',
    StateName: 'New York',
    lat: 43.31320642,
    lng: -73.430748,
  },
  {
    GEO_ID: '0500000US36119',
    STATE: '36',
    COUNTY: '119',
    NAME: 'Westchester',
    LSAD: 'County',
    CENSUSAREA: 430.497,
    FIPS: 'NY119',
    fips: '36119',
    StateAbbr: 'NY',
    StateName: 'New York',
    lat: 41.16176335,
    lng: -73.75591281,
  },
  {
    GEO_ID: '0500000US45091',
    STATE: '45',
    COUNTY: '091',
    NAME: 'York',
    LSAD: 'County',
    CENSUSAREA: 680.595,
    FIPS: 'SC091',
    fips: '45091',
    StateAbbr: 'SC',
    StateName: 'South Carolina',
    lat: 34.9746458,
    lng: -81.18407852,
  },
  {
    GEO_ID: '0500000US46009',
    STATE: '46',
    COUNTY: '009',
    NAME: 'Bon Homme',
    LSAD: 'County',
    CENSUSAREA: 563.701,
    FIPS: 'SD009',
    fips: '46009',
    StateAbbr: 'SD',
    StateName: 'South Dakota',
    lat: 42.98827986,
    lng: -97.88493814,
  },
  {
    GEO_ID: '0500000US46013',
    STATE: '46',
    COUNTY: '013',
    NAME: 'Brown',
    LSAD: 'County',
    CENSUSAREA: 1712.981,
    FIPS: 'SD013',
    fips: '46013',
    StateAbbr: 'SD',
    StateName: 'South Dakota',
    lat: 45.58993475,
    lng: -98.35163367,
  },
  {
    GEO_ID: '0500000US46021',
    STATE: '46',
    COUNTY: '021',
    NAME: 'Campbell',
    LSAD: 'County',
    CENSUSAREA: 733.677,
    FIPS: 'SD021',
    fips: '46021',
    StateAbbr: 'SD',
    StateName: 'South Dakota',
    lat: 45.77126363,
    lng: -100.0517394,
  },
  {
    GEO_ID: '0500000US29209',
    STATE: '29',
    COUNTY: '209',
    NAME: 'Stone',
    LSAD: 'County',
    CENSUSAREA: 464.033,
    FIPS: 'MO209',
    fips: '29209',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 36.74710976,
    lng: -93.45602697,
  },
  {
    GEO_ID: '0500000US29217',
    STATE: '29',
    COUNTY: '217',
    NAME: 'Vernon',
    LSAD: 'County',
    CENSUSAREA: 826.398,
    FIPS: 'MO217',
    fips: '29217',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 37.84998285,
    lng: -94.34168569,
  },
  {
    GEO_ID: '0500000US29227',
    STATE: '29',
    COUNTY: '227',
    NAME: 'Worth',
    LSAD: 'County',
    CENSUSAREA: 266.613,
    FIPS: 'MO227',
    fips: '29227',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 40.47867651,
    lng: -94.42191277,
  },
  {
    GEO_ID: '0500000US30001',
    STATE: '30',
    COUNTY: '001',
    NAME: 'Beaverhead',
    LSAD: 'County',
    CENSUSAREA: 5541.624,
    FIPS: 'MT001',
    fips: '30001',
    StateAbbr: 'MT',
    StateName: 'Montana',
    lat: 45.13261641,
    lng: -112.899016,
  },
  {
    GEO_ID: '0500000US37171',
    STATE: '37',
    COUNTY: '171',
    NAME: 'Surry',
    LSAD: 'County',
    CENSUSAREA: 532.166,
    FIPS: 'NC171',
    fips: '37171',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 36.41459623,
    lng: -80.68725838,
  },
  {
    GEO_ID: '0500000US37177',
    STATE: '37',
    COUNTY: '177',
    NAME: 'Tyrrell',
    LSAD: 'County',
    CENSUSAREA: 389.035,
    FIPS: 'NC177',
    fips: '37177',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 35.81805644,
    lng: -76.20702207,
  },
  {
    GEO_ID: '0500000US39067',
    STATE: '39',
    COUNTY: '067',
    NAME: 'Harrison',
    LSAD: 'County',
    CENSUSAREA: 402.339,
    FIPS: 'OH067',
    fips: '39067',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 40.29356193,
    lng: -81.09149874,
  },
  {
    GEO_ID: '0500000US39073',
    STATE: '39',
    COUNTY: '073',
    NAME: 'Hocking',
    LSAD: 'County',
    CENSUSAREA: 421.323,
    FIPS: 'OH073',
    fips: '39073',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 39.49712923,
    lng: -82.47907934,
  },
  {
    GEO_ID: '0500000US39079',
    STATE: '39',
    COUNTY: '079',
    NAME: 'Jackson',
    LSAD: 'County',
    CENSUSAREA: 420.304,
    FIPS: 'OH079',
    fips: '39079',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 39.01971543,
    lng: -82.61832518,
  },
  {
    GEO_ID: '0500000US48057',
    STATE: '48',
    COUNTY: '057',
    NAME: 'Calhoun',
    LSAD: 'County',
    CENSUSAREA: 506.84,
    FIPS: 'TX057',
    fips: '48057',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 28.50349599,
    lng: -96.60474134,
  },
  {
    GEO_ID: '0500000US48071',
    STATE: '48',
    COUNTY: '071',
    NAME: 'Chambers',
    LSAD: 'County',
    CENSUSAREA: 597.137,
    FIPS: 'TX071',
    fips: '48071',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 29.73846857,
    lng: -94.60850621,
  },
  {
    GEO_ID: '0500000US48093',
    STATE: '48',
    COUNTY: '093',
    NAME: 'Comanche',
    LSAD: 'County',
    CENSUSAREA: 937.754,
    FIPS: 'TX093',
    fips: '48093',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 31.94854859,
    lng: -98.55874953,
  },
  {
    GEO_ID: '0500000US48097',
    STATE: '48',
    COUNTY: '097',
    NAME: 'Cooke',
    LSAD: 'County',
    CENSUSAREA: 874.759,
    FIPS: 'TX097',
    fips: '48097',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 33.63940669,
    lng: -97.21276751,
  },
  {
    GEO_ID: '0500000US24510',
    STATE: '24',
    COUNTY: '510',
    NAME: 'Baltimore',
    LSAD: 'city',
    CENSUSAREA: 80.944,
    FIPS: 'MD510',
    fips: '24510',
    StateAbbr: 'MD',
    StateName: 'Maryland',
    lat: 39.30522045,
    lng: -76.61440436,
  },
  {
    GEO_ID: '0500000US45063',
    STATE: '45',
    COUNTY: '063',
    NAME: 'Lexington',
    LSAD: 'County',
    CENSUSAREA: 698.913,
    FIPS: 'SC063',
    fips: '45063',
    StateAbbr: 'SC',
    StateName: 'South Carolina',
    lat: 33.90217281,
    lng: -81.27229853,
  },
  {
    GEO_ID: '0500000US45067',
    STATE: '45',
    COUNTY: '067',
    NAME: 'Marion',
    LSAD: 'County',
    CENSUSAREA: 489.227,
    FIPS: 'SC067',
    fips: '45067',
    StateAbbr: 'SC',
    StateName: 'South Carolina',
    lat: 34.08038363,
    lng: -79.36252998,
  },
  {
    GEO_ID: '0500000US45077',
    STATE: '45',
    COUNTY: '077',
    NAME: 'Pickens',
    LSAD: 'County',
    CENSUSAREA: 496.407,
    FIPS: 'SC077',
    fips: '45077',
    StateAbbr: 'SC',
    StateName: 'South Carolina',
    lat: 34.88795956,
    lng: -82.72522047,
  },
  {
    GEO_ID: '0500000US26053',
    STATE: '26',
    COUNTY: '053',
    NAME: 'Gogebic',
    LSAD: 'County',
    CENSUSAREA: 1101.849,
    FIPS: 'MI053',
    fips: '26053',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 46.40885281,
    lng: -89.69437271,
  },
  {
    GEO_ID: '0500000US46027',
    STATE: '46',
    COUNTY: '027',
    NAME: 'Clay',
    LSAD: 'County',
    CENSUSAREA: 412.185,
    FIPS: 'SD027',
    fips: '46027',
    StateAbbr: 'SD',
    StateName: 'South Dakota',
    lat: 42.91461976,
    lng: -96.97560307,
  },
  {
    GEO_ID: '0500000US46031',
    STATE: '46',
    COUNTY: '031',
    NAME: 'Corson',
    LSAD: 'County',
    CENSUSAREA: 2469.692,
    FIPS: 'SD031',
    fips: '46031',
    StateAbbr: 'SD',
    StateName: 'South Dakota',
    lat: 45.7085132,
    lng: -101.1967817,
  },
  {
    GEO_ID: '0500000US46047',
    STATE: '46',
    COUNTY: '047',
    NAME: 'Fall River',
    LSAD: 'County',
    CENSUSAREA: 1739.915,
    FIPS: 'SD047',
    fips: '46047',
    StateAbbr: 'SD',
    StateName: 'South Dakota',
    lat: 43.23917478,
    lng: -103.5274481,
  },
  {
    GEO_ID: '0500000US46053',
    STATE: '46',
    COUNTY: '053',
    NAME: 'Gregory',
    LSAD: 'County',
    CENSUSAREA: 1014.957,
    FIPS: 'SD053',
    fips: '46053',
    StateAbbr: 'SD',
    StateName: 'South Dakota',
    lat: 43.19247936,
    lng: -99.18590693,
  },
  {
    GEO_ID: '0500000US27007',
    STATE: '27',
    COUNTY: '007',
    NAME: 'Beltrami',
    LSAD: 'County',
    CENSUSAREA: 2504.94,
    FIPS: 'MN007',
    fips: '27007',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 47.97364569,
    lng: -94.93765473,
  },
  {
    GEO_ID: '0500000US27015',
    STATE: '27',
    COUNTY: '015',
    NAME: 'Brown',
    LSAD: 'County',
    CENSUSAREA: 611.09,
    FIPS: 'MN015',
    fips: '27015',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 44.24201356,
    lng: -94.727558,
  },
  {
    GEO_ID: '0500000US27021',
    STATE: '27',
    COUNTY: '021',
    NAME: 'Cass',
    LSAD: 'County',
    CENSUSAREA: 2021.542,
    FIPS: 'MN021',
    fips: '27021',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 46.94877115,
    lng: -94.32573065,
  },
  {
    GEO_ID: '0500000US27027',
    STATE: '27',
    COUNTY: '027',
    NAME: 'Clay',
    LSAD: 'County',
    CENSUSAREA: 1045.366,
    FIPS: 'MN027',
    fips: '27027',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 46.89214202,
    lng: -96.49066248,
  },
  {
    GEO_ID: '0500000US46123',
    STATE: '46',
    COUNTY: '123',
    NAME: 'Tripp',
    LSAD: 'County',
    CENSUSAREA: 1612.446,
    FIPS: 'SD123',
    fips: '46123',
    StateAbbr: 'SD',
    StateName: 'South Dakota',
    lat: 43.34592545,
    lng: -99.88408568,
  },
  {
    GEO_ID: '0500000US46135',
    STATE: '46',
    COUNTY: '135',
    NAME: 'Yankton',
    LSAD: 'County',
    CENSUSAREA: 521.164,
    FIPS: 'SD135',
    fips: '46135',
    StateAbbr: 'SD',
    StateName: 'South Dakota',
    lat: 43.00895493,
    lng: -97.39494039,
  },
  {
    GEO_ID: '0500000US30011',
    STATE: '30',
    COUNTY: '011',
    NAME: 'Carter',
    LSAD: 'County',
    CENSUSAREA: 3340.752,
    FIPS: 'MT011',
    fips: '30011',
    StateAbbr: 'MT',
    StateName: 'Montana',
    lat: 45.51700775,
    lng: -104.5359622,
  },
  {
    GEO_ID: '0500000US30017',
    STATE: '30',
    COUNTY: '017',
    NAME: 'Custer',
    LSAD: 'County',
    CENSUSAREA: 3783.362,
    FIPS: 'MT017',
    fips: '30017',
    StateAbbr: 'MT',
    StateName: 'Montana',
    lat: 46.25264388,
    lng: -105.5718948,
  },
  {
    GEO_ID: '0500000US30025',
    STATE: '30',
    COUNTY: '025',
    NAME: 'Fallon',
    LSAD: 'County',
    CENSUSAREA: 1620.772,
    FIPS: 'MT025',
    fips: '30025',
    StateAbbr: 'MT',
    StateName: 'Montana',
    lat: 46.3343054,
    lng: -104.417515,
  },
  {
    GEO_ID: '0500000US30027',
    STATE: '30',
    COUNTY: '027',
    NAME: 'Fergus',
    LSAD: 'County',
    CENSUSAREA: 4339.803,
    FIPS: 'MT027',
    fips: '30027',
    StateAbbr: 'MT',
    StateName: 'Montana',
    lat: 47.2633104,
    lng: -109.224376,
  },
  {
    GEO_ID: '0500000US30033',
    STATE: '30',
    COUNTY: '033',
    NAME: 'Garfield',
    LSAD: 'County',
    CENSUSAREA: 4675.356,
    FIPS: 'MT033',
    fips: '30033',
    StateAbbr: 'MT',
    StateName: 'Montana',
    lat: 47.27767106,
    lng: -106.9928862,
  },
  {
    GEO_ID: '0500000US30039',
    STATE: '30',
    COUNTY: '039',
    NAME: 'Granite',
    LSAD: 'County',
    CENSUSAREA: 1727.415,
    FIPS: 'MT039',
    fips: '30039',
    StateAbbr: 'MT',
    StateName: 'Montana',
    lat: 46.40415824,
    lng: -113.4401981,
  },
  {
    GEO_ID: '0500000US30045',
    STATE: '30',
    COUNTY: '045',
    NAME: 'Judith Basin',
    LSAD: 'County',
    CENSUSAREA: 1869.821,
    FIPS: 'MT045',
    fips: '30045',
    StateAbbr: 'MT',
    StateName: 'Montana',
    lat: 47.04516111,
    lng: -110.2659877,
  },
  {
    GEO_ID: '0500000US31131',
    STATE: '31',
    COUNTY: '131',
    NAME: 'Otoe',
    LSAD: 'County',
    CENSUSAREA: 615.631,
    FIPS: 'NE131',
    fips: '31131',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 40.64856103,
    lng: -96.1350641,
  },
  {
    GEO_ID: '0500000US25005',
    STATE: '25',
    COUNTY: '005',
    NAME: 'Bristol',
    LSAD: 'County',
    CENSUSAREA: 553.096,
    FIPS: 'MA005',
    fips: '25005',
    StateAbbr: 'MA',
    StateName: 'Massachusetts',
    lat: 41.79709525,
    lng: -71.11419448,
  },
  {
    GEO_ID: '0500000US25009',
    STATE: '25',
    COUNTY: '009',
    NAME: 'Essex',
    LSAD: 'County',
    CENSUSAREA: 492.563,
    FIPS: 'MA009',
    fips: '25009',
    StateAbbr: 'MA',
    StateName: 'Massachusetts',
    lat: 42.67174524,
    lng: -70.9515844,
  },
  {
    GEO_ID: '0500000US25019',
    STATE: '25',
    COUNTY: '019',
    NAME: 'Nantucket',
    LSAD: 'County',
    CENSUSAREA: 44.97,
    FIPS: 'MA019',
    fips: '25019',
    StateAbbr: 'MA',
    StateName: 'Massachusetts',
    lat: 41.28422779,
    lng: -70.07344074,
  },
  {
    GEO_ID: '0500000US34023',
    STATE: '34',
    COUNTY: '023',
    NAME: 'Middlesex',
    LSAD: 'County',
    CENSUSAREA: 308.914,
    FIPS: 'NJ023',
    fips: '34023',
    StateAbbr: 'NJ',
    StateName: 'New Jersey',
    lat: 40.43949114,
    lng: -74.41108832,
  },
  {
    GEO_ID: '0500000US26055',
    STATE: '26',
    COUNTY: '055',
    NAME: 'Grand Traverse',
    LSAD: 'County',
    CENSUSAREA: 464.331,
    FIPS: 'MI055',
    fips: '26055',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 44.66916098,
    lng: -85.56013503,
  },
  {
    GEO_ID: '0500000US31091',
    STATE: '31',
    COUNTY: '091',
    NAME: 'Hooker',
    LSAD: 'County',
    CENSUSAREA: 721.121,
    FIPS: 'NE091',
    fips: '31091',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 41.91586243,
    lng: -101.1354293,
  },
  {
    GEO_ID: '0500000US31095',
    STATE: '31',
    COUNTY: '095',
    NAME: 'Jefferson',
    LSAD: 'County',
    CENSUSAREA: 570.185,
    FIPS: 'NE095',
    fips: '31095',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 40.17580816,
    lng: -97.14261379,
  },
  {
    GEO_ID: '0500000US31103',
    STATE: '31',
    COUNTY: '103',
    NAME: 'Keya Paha',
    LSAD: 'County',
    CENSUSAREA: 773.072,
    FIPS: 'NE103',
    fips: '31103',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 42.87883323,
    lng: -99.71213247,
  },
  {
    GEO_ID: '0500000US31111',
    STATE: '31',
    COUNTY: '111',
    NAME: 'Lincoln',
    LSAD: 'County',
    CENSUSAREA: 2564.068,
    FIPS: 'NE111',
    fips: '31111',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 41.04750134,
    lng: -100.7452801,
  },
  {
    GEO_ID: '0500000US28089',
    STATE: '28',
    COUNTY: '089',
    NAME: 'Madison',
    LSAD: 'County',
    CENSUSAREA: 714.506,
    FIPS: 'MS089',
    fips: '28089',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 32.63533895,
    lng: -90.03327096,
  },
  {
    GEO_ID: '0500000US28093',
    STATE: '28',
    COUNTY: '093',
    NAME: 'Marshall',
    LSAD: 'County',
    CENSUSAREA: 706.193,
    FIPS: 'MS093',
    fips: '28093',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 34.76229047,
    lng: -89.50306303,
  },
  {
    GEO_ID: '0500000US27031',
    STATE: '27',
    COUNTY: '031',
    NAME: 'Cook',
    LSAD: 'County',
    CENSUSAREA: 1452.277,
    FIPS: 'MN031',
    fips: '27031',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 47.90304185,
    lng: -90.53150846,
  },
  {
    GEO_ID: '0500000US27049',
    STATE: '27',
    COUNTY: '049',
    NAME: 'Goodhue',
    LSAD: 'County',
    CENSUSAREA: 756.838,
    FIPS: 'MN049',
    fips: '27049',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 44.40972913,
    lng: -92.72266089,
  },
  {
    GEO_ID: '0500000US27055',
    STATE: '27',
    COUNTY: '055',
    NAME: 'Houston',
    LSAD: 'County',
    CENSUSAREA: 552.058,
    FIPS: 'MN055',
    fips: '27055',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 43.67146528,
    lng: -91.49277553,
  },
  {
    GEO_ID: '0500000US37049',
    STATE: '37',
    COUNTY: '049',
    NAME: 'Craven',
    LSAD: 'County',
    CENSUSAREA: 708.958,
    FIPS: 'NC049',
    fips: '37049',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 35.12343918,
    lng: -77.09255683,
  },
  {
    GEO_ID: '0500000US37051',
    STATE: '37',
    COUNTY: '051',
    NAME: 'Cumberland',
    LSAD: 'County',
    CENSUSAREA: 652.315,
    FIPS: 'NC051',
    fips: '37051',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 35.04887721,
    lng: -78.82718356,
  },
  {
    GEO_ID: '0500000US37131',
    STATE: '37',
    COUNTY: '131',
    NAME: 'Northampton',
    LSAD: 'County',
    CENSUSAREA: 536.589,
    FIPS: 'NC131',
    fips: '37131',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 36.41780769,
    lng: -77.39657442,
  },
  {
    GEO_ID: '0500000US37137',
    STATE: '37',
    COUNTY: '137',
    NAME: 'Pamlico',
    LSAD: 'County',
    CENSUSAREA: 336.537,
    FIPS: 'NC137',
    fips: '37137',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 35.14691784,
    lng: -76.72927678,
  },
  {
    GEO_ID: '0500000US37149',
    STATE: '37',
    COUNTY: '149',
    NAME: 'Polk',
    LSAD: 'County',
    CENSUSAREA: 237.789,
    FIPS: 'NC149',
    fips: '37149',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 35.27959223,
    lng: -82.17008141,
  },
  {
    GEO_ID: '0500000US37155',
    STATE: '37',
    COUNTY: '155',
    NAME: 'Robeson',
    LSAD: 'County',
    CENSUSAREA: 949.221,
    FIPS: 'NC155',
    fips: '37155',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 34.64028878,
    lng: -79.10352623,
  },
  {
    GEO_ID: '0500000US42091',
    STATE: '42',
    COUNTY: '091',
    NAME: 'Montgomery',
    LSAD: 'County',
    CENSUSAREA: 483.04,
    FIPS: 'PA091',
    fips: '42091',
    StateAbbr: 'PA',
    StateName: 'Pennsylvania',
    lat: 40.21085251,
    lng: -75.36722689,
  },
  {
    GEO_ID: '0500000US42097',
    STATE: '42',
    COUNTY: '097',
    NAME: 'Northumberland',
    LSAD: 'County',
    CENSUSAREA: 458.368,
    FIPS: 'PA097',
    fips: '42097',
    StateAbbr: 'PA',
    StateName: 'Pennsylvania',
    lat: 40.85142483,
    lng: -76.70893391,
  },
  {
    GEO_ID: '0500000US42103',
    STATE: '42',
    COUNTY: '103',
    NAME: 'Pike',
    LSAD: 'County',
    CENSUSAREA: 544.961,
    FIPS: 'PA103',
    fips: '42103',
    StateAbbr: 'PA',
    StateName: 'Pennsylvania',
    lat: 41.33189163,
    lng: -75.03337176,
  },
  {
    GEO_ID: '0500000US34025',
    STATE: '34',
    COUNTY: '025',
    NAME: 'Monmouth',
    LSAD: 'County',
    CENSUSAREA: 468.793,
    FIPS: 'NJ025',
    fips: '34025',
    StateAbbr: 'NJ',
    StateName: 'New Jersey',
    lat: 40.2606812,
    lng: -74.22051128,
  },
  {
    GEO_ID: '0500000US34039',
    STATE: '34',
    COUNTY: '039',
    NAME: 'Union',
    LSAD: 'County',
    CENSUSAREA: 102.855,
    FIPS: 'NJ039',
    fips: '34039',
    StateAbbr: 'NJ',
    StateName: 'New Jersey',
    lat: 40.65928966,
    lng: -74.30721513,
  },
  {
    GEO_ID: '0500000US35003',
    STATE: '35',
    COUNTY: '003',
    NAME: 'Catron',
    LSAD: 'County',
    CENSUSAREA: 6923.69,
    FIPS: 'NM003',
    fips: '35003',
    StateAbbr: 'NM',
    StateName: 'New Mexico',
    lat: 33.91502948,
    lng: -108.4054839,
  },
  {
    GEO_ID: '0500000US35005',
    STATE: '35',
    COUNTY: '005',
    NAME: 'Chaves',
    LSAD: 'County',
    CENSUSAREA: 6065.275,
    FIPS: 'NM005',
    fips: '35005',
    StateAbbr: 'NM',
    StateName: 'New Mexico',
    lat: 33.36323485,
    lng: -104.4668372,
  },
  {
    GEO_ID: '0500000US35009',
    STATE: '35',
    COUNTY: '009',
    NAME: 'Curry',
    LSAD: 'County',
    CENSUSAREA: 1404.797,
    FIPS: 'NM009',
    fips: '35009',
    StateAbbr: 'NM',
    StateName: 'New Mexico',
    lat: 34.57414777,
    lng: -103.3468992,
  },
  {
    GEO_ID: '0500000US30061',
    STATE: '30',
    COUNTY: '061',
    NAME: 'Mineral',
    LSAD: 'County',
    CENSUSAREA: 1219.437,
    FIPS: 'MT061',
    fips: '30061',
    StateAbbr: 'MT',
    StateName: 'Montana',
    lat: 47.1472918,
    lng: -114.9981923,
  },
  {
    GEO_ID: '0500000US38001',
    STATE: '38',
    COUNTY: '001',
    NAME: 'Adams',
    LSAD: 'County',
    CENSUSAREA: 987.619,
    FIPS: 'ND001',
    fips: '38001',
    StateAbbr: 'ND',
    StateName: 'North Dakota',
    lat: 46.09690164,
    lng: -102.5285391,
  },
  {
    GEO_ID: '0500000US09011',
    STATE: '09',
    COUNTY: '011',
    NAME: 'New London',
    LSAD: 'County',
    CENSUSAREA: 664.878,
    FIPS: 'CT011',
    fips: '09011',
    StateAbbr: 'CT',
    StateName: 'Connecticut',
    lat: 41.48708483,
    lng: -72.10232387,
  },
  {
    GEO_ID: '0500000US10001',
    STATE: '10',
    COUNTY: '001',
    NAME: 'Kent',
    LSAD: 'County',
    CENSUSAREA: 586.179,
    FIPS: 'DE001',
    fips: '10001',
    StateAbbr: 'DE',
    StateName: 'Delaware',
    lat: 39.08639126,
    lng: -75.56844621,
  },
  {
    GEO_ID: '0500000US10003',
    STATE: '10',
    COUNTY: '003',
    NAME: 'New Castle',
    LSAD: 'County',
    CENSUSAREA: 426.286,
    FIPS: 'DE003',
    fips: '10003',
    StateAbbr: 'DE',
    StateName: 'Delaware',
    lat: 39.58150592,
    lng: -75.64791441,
  },
  {
    GEO_ID: '0500000US12009',
    STATE: '12',
    COUNTY: '009',
    NAME: 'Brevard',
    LSAD: 'County',
    CENSUSAREA: 1015.664,
    FIPS: 'FL009',
    fips: '12009',
    StateAbbr: 'FL',
    StateName: 'Florida',
    lat: 28.29324349,
    lng: -80.73218698,
  },
  {
    GEO_ID: '0500000US12075',
    STATE: '12',
    COUNTY: '075',
    NAME: 'Levy',
    LSAD: 'County',
    CENSUSAREA: 1118.206,
    FIPS: 'FL075',
    fips: '12075',
    StateAbbr: 'FL',
    StateName: 'Florida',
    lat: 29.31952768,
    lng: -82.74249558,
  },
  {
    GEO_ID: '0500000US05031',
    STATE: '05',
    COUNTY: '031',
    NAME: 'Craighead',
    LSAD: 'County',
    CENSUSAREA: 707.206,
    FIPS: 'AR031',
    fips: '05031',
    StateAbbr: 'AR',
    StateName: 'Arkansas',
    lat: 35.8310138,
    lng: -90.63270647,
  },
  {
    GEO_ID: '0500000US05035',
    STATE: '05',
    COUNTY: '035',
    NAME: 'Crittenden',
    LSAD: 'County',
    CENSUSAREA: 609.76,
    FIPS: 'AR035',
    fips: '05035',
    StateAbbr: 'AR',
    StateName: 'Arkansas',
    lat: 35.20762172,
    lng: -90.30924635,
  },
  {
    GEO_ID: '0500000US05041',
    STATE: '05',
    COUNTY: '041',
    NAME: 'Desha',
    LSAD: 'County',
    CENSUSAREA: 768.152,
    FIPS: 'AR041',
    fips: '05041',
    StateAbbr: 'AR',
    StateName: 'Arkansas',
    lat: 33.83346926,
    lng: -91.2539449,
  },
  {
    GEO_ID: '0500000US05057',
    STATE: '05',
    COUNTY: '057',
    NAME: 'Hempstead',
    LSAD: 'County',
    CENSUSAREA: 727.52,
    FIPS: 'AR057',
    fips: '05057',
    StateAbbr: 'AR',
    StateName: 'Arkansas',
    lat: 33.73564268,
    lng: -93.66809039,
  },
  {
    GEO_ID: '0500000US05063',
    STATE: '05',
    COUNTY: '063',
    NAME: 'Independence',
    LSAD: 'County',
    CENSUSAREA: 763.953,
    FIPS: 'AR063',
    fips: '05063',
    StateAbbr: 'AR',
    StateName: 'Arkansas',
    lat: 35.74143554,
    lng: -91.56991638,
  },
  {
    GEO_ID: '0500000US05069',
    STATE: '05',
    COUNTY: '069',
    NAME: 'Jefferson',
    LSAD: 'County',
    CENSUSAREA: 870.746,
    FIPS: 'AR069',
    fips: '05069',
    StateAbbr: 'AR',
    StateName: 'Arkansas',
    lat: 34.26882203,
    lng: -91.93189159,
  },
  {
    GEO_ID: '0500000US05075',
    STATE: '05',
    COUNTY: '075',
    NAME: 'Lawrence',
    LSAD: 'County',
    CENSUSAREA: 587.614,
    FIPS: 'AR075',
    fips: '05075',
    StateAbbr: 'AR',
    StateName: 'Arkansas',
    lat: 36.04103952,
    lng: -91.1070497,
  },
  {
    GEO_ID: '0500000US05077',
    STATE: '05',
    COUNTY: '077',
    NAME: 'Lee',
    LSAD: 'County',
    CENSUSAREA: 602.621,
    FIPS: 'AR077',
    fips: '05077',
    StateAbbr: 'AR',
    StateName: 'Arkansas',
    lat: 34.78064921,
    lng: -90.78211015,
  },
  {
    GEO_ID: '0500000US04025',
    STATE: '04',
    COUNTY: '025',
    NAME: 'Yavapai',
    LSAD: 'County',
    CENSUSAREA: 8123.499,
    FIPS: 'AZ025',
    fips: '04025',
    StateAbbr: 'AZ',
    StateName: 'Arizona',
    lat: 34.59969928,
    lng: -112.5538467,
  },
  {
    GEO_ID: '0500000US05005',
    STATE: '05',
    COUNTY: '005',
    NAME: 'Baxter',
    LSAD: 'County',
    CENSUSAREA: 554.283,
    FIPS: 'AR005',
    fips: '05005',
    StateAbbr: 'AR',
    StateName: 'Arkansas',
    lat: 36.28715062,
    lng: -92.33686558,
  },
  {
    GEO_ID: '0500000US05007',
    STATE: '05',
    COUNTY: '007',
    NAME: 'Benton',
    LSAD: 'County',
    CENSUSAREA: 847.357,
    FIPS: 'AR007',
    fips: '05007',
    StateAbbr: 'AR',
    StateName: 'Arkansas',
    lat: 36.33873798,
    lng: -94.25598718,
  },
  {
    GEO_ID: '0500000US16073',
    STATE: '16',
    COUNTY: '073',
    NAME: 'Owyhee',
    LSAD: 'County',
    CENSUSAREA: 7665.511,
    FIPS: 'ID073',
    fips: '16073',
    StateAbbr: 'ID',
    StateName: 'Idaho',
    lat: 42.58161924,
    lng: -116.1698327,
  },
  {
    GEO_ID: '0500000US16085',
    STATE: '16',
    COUNTY: '085',
    NAME: 'Valley',
    LSAD: 'County',
    CENSUSAREA: 3664.515,
    FIPS: 'ID085',
    fips: '16085',
    StateAbbr: 'ID',
    StateName: 'Idaho',
    lat: 44.76650867,
    lng: -115.5662454,
  },
  {
    GEO_ID: '0500000US16087',
    STATE: '16',
    COUNTY: '087',
    NAME: 'Washington',
    LSAD: 'County',
    CENSUSAREA: 1452.982,
    FIPS: 'ID087',
    fips: '16087',
    StateAbbr: 'ID',
    StateName: 'Idaho',
    lat: 44.45250794,
    lng: -116.7847747,
  },
  {
    GEO_ID: '0500000US17013',
    STATE: '17',
    COUNTY: '013',
    NAME: 'Calhoun',
    LSAD: 'County',
    CENSUSAREA: 253.825,
    FIPS: 'IL013',
    fips: '17013',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 39.16895789,
    lng: -90.66743491,
  },
  {
    GEO_ID: '0500000US17021',
    STATE: '17',
    COUNTY: '021',
    NAME: 'Christian',
    LSAD: 'County',
    CENSUSAREA: 709.377,
    FIPS: 'IL021',
    fips: '17021',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 39.54582799,
    lng: -89.27760111,
  },
  {
    GEO_ID: '0500000US17027',
    STATE: '17',
    COUNTY: '027',
    NAME: 'Clinton',
    LSAD: 'County',
    CENSUSAREA: 474.085,
    FIPS: 'IL027',
    fips: '17027',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 38.60632268,
    lng: -89.4229686,
  },
  {
    GEO_ID: '0500000US16031',
    STATE: '16',
    COUNTY: '031',
    NAME: 'Cassia',
    LSAD: 'County',
    CENSUSAREA: 2565.081,
    FIPS: 'ID031',
    fips: '16031',
    StateAbbr: 'ID',
    StateName: 'Idaho',
    lat: 42.28381567,
    lng: -113.6013217,
  },
  {
    GEO_ID: '0500000US16035',
    STATE: '16',
    COUNTY: '035',
    NAME: 'Clearwater',
    LSAD: 'County',
    CENSUSAREA: 2457.274,
    FIPS: 'ID035',
    fips: '16035',
    StateAbbr: 'ID',
    StateName: 'Idaho',
    lat: 46.67349275,
    lng: -115.656021,
  },
  {
    GEO_ID: '0500000US16037',
    STATE: '16',
    COUNTY: '037',
    NAME: 'Custer',
    LSAD: 'County',
    CENSUSAREA: 4920.94,
    FIPS: 'ID037',
    fips: '16037',
    StateAbbr: 'ID',
    StateName: 'Idaho',
    lat: 44.24147306,
    lng: -114.2816427,
  },
  {
    GEO_ID: '0500000US16049',
    STATE: '16',
    COUNTY: '049',
    NAME: 'Idaho',
    LSAD: 'County',
    CENSUSAREA: 8477.352,
    FIPS: 'ID049',
    fips: '16049',
    StateAbbr: 'ID',
    StateName: 'Idaho',
    lat: 45.84388002,
    lng: -115.4671964,
  },
  {
    GEO_ID: '0500000US16059',
    STATE: '16',
    COUNTY: '059',
    NAME: 'Lemhi',
    LSAD: 'County',
    CENSUSAREA: 4563.387,
    FIPS: 'ID059',
    fips: '16059',
    StateAbbr: 'ID',
    StateName: 'Idaho',
    lat: 44.9436279,
    lng: -113.9335998,
  },
  {
    GEO_ID: '0500000US18023',
    STATE: '18',
    COUNTY: '023',
    NAME: 'Clinton',
    LSAD: 'County',
    CENSUSAREA: 405.07,
    FIPS: 'IN023',
    fips: '18023',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 40.30214562,
    lng: -86.475326,
  },
  {
    GEO_ID: '0500000US02282',
    STATE: '02',
    COUNTY: '282',
    NAME: 'Yakutat',
    LSAD: 'Cty&Bor',
    CENSUSAREA: 7649.457,
    FIPS: 'AK282',
    fips: '02282',
    StateAbbr: 'AK',
    StateName: 'Alaska',
    lat: 59.88824845,
    lng: -140.349186,
  },
  {
    GEO_ID: '0500000US12021',
    STATE: '12',
    COUNTY: '021',
    NAME: 'Collier',
    LSAD: 'County',
    CENSUSAREA: 1998.324,
    FIPS: 'FL021',
    fips: '12021',
    StateAbbr: 'FL',
    StateName: 'Florida',
    lat: 26.11069361,
    lng: -81.34769003,
  },
  {
    GEO_ID: '0500000US12033',
    STATE: '12',
    COUNTY: '033',
    NAME: 'Escambia',
    LSAD: 'County',
    CENSUSAREA: 656.461,
    FIPS: 'FL033',
    fips: '12033',
    StateAbbr: 'FL',
    StateName: 'Florida',
    lat: 30.66830369,
    lng: -87.36273986,
  },
  {
    GEO_ID: '0500000US15003',
    STATE: '15',
    COUNTY: '003',
    NAME: 'Honolulu',
    LSAD: 'County',
    CENSUSAREA: 600.743,
    FIPS: 'HI003',
    fips: '15003',
    StateAbbr: 'HI',
    StateName: 'Hawaii',
    lat: 21.45879706,
    lng: -157.9746934,
  },
  {
    GEO_ID: '0500000US16007',
    STATE: '16',
    COUNTY: '007',
    NAME: 'Bear Lake',
    LSAD: 'County',
    CENSUSAREA: 974.785,
    FIPS: 'ID007',
    fips: '16007',
    StateAbbr: 'ID',
    StateName: 'Idaho',
    lat: 42.28476453,
    lng: -111.3294847,
  },
  {
    GEO_ID: '0500000US16011',
    STATE: '16',
    COUNTY: '011',
    NAME: 'Bingham',
    LSAD: 'County',
    CENSUSAREA: 2093.978,
    FIPS: 'ID011',
    fips: '16011',
    StateAbbr: 'ID',
    StateName: 'Idaho',
    lat: 43.21643996,
    lng: -112.3976535,
  },
  {
    GEO_ID: '0500000US16013',
    STATE: '16',
    COUNTY: '013',
    NAME: 'Blaine',
    LSAD: 'County',
    CENSUSAREA: 2643.586,
    FIPS: 'ID013',
    fips: '16013',
    StateAbbr: 'ID',
    StateName: 'Idaho',
    lat: 43.41242641,
    lng: -113.980642,
  },
  {
    GEO_ID: '0500000US05105',
    STATE: '05',
    COUNTY: '105',
    NAME: 'Perry',
    LSAD: 'County',
    CENSUSAREA: 551.402,
    FIPS: 'AR105',
    fips: '05105',
    StateAbbr: 'AR',
    StateName: 'Arkansas',
    lat: 34.947251,
    lng: -92.93150636,
  },
  {
    GEO_ID: '0500000US05107',
    STATE: '05',
    COUNTY: '107',
    NAME: 'Phillips',
    LSAD: 'County',
    CENSUSAREA: 695.663,
    FIPS: 'AR107',
    fips: '05107',
    StateAbbr: 'AR',
    StateName: 'Arkansas',
    lat: 34.42853288,
    lng: -90.84810681,
  },
  {
    GEO_ID: '0500000US05119',
    STATE: '05',
    COUNTY: '119',
    NAME: 'Pulaski',
    LSAD: 'County',
    CENSUSAREA: 759.763,
    FIPS: 'AR119',
    fips: '05119',
    StateAbbr: 'AR',
    StateName: 'Arkansas',
    lat: 34.76993891,
    lng: -92.31191414,
  },
  {
    GEO_ID: '0500000US17031',
    STATE: '17',
    COUNTY: '031',
    NAME: 'Cook',
    LSAD: 'County',
    CENSUSAREA: 945.326,
    FIPS: 'IL031',
    fips: '17031',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 41.84005007,
    lng: -87.81698762,
  },
  {
    GEO_ID: '0500000US18123',
    STATE: '18',
    COUNTY: '123',
    NAME: 'Perry',
    LSAD: 'County',
    CENSUSAREA: 381.727,
    FIPS: 'IN123',
    fips: '18123',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 38.07971276,
    lng: -86.6380062,
  },
  {
    GEO_ID: '0500000US18129',
    STATE: '18',
    COUNTY: '129',
    NAME: 'Posey',
    LSAD: 'County',
    CENSUSAREA: 409.571,
    FIPS: 'IN129',
    fips: '18129',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 38.02193911,
    lng: -87.86874523,
  },
  {
    GEO_ID: '0500000US18143',
    STATE: '18',
    COUNTY: '143',
    NAME: 'Scott',
    LSAD: 'County',
    CENSUSAREA: 190.397,
    FIPS: 'IN143',
    fips: '18143',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 38.68627244,
    lng: -85.74696726,
  },
  {
    GEO_ID: '0500000US18147',
    STATE: '18',
    COUNTY: '147',
    NAME: 'Spencer',
    LSAD: 'County',
    CENSUSAREA: 396.744,
    FIPS: 'IN147',
    fips: '18147',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 38.01429111,
    lng: -87.00784468,
  },
  {
    GEO_ID: '0500000US18155',
    STATE: '18',
    COUNTY: '155',
    NAME: 'Switzerland',
    LSAD: 'County',
    CENSUSAREA: 220.633,
    FIPS: 'IN155',
    fips: '18155',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 38.82631081,
    lng: -85.03658909,
  },
  {
    GEO_ID: '0500000US18163',
    STATE: '18',
    COUNTY: '163',
    NAME: 'Vanderburgh',
    LSAD: 'County',
    CENSUSAREA: 233.475,
    FIPS: 'IN163',
    fips: '18163',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 38.02551429,
    lng: -87.58559945,
  },
  {
    GEO_ID: '0500000US18029',
    STATE: '18',
    COUNTY: '029',
    NAME: 'Dearborn',
    LSAD: 'County',
    CENSUSAREA: 305.034,
    FIPS: 'IN029',
    fips: '18029',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 39.14487647,
    lng: -84.97334479,
  },
  {
    GEO_ID: '0500000US18037',
    STATE: '18',
    COUNTY: '037',
    NAME: 'Dubois',
    LSAD: 'County',
    CENSUSAREA: 427.269,
    FIPS: 'IN037',
    fips: '18037',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 38.36427783,
    lng: -86.87970258,
  },
  {
    GEO_ID: '0500000US18039',
    STATE: '18',
    COUNTY: '039',
    NAME: 'Elkhart',
    LSAD: 'County',
    CENSUSAREA: 463.17,
    FIPS: 'IN039',
    fips: '18039',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 41.59771704,
    lng: -85.85885682,
  },
  {
    GEO_ID: '0500000US13135',
    STATE: '13',
    COUNTY: '135',
    NAME: 'Gwinnett',
    LSAD: 'County',
    CENSUSAREA: 430.383,
    FIPS: 'GA135',
    fips: '13135',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 33.9614268,
    lng: -84.02400746,
  },
  {
    GEO_ID: '0500000US13145',
    STATE: '13',
    COUNTY: '145',
    NAME: 'Harris',
    LSAD: 'County',
    CENSUSAREA: 463.869,
    FIPS: 'GA145',
    fips: '13145',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 32.73613817,
    lng: -84.9082899,
  },
  {
    GEO_ID: '0500000US13151',
    STATE: '13',
    COUNTY: '151',
    NAME: 'Henry',
    LSAD: 'County',
    CENSUSAREA: 322.127,
    FIPS: 'GA151',
    fips: '13151',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 33.45278905,
    lng: -84.15307775,
  },
  {
    GEO_ID: '0500000US13161',
    STATE: '13',
    COUNTY: '161',
    NAME: 'Jeff Davis',
    LSAD: 'County',
    CENSUSAREA: 330.74,
    FIPS: 'GA161',
    fips: '13161',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 31.8053696,
    lng: -82.63685771,
  },
  {
    GEO_ID: '0500000US13167',
    STATE: '13',
    COUNTY: '167',
    NAME: 'Johnson',
    LSAD: 'County',
    CENSUSAREA: 303.01,
    FIPS: 'GA167',
    fips: '13167',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 32.70148912,
    lng: -82.66017474,
  },
  {
    GEO_ID: '0500000US13177',
    STATE: '13',
    COUNTY: '177',
    NAME: 'Lee',
    LSAD: 'County',
    CENSUSAREA: 355.784,
    FIPS: 'GA177',
    fips: '13177',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 31.77942972,
    lng: -84.14092773,
  },
  {
    GEO_ID: '0500000US13179',
    STATE: '13',
    COUNTY: '179',
    NAME: 'Liberty',
    LSAD: 'County',
    CENSUSAREA: 489.801,
    FIPS: 'GA179',
    fips: '13179',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 31.82777126,
    lng: -81.49463778,
  },
  {
    GEO_ID: '0500000US20107',
    STATE: '20',
    COUNTY: '107',
    NAME: 'Linn',
    LSAD: 'County',
    CENSUSAREA: 594.059,
    FIPS: 'KS107',
    fips: '20107',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 38.21238797,
    lng: -94.84299061,
  },
  {
    GEO_ID: '0500000US20117',
    STATE: '20',
    COUNTY: '117',
    NAME: 'Marshall',
    LSAD: 'County',
    CENSUSAREA: 900.183,
    FIPS: 'KS117',
    fips: '20117',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 39.78357355,
    lng: -96.52279918,
  },
  {
    GEO_ID: '0500000US17149',
    STATE: '17',
    COUNTY: '149',
    NAME: 'Pike',
    LSAD: 'County',
    CENSUSAREA: 831.381,
    FIPS: 'IL149',
    fips: '17149',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 39.62260409,
    lng: -90.88665915,
  },
  {
    GEO_ID: '0500000US17157',
    STATE: '17',
    COUNTY: '157',
    NAME: 'Randolph',
    LSAD: 'County',
    CENSUSAREA: 575.5,
    FIPS: 'IL157',
    fips: '17157',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 38.05214985,
    lng: -89.82565532,
  },
  {
    GEO_ID: '0500000US17161',
    STATE: '17',
    COUNTY: '161',
    NAME: 'Rock Island',
    LSAD: 'County',
    CENSUSAREA: 427.636,
    FIPS: 'IL161',
    fips: '17161',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 41.46753509,
    lng: -90.56755378,
  },
  {
    GEO_ID: '0500000US17171',
    STATE: '17',
    COUNTY: '171',
    NAME: 'Scott',
    LSAD: 'County',
    CENSUSAREA: 250.913,
    FIPS: 'IL171',
    fips: '17171',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 39.64403604,
    lng: -90.47455118,
  },
  {
    GEO_ID: '0500000US17173',
    STATE: '17',
    COUNTY: '173',
    NAME: 'Shelby',
    LSAD: 'County',
    CENSUSAREA: 758.523,
    FIPS: 'IL173',
    fips: '17173',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 39.39127226,
    lng: -88.80591091,
  },
  {
    GEO_ID: '0500000US17181',
    STATE: '17',
    COUNTY: '181',
    NAME: 'Union',
    LSAD: 'County',
    CENSUSAREA: 413.459,
    FIPS: 'IL181',
    fips: '17181',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 37.47114082,
    lng: -89.25435617,
  },
  {
    GEO_ID: '0500000US17185',
    STATE: '17',
    COUNTY: '185',
    NAME: 'Wabash',
    LSAD: 'County',
    CENSUSAREA: 223.252,
    FIPS: 'IL185',
    fips: '17185',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 38.44634379,
    lng: -87.84368093,
  },
  {
    GEO_ID: '0500000US17193',
    STATE: '17',
    COUNTY: '193',
    NAME: 'White',
    LSAD: 'County',
    CENSUSAREA: 494.766,
    FIPS: 'IL193',
    fips: '17193',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 38.08714445,
    lng: -88.18016372,
  },
  {
    GEO_ID: '0500000US17201',
    STATE: '17',
    COUNTY: '201',
    NAME: 'Winnebago',
    LSAD: 'County',
    CENSUSAREA: 513.362,
    FIPS: 'IL201',
    fips: '17201',
    StateAbbr: 'IL',
    StateName: 'Illinois',
    lat: 42.33630888,
    lng: -89.16084223,
  },
  {
    GEO_ID: '0500000US13287',
    STATE: '13',
    COUNTY: '287',
    NAME: 'Turner',
    LSAD: 'County',
    CENSUSAREA: 285.393,
    FIPS: 'GA287',
    fips: '13287',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 31.7164981,
    lng: -83.62479675,
  },
  {
    GEO_ID: '0500000US13291',
    STATE: '13',
    COUNTY: '291',
    NAME: 'Union',
    LSAD: 'County',
    CENSUSAREA: 321.926,
    FIPS: 'GA291',
    fips: '13291',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 34.83375049,
    lng: -83.99038243,
  },
  {
    GEO_ID: '0500000US13299',
    STATE: '13',
    COUNTY: '299',
    NAME: 'Ware',
    LSAD: 'County',
    CENSUSAREA: 892.461,
    FIPS: 'GA299',
    fips: '13299',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 31.05375667,
    lng: -82.42364479,
  },
  {
    GEO_ID: '0500000US21105',
    STATE: '21',
    COUNTY: '105',
    NAME: 'Hickman',
    LSAD: 'County',
    CENSUSAREA: 242.274,
    FIPS: 'KY105',
    fips: '21105',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 36.67806433,
    lng: -88.97605071,
  },
  {
    GEO_ID: '0500000US48273',
    STATE: '48',
    COUNTY: '273',
    NAME: 'Kleberg',
    LSAD: 'County',
    CENSUSAREA: 881.308,
    FIPS: 'TX273',
    fips: '48273',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 27.43132443,
    lng: -97.72430027,
  },
  {
    GEO_ID: '0500000US48311',
    STATE: '48',
    COUNTY: '311',
    NAME: 'McMullen',
    LSAD: 'County',
    CENSUSAREA: 1139.426,
    FIPS: 'TX311',
    fips: '48311',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 28.35261097,
    lng: -98.567849,
  },
  {
    GEO_ID: '0500000US48315',
    STATE: '48',
    COUNTY: '315',
    NAME: 'Marion',
    LSAD: 'County',
    CENSUSAREA: 380.88,
    FIPS: 'TX315',
    fips: '48315',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 32.79813996,
    lng: -94.3574341,
  },
  {
    GEO_ID: '0500000US48321',
    STATE: '48',
    COUNTY: '321',
    NAME: 'Matagorda',
    LSAD: 'County',
    CENSUSAREA: 1100.276,
    FIPS: 'TX321',
    fips: '48321',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 28.82108792,
    lng: -96.01140809,
  },
  {
    GEO_ID: '0500000US48337',
    STATE: '48',
    COUNTY: '337',
    NAME: 'Montague',
    LSAD: 'County',
    CENSUSAREA: 930.906,
    FIPS: 'TX337',
    fips: '48337',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 33.67539964,
    lng: -97.72439946,
  },
  {
    GEO_ID: '0500000US54047',
    STATE: '54',
    COUNTY: '047',
    NAME: 'McDowell',
    LSAD: 'County',
    CENSUSAREA: 533.456,
    FIPS: 'WV047',
    fips: '54047',
    StateAbbr: 'WV',
    StateName: 'West Virginia',
    lat: 37.378572,
    lng: -81.65416089,
  },
  {
    GEO_ID: '0500000US54057',
    STATE: '54',
    COUNTY: '057',
    NAME: 'Mineral',
    LSAD: 'County',
    CENSUSAREA: 327.83,
    FIPS: 'WV057',
    fips: '54057',
    StateAbbr: 'WV',
    StateName: 'West Virginia',
    lat: 39.41464251,
    lng: -78.94327353,
  },
  {
    GEO_ID: '0500000US54063',
    STATE: '54',
    COUNTY: '063',
    NAME: 'Monroe',
    LSAD: 'County',
    CENSUSAREA: 472.752,
    FIPS: 'WV063',
    fips: '54063',
    StateAbbr: 'WV',
    StateName: 'West Virginia',
    lat: 37.5604155,
    lng: -80.55052779,
  },
  {
    GEO_ID: '0500000US54071',
    STATE: '54',
    COUNTY: '071',
    NAME: 'Pendleton',
    LSAD: 'County',
    CENSUSAREA: 696.049,
    FIPS: 'WV071',
    fips: '54071',
    StateAbbr: 'WV',
    StateName: 'West Virginia',
    lat: 38.68040465,
    lng: -79.35069018,
  },
  {
    GEO_ID: '0500000US54077',
    STATE: '54',
    COUNTY: '077',
    NAME: 'Preston',
    LSAD: 'County',
    CENSUSAREA: 648.805,
    FIPS: 'WV077',
    fips: '54077',
    StateAbbr: 'WV',
    StateName: 'West Virginia',
    lat: 39.46929522,
    lng: -79.6682145,
  },
  {
    GEO_ID: '0500000US51059',
    STATE: '51',
    COUNTY: '059',
    NAME: 'Fairfax',
    LSAD: 'County',
    CENSUSAREA: 390.969,
    FIPS: 'VA059',
    fips: '51059',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 38.83620791,
    lng: -77.27651017,
  },
  {
    GEO_ID: '0500000US51071',
    STATE: '51',
    COUNTY: '071',
    NAME: 'Giles',
    LSAD: 'County',
    CENSUSAREA: 355.781,
    FIPS: 'VA071',
    fips: '51071',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 37.31404444,
    lng: -80.70422242,
  },
  {
    GEO_ID: '0500000US51077',
    STATE: '51',
    COUNTY: '077',
    NAME: 'Grayson',
    LSAD: 'County',
    CENSUSAREA: 442.175,
    FIPS: 'VA077',
    fips: '51077',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 36.65661781,
    lng: -81.22507847,
  },
  {
    GEO_ID: '0500000US51083',
    STATE: '51',
    COUNTY: '083',
    NAME: 'Halifax',
    LSAD: 'County',
    CENSUSAREA: 817.839,
    FIPS: 'VA083',
    fips: '51083',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 36.76689258,
    lng: -78.93659955,
  },
  {
    GEO_ID: '0500000US51089',
    STATE: '51',
    COUNTY: '089',
    NAME: 'Henry',
    LSAD: 'County',
    CENSUSAREA: 382.334,
    FIPS: 'VA089',
    fips: '51089',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 36.68267428,
    lng: -79.87418576,
  },
  {
    GEO_ID: '0500000US51093',
    STATE: '51',
    COUNTY: '093',
    NAME: 'Isle of Wight',
    LSAD: 'County',
    CENSUSAREA: 315.612,
    FIPS: 'VA093',
    fips: '51093',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 36.89191765,
    lng: -76.72465523,
  },
  {
    GEO_ID: '0500000US51099',
    STATE: '51',
    COUNTY: '099',
    NAME: 'King George',
    LSAD: 'County',
    CENSUSAREA: 179.636,
    FIPS: 'VA099',
    fips: '51099',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 38.27358126,
    lng: -77.15723124,
  },
  {
    GEO_ID: '0500000US51105',
    STATE: '51',
    COUNTY: '105',
    NAME: 'Lee',
    LSAD: 'County',
    CENSUSAREA: 435.518,
    FIPS: 'VA105',
    fips: '51105',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 36.70548431,
    lng: -83.12815503,
  },
  {
    GEO_ID: '0500000US21033',
    STATE: '21',
    COUNTY: '033',
    NAME: 'Caldwell',
    LSAD: 'County',
    CENSUSAREA: 344.791,
    FIPS: 'KY033',
    fips: '21033',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 37.14556161,
    lng: -87.86831993,
  },
  {
    GEO_ID: '0500000US13305',
    STATE: '13',
    COUNTY: '305',
    NAME: 'Wayne',
    LSAD: 'County',
    CENSUSAREA: 641.777,
    FIPS: 'GA305',
    fips: '13305',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 31.55172562,
    lng: -81.91710568,
  },
  {
    GEO_ID: '0500000US51117',
    STATE: '51',
    COUNTY: '117',
    NAME: 'Mecklenburg',
    LSAD: 'County',
    CENSUSAREA: 625.485,
    FIPS: 'VA117',
    fips: '51117',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 36.68047202,
    lng: -78.3626113,
  },
  {
    GEO_ID: '0500000US72023',
    STATE: '72',
    COUNTY: '023',
    NAME: 'Cabo Rojo',
    LSAD: 'Muno',
    CENSUSAREA: 70.374,
    FIPS: 'PR023',
    fips: '72023',
    StateAbbr: 'PR',
    StateName: 'Puerto Rico',
    lat: 18.03901469,
    lng: -67.15457364,
  },
  {
    GEO_ID: '0500000US72033',
    STATE: '72',
    COUNTY: '033',
    NAME: 'Cataño',
    LSAD: 'Muno',
    CENSUSAREA: 4.845,
    FIPS: 'PR033',
    fips: '72033',
    StateAbbr: 'PR',
    StateName: 'Puerto Rico',
    lat: 18.44436903,
    lng: -66.13939837,
  },
  {
    GEO_ID: '0500000US72037',
    STATE: '72',
    COUNTY: '037',
    NAME: 'Ceiba',
    LSAD: 'Muno',
    CENSUSAREA: 29.035,
    FIPS: 'PR037',
    fips: '72037',
    StateAbbr: 'PR',
    StateName: 'Puerto Rico',
    lat: 18.25141544,
    lng: -65.65638154,
  },
  {
    GEO_ID: '0500000US72047',
    STATE: '72',
    COUNTY: '047',
    NAME: 'Corozal',
    LSAD: 'Muno',
    CENSUSAREA: 42.573,
    FIPS: 'PR047',
    fips: '72047',
    StateAbbr: 'PR',
    StateName: 'Puerto Rico',
    lat: 18.3041008,
    lng: -66.32896683,
  },
  {
    GEO_ID: '0500000US72049',
    STATE: '72',
    COUNTY: '049',
    NAME: 'Culebra',
    LSAD: 'Muno',
    CENSUSAREA: 11.624,
    FIPS: 'PR049',
    fips: '72049',
    StateAbbr: 'PR',
    StateName: 'Puerto Rico',
    lat: 18.31378498,
    lng: -65.28334116,
  },
  {
    GEO_ID: '0500000US72057',
    STATE: '72',
    COUNTY: '057',
    NAME: 'Guayama',
    LSAD: 'Muno',
    CENSUSAREA: 64.992,
    FIPS: 'PR057',
    fips: '72057',
    StateAbbr: 'PR',
    StateName: 'Puerto Rico',
    lat: 18.00377727,
    lng: -66.13696252,
  },
  {
    GEO_ID: '0500000US72065',
    STATE: '72',
    COUNTY: '065',
    NAME: 'Hatillo',
    LSAD: 'Muno',
    CENSUSAREA: 41.781,
    FIPS: 'PR065',
    fips: '72065',
    StateAbbr: 'PR',
    StateName: 'Puerto Rico',
    lat: 18.40992471,
    lng: -66.79574437,
  },
  {
    GEO_ID: '0500000US53007',
    STATE: '53',
    COUNTY: '007',
    NAME: 'Chelan',
    LSAD: 'County',
    CENSUSAREA: 2920.525,
    FIPS: 'WA007',
    fips: '53007',
    StateAbbr: 'WA',
    StateName: 'Washington',
    lat: 47.86921949,
    lng: -120.6188281,
  },
  {
    GEO_ID: '0500000US72087',
    STATE: '72',
    COUNTY: '087',
    NAME: 'Loíza',
    LSAD: 'Muno',
    CENSUSAREA: 19.366,
    FIPS: 'PR087',
    fips: '72087',
    StateAbbr: 'PR',
    StateName: 'Puerto Rico',
    lat: 18.42701557,
    lng: -65.9002482,
  },
  {
    GEO_ID: '0500000US72091',
    STATE: '72',
    COUNTY: '091',
    NAME: 'Manatí',
    LSAD: 'Muno',
    CENSUSAREA: 45.128,
    FIPS: 'PR091',
    fips: '72091',
    StateAbbr: 'PR',
    StateName: 'Puerto Rico',
    lat: 18.4199957,
    lng: -66.48979972,
  },
  {
    GEO_ID: '0500000US72097',
    STATE: '72',
    COUNTY: '097',
    NAME: 'Mayagüez',
    LSAD: 'Muno',
    CENSUSAREA: 77.645,
    FIPS: 'PR097',
    fips: '72097',
    StateAbbr: 'PR',
    StateName: 'Puerto Rico',
    lat: 18.18018788,
    lng: -67.33290558,
  },
  {
    GEO_ID: '0500000US72107',
    STATE: '72',
    COUNTY: '107',
    NAME: 'Orocovis',
    LSAD: 'Muno',
    CENSUSAREA: 63.62,
    FIPS: 'PR107',
    fips: '72107',
    StateAbbr: 'PR',
    StateName: 'Puerto Rico',
    lat: 18.21446774,
    lng: -66.4354442,
  },
  {
    GEO_ID: '0500000US72113',
    STATE: '72',
    COUNTY: '113',
    NAME: 'Ponce',
    LSAD: 'Muno',
    CENSUSAREA: 114.762,
    FIPS: 'PR113',
    fips: '72113',
    StateAbbr: 'PR',
    StateName: 'Puerto Rico',
    lat: 18.05756903,
    lng: -66.613128,
  },
  {
    GEO_ID: '0500000US72121',
    STATE: '72',
    COUNTY: '121',
    NAME: 'Sabana Grande',
    LSAD: 'Muno',
    CENSUSAREA: 35.833,
    FIPS: 'PR121',
    fips: '72121',
    StateAbbr: 'PR',
    StateName: 'Puerto Rico',
    lat: 18.08307024,
    lng: -66.94446564,
  },
  {
    GEO_ID: '0500000US72127',
    STATE: '72',
    COUNTY: '127',
    NAME: 'San Juan',
    LSAD: 'Muno',
    CENSUSAREA: 47.847,
    FIPS: 'PR127',
    fips: '72127',
    StateAbbr: 'PR',
    StateName: 'Puerto Rico',
    lat: 18.39080346,
    lng: -66.06170394,
  },
  {
    GEO_ID: '0500000US72133',
    STATE: '72',
    COUNTY: '133',
    NAME: 'Santa Isabel',
    LSAD: 'Muno',
    CENSUSAREA: 34.023,
    FIPS: 'PR133',
    fips: '72133',
    StateAbbr: 'PR',
    StateName: 'Puerto Rico',
    lat: 17.99547652,
    lng: -66.38861781,
  },
  {
    GEO_ID: '0500000US72141',
    STATE: '72',
    COUNTY: '141',
    NAME: 'Utuado',
    LSAD: 'Muno',
    CENSUSAREA: 113.529,
    FIPS: 'PR141',
    fips: '72141',
    StateAbbr: 'PR',
    StateName: 'Puerto Rico',
    lat: 18.27147584,
    lng: -66.70280065,
  },
  {
    GEO_ID: '0500000US48117',
    STATE: '48',
    COUNTY: '117',
    NAME: 'Deaf Smith',
    LSAD: 'County',
    CENSUSAREA: 1496.868,
    FIPS: 'TX117',
    fips: '48117',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 34.96515022,
    lng: -102.6052738,
  },
  {
    GEO_ID: '0500000US48261',
    STATE: '48',
    COUNTY: '261',
    NAME: 'Kenedy',
    LSAD: 'County',
    CENSUSAREA: 1458.329,
    FIPS: 'TX261',
    fips: '48261',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 26.92975017,
    lng: -97.69810806,
  },
  {
    GEO_ID: '0500000US48135',
    STATE: '48',
    COUNTY: '135',
    NAME: 'Ector',
    LSAD: 'County',
    CENSUSAREA: 897.687,
    FIPS: 'TX135',
    fips: '48135',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 31.86898877,
    lng: -102.5429428,
  },
  {
    GEO_ID: '0500000US48141',
    STATE: '48',
    COUNTY: '141',
    NAME: 'El Paso',
    LSAD: 'County',
    CENSUSAREA: 1012.693,
    FIPS: 'TX141',
    fips: '48141',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 31.76884214,
    lng: -106.2352418,
  },
  {
    GEO_ID: '0500000US48155',
    STATE: '48',
    COUNTY: '155',
    NAME: 'Foard',
    LSAD: 'County',
    CENSUSAREA: 704.397,
    FIPS: 'TX155',
    fips: '48155',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 33.97478308,
    lng: -99.77794293,
  },
  {
    GEO_ID: '0500000US48161',
    STATE: '48',
    COUNTY: '161',
    NAME: 'Freestone',
    LSAD: 'County',
    CENSUSAREA: 877.739,
    FIPS: 'TX161',
    fips: '48161',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 31.7044343,
    lng: -96.14897872,
  },
  {
    GEO_ID: '0500000US48165',
    STATE: '48',
    COUNTY: '165',
    NAME: 'Gaines',
    LSAD: 'County',
    CENSUSAREA: 1502.375,
    FIPS: 'TX165',
    fips: '48165',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 32.74057746,
    lng: -102.6353739,
  },
  {
    GEO_ID: '0500000US51107',
    STATE: '51',
    COUNTY: '107',
    NAME: 'Loudoun',
    LSAD: 'County',
    CENSUSAREA: 515.561,
    FIPS: 'VA107',
    fips: '51107',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 39.09048044,
    lng: -77.63575211,
  },
  {
    GEO_ID: '0500000US51131',
    STATE: '51',
    COUNTY: '131',
    NAME: 'Northampton',
    LSAD: 'County',
    CENSUSAREA: 211.612,
    FIPS: 'VA131',
    fips: '51131',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 37.34305358,
    lng: -75.87764606,
  },
  {
    GEO_ID: '0500000US51147',
    STATE: '51',
    COUNTY: '147',
    NAME: 'Prince Edward',
    LSAD: 'County',
    CENSUSAREA: 349.96,
    FIPS: 'VA147',
    fips: '51147',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 37.22409267,
    lng: -78.44090565,
  },
  {
    GEO_ID: '0500000US51149',
    STATE: '51',
    COUNTY: '149',
    NAME: 'Prince George',
    LSAD: 'County',
    CENSUSAREA: 265.155,
    FIPS: 'VA149',
    fips: '51149',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 37.18648609,
    lng: -77.22446275,
  },
  {
    GEO_ID: '0500000US51159',
    STATE: '51',
    COUNTY: '159',
    NAME: 'Richmond',
    LSAD: 'County',
    CENSUSAREA: 191.492,
    FIPS: 'VA159',
    fips: '51159',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 37.94301072,
    lng: -76.7268014,
  },
  {
    GEO_ID: '0500000US51161',
    STATE: '51',
    COUNTY: '161',
    NAME: 'Roanoke',
    LSAD: 'County',
    CENSUSAREA: 250.518,
    FIPS: 'VA161',
    fips: '51161',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 37.26938285,
    lng: -80.067514,
  },
  {
    GEO_ID: '0500000US51165',
    STATE: '51',
    COUNTY: '165',
    NAME: 'Rockingham',
    LSAD: 'County',
    CENSUSAREA: 849.086,
    FIPS: 'VA165',
    fips: '51165',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 38.51221007,
    lng: -78.87581609,
  },
  {
    GEO_ID: '0500000US51175',
    STATE: '51',
    COUNTY: '175',
    NAME: 'Southampton',
    LSAD: 'County',
    CENSUSAREA: 599.145,
    FIPS: 'VA175',
    fips: '51175',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 36.72019409,
    lng: -77.10566044,
  },
  {
    GEO_ID: '0500000US51177',
    STATE: '51',
    COUNTY: '177',
    NAME: 'Spotsylvania',
    LSAD: 'County',
    CENSUSAREA: 401.496,
    FIPS: 'VA177',
    fips: '51177',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 38.18453467,
    lng: -77.65615344,
  },
  {
    GEO_ID: '0500000US72071',
    STATE: '72',
    COUNTY: '071',
    NAME: 'Isabela',
    LSAD: 'Muno',
    CENSUSAREA: 55.304,
    FIPS: 'PR071',
    fips: '72071',
    StateAbbr: 'PR',
    StateName: 'Puerto Rico',
    lat: 18.44963632,
    lng: -67.00476317,
  },
  {
    GEO_ID: '0500000US55061',
    STATE: '55',
    COUNTY: '061',
    NAME: 'Kewaunee',
    LSAD: 'County',
    CENSUSAREA: 342.518,
    FIPS: 'WI061',
    fips: '55061',
    StateAbbr: 'WI',
    StateName: 'Wisconsin',
    lat: 44.51638089,
    lng: -87.61509441,
  },
  {
    GEO_ID: '0500000US56039',
    STATE: '56',
    COUNTY: '039',
    NAME: 'Teton',
    LSAD: 'County',
    CENSUSAREA: 3995.379,
    FIPS: 'WY039',
    fips: '56039',
    StateAbbr: 'WY',
    StateName: 'Wyoming',
    lat: 43.93456366,
    lng: -110.5896979,
  },
  {
    GEO_ID: '0500000US72003',
    STATE: '72',
    COUNTY: '003',
    NAME: 'Aguada',
    LSAD: 'Muno',
    CENSUSAREA: 30.851,
    FIPS: 'PR003',
    fips: '72003',
    StateAbbr: 'PR',
    StateName: 'Puerto Rico',
    lat: 18.36039221,
    lng: -67.17524655,
  },
  {
    GEO_ID: '0500000US72009',
    STATE: '72',
    COUNTY: '009',
    NAME: 'Aibonito',
    LSAD: 'Muno',
    CENSUSAREA: 31.311,
    FIPS: 'PR009',
    fips: '72009',
    StateAbbr: 'PR',
    StateName: 'Puerto Rico',
    lat: 18.13045854,
    lng: -66.26454838,
  },
  {
    GEO_ID: '0500000US72015',
    STATE: '72',
    COUNTY: '015',
    NAME: 'Arroyo',
    LSAD: 'Muno',
    CENSUSAREA: 15.008,
    FIPS: 'PR015',
    fips: '72015',
    StateAbbr: 'PR',
    StateName: 'Puerto Rico',
    lat: 17.9984593,
    lng: -66.05744329,
  },
  {
    GEO_ID: '0500000US53047',
    STATE: '53',
    COUNTY: '047',
    NAME: 'Okanogan',
    LSAD: 'County',
    CENSUSAREA: 5267.978,
    FIPS: 'WA047',
    fips: '53047',
    StateAbbr: 'WA',
    StateName: 'Washington',
    lat: 48.54862984,
    lng: -119.7406032,
  },
  {
    GEO_ID: '0500000US48129',
    STATE: '48',
    COUNTY: '129',
    NAME: 'Donley',
    LSAD: 'County',
    CENSUSAREA: 926.886,
    FIPS: 'TX129',
    fips: '48129',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 34.96517499,
    lng: -100.8140093,
  },
  {
    GEO_ID: '0500000US48167',
    STATE: '48',
    COUNTY: '167',
    NAME: 'Galveston',
    LSAD: 'County',
    CENSUSAREA: 378.358,
    FIPS: 'TX167',
    fips: '48167',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 29.3895025,
    lng: -94.95985195,
  },
  {
    GEO_ID: '0500000US48221',
    STATE: '48',
    COUNTY: '221',
    NAME: 'Hood',
    LSAD: 'County',
    CENSUSAREA: 420.641,
    FIPS: 'TX221',
    fips: '48221',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 32.43002712,
    lng: -97.83219383,
  },
  {
    GEO_ID: '0500000US48225',
    STATE: '48',
    COUNTY: '225',
    NAME: 'Houston',
    LSAD: 'County',
    CENSUSAREA: 1230.909,
    FIPS: 'TX225',
    fips: '48225',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 31.31764204,
    lng: -95.42200282,
  },
  {
    GEO_ID: '0500000US48229',
    STATE: '48',
    COUNTY: '229',
    NAME: 'Hudspeth',
    LSAD: 'County',
    CENSUSAREA: 4570.983,
    FIPS: 'TX229',
    fips: '48229',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 31.4564022,
    lng: -105.3871487,
  },
  {
    GEO_ID: '0500000US48245',
    STATE: '48',
    COUNTY: '245',
    NAME: 'Jefferson',
    LSAD: 'County',
    CENSUSAREA: 876.295,
    FIPS: 'TX245',
    fips: '48245',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 29.88337578,
    lng: -94.16307113,
  },
  {
    GEO_ID: '0500000US48257',
    STATE: '48',
    COUNTY: '257',
    NAME: 'Kaufman',
    LSAD: 'County',
    CENSUSAREA: 780.699,
    FIPS: 'TX257',
    fips: '48257',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 32.59905385,
    lng: -96.2877972,
  },
  {
    GEO_ID: '0500000US51193',
    STATE: '51',
    COUNTY: '193',
    NAME: 'Westmoreland',
    LSAD: 'County',
    CENSUSAREA: 229.375,
    FIPS: 'VA193',
    fips: '51193',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 38.11166934,
    lng: -76.80178124,
  },
  {
    GEO_ID: '0500000US51199',
    STATE: '51',
    COUNTY: '199',
    NAME: 'York',
    LSAD: 'County',
    CENSUSAREA: 104.78,
    FIPS: 'VA199',
    fips: '51199',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 37.24271166,
    lng: -76.56191748,
  },
  {
    GEO_ID: '0500000US53031',
    STATE: '53',
    COUNTY: '031',
    NAME: 'Jefferson',
    LSAD: 'County',
    CENSUSAREA: 1803.7,
    FIPS: 'WA031',
    fips: '53031',
    StateAbbr: 'WA',
    StateName: 'Washington',
    lat: 47.7490129,
    lng: -123.5935915,
  },
  {
    GEO_ID: '0500000US55031',
    STATE: '55',
    COUNTY: '031',
    NAME: 'Douglas',
    LSAD: 'County',
    CENSUSAREA: 1304.136,
    FIPS: 'WI031',
    fips: '55031',
    StateAbbr: 'WI',
    StateName: 'Wisconsin',
    lat: 46.43292074,
    lng: -91.91618868,
  },
  {
    GEO_ID: '0500000US55051',
    STATE: '55',
    COUNTY: '051',
    NAME: 'Iron',
    LSAD: 'County',
    CENSUSAREA: 758.174,
    FIPS: 'WI051',
    fips: '55051',
    StateAbbr: 'WI',
    StateName: 'Wisconsin',
    lat: 46.26247657,
    lng: -90.24265966,
  },
  {
    GEO_ID: '0500000US53049',
    STATE: '53',
    COUNTY: '049',
    NAME: 'Pacific',
    LSAD: 'County',
    CENSUSAREA: 932.658,
    FIPS: 'WA049',
    fips: '53049',
    StateAbbr: 'WA',
    StateName: 'Washington',
    lat: 46.55547971,
    lng: -123.7057228,
  },
  {
    GEO_ID: '0500000US51570',
    STATE: '51',
    COUNTY: '570',
    NAME: 'Colonial Heights',
    LSAD: 'city',
    CENSUSAREA: 7.52,
    FIPS: 'VA570',
    fips: '51570',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 37.26480119,
    lng: -77.39716266,
  },
  {
    GEO_ID: '0500000US51630',
    STATE: '51',
    COUNTY: '630',
    NAME: 'Fredericksburg',
    LSAD: 'city',
    CENSUSAREA: 10.44,
    FIPS: 'VA630',
    fips: '51630',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 38.29892214,
    lng: -77.48619454,
  },
  {
    GEO_ID: '0500000US51650',
    STATE: '51',
    COUNTY: '650',
    NAME: 'Hampton',
    LSAD: 'city',
    CENSUSAREA: 51.413,
    FIPS: 'VA650',
    fips: '51650',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 37.05573241,
    lng: -76.36333533,
  },
  {
    GEO_ID: '0500000US51700',
    STATE: '51',
    COUNTY: '700',
    NAME: 'Newport News',
    LSAD: 'city',
    CENSUSAREA: 68.714,
    FIPS: 'VA700',
    fips: '51700',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 37.10553608,
    lng: -76.5199628,
  },
  {
    GEO_ID: '0500000US51735',
    STATE: '51',
    COUNTY: '735',
    NAME: 'Poquoson',
    LSAD: 'city',
    CENSUSAREA: 15.317,
    FIPS: 'VA735',
    fips: '51735',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 37.1307115,
    lng: -76.35566972,
  },
  {
    GEO_ID: '0500000US51770',
    STATE: '51',
    COUNTY: '770',
    NAME: 'Roanoke',
    LSAD: 'city',
    CENSUSAREA: 42.562,
    FIPS: 'VA770',
    fips: '51770',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 37.27905915,
    lng: -79.95759159,
  },
  {
    GEO_ID: '0500000US55071',
    STATE: '55',
    COUNTY: '071',
    NAME: 'Manitowoc',
    LSAD: 'County',
    CENSUSAREA: 589.082,
    FIPS: 'WI071',
    fips: '55071',
    StateAbbr: 'WI',
    StateName: 'Wisconsin',
    lat: 44.11990639,
    lng: -87.80948518,
  },
  {
    GEO_ID: '0500000US55079',
    STATE: '55',
    COUNTY: '079',
    NAME: 'Milwaukee',
    LSAD: 'County',
    CENSUSAREA: 241.402,
    FIPS: 'WI079',
    fips: '55079',
    StateAbbr: 'WI',
    StateName: 'Wisconsin',
    lat: 43.00700658,
    lng: -87.96698811,
  },
  {
    GEO_ID: '0500000US55089',
    STATE: '55',
    COUNTY: '089',
    NAME: 'Ozaukee',
    LSAD: 'County',
    CENSUSAREA: 233.077,
    FIPS: 'WI089',
    fips: '55089',
    StateAbbr: 'WI',
    StateName: 'Wisconsin',
    lat: 43.38375913,
    lng: -87.95138777,
  },
  {
    GEO_ID: '0500000US53055',
    STATE: '53',
    COUNTY: '055',
    NAME: 'San Juan',
    LSAD: 'County',
    CENSUSAREA: 173.915,
    FIPS: 'WA055',
    fips: '53055',
    StateAbbr: 'WA',
    StateName: 'Washington',
    lat: 48.57856326,
    lng: -122.9534631,
  },
  {
    GEO_ID: '0500000US53061',
    STATE: '53',
    COUNTY: '061',
    NAME: 'Snohomish',
    LSAD: 'County',
    CENSUSAREA: 2087.273,
    FIPS: 'WA061',
    fips: '53061',
    StateAbbr: 'WA',
    StateName: 'Washington',
    lat: 48.04772437,
    lng: -121.6977603,
  },
  {
    GEO_ID: '0500000US53071',
    STATE: '53',
    COUNTY: '071',
    NAME: 'Walla Walla',
    LSAD: 'County',
    CENSUSAREA: 1270.129,
    FIPS: 'WA071',
    fips: '53071',
    StateAbbr: 'WA',
    StateName: 'Washington',
    lat: 46.22968278,
    lng: -118.4785331,
  },
  {
    GEO_ID: '0500000US53073',
    STATE: '53',
    COUNTY: '073',
    NAME: 'Whatcom',
    LSAD: 'County',
    CENSUSAREA: 2106.857,
    FIPS: 'WA073',
    fips: '53073',
    StateAbbr: 'WA',
    StateName: 'Washington',
    lat: 48.82595321,
    lng: -121.7209525,
  },
  {
    GEO_ID: '0500000US22039',
    STATE: '22',
    COUNTY: '039',
    NAME: 'Evangeline',
    LSAD: 'Parish',
    CENSUSAREA: 662.378,
    FIPS: 'LA039',
    fips: '22039',
    StateAbbr: 'LA',
    StateName: 'Louisiana',
    lat: 30.72890813,
    lng: -92.40566294,
  },
  {
    GEO_ID: '0500000US27139',
    STATE: '27',
    COUNTY: '139',
    NAME: 'Scott',
    LSAD: 'County',
    CENSUSAREA: 356.476,
    FIPS: 'MN139',
    fips: '27139',
    StateAbbr: 'MN',
    StateName: 'Minnesota',
    lat: 44.64850008,
    lng: -93.53696693,
  },
  {
    GEO_ID: '0500000US31113',
    STATE: '31',
    COUNTY: '113',
    NAME: 'Logan',
    LSAD: 'County',
    CENSUSAREA: 570.66,
    FIPS: 'NE113',
    fips: '31113',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 41.56652317,
    lng: -100.482244,
  },
  {
    GEO_ID: '0500000US37043',
    STATE: '37',
    COUNTY: '043',
    NAME: 'Clay',
    LSAD: 'County',
    CENSUSAREA: 214.751,
    FIPS: 'NC043',
    fips: '37043',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 35.05771746,
    lng: -83.75018218,
  },
  {
    GEO_ID: '0500000US39057',
    STATE: '39',
    COUNTY: '057',
    NAME: 'Greene',
    LSAD: 'County',
    CENSUSAREA: 413.729,
    FIPS: 'OH057',
    fips: '39057',
    StateAbbr: 'OH',
    StateName: 'Ohio',
    lat: 39.69132786,
    lng: -83.88969073,
  },
  {
    GEO_ID: '0500000US36009',
    STATE: '36',
    COUNTY: '009',
    NAME: 'Cattaraugus',
    LSAD: 'County',
    CENSUSAREA: 1308.35,
    FIPS: 'NY009',
    fips: '36009',
    StateAbbr: 'NY',
    StateName: 'New York',
    lat: 42.24856064,
    lng: -78.67903202,
  },
  {
    GEO_ID: '0500000US47169',
    STATE: '47',
    COUNTY: '169',
    NAME: 'Trousdale',
    LSAD: 'County',
    CENSUSAREA: 114.193,
    FIPS: 'TN169',
    fips: '47169',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 36.39236244,
    lng: -86.15630295,
  },
  {
    GEO_ID: '0500000US46073',
    STATE: '46',
    COUNTY: '073',
    NAME: 'Jerauld',
    LSAD: 'County',
    CENSUSAREA: 526.233,
    FIPS: 'SD073',
    fips: '46073',
    StateAbbr: 'SD',
    StateName: 'South Dakota',
    lat: 44.06637798,
    lng: -98.62965972,
  },
  {
    GEO_ID: '0500000US48365',
    STATE: '48',
    COUNTY: '365',
    NAME: 'Panola',
    LSAD: 'County',
    CENSUSAREA: 801.749,
    FIPS: 'TX365',
    fips: '48365',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 32.16239924,
    lng: -94.30558387,
  },
  {
    GEO_ID: '0500000US48207',
    STATE: '48',
    COUNTY: '207',
    NAME: 'Haskell',
    LSAD: 'County',
    CENSUSAREA: 903.131,
    FIPS: 'TX207',
    fips: '48207',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 33.17823584,
    lng: -99.73037359,
  },
  {
    GEO_ID: '0500000US51690',
    STATE: '51',
    COUNTY: '690',
    NAME: 'Martinsville',
    LSAD: 'city',
    CENSUSAREA: 10.956,
    FIPS: 'VA690',
    fips: '51690',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 36.68207476,
    lng: -79.86345947,
  },
  {
    GEO_ID: '0500000US55131',
    STATE: '55',
    COUNTY: '131',
    NAME: 'Washington',
    LSAD: 'County',
    CENSUSAREA: 430.703,
    FIPS: 'WI131',
    fips: '55131',
    StateAbbr: 'WI',
    StateName: 'Wisconsin',
    lat: 43.36887792,
    lng: -88.23041319,
  },
  {
    GEO_ID: '0500000US54067',
    STATE: '54',
    COUNTY: '067',
    NAME: 'Nicholas',
    LSAD: 'County',
    CENSUSAREA: 646.824,
    FIPS: 'WV067',
    fips: '54067',
    StateAbbr: 'WV',
    StateName: 'West Virginia',
    lat: 38.2918799,
    lng: -80.79999513,
  },
  {
    GEO_ID: '0500000US51087',
    STATE: '51',
    COUNTY: '087',
    NAME: 'Henrico',
    LSAD: 'County',
    CENSUSAREA: 233.697,
    FIPS: 'VA087',
    fips: '51087',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 37.53796421,
    lng: -77.40505652,
  },
  {
    GEO_ID: '0500000US01043',
    STATE: '01',
    COUNTY: '043',
    NAME: 'Cullman',
    LSAD: 'County',
    CENSUSAREA: 734.841,
    FIPS: 'AL043',
    fips: '01043',
    StateAbbr: 'AL',
    StateName: 'Alabama',
    lat: 34.13183982,
    lng: -86.86718877,
  },
  {
    GEO_ID: '0500000US01109',
    STATE: '01',
    COUNTY: '109',
    NAME: 'Pike',
    LSAD: 'County',
    CENSUSAREA: 672.094,
    FIPS: 'AL109',
    fips: '01109',
    StateAbbr: 'AL',
    StateName: 'Alabama',
    lat: 31.80232564,
    lng: -85.94087373,
  },
  {
    GEO_ID: '0500000US09009',
    STATE: '09',
    COUNTY: '009',
    NAME: 'New Haven',
    LSAD: 'County',
    CENSUSAREA: 604.506,
    FIPS: 'CT009',
    fips: '09009',
    StateAbbr: 'CT',
    StateName: 'Connecticut',
    lat: 41.41072833,
    lng: -72.93235204,
  },
  {
    GEO_ID: '0500000US05049',
    STATE: '05',
    COUNTY: '049',
    NAME: 'Fulton',
    LSAD: 'County',
    CENSUSAREA: 618.194,
    FIPS: 'AR049',
    fips: '05049',
    StateAbbr: 'AR',
    StateName: 'Arkansas',
    lat: 36.3818346,
    lng: -91.81767336,
  },
  {
    GEO_ID: '0500000US05127',
    STATE: '05',
    COUNTY: '127',
    NAME: 'Scott',
    LSAD: 'County',
    CENSUSAREA: 892.32,
    FIPS: 'AR127',
    fips: '05127',
    StateAbbr: 'AR',
    StateName: 'Arkansas',
    lat: 34.86103868,
    lng: -94.06315729,
  },
  {
    GEO_ID: '0500000US21117',
    STATE: '21',
    COUNTY: '117',
    NAME: 'Kenton',
    LSAD: 'County',
    CENSUSAREA: 160.25,
    FIPS: 'KY117',
    fips: '21117',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 38.93368118,
    lng: -84.53313887,
  },
  {
    GEO_ID: '0500000US19123',
    STATE: '19',
    COUNTY: '123',
    NAME: 'Mahaska',
    LSAD: 'County',
    CENSUSAREA: 570.863,
    FIPS: 'IA123',
    fips: '19123',
    StateAbbr: 'IA',
    StateName: 'Iowa',
    lat: 41.33521502,
    lng: -92.64083907,
  },
  {
    GEO_ID: '0500000US47015',
    STATE: '47',
    COUNTY: '015',
    NAME: 'Cannon',
    LSAD: 'County',
    CENSUSAREA: 265.635,
    FIPS: 'TN015',
    fips: '47015',
    StateAbbr: 'TN',
    StateName: 'Tennessee',
    lat: 35.80842943,
    lng: -86.06179619,
  },
  {
    GEO_ID: '0500000US38011',
    STATE: '38',
    COUNTY: '011',
    NAME: 'Bowman',
    LSAD: 'County',
    CENSUSAREA: 1161.807,
    FIPS: 'ND011',
    fips: '38011',
    StateAbbr: 'ND',
    StateName: 'North Dakota',
    lat: 46.1127569,
    lng: -103.5205091,
  },
  {
    GEO_ID: '0500000US38083',
    STATE: '38',
    COUNTY: '083',
    NAME: 'Sheridan',
    LSAD: 'County',
    CENSUSAREA: 972.379,
    FIPS: 'ND083',
    fips: '38083',
    StateAbbr: 'ND',
    StateName: 'North Dakota',
    lat: 47.5754264,
    lng: -100.3455585,
  },
  {
    GEO_ID: '0500000US48375',
    STATE: '48',
    COUNTY: '375',
    NAME: 'Potter',
    LSAD: 'County',
    CENSUSAREA: 908.37,
    FIPS: 'TX375',
    fips: '48375',
    StateAbbr: 'TX',
    StateName: 'Texas',
    lat: 35.40133773,
    lng: -101.893964,
  },
  {
    GEO_ID: '0500000US31065',
    STATE: '31',
    COUNTY: '065',
    NAME: 'Furnas',
    LSAD: 'County',
    CENSUSAREA: 719.132,
    FIPS: 'NE065',
    fips: '31065',
    StateAbbr: 'NE',
    StateName: 'Nebraska',
    lat: 40.17646227,
    lng: -99.91249504,
  },
  {
    GEO_ID: '0500000US28135',
    STATE: '28',
    COUNTY: '135',
    NAME: 'Tallahatchie',
    LSAD: 'County',
    CENSUSAREA: 645.288,
    FIPS: 'MS135',
    fips: '28135',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 33.95061391,
    lng: -90.17334129,
  },
  {
    GEO_ID: '0500000US41019',
    STATE: '41',
    COUNTY: '019',
    NAME: 'Douglas',
    LSAD: 'County',
    CENSUSAREA: 5036.075,
    FIPS: 'OR019',
    fips: '41019',
    StateAbbr: 'OR',
    StateName: 'Oregon',
    lat: 43.2796987,
    lng: -123.1658435,
  },
  {
    GEO_ID: '0500000US29013',
    STATE: '29',
    COUNTY: '013',
    NAME: 'Bates',
    LSAD: 'County',
    CENSUSAREA: 836.689,
    FIPS: 'MO013',
    fips: '29013',
    StateAbbr: 'MO',
    StateName: 'Missouri',
    lat: 38.25742352,
    lng: -94.34015625,
  },
  {
    GEO_ID: '0500000US55001',
    STATE: '55',
    COUNTY: '001',
    NAME: 'Adams',
    LSAD: 'County',
    CENSUSAREA: 645.646,
    FIPS: 'WI001',
    fips: '55001',
    StateAbbr: 'WI',
    StateName: 'Wisconsin',
    lat: 43.96955395,
    lng: -89.77038616,
  },
  {
    GEO_ID: '0500000US53057',
    STATE: '53',
    COUNTY: '057',
    NAME: 'Skagit',
    LSAD: 'County',
    CENSUSAREA: 1731.203,
    FIPS: 'WA057',
    fips: '53057',
    StateAbbr: 'WA',
    StateName: 'Washington',
    lat: 48.47967845,
    lng: -121.7319234,
  },
  {
    GEO_ID: '0500000US06085',
    STATE: '06',
    COUNTY: '085',
    NAME: 'Santa Clara',
    LSAD: 'County',
    CENSUSAREA: 1290.1,
    FIPS: 'CA085',
    fips: '06085',
    StateAbbr: 'CA',
    StateName: 'California',
    lat: 37.23169965,
    lng: -121.6951016,
  },
  {
    GEO_ID: '0500000US13017',
    STATE: '13',
    COUNTY: '017',
    NAME: 'Ben Hill',
    LSAD: 'County',
    CENSUSAREA: 250.121,
    FIPS: 'GA017',
    fips: '13017',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 31.75972075,
    lng: -83.22027638,
  },
  {
    GEO_ID: '0500000US18047',
    STATE: '18',
    COUNTY: '047',
    NAME: 'Franklin',
    LSAD: 'County',
    CENSUSAREA: 384.43,
    FIPS: 'IN047',
    fips: '18047',
    StateAbbr: 'IN',
    StateName: 'Indiana',
    lat: 39.41429175,
    lng: -85.06053013,
  },
  {
    GEO_ID: '0500000US22123',
    STATE: '22',
    COUNTY: '123',
    NAME: 'West Carroll',
    LSAD: 'Parish',
    CENSUSAREA: 359.646,
    FIPS: 'LA123',
    fips: '22123',
    StateAbbr: 'LA',
    StateName: 'Louisiana',
    lat: 32.78885847,
    lng: -91.45664676,
  },
  {
    GEO_ID: '0500000US26067',
    STATE: '26',
    COUNTY: '067',
    NAME: 'Ionia',
    LSAD: 'County',
    CENSUSAREA: 571.304,
    FIPS: 'MI067',
    fips: '26067',
    StateAbbr: 'MI',
    StateName: 'Michigan',
    lat: 42.94502238,
    lng: -85.07413319,
  },
  {
    GEO_ID: '0500000US37185',
    STATE: '37',
    COUNTY: '185',
    NAME: 'Warren',
    LSAD: 'County',
    CENSUSAREA: 428.456,
    FIPS: 'NC185',
    fips: '37185',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 36.39674307,
    lng: -78.10671227,
  },
  {
    GEO_ID: '0500000US45015',
    STATE: '45',
    COUNTY: '015',
    NAME: 'Berkeley',
    LSAD: 'County',
    CENSUSAREA: 1098.857,
    FIPS: 'SC015',
    fips: '45015',
    StateAbbr: 'SC',
    StateName: 'South Carolina',
    lat: 33.19764043,
    lng: -79.95087397,
  },
  {
    GEO_ID: '0500000US13045',
    STATE: '13',
    COUNTY: '045',
    NAME: 'Carroll',
    LSAD: 'County',
    CENSUSAREA: 499.076,
    FIPS: 'GA045',
    fips: '13045',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 33.58246933,
    lng: -85.07980892,
  },
  {
    GEO_ID: '0500000US37093',
    STATE: '37',
    COUNTY: '093',
    NAME: 'Hoke',
    LSAD: 'County',
    CENSUSAREA: 390.744,
    FIPS: 'NC093',
    fips: '37093',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 35.01716668,
    lng: -79.23678619,
  },
  {
    GEO_ID: '0500000US20149',
    STATE: '20',
    COUNTY: '149',
    NAME: 'Pottawatomie',
    LSAD: 'County',
    CENSUSAREA: 841.022,
    FIPS: 'KS149',
    fips: '20149',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 39.37903349,
    lng: -96.34276557,
  },
  {
    GEO_ID: '0500000US28033',
    STATE: '28',
    COUNTY: '033',
    NAME: 'DeSoto',
    LSAD: 'County',
    CENSUSAREA: 476.145,
    FIPS: 'MS033',
    fips: '28033',
    StateAbbr: 'MS',
    StateName: 'Mississippi',
    lat: 34.8756938,
    lng: -89.99140222,
  },
  {
    GEO_ID: '0500000US42001',
    STATE: '42',
    COUNTY: '001',
    NAME: 'Adams',
    LSAD: 'County',
    CENSUSAREA: 518.668,
    FIPS: 'PA001',
    fips: '42001',
    StateAbbr: 'PA',
    StateName: 'Pennsylvania',
    lat: 39.87146641,
    lng: -77.21807609,
  },
  {
    GEO_ID: '0500000US37161',
    STATE: '37',
    COUNTY: '161',
    NAME: 'Rutherford',
    LSAD: 'County',
    CENSUSAREA: 564.151,
    FIPS: 'NC161',
    fips: '37161',
    StateAbbr: 'NC',
    StateName: 'North Carolina',
    lat: 35.4018617,
    lng: -81.92041527,
  },
  {
    GEO_ID: '0500000US21051',
    STATE: '21',
    COUNTY: '051',
    NAME: 'Clay',
    LSAD: 'County',
    CENSUSAREA: 469.247,
    FIPS: 'KY051',
    fips: '21051',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 37.15962402,
    lng: -83.71435179,
  },
  {
    GEO_ID: '0500000US13245',
    STATE: '13',
    COUNTY: '245',
    NAME: 'Richmond',
    LSAD: 'County',
    CENSUSAREA: 324.326,
    FIPS: 'GA245',
    fips: '13245',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 33.35950332,
    lng: -82.07386121,
  },
  {
    GEO_ID: '0500000US13251',
    STATE: '13',
    COUNTY: '251',
    NAME: 'Screven',
    LSAD: 'County',
    CENSUSAREA: 645.101,
    FIPS: 'GA251',
    fips: '13251',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 32.75107817,
    lng: -81.61169117,
  },
  {
    GEO_ID: '0500000US13261',
    STATE: '13',
    COUNTY: '261',
    NAME: 'Sumter',
    LSAD: 'County',
    CENSUSAREA: 482.697,
    FIPS: 'GA261',
    fips: '13261',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 32.04008071,
    lng: -84.19615662,
  },
  {
    GEO_ID: '0500000US13263',
    STATE: '13',
    COUNTY: '263',
    NAME: 'Talbot',
    LSAD: 'County',
    CENSUSAREA: 391.389,
    FIPS: 'GA263',
    fips: '13263',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 32.69944401,
    lng: -84.53322383,
  },
  {
    GEO_ID: '0500000US13273',
    STATE: '13',
    COUNTY: '273',
    NAME: 'Terrell',
    LSAD: 'County',
    CENSUSAREA: 335.439,
    FIPS: 'GA273',
    fips: '13273',
    StateAbbr: 'GA',
    StateName: 'Georgia',
    lat: 31.77710794,
    lng: -84.43693855,
  },
  {
    GEO_ID: '0500000US20193',
    STATE: '20',
    COUNTY: '193',
    NAME: 'Thomas',
    LSAD: 'County',
    CENSUSAREA: 1074.687,
    FIPS: 'KS193',
    fips: '20193',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 39.35107575,
    lng: -101.0553705,
  },
  {
    GEO_ID: '0500000US20199',
    STATE: '20',
    COUNTY: '199',
    NAME: 'Wallace',
    LSAD: 'County',
    CENSUSAREA: 913.652,
    FIPS: 'KS199',
    fips: '20199',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 38.91690628,
    lng: -101.7637347,
  },
  {
    GEO_ID: '0500000US20201',
    STATE: '20',
    COUNTY: '201',
    NAME: 'Washington',
    LSAD: 'County',
    CENSUSAREA: 894.756,
    FIPS: 'KS201',
    fips: '20201',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 39.78417855,
    lng: -97.08751558,
  },
  {
    GEO_ID: '0500000US21195',
    STATE: '21',
    COUNTY: '195',
    NAME: 'Pike',
    LSAD: 'County',
    CENSUSAREA: 786.833,
    FIPS: 'KY195',
    fips: '21195',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 37.46909859,
    lng: -82.39537783,
  },
  {
    GEO_ID: '0500000US21211',
    STATE: '21',
    COUNTY: '211',
    NAME: 'Shelby',
    LSAD: 'County',
    CENSUSAREA: 379.636,
    FIPS: 'KY211',
    fips: '21211',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 38.21556092,
    lng: -85.19489586,
  },
  {
    GEO_ID: '0500000US21213',
    STATE: '21',
    COUNTY: '213',
    NAME: 'Simpson',
    LSAD: 'County',
    CENSUSAREA: 234.204,
    FIPS: 'KY213',
    fips: '21213',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 36.74196569,
    lng: -86.58284235,
  },
  {
    GEO_ID: '0500000US21223',
    STATE: '21',
    COUNTY: '223',
    NAME: 'Trimble',
    LSAD: 'County',
    CENSUSAREA: 151.648,
    FIPS: 'KY223',
    fips: '21223',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 38.61269613,
    lng: -85.33714572,
  },
  {
    GEO_ID: '0500000US21225',
    STATE: '21',
    COUNTY: '225',
    NAME: 'Union',
    LSAD: 'County',
    CENSUSAREA: 342.849,
    FIPS: 'KY225',
    fips: '21225',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 37.65840762,
    lng: -87.94548669,
  },
  {
    GEO_ID: '0500000US20037',
    STATE: '20',
    COUNTY: '037',
    NAME: 'Crawford',
    LSAD: 'County',
    CENSUSAREA: 589.756,
    FIPS: 'KS037',
    fips: '20037',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 37.50702733,
    lng: -94.85185156,
  },
  {
    GEO_ID: '0500000US20043',
    STATE: '20',
    COUNTY: '043',
    NAME: 'Doniphan',
    LSAD: 'County',
    CENSUSAREA: 393.405,
    FIPS: 'KS043',
    fips: '20043',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 39.78796644,
    lng: -95.14683558,
  },
  {
    GEO_ID: '0500000US20061',
    STATE: '20',
    COUNTY: '061',
    NAME: 'Geary',
    LSAD: 'County',
    CENSUSAREA: 384.618,
    FIPS: 'KS061',
    fips: '20061',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 39.00227253,
    lng: -96.75251853,
  },
  {
    GEO_ID: '0500000US20067',
    STATE: '20',
    COUNTY: '067',
    NAME: 'Grant',
    LSAD: 'County',
    CENSUSAREA: 574.8,
    FIPS: 'KS067',
    fips: '20067',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 37.56227336,
    lng: -101.3079758,
  },
  {
    GEO_ID: '0500000US20071',
    STATE: '20',
    COUNTY: '071',
    NAME: 'Greeley',
    LSAD: 'County',
    CENSUSAREA: 778.45,
    FIPS: 'KS071',
    fips: '20071',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 38.48078179,
    lng: -101.8060294,
  },
  {
    GEO_ID: '0500000US20083',
    STATE: '20',
    COUNTY: '083',
    NAME: 'Hodgeman',
    LSAD: 'County',
    CENSUSAREA: 859.992,
    FIPS: 'KS083',
    fips: '20083',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 38.08784693,
    lng: -99.89821264,
  },
  {
    GEO_ID: '0500000US20089',
    STATE: '20',
    COUNTY: '089',
    NAME: 'Jewell',
    LSAD: 'County',
    CENSUSAREA: 909.781,
    FIPS: 'KS089',
    fips: '20089',
    StateAbbr: 'KS',
    StateName: 'Kansas',
    lat: 39.7847206,
    lng: -98.21822331,
  },
  {
    GEO_ID: '0500000US22019',
    STATE: '22',
    COUNTY: '019',
    NAME: 'Calcasieu',
    LSAD: 'Parish',
    CENSUSAREA: 1063.659,
    FIPS: 'LA019',
    fips: '22019',
    StateAbbr: 'LA',
    StateName: 'Louisiana',
    lat: 30.22942198,
    lng: -93.35798129,
  },
  {
    GEO_ID: '0500000US22027',
    STATE: '22',
    COUNTY: '027',
    NAME: 'Claiborne',
    LSAD: 'Parish',
    CENSUSAREA: 754.878,
    FIPS: 'LA027',
    fips: '22027',
    StateAbbr: 'LA',
    StateName: 'Louisiana',
    lat: 32.82277025,
    lng: -92.99565971,
  },
  {
    GEO_ID: '0500000US22029',
    STATE: '22',
    COUNTY: '029',
    NAME: 'Concordia',
    LSAD: 'Parish',
    CENSUSAREA: 696.92,
    FIPS: 'LA029',
    fips: '22029',
    StateAbbr: 'LA',
    StateName: 'Louisiana',
    lat: 31.44577009,
    lng: -91.63992436,
  },
  {
    GEO_ID: '0500000US22037',
    STATE: '22',
    COUNTY: '037',
    NAME: 'East Feliciana',
    LSAD: 'Parish',
    CENSUSAREA: 453.41,
    FIPS: 'LA037',
    fips: '22037',
    StateAbbr: 'LA',
    StateName: 'Louisiana',
    lat: 30.8451603,
    lng: -91.04553925,
  },
  {
    GEO_ID: '0500000US53009',
    STATE: '53',
    COUNTY: '009',
    NAME: 'Clallam',
    LSAD: 'County',
    CENSUSAREA: 1738.329,
    FIPS: 'WA009',
    fips: '53009',
    StateAbbr: 'WA',
    StateName: 'Washington',
    lat: 48.04911666,
    lng: -123.9277111,
  },
  {
    GEO_ID: '0500000US53027',
    STATE: '53',
    COUNTY: '027',
    NAME: 'Grays Harbor',
    LSAD: 'County',
    CENSUSAREA: 1902.027,
    FIPS: 'WA027',
    fips: '53027',
    StateAbbr: 'WA',
    StateName: 'Washington',
    lat: 47.14971004,
    lng: -123.773048,
  },
  {
    GEO_ID: '0500000US55105',
    STATE: '55',
    COUNTY: '105',
    NAME: 'Rock',
    LSAD: 'County',
    CENSUSAREA: 718.142,
    FIPS: 'WI105',
    fips: '55105',
    StateAbbr: 'WI',
    StateName: 'Wisconsin',
    lat: 42.67118649,
    lng: -89.0712802,
  },
  {
    GEO_ID: '0500000US55117',
    STATE: '55',
    COUNTY: '117',
    NAME: 'Sheboygan',
    LSAD: 'County',
    CENSUSAREA: 511.265,
    FIPS: 'WI117',
    fips: '55117',
    StateAbbr: 'WI',
    StateName: 'Wisconsin',
    lat: 43.72108736,
    lng: -87.94566398,
  },
  {
    GEO_ID: '0500000US55127',
    STATE: '55',
    COUNTY: '127',
    NAME: 'Walworth',
    LSAD: 'County',
    CENSUSAREA: 555.127,
    FIPS: 'WI127',
    fips: '55127',
    StateAbbr: 'WI',
    StateName: 'Wisconsin',
    lat: 42.66848551,
    lng: -88.54158045,
  },
  {
    GEO_ID: '0500000US55009',
    STATE: '55',
    COUNTY: '009',
    NAME: 'Brown',
    LSAD: 'County',
    CENSUSAREA: 529.708,
    FIPS: 'WI009',
    fips: '55009',
    StateAbbr: 'WI',
    StateName: 'Wisconsin',
    lat: 44.452501,
    lng: -88.00366602,
  },
  {
    GEO_ID: '0500000US49047',
    STATE: '49',
    COUNTY: '047',
    NAME: 'Uintah',
    LSAD: 'County',
    CENSUSAREA: 4479.693,
    FIPS: 'UT047',
    fips: '49047',
    StateAbbr: 'UT',
    StateName: 'Utah',
    lat: 40.12501568,
    lng: -109.517996,
  },
  {
    GEO_ID: '0500000US49053',
    STATE: '49',
    COUNTY: '053',
    NAME: 'Washington',
    LSAD: 'County',
    CENSUSAREA: 2426.358,
    FIPS: 'UT053',
    fips: '49053',
    StateAbbr: 'UT',
    StateName: 'Utah',
    lat: 37.28047916,
    lng: -113.5045338,
  },
  {
    GEO_ID: '0500000US50001',
    STATE: '50',
    COUNTY: '001',
    NAME: 'Addison',
    LSAD: 'County',
    CENSUSAREA: 766.325,
    FIPS: 'VT001',
    fips: '50001',
    StateAbbr: 'VT',
    StateName: 'Vermont',
    lat: 44.03090072,
    lng: -73.14089327,
  },
  {
    GEO_ID: '0500000US50009',
    STATE: '50',
    COUNTY: '009',
    NAME: 'Essex',
    LSAD: 'County',
    CENSUSAREA: 663.598,
    FIPS: 'VT009',
    fips: '50009',
    StateAbbr: 'VT',
    StateName: 'Vermont',
    lat: 44.7283185,
    lng: -71.736327,
  },
  {
    GEO_ID: '0500000US50017',
    STATE: '50',
    COUNTY: '017',
    NAME: 'Orange',
    LSAD: 'County',
    CENSUSAREA: 687.033,
    FIPS: 'VT017',
    fips: '50017',
    StateAbbr: 'VT',
    StateName: 'Vermont',
    lat: 44.00552167,
    lng: -72.37674987,
  },
  {
    GEO_ID: '0500000US50023',
    STATE: '50',
    COUNTY: '023',
    NAME: 'Washington',
    LSAD: 'County',
    CENSUSAREA: 687.233,
    FIPS: 'VT023',
    fips: '50023',
    StateAbbr: 'VT',
    StateName: 'Vermont',
    lat: 44.27309445,
    lng: -72.61502314,
  },
  {
    GEO_ID: '0500000US22003',
    STATE: '22',
    COUNTY: '003',
    NAME: 'Allen',
    LSAD: 'Parish',
    CENSUSAREA: 761.847,
    FIPS: 'LA003',
    fips: '22003',
    StateAbbr: 'LA',
    StateName: 'Louisiana',
    lat: 30.65238675,
    lng: -92.82796914,
  },
  {
    GEO_ID: '0500000US22009',
    STATE: '22',
    COUNTY: '009',
    NAME: 'Avoyelles',
    LSAD: 'Parish',
    CENSUSAREA: 832.434,
    FIPS: 'LA009',
    fips: '22009',
    StateAbbr: 'LA',
    StateName: 'Louisiana',
    lat: 31.07634786,
    lng: -92.001673,
  },
  {
    GEO_ID: '0500000US22101',
    STATE: '22',
    COUNTY: '101',
    NAME: 'St. Mary',
    LSAD: 'Parish',
    CENSUSAREA: 555.379,
    FIPS: 'LA101',
    fips: '22101',
    StateAbbr: 'LA',
    StateName: 'Louisiana',
    lat: 29.70500764,
    lng: -91.44469375,
  },
  {
    GEO_ID: '0500000US22107',
    STATE: '22',
    COUNTY: '107',
    NAME: 'Tensas',
    LSAD: 'Parish',
    CENSUSAREA: 602.784,
    FIPS: 'LA107',
    fips: '22107',
    StateAbbr: 'LA',
    StateName: 'Louisiana',
    lat: 32.00026867,
    lng: -91.34006167,
  },
  {
    GEO_ID: '0500000US22109',
    STATE: '22',
    COUNTY: '109',
    NAME: 'Terrebonne',
    LSAD: 'Parish',
    CENSUSAREA: 1231.815,
    FIPS: 'LA109',
    fips: '22109',
    StateAbbr: 'LA',
    StateName: 'Louisiana',
    lat: 29.40922061,
    lng: -90.86454347,
  },
  {
    GEO_ID: '0500000US21021',
    STATE: '21',
    COUNTY: '021',
    NAME: 'Boyle',
    LSAD: 'County',
    CENSUSAREA: 180.169,
    FIPS: 'KY021',
    fips: '21021',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 37.62427782,
    lng: -84.86612061,
  },
  {
    GEO_ID: '0500000US21023',
    STATE: '21',
    COUNTY: '023',
    NAME: 'Bracken',
    LSAD: 'County',
    CENSUSAREA: 205.612,
    FIPS: 'KY023',
    fips: '21023',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 38.68873502,
    lng: -84.08972896,
  },
  {
    GEO_ID: '0500000US22045',
    STATE: '22',
    COUNTY: '045',
    NAME: 'Iberia',
    LSAD: 'Parish',
    CENSUSAREA: 574.111,
    FIPS: 'LA045',
    fips: '22045',
    StateAbbr: 'LA',
    StateName: 'Louisiana',
    lat: 29.89484111,
    lng: -91.73103405,
  },
  {
    GEO_ID: '0500000US21047',
    STATE: '21',
    COUNTY: '047',
    NAME: 'Christian',
    LSAD: 'County',
    CENSUSAREA: 717.503,
    FIPS: 'KY047',
    fips: '21047',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 36.89391023,
    lng: -87.49033769,
  },
  {
    GEO_ID: '0500000US21055',
    STATE: '21',
    COUNTY: '055',
    NAME: 'Crittenden',
    LSAD: 'County',
    CENSUSAREA: 359.952,
    FIPS: 'KY055',
    fips: '21055',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 37.35257485,
    lng: -88.09694438,
  },
  {
    GEO_ID: '0500000US21059',
    STATE: '21',
    COUNTY: '059',
    NAME: 'Daviess',
    LSAD: 'County',
    CENSUSAREA: 458.346,
    FIPS: 'KY059',
    fips: '21059',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 37.73176143,
    lng: -87.08762263,
  },
  {
    GEO_ID: '0500000US21073',
    STATE: '21',
    COUNTY: '073',
    NAME: 'Franklin',
    LSAD: 'County',
    CENSUSAREA: 207.747,
    FIPS: 'KY073',
    fips: '21073',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 38.23894371,
    lng: -84.87704049,
  },
  {
    GEO_ID: '0500000US21075',
    STATE: '21',
    COUNTY: '075',
    NAME: 'Fulton',
    LSAD: 'County',
    CENSUSAREA: 205.504,
    FIPS: 'KY075',
    fips: '21075',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 36.55403726,
    lng: -89.18729157,
  },
  {
    GEO_ID: '0500000US21089',
    STATE: '21',
    COUNTY: '089',
    NAME: 'Greenup',
    LSAD: 'County',
    CENSUSAREA: 344.397,
    FIPS: 'KY089',
    fips: '21089',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 38.5461641,
    lng: -82.92215371,
  },
  {
    GEO_ID: '0500000US21095',
    STATE: '21',
    COUNTY: '095',
    NAME: 'Harlan',
    LSAD: 'County',
    CENSUSAREA: 465.828,
    FIPS: 'KY095',
    fips: '21095',
    StateAbbr: 'KY',
    StateName: 'Kentucky',
    lat: 36.85707442,
    lng: -83.21750489,
  },
  {
    GEO_ID: '0500000US55137',
    STATE: '55',
    COUNTY: '137',
    NAME: 'Waushara',
    LSAD: 'County',
    CENSUSAREA: 626.153,
    FIPS: 'WI137',
    fips: '55137',
    StateAbbr: 'WI',
    StateName: 'Wisconsin',
    lat: 44.11328215,
    lng: -89.24316913,
  },
  {
    GEO_ID: '0500000US56001',
    STATE: '56',
    COUNTY: '001',
    NAME: 'Albany',
    LSAD: 'County',
    CENSUSAREA: 4273.84,
    FIPS: 'WY001',
    fips: '56001',
    StateAbbr: 'WY',
    StateName: 'Wyoming',
    lat: 41.65453797,
    lng: -105.7236684,
  },
  {
    GEO_ID: '0500000US56007',
    STATE: '56',
    COUNTY: '007',
    NAME: 'Carbon',
    LSAD: 'County',
    CENSUSAREA: 7897.578,
    FIPS: 'WY007',
    fips: '56007',
    StateAbbr: 'WY',
    StateName: 'Wyoming',
    lat: 41.69413999,
    lng: -106.9303916,
  },
  {
    GEO_ID: '0500000US56011',
    STATE: '56',
    COUNTY: '011',
    NAME: 'Crook',
    LSAD: 'County',
    CENSUSAREA: 2854.408,
    FIPS: 'WY011',
    fips: '56011',
    StateAbbr: 'WY',
    StateName: 'Wyoming',
    lat: 44.58843934,
    lng: -104.5703226,
  },
  {
    GEO_ID: '0500000US56013',
    STATE: '56',
    COUNTY: '013',
    NAME: 'Fremont',
    LSAD: 'County',
    CENSUSAREA: 9183.814,
    FIPS: 'WY013',
    fips: '56013',
    StateAbbr: 'WY',
    StateName: 'Wyoming',
    lat: 43.04055711,
    lng: -108.6304217,
  },
  {
    GEO_ID: '0500000US56021',
    STATE: '56',
    COUNTY: '021',
    NAME: 'Laramie',
    LSAD: 'County',
    CENSUSAREA: 2685.912,
    FIPS: 'WY021',
    fips: '56021',
    StateAbbr: 'WY',
    StateName: 'Wyoming',
    lat: 41.30709358,
    lng: -104.6892193,
  },
  {
    GEO_ID: '0500000US54031',
    STATE: '54',
    COUNTY: '031',
    NAME: 'Hardy',
    LSAD: 'County',
    CENSUSAREA: 582.312,
    FIPS: 'WV031',
    fips: '54031',
    StateAbbr: 'WV',
    StateName: 'West Virginia',
    lat: 39.00759684,
    lng: -78.85797558,
  },
  {
    GEO_ID: '0500000US54035',
    STATE: '54',
    COUNTY: '035',
    NAME: 'Jackson',
    LSAD: 'County',
    CENSUSAREA: 464.348,
    FIPS: 'WV035',
    fips: '54035',
    StateAbbr: 'WV',
    StateName: 'West Virginia',
    lat: 38.83443006,
    lng: -81.674543,
  },
  {
    GEO_ID: '0500000US54041',
    STATE: '54',
    COUNTY: '041',
    NAME: 'Lewis',
    LSAD: 'County',
    CENSUSAREA: 384.895,
    FIPS: 'WV041',
    fips: '54041',
    StateAbbr: 'WV',
    StateName: 'West Virginia',
    lat: 38.99603963,
    lng: -80.50213826,
  },
  {
    GEO_ID: '0500000US50027',
    STATE: '50',
    COUNTY: '027',
    NAME: 'Windsor',
    LSAD: 'County',
    CENSUSAREA: 969.337,
    FIPS: 'VT027',
    fips: '50027',
    StateAbbr: 'VT',
    StateName: 'Vermont',
    lat: 43.57967872,
    lng: -72.58619896,
  },
  {
    GEO_ID: '0500000US51001',
    STATE: '51',
    COUNTY: '001',
    NAME: 'Accomack',
    LSAD: 'County',
    CENSUSAREA: 449.496,
    FIPS: 'VA001',
    fips: '51001',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 37.76647217,
    lng: -75.63628772,
  },
  {
    GEO_ID: '0500000US51021',
    STATE: '51',
    COUNTY: '021',
    NAME: 'Bland',
    LSAD: 'County',
    CENSUSAREA: 357.725,
    FIPS: 'VA021',
    fips: '51021',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 37.13389662,
    lng: -81.13059253,
  },
  {
    GEO_ID: '0500000US51027',
    STATE: '51',
    COUNTY: '027',
    NAME: 'Buchanan',
    LSAD: 'County',
    CENSUSAREA: 502.763,
    FIPS: 'VA027',
    fips: '51027',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 37.26641332,
    lng: -82.03580207,
  },
  {
    GEO_ID: '0500000US51037',
    STATE: '51',
    COUNTY: '037',
    NAME: 'Charlotte',
    LSAD: 'County',
    CENSUSAREA: 475.271,
    FIPS: 'VA037',
    fips: '51037',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 37.0117581,
    lng: -78.66167401,
  },
  {
    GEO_ID: '0500000US51041',
    STATE: '51',
    COUNTY: '041',
    NAME: 'Chesterfield',
    LSAD: 'County',
    CENSUSAREA: 423.297,
    FIPS: 'VA041',
    fips: '51041',
    StateAbbr: 'VA',
    StateName: 'Virginia',
    lat: 37.37880418,
    lng: -77.58654268,
  },
];
