import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import { format } from 'date-fns/format';
import { orderBy, uniq } from 'lodash';
import React from 'react';
import { CardIcon } from '../../components/card-icon';
import { DisplayValue } from '../../components/display-value';
import { RouteError } from '../../components/route-container/route-error';
import { AuthContext } from '../../contexts/auth-context';
import { LEAD_PRODUCT } from '../../types';
import { toCurrency } from '../../utils/formatter';
import { getProductColor, getProductTitle } from '../../utils/general';
import { getCountyByFIPS } from '../../utils/map';
import {
  OrderCartData,
  OrderDetailsData,
  getCartItemID,
  getDetailsFIPS,
} from './data';

export function OrderItem(props: {
  item: OrderCartData | undefined;
  onClose: () => void;
}) {
  // Props
  const { item } = props;
  // Context
  const {
    state: { user, impersonatedAgent },
  } = React.useContext(AuthContext);

  const handleClose = async () => {
    props.onClose();
  };

  // Query - Cart Details
  const path = '/leads/instant-purchase/cart-history/details';
  const query = useQuery({
    enabled: item !== undefined,
    queryKey: [path, { CartId: item?.CartId, BillToAgt: item?.BillToAgt }],
    queryFn: async () => {
      const response: {
        data: OrderDetailsData[];
      } = await API.post('LeadsAPI', path, {
        body: { CartId: item?.CartId, BillToAgt: item?.BillToAgt },
      });

      return response.data;
    },
  });

  const currentUser = impersonatedAgent?.AgtNo || user?.getUsername();

  const counties = orderBy(uniq(query.data?.map((i) => i.FIPS)));

  return (
    <Drawer
      container={window.document.body}
      variant="temporary"
      anchor="right"
      open={props.item !== undefined}
      onClose={handleClose}
      sx={{
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: { xs: '100vw', md: '60vw', lg: '50vw', xl: '40vw' },
          height: '100dvh',
        },
      }}
    >
      <Box sx={{ height: '100dvh', display: 'flex', flexDirection: 'column' }}>
        <Toolbar>
          <IconButton sx={{ mr: 2 }} onClick={handleClose}>
            <CloseIcon />
          </IconButton>

          <Box sx={{ flex: 1, display: 'flex', alignItems: 'center' }}>
            <Typography variant="h6" noWrap component="div">
              Order
            </Typography>

            <Box sx={{ flex: 1 }} />

            <Box>
              <Stack
                spacing={1}
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
              >
                <Box sx={{ fontSize: 14 }}>Leads</Box>

                <Box
                  sx={{
                    textAlign: 'right',
                    fontSize: 16,
                    fontFamily: 'Roboto Mono',
                    fontWeight: 'bold',
                    color: '#2196f3',
                  }}
                >
                  {item?.TotalLeads}
                </Box>
              </Stack>
              <Stack
                spacing={1}
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
              >
                <Box sx={{ fontSize: 14 }}>Counties</Box>

                <Box
                  sx={{
                    textAlign: 'right',
                    fontSize: 16,
                    fontFamily: 'Roboto Mono',
                    fontWeight: 'bold',
                    color: '#b26500',
                  }}
                >
                  {item?.TotalCounties}
                </Box>
              </Stack>
            </Box>
          </Box>
        </Toolbar>

        <Divider />

        {query.isLoading ? (
          <LinearProgress />
        ) : query.isError ? (
          <RouteError />
        ) : item ? (
          <Box
            sx={{
              minHeight: 0,
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              overflow: 'auto',
            }}
          >
            <Stack spacing={1} sx={{ p: 2, pb: 16 }}>
              <DisplayValue
                label="Order Placed"
                value={
                  item.ComplDate ? (
                    <Box sx={{ fontSize: { xs: 13, md: 16 } }}>
                      {format(new Date(item.ComplDate), 'PPPPpppp')}
                    </Box>
                  ) : null
                }
              />

              <DisplayValue
                label="Order Total"
                monospace
                value={toCurrency({ value: item.FinalTotal })}
              />

              {item.AcctType || item.AcctNumber ? (
                <DisplayValue
                  label="Payment Info"
                  value={
                    <Stack spacing={1} direction="row" alignItems="center">
                      {item.AcctType ? (
                        <CardIcon cardType={item.AcctType} size="lg" />
                      ) : null}

                      {item.AcctNumber ? (
                        <Box sx={{ fontFamily: 'Roboto Mono', fontSize: 18 }}>
                          {item.AcctNumber}
                        </Box>
                      ) : null}
                    </Stack>
                  }
                />
              ) : null}

              {counties.map((FIPS) => {
                const county = getCountyByFIPS(FIPS);
                const details = getDetailsFIPS({
                  data: query.data || [],
                  FIPS,
                });

                return (
                  <Paper
                    key={FIPS}
                    elevation={0}
                    sx={{ border: '1px solid #00000022', overflow: 'hidden' }}
                  >
                    <Box sx={{ p: 1 }}>
                      <Stack spacing={1} direction="row" alignItems="center">
                        <Box
                          sx={{
                            color: '#b26500',
                            fontSize: { xs: 16, md: 22 },
                            fontWeight: 'bold',
                            fontFamily: 'Roboto Mono',
                          }}
                        >
                          {county?.StateAbbr}
                        </Box>

                        <Box sx={{ fontSize: { xs: 18, md: 24 } }}>
                          {county?.NAME}
                        </Box>

                        <Box sx={{ flex: 1, borderTop: '1px solid #eaeaea' }} />

                        <Box
                          sx={{ fontFamily: 'Roboto Mono', color: '#2e7d32' }}
                        >
                          {details.quantity}
                        </Box>

                        <Box
                          sx={{ width: 8, borderTop: '1px solid #eaeaea' }}
                        />

                        <Box
                          sx={{
                            fontFamily: 'Roboto Mono',
                            fontSize: 14,
                            color: '#9b9b9b',
                          }}
                        >
                          {toCurrency({ value: details.spend })}
                        </Box>
                      </Stack>
                    </Box>

                    <Box sx={{ px: 1 }}>
                      <Stack spacing={1}>
                        {Object.keys(LEAD_PRODUCT).map((Product) => {
                          const title = getProductTitle(
                            Product as LEAD_PRODUCT,
                          );
                          const color = getProductColor(
                            Product as LEAD_PRODUCT,
                          );
                          const productPurchases = details.purchases.filter(
                            (i) => i.Product === Product,
                          );

                          if (productPurchases.length) {
                            return (
                              <Box key={Product}>
                                <Box sx={{ color, fontWeight: 'bold' }}>
                                  {title}
                                </Box>
                                <Stack spacing={1}>
                                  {productPurchases.map((purchase) => {
                                    const itemID = getCartItemID({
                                      data: purchase,
                                    });
                                    const isSelf =
                                      purchase.RecAgt === currentUser;
                                    return (
                                      <Box key={itemID} sx={{ pl: 1 }}>
                                        <Stack
                                          spacing={1}
                                          direction="row"
                                          alignItems="center"
                                        >
                                          <Box
                                            sx={{
                                              fontFamily: 'Roboto Mono',
                                              fontWeight: 'bold',
                                              fontSize: 24,
                                              color: 'teal',
                                            }}
                                          >
                                            {purchase.LeadType}
                                          </Box>

                                          <Stack>
                                            <Box>
                                              for{' '}
                                              <Box
                                                component="span"
                                                sx={{
                                                  fontFamily: 'Roboto Mono',
                                                  fontWeight: 'bold',
                                                  fontSize: 14,
                                                  color: isSelf
                                                    ? '#b26500'
                                                    : 'purple',
                                                }}
                                              >
                                                {purchase.RecAgt}
                                              </Box>
                                            </Box>

                                            {purchase.Exclusive ? (
                                              <Box
                                                sx={{
                                                  textTransform: 'uppercase',
                                                  fontWeight: 'bold',
                                                  fontSize: 12,
                                                  color: 'darkorange',
                                                }}
                                              >
                                                Exclusive
                                              </Box>
                                            ) : null}
                                          </Stack>

                                          <Box
                                            sx={{
                                              flex: 1,
                                              borderTop: '1px solid #eaeaea',
                                            }}
                                          />

                                          <Box
                                            sx={{ fontFamily: 'Roboto Mono' }}
                                          >
                                            {purchase.TotalLeads}
                                          </Box>

                                          <Box
                                            sx={{
                                              width: 8,
                                              borderTop: '1px solid #eaeaea',
                                            }}
                                          />

                                          <Box
                                            sx={{
                                              fontFamily: 'Roboto Mono',
                                              fontSize: 14,
                                              color: '#9b9b9b',
                                            }}
                                          >
                                            {toCurrency({
                                              value: purchase.TotalPrice,
                                            })}
                                          </Box>
                                        </Stack>
                                      </Box>
                                    );
                                  })}
                                </Stack>
                              </Box>
                            );
                          } else {
                            return null;
                          }
                        })}
                      </Stack>
                    </Box>
                  </Paper>
                );
              })}
            </Stack>
          </Box>
        ) : null}
      </Box>
    </Drawer>
  );
}
