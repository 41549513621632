import { Auth, Hub } from 'aws-amplify';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { AppProvider } from './contexts/app-context';
import { AuthContext } from './contexts/auth-context';
import { CartProvider } from './contexts/cart-context';
import { Root } from './routes/root';

export function App() {
  // Context
  const { dispatch } = React.useContext(AuthContext);

  React.useEffect(() => {
    // Amplify has a local eventing system called Hub
    // Amplify’s Auth category publishes in the auth channel
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
          dispatch({ type: 'USER_SET', payload: data });
          break;
        case 'tokenRefresh_failure':
          dispatch({ type: 'TOKEN_ERROR' });
          break;
        case 'signOut':
          dispatch({ type: 'USER_CLEAR' });
          break;
      }
    });

    const checkForUser = async () => {
      try {
        dispatch({ type: 'SET_CHECKING', payload: true });

        // Used to check if a user is logged in when the page is loaded
        const user = await Auth.currentAuthenticatedUser();
        dispatch({ type: 'USER_SET', payload: user });

        // Provides decoded JWT Tokens
        const userSession = await Auth.currentSession();
        dispatch({ type: 'SESSION_SET', payload: userSession });
      } catch (error) {
        // Will throw an error if there is no user logged in
        dispatch({ type: 'USER_CLEAR' });
      } finally {
        dispatch({ type: 'SET_CHECKING', payload: false });
      }
    };

    checkForUser();
  }, [dispatch]);

  return (
    <AppProvider>
      <CartProvider>
        <Router>
          <Root />
        </Router>
      </CartProvider>
    </AppProvider>
  );
}
