import { Edit } from '@mui/icons-material';
import Close from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import React from 'react';
import { AuthContext } from '../../contexts/auth-context';
import { captureError } from '../../utils/capture-error';
import { LeadSubData } from './data';

export function UpdateRegions(props: {
  item: LeadSubData | undefined;
  onClose: () => void;
}) {
  // Props
  const { item } = props;
  // Context
  const {
    state: { impersonatedAgent, user },
  } = React.useContext(AuthContext);
  // State
  const [open, setOpen] = React.useState(false);
  const [subRegions, setSubRegions] = React.useState<any>([]);
  const AgtNo = impersonatedAgent?.AgtNo || user?.getUsername();

  const handleOpen = () => {
    setOpen(true);
    setSubRegions(item?.Regions?.map((r) => r.FIPSRegId));
  };

  // Query - Get Regions
  const path = '/leads/subscriptions/regions';
  const query = useQuery({
    enabled: open === true,
    queryKey: [path, { FIPS: item?.FIPS }],
    queryFn: async () => {
      const response: {
        data: [{ FIPSRegId: number | null; Region: string | null }];
      } = await API.post('LeadsAPI', path, {
        body: { FIPS: item?.FIPS },
      });
      return response.data;
    },
  });

  const queryClient = useQueryClient();
  // Mutation - Update Subscription's Regions
  const mutation = useMutation({
    mutationFn: async () => {
      await API.post('LeadsAPI', '/admin/leads/subscriptions/update-regions', {
        body: { LeadSubId: item?.LeadSubId, SubRegions: subRegions },
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['/leads/subscriptions', { AgtNo }],
      });
      await queryClient.invalidateQueries({
        queryKey: ['/admin/leads/subscriptions', { FIPS: item?.FIPS }],
      });
      setOpen(false);
    },
    onError: (error: any) => {
      captureError({ data: { error } });
    },
  });

  const noRegions = !query.isLoading && query.data && query.data?.length < 1;

  return (
    <React.Fragment>
      <Tooltip placement="top" arrow title="Update Regions">
        <IconButton size="small" onClick={handleOpen}>
          {query.isLoading && (
            <CircularProgress
              color="info"
              size={18}
              sx={{ position: 'absolute' }}
            />
          )}
          <Edit fontSize="small" color={query.isLoading ? undefined : 'info'} />
        </IconButton>
      </Tooltip>

      <Dialog open={open && !query.isLoading} maxWidth="sm" fullWidth>
        <DialogTitle>
          <Stack spacing={1} direction="row" alignItems="center">
            <Box>Update Regions</Box>
            <Box sx={{ flex: 1 }} />
            <IconButton size="small" onClick={() => setOpen(false)}>
              <Close fontSize="small" />
            </IconButton>
          </Stack>
        </DialogTitle>
        <Divider />

        {noRegions && (
          <Alert severity="warning">No regions found for this county.</Alert>
        )}

        {mutation.isError && (
          <Alert severity="error" onClose={() => mutation.reset()}>
            <strong>An error has occured!</strong>
          </Alert>
        )}

        <Stack spacing={2} sx={{ py: 2, px: 3 }}>
          {query.data && query.data?.length ? (
            <Stack spacing={1}>
              <Box>
                <Box sx={{ fontSize: 18 }}>
                  Select specific Regions for this County:
                </Box>
                <Box
                  component="small"
                  sx={{ fontSize: 12, fontStyle: 'italic' }}
                >
                  If no Regions are selected the subscription will be for the
                  entire county.
                </Box>
              </Box>

              {query.data?.map((region) => {
                const isChecked = Boolean(
                  subRegions.find((s: any) => s === region?.FIPSRegId),
                );

                return (
                  <Paper
                    key={region.FIPSRegId}
                    elevation={0}
                    sx={{
                      borderWidth: 1,
                      borderStyle: 'solid',
                      borderColor: isChecked ? '#2196f3' : '#00000022',
                      overflow: 'hidden',
                    }}
                  >
                    <Stack spacing={1} direction="row" alignItems="center">
                      <Checkbox
                        size="small"
                        color="info"
                        disabled={mutation.isPending}
                        checked={isChecked}
                        onChange={(event, checked) => {
                          if (checked) {
                            setSubRegions((currentState: any) => [
                              ...currentState,
                              region.FIPSRegId,
                            ]);
                          } else {
                            setSubRegions((currentState: any) =>
                              // Filter out this Region
                              currentState.filter(
                                (s: number) => s !== region.FIPSRegId,
                              ),
                            );
                          }
                        }}
                      />

                      <Box sx={{ fontWeight: 'bold' }}>{region.Region}</Box>
                    </Stack>
                  </Paper>
                );
              })}
            </Stack>
          ) : null}

          <Button
            variant="outlined"
            fullWidth
            onClick={() => setSubRegions([])}
          >
            Clear
          </Button>

          <Button
            disabled={noRegions || mutation.isError || mutation.isPending}
            variant="contained"
            fullWidth
            onClick={() => mutation.mutate()}
          >
            {mutation.isPending ? 'Saving Changes...' : 'Save Changes'}
          </Button>
        </Stack>
      </Dialog>
    </React.Fragment>
  );
}
