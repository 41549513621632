import { Edit } from '@mui/icons-material';
import Close from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import { format } from 'date-fns/format';
import React from 'react';
import { AuthContext } from '../../contexts/auth-context';
import { captureError } from '../../utils/capture-error';
import { LeadSubData } from './data';

export function UpdateCancelledDate(props: {
  item: LeadSubData | undefined;
  onClose: () => void;
}) {
  // Props
  const { item } = props;
  // Context
  const {
    state: { impersonatedAgent, user },
  } = React.useContext(AuthContext);
  // State
  const [newCancelDate, setNewCancelDate] = React.useState(item?.CancelledDate);
  const [open, setOpen] = React.useState(false);
  const AgtNo = impersonatedAgent?.AgtNo || user?.getUsername();

  const queryClient = useQueryClient();
  // Mutation - Update Subscription Cancellation Date
  const mutation = useMutation({
    mutationFn: async () => {
      await API.post(
        'LeadsAPI',
        '/admin/leads/subscriptions/update-cancel-date',
        {
          body: {
            LeadSubId: item?.LeadSubId,
            BillToAgt: item?.BillToAgt,
            CancelledDate: newCancelDate,
          },
        },
      );
    },
    onSuccess: async () => {
      // Refetch subscriptions for user
      await queryClient.invalidateQueries({
        queryKey: ['/leads/subscriptions', { AgtNo }],
      });
      await queryClient.invalidateQueries({
        queryKey: ['/admin/leads/subscriptions', { FIPS: item?.FIPS }],
      });
      setOpen(false);
    },
    onError: (error) => captureError({ data: { error } }),
  });

  return (
    <React.Fragment>
      <Tooltip placement="top" arrow title="Adjust cancellation date">
        <IconButton
          size="small"
          onClick={() => {
            setOpen(true);
            setNewCancelDate(item?.CancelledDate);
          }}
        >
          <Edit fontSize="small" color="info" />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          <Stack spacing={1} direction="row" alignItems="center">
            <Box>Adjust Cancellation Date</Box>
            <Box sx={{ flex: 1 }} />
            <IconButton size="small" onClick={() => setOpen(false)}>
              <Close fontSize="small" />
            </IconButton>
          </Stack>
        </DialogTitle>
        <Divider />

        {mutation.isError && (
          <Alert severity="error" onClose={() => mutation.reset()}>
            An error has occured.
          </Alert>
        )}
        <Stack spacing={2} sx={{ p: 2 }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              fontWeight: 'bold',
              fontSize: 18,
            }}
          >
            Current Expiration Date:{' '}
            {format(new Date(item?.CancelledDate + 'T00:00'), 'MM/dd/yyyy')}
          </Box>
          <Box>
            <TextField
              fullWidth
              type="date"
              value={newCancelDate}
              label="New Cancellation Date"
              onChange={(e) => setNewCancelDate(e.target.value)}
            />
            <Button
              sx={{ mt: 1 }}
              fullWidth
              color="info"
              size="small"
              variant="outlined"
              disabled={mutation.isPending}
              onClick={() => setNewCancelDate(format(new Date(), 'yyyy-MM-dd'))}
            >
              Choose Today's Date
            </Button>
          </Box>

          <Box sx={{ pt: 1 }}>
            <Button
              fullWidth
              color="info"
              variant="contained"
              disabled={mutation.isPending || mutation.isError}
              onClick={() => mutation.mutate()}
            >
              {mutation.isPending ? 'Saving...' : 'Save Changes'}
            </Button>
          </Box>
        </Stack>
      </Dialog>
    </React.Fragment>
  );
}
