import PeopleIcon from '@mui/icons-material/People';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { format } from 'date-fns/format';
import { LeadData } from '../../types';
import { formatDate } from '../../utils/formatter';
import { getProductColor, getProductTitle } from '../../utils/general';
import { leadStatuses } from './data';

export function LeadListItemContent(props: { item: LeadData }) {
  // Props
  const { item } = props;

  const statusType = leadStatuses.find((i) => i.status === item.ContactStatus);

  return (
    <Stack
      spacing={1}
      direction="row"
      alignItems="center"
      sx={{ color: '#000' }}
    >
      <Box sx={{ py: { xs: 0.5, md: 1 }, px: { xs: 0, md: 0.5 } }}>
        <Box
          sx={{
            backgroundColor: statusType?.color || '#e1e1e1',
            borderRadius: 2,
            height: 36,
            width: 6,
          }}
        />
      </Box>
      <Box>
        <Stack spacing={1} direction="row" alignItems="flex-end">
          <Box sx={{ fontSize: { xs: 14, md: 22 } }}>
            {item.FirstName} {item.LastName}
            {item.Age ? ',' : null}
          </Box>

          {item.Age ? (
            <Box component="span" sx={{ fontSize: { xs: 12, md: 16 } }}>
              {item.Age}{' '}
              <Box
                component="span"
                sx={{ display: { xs: 'none', md: 'inline-block' } }}
              >
                years old
              </Box>
            </Box>
          ) : null}

          {item.SpouseName ? (
            <Tooltip title="has Spouse Info" placement="right" arrow>
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <PeopleIcon color="disabled" fontSize="small" />
              </Box>
            </Tooltip>
          ) : null}
        </Stack>

        <Stack spacing={1} direction="row" alignItems="center" sx={{ pt: 0.2 }}>
          <Box
            component="small"
            sx={{
              fontFamily: 'Roboto Mono',
              fontWeight: 'bold',
              color: 'teal',
            }}
          >
            {item.LeadType}
          </Box>

          <Box
            sx={{
              height: 4,
              width: 4,
              borderRadius: 1,
              backgroundColor: '#7a7a7a',
            }}
          />

          <Box component="small">
            <Box
              sx={{
                textTransform: 'uppercase',
                fontWeight: 'bold',
                fontFamily: 'Roboto Mono',
                letterSpacing: -0.1,
                color: '#9b9b9b',
                fontSize: 10,
                display: { xs: 'none', md: 'inline-block' },
              }}
            >
              {item.RecDate
                ? format(
                    new Date(formatDate({ value: item.RecDate })),
                    'EEE, LLL do, yyyy',
                  )
                : null}
            </Box>

            <Box
              sx={{
                textTransform: 'uppercase',
                fontWeight: 'bold',
                fontFamily: 'Roboto Mono',
                letterSpacing: -0.1,
                color: '#9b9b9b',
                fontSize: 10,
                display: { xs: 'inline-block', md: 'none' },
              }}
            >
              {item.RecDate
                ? format(
                    new Date(formatDate({ value: item.RecDate })),
                    'EEE, LLL do',
                  )
                : null}
            </Box>
          </Box>

          <Box
            sx={{
              height: 4,
              width: 4,
              borderRadius: 1,
              backgroundColor: '#7a7a7a',
            }}
          />

          <Box component="small">
            <Box
              sx={{
                textTransform: 'uppercase',
                fontWeight: 'bold',
                color: getProductColor(item.Product),
                fontSize: 10,
                display: { xs: 'inline-block', md: 'none' },
              }}
            >
              {item.Product}
            </Box>

            <Box
              sx={{
                textTransform: 'uppercase',
                fontWeight: 'bold',
                color: getProductColor(item.Product),
                fontSize: 10,
                display: { xs: 'none', md: 'inline-block' },
              }}
            >
              {getProductTitle(item.Product)}
            </Box>
          </Box>

          {item.Exclusive ? (
            <Stack spacing={1} direction="row" alignItems="center">
              <Box
                sx={{
                  height: 4,
                  width: 4,
                  borderRadius: 1,
                  backgroundColor: '#7a7a7a',
                }}
              />

              <Box
                component="small"
                sx={{
                  textTransform: 'uppercase',
                  fontWeight: 'bold',
                  color: 'darkorange',
                  fontSize: 10,
                }}
              >
                <Box
                  component="span"
                  sx={{ display: { xs: 'inline', md: 'none' } }}
                >
                  Exc
                </Box>
                <Box
                  component="span"
                  sx={{ display: { xs: 'none', md: 'inline' } }}
                >
                  Exclusive
                </Box>
              </Box>
            </Stack>
          ) : null}

          {item.AgtExport ? (
            <Stack spacing={1} direction="row" alignItems="center">
              <Box
                sx={{
                  height: 4,
                  width: 4,
                  borderRadius: 1,
                  backgroundColor: '#7a7a7a',
                }}
              />

              <Box
                sx={{
                  fontSize: 10,
                  fontWeight: 'bold',
                  fontFamily: 'Roboto Mono',
                  textTransform: 'uppercase',
                  color: '#2196f3',
                }}
              >
                <Box
                  component="span"
                  sx={{ display: { xs: 'inline', md: 'none' } }}
                >
                  Exp
                </Box>
                <Box
                  component="span"
                  sx={{ display: { xs: 'none', md: 'inline' } }}
                >
                  Exported to PDF
                </Box>
              </Box>
            </Stack>
          ) : null}
        </Stack>
      </Box>

      <Box sx={{ flex: 1 }} />

      <Stack spacing={1} direction="row">
        <Box sx={{ textAlign: 'right' }}>
          <Box sx={{ fontSize: { xs: 12, md: 16 } }}>{item.City}</Box>
          <Box
            component="small"
            sx={{
              textTransform: 'uppercase',
              fontWeight: 'bold',
              color: '#9b9b9b',
              letterSpacing: 1.1,
              fontSize: { xs: 10, md: 'inherit' },
            }}
          >
            {item.County}
          </Box>
        </Box>
        <Box
          sx={{
            color: '#b26500',
            fontSize: { xs: 18, md: 22 },
            fontWeight: 'bold',
            fontFamily: 'Roboto Mono',
          }}
        >
          {item.St}
        </Box>
      </Stack>
    </Stack>
  );
}

export function LeadListItem(props: { item: LeadData; onClick: () => void }) {
  // Props
  const { item } = props;

  return (
    <Box
      component="button"
      sx={{
        pr: { xs: 1, md: 2 },
        fontFamily: 'Roboto',
        textAlign: 'left',
        borderWidth: 2,
        borderStyle: 'solid',
        borderColor: '#eaeaea',
        borderRadius: 1,
        backgroundColor: '#fff',
        cursor: 'pointer',
        transition: 'all 0.2s',
        ':hover': {
          transform: 'scale(1.01)',
          borderColor: '#aeaeae',
        },
      }}
      onClick={props.onClick}
    >
      <LeadListItemContent item={item} />
    </Box>
  );
}
