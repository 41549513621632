import Alert, { AlertProps } from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

interface ErrorResponse {
  status: string | null;
  errors: { field: string | null; type: string | null; msg: string | null }[];
}

export function DisplayMutationError(props: {
  isError: boolean;
  error: Error | null;
  onClose: AlertProps['onClose'];
  displayMore?: boolean; // Display both "invalid" and "error" statuses instead of just "invalid"
  alertTitle?: string;
}) {
  if (props.isError) {
    // @ts-ignore
    const data: ErrorResponse | undefined = props.error?.response?.data;

    const displayErrors =
      props.displayMore || (data && data.status === 'invalid');

    return (
      <Alert severity="error" onClose={props.onClose}>
        <AlertTitle>{props.alertTitle || 'Error'}</AlertTitle>

        {displayErrors && data && data.errors.length ? (
          <Stack spacing={1}>
            {data.errors.map((item, index) => {
              const { field, type, msg } = item;
              return (
                <Stack
                  key={index}
                  spacing={1}
                  direction="row"
                  alignItems="center"
                >
                  {field ? <Box component="strong">{field}</Box> : null}
                  {type ? <Box component="strong">{type}</Box> : null}
                  {msg ? <Box sx={{ fontStyle: 'italic' }}>{msg}</Box> : null}
                </Stack>
              );
            })}
          </Stack>
        ) : (
          'An error has occured. Please refresh the page and try again.'
        )}
      </Alert>
    );
  } else {
    return null;
  }
}
