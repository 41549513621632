import React, { Dispatch } from 'react';

// State
type AppState = {
  drawerOpen: boolean;
};
const initialState: AppState = {
  drawerOpen: false,
};

// Actions
type AppActions = { type: 'TOGGLE_DRAWER' } | { type: 'DRAWER_CLOSE' };

const reducer = (state: AppState, action: AppActions) => {
  switch (action.type) {
    case 'TOGGLE_DRAWER': {
      return { ...state, drawerOpen: !state.drawerOpen };
    }
    case 'DRAWER_CLOSE': {
      return { ...state, drawerOpen: false };
    }
    default: {
      return { ...state };
    }
  }
};

export const AppContext = React.createContext(
  {} as {
    state: AppState;
    dispatch: Dispatch<AppActions>;
  },
);

export function AppProvider(props: { children: React.ReactNode }) {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {props.children}
    </AppContext.Provider>
  );
}
