import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { RouteContainer } from '../../components/route-container';
import { appTheme } from '../../theme';
import { Purchases } from './purchases';
import { Subscriptions } from './subscriptions';

const tabItems: { label: string; value?: string }[] = [
  { label: 'Subscriptions' },
  { label: 'Purchases' },
];

export function Team() {
  // Hooks
  const [searchParams, setSearchParams] = useSearchParams();

  const currentTab = decodeURIComponent(
    searchParams.get('tab') || tabItems[0].value || tabItems[0].label,
  );

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    // Encode the value before setting it in the URL
    setSearchParams({ tab: encodeURIComponent(newValue) });
  };

  return (
    <RouteContainer routeTitle="Team Management" noanimation>
      <Box sx={{ width: '100%' }}>
        <Box
          sx={{
            position: 'fixed',
            width: { xs: '100%', sm: `calc(100% - ${appTheme.drawerWidth}px)` },
            borderBottom: 1,
            borderColor: 'divider',
            zIndex: 4,
            backgroundColor: '#fff',
          }}
        >
          <Tabs
            value={currentTab}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            indicatorColor="secondary"
          >
            {tabItems.map((item) => {
              return (
                <Tab
                  key={item.label}
                  label={item.label}
                  value={item.value || item.label}
                />
              );
            })}
          </Tabs>
        </Box>

        <Tabs />

        <Box sx={{ width: '100%' }}>
          {currentTab === 'Subscriptions' ? <Subscriptions /> : null}

          {currentTab === 'Purchases' ? <Purchases /> : null}
        </Box>
      </Box>
    </RouteContainer>
  );
}
