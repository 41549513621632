import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { orderBy } from 'lodash';
import { CartSubData } from '../../routes/subscriptions/data';
import { LEAD_PRODUCT } from '../../types';
import { toCurrency } from '../../utils/formatter';
import { getProductColor, getProductTitle } from '../../utils/general';
import { getCountyByFIPS } from '../../utils/map';
import { sumArray } from '../../utils/sumarray';
import { CartSubscriptionItem } from './cart-subscription-item';

export function CartSubscriptionCounty(props: {
  FIPS: string;
  subs: CartSubData[];
}) {
  // Props
  const { FIPS, subs } = props;

  const county = getCountyByFIPS(FIPS);

  const countySubsCount = subs.length;
  const countyInvestment = sumArray(subs.map((i) => Number(i.WklyInv)));

  return (
    <Paper
      key={FIPS}
      elevation={0}
      sx={{
        border: '1px solid #00000022',
        overflow: 'hidden',
      }}
    >
      <Stack
        spacing={1}
        direction="row"
        alignItems="center"
        sx={{ p: 1, py: 0.5 }}
      >
        <Box
          sx={{
            color: '#b26500',
            fontSize: { xs: 16, md: 22 },
            fontWeight: 'bold',
            fontFamily: 'Roboto Mono',
          }}
        >
          {county?.StateAbbr}
        </Box>

        <Box sx={{ fontSize: { xs: 18, md: 24 } }}>{county?.NAME}</Box>

        <Box
          sx={{
            fontWeight: 'bold',
            fontFamily: 'Roboto Mono',
            color: '#2e7d32',
          }}
        >
          {countySubsCount}
        </Box>

        <Box sx={{ flex: 1, borderTop: '1px solid #eaeaea' }} />

        <Box
          sx={{
            fontFamily: 'Roboto Mono',
            fontSize: 14,
            color: '#9b9b9b',
          }}
        >
          {toCurrency({ value: countyInvestment })}
        </Box>
      </Stack>

      <Divider />

      <Stack spacing={1} sx={{ p: 1 }}>
        {Object.keys(LEAD_PRODUCT).map((Product) => {
          const subsForProduct = orderBy(
            subs.filter((i) => i.Product === Product),
            'LeadType',
            'asc',
          );

          if (subsForProduct.length) {
            return (
              <Stack key={`${props.FIPS}_${Product}`}>
                <Box
                  sx={{
                    fontWeight: 'bold',
                    color: getProductColor(Product as LEAD_PRODUCT),
                  }}
                >
                  {getProductTitle(Product as LEAD_PRODUCT)}
                </Box>

                <Stack spacing={1}>
                  {subsForProduct.map((item) => {
                    return (
                      <CartSubscriptionItem key={item.TempID} item={item} />
                    );
                  })}
                </Stack>
              </Stack>
            );
          } else {
            return null;
          }
        })}
      </Stack>
    </Paper>
  );
}
