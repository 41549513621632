import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { CartSubData } from '../../routes/subscriptions/data';
import { toCurrency } from '../../utils/formatter';

export function CartSubscriptionItem(props: { item: CartSubData }) {
  // Props
  const { item } = props;

  return (
    <Stack key={item.TempID} spacing={1} direction="row" alignItems="center">
      <Box
        sx={{
          px: { xs: 0, md: 1 },
          fontSize: 18,
          fontWeight: 'bold',
          fontFamily: 'Roboto Mono',
          color: 'teal',
        }}
      >
        {item.LeadType}
      </Box>

      <Stack>
        {item.Exclusive ? (
          <Box
            component="small"
            sx={{
              textTransform: 'uppercase',
              fontWeight: 'bold',
              color: 'darkorange',
              fontSize: 10,
            }}
          >
            Exclusive
          </Box>
        ) : null}

        {item.RecAgt ? (
          <Box component="span">
            <Box component="strong">
              {item.RecAgt.Fname} {item.RecAgt.Lname}
            </Box>{' '}
            <Box
              component="span"
              sx={{
                fontFamily: 'Roboto Mono',
                fontWeight: 'bold',
                fontSize: 12,
                color: 'purple',
              }}
            >
              {item.RecAgt.AgtNo}
            </Box>
          </Box>
        ) : null}

        {item.SubRegions.length ? (
          <Stack
            spacing={1}
            direction="row"
            alignItems="center"
            sx={{ flexWrap: 'wrap' }}
          >
            {item.SubRegions.map((region) => {
              return (
                <Box
                  key={region.FIPSRegId}
                  sx={{
                    fontWeight: 'bold',
                    fontSize: 12,
                    border: '1px solid #0002',
                    borderRadius: 1,
                    px: 0.5,
                    textWrap: 'nowrap',
                  }}
                >
                  {region.Region}
                </Box>
              );
            })}
          </Stack>
        ) : null}
      </Stack>

      <Box
        sx={{
          flex: 1,
          borderTop: '1px solid #eaeaea',
        }}
      />

      <Box
        sx={{
          fontFamily: 'Roboto Mono',
          fontSize: 14,
          color: '#9b9b9b',
        }}
      >
        {toCurrency({ value: Number(item.WklyInv) })}
      </Box>
    </Stack>
  );
}
