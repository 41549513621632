import { Edit } from '@mui/icons-material';
import Close from '@mui/icons-material/Close';
import { Alert, CircularProgress, Divider } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import { floor } from 'lodash';
import React from 'react';
import { DisplayMutationError } from '../../components/display-mutation-error';
import { AuthContext } from '../../contexts/auth-context';
import { usePermissions } from '../../hooks/use-permissions';
import { AWS_GROUP, LEAD_PRODUCT } from '../../types';
import { captureError } from '../../utils/capture-error';
import { toCurrency } from '../../utils/formatter';
import { multNums } from '../../utils/mult-nums';
import { LeadSubData } from './data';

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export function EditWeeklyInvestment(props: {
  item: LeadSubData | undefined;
  onClose: () => void;
}) {
  // Props
  const { item } = props;
  // Context
  const {
    state: { impersonatedAgent, user },
  } = React.useContext(AuthContext);
  // Hooks
  const { hasGroup } = usePermissions();
  // State
  const [newWklyInv, setNewWklyInv] = React.useState<number | null>(null);

  const AgtNo = impersonatedAgent?.AgtNo || user?.getUsername();
  const isAdmin = hasGroup(AWS_GROUP.LDS_GlobalAdmin);

  // Query - Available Leads
  const path = '/leads/subscriptions/available-leads';
  const body = {
    FIPS: item?.FIPS,
    Product: item?.Product,
    LeadType: item?.LeadType,
  };
  const queryLeads = useQuery({
    enabled: newWklyInv !== null,
    queryKey: [path, { body }],
    queryFn: async () => {
      const response: {
        data: { AvailableLeads: number | null };
      } = await API.post('LeadsAPI', path, {
        body,
      });

      return response.data;
    },
  });

  const queryClient = useQueryClient();
  // Mutation - Update Weekly Investment Subscription
  let mutationUpdatePath = '/leads/subscriptions/update';
  if (isAdmin) {
    mutationUpdatePath = '/admin/leads/subscriptions/update';
  }
  const mutationUptInv = useMutation({
    mutationFn: async () => {
      await API.post('LeadsAPI', mutationUpdatePath, {
        body: {
          LeadSubId: item?.LeadSubId,
          BillToAgt: item?.BillToAgt,
          WklyInv: newWklyInv,
        },
      });
    },
    onSuccess: async () => {
      setNewWklyInv(null);
      // Refetch subscriptions for user
      await queryClient.invalidateQueries({
        queryKey: ['/leads/subscriptions', { AgtNo }],
      });
      await queryClient.invalidateQueries({
        queryKey: ['/admin/leads/subscriptions', { FIPS: item?.FIPS }],
      });
    },
    onError: (error) => captureError({ data: { error } }),
  });

  const availableLeads = queryLeads.data?.AvailableLeads || 0;

  const limitExceeded =
    floor(((newWklyInv || 0) - (item?.WklyInv || 0)) / (item?.LeadPrice || 0)) >
    availableLeads;

  const disableSubmit =
    limitExceeded || queryLeads.isLoading || mutationUptInv.isPending;

  const currentLeadAmt = floor((item?.WklyInv || 0) / (item?.LeadPrice || 0));

  return (
    <React.Fragment>
      <Tooltip title="Adjust Weekly Investment" placement="top" arrow>
        <IconButton
          sx={{
            ml: 1,
            opacity: item?.Product === LEAD_PRODUCT['ANN'] ? 0.4 : 1,
          }}
          disabled={
            queryLeads.isLoading ||
            item?.Product === LEAD_PRODUCT['ANN'] ||
            item?.Status !== 'Active'
          }
          size="small"
          onClick={() => setNewWklyInv(item?.WklyInv || null)}
        >
          {queryLeads.isLoading && (
            <CircularProgress
              color="info"
              size={18}
              sx={{ position: 'absolute' }}
            />
          )}
          <Edit
            fontSize="small"
            color={
              queryLeads.isLoading || item?.Status !== 'Active'
                ? undefined
                : 'info'
            }
          />
        </IconButton>
      </Tooltip>

      <Dialog
        open={newWklyInv !== null && !queryLeads.isLoading}
        onClose={() => setNewWklyInv(null)}
      >
        <DialogTitle>
          <Stack direction="row" alignItems="center">
            <Box>Adjust Weekly Investment</Box>
            <Box sx={{ flex: 1 }} />
            <IconButton size="small" onClick={() => setNewWklyInv(null)}>
              <Close fontSize="small" />
            </IconButton>
          </Stack>
        </DialogTitle>
        <Divider />
        {availableLeads <= 0 && (
          <Alert severity="warning">
            Sorry! There are no leads available to add to your current
            subscription.
          </Alert>
        )}
        <Stack spacing={2} sx={{ p: 3 }}>
          {item?.LeadType === 'A' && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                fontWeight: 'bold',
                fontSize: 14,
              }}
            >
              Available Leads:{' '}
              {availableLeads -
                floor(
                  ((newWklyInv || 0) - (item?.WklyInv || 0)) /
                    (item?.LeadPrice || 0),
                )}
            </Box>
          )}

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              fontWeight: 'bold',
              fontSize: 14,
            }}
          >
            Current Investment: {formatter.format(item?.WklyInv || 0)} - (
            {floor((item?.WklyInv || 0) / (item?.LeadPrice || 0))} x{' '}
            {formatter.format(item?.LeadPrice || 0)})
          </Box>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              fontWeight: 'bold',
              fontSize: 14,
            }}
          >
            New Investment: {formatter.format(newWklyInv || 0)} - (
            {floor((newWklyInv || 0) / (item?.LeadPrice || 0))} x{' '}
            {formatter.format(item?.LeadPrice || 0)})
          </Box>

          {item?.LeadType === 'A' && (
            <Box
              component="small"
              sx={{ fontStyle: 'italic', color: '#4a4a4a' }}
            >
              Updated investment value for "A" type leads must be larger than
              current amount.
            </Box>
          )}
          <Box>
            <TextField
              fullWidth
              label="Weekly Investment"
              type="number"
              value={newWklyInv}
              onChange={(event) => {
                setNewWklyInv(Number(event.target.value));
              }}
              inputProps={{ style: { fontFamily: 'Roboto Mono' } }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              helperText={
                limitExceeded
                  ? 'Weekly investment entered would exceed the limit of leads allowed for this county'
                  : ''
              }
              error={limitExceeded}
            />
          </Box>

          <Stack spacing={1} direction="row" alignItems="center">
            {[
              currentLeadAmt + 1,
              currentLeadAmt + 3,
              currentLeadAmt + 6,
              currentLeadAmt + 9,
            ].map((count) => {
              let total = 0;
              total = multNums(count, item?.LeadPrice || 0);
              if (total > 0 && count - currentLeadAmt <= availableLeads) {
                return (
                  <Button
                    key={count}
                    fullWidth
                    size="small"
                    variant="outlined"
                    onClick={() => {
                      setNewWklyInv(total);
                    }}
                    sx={{ whiteSpace: 'nowrap' }}
                  >
                    {count} - {toCurrency({ value: total })}
                  </Button>
                );
              } else {
                return null;
              }
            })}
          </Stack>

          <DisplayMutationError
            isError={mutationUptInv.isError}
            error={mutationUptInv.error}
            onClose={() => mutationUptInv.reset()}
            displayMore={isAdmin}
          />

          <Box sx={{ pt: 1 }}>
            <Button
              fullWidth
              size="small"
              color="info"
              variant="contained"
              disabled={
                (item?.WklyInv &&
                newWklyInv !== null &&
                item?.LeadType === 'A' &&
                !isAdmin
                  ? item?.WklyInv >= newWklyInv
                  : false) || disableSubmit
              }
              onClick={() => mutationUptInv.mutate()}
            >
              {mutationUptInv.isPending ? 'Saving...' : 'Save Changes'}
            </Button>
          </Box>
        </Stack>
      </Dialog>
    </React.Fragment>
  );
}
