import LinearProgress from '@mui/material/LinearProgress';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Dashboard } from '../components/dashboard';
import { MaintenanceMessageAdmins } from '../components/maintenance-message';
import { RequireAuth } from '../components/require-auth';
import { usePermissions } from '../hooks/use-permissions';
import { AWS_GROUP } from '../types';
import { History } from './history';
import { Home } from './home';
import { Leads } from './leads';
import { LeadsMap } from './leads-map';
import { SignIn } from './sign-in';
import { Subscriptions } from './subscriptions';
import { Team } from './team';
import { WeeklyInvoices } from './weekly-invoices';

const AppRoutes = {
  Admin: React.lazy(() => import('./admin')),
};

export function Root() {
  // Hooks
  const { checkPermissions } = usePermissions();

  // Query - Check for Maintenance
  const pathMaint = process.env.REACT_APP_MAINT_URL;
  const queryMaint = useQuery({
    enabled: pathMaint !== undefined,
    queryKey: [pathMaint],
    queryFn: async () => {
      if (pathMaint) {
        const body = { data: { origin: window.origin } };
        const response = await fetch(pathMaint, {
          body: JSON.stringify(body),
          headers: { 'Content-Type': 'application/json' },
          method: 'POST',
        });
        const data: {
          data: {
            maintenanceActive: boolean;
            maintenanceBegin: string;
            maintenanceEnd: string;
          };
        } = await response.json();

        return data.data;
      }

      return null;
    },
  });

  const isAdmin = checkPermissions([
    AWS_GROUP.ARC_GlobalAdmin,
    AWS_GROUP.LDS_GlobalAdmin,
    AWS_GROUP.LDS_KeyingMgr,
  ]);

  // Agents cannot access the ARC during Maintenance
  // Admins can access the ARC during Maintentnace
  const displayAdminMaint =
    isAdmin &&
    queryMaint.data &&
    queryMaint.data.maintenanceActive &&
    queryMaint.data.maintenanceEnd;

  return (
    <React.Fragment>
      {displayAdminMaint && queryMaint.data ? (
        <MaintenanceMessageAdmins
          maintenanceEnd={queryMaint.data.maintenanceEnd}
        />
      ) : null}

      <Routes>
        <Route path="sign-in" element={<SignIn />} />

        <Route
          path="/"
          element={
            <RequireAuth isAdmin={isAdmin} maintData={queryMaint.data}>
              <Dashboard />
            </RequireAuth>
          }
        >
          <Route path="home" element={<Home />} />
          <Route
            path="admin/*"
            element={
              isAdmin ? (
                <React.Suspense fallback={<LinearProgress />}>
                  <AppRoutes.Admin />
                </React.Suspense>
              ) : (
                <Navigate to="home" />
              )
            }
          />
          <Route path="map" element={<LeadsMap />} />
          <Route path="subscriptions" element={<Subscriptions />} />
          <Route path="team" element={<Team />} />
          <Route path="leads" element={<Leads />} />
          <Route path="history" element={<History />} />
          <Route path="invoices" element={<WeeklyInvoices />} />

          <Route
            path="admin/*"
            element={
              isAdmin ? (
                <React.Suspense fallback={<LinearProgress />}>
                  <AppRoutes.Admin />
                </React.Suspense>
              ) : (
                <Navigate to="home" />
              )
            }
          />

          <Route path="/" element={<Navigate to="home" />} />
          <Route path="*" element={<Navigate to="home" />} />
        </Route>
      </Routes>
    </React.Fragment>
  );
}
