import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import './amplify';
import { App } from './app-root';
import { AuthProvider } from './contexts/auth-context';
import { materialTheme } from './theme';
import { captureError } from './utils/capture-error';

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false,
    },
  },
  queryCache: new QueryCache({
    onError: (error) => captureError({ data: { error } }),
  }),
});

ReactDOM.render(
  <ThemeProvider theme={materialTheme}>
    <CssBaseline />

    <QueryClientProvider client={queryClient}>
      <HelmetProvider>
        <AuthProvider>
          <App />
        </AuthProvider>
      </HelmetProvider>
    </QueryClientProvider>
  </ThemeProvider>,
  document.getElementById('root'),
);
