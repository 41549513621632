import { LEAD_PRODUCT } from '../types';

export const getProductTitle = (product: LEAD_PRODUCT) => {
  switch (product) {
    case LEAD_PRODUCT['FEX']: {
      return 'Final Expense';
    }
    case LEAD_PRODUCT['MP']: {
      return 'Mortgage Protection';
    }
    case LEAD_PRODUCT['ANN']: {
      return 'Annuity';
    }
    default: {
      return product;
    }
  }
};

export const getProductColor = (
  product: LEAD_PRODUCT,
): React.CSSProperties['color'] => {
  switch (product) {
    case LEAD_PRODUCT['FEX']: {
      return 'darkgreen';
    }
    case LEAD_PRODUCT['MP']: {
      return 'darkblue';
    }
    case LEAD_PRODUCT['ANN']: {
      return 'darkred';
    }
    default: {
      return 'inherit';
    }
  }
};
