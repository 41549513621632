// Using JavaScript's default toFixed function will round decimals
// (1.225).toFixed(2) = 1.23
// (-1.225).toFixed(2) = -1.23
// This function will remove decimals after 2 places
// toFixed({ value: 1.225 }) = 1.22
// toFixed({ value: -1.225 }) = -1.22
export function toFixed(params: { value: number }) {
  const decimals = 2;
  const re = new RegExp('^-?\\d+(?:.\\d{0,' + (decimals || -1) + '})?');
  const value = params.value.toString().match(re);
  if (value !== null) {
    return Number.parseFloat(value[0]);
  } else {
    return params.value;
  }
}

export function toCurrency(params: {
  value: number | null;
  displayAllDigits?: boolean;
}) {
  if (params.value !== null) {
    if (params.displayAllDigits) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 4,
        maximumFractionDigits: 8,
      });
      return formatter.format(params.value);
    } else {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      const fixedValue = toFixed({ value: params.value });
      return formatter.format(fixedValue);
    }
  } else {
    return null;
  }
}

/**
 * Use this when the specific time of day is not relevant
 *
 * This can cause issues:
 * new Date("2024-02-07") > Tue Feb 06 2024 19:00:00 GMT-0500 (Eastern Standard Time)
 * new Date("2024-02-07T20:34:36.957Z") > Wed Feb 07 2024 15:34:36 GMT-0500 (Eastern Standard Time)
 *
 * This is what we want:
 * new Date("2024-02-07T00:00") > Wed Feb 07 2024 00:00:00 GMT-0500 (Eastern Standard Time)
 *
 */
export function formatDate(params: { value: string }): string {
  // Regular expression to match the date part in the format yyyy-MM-dd
  const dateRegex = /^(\d{4}-\d{2}-\d{2})/;
  const match = params.value.match(dateRegex);

  if (match) {
    // If a match is found, append 'T00:00' to the matched date portion
    return `${match[1]}T00:00`;
  } else {
    return params.value;
  }
}
