import { LEAD_STATUS } from '../../types';

export const leadStatuses: {
  status: LEAD_STATUS;
  color: React.CSSProperties['color'];
}[] = [
  { status: LEAD_STATUS.New, color: '#f3d42c' },
  { status: LEAD_STATUS['No Answer'], color: '#0b6985' },
  { status: LEAD_STATUS["Didn't Book"], color: '#ea912b' },
  { status: LEAD_STATUS.Booked, color: '#50b733' },
  { status: LEAD_STATUS['Call Back'], color: '#4f2a70' },
  { status: LEAD_STATUS['Door Knock'], color: '#d15214' },
  { status: LEAD_STATUS['No Sale'], color: '#b61f26' },
  { status: LEAD_STATUS['Sold Policy'], color: '#35763c' },
];
