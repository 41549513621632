import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import { orderBy, uniq } from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../contexts/auth-context';
import { CartContext } from '../../contexts/cart-context';
import { usePermissions } from '../../hooks/use-permissions';
import { CheckoutData } from '../../routes/leads-map/data';
import {
  getAllFIPS,
  getDetailsAll,
} from '../../routes/leads-map/purchase-utils';
import {
  AWS_GROUP,
  CustomerData,
  CustomerPaymentData,
  PYMNT_DFLT,
} from '../../types';
import { captureError } from '../../utils/capture-error';
import { cleanString } from '../../utils/clean-string';
import { toCurrency } from '../../utils/formatter';
import { isAgt } from '../../utils/is-agent';
import { sumArray } from '../../utils/sumarray';
import { DisplayMutationError } from '../display-mutation-error';
import { CartPurchaseCounty } from './cart-purchase-county';
import { CartPurchaseExpire } from './cart-purchase-expire';
import { CartPurchaseSuccess } from './cart-purchase-success';
import { CartSubscriptionCounty } from './cart-subscription-county';
import { PaymentCardAdd } from './payment-card-add';
import { PaymentCardItem } from './payment-card-item';
import { PaymentCardUpdate } from './payment-card-update';

export function Cart() {
  // Context
  const authContext = React.useContext(AuthContext);
  const { state, dispatch } = React.useContext(CartContext);
  // State
  const [CustomerPaymentProfileId, setCustomerPaymentProfileId] =
    React.useState<string>();
  const [addingCard, setAddingCard] = React.useState(false);
  const [updatingCard, setUpdatingCard] = React.useState<CustomerPaymentData>();
  const [Code, setCode] = React.useState('');
  // Hooks
  const { hasGroup } = usePermissions();
  const queryClient = useQueryClient();

  const { impersonatedAgent, user } = authContext.state;
  const isAdmin = hasGroup(AWS_GROUP.LDS_GlobalAdmin);

  // Only Admins can manage carts for other users
  let AgtNo = user?.getUsername() || null;
  if (isAdmin) {
    AgtNo = impersonatedAgent?.AgtNo || null;
  }

  // Query - Authorize Payment Profiles
  const path = '/payments/customer-profile';
  const queryKey = [path, { AgtNo }];
  const query = useQuery({
    enabled: AgtNo !== null && isAgt(AgtNo) && state.cartOpen,
    queryKey,
    queryFn: async () => {
      if (isAgt(AgtNo)) {
        const response: {
          data: CustomerData | null;
        } = await API.post('LeadsAPI', path, { body: { AgtNo } });

        if (response.data) {
          // If the Agent has payment profiles
          if (response.data.customerPaymentProfiles.length) {
            const { customerPaymentProfiles } = response.data;
            // Check to to see if the user has set a default payment profile
            const defaultProfile = customerPaymentProfiles.find(
              (i) => i.defaultFor?.includes(PYMNT_DFLT['LDS']),
            );
            if (
              defaultProfile !== undefined &&
              defaultProfile.customerPaymentProfileId
            ) {
              setCustomerPaymentProfileId(
                defaultProfile.customerPaymentProfileId,
              );
            } else {
              // The user has not set a default profile so just use their first payment profile
              const { customerPaymentProfileId } = customerPaymentProfiles[0];
              setCustomerPaymentProfileId(
                customerPaymentProfileId || undefined,
              );
            }
          }

          return response.data;
        }
      }

      return null;
    },
  });

  // Mutation - Redeem Code for Coupon or Voucher
  const mutationRedeem = useMutation({
    mutationFn: async () => {
      await API.post('LeadsAPI', '/discounts/redeem', {
        body: {
          Code,
          CartId: state.cart?.CartId,
          AgtNo,
        },
      });
    },
    onSuccess: async () => {
      // Refetch Details for Cart
      await queryClient.invalidateQueries({
        queryKey: ['/leads/instant-purchase/cart'],
      });
      // Reset Code Input
      setCode('');
    },
    onError: (error) => captureError({ data: { error } }),
  });

  // Mutation - Toggle Voucher for Cart
  const mutationToggle = useMutation({
    mutationFn: async () => {
      let UseVoucher = 0;
      if (
        state.cart &&
        (state.cart.UseVoucher === 0 || state.cart.UseVoucher === null)
      ) {
        UseVoucher = 1;
      }
      await API.post(
        'LeadsAPI',
        '/leads/instant-purchase/cart/use-voucher/update',
        {
          body: { CartId: state.cart?.CartId, UseVoucher },
        },
      );
    },
    onSuccess: async () => {
      // Refetch Details for Cart
      await queryClient.invalidateQueries({
        queryKey: ['/leads/instant-purchase/cart'],
      });
    },
    onError: (error) => captureError({ data: { error } }),
  });

  // Mutation - Create Subscriptions
  const mutationSubscribe = useMutation({
    mutationFn: async () => {
      const BillToAgt = AgtNo;
      const Subscriptions = state.subs.map((i) => {
        const RecAgt = i.RecAgt?.AgtNo || BillToAgt;
        const SubRegions = i.SubRegions.map((i) => i.FIPSRegId);
        return {
          RecAgt,
          FIPS: i.FIPS,
          SubRegions,
          Product: i.Product,
          LeadType: i.LeadType,
          WklyInv: Number(i.WklyInv),
          Exclusive: i.Exclusive,
        };
      });

      let mutationPath = '/leads/subscriptions/add';
      if (hasGroup(AWS_GROUP.LDS_GlobalAdmin)) {
        // Admin is creating a subscription for a user
        mutationPath = '/admin/leads/subscriptions/add';
      }

      // Add Subscriptions
      await API.post('LeadsAPI', mutationPath, {
        body: { BillToAgt, Subscriptions },
      });
    },
    onSuccess: async () => {
      // Reset Subscriptions in Cart Context
      dispatch({ type: 'RESET_SUBS' });
      // Refetch subscriptions for user
      await queryClient.invalidateQueries({
        queryKey: ['/leads/subscriptions', { AgtNo }],
      });
    },
    onError: (error) => captureError({ data: { error } }),
  });

  // Mutation - Purchase Leads
  const mutationPurchase = useMutation({
    mutationFn: async () => {
      const response: {
        data: CheckoutData;
      } = await API.post('LeadsAPI', '/leads/instant-purchase/cart/checkout', {
        body: { AgtNo, CustomerPaymentProfileId },
      });

      return response.data;
    },
    onSuccess: async () => {
      // Reset Leads in Cart Context
      dispatch({ type: 'RESET_LEADS' });
      // Refetch Details for Cart
      await queryClient.invalidateQueries({
        queryKey: ['/leads/instant-purchase/cart'],
      });
    },
    onError: (error) => captureError({ data: { error } }),
  });

  React.useEffect(() => {
    // Refetch cart on open/close
    queryClient.invalidateQueries({
      queryKey: ['/leads/instant-purchase/cart'],
    });
  }, [state.cartOpen, queryClient]);

  const handleSubmit = async () => {
    // Add Subscriptions
    if (state.subs.length > 0) {
      mutationSubscribe.mutate();
    }

    // Purchase Leads
    if (
      state.cart &&
      state.cart.CartId &&
      state.cart.CartItems &&
      state.cart.CartItems.length > 0
    ) {
      mutationPurchase.mutate();
    }
  };

  const handleClose = async () => {
    dispatch({ type: 'TOGGLE_CART' });
    // Reset Mutations
    mutationSubscribe.reset();
    mutationPurchase.reset();
    // Reset State
    setAddingCard(false);
    setUpdatingCard(undefined);
  };

  const CartItems =
    state.cart &&
    state.cart.CartId &&
    state.cart.CartItems &&
    state.cart.CartItems.length
      ? state.cart.CartItems
      : [];
  const leadsFIPS = getAllFIPS({ data: CartItems });
  const leadsDetails = getDetailsAll({ data: CartItems });

  const subCounties = orderBy(uniq(state.subs.map((i) => i.FIPS)));
  const subInvestments = sumArray(state.subs.map((i) => Number(i.WklyInv)));

  const isImpersonating = authContext.state.impersonatedAgent !== null;

  const emptyCart = subCounties.length === 0 && CartItems.length === 0;
  const noCardSelected = CustomerPaymentProfileId === undefined;

  let submitText = 'Checkout';
  if (CartItems.length && state.subs.length) {
    // Leads and Subscriptions in Cart
    submitText = 'Purchase Leads and Create Subscriptions';
  } else if (CartItems.length && state.subs.length === 0) {
    // Just Leads in Cart
    submitText = 'Purchase Leads';
  } else if (CartItems.length === 0 && state.subs.length) {
    // Just Subscriptions in Cart
    submitText = 'Create Subscriptions';
  }

  // The Cart is not $0.00 and the Total is less than $0.11
  const minPurchaseError =
    state.cart &&
    state.cart.FinalTotal !== null &&
    state.cart.FinalTotal !== 0 &&
    state.cart.FinalTotal < 0.11;

  const submitDisabled =
    minPurchaseError ||
    Boolean(isImpersonating && !isAdmin) ||
    Boolean(!isImpersonating && isAdmin) ||
    emptyCart ||
    (CartItems.length > 0 && noCardSelected) ||
    mutationSubscribe.isPending ||
    mutationSubscribe.isError ||
    mutationSubscribe.isSuccess ||
    mutationPurchase.isPending ||
    mutationPurchase.isError ||
    mutationPurchase.isSuccess;

  return (
    <Drawer
      container={window.document.body}
      variant="temporary"
      anchor="right"
      open={state.cartOpen}
      onClose={handleClose}
      sx={{
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: { xs: '100vw', md: '60vw', lg: '50vw', xl: '40vw' },
          height: '100dvh',
        },
      }}
    >
      {addingCard ? (
        <PaymentCardAdd
          queryKey={queryKey}
          onClose={() => setAddingCard(false)}
        />
      ) : updatingCard !== undefined ? (
        <PaymentCardUpdate
          profile={updatingCard}
          queryKey={queryKey}
          onClose={() => setUpdatingCard(undefined)}
        />
      ) : mutationPurchase.isSuccess && mutationPurchase.data ? (
        <CartPurchaseSuccess
          data={mutationPurchase.data}
          onClose={handleClose}
        />
      ) : (
        <Box
          sx={{ height: '100dvh', display: 'flex', flexDirection: 'column' }}
        >
          <Toolbar>
            <IconButton sx={{ mr: 2 }} onClick={handleClose}>
              <CloseIcon />
            </IconButton>

            <Box sx={{ flex: 1, display: 'flex', alignItems: 'center' }}>
              <Typography variant="h6" noWrap component="div">
                Cart
              </Typography>
            </Box>
          </Toolbar>

          <Divider />

          <Box
            sx={{
              minHeight: 0,
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box sx={{ flex: 1, overflow: 'auto' }}>
              <Stack spacing={1} sx={{ p: 2 }}>
                <Stack spacing={1}>
                  <Stack spacing={1} direction="row" alignItems="center">
                    <Box sx={{ fontSize: 32 }}>Subscriptions</Box>
                    <Box sx={{ flex: 1, borderTop: '2px solid #f5f5f5' }} />
                    <Box
                      sx={{ pr: 1, fontFamily: 'Roboto Mono', fontSize: 14 }}
                    >
                      {toCurrency({ value: subInvestments })}
                    </Box>
                  </Stack>

                  {subCounties.length ? (
                    <Stack spacing={1}>
                      <Box component="small">
                        Money is only paid when a Subscription is filled.
                        Subscriptions are filled based on Leads Generated.
                      </Box>

                      <Box component="small">
                        A subscription may only be partially filled or fully
                        filled up to the amount subscribed, but not exceeding
                        the amount subscribed.
                      </Box>

                      {subCounties.map((FIPS) => {
                        const subsForCounty = state.subs.filter(
                          (i) => i.FIPS === FIPS,
                        );
                        return (
                          <CartSubscriptionCounty
                            key={FIPS}
                            FIPS={FIPS}
                            subs={subsForCounty}
                          />
                        );
                      })}
                    </Stack>
                  ) : (
                    <Box
                      sx={{
                        p: 1,
                        px: 2,
                        textAlign: 'center',
                        backgroundColor: '#fafafa',
                        borderRadius: 2,
                      }}
                    >
                      <Box sx={{ fontFamily: 'Roboto Mono' }}>
                        No Subscriptions in Cart
                      </Box>
                      <Box
                        component={Link}
                        to="/subscriptions"
                        sx={{ textDecorationLine: 'none', fontSize: 12 }}
                      >
                        Add a Subscription
                      </Box>
                    </Box>
                  )}
                </Stack>

                <Stack spacing={1}>
                  <Stack spacing={1} direction="row" alignItems="center">
                    <Box sx={{ fontSize: 32 }}>Leads</Box>
                    <Box sx={{ flex: 1, borderTop: '2px solid #f5f5f5' }} />
                    <Box sx={{ fontFamily: 'Roboto Mono' }}>
                      {leadsDetails.quantity}
                    </Box>
                    <Box sx={{ width: 10, borderTop: '2px solid #f5f5f5' }} />
                    {state.cart ? (
                      <Stack direction="column" alignItems="center">
                        {state.cart.CartTotal !== state.cart.FinalTotal ? (
                          <Box
                            sx={{
                              pr: 1,
                              fontFamily: 'Roboto Mono',
                              fontSize: { xs: 10, md: 12 },
                              opacity: 0.6,
                              textDecorationLine: 'line-through',
                            }}
                          >
                            {toCurrency({ value: state.cart.CartTotal })}
                          </Box>
                        ) : null}

                        <Box
                          sx={{
                            pr: 1,
                            fontFamily: 'Roboto Mono',
                            fontSize: 14,
                          }}
                        >
                          {toCurrency({ value: state.cart.FinalTotal })}
                        </Box>
                      </Stack>
                    ) : null}
                  </Stack>

                  {leadsFIPS.length ? (
                    <Stack spacing={1}>
                      <CartPurchaseExpire cart={state.cart} />

                      <Paper
                        elevation={0}
                        sx={{
                          border: '1px solid #00000022',
                          overflow: 'hidden',
                        }}
                      >
                        <Box sx={{ p: 1 }}>
                          <Stack
                            spacing={1}
                            direction="row"
                            alignItems="center"
                          >
                            <TextField
                              fullWidth
                              size="small"
                              label="Code for Coupon or Voucher"
                              value={Code}
                              onChange={(event) => {
                                const value = cleanString(event.target.value);
                                setCode(value);
                              }}
                              inputProps={{
                                style: {
                                  fontFamily: 'Roboto Mono',
                                  fontWeight: 'bold',
                                },
                              }}
                            />

                            <Button
                              disabled={
                                !Code.trim() || mutationRedeem.isPending
                              }
                              variant="contained"
                              disableElevation
                              color="info"
                              onClick={() => mutationRedeem.mutate()}
                            >
                              Apply
                            </Button>
                          </Stack>

                          {state.cart && state.cart.CouponCode ? (
                            <Box
                              sx={{
                                pt: 1,
                                pl: 1,
                                fontSize: 13,
                                color: '#4a4a4a',
                              }}
                            >
                              <Box
                                component="span"
                                sx={{
                                  fontWeight: 'bold',
                                  fontFamily: 'Roboto Mono',
                                  fontStyle: 'normal',
                                }}
                              >
                                {state.cart.CouponCode}
                              </Box>{' '}
                              has been applied!
                            </Box>
                          ) : null}
                        </Box>

                        <DisplayMutationError
                          alertTitle="Redeem Error"
                          isError={mutationRedeem.isError}
                          error={mutationRedeem.error}
                          onClose={() => mutationRedeem.reset()}
                          displayMore={isAdmin}
                        />
                      </Paper>

                      {state.cart &&
                      state.cart.VoucherAmount !== null &&
                      state.cart.VoucherAmount > 0 ? (
                        <Paper
                          elevation={0}
                          sx={{
                            border: '1px solid #00000022',
                            overflow: 'hidden',
                          }}
                        >
                          <Box sx={{ p: 0.5, pl: 2 }}>
                            <Stack direction="column">
                              <FormControlLabel
                                disabled={mutationToggle.isPending}
                                control={
                                  <Checkbox
                                    checked={state.cart.UseVoucher === 1}
                                    onChange={() => mutationToggle.mutate()}
                                    color="info"
                                  />
                                }
                                label="Apply Voucher to Cart"
                              />

                              <Box
                                sx={{ pl: 1, fontSize: 13, color: '#4a4a4a' }}
                              >
                                Voucher of{' '}
                                <Box
                                  component="span"
                                  sx={{
                                    fontWeight: 'bold',
                                    fontFamily: 'Roboto Mono',
                                  }}
                                >
                                  {toCurrency({
                                    value: state.cart.VoucherAmount,
                                  })}
                                </Box>
                                {state.cart.UseVoucher === 1
                                  ? ' will be applied to this cart!'
                                  : ' can be applied to this cart.'}
                              </Box>
                            </Stack>
                          </Box>

                          <DisplayMutationError
                            alertTitle="Voucher Error"
                            isError={mutationToggle.isError}
                            error={mutationToggle.error}
                            onClose={() => mutationToggle.reset()}
                            displayMore={isAdmin}
                          />
                        </Paper>
                      ) : null}

                      {leadsFIPS.map((FIPS) => {
                        return (
                          <CartPurchaseCounty
                            key={FIPS}
                            FIPS={FIPS}
                            items={CartItems}
                          />
                        );
                      })}

                      <Stack
                        spacing={1}
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-end"
                      >
                        <Box sx={{ fontSize: 24 }}>Leads Total:</Box>

                        {state.cart ? (
                          <Stack direction="column" alignItems="center">
                            {state.cart.CartTotal !== state.cart.FinalTotal ? (
                              <Box
                                sx={{
                                  pr: 1,
                                  fontFamily: 'Roboto Mono',
                                  fontSize: { xs: 12, md: 14 },
                                  opacity: 0.6,
                                  textDecorationLine: 'line-through',
                                }}
                              >
                                {toCurrency({
                                  value: state.cart.CartTotal,
                                })}
                              </Box>
                            ) : null}

                            <Box
                              sx={{
                                pr: 1,
                                fontFamily: 'Roboto Mono',
                                fontSize: 16,
                              }}
                            >
                              {toCurrency({ value: state.cart.FinalTotal })}
                            </Box>
                          </Stack>
                        ) : null}
                      </Stack>
                    </Stack>
                  ) : (
                    <Stack spacing={1}>
                      <Box
                        sx={{
                          p: 1,
                          px: 2,
                          textAlign: 'center',
                          backgroundColor: '#fafafa',
                          borderRadius: 2,
                        }}
                      >
                        <Box sx={{ fontFamily: 'Roboto Mono' }}>
                          No Leads in Cart
                        </Box>
                        <Box
                          component={Link}
                          to="/map"
                          sx={{ textDecorationLine: 'none', fontSize: 12 }}
                        >
                          Find New Leads
                        </Box>
                      </Box>
                    </Stack>
                  )}
                </Stack>

                {CartItems.length ? (
                  <Stack spacing={1}>
                    <Stack spacing={1} direction="row" alignItems="center">
                      <Box sx={{ fontSize: 32 }}>Payment Info</Box>
                      <Box sx={{ flex: 1, borderTop: '2px solid #f5f5f5' }} />
                    </Stack>

                    {query.isLoading ? (
                      <Box
                        sx={{
                          p: 2,
                          display: 'flex',
                          justifyContent: 'center',
                          backgroundColor: '#fafafa',
                          borderRadius: 2,
                        }}
                      >
                        <CircularProgress />
                      </Box>
                    ) : query.data &&
                      query.data.customerPaymentProfiles.length === 0 ? (
                      <Box
                        sx={{
                          p: 3,
                          textAlign: 'center',
                          backgroundColor: '#fafafa',
                          borderRadius: 2,
                        }}
                      >
                        <Box>Add a Card to complete checkout</Box>
                      </Box>
                    ) : null}

                    {query.data?.customerPaymentProfiles.map((item) => {
                      const isDefault = Boolean(
                        item.defaultFor?.includes(PYMNT_DFLT['LDS']),
                      );
                      const isChecked =
                        CustomerPaymentProfileId ===
                        item.customerPaymentProfileId;

                      return (
                        <PaymentCardItem
                          key={item.customerPaymentProfileId}
                          item={item}
                          isChecked={isChecked}
                          isDefault={isDefault}
                          isAdmin={isAdmin}
                          onSelect={() => {
                            if (isChecked) {
                              // Deselect card
                              setCustomerPaymentProfileId(undefined);
                            } else {
                              setCustomerPaymentProfileId(
                                item.customerPaymentProfileId || undefined,
                              );
                            }
                          }}
                          onUpdate={() => setUpdatingCard(item)}
                        />
                      );
                    })}

                    {isAdmin ? null : (
                      <Box
                        component="button"
                        onClick={() => setAddingCard(true)}
                        sx={{
                          cursor: 'pointer',
                          width: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          backgroundColor: '#fefefe',
                          borderRadius: 2,
                          borderWidth: 2,
                          borderStyle: 'solid',
                          borderColor: '#03182E22',
                          transition: 'all 0.2s',
                          ':hover': {
                            transform: 'scale(1.01)',
                            borderColor: '#03182E',
                            backgroundColor: '#fff',
                          },
                        }}
                      >
                        <Box sx={{ p: 0.6, color: '#4a4a4a' }}>
                          <AddCircleOutlineIcon fontSize="medium" />
                          <Box
                            component="span"
                            sx={{ ml: 1, fontSize: 28, fontWeight: 'bold' }}
                          >
                            Add Card
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </Stack>
                ) : null}
              </Stack>
            </Box>

            <Divider />

            {isImpersonating && !isAdmin ? (
              <Alert severity="info" onClose={handleClose}>
                <AlertTitle>Impersonating</AlertTitle>
                You cannot check out while impersonating.
              </Alert>
            ) : null}

            {isImpersonating && isAdmin ? (
              <Alert severity="info" onClose={handleClose}>
                <AlertTitle>Impersonating</AlertTitle>
                You are managing a cart for{' '}
                <Box component="strong">
                  {authContext.state.impersonatedAgent?.AgtName} (
                  {authContext.state.impersonatedAgent?.AgtNo})
                </Box>
              </Alert>
            ) : null}

            {mutationSubscribe.isSuccess ? (
              <Alert severity="success" onClose={handleClose}>
                <AlertTitle>Success</AlertTitle>
                Your subscriptions have been created!
              </Alert>
            ) : null}

            <DisplayMutationError
              alertTitle="Subscriptions Error"
              isError={mutationSubscribe.isError}
              error={mutationSubscribe.error}
              onClose={() => mutationSubscribe.reset()}
              displayMore={isAdmin}
            />

            <DisplayMutationError
              alertTitle="Leads Error"
              isError={mutationPurchase.isError}
              error={mutationPurchase.error}
              onClose={() => mutationPurchase.reset()}
              displayMore={isAdmin}
            />

            {minPurchaseError ? (
              <Alert severity="warning">
                <AlertTitle>Minimum Purchase Error</AlertTitle>
                Leads Total must be $0, or $0.11 and higher to process credit
                cards.
              </Alert>
            ) : null}

            <Button
              disabled={submitDisabled}
              fullWidth
              size="large"
              color="info"
              variant="contained"
              disableElevation
              sx={{ borderRadius: 0, minHeight: 64 }}
              onClick={handleSubmit}
              startIcon={
                mutationSubscribe.isPending || mutationPurchase.isPending ? (
                  <CircularProgress color="inherit" />
                ) : (
                  <ShoppingBagIcon />
                )
              }
            >
              {mutationSubscribe.isPending || mutationPurchase.isPending
                ? 'Purchasing...'
                : submitText}
            </Button>
          </Box>
        </Box>
      )}
    </Drawer>
  );
}
