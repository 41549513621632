import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { QueryFilters, useQueryClient } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import React from 'react';
import { CustomerPaymentData } from '../../types';
import { captureError } from '../../utils/capture-error';
import { CustomerProfileIframe } from '../customer-profile-iframe';

export function PaymentCardUpdate(props: {
  profile: CustomerPaymentData;
  queryKey: QueryFilters['queryKey'];
  onClose: () => void;
}) {
  // State
  const [closing, setClosing] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [token, setToken] = React.useState('');

  React.useEffect(() => {
    // Not using react-query because the response token should not be cached
    const fetchData = async () => {
      try {
        setLoading(true);
        setToken('');
        const response: {
          token: string;
        } = await API.post('LeadsAPI', '/payments/customer-profile/token', {
          body: {
            hostedIframeReturnUrl:
              window.location.origin + '/IFrameCommunicator.html',
          },
        });
        setToken(response.token);
      } catch (error) {
        captureError({ data: { error } });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const queryClient = useQueryClient();
  const handleClose = async () => {
    if (!closing) {
      try {
        setClosing(true);

        // Refetch data
        await queryClient.invalidateQueries({ queryKey: props.queryKey });

        // Back to Cart
        props.onClose();
      } catch (error) {
        captureError({ data: { error } });
      } finally {
        setClosing(false);
      }
    }
  };

  return (
    <Box sx={{ height: '100dvh', display: 'flex', flexDirection: 'column' }}>
      <Toolbar>
        <IconButton sx={{ mr: 2 }} onClick={handleClose}>
          {loading || closing ? (
            <CircularProgress size={24} />
          ) : (
            <ArrowBackIcon />
          )}
        </IconButton>

        <Box sx={{ flex: 1, display: 'flex', alignItems: 'center' }}>
          <Typography variant="h6" noWrap component="div">
            Update Card
          </Typography>
        </Box>
      </Toolbar>

      <Divider />

      {loading ? (
        <LinearProgress />
      ) : token !== '' ? (
        <Box
          sx={{
            minHeight: 0,
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <CustomerProfileIframe
            action="/customer/editPayment"
            iframeToken={token}
            title="Edit Card"
            paymentProfileId={props.profile.customerPaymentProfileId || ''}
            onReceiveCommunication={(action) => {
              if (action) {
                handleClose();
              }
            }}
          />
        </Box>
      ) : null}
    </Box>
  );
}
