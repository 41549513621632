import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import {
  GoogleMap,
  InfoWindow,
  LoadScriptProps,
  Marker,
  MarkerClusterer,
  MarkerProps,
  useJsApiLoader,
} from '@react-google-maps/api';
import { orderBy } from 'lodash';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import pinBooked from '../../assets/maps-pin-Booked.svg';
import pinCallBack from '../../assets/maps-pin-CallBack.svg';
import pinDidntBook from '../../assets/maps-pin-DidntBook.svg';
import pinDoorKnock from '../../assets/maps-pin-DoorKnock.svg';
import pinNew from '../../assets/maps-pin-New.svg';
import pinNoAnswer from '../../assets/maps-pin-NoAnswer.svg';
import pinNoSale from '../../assets/maps-pin-NoSale.svg';
import pinSoldPolicy from '../../assets/maps-pin-SoldPolicy.svg';
import pinRed from '../../assets/maps-pin-red.svg';
import { countyFeatures } from '../../data/county-features';
import { LEAD_STATUS, LeadData } from '../../types';
import { captureError } from '../../utils/capture-error';
import { leadStatuses } from './data';

const containerStyle = { width: '100%', height: '100%', minHeight: 384 };
const libraries: LoadScriptProps['libraries'] = ['visualization'];

function LeadMarker(props: {
  item: LeadData;
  clusterer: MarkerProps['clusterer'];
  onClick: () => void;
}) {
  // Props
  const { item } = props;
  // State
  const [open, setOpen] = React.useState(false);

  let icon = pinRed;
  if (item.ContactStatus === LEAD_STATUS.New) {
    icon = pinNew;
  } else if (item.ContactStatus === LEAD_STATUS['No Answer']) {
    icon = pinNoAnswer;
  } else if (item.ContactStatus === LEAD_STATUS["Didn't Book"]) {
    icon = pinDidntBook;
  } else if (item.ContactStatus === LEAD_STATUS.Booked) {
    icon = pinBooked;
  } else if (item.ContactStatus === LEAD_STATUS['Call Back']) {
    icon = pinCallBack;
  } else if (item.ContactStatus === LEAD_STATUS['Door Knock']) {
    icon = pinDoorKnock;
  } else if (item.ContactStatus === LEAD_STATUS['No Sale']) {
    icon = pinNoSale;
  } else if (item.ContactStatus === LEAD_STATUS['Sold Policy']) {
    icon = pinSoldPolicy;
  }

  // If coordinates are not found for an address they API will default to 0,0
  const hasCoords =
    item &&
    item.Latitude &&
    item.Longitude &&
    Number(item.Latitude) !== 0 &&
    Number(item.Longitude) !== 0;

  if (hasCoords) {
    const statusType = leadStatuses.find(
      (i) => i.status === item.ContactStatus,
    );

    const lat = Number(item.Latitude);
    const lng = Number(item.Longitude);

    return (
      <Marker
        position={{ lat, lng }}
        icon={icon}
        clusterer={props.clusterer}
        onClick={() => setOpen((currentState) => !currentState)}
      >
        {open && (
          <InfoWindow
            position={{ lat, lng }}
            onCloseClick={() => setOpen(false)}
          >
            <Stack spacing={1}>
              <Stack spacing={1} direction="row" alignItems="center">
                <Box
                  sx={{
                    backgroundColor: statusType?.color || '#e1e1e1',
                    borderRadius: 2,
                    height: 24,
                    width: 4,
                  }}
                />

                <Box sx={{ fontSize: 16, fontWeight: 'bold' }}>
                  {item.FirstName} {item.LastName}
                </Box>
              </Stack>

              <Box>{item.Address1}</Box>

              {item.Address2 ? <Box>{item.Address2}</Box> : null}

              <Box>
                {item.City}, {item.St} {item.Zip}
              </Box>

              <Stack spacing={1} direction="row" alignItems="center">
                <Box
                  sx={{
                    fontFamily: 'Roboto Mono',
                    fontWeight: 'bold',
                    color: 'teal',
                  }}
                >
                  {item.LeadType}
                </Box>

                <Box
                  sx={{
                    height: 4,
                    width: 4,
                    borderRadius: 1,
                    backgroundColor: '#7a7a7a',
                  }}
                />

                <Box
                  sx={{
                    fontWeight: 'bold',
                    color: item.Product === 'FEX' ? 'darkgreen' : 'darkblue',
                  }}
                >
                  {item.Product === 'FEX'
                    ? 'Final Expense'
                    : 'Mortgage Protection'}
                </Box>
              </Stack>

              <Button
                fullWidth
                size="small"
                variant="outlined"
                onClick={props.onClick}
              >
                View Lead
              </Button>
            </Stack>
          </InfoWindow>
        )}
      </Marker>
    );
  } else {
    return null;
  }
}

export function LeadsMap(props: { data: LeadData[] }) {
  // State
  const [center, setCenter] = React.useState({
    lat: 36.07813,
    lng: -79.432279,
  });
  const [loading, setLoading] = React.useState(true);
  const [userLocation, setUserLocation] =
    React.useState<GeolocationCoordinates>();
  // Hooks
  const [, setSearchParams] = useSearchParams();

  // // Get user's location
  React.useEffect(() => {
    const getCurrentPosition = () => {
      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          (successCallback) => {
            resolve(successCallback);
          },
          (errorCallback) => {
            reject(errorCallback);
          },
        );
      });
    };
    const fetchData = async () => {
      try {
        setLoading(true);
        // Alliance Convention Center
        const defaultCoordinates: GeolocationCoordinates = {
          accuracy: 10,
          altitude: null,
          altitudeAccuracy: null,
          heading: null,
          latitude: 36.07813,
          longitude: -79.432279,
          speed: null,
        };

        if ('geolocation' in navigator) {
          // Coordinates
          try {
            const position =
              (await getCurrentPosition()) as GeolocationPosition;
            setUserLocation(position.coords);
          } catch (error) {
            // Permission was denied
            setUserLocation(defaultCoordinates);
          }
        } else {
          /* geolocation IS NOT available */
          setUserLocation(defaultCoordinates);
        }
      } catch (error) {
        captureError({ data: { error } });
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  React.useEffect(() => {
    // Get the count of Leads for each County
    const leadsCountyCount: { [key: string]: number } = {};
    props.data.forEach((i) => {
      if (i.FIPS) {
        if (leadsCountyCount.hasOwnProperty(i.FIPS)) {
          const currentCount = leadsCountyCount[i.FIPS];
          leadsCountyCount[i.FIPS] = currentCount + 1;
        } else {
          leadsCountyCount[i.FIPS] = 1;
        }
      }
    });
    // Order the Count of Leads for each County so that the
    // County with the largest amount of leads is at the top
    const leadsCountyArray = orderBy(
      Object.keys(leadsCountyCount).map((key) => ({
        FIPS: key,
        count: leadsCountyCount[key],
      })),
      'count',
      'desc',
    );
    if (leadsCountyArray.length) {
      const largestCounty = leadsCountyArray[0];
      const centeryCounty = countyFeatures.find(
        (i) => i.FIPS === largestCounty.FIPS,
      );

      if (centeryCounty) {
        // Center the map on the County with the most Leads
        setCenter({ lat: centeryCounty.lat, lng: centeryCounty.lng });
      } else if (userLocation) {
        // If county is not found then center on user location
        setCenter({ lat: userLocation.latitude, lng: userLocation.longitude });
      }
    } else if (userLocation) {
      // If they have no Leads then center on user location
      setCenter({ lat: userLocation.latitude, lng: userLocation.longitude });
    }
  }, [props.data, userLocation]);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY || '',
    libraries,
  });

  return (
    <Box sx={{ height: '100%', width: '100%' }}>
      {isLoaded && !loading ? (
        <GoogleMap
          options={{ streetViewControl: false }}
          mapContainerStyle={containerStyle}
          center={center}
          zoom={9}
        >
          <MarkerClusterer minimumClusterSize={3} maxZoom={12}>
            {(clusterer) => (
              <Box>
                {props.data.map((item) => {
                  const key = `${item.LeadID}-${item.DistHistID}`;
                  return (
                    <LeadMarker
                      key={key}
                      item={item}
                      clusterer={clusterer}
                      onClick={() =>
                        setSearchParams({ LeadID: String(item.LeadID) })
                      }
                    />
                  );
                })}
              </Box>
            )}
          </MarkerClusterer>
        </GoogleMap>
      ) : (
        <Box
          sx={{
            ...containerStyle,
            backgroundColor: '#E6E3E0',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress color="info" size={64} />
        </Box>
      )}
    </Box>
  );
}
