import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { orderBy } from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import { LeadItem } from '../../../components/lead-item';
import { PaperExpandable } from '../../../components/paper-expandable';
import { LEAD_STATUS, LeadData } from '../../../types';
import { LeadListItemContent } from '../../leads/lead-list-item';

export function NewLeads(props: { data: LeadData[] }) {
  // State
  const [selected, setSelected] = React.useState<string>();
  const [displayAll, setDisplayAll] = React.useState(false);

  // Filter for New Leads
  const newLeads = orderBy(
    props.data.filter((i) => i.ContactStatus === LEAD_STATUS['New']),
    ['RecDate', 'FIPS', 'City', 'LeadType', (i) => i.LastName?.toLowerCase()],
    ['desc', 'asc', 'asc', 'asc', 'asc'],
  );

  let displayLeads = newLeads;
  // Limit up to 20
  const displayLimit = 20;
  if (!displayAll && newLeads.length > displayLimit) {
    displayLeads = newLeads.slice(0, displayLimit);
  }

  return (
    <React.Fragment>
      <PaperExpandable title="New Leads" count={newLeads.length}>
        {newLeads.length === 0 ? (
          <Stack divider={<Divider />}>
            <Box
              sx={{
                p: 4,
                textAlign: 'center',
                fontFamily: 'Roboto Mono',
                color: '#9b9b9b',
              }}
            >
              Leads with the Status of <Box component="strong">New</Box> are
              displayed here
            </Box>

            <Button
              color="info"
              fullWidth
              component={Link}
              to="/leads"
              sx={{ borderRadius: 0 }}
            >
              View all Leads
            </Button>
          </Stack>
        ) : (
          <Stack divider={<Divider />}>
            {displayLeads.map((item) => {
              return (
                <Box
                  key={item.LeadID}
                  component="button"
                  sx={{
                    textAlign: 'left',
                    display: 'flex',
                    alignItems: 'center',
                    border: 'none',
                    backgroundColor: '#fff',
                    cursor: 'pointer',
                    transition: 'all 0.2s',
                    ':hover': {
                      backgroundColor: '#f5f5f5',
                    },
                    ':hover #chevron-icon': {
                      color: '#03182E',
                      mr: 0,
                    },
                  }}
                  onClick={() => setSelected(String(item.LeadID))}
                >
                  <Box sx={{ flex: 1 }}>
                    <LeadListItemContent item={item} />
                  </Box>

                  <Box
                    sx={{
                      minWidth: 32,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <ChevronRightIcon
                      id="chevron-icon"
                      sx={{
                        color: '#03182E44',
                        transition: 'all 0.2s',
                        mr: 0.4,
                      }}
                    />
                  </Box>
                </Box>
              );
            })}

            {!displayAll && props.data && props.data.length > displayLimit ? (
              <Button
                size="large"
                sx={{ borderRadius: 0 }}
                startIcon={<ExpandCircleDownOutlinedIcon />}
                onClick={() => setDisplayAll((currentState) => !currentState)}
              >
                Load More New Leads
              </Button>
            ) : null}

            <Button
              color="info"
              fullWidth
              component={Link}
              to="/leads"
              sx={{ borderRadius: 0 }}
            >
              View all Leads
            </Button>
          </Stack>
        )}
      </PaperExpandable>

      <LeadItem
        item={props.data.find((i) => i.LeadID === Number(selected))}
        onClose={() => setSelected(undefined)}
      />
    </React.Fragment>
  );
}
