import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { CheckoutData } from '../../routes/leads-map/data';
import { toCurrency } from '../../utils/formatter';
import { CardIcon } from '../card-icon';
import { DisplayValue } from '../display-value';

export function CartPurchaseSuccess(props: {
  data: CheckoutData;
  onClose: () => void;
}) {
  // Props
  const { data } = props;

  return (
    <Box sx={{ height: '100dvh', display: 'flex', flexDirection: 'column' }}>
      <Toolbar>
        <IconButton sx={{ mr: 2 }} onClick={props.onClose}>
          <ArrowBackIcon />
        </IconButton>

        <Box sx={{ flex: 1, display: 'flex', alignItems: 'center' }}>
          <Typography variant="h6" noWrap component="div">
            Checkout complete!
          </Typography>
        </Box>
      </Toolbar>

      <Divider />

      <Box
        sx={{
          minHeight: 0,
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box sx={{ flex: 1, overflow: 'auto' }}>
          <Box sx={{ p: 2 }}>
            <Stack spacing={1}>
              <DisplayValue
                label="Total"
                value={toCurrency({ value: data.Total })}
                monospace
              />

              {data.accountNumber || data.accountType ? (
                <DisplayValue
                  label="Payment Info"
                  value={
                    <Stack spacing={1} direction="row" alignItems="center">
                      {data.accountType ? (
                        <CardIcon cardType={data.accountType} size="lg" />
                      ) : null}

                      {data.accountNumber ? (
                        <Box sx={{ fontFamily: 'Roboto Mono', fontSize: 18 }}>
                          {data.accountNumber}
                        </Box>
                      ) : null}
                    </Stack>
                  }
                />
              ) : null}

              <Alert severity="success">
                Your purchase of{' '}
                <Box component="strong">{data.CartItems.length} Lead(s)</Box>{' '}
                was successful!
              </Alert>

              {data.CartItems.map((item) => {
                const isSelf = item.BillToAgt === item.RecAgt;

                if (isSelf) {
                  return (
                    <Box
                      key={item.LeadId}
                      component={Link}
                      to={`/leads?LeadID=${item.LeadId}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{
                        color: '#000',
                        fontFamily: 'Roboto',
                        textAlign: 'left',
                        borderWidth: 2,
                        borderStyle: 'solid',
                        borderColor: '#eaeaea',
                        borderRadius: 1,
                        backgroundColor: '#fff',
                        textDecorationLine: 'none',
                        transition: 'all 0.2s',
                        ':hover': {
                          transform: 'scale(1.01)',
                          borderColor: '#aeaeae',
                        },
                      }}
                    >
                      <Box sx={{ px: 1 }}>
                        <Stack spacing={1} direction="row" alignItems="center">
                          <Box
                            sx={{
                              px: 1,
                              fontFamily: 'Roboto Mono',
                              fontSize: 32,
                              fontWeight: 'bold',
                              color: 'teal',
                            }}
                          >
                            {item.LeadType}
                          </Box>

                          <Stack>
                            <Box>
                              Lead for{' '}
                              <Box
                                component="span"
                                sx={{
                                  fontFamily: 'Roboto Mono',
                                  fontWeight: 'bold',
                                  fontSize: 14,
                                  color: '#b26500',
                                }}
                              >
                                {item.RecAgt}
                              </Box>
                            </Box>

                            {item.Exclusive ? (
                              <Box
                                sx={{
                                  textTransform: 'uppercase',
                                  fontWeight: 'bold',
                                  fontSize: 12,
                                  color: 'darkorange',
                                }}
                              >
                                Exclusive
                              </Box>
                            ) : null}
                          </Stack>

                          <Box
                            sx={{ flex: 1, borderTop: '2px solid #f5f5f5' }}
                          />

                          <Box>{toCurrency({ value: item.LeadPrice })}</Box>

                          <ChevronRightIcon sx={{ color: '#03182E44' }} />
                        </Stack>
                      </Box>
                    </Box>
                  );
                } else {
                  return (
                    <Box
                      key={item.LeadId}
                      sx={{
                        pr: 2,
                        color: '#000',
                        fontFamily: 'Roboto',
                        textAlign: 'left',
                        borderWidth: 2,
                        borderStyle: 'solid',
                        borderColor: '#eaeaea',
                        borderRadius: 1,
                        backgroundColor: '#fff',
                        textDecorationLine: 'none',
                      }}
                    >
                      <Box sx={{ px: 1 }}>
                        <Stack spacing={1} direction="row" alignItems="center">
                          <Box
                            sx={{
                              px: 1,
                              fontFamily: 'Roboto Mono',
                              fontSize: 32,
                              fontWeight: 'bold',
                              color: 'teal',
                            }}
                          >
                            {item.LeadType}
                          </Box>

                          <Stack>
                            <Box>
                              Lead for{' '}
                              <Box
                                component="span"
                                sx={{
                                  fontFamily: 'Roboto Mono',
                                  fontWeight: 'bold',
                                  fontSize: 14,
                                  color: 'purple',
                                }}
                              >
                                {item.RecAgt}
                              </Box>
                            </Box>

                            {item.Exclusive ? (
                              <Box
                                sx={{
                                  textTransform: 'uppercase',
                                  fontWeight: 'bold',
                                  fontSize: 12,
                                  color: 'darkorange',
                                }}
                              >
                                Exclusive
                              </Box>
                            ) : null}
                          </Stack>

                          <Box
                            sx={{ flex: 1, borderTop: '2px solid #f5f5f5' }}
                          />

                          <Box>{toCurrency({ value: item.LeadPrice })}</Box>
                        </Stack>
                      </Box>
                    </Box>
                  );
                }
              })}
            </Stack>
          </Box>
        </Box>

        <Button
          component={Link}
          to="/leads"
          target="_blank"
          rel="noopener noreferrer"
          fullWidth
          size="large"
          color="inherit"
          variant="contained"
          disableElevation
          sx={{ borderRadius: 0, minHeight: 64 }}
          startIcon={<RecentActorsIcon />}
          endIcon={<ArrowForwardIcon />}
        >
          Go to My Leads
        </Button>
      </Box>
    </Box>
  );
}
