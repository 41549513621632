import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import cuid from 'cuid';
import React from 'react';
import { RouteError } from '../../components/route-container/route-error';
import { SelectAgent } from '../../components/select-agent';
import { CartContext } from '../../contexts/cart-context';
import { usePermissions } from '../../hooks/use-permissions';
import { AWS_GROUP, LEAD_PRODUCT, LEAD_TYPE } from '../../types';
import { toCurrency, toFixed } from '../../utils/formatter';
import { getProductColor, getProductTitle } from '../../utils/general';
import { getCounty } from '../../utils/map';
import { multNums } from '../../utils/mult-nums';
import { CartSubData, SubscriptionInfoData } from './data';

export function SubscriptionAdd(props: {
  open: boolean;
  onClose: () => void;
  feature: google.maps.Data.Feature;
  filters: string[];
}) {
  // Props
  const { feature } = props;
  // Context
  const { dispatch } = React.useContext(CartContext);
  // State
  const [data, setData] = React.useState<CartSubData>({
    RecAgt: null,
    FIPS: '',
    SubRegions: [],
    Product: LEAD_PRODUCT['FEX'],
    LeadType: LEAD_TYPE['A'],
    WklyInv: '0',
    Exclusive: false,
  });
  const [showRecAgt, setShowRecAgt] = React.useState(false);
  const [invError, setInvError] = React.useState(false);
  // Hooks
  const { hasGroup } = usePermissions();
  const isAdmin = hasGroup(AWS_GROUP.LDS_GlobalAdmin);

  const county = getCounty(feature);

  // Query - Get Subscription Info for this County
  const path = '/leads/subscriptions/fips-info';
  const query = useQuery({
    enabled: props.open && county !== null,
    queryKey: [path, { FIPS: county?.FIPS }],
    queryFn: async () => {
      const response: {
        data: SubscriptionInfoData;
      } = await API.post('LeadsAPI', path, {
        body: { FIPS: county?.FIPS },
      });

      return response.data;
    },
  });

  // Query - Check if Agent has a default Lead Billing payment
  let pathLeadBill = '/leads/subscriptions/agent-lead-billing';
  const queryLeadBill = useQuery({
    queryKey: [pathLeadBill],
    enabled: props.open,
    queryFn: async () => {
      const response: {
        data: { HasLeadBilling: boolean };
      } = await API.post('LeadsAPI', pathLeadBill, {});
      if (response.data) {
        return response.data;
      }
      return null;
    },
  });

  React.useEffect(() => {
    if (county && props.open && query.data) {
      // Default Product to what they have filtered on the map
      let Product = LEAD_PRODUCT['FEX'];
      if (props.filters.includes(LEAD_PRODUCT['MP'])) {
        Product = LEAD_PRODUCT['MP'];
      }
      const Price = query.data.Pricing.find(
        (i) => i.Product === Product && i.LeadType === LEAD_TYPE['A'],
      );
      let WklyInv = String(0);
      if (Price && Price.LeadPrice) {
        // "A" Leads have a minimum Weekly Investment of 3 Leads
        WklyInv = String(multNums(3, Price.LeadPrice));
      }
      // Reset state when opened
      setData({
        RecAgt: null,
        FIPS: county.FIPS,
        SubRegions: [],
        Product,
        LeadType: LEAD_TYPE['A'],
        WklyInv,
        Exclusive: false,
      });
      setShowRecAgt(false);
    }
  }, [county, props.open, props.filters, query.data]);

  const handleAddToCart = () => {
    // Add to Cart
    dispatch({ type: 'CART_SUB_ADD', payload: { ...data, TempID: cuid() } });

    // Close Form
    handleClose();
  };

  const handleClose = () => {
    props.onClose();
  };

  // Default Product Options are Final Expense and Mortgage Protection
  const productOptions = [LEAD_PRODUCT['FEX'], LEAD_PRODUCT['MP']];
  // Global Admins can Add Subscriptions for Annuities
  if (hasGroup(AWS_GROUP.LDS_GlobalAdmin)) {
    productOptions.push(LEAD_PRODUCT['ANN']);
  }

  // Get Lead Price for this Lead Type and Product
  const priceData =
    query.data?.Pricing.find(
      (i) => i.LeadType === data.LeadType && i.Product === data.Product,
    ) || null;

  // Get the price per Lead
  let perLeadCost: number | null = null;
  if (priceData) {
    if (data.Exclusive && priceData.ExlLeadPrice !== null) {
      perLeadCost = priceData.ExlLeadPrice;
    } else if (!data.Exclusive && priceData.LeadPrice !== null) {
      perLeadCost = priceData.LeadPrice;
    }
  }

  const WklyInv = data.WklyInv ? Number(data.WklyInv) : 0;
  // Investment is below per Lead cost
  let invBelow = false;
  if (
    data.LeadType === LEAD_TYPE['A'] &&
    perLeadCost !== null &&
    WklyInv < multNums(perLeadCost, 3)
  ) {
    // Minimum purchase for "A" Leads is 3 Leads
    invBelow = true;
  } else if (perLeadCost !== null && WklyInv < perLeadCost) {
    // Their weekly investment is below the cost a single Lead
    invBelow = true;
  }

  // This is the maximum investment an Agent can make for this Product/Type/County
  let invMax = 0;
  if (
    priceData &&
    priceData.LeadPrice !== null &&
    priceData.ExlLeadPrice !== null &&
    priceData.SubQtyMax !== null
  ) {
    if (data.Exclusive) {
      invMax = multNums(priceData.ExlLeadPrice, priceData.SubQtyMax);
    } else if (!data.Exclusive) {
      invMax = multNums(priceData.LeadPrice, priceData.SubQtyMax);
    }
  }

  // Investment is above subscription quantity max
  const invAbove = WklyInv > invMax;

  // Creat quick select price options as long as the value is below the max quantity
  let priceOptions: number[] = [];
  [3, 6, 9, 18].forEach((i) => {
    if (priceData && priceData.SubQtyMax !== null) {
      if (i <= priceData.SubQtyMax) {
        priceOptions.push(i);
      } else if (
        i > priceData.SubQtyMax &&
        !priceOptions.includes(priceData.SubQtyMax)
      ) {
        priceOptions.push(priceData.SubQtyMax);
      }
    }
  });

  // There are no subscriptions availaible for this Product and LeadType in this County
  const notAvailable =
    Boolean(
      priceData && priceData.SubQtyMax !== null && priceData.SubQtyMax <= 0,
    ) ||
    Boolean(
      data.LeadType === LEAD_TYPE['A'] &&
        priceData &&
        priceData.SubQtyMax !== null &&
        priceData.SubQtyMax < 3,
    );

  let submitDisabled =
    notAvailable ||
    invAbove ||
    invBelow ||
    !data.WklyInv ||
    data.WklyInv === '' ||
    WklyInv <= 0 ||
    (!queryLeadBill.data?.HasLeadBilling && !isAdmin);
  // If the user is an Admin and the product is an Annuity the submit button should not be disabled
  // The normal checks don't apply for this product for admins
  if (isAdmin && data.Product === LEAD_PRODUCT['ANN']) {
    submitDisabled = false;
  }

  return (
    <Drawer
      container={window.document.body}
      variant="temporary"
      anchor="right"
      open={props.open}
      onClose={handleClose}
      sx={{
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: { xs: '100vw', md: '60vw', lg: '50vw', xl: '40vw' },
          height: '100dvh',
        },
      }}
    >
      <Box sx={{ height: '100dvh', display: 'flex', flexDirection: 'column' }}>
        <Toolbar>
          <IconButton
            disabled={query.isLoading}
            sx={{ mr: 2 }}
            onClick={handleClose}
          >
            {query.isLoading || queryLeadBill.isLoading ? (
              <CircularProgress size={24} />
            ) : (
              <CloseIcon />
            )}
          </IconButton>

          <Box sx={{ flex: 1, display: 'flex', alignItems: 'center' }}>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ fontSize: { xs: 14, sm: 16, md: 20, lg: 22 } }}
            >
              Add Subscription for {county?.NAME} {county?.LSAD}
            </Typography>

            <Box sx={{ flex: 1 }} />
          </Box>
        </Toolbar>

        <Divider />

        {query.isLoading || queryLeadBill.isLoading ? (
          <LinearProgress />
        ) : query.isError ? (
          <RouteError />
        ) : (
          <Box
            sx={{
              minHeight: 0,
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box sx={{ flex: 1, overflow: 'scroll' }}>
              <Stack spacing={2} sx={{ p: 2 }}>
                {query.data && query.data.Regions.length ? (
                  <Stack spacing={1}>
                    <Box>
                      <Box sx={{ fontSize: 18 }}>
                        You can select specific Regions for this County:
                      </Box>
                      <Box
                        component="small"
                        sx={{ fontSize: 12, fontStyle: 'italic' }}
                      >
                        If no Regions are selected the subscription will be for
                        the entire county.
                      </Box>
                    </Box>

                    {query.data.Regions.map((region) => {
                      const isChecked = Boolean(
                        data.SubRegions.find(
                          (i) => i.FIPSRegId === region.FIPSRegId,
                        ),
                      );
                      return (
                        <Paper
                          key={region.FIPSRegId}
                          elevation={0}
                          sx={{
                            borderWidth: 1,
                            borderStyle: 'solid',
                            borderColor: isChecked ? '#2196f3' : '#00000022',
                            overflow: 'hidden',
                          }}
                        >
                          <Stack
                            spacing={1}
                            direction="row"
                            alignItems="center"
                          >
                            <Checkbox
                              size="small"
                              color="info"
                              checked={isChecked}
                              onChange={(event, checked) => {
                                if (checked) {
                                  setData((currentState) => ({
                                    ...currentState,
                                    SubRegions: [
                                      ...currentState.SubRegions,
                                      // Add new selected Region
                                      region,
                                    ],
                                  }));
                                } else {
                                  setData((currentState) => ({
                                    ...currentState,
                                    // Filter out this Region
                                    SubRegions: currentState.SubRegions.filter(
                                      (i) => i.FIPSRegId !== region.FIPSRegId,
                                    ),
                                  }));
                                }
                              }}
                            />

                            <Box sx={{ fontWeight: 'bold' }}>
                              {region.Region}
                            </Box>
                          </Stack>
                        </Paper>
                      );
                    })}
                  </Stack>
                ) : null}

                <ToggleButtonGroup
                  fullWidth
                  color="info"
                  exclusive
                  value={data.Product}
                  onChange={(event, newValue) => {
                    const Product = newValue || LEAD_PRODUCT['FEX'];
                    setData((currentState) => ({
                      ...currentState,
                      Product,
                      WklyInv: '0',
                    }));
                  }}
                >
                  {productOptions.map((Product) => {
                    return (
                      <ToggleButton key={Product} value={Product}>
                        {getProductTitle(Product as LEAD_PRODUCT)}
                      </ToggleButton>
                    );
                  })}
                </ToggleButtonGroup>

                <ToggleButtonGroup
                  fullWidth
                  color="info"
                  exclusive
                  value={data.LeadType}
                  onChange={(event, newValue) => {
                    const LeadType = newValue || LEAD_TYPE['A'];
                    setData((currentState) => ({
                      ...currentState,
                      LeadType,
                      WklyInv: '0',
                    }));
                  }}
                >
                  {Object.keys(LEAD_TYPE).map((LeadType) => {
                    return (
                      <ToggleButton key={LeadType} value={LeadType}>
                        {LeadType}
                      </ToggleButton>
                    );
                  })}
                </ToggleButtonGroup>

                <Stack spacing={1} direction="row" alignItems="center">
                  <FormControlLabel
                    control={
                      <Switch
                        size="medium"
                        color="info"
                        checked={showRecAgt}
                        onChange={(event, checked) => {
                          setShowRecAgt(checked);
                          // Reset RecAgt in data
                          setData((currentState) => ({
                            ...currentState,
                            RecAgt: null,
                          }));
                        }}
                      />
                    }
                    label={
                      <Box sx={{ fontWeight: 'bold', textWrap: 'nowrap' }}>
                        Receiving Agent
                      </Box>
                    }
                  />

                  <Box component="small" sx={{ color: '#4a4a4a' }}>
                    Do you want an Agent other than yourself to receive the
                    Leads generated from this subscription?
                  </Box>
                </Stack>

                {showRecAgt ? (
                  <SelectAgent
                    onSelectAgent={(agent) =>
                      setData((currentState) => ({
                        ...currentState,
                        RecAgt: agent,
                      }))
                    }
                  />
                ) : null}

                <Stack spacing={1} direction="row" alignItems="center">
                  <FormControlLabel
                    control={
                      <Switch
                        size="medium"
                        color="info"
                        checked={data.Exclusive}
                        onChange={(event, checked) => {
                          setData((currentState) => ({
                            ...currentState,
                            Exclusive: checked,
                            WklyInv: '0',
                          }));
                        }}
                      />
                    }
                    label={
                      <Box
                        sx={{
                          textTransform: 'uppercase',
                          fontWeight: 'bold',
                          color: 'darkorange',
                        }}
                      >
                        Exclusive
                      </Box>
                    }
                  />

                  <Box component="small" sx={{ color: '#4a4a4a' }}>
                    Cost double, but will be taken out of inventory regardless
                    if a policy is sold.
                  </Box>
                </Stack>

                <Box sx={{ fontSize: 22 }}>
                  How much would you like to invest in generating{' '}
                  {data.Exclusive ? (
                    <React.Fragment>
                      <Box
                        component="strong"
                        sx={{ textTransform: 'uppercase', color: 'darkorange' }}
                      >
                        Exclusive
                      </Box>{' '}
                    </React.Fragment>
                  ) : null}
                  <Box
                    component="strong"
                    sx={{ color: getProductColor(data.Product) }}
                  >
                    {getProductTitle(data.Product)}
                  </Box>{' '}
                  <Box
                    component="strong"
                    sx={{
                      fontSize: 28,
                      fontWeight: 'bold',
                      fontFamily: 'Roboto Mono',
                      color: 'teal',
                    }}
                  >
                    {data.LeadType}
                  </Box>{' '}
                  Leads{' '}
                  {data.RecAgt ? (
                    <Box component="span">
                      for{' '}
                      <Box component="strong">
                        {data.RecAgt.Fname} {data.RecAgt.Lname}
                      </Box>
                    </Box>
                  ) : null}{' '}
                  in <Box component="strong">{county?.NAME}</Box> County weekly?
                </Box>

                {perLeadCost ? (
                  <Box
                    component="small"
                    sx={{ fontStyle: 'italic', color: '#4a4a4a' }}
                  >
                    Per Lead cost is {toCurrency({ value: perLeadCost })}
                    {data.LeadType === LEAD_TYPE['A']
                      ? `, minimum weekly investment for ${
                          data.Exclusive ? 'Exclusive ' : ''
                        }"A" Leads is ${toCurrency({
                          value: multNums(3, perLeadCost),
                        })}`
                      : null}
                  </Box>
                ) : null}

                {invError && invAbove ? (
                  <Alert
                    severity="info"
                    action={
                      <Button
                        sx={{ whiteSpace: 'nowrap' }}
                        onClick={() => {
                          setData((currentState) => ({
                            ...currentState,
                            WklyInv: invMax,
                          }));
                          setInvError(false);
                        }}
                      >
                        Set Max
                      </Button>
                    }
                  >
                    <AlertTitle>Amount error</AlertTitle>
                    Your investment amount exceeds what's allowed for this
                    Product and Lead Type in the County.
                  </Alert>
                ) : null}

                {hasGroup(AWS_GROUP.LDS_GlobalAdmin) &&
                data.Product === LEAD_PRODUCT['ANN'] ? (
                  <Alert severity="warning">
                    <AlertTitle>Annuity Subscriptions</AlertTitle>A weekly
                    investment amount is not currently required for Admins
                    setting up new Annuity Subscriptions.
                  </Alert>
                ) : null}

                <TextField
                  disabled={data.Product === LEAD_PRODUCT['ANN']}
                  fullWidth
                  size="medium"
                  label="Weekly Investment"
                  type="number"
                  value={data.WklyInv}
                  onChange={(event) => {
                    setData((currentState) => ({
                      ...currentState,
                      WklyInv: event.target.value,
                    }));
                  }}
                  inputProps={{ style: { fontFamily: 'Roboto Mono' } }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  error={invError}
                  onFocus={() => setInvError(false)}
                  onBlur={() => {
                    if (data.WklyInv && priceData) {
                      const currentInv = Number(data.WklyInv);
                      if (currentInv !== 0) {
                        if (
                          data.Exclusive &&
                          priceData.ExlLeadPrice !== null &&
                          currentInv < priceData.ExlLeadPrice
                        ) {
                          setInvError(true);
                        } else if (
                          !data.Exclusive &&
                          priceData.LeadPrice !== null &&
                          currentInv < priceData.LeadPrice
                        ) {
                          setInvError(true);
                        } else if (invAbove) {
                          setInvError(true);
                        } else {
                          setInvError(false);
                        }
                      }
                    }
                  }}
                  helperText={
                    invError && invBelow
                      ? `Minimum weekly investment is ${toCurrency({
                          value:
                            data.LeadType === LEAD_TYPE['A']
                              ? perLeadCost
                                ? multNums(perLeadCost, 3)
                                : null
                              : perLeadCost,
                        })}`
                      : invError && invAbove
                        ? `Maximum weekly investment is ${toCurrency({
                            value: invMax,
                          })}`
                        : null
                  }
                />

                {priceData && priceOptions.length ? (
                  <Stack spacing={1} direction="row" alignItems="center">
                    {priceOptions.map((count) => {
                      let total = 0;
                      if (data.Exclusive && priceData.ExlLeadPrice) {
                        // Exclusive Leads
                        total = multNums(count, priceData.ExlLeadPrice);
                      } else if (!data.Exclusive && priceData.LeadPrice) {
                        total = multNums(count, priceData.LeadPrice);
                      }

                      if (total > 0) {
                        const isMax = count === priceData.SubQtyMax;
                        return (
                          <Button
                            key={count}
                            fullWidth
                            size="small"
                            variant="outlined"
                            onClick={() => {
                              const newWklyInv = String(
                                toFixed({ value: total }),
                              );
                              setData((currentState) => ({
                                ...currentState,
                                WklyInv: newWklyInv,
                              }));
                              setInvError(false);
                            }}
                          >
                            {count} - {toCurrency({ value: total })}
                            {isMax ? ' - MAX' : null}
                          </Button>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </Stack>
                ) : null}
              </Stack>
            </Box>

            {notAvailable ? (
              <React.Fragment>
                <Divider />

                <Stack
                  alignItems="center"
                  sx={{
                    p: 2,
                    px: 3,
                    fontSize: 18,
                    textAlign: 'center',
                    backgroundColor: '#EBF8FF',
                  }}
                >
                  <InfoIcon fontSize="large" color="warning" />

                  <Box sx={{ maxWidth: 440 }}>
                    Subscriptions are not currently availaible for{' '}
                    <Box
                      component="span"
                      sx={{
                        fontWeight: 'bold',
                        color: getProductColor(data.Product),
                      }}
                    >
                      {getProductTitle(data.Product)}
                    </Box>{' '}
                    <Box
                      component="span"
                      sx={{
                        fontFamily: 'Roboto Mono',
                        fontWeight: 'bold',
                        color: 'teal',
                      }}
                    >
                      {data.LeadType}
                    </Box>{' '}
                    Leads in{' '}
                    <Box component="span" sx={{ fontWeight: 'bold' }}>
                      {county?.NAME}
                    </Box>
                    .
                  </Box>
                  <Box sx={{ maxWidth: 440 }}>
                    Please check the availability of our other Products and Lead
                    Types in{' '}
                    <Box component="span" sx={{ fontWeight: 'bold' }}>
                      {county?.NAME}
                    </Box>
                    .
                  </Box>
                </Stack>
              </React.Fragment>
            ) : null}

            {!queryLeadBill.data?.HasLeadBilling && !isAdmin ? (
              <Alert severity="warning">
                <AlertTitle>Account Not Assigned</AlertTitle>
                An account needs to be assigned for Lead Billing in order to
                purchase a subscription.
                <br /> Visit{' '}
                <a
                  href="https://thealliancecontracting.com/wallet"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Contracting
                </a>{' '}
                to add/assign a bank account.
              </Alert>
            ) : null}

            <Divider />

            <Button
              disabled={submitDisabled}
              fullWidth
              size="large"
              color="info"
              variant="contained"
              disableElevation
              startIcon={<AddShoppingCartIcon />}
              sx={{ minHeight: 64, borderRadius: 0 }}
              onClick={handleAddToCart}
            >
              Add to Cart
            </Button>
          </Box>
        )}
      </Box>
    </Drawer>
  );
}
