import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { orderBy } from 'lodash';
import { LEAD_PRODUCT } from '../../types';
import { toCurrency } from '../../utils/formatter';
import { getProductColor, getProductTitle } from '../../utils/general';
import { getCountyByFIPS } from '../../utils/map';
import { sumArray } from '../../utils/sumarray';
import { LeadSubData, SUB_STATUS } from './data';
import { MySubscriptionsItem } from './my-subscriptions-item';

export function MySubscriptionsCounty(props: {
  FIPS: string;
  data: LeadSubData[];
  onSelect: (LeadSubId: LeadSubData['LeadSubId']) => void;
}) {
  const county = getCountyByFIPS(props.FIPS);

  // Get Total Invested Amount for County
  const investments: number[] = [];
  props.data.forEach((i) => {
    // Agents are charged for Subscriptions with a status of Active or Cancelling
    if ([SUB_STATUS['Active'], SUB_STATUS['Cancelling']].includes(i.Status)) {
      investments.push(i.WklyInv);
    }
  });
  const total = sumArray(investments);

  return (
    <Paper
      elevation={0}
      sx={{ border: '1px solid #00000022', overflow: 'hidden' }}
    >
      <Stack
        spacing={1}
        direction="row"
        alignItems="center"
        sx={{ p: 1, py: 0.5 }}
      >
        <Box
          sx={{
            color: '#b26500',
            fontSize: { xs: 16, md: 22 },
            fontWeight: 'bold',
            fontFamily: 'Roboto Mono',
          }}
        >
          {county?.StateAbbr}
        </Box>

        <Box sx={{ fontSize: { xs: 18, md: 24 } }}>{county?.NAME}</Box>

        <Box sx={{ flex: 1 }} />

        <Box
          sx={{
            fontFamily: 'Roboto Mono',
            fontSize: 16,
            color: '#4a4a4a',
          }}
        >
          {toCurrency({ value: total })}
        </Box>
      </Stack>

      <Divider />

      <Stack>
        {Object.keys(LEAD_PRODUCT).map((Product) => {
          const subsForProduct = orderBy(
            props.data.filter((i) => i.Product === Product),
            'LeadType',
            'asc',
          );

          if (subsForProduct.length) {
            return (
              <Stack key={`${props.FIPS}_${Product}`}>
                <Box
                  sx={{
                    p: 1,
                    fontWeight: 'bold',
                    color: getProductColor(Product as LEAD_PRODUCT),
                  }}
                >
                  {getProductTitle(Product as LEAD_PRODUCT)}
                </Box>

                {subsForProduct.map((item) => {
                  return (
                    <MySubscriptionsItem
                      key={item.LeadSubId}
                      item={item}
                      onSelect={props.onSelect}
                    />
                  );
                })}
              </Stack>
            );
          } else {
            return null;
          }
        })}
      </Stack>
    </Paper>
  );
}
