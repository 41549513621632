import { API } from '@aws-amplify/api';
import Close from '@mui/icons-material/Close';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { Alert, AlertTitle, CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { format } from 'date-fns';
import React from 'react';
import { DisplayMutationError } from '../../components/display-mutation-error';
import { DisplayValue } from '../../components/display-value';
import { AuthContext } from '../../contexts/auth-context';
import { usePermissions } from '../../hooks/use-permissions';
import { AWS_GROUP } from '../../types';
import { captureError } from '../../utils/capture-error';
import { LeadSubData } from './data';
import { EditWeeklyInvestment } from './edit-weekly-investment';
import { UpdateCancelledDate } from './update-cancelled-date';
import { UpdateFillFactor } from './update-fill-factor';
import { UpdateRegions } from './update-regions';

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export function ManageSubscription(props: {
  item: LeadSubData | undefined;
  subs: LeadSubData[] | undefined;
  onClose: () => void;
}) {
  // Props
  const { item } = props;
  // Context
  const {
    state: { impersonatedAgent, user },
  } = React.useContext(AuthContext);
  // State
  const [open, setOpen] = React.useState(false);
  // Hooks
  const { hasGroup } = usePermissions();

  const AgtNo = impersonatedAgent?.AgtNo || user?.getUsername();
  const isBilledAgt = user?.getUsername() === item?.BillToAgt;
  const isAdmin = hasGroup(AWS_GROUP.LDS_GlobalAdmin);

  let statusColor = '#00C868';
  switch (item?.Status) {
    case 'Cancelling':
      statusColor = 'darkorange';
      break;
    case 'Cancelled':
      statusColor = '#b61f26';
      break;
    case 'On Waitlist':
      statusColor = '#0094FF';
      break;
  }

  const queryClient = useQueryClient();
  // Mutation - Cancel/Reactivate Subscription
  const mutation = useMutation({
    mutationFn: async () => {
      if (item) {
        let mutationPath = '';
        if (item.Status === 'Active' && isAdmin) {
          // Admin is cancelling a subscription for a user
          mutationPath = '/admin/leads/subscriptions/cancel';
        } else if (item.Status === 'Active') {
          // User is cancelling a subscription
          mutationPath = '/leads/subscriptions/cancel';
        } else if (isAdmin) {
          // Admin is reactivating a subscription for a user
          mutationPath = '/admin/leads/subscriptions/restore';
        } else {
          // User is reactivating a subscription
          mutationPath = '/leads/subscriptions/restore';
        }

        await API.post('LeadsAPI', mutationPath, {
          body: { LeadSubId: item.LeadSubId, BillToAgt: item.BillToAgt },
        });
      }
    },
    onSuccess: async () => {
      setOpen(false);
      props.onClose();
      // Refetch subscriptions for user
      await queryClient.invalidateQueries({
        queryKey: ['/leads/subscriptions', { AgtNo }],
      });
      await queryClient.invalidateQueries({
        queryKey: ['/admin/leads/subscriptions', { FIPS: item?.FIPS }],
      });
    },
    onError: (error: any) => captureError({ data: { error } }),
  });

  // Query - Check if Agent has a default Lead Billing payment
  const pathLeadBill = '/leads/subscriptions/agent-lead-billing';
  const queryKey = [pathLeadBill];
  const queryLeadBill = useQuery({
    queryKey,
    queryFn: async () => {
      const response: {
        data: { HasLeadBilling: boolean };
      } = await API.post('LeadsAPI', pathLeadBill, {});
      if (response.data) {
        return response.data;
      }
      return null;
    },
  });

  const HasLeadBilling = queryLeadBill.data?.HasLeadBilling;

  const handleClose = async () => {
    props.onClose();
  };

  return (
    <Drawer
      container={window.document.body}
      variant="temporary"
      anchor="right"
      open={item !== undefined}
      onClose={handleClose}
      sx={{
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: { xs: '100vw', md: '50vw', lg: '40vw', xl: '30vw' },
          height: '100dvh',
        },
      }}
    >
      <Box sx={{ height: '100dvh', display: 'flex', flexDirection: 'column' }}>
        <Toolbar>
          <IconButton
            sx={{ mr: 2 }}
            onClick={handleClose}
            disabled={queryLeadBill.isLoading}
          >
            {queryLeadBill.isLoading ? (
              <CircularProgress size={22} />
            ) : (
              <Close />
            )}
          </IconButton>

          <Box sx={{ flex: 1, display: 'flex', alignItems: 'center' }}>
            <Typography variant="h6" noWrap component="div">
              Subscription Details
            </Typography>

            <Box sx={{ flex: 1 }} />

            <Box
              sx={{
                color: statusColor,
                fontWeight: 'bold',
                textTransform: 'uppercase',
              }}
            >
              {item?.Status}
            </Box>
          </Box>
        </Toolbar>
        <Divider />

        {item?.isSuspended ? (
          <Alert severity="warning">Billing is currently suspended</Alert>
        ) : null}

        <Box
          sx={{
            minHeight: 0,
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box sx={{ flex: 1, overflow: 'auto' }}>
            <Stack spacing={2} sx={{ p: 2, pb: 16 }}>
              <Paper
                elevation={0}
                sx={{ border: '1px solid #00000022', overflow: 'hidden' }}
              >
                <Stack direction="row" alignItems="center" sx={{ p: 1 }}>
                  <Box sx={{ flex: 1 }}>
                    <DisplayValue
                      label="Receiving Agent"
                      value={`${item?.RecAgtName || ''} (${
                        item?.RecAgt || ''
                      })`}
                    />
                  </Box>
                </Stack>
              </Paper>

              <Paper
                elevation={0}
                sx={{ border: '1px solid #00000022', overflow: 'hidden' }}
              >
                <Stack direction="row" alignItems="center" sx={{ p: 1 }}>
                  <Box sx={{ flex: 1 }}>
                    <DisplayValue
                      label="Billed Agent"
                      value={`${item?.BillAgtName || ''} (${
                        item?.BillToAgt || ''
                      })`}
                    />
                  </Box>
                </Stack>
              </Paper>

              {isAdmin && item?.BkndFillFactor ? (
                <UpdateFillFactor item={item} onClose={props.onClose} />
              ) : null}

              <Paper
                elevation={0}
                sx={{ border: '1px solid #00000022', overflow: 'hidden' }}
              >
                <Stack direction="row" alignItems="center" sx={{ p: 1 }}>
                  <Box sx={{ flex: 1 }}>
                    <DisplayValue label="State" value={item?.State || ''} />
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <DisplayValue label="County" value={item?.County || ''} />
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <DisplayValue label="FIPS" value={item?.FIPS || ''} />
                  </Box>
                </Stack>
              </Paper>

              {(item?.Regions && item?.Regions?.length > 0) || isAdmin ? (
                <Paper
                  elevation={0}
                  sx={{
                    border: '1px solid #00000022',
                    overflow: 'hidden',
                    p: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box>
                    <Box
                      sx={{
                        textTransform: 'uppercase',
                        fontSize: 10,
                        fontWeight: 'bold',
                        color: '#9b9b9b',
                        mb: 0.5,
                      }}
                    >
                      Region(s)
                    </Box>
                    <Stack
                      spacing={1}
                      direction="row"
                      alignItems="center"
                      sx={{ flexWrap: 'wrap' }}
                    >
                      {item?.Regions?.map((region) => {
                        return (
                          <Box
                            key={region.FIPSRegId}
                            sx={{
                              fontSize: 18,
                              border: '1px solid #0002',
                              borderRadius: 1,
                              px: 0.5,
                              textWrap: 'nowrap',
                            }}
                          >
                            {region.Region}
                          </Box>
                        );
                      })}
                    </Stack>
                  </Box>
                  {isAdmin && (
                    <UpdateRegions item={item} onClose={props.onClose} />
                  )}
                </Paper>
              ) : null}

              <Paper
                elevation={0}
                sx={{ border: '1px solid #00000022', overflow: 'hidden' }}
              >
                <Stack direction="row" alignItems="center" sx={{ p: 1 }}>
                  <Box sx={{ flex: 1 }}>
                    <DisplayValue
                      label="Product"
                      value={item?.DisplayName || ''}
                    />
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <DisplayValue
                      label="Lead Type"
                      value={item?.LeadType || ''}
                    />
                  </Box>
                </Stack>
              </Paper>

              <Paper
                elevation={0}
                sx={{ border: '1px solid #00000022', overflow: 'hidden' }}
              >
                <Stack
                  spacing={1}
                  direction="row"
                  alignItems="center"
                  sx={{ p: 1 }}
                >
                  <Box sx={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                    <DisplayValue
                      label="Weekly Investment"
                      value={formatter.format(item?.WklyInv || 0)}
                    />
                    {(item?.Status === 'Active' &&
                      isBilledAgt &&
                      HasLeadBilling) ||
                    isAdmin ? (
                      <EditWeeklyInvestment
                        item={item}
                        onClose={props.onClose}
                      />
                    ) : null}
                  </Box>

                  <Box sx={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                    <DisplayValue
                      label="Projected Leads"
                      value={
                        item?.Quantity +
                          ` (${formatter.format(item?.LeadPrice || 0)})` || 0
                      }
                    />
                  </Box>

                  <Box sx={{ flex: 1 }}>
                    <DisplayValue
                      label="Exclusive"
                      value={item?.Exclusive ? 'Yes' : 'No'}
                    />
                  </Box>
                </Stack>
              </Paper>

              <Paper
                elevation={0}
                sx={{ border: '1px solid #00000022', overflow: 'hidden' }}
              >
                <Stack direction="row" alignItems="center" sx={{ p: 1 }}>
                  <Box sx={{ flex: 1 }}>
                    <DisplayValue
                      label="Start Date"
                      value={
                        item?.CreatedDate
                          ? format(new Date(item?.CreatedDate), 'yyyy-MM-dd')
                          : ''
                      }
                    />
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    {item?.Status === 'Cancelling' ||
                    item?.Status === 'Cancelled' ? (
                      <Stack spacing={1} direction="row" alignItems="center">
                        <DisplayValue
                          label="Expiration Date"
                          value={item?.CancelledDate}
                        />
                        <Tooltip
                          placement="right"
                          arrow
                          title='Subscriptions with an "A" lead type will continue to be charged 8 weeks following date of cancellation.'
                        >
                          <InfoOutlined fontSize="small" color="info" />
                        </Tooltip>

                        {isAdmin && (
                          <UpdateCancelledDate
                            item={item}
                            onClose={props.onClose}
                          />
                        )}
                      </Stack>
                    ) : null}
                  </Box>
                </Stack>
              </Paper>
              {!HasLeadBilling && isBilledAgt ? (
                <Alert severity="warning">
                  <AlertTitle>Account Not Assigned</AlertTitle>
                  <span style={{ fontStyle: 'italic' }}>
                    An account needs to be assigned for Lead Billing in order to
                    manage this subscription.
                  </span>
                  <br />
                  <br /> Visit{' '}
                  <a
                    href="https://thealliancecontracting.com/wallet"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Contracting
                  </a>{' '}
                  to add/assign a bank account.
                </Alert>
              ) : null}

              {isBilledAgt || isAdmin ? (
                <Button
                  variant="contained"
                  fullWidth
                  color={item?.Status === 'Active' ? 'error' : 'info'}
                  onClick={() => setOpen(true)}
                  disabled={
                    mutation.isPending ||
                    (isBilledAgt &&
                      !HasLeadBilling &&
                      item?.Status !== 'Active')
                  }
                >
                  {item?.Status === 'Active'
                    ? 'Cancel Subscription'
                    : 'Reactivate Subscription'}
                </Button>
              ) : null}
            </Stack>
          </Box>
        </Box>
      </Box>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          <Stack spacing={1} direction="row" alignItems="center">
            <Box>
              {item?.Status === 'Active'
                ? 'Cancel Subscription'
                : 'Reactivate Subscription'}
            </Box>
            <Box sx={{ flex: 1 }} />
            <IconButton size="small" onClick={() => setOpen(false)}>
              <Close fontSize="small" />
            </IconButton>
          </Stack>
        </DialogTitle>
        <Stack spacing={2} sx={{ p: 2 }}>
          <Box>
            Are you sure you want to{' '}
            {item?.Status === 'Active' ? 'cancel' : 'reactivate'} this
            subscription?
          </Box>
          {item?.LeadType === 'A' && item?.Status === 'Active' && (
            <Box>
              <strong>NOTE: </strong>Subscriptions with an <strong>"A"</strong>{' '}
              lead type will continue to be charged 8 weeks following date of
              cancellation.
            </Box>
          )}
          <DisplayMutationError
            isError={mutation.isError}
            error={mutation.error}
            onClose={() => mutation.reset()}
            displayMore={isAdmin}
          />
          <Box sx={{ textAlign: 'right' }}>
            <Button
              size="small"
              color="info"
              variant="contained"
              onClick={() => mutation.mutate()}
              disabled={mutation.isError}
            >
              Yes
            </Button>
          </Box>
        </Stack>
      </Dialog>
    </Drawer>
  );
}
