import { Amplify, Auth } from 'aws-amplify';

const getToken = async () => {
  try {
    // If the user is logged in add Authorization token to the request
    const Authorization = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    return { Authorization };
  } catch (error) {
    // The user is not logged in
    return;
  }
};

Amplify.configure({
  Auth: {
    // Amazon Cognito Region
    region: 'us-east-1',
    // Amazon Cognito User Pool ID
    userPoolId: process.env.REACT_APP_AWS_POOL_ID,
    // Amazon Cognito Web Client ID
    userPoolWebClientId: process.env.REACT_APP_AWS_CLIENT_ID,
    // Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: true,
    // Hosted UI configuration
    oauth: {
      domain: process.env.REACT_APP_AWS_DOMAIN,
      scope: [
        'phone',
        'email',
        'profile',
        'openid',
        'aws.cognito.signin.user.admin',
      ],
      redirectSignIn: process.env.REACT_APP_AWS_REDIRECT_SIGN_IN,
      redirectSignOut: process.env.REACT_APP_AWS_REDIRECT_SIGN_OUT,
      // note that REFRESH token will only be generated when the responseType is code
      responseType: 'code',
    },
  },
  API: {
    endpoints: [
      {
        name: 'LeadsAPI',
        endpoint: process.env.REACT_APP_LEADS_API_URL,
        custom_header: async () => {
          const Authorization = (await Auth.currentSession())
            .getAccessToken()
            .getJwtToken();
          return { Authorization };
        },
      },
      {
        name: 'DashboardAPI',
        endpoint: process.env.REACT_APP_DASHBOARD_API_URL,
        custom_header: async () => {
          const Authorization = (await Auth.currentSession())
            .getAccessToken()
            .getJwtToken();
          return { Authorization };
        },
      },
      {
        name: 'ContractingAPI',
        endpoint: process.env.REACT_APP_CONTRACTING_API_URL,
        custom_header: async () => {
          const Authorization = (await Auth.currentSession())
            .getAccessToken()
            .getJwtToken();
          return { Authorization };
        },
      },
      {
        name: 'RegistrationAPI',
        endpoint: process.env.REACT_APP_CONTRACTING_API_URL,
        custom_header: async () => {
          return await getToken();
        },
      },
      {
        name: 'EventsAPI',
        endpoint: process.env.REACT_APP_EVENTS_API_URL,
        custom_header: async () => {
          return await getToken();
        },
      },
    ],
  },
});
