/**
 * Converts county names containing Latin characters with diacritics
 * (such as accents, tildes, or umlauts) to plain ASCII characters.
 * This is useful for normalizing names like "Comerío" to "Comerio".
 *
 * @param str The string to be normalized.
 * @returns The normalized string with diacritics removed.
 */
export function stripDiacritics(str: string): string {
  // decompose each character in the string into its normalized form (NFD stands for Normalization Form Decomposed)
  // use a regular expression to match and remove all diacritic marks
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}
