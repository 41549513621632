import { LoadScriptProps } from '@react-google-maps/api';
import { CountyFeatureData, countyFeatures } from '../data/county-features';
import {
  CartLeadData,
  CartSubscriptionData,
  FeatureData,
  InventoryData,
} from '../types';

export function getCounty(feature: google.maps.Data.Feature) {
  // @ts-ignore
  const STATE = feature.getProperty('STATE') as string | undefined;
  const COUNTY = feature.getProperty('COUNTY') as string | undefined;
  if (STATE && COUNTY) {
    const fips = STATE + COUNTY;
    const county = countyFeatures.find((i) => i.fips === fips);
    return county || null;
  } else {
    return null;
  }
}

/**
 * Get FIPS code from Google Maps Feature
 */
export function getFeatureFIPS(feature: google.maps.Data.Feature) {
  // @ts-ignore
  const STATE = feature.getProperty('STATE') as string | undefined;
  const COUNTY = feature.getProperty('COUNTY') as string | undefined;
  if (STATE && COUNTY) {
    const StateName = stateNames[STATE];
    const StateAbbr = stateAbbrs[StateName];
    return `${StateAbbr}${COUNTY}`;
  } else {
    return null;
  }
}

/**
 * Get FIPS code from GeoJSON for Counties
 */
export function getCountyFIPS(feature: FeatureData) {
  const { STATE, COUNTY } = feature.properties;
  if (STATE && COUNTY) {
    const StateName = stateNames[STATE];
    const StateAbbr = stateAbbrs[StateName];
    return `${StateAbbr}${COUNTY}`;
  } else {
    return null;
  }
}

export function getCountyByFIPS(FIPS: CountyFeatureData['FIPS']) {
  const county = countyFeatures.find((i) => i.FIPS === FIPS);
  return county || null;
}

export function getCountyCoords(feature: FeatureData) {
  const { STATE, COUNTY } = feature.properties;

  let lat = 0;
  let lng = 0;

  if (STATE && COUNTY) {
    const fips = STATE + COUNTY;
    const county = countyFeatures.find((i) => i.fips === fips);
    if (county) {
      lat = county.lat;
      lng = county.lng;
    }
  }

  if (lat !== 0 && lng !== 0) {
    return [lat, lng];
  } else {
    return null;
  }
}

// {
//   "A": "0",
//   "B": "0",
//   "C": "0",
//   "County": "ALEUTIANS EAST",
//   "D": "0",
//   "E": "0",
//   "F": "0",
//   "FIPS": "AK013",
//   "G": "0",
//   "Region": "",
//   "State": "AK"
// }
export function countInventory(county: InventoryData | CartLeadData) {
  let count = 0;

  // A
  if (county.A) {
    count = count + Number(county.A);
  }

  // B
  if (county.B) {
    count = count + Number(county.B);
  }

  // C
  if (county.C) {
    count = count + Number(county.C);
  }

  // D
  if (county.D) {
    count = count + Number(county.D);
  }

  // E
  if (county.E) {
    count = count + Number(county.E);
  }

  // F
  if (county.F) {
    count = count + Number(county.F);
  }

  // G
  if (county.G) {
    count = count + Number(county.G);
  }

  return count;
}

export function cartChanged(params: {
  selectedData: CartLeadData;
  cartData: CartLeadData | undefined;
}) {
  // Params
  const { selectedData, cartData } = params;

  let hasChanged = false;

  if (cartData) {
    // A
    if (selectedData.A !== cartData.A) {
      hasChanged = true;
    }

    // B
    if (selectedData.B !== cartData.B) {
      hasChanged = true;
    }

    // C
    if (selectedData.C !== cartData.C) {
      hasChanged = true;
    }

    // D
    if (selectedData.D !== cartData.D) {
      hasChanged = true;
    }

    // E
    if (selectedData.E !== cartData.E) {
      hasChanged = true;
    }

    // F
    if (selectedData.F !== cartData.F) {
      hasChanged = true;
    }

    // G
    if (selectedData.G !== cartData.G) {
      hasChanged = true;
    }
  } else {
    // A
    if (selectedData.A !== 0) {
      hasChanged = true;
    }

    // B
    if (selectedData.B !== 0) {
      hasChanged = true;
    }

    // C
    if (selectedData.C !== 0) {
      hasChanged = true;
    }

    // D
    if (selectedData.D !== 0) {
      hasChanged = true;
    }

    // E
    if (selectedData.E !== 0) {
      hasChanged = true;
    }

    // F
    if (selectedData.F !== 0) {
      hasChanged = true;
    }

    // G
    if (selectedData.G !== 0) {
      hasChanged = true;
    }
  }

  return hasChanged;
}

export function countSubscriptions(county: CartSubscriptionData) {
  let count = 0;

  // A
  if (county.A && Number(county.A) > 0) {
    count = count + 1;
  }

  // B
  if (county.B && Number(county.B) > 0) {
    count = count + 1;
  }

  // C
  if (county.C && Number(county.C) > 0) {
    count = count + 1;
  }

  // D
  if (county.D && Number(county.D) > 0) {
    count = count + 1;
  }

  // E
  if (county.E && Number(county.E) > 0) {
    count = count + 1;
  }

  // F
  if (county.F && Number(county.F) > 0) {
    count = count + 1;
  }

  // G
  if (county.G && Number(county.G) > 0) {
    count = count + 1;
  }

  return count;
}

export function cartChangedSubscriptions(params: {
  selectedData: CartSubscriptionData;
  cartData: CartSubscriptionData | undefined;
}) {
  // Params
  const { selectedData, cartData } = params;

  let hasChanged = false;

  if (cartData) {
    // A
    if (selectedData.A !== cartData.A) {
      hasChanged = true;
    }

    // B
    if (selectedData.B !== cartData.B) {
      hasChanged = true;
    }

    // C
    if (selectedData.C !== cartData.C) {
      hasChanged = true;
    }

    // D
    if (selectedData.D !== cartData.D) {
      hasChanged = true;
    }

    // E
    if (selectedData.E !== cartData.E) {
      hasChanged = true;
    }

    // F
    if (selectedData.F !== cartData.F) {
      hasChanged = true;
    }

    // G
    if (selectedData.G !== cartData.G) {
      hasChanged = true;
    }
  } else {
    // A
    if (selectedData.A !== 0) {
      hasChanged = true;
    }

    // B
    if (selectedData.B !== 0) {
      hasChanged = true;
    }

    // C
    if (selectedData.C !== 0) {
      hasChanged = true;
    }

    // D
    if (selectedData.D !== 0) {
      hasChanged = true;
    }

    // E
    if (selectedData.E !== 0) {
      hasChanged = true;
    }

    // F
    if (selectedData.F !== 0) {
      hasChanged = true;
    }

    // G
    if (selectedData.G !== 0) {
      hasChanged = true;
    }
  }

  return hasChanged;
}

export const stateNames: { [key: string]: string } = {
  '10': 'Delaware',
  '11': 'District of Columbia',
  '12': 'Florida',
  '13': 'Georgia',
  '15': 'Hawaii',
  '16': 'Idaho',
  '17': 'Illinois',
  '18': 'Indiana',
  '19': 'Iowa',
  '20': 'Kansas',
  '21': 'Kentucky',
  '22': 'Louisiana',
  '23': 'Maine',
  '24': 'Maryland',
  '25': 'Massachusetts',
  '26': 'Michigan',
  '27': 'Minnesota',
  '28': 'Mississippi',
  '29': 'Missouri',
  '30': 'Montana',
  '31': 'Nebraska',
  '32': 'Nevada',
  '33': 'New Hampshire',
  '34': 'New Jersey',
  '35': 'New Mexico',
  '36': 'New York',
  '37': 'North Carolina',
  '38': 'North Dakota',
  '39': 'Ohio',
  '40': 'Oklahoma',
  '41': 'Oregon',
  '42': 'Pennsylvania',
  '44': 'Rhode Island',
  '45': 'South Carolina',
  '46': 'South Dakota',
  '47': 'Tennessee',
  '48': 'Texas',
  '49': 'Utah',
  '50': 'Vermont',
  '51': 'Virginia',
  '53': 'Washington',
  '54': 'West Virginia',
  '55': 'Wisconsin',
  '56': 'Wyoming',
  '72': 'Puerto Rico',
  '04': 'Arizona',
  '05': 'Arkansas',
  '06': 'California',
  '08': 'Colorado',
  '09': 'Connecticut',
  '01': 'Alabama',
  '02': 'Alaska',
};

export const stateAbbrs: { [key: string]: string } = {
  Alabama: 'AL',
  Alaska: 'AK',
  Arizona: 'AZ',
  Arkansas: 'AR',
  California: 'CA',
  Colorado: 'CO',
  Connecticut: 'CT',
  Delaware: 'DE',
  'District of Columbia': 'DC',
  Florida: 'FL',
  Georgia: 'GA',
  Hawaii: 'HI',
  Idaho: 'ID',
  Illinois: 'IL',
  Indiana: 'IN',
  Iowa: 'IA',
  Kansas: 'KS',
  Kentucky: 'KY',
  Louisiana: 'LA',
  Maine: 'ME',
  Maryland: 'MD',
  Massachusetts: 'MA',
  Michigan: 'MI',
  Minnesota: 'MN',
  Mississippi: 'MS',
  Missouri: 'MO',
  Montana: 'MT',
  Nebraska: 'NE',
  Nevada: 'NV',
  'New Hampshire': 'NH',
  'New Jersey': 'NJ',
  'New Mexico': 'NM',
  'New York': 'NY',
  'North Carolina': 'NC',
  'North Dakota': 'ND',
  Ohio: 'OH',
  Oklahoma: 'OK',
  Oregon: 'OR',
  Pennsylvania: 'PA',
  'Puerto Rico': 'PR',
  'Rhode Island': 'RI',
  'South Carolina': 'SC',
  'South Dakota': 'SD',
  Tennessee: 'TN',
  Texas: 'TX',
  Utah: 'UT',
  Vermont: 'VT',
  Virginia: 'VA',
  Washington: 'WA',
  'West Virginia': 'WV',
  Wisconsin: 'WI',
  Wyoming: 'WY',
};

export const center = { lat: 36.07825340392348, lng: -79.4315004019236 };
export const libraries: LoadScriptProps['libraries'] = ['visualization'];
export const defaultStyle = {
  fillColor: 'black',
  strokeColor: 'black',
  strokeWeight: 1,
  fillOpacity: 0.2,
};
export const hoverStyle = {
  fillColor: 'blue',
  strokeColor: 'blue',
  strokeWeight: 1,
  fillOpacity: 0.4,
};
export const selectedStyle = {
  fillColor: 'aqua',
  strokeColor: 'aqua',
  strokeWeight: 1,
  fillOpacity: 0.4,
};
export const premiumStyle = {
  fillColor: 'red',
  strokeColor: 'red',
  strokeWeight: 1,
  fillOpacity: 0.2,
};
export const discountStyle = {
  fillColor: '#00C868',
  strokeColor: '#00C868',
  strokeWeight: 1,
  fillOpacity: 0.2,
};
