import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import React from 'react';
import { LEAD_PRODUCT, LEAD_TYPE } from '../../types';
import { toCurrency } from '../../utils/formatter';
import { getProductTitle } from '../../utils/general';
import { sumArray } from '../../utils/sumarray';
import { PurchaseInfoData, PurchaseRegionData } from './data';

function RegionOption(props: {
  Region: PurchaseRegionData;
  onClick: () => void;
}) {
  // Props
  const { Region } = props;
  // State
  const [viewZips, setViewZips] = React.useState(false);

  const regionTotalLeads = sumArray(
    Region.Pricing.map((i) => i.TotalLeads || 0),
  );
  const hasLeads = Boolean(regionTotalLeads);

  if (hasLeads) {
    return (
      <Box
        sx={{
          overflow: 'hidden',
          borderWidth: 2,
          borderStyle: 'solid',
          borderColor: '#eaeaea',
          borderRadius: 1,
          backgroundColor: '#fff',
          transition: 'all 0.2s',
          ':hover': {
            transform: 'scale(1.01)',
            borderColor: '#aeaeae',
          },
        }}
      >
        <Box
          component="button"
          onClick={props.onClick}
          sx={{
            p: 1,
            width: '100%',
            textAlign: 'left',
            fontFamily: 'Roboto',
            background: 'none',
            border: 'none',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            transition: 'all 0.2s',
            ':hover': {
              backgroundColor: '#f5f5f5',
            },
          }}
        >
          <Box sx={{ flex: 1 }}>
            <Stack
              spacing={1}
              direction="row"
              alignItems="center"
              sx={{ p: 0.5 }}
            >
              <Box sx={{ fontWeight: 'bold', fontSize: 24 }}>
                {Region.Region}
              </Box>

              <Box sx={{ flex: 1 }} />
            </Stack>

            <Stack spacing={0.5}>
              {Object.keys(LEAD_PRODUCT).map((Product) => {
                const productLeads = sumArray(
                  Region.Pricing.map((i) => {
                    if (i.Product === Product) {
                      return i.TotalLeads || 0;
                    } else {
                      return 0;
                    }
                  }),
                );
                const hasProductLeads = Boolean(productLeads);

                if (hasProductLeads) {
                  return (
                    <Stack key={Product} spacing={0.5} sx={{ pl: 1 }}>
                      <Box sx={{ fontWeight: 'bold' }}>
                        {getProductTitle(Product as LEAD_PRODUCT)}
                      </Box>
                      {Object.keys(LEAD_TYPE).map((LeadType) => {
                        const leadType = Region.Pricing.find(
                          (i) =>
                            i.Product === Product && i.LeadType === LeadType,
                        );

                        if (
                          leadType &&
                          leadType.TotalLeads &&
                          Number(leadType.TotalLeads) > 0
                        ) {
                          return (
                            <Stack
                              key={LeadType}
                              spacing={1}
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 1, fontFamily: 'Roboto Mono' }}
                            >
                              <Box sx={{ color: 'teal', fontWeight: 'bold' }}>
                                {leadType.LeadType}
                              </Box>
                              <Box>-</Box>
                              <Box>
                                {toCurrency({ value: leadType.LeadPrice })}
                              </Box>
                              <Box>-</Box>
                              <Box sx={{ fontWeight: 'bold' }}>
                                {leadType.TotalLeads}
                              </Box>
                            </Stack>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </Stack>
                  );
                } else {
                  return null;
                }
              })}
            </Stack>
          </Box>

          <ArrowForwardIcon />
        </Box>

        <Box sx={{ p: 0.5 }}>
          <Button
            fullWidth
            size="small"
            variant="outlined"
            startIcon={
              viewZips ? (
                <ExpandLessIcon fontSize="small" color="inherit" />
              ) : (
                <ExpandMoreIcon fontSize="small" color="inherit" />
              )
            }
            onClick={() => setViewZips((currentState) => !currentState)}
          >
            {viewZips ? 'Hide Zip Codes' : 'View Zip Codes for Region'}
          </Button>
        </Box>

        {viewZips ? (
          <Stack sx={{ p: 1 }}>
            <Box component="small" sx={{ fontStyle: 'italic' }}>
              Zip codes in this Region:
            </Box>

            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
              {Region.ZipCodes.map((ZipCode) => {
                return (
                  <Box
                    key={ZipCode}
                    component="strong"
                    sx={{
                      p: 0.4,
                      fontSize: 12,
                      color: '#4a4a4a',
                    }}
                  >
                    {ZipCode}
                  </Box>
                );
              })}
            </Box>
          </Stack>
        ) : null}
      </Box>
    );
  } else {
    return (
      <Box
        sx={{
          opacity: 0.4,
          fontFamily: 'Roboto',
          textAlign: 'left',
          borderWidth: 2,
          borderStyle: 'solid',
          borderColor: '#eaeaea',
          borderRadius: 1,
          backgroundColor: '#fff',
        }}
      >
        <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>
          <Box sx={{ flex: 1 }}>
            <Stack direction="row" alignItems="center" sx={{ p: 0.5 }}>
              <Box sx={{ fontWeight: 'bold', fontSize: 24 }}>
                {Region.Region}
              </Box>

              <Box sx={{ flex: 1 }} />
            </Stack>
          </Box>

          <ArrowForwardIcon />
        </Box>
      </Box>
    );
  }
}

export function PurchaseAddSelectRegion(props: {
  PurchaseInfo: PurchaseInfoData;
  onSelect: (region: PurchaseRegionData) => void;
}) {
  // Props
  const { PurchaseInfo } = props;

  return (
    <Box
      sx={{
        minHeight: 0,
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box sx={{ flex: 1, overflow: 'scroll' }}>
        <Stack spacing={2} sx={{ p: 2, pb: 16 }}>
          <Box sx={{ fontWeight: 'bold', fontSize: 32, color: '#4a4a4a' }}>
            Select Region
          </Box>

          {PurchaseInfo.Regions.map((Region) => {
            return (
              <RegionOption
                key={Region.FIPSRegId}
                Region={Region}
                onClick={() => props.onSelect(Region)}
              />
            );
          })}
        </Stack>
      </Box>
    </Box>
  );
}
