import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { format } from 'date-fns/format';
import { formatDistanceToNow } from 'date-fns/formatDistanceToNow';
import { isPast } from 'date-fns/isPast';
import React from 'react';
import { CartData } from '../../routes/leads-map/data';

// useInterval from
// https://overreacted.io/making-setinterval-declarative-with-react-hooks/
type IntervalFunction = () => unknown | void;
function useInterval(callback: IntervalFunction, delay: number) {
  const savedCallback = React.useRef<IntervalFunction | null>(null);

  // Remember the latest callback.
  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  React.useEffect(() => {
    function tick() {
      if (savedCallback.current !== null) {
        savedCallback.current();
      }
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

// Carts are saved for 15 minutes
const cartSaved = 15 * 60 * 1000;

export function CartPurchaseExpire(props: { cart: CartData | null }) {
  // State
  const [expireIn, setExpireIn] = React.useState('');
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    if (props.cart && props.cart.Expiration) {
      const dateExpire = new Date(props.cart.Expiration);
      setExpireIn(
        formatDistanceToNow(dateExpire, {
          includeSeconds: true,
          addSuffix: true,
        }),
      );

      const timeCurrent = new Date().getTime();
      const timeExpire = dateExpire.getTime();
      const remaining = timeExpire - timeCurrent;
      const newProgress = (remaining / cartSaved) * 100;
      setProgress(100 - newProgress);
    } else {
      setProgress(0);
    }
  }, [props.cart]);

  // 1 second delay between intervals
  const delay = 1000;
  useInterval(() => {
    if (props.cart && props.cart.Expiration) {
      const dateExpire = new Date(props.cart.Expiration);
      setExpireIn(
        formatDistanceToNow(dateExpire, {
          includeSeconds: true,
          addSuffix: true,
        }),
      );

      const timeCurrent = new Date().getTime();
      const timeExpire = dateExpire.getTime();
      const remaining = timeExpire - timeCurrent;
      const newProgress = (remaining / cartSaved) * 100;
      setProgress(100 - newProgress);
    }
  }, delay);

  if (props.cart && props.cart.Expiration) {
    const { Expiration } = props.cart;
    const dateExpiration = new Date(Expiration);
    const hasExpired = isPast(dateExpiration);

    return (
      <Paper
        elevation={0}
        sx={{
          overflow: 'hidden',
          backgroundColor: 'rgba(158, 209, 237, 0.05)',
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: hasExpired ? '#d32f2f' : 'rgb(158, 209, 237)',
          borderBottom: 'none',
        }}
      >
        <Box sx={{ p: 0.6, pl: 2 }}>
          <Stack spacing={2} direction="row" alignItems="center">
            {hasExpired ? (
              <WarningAmberIcon fontSize="large" color="warning" />
            ) : (
              <DoneOutlineIcon fontSize="large" color="success" />
            )}

            <Box sx={{ flex: 1, fontSize: { xs: 14, md: 16 } }}>
              <Box>
                Leads in cart are saved until{' '}
                <Box component="strong">{format(dateExpiration, 'p')}</Box>
              </Box>

              {hasExpired ? (
                <Box>
                  Cart reset <Box component="strong">{expireIn}</Box>
                </Box>
              ) : (
                <Box>
                  Cart resets <Box component="strong">{expireIn}</Box>
                </Box>
              )}
            </Box>
          </Stack>
        </Box>

        {hasExpired ? (
          <LinearProgress color="error" variant="determinate" value={100} />
        ) : (
          <LinearProgress color="info" variant="determinate" value={progress} />
        )}
      </Paper>
    );
  } else {
    return null;
  }
}
