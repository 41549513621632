import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import { orderBy } from 'lodash';
import React from 'react';
import { RouteError } from '../../../components/route-container/route-error';
import { SearchField, searchBy } from '../../../components/search-field';
import { TableColumnHeaders } from '../../../components/table-column-headers';
import { AuthContext } from '../../../contexts/auth-context';
import { ColumnHeader } from '../../../types';
import { isAgt } from '../../../utils/is-agent';
import { subarray } from '../../../utils/subarray';
import { LeadSubData } from '../../subscriptions/data';
import { SubscriptionsRow } from './subscriptions-row';

const tableColumns: ColumnHeader<LeadSubData>[] = [
  { key: 'CreatedDate' },
  { key: 'Status' },
  { key: 'FIPS' },
  { key: 'State', align: 'right' },
  { key: 'County', align: 'left' },
  { key: 'Product', align: 'right' },
  { key: 'LeadType', align: 'left' },
  { key: 'LeadPrice', align: 'right' },
  { key: 'WklyInv', align: 'right' },
  { key: 'RecAgt', align: 'right' },
  { key: 'RecAgtName', align: 'left' },
  { key: 'BillToAgt', align: 'right' },
  { key: 'BillAgtName', align: 'left' },
];

export function Subscriptions() {
  // Context
  const {
    state: { user, impersonatedAgent },
  } = React.useContext(AuthContext);
  // State
  const [searchString, setSearchString] = React.useState('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(250);
  const [sort, setSort] = React.useState<'asc' | 'desc'>('desc');
  const [sortBy, setSortBy] =
    React.useState<ColumnHeader<LeadSubData>['key']>('CreatedDate');

  const AgtNo = impersonatedAgent?.AgtNo || user?.getUsername();

  // Query - Team Subscriptions
  const path = '/leads/team/subscription';
  const query = useQuery({
    queryKey: [path, { AgtNo }],
    queryFn: async () => {
      if (isAgt(AgtNo)) {
        const response: {
          data: LeadSubData[];
        } = await API.post('LeadsAPI', path, { body: { AgtNo } });

        if (response.data.length) {
          return response.data;
        }
      }

      return [];
    },
  });

  // Used by TableHead
  const handleSort =
    (property: ColumnHeader<LeadSubData>['key']) =>
    (event: React.MouseEvent<unknown>) => {
      if (property === sortBy) {
        // User is changing sort of current column
        setSort((currentState) => (currentState === 'asc' ? 'desc' : 'asc'));
      } else {
        // User is changing sort of new column
        setSortBy(property);
        setSort('asc');
      }
    };

  // Used by TablePagination
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  // Used by TablePagination
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (query.isLoading) {
    return <LinearProgress color="info" />;
  } else if (query.isError) {
    return <RouteError />;
  } else {
    // Searching
    const searchedItems = searchBy(query.data || [], searchString);
    // Sorting
    const sortedItems = orderBy(searchedItems, sortBy, sort);
    // Paging
    const start = page * rowsPerPage;
    const end = start + rowsPerPage;
    const renderItems = subarray({ data: sortedItems, start, end });

    return (
      <Box sx={{ width: { xs: '100vw', sm: 'inherit' } }}>
        <Container maxWidth="xl">
          <Box sx={{ pt: 2 }}>
            <Stack spacing={2}>
              <Paper
                elevation={0}
                sx={{ border: '1px solid #ccc', overflow: 'hidden' }}
              >
                <Stack direction="row" alignItems="center" sx={{ px: 2 }}>
                  <SearchField
                    id="team-search-subscriptions"
                    placeholder="Search subscriptions"
                    value={searchString}
                    onChange={(event) => {
                      setSearchString(event.target.value);
                      // Reset page
                      setPage(0);
                    }}
                  />

                  <Box sx={{ flex: 1 }} />

                  <TablePagination
                    rowsPerPageOptions={[250, 500, 1000]}
                    component="div"
                    count={sortedItems.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Stack>

                <Divider />

                <Box sx={{ width: '100%', overflowX: 'auto' }}>
                  <TableContainer
                    sx={{
                      height:
                        query.data && query.data.length
                          ? { xs: '60dvh', md: '70dvh' }
                          : undefined,
                    }}
                  >
                    <Table stickyHeader aria-label="sticky table" size="small">
                      <TableHead>
                        <TableRow>
                          <TableColumnHeaders
                            sort={sort}
                            sortBy={sortBy}
                            tableColumns={tableColumns}
                            sortHandler={handleSort}
                          />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {renderItems.map((row) => {
                          return (
                            <SubscriptionsRow
                              key={row.LeadSubId}
                              row={row}
                              tableColumns={tableColumns}
                            />
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  {query.data && query.data.length === 0 ? (
                    <Box
                      sx={{
                        height: { xs: '55dvh', md: '65dvh' },
                        p: 4,
                        fontFamily: 'Roboto Mono',
                        color: '#4a4a4a',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <Box
                        sx={{
                          textAlign: 'center',
                          maxWidth: 480,
                          width: '100%',
                        }}
                      >
                        You have no downline Agents who have created a Lead
                        Subscription or are receiving Leads from a Subscription.
                      </Box>
                    </Box>
                  ) : null}
                </Box>
              </Paper>
            </Stack>
          </Box>
        </Container>
      </Box>
    );
  }
}
