import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { ColumnHeader } from '../../../types';
import { toCurrency } from '../../../utils/formatter';
import { PurchaseData } from './data';

export function PurchasesRow(props: {
  row: PurchaseData;
  tableColumns: ColumnHeader<PurchaseData>[];
}) {
  // Props
  const { row, tableColumns } = props;

  return (
    <TableRow
      hover
      sx={{
        whiteSpace: 'nowrap',
        ':nth-of-type(odd)': {
          backgroundColor: '#fafafa',
        },
      }}
    >
      {tableColumns.map((column) => {
        const align = column.align || 'center';
        switch (column.key) {
          case 'ReceivedDate': {
            const value = row[column.key];
            return (
              <TableCell key={column.key} align={align}>
                <Box component="span" sx={{ fontFamily: 'Roboto Mono' }}>
                  {value.slice(0, 10)}
                </Box>
              </TableCell>
            );
          }
          case 'State': {
            return (
              <TableCell key={column.key} align={align}>
                <Box
                  component="span"
                  sx={{
                    fontFamily: 'Roboto Mono',
                    fontWeight: 'bold',
                    color: '#b26500',
                  }}
                >
                  {row[column.key]}
                </Box>
              </TableCell>
            );
          }
          case 'FinalTotal': {
            const value = row[column.key];
            return (
              <TableCell key={column.key} align={align}>
                <Box component="span" sx={{ fontFamily: 'Roboto Mono' }}>
                  {toCurrency({ value })}
                </Box>
              </TableCell>
            );
          }
          case 'BillToAgt': {
            return (
              <TableCell key={column.key} align={align}>
                <Box
                  component="span"
                  sx={{
                    fontFamily: 'Roboto Mono',
                    fontWeight: 'bold',
                    color: '#b26500',
                  }}
                >
                  {row[column.key]}
                </Box>
              </TableCell>
            );
          }
          case 'RecAgt': {
            const isBillToAgt = row.RecAgt === row.BillToAgt;
            return (
              <TableCell key={column.key} align={align}>
                <Box
                  component="span"
                  sx={{
                    fontFamily: 'Roboto Mono',
                    fontWeight: 'bold',
                    color: isBillToAgt ? '#b26500' : 'purple',
                  }}
                >
                  {row[column.key]}
                </Box>
              </TableCell>
            );
          }
          case 'LeadType': {
            return (
              <TableCell key={column.key} align={align}>
                <Box
                  component="span"
                  sx={{
                    fontFamily: 'Roboto Mono',
                    fontWeight: 'bold',
                    color: 'teal',
                  }}
                >
                  {row[column.key]}
                </Box>
              </TableCell>
            );
          }
          default: {
            const value = row[column.key];
            return (
              <TableCell key={column.key} align={align}>
                {Array.isArray(value) ? null : value}
              </TableCell>
            );
          }
        }
      })}
    </TableRow>
  );
}
