import { orderBy } from 'lodash';
import { LEAD_PRODUCT, LEAD_TYPE } from '../../types';
import { sumArray } from '../../utils/sumarray';

export interface OrderCartData {
  CartId: number;
  BillToAgt: string;
  ComplDate: string | null;
  FinalTotal: number;
  TotalLeads: number;
  TotalCounties: number;
  AcctNumber: string | null;
  AcctType: string | null;
}

export interface OrderDetailsData {
  CartId: number;
  RecAgt: string;
  FIPS: string;
  St: string;
  County: string;
  Product: LEAD_PRODUCT;
  LeadType: LEAD_TYPE;
  TotalPrice: number;
  TotalLeads: number;
  Exclusive: boolean;
}

export const getCartItemID = (params: { data: OrderDetailsData }) => {
  const { RecAgt, FIPS, LeadType, Product } = params.data;
  return `${RecAgt}-${FIPS}-${LeadType}-${Product}`;
};

export const getDetailsFIPS = (params: {
  data: OrderDetailsData[];
  FIPS: string;
}) => {
  let quantity: number | null = null;
  let spend: number | null = null;

  // Purchases for this FIPS
  const purchases: OrderDetailsData[] = [];

  const quantityValues: number[] = [];
  const spendValues: number[] = [];

  // Order the data
  const orderedData = orderBy(params.data, ['Product', 'LeadType']);

  orderedData.forEach((item) => {
    if (item.FIPS === params.FIPS) {
      purchases.push(item);

      quantityValues.push(item.TotalLeads);

      spendValues.push(item.TotalPrice);
    }
  });

  if (quantityValues.length) {
    quantity = sumArray(quantityValues);
  }

  if (spendValues.length) {
    spend = sumArray(spendValues);
  }

  return { quantity, spend, purchases };
};
