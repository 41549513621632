import ClearIcon from '@mui/icons-material/Clear';
import PersonIcon from '@mui/icons-material/Person';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import SearchIcon from '@mui/icons-material/Search';
import Autocomplete, {
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
} from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import TextField, { BaseTextFieldProps } from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useMutation } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import React from 'react';
import { AgentSelect } from '../types';
import { captureError } from '../utils/capture-error';

// Remove whitespace from the beginning of a string
function ltrim(str: string) {
  if (!str) return str;
  return str.replace(/^\s+/g, '');
}

export function SelectAgent(props: {
  onSelectAgent: (agent: AgentSelect | null) => void;
  autoFocus?: BaseTextFieldProps['autoFocus'];
  label?: BaseTextFieldProps['label'];
  searchAll?: boolean;
}) {
  // State
  const [impersonatedAgent, setImpersonatedAgent] =
    React.useState<AgentSelect | null>(null);
  const [searching, setSearching] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<readonly AgentSelect[]>([]);
  const [inputValue, setInputValue] = React.useState('');
  const [noOptionsText, setNoOptionsText] = React.useState(
    'Enter 3 or more characters and click the Search icon',
  );

  const handleStopImersonating = async () => {
    setSearching(true);
    setImpersonatedAgent(null);
    props.onSelectAgent(null);
  };

  const handleNewSearch = () => {
    setSearching(true);
    setOpen(true);
  };

  const handleNewSearchCancel = () => {
    setSearching(false);
  };

  // Mutation - search for Agents
  const mutation = useMutation({
    mutationFn: async () => {
      let path = '/agent/search';
      // Search all agents and not just downline
      if (props.searchAll) {
        path = '/agent/search/all';
      }
      const response: {
        data: AgentSelect[];
      } = await API.post('ContractingAPI', path, {
        body: { search: inputValue },
      });

      if (response.data) {
        return response.data;
      }

      return [];
    },
    onSuccess: async (data) => {
      if (data.length === 0) {
        setNoOptionsText('No Agents found matching value');
      } else if (data.length) {
        setOptions(data);
        setInputValue('');
      }

      setOpen(true);
    },
    onError: (error) => captureError({ data: { error } }),
  });

  const handleInputChange = (
    event: React.ChangeEvent<{}>,
    value: string,
    reason: AutocompleteInputChangeReason,
  ) => {
    const newValue = ltrim(value);
    if (reason === 'input') {
      setInputValue(newValue);
    }
  };

  const handleChange = async (
    event: React.ChangeEvent<{}>,
    value: AgentSelect | null,
    reason: AutocompleteChangeReason,
  ) => {
    if (reason === 'selectOption') {
      if (value !== null) {
        setSearching(false);
      }
      setImpersonatedAgent(value);
      props.onSelectAgent(value);
    }
  };

  const submitDisabled =
    mutation.isPending || inputValue.length < 3 || inputValue.length > 50;

  const searchInput = (
    <Autocomplete
      fullWidth
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => {
        setOpen(false);

        // Reset no matching agents text on close
        if (noOptionsText === 'No Agents found matching value') {
          setNoOptionsText(
            'Enter 3 or more characters and click the Search icon',
          );
        }
      }}
      onKeyDown={(event) => {
        if (event.key === 'Enter' && !submitDisabled) {
          mutation.mutate();
        }
      }}
      getOptionLabel={(option) => `${option.AgtName} (${option.AgtNo})`}
      filterOptions={(x) => x}
      noOptionsText={noOptionsText}
      options={options}
      loading={mutation.isPending}
      onInputChange={handleInputChange}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField
          {...params}
          autoFocus={props.autoFocus || false}
          size="small"
          type="search"
          label={props.label}
          placeholder="Search agents"
          sx={{
            '.MuiOutlinedInput-root': {
              backgroundColor: '#fff',
            },
          }}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start" sx={{ pl: 0.8 }}>
                <PersonSearchIcon fontSize="small" />
              </InputAdornment>
            ),
            endAdornment: (
              <React.Fragment>
                {mutation.isPending ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}

                <IconButton
                  disabled={submitDisabled}
                  size="small"
                  sx={{
                    backgroundColor: '#fff',
                    border: '1px solid #c1c1c1',
                    borderRadius: 2,
                  }}
                  onClick={() => mutation.mutate()}
                >
                  <SearchIcon fontSize="small" />
                </IconButton>

                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );

  if (impersonatedAgent !== null && searching) {
    return (
      <Stack spacing={1} direction="row" alignItems="center">
        {searchInput}

        <Tooltip title="Cancel" placement="bottom" arrow>
          <IconButton
            sx={{
              ml: 0.6,
              backgroundColor: '#fff',
              border: '1px solid #c1c1c1',
              borderRadius: 2,
            }}
            onClick={handleNewSearchCancel}
          >
            <ClearIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Stack>
    );
  } else if (impersonatedAgent !== null) {
    return (
      <Stack spacing={1} direction="row" alignItems="center">
        <Tooltip title="New search" placement="bottom" arrow>
          <ButtonBase
            sx={{
              flex: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              backgroundColor: '#ffffff77',
              border: '1px solid #ccc',
              borderRadius: 1,
              minHeight: 40,
              transition: 'all 0.2s',
              ':hover': {
                backgroundColor: '#fff',
              },
            }}
            onClick={handleNewSearch}
          >
            <Box sx={{ px: 1.2, display: 'flex', alignItems: 'center' }}>
              <PersonIcon fontSize="small" sx={{ color: '#7a7a7a' }} />
            </Box>
            <Typography noWrap sx={{ width: 175, textAlign: 'left' }}>
              {impersonatedAgent.Fname} {impersonatedAgent.Lname}
            </Typography>
          </ButtonBase>
        </Tooltip>

        <Tooltip title="Clear" placement="bottom" arrow>
          <IconButton
            sx={{
              ml: 0.6,
              backgroundColor: '#fff',
              border: '1px solid #c1c1c1',
              borderRadius: 2,
            }}
            onClick={handleStopImersonating}
          >
            <PersonOffIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Stack>
    );
  } else {
    return searchInput;
  }
}
