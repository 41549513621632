/**
 * Cleans a given string by removing any non-alphanumeric characters and converting
 * all remaining characters to uppercase.
 *
 * "coupon code" -> "COUPONCODE"
 * "April 2024"  -> "APRIL2024"
 *
 * @param input - The string to be cleaned.
 * @returns The cleaned and formatted string.
 */
export function cleanString(input: string): string {
  // Remove non-alphanumeric characters and convert to uppercase
  return input.replace(/[^a-z0-9]/gi, '').toUpperCase();
}
