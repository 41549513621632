import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import ErrorIcon from '@mui/icons-material/Error';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Radio from '@mui/material/Radio';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { CustomerPaymentData } from '../../types';
import { cardExpired } from '../../utils/card-expired';
import { CardIcon } from '../card-icon';

export function PaymentCardItem(props: {
  item: CustomerPaymentData;
  isChecked: boolean;
  isDefault: boolean;
  isAdmin: boolean;
  onSelect: () => void;
  onUpdate: () => void;
}) {
  // Props
  const { item, isChecked, isDefault } = props;

  const isExpired = cardExpired({ expirationDate: item.expirationDate });

  return (
    <Box>
      <Stack direction="row" alignItems="center">
        <Box>
          <Radio
            color="info"
            size="medium"
            checked={isChecked}
            value={item.customerPaymentProfileId}
            onChange={props.onSelect}
          />
        </Box>

        <Box sx={{ flex: 1 }}>
          <Box
            key={item.customerPaymentProfileId}
            sx={{
              width: '100%',
              position: 'relative',
              border: '1px solid #4a4a4a88',
              display: 'flex',
              borderRadius: 2,
              overflow: 'hidden',
              backgroundColor: '#fefefe',
              borderWidth: 2,
              borderStyle: 'solid',
              borderColor: isChecked ? '#2196f399' : '#03182E22',
              transition: 'all 0.2s',
            }}
          >
            {props.isAdmin ? null : (
              <div
                style={{
                  position: 'absolute',
                  top: 5,
                  right: 5,
                }}
              >
                <Tooltip title="Update card" placement="left" arrow>
                  <IconButton onClick={props.onUpdate}>
                    <EditIcon color="primary" />
                  </IconButton>
                </Tooltip>
              </div>
            )}

            <Box
              onClick={props.onSelect}
              sx={{
                flex: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: `linear-gradient(to bottom, #2196f344, #ffff0022)`,
                borderRight: '1px solid #00000011',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <CardIcon cardType={item.cardType || ''} size="3x" />

                {isDefault ? (
                  <Chip
                    label="Default"
                    variant="outlined"
                    color="info"
                    icon={<CheckCircleIcon color="info" />}
                    style={{
                      fontWeight: 'bold',
                      marginBottom: 4,
                    }}
                  />
                ) : null}

                {isExpired ? (
                  <Chip
                    label="Expired"
                    variant="outlined"
                    color="error"
                    size="small"
                    icon={<ErrorIcon />}
                    style={{ fontWeight: 'bold' }}
                  />
                ) : null}
              </div>
            </Box>

            <div
              onClick={props.onSelect}
              style={{
                flex: 2,
                display: 'flex',
                alignItems: 'center',
                padding: 4,
                paddingLeft: 8,
              }}
            >
              <Stack sx={{ fontSize: 13 }}>
                <strong style={{ fontSize: 14 }}>
                  {item.cardType?.toUpperCase()}
                </strong>
                <Box>
                  {item.billToFirstName} {item.billToLastName}
                </Box>
                <div>
                  <span style={{ fontFamily: 'Roboto Mono' }}>
                    **** **** ****{' '}
                    <Box component="strong" sx={{ fontSize: 14 }}>
                      {item.cardNumber?.replaceAll('X', '')}
                    </Box>
                  </span>
                </div>
                <div>
                  <span style={{ fontFamily: 'Roboto Mono' }}>
                    EXP: {item.expirationDate}
                  </span>
                </div>
              </Stack>
            </div>
          </Box>
        </Box>
      </Stack>
    </Box>
  );
}
