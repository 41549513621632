import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { useQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import { format } from 'date-fns';
import { orderBy } from 'lodash';
import React from 'react';
import { RouteContainer } from '../../components/route-container';
import { AuthContext } from '../../contexts/auth-context';
import { usePermissions } from '../../hooks/use-permissions';
import { AWS_GROUP } from '../../types';
import { isAgt } from '../../utils/is-agent';
import { InvoiceItem } from './invoice-item';

export interface InvoiceData {
  InvId: number;
  AgtNo: string;
  AR: string;
  ARName: string;
  WeekEndDate: string;
  CreateDate: string;
}

export function WeeklyInvoices() {
  // Context
  const {
    state: { impersonatedAgent, user },
  } = React.useContext(AuthContext);
  const AgtNo = impersonatedAgent?.AgtNo || user?.getUsername();
  // State
  const [selected, setSelected] = React.useState<number>();
  // Hooks
  const { hasGroup } = usePermissions();

  // Query - Weekly Invoices
  const path = '/leads/weekly-invoices';
  const query = useQuery({
    queryKey: [path, { AgtNo }],
    queryFn: async () => {
      if (isAgt(AgtNo)) {
        const response: {
          data: InvoiceData[];
        } = await API.post('LeadsAPI', path, { body: { AgtNo } });
        return response.data;
      }
      return [];
    },
  });

  const invoices = orderBy(query.data || [], 'WeekEndDate', 'desc');

  return (
    <React.Fragment>
      <RouteContainer routeTitle="Weekly Invoices" loading={query.isLoading}>
        <Container maxWidth="sm">
          <Box sx={{ pt: 2, pb: 16 }}>
            <Stack spacing={1}>
              {!isAgt(AgtNo) &&
              hasGroup(AWS_GROUP.LDS_GlobalAdmin) &&
              invoices.length === 0 ? (
                <Box
                  sx={{
                    p: 4,
                    textAlign: 'center',
                    backgroundColor: '#f5f5f5',
                    border: '1px solid #cacaca',
                    borderRadius: 2,
                  }}
                >
                  <Box sx={{ fontFamily: 'Roboto Mono' }}>
                    Impersonate an Agent to view their Invoices
                  </Box>
                </Box>
              ) : invoices.length === 0 ? (
                <Box
                  sx={{
                    p: 4,
                    textAlign: 'center',
                    backgroundColor: '#f5f5f5',
                    border: '1px solid #cacaca',
                    borderRadius: 2,
                  }}
                >
                  <Box sx={{ fontFamily: 'Roboto Mono' }}>
                    No invoices found
                  </Box>
                </Box>
              ) : null}

              {invoices.map((item) => {
                return (
                  <Box
                    key={item.InvId}
                    component="button"
                    sx={{
                      color: '#000',
                      textAlign: 'left',
                      borderWidth: 2,
                      borderStyle: 'solid',
                      borderColor: '#eaeaea',
                      borderRadius: 1,
                      backgroundColor: '#fff',
                      cursor: 'pointer',
                      transition: 'all 0.2s',
                      ':hover': {
                        transform: 'scale(1.01)',
                        borderColor: '#aeaeae',
                      },
                    }}
                    onClick={() => setSelected(item.InvId)}
                  >
                    <Stack direction="row" alignItems="center" sx={{ p: 0.5 }}>
                      <Box
                        component="small"
                        sx={{
                          fontSize: 22,
                          fontFamily: 'Roboto Mono',
                          fontStyle: 'italic',
                        }}
                      >
                        #{item.InvId}
                      </Box>

                      <Box flex={1} alignItems="center" px={2}>
                        <Divider />
                      </Box>
                      <Box sx={{ textAlign: 'right' }}>
                        <Box
                          sx={{
                            fontSize: 18,
                            fontFamily: 'Roboto Mono',
                            color: 'green',
                          }}
                        >
                          {item.ARName}
                        </Box>

                        <Box
                          sx={{
                            fontSize: 16,
                            fontFamily: 'Roboto Mono',
                          }}
                        >
                          {format(
                            new Date(item.WeekEndDate + 'T00:00'),
                            'yyyy-MM-dd',
                          )}
                        </Box>
                      </Box>
                    </Stack>
                  </Box>
                );
              })}
            </Stack>
          </Box>
        </Container>
      </RouteContainer>
      <InvoiceItem
        item={query.data?.find((i) => i.InvId === selected)}
        onClose={() => setSelected(undefined)}
      />
    </React.Fragment>
  );
}
