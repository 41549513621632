import { TableCellProps } from '@mui/material/TableCell';

export interface ColumnHeader<T> {
  key: keyof T;
  label?: string;
  align?: TableCellProps['align'];
}

export interface TableState {
  page: number;
  rowsPerPage: number;
  sort: 'asc' | 'desc';
  sortBy: string;
}

export enum AWS_GROUP {
  ARC_ABTF = 'ARC_ABTF',
  ARC_Accounting = 'ARC_Accounting',
  ARC_Commissions = 'ARC_Commissions',
  ARC_GlobalAdmin = 'ARC_GlobalAdmin',
  ARC_ImpersonateAll = 'ARC_ImpersonateAll',
  ARC_LPT = 'ARC_LPT',
  ARC_Marketing = 'ARC_Marketing',
  ARC_PresidentsClub = 'ARC_PresidentsClub',
  AllianceEvents_Admin = 'AllianceEvents_Admin',
  AllianceEvents_CheckIn = 'AllianceEvents_CheckIn',
  ARC_BillingSystem = 'ARC_BillingSystem',
  CTR_ImpersonateAll = 'CTR_ImpersonateAll',
  CTR_AgtSpecImpersonation = 'CTR_AgtSpecImpersonation',
  CTR_GlobalAdmin = 'CTR_GlobalAdmin',
  CTR_AgentAdmin = 'CTR_AgentAdmin',
  LDS_KeyingMgr = 'LDS_KeyingMgr',
  LDS_ImpersonateAll = 'LDS_ImpersonateAll',
  LDS_GlobalAdmin = 'LDS_GlobalAdmin',
}

export interface AgentSelect {
  AgtNo: string | null;
  Fname: string | null;
  Mname: string | null;
  Lname: string | null;
  AgtName: string | null;
  CellPh: string | null;
  Email: string | null;
  HomeAdd: string | null;
  HomeCity: string | null;
  HomeSt: string | null;
  HomeZip: string | null;
  AgtStatus: string | null;
  IMOName?: string | null;
  LastAccessDate?: string | null;
  RegVerified?: '0' | '1' | null;
  ContAuth: '0' | '1' | null;
  HasSignedICA?: '0' | '1' | null;
  HasNIPRData?: '0' | '1' | null;
}

export interface AuthorizeIMOData {
  IMOName: string | null;
  IMODisplayName: string | null;
  IMOAgtNo: string | null;
  IMOAcronym: string | null;
  Logo: string | null;
  Address: string | null;
  Phone: string | null;
  Status: string | null;
  AgtNoFormat: null;
  BillingContact: null;
}

export interface AuthorizeStaffData {
  Username: string | null;
  Fname: string | null;
  Lname: string | null;
  IMOName: string | null;
  Active: string | null;
  LastAccessDate: string | null;
}

export interface AuthorizeData {
  IMO: AuthorizeIMOData;
  Agent: AgentSelect | null;
  Staff: AuthorizeStaffData | null;
}

export enum LEAD_STATUS {
  'New' = 'New',
  'No Answer' = 'No Answer',
  "Didn't Book" = "Didn't Book",
  'Booked' = 'Booked',
  'Call Back' = 'Call Back',
  'Door Knock' = 'Door Knock',
  'No Sale' = 'No Sale',
  'Sold Policy' = 'Sold Policy',
}

export enum LEAD_PRODUCT {
  'FEX' = 'FEX',
  'MP' = 'MP',
  'ANN' = 'ANN',
}

export enum LEAD_TYPE {
  'A' = 'A',
  'B' = 'B',
  'C' = 'C',
  'D' = 'D',
  'E' = 'E',
  'F' = 'F',
  'G' = 'G',
}

export enum LEAD_DIST_SRC {
  'Instant Purchase' = 'Instant Purchase',
  'Subscription' = 'Subscription',
}

export interface LeadData {
  DistHistID: number;
  LeadID: number;
  FIPS: string | null;
  ContactStatus: LEAD_STATUS;
  Product: LEAD_PRODUCT;
  LeadType: LEAD_TYPE;
  BillToAgt: string | null;
  RecAgt: string | null;
  RecDate: string | null;
  DistStatus: string | null;
  Exclusive: boolean | null;
  DistFilePath: string | null;
  ExpireDate: string | null;
  BillProc: boolean | null;
  CRMProc: boolean | null;
  AgtExport: boolean | null;
  FirstName: string | null;
  LastName: string | null;
  Address1: string | null;
  Address2: string | null;
  City: string | null;
  St: string | null;
  Zip: string | null;
  County: string | null;
  Phone: string | null;
  Email: string | null;
  DOB: string | null;
  Age: number | null;
  SpouseName: string | null;
  SpouseAge: number | null;
  Latitude: number | string | null;
  Longitude: number | string | null;
  FilePath: string | null;
  MortgageDate: string | null;
  MortgageAmount: string | null;
  Lender: string | null;
  paddedLeadID: string | null;
  DistSrc: LEAD_DIST_SRC;
}

export interface LeadMultiplierData {
  FIPS: string;
  MltFEX: number;
  MltMP: number;
}

export interface InventoryData {
  State: string | null;
  County: string | null;
  FIPS: string | null;
  Region: string | null;
  A: string | number | null;
  B: string | number | null;
  C: string | number | null;
  D: string | number | null;
  E: string | number | null;
  F: string | number | null;
  G: string | number | null;
}

export interface FeaturePropertiesData {
  GEO_ID: string | null;
  STATE: string | null;
  COUNTY?: string | null;
  NAME: string | null;
  LSAD: string | null;
  CENSUSAREA: number | null;
}

type Coordinate = [number, number];

export interface PolygonData {
  type: 'Polygon';
  coordinates: Coordinate[][];
}

export interface MultiPolygonData {
  type: 'MultiPolygon';
  coordinates: Coordinate[][][];
}

export interface FeatureData {
  type: 'Feature';
  properties: FeaturePropertiesData;
  geometry: PolygonData | MultiPolygonData;
}

export interface FeatureCollectionData {
  type: 'FeatureCollection';
  features: FeatureData[];
}

export interface CartLeadData extends InventoryData {
  Type: 'LIF' | 'FEX';
}

export interface CartSubscriptionData extends InventoryData {
  Type: 'LIF' | 'FEX';
}

export enum PYMNT_DFLT {
  'EVT' = 'EVT',
  'LDS' = 'LDS',
}

export interface CustomerPaymentData {
  customerPaymentProfileId: string | null;
  cardNumber: string | null;
  expirationDate: string | null;
  cardType: string | null;
  billToPhone: string | null;
  billToEmail: string | null;
  billToFirstName: string | null;
  billToLastName: string | null;
  billToCompany: string | null;
  billToAddress: string | null;
  billToCity: string | null;
  billToState: string | null;
  billToZip: string | null;
  billToCountry: string | null;
  defaultFor: PYMNT_DFLT[] | null;
}

export interface CustomerData {
  merchantCustomerId: string | null;
  customerProfileId: string | null;
  description: string | null;
  email: string | null;
  username: string | null;
  customerPaymentProfiles: CustomerPaymentData[];
  profileType: string | null;
}
