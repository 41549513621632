import ClearIcon from '@mui/icons-material/Clear';
import DiscountIcon from '@mui/icons-material/Discount';
import PlusOneIcon from '@mui/icons-material/PlusOne';
import RemoveIcon from '@mui/icons-material/Remove';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import React from 'react';
import { AuthContext } from '../../contexts/auth-context';
import { CartContext } from '../../contexts/cart-context';
import { LEAD_PRODUCT } from '../../types';
import { captureError } from '../../utils/capture-error';
import { toCurrency } from '../../utils/formatter';
import { getProductColor, getProductTitle } from '../../utils/general';
import { multNums } from '../../utils/mult-nums';
import { CartData, CartItemData } from './data';
import { getDetailsOne } from './purchase-utils';

const getCartUpdate = (params: {
  item: CartItemData;
  BillToAgt: string | undefined;
}) => {
  // Params
  const { item, BillToAgt } = params;

  if (BillToAgt) {
    return {
      BillToAgt,
      RecAgt: item.RecAgt,
      FIPS: item.FIPS,
      Product: item.Product,
      LeadType: item.LeadType,
      FIPSRegId: item.FIPSRegId,
      Quantity: item.Quantity,
      Exclusive: item.Exclusive,
    };
  } else {
    return null;
  }
};

export function CartItem(props: { item: CartItemData }) {
  // Props
  const { item } = props;
  // Context
  const {
    state: { cart },
    dispatch,
  } = React.useContext(CartContext);
  const {
    state: { user },
  } = React.useContext(AuthContext);

  const queryClient = useQueryClient();

  const BillToAgt = cart?.BillToAgt || undefined;
  const AgtNo = BillToAgt;
  const FIPS = item.FIPS;

  const details = getDetailsOne({ data: item });

  const cannotIncrease = item.Quantity + 1 > item.AvailableLeads;
  const cannotDecrease = item.Quantity - 1 === 0;

  const pathUpdate = '/leads/instant-purchase/cart/update';

  // Mutation - Increase by One Lead
  const mutationIncrease = useMutation({
    mutationFn: async () => {
      const Quantity = item.Quantity + 1;
      const newItem = { ...item, Quantity };
      const update = getCartUpdate({ item: newItem, BillToAgt });
      if (update) {
        const response: {
          data: CartData;
        } = await API.post('LeadsAPI', pathUpdate, {
          body: { ...update },
        });

        // Add Cart from Response to Context
        dispatch({ type: 'CART_SET_LEADS', payload: response.data });
      }
    },
    onSuccess: async () => {
      // Refetch county info to reflect any Leads added to cart
      // Spefically if the Agent has added Leads for another Agent
      // the amount of Leads available to the current Agent will have been reduced
      await queryClient.invalidateQueries({
        queryKey: ['/leads/instant-purchase/county-info', { FIPS, AgtNo }],
      });
    },
    onError: (error) => captureError({ data: { error } }),
  });

  // Mutation - Decrease by One Lead
  const mutationDecrease = useMutation({
    mutationFn: async () => {
      const Quantity = item.Quantity - 1;
      const newItem = { ...item, Quantity };
      const update = getCartUpdate({ item: newItem, BillToAgt });
      if (update) {
        const response: {
          data: CartData;
        } = await API.post('LeadsAPI', pathUpdate, {
          body: { ...update },
        });

        // Add Cart from Response to Context
        dispatch({ type: 'CART_SET_LEADS', payload: response.data });
      }
    },
    onSuccess: async () => {
      // Refetch county info to reflect any Leads added to cart
      // Spefically if the Agent has added Leads for another Agent
      // the amount of Leads available to the current Agent will have been reduced
      await queryClient.invalidateQueries({
        queryKey: ['/leads/instant-purchase/county-info', { FIPS, AgtNo }],
      });
    },
    onError: (error) => captureError({ data: { error } }),
  });

  // Mutation - Remove this Item from the Cart
  const mutationRemove = useMutation({
    mutationFn: async () => {
      const Quantity = 0;
      const newItem = { ...item, Quantity };
      const update = getCartUpdate({ item: newItem, BillToAgt });
      if (update) {
        const response: {
          data: CartData;
        } = await API.post('LeadsAPI', pathUpdate, {
          body: { ...update },
        });

        // Add Cart from Response to Context
        dispatch({ type: 'CART_SET_LEADS', payload: response.data });
      }
    },
    onSuccess: async () => {
      // Refetch county info to reflect any Leads added to cart
      // Spefically if the Agent has added Leads for another Agent
      // the amount of Leads available to the current Agent will have been reduced
      await queryClient.invalidateQueries({
        queryKey: ['/leads/instant-purchase/county-info', { FIPS, AgtNo }],
      });
    },
    onError: (error) => captureError({ data: { error } }),
  });

  const isSelf = item.RecAgt === user?.getUsername();

  const isPending =
    mutationDecrease.isPending ||
    mutationIncrease.isPending ||
    mutationRemove.isPending;

  return (
    <Paper
      elevation={0}
      sx={{ border: '1px solid #00000011', overflow: 'hidden' }}
    >
      <Box sx={{ p: 1 }}>
        <Stack spacing={{ xs: 0.5, md: 1 }} direction="row" alignItems="center">
          <Stack direction="column" alignItems="center">
            <Box
              sx={{
                fontFamily: 'Roboto Mono',
                fontWeight: 'bold',
                fontSize: 24,
                color: 'teal',
              }}
            >
              {item.LeadType}
            </Box>

            {item.Discount ? (
              <Tooltip
                title={`${multNums(item.Discount, 100)}% off this purchase`}
                placement="right"
                arrow
              >
                <DiscountIcon fontSize="small" color="info" />
              </Tooltip>
            ) : null}
          </Stack>

          <Stack>
            <Box
              sx={{
                display: { xs: 'none', md: 'block' },
                fontWeight: 'bold',
                color: getProductColor(item.Product as LEAD_PRODUCT),
              }}
            >
              {getProductTitle(item.Product as LEAD_PRODUCT)}
            </Box>

            <Box
              sx={{
                display: { xs: 'block', md: 'none' },
                fontWeight: 'bold',
                color: getProductColor(item.Product as LEAD_PRODUCT),
              }}
            >
              {item.Product}
            </Box>

            {item.Exclusive ? (
              <Box
                sx={{
                  textTransform: 'uppercase',
                  fontWeight: 'bold',
                  fontSize: 12,
                  color: 'darkorange',
                }}
              >
                Exclusive
              </Box>
            ) : null}

            {item.RecAgt ? (
              <Stack
                spacing={{ xs: 0, md: 1 }}
                direction={{ xs: 'column', md: 'row' }}
                alignItems={{ xs: 'flex-start', md: 'center' }}
              >
                <Box sx={{ fontSize: 14 }}>{item.AgtName}</Box>

                <Box
                  sx={{
                    fontFamily: 'Roboto Mono',
                    fontWeight: 'bold',
                    fontSize: 12,
                    color: isSelf ? '#b26500' : 'purple',
                  }}
                >
                  {item.RecAgt}
                </Box>
              </Stack>
            ) : null}

            {item.FIPSRegId ? (
              <Box>
                Region: <Box component="strong">{item.Region}</Box>
              </Box>
            ) : null}
          </Stack>

          <Box sx={{ flex: 1, borderTop: '2px solid #f5f5f5' }} />

          <Box
            sx={{
              fontFamily: 'Roboto Mono',
              fontWeight: 'bold',
              fontSize: { xs: 18, md: 24 },
            }}
          >
            {details.quantity}
          </Box>

          <Box sx={{ width: 8, borderTop: '2px solid #f5f5f5' }} />

          <Stack direction="column" alignItems="center">
            {details.originalSpend &&
            details.originalSpend !== details.spend ? (
              <Box
                sx={{
                  pr: 1,
                  fontFamily: 'Roboto Mono',
                  fontSize: { xs: 10, md: 12 },
                  opacity: 0.6,
                  textDecorationLine: 'line-through',
                }}
              >
                {toCurrency({ value: details.originalSpend })}
              </Box>
            ) : null}

            <Box
              sx={{
                pr: 1,
                fontFamily: 'Roboto Mono',
                fontSize: { xs: 12, md: 14 },
              }}
            >
              {toCurrency({ value: details.spend })}
            </Box>
          </Stack>

          <Box sx={{ width: 8, borderTop: '2px solid #f5f5f5' }} />

          <Stack
            spacing={1}
            direction={{ xs: 'column-reverse', md: 'row' }}
            alignItems="center"
          >
            <Tooltip title="Remove One Lead" placement="top" arrow>
              <span>
                <IconButton
                  disabled={isPending || cannotDecrease}
                  size="small"
                  onClick={() => mutationDecrease.mutate()}
                >
                  {mutationDecrease.isPending ? (
                    <CircularProgress size={20} color="warning" />
                  ) : (
                    <RemoveIcon
                      fontSize="small"
                      color={cannotDecrease ? 'disabled' : 'warning'}
                    />
                  )}
                </IconButton>
              </span>
            </Tooltip>

            <Tooltip
              title={`Add One Lead for ${toCurrency({
                value: item.LeadPrice,
              })}`}
              placement="top"
              arrow
            >
              <span>
                <IconButton
                  disabled={isPending || cannotIncrease}
                  size="small"
                  onClick={() => mutationIncrease.mutate()}
                >
                  {mutationIncrease.isPending ? (
                    <CircularProgress size={20} color="success" />
                  ) : (
                    <PlusOneIcon
                      fontSize="small"
                      color={cannotIncrease ? 'disabled' : 'success'}
                    />
                  )}
                </IconButton>
              </span>
            </Tooltip>
          </Stack>

          <Tooltip title="Remove these Leads from Cart" placement="top" arrow>
            <span>
              <IconButton
                disabled={isPending}
                size="small"
                onClick={() => mutationRemove.mutate()}
              >
                {mutationRemove.isPending ? (
                  <CircularProgress size={20} color="error" />
                ) : (
                  <ClearIcon fontSize="small" color="error" />
                )}
              </IconButton>
            </span>
          </Tooltip>
        </Stack>
      </Box>
    </Paper>
  );
}
