import { orderBy, uniq } from 'lodash';
import { multNums } from '../../utils/mult-nums';
import { sumArray } from '../../utils/sumarray';
import { CartItemData } from './data';

export const getCartItemID = (params: { data: CartItemData }) => {
  const { RecAgt, FIPS, FIPSRegId, LeadType, Product } = params.data;
  return `${RecAgt}-${FIPS}-${FIPSRegId || '_'}-${LeadType}-${Product}`;
};

export const getCartItemUpdate = (params: {
  item: CartItemData;
  BillToAgt: string | undefined | null;
}) => {
  // Params
  const { item, BillToAgt } = params;

  if (BillToAgt) {
    return {
      BillToAgt,
      RecAgt: item.RecAgt,
      FIPS: item.FIPS,
      Product: item.Product,
      LeadType: item.LeadType,
      FIPSRegId: item.FIPSRegId,
      Quantity: item.Quantity,
      Exclusive: item.Exclusive,
    };
  } else {
    return null;
  }
};

export const getAllFIPS = (params: { data: CartItemData[] }) => {
  // Get the Fips for each Cart Item
  const allFIPS = params.data.map((i) => i.FIPS);
  // Get all Unique Fips
  const uniqFIPS = uniq(allFIPS);
  // Return the Unique FIPS ordered
  return orderBy(uniqFIPS);
};

export const getDetailsAll = (params: { data: CartItemData[] }) => {
  let quantity: number | null = null;
  let spend: number | null = null;

  const quantityValues: number[] = [];
  const spendValues: number[] = [];

  // Order the data
  const orderedData = orderBy(params.data, ['Product', 'LeadType']);

  orderedData.forEach((item) => {
    quantityValues.push(item.Quantity);

    // Calculate how much they are spending on this CartItem
    spendValues.push(multNums(item.Quantity, item.LeadPrice));
  });

  if (quantityValues.length) {
    quantity = sumArray(quantityValues);
  }

  if (spendValues.length) {
    spend = sumArray(spendValues);
  }

  return { quantity, spend };
};

export const getDetailsOne = (params: { data: CartItemData }) => {
  const quantity: number | null = params.data.Quantity || null;
  const spend: number | null =
    multNums(params.data.Quantity, params.data.LeadPrice) || null;
  let originalSpend: number | null = null;
  if (params.data.OriginalPrice) {
    originalSpend = multNums(params.data.Quantity, params.data.OriginalPrice);
  }

  return { quantity, spend, originalSpend };
};

export const getDetailsFIPS = (params: {
  data: CartItemData[];
  FIPS: string;
}) => {
  let quantity: number | null = null;
  let spend: number | null = null;

  // Purchases for this FIPS
  const purchases: CartItemData[] = [];

  const quantityValues: number[] = [];
  const spendValues: number[] = [];

  // Order the data
  const orderedData = orderBy(params.data, ['Product', 'LeadType']);

  orderedData.forEach((item) => {
    if (item.FIPS === params.FIPS) {
      purchases.push(item);

      quantityValues.push(item.Quantity);

      // Calculate how much they are spending on this CartItem
      spendValues.push(multNums(item.Quantity, item.LeadPrice));
    }
  });

  if (quantityValues.length) {
    quantity = sumArray(quantityValues);
  }

  if (spendValues.length) {
    spend = sumArray(spendValues);
  }

  return { quantity, spend, purchases };
};
