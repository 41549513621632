import CloseIcon from '@mui/icons-material/Close';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LibraryAdd from '@mui/icons-material/LibraryAdd';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip, { ChipOwnProps } from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import { CartContext } from '../../contexts/cart-context';
import { CountyFeatureData } from '../../data/county-features';
import { LEAD_PRODUCT } from '../../types';
import { toCurrency } from '../../utils/formatter';
import { getProductColor, getProductTitle } from '../../utils/general';
import { LeadMultiplierData } from './data';
import { SubscriptionAdd } from './subscription-add';

function PricingIndicator(props: {
  Product: LEAD_PRODUCT;
  Multiplier: number;
}) {
  let color: ChipOwnProps['color'] = 'default';
  let icon: ChipOwnProps['icon'] = <TrendingFlatIcon fontSize="small" />;
  if (props.Multiplier > 1) {
    color = 'error';
    icon = <TrendingUpIcon fontSize="small" />;
  } else if (props.Multiplier < 1) {
    color = 'success';
    icon = <TrendingDownIcon fontSize="small" />;
  }

  return (
    <Chip
      size="small"
      variant="outlined"
      color={color}
      icon={icon}
      label={props.Product}
      sx={{ fontWeight: 'bold' }}
    />
  );
}

export function CountyListItem(props: {
  feature: google.maps.Data.Feature;
  filters: string[];
  county: CountyFeatureData | null;
  leadMult: LeadMultiplierData | undefined;
  onUnselect: () => void;
}) {
  // Props
  const { county } = props;
  // Context
  const { state, dispatch } = React.useContext(CartContext);
  // State
  const [open, setOpen] = React.useState(true);
  const [adding, setAdding] = React.useState(false);

  // All subscriptions in Cart for this County
  const subsForCounty = state.subs.filter((i) => i.FIPS === county?.FIPS);
  const cartCountTotal = subsForCounty.length || 0;

  return (
    <React.Fragment>
      <Paper
        sx={{
          overflow: 'hidden',
          background: `linear-gradient(to top left, #00aaff22, #fffbf3)`,
        }}
      >
        <Stack
          spacing={1}
          direction="row"
          alignItems="center"
          sx={{
            p: 1,
            py: 0.5,
            flex: 1,
            background: `linear-gradient(to bottom right, #00aaff33, #fffbf3)`,
          }}
        >
          <Tooltip title="Remove County" placement="right" arrow>
            <IconButton
              size="small"
              sx={{
                borderRadius: 1.2,
                border: '1px solid #cacaca',
                backgroundColor: '#fff',
              }}
              onClick={() => {
                // Remove from Map
                props.onUnselect();
                // Remove from Cart
                dispatch({
                  type: 'CART_SUB_REMOVE_COUNTY',
                  payload: county?.FIPS || '',
                });
              }}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>

          <Box
            sx={{
              color: '#b26500',
              fontSize: { xs: 16, md: 22 },
              fontWeight: 'bold',
              fontFamily: 'Roboto Mono',
            }}
          >
            {county?.StateAbbr}
          </Box>

          <Box sx={{ fontSize: { xs: 18, md: 24 } }}>{county?.NAME}</Box>

          <Box sx={{ flex: 1 }} />

          {props.leadMult ? (
            <Stack
              spacing={1}
              direction="row"
              alignItems="center"
              sx={{ display: { xs: 'none', md: 'flex' } }}
            >
              <PricingIndicator
                Product={LEAD_PRODUCT['FEX']}
                Multiplier={props.leadMult.MltFEX}
              />

              <PricingIndicator
                Product={LEAD_PRODUCT['MP']}
                Multiplier={props.leadMult.MltMP}
              />
            </Stack>
          ) : null}

          <Chip
            disabled={cartCountTotal === 0}
            size="small"
            label={
              <Box sx={{ fontWeight: 'bold' }}>
                <Stack spacing={1} direction="row" alignItems="center">
                  <Box sx={{ fontFamily: 'Roboto Mono' }}>{cartCountTotal}</Box>
                  <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                    in Cart
                  </Box>
                </Stack>
              </Box>
            }
            variant="outlined"
            color="success"
            sx={{ backgroundColor: '#fff' }}
          />

          <Tooltip title={open ? 'Collapse' : 'Expand'} placement="left" arrow>
            <IconButton
              size="small"
              onClick={() => setOpen((currentState) => !currentState)}
              sx={{ backgroundColor: '#fff' }}
            >
              {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Tooltip>
        </Stack>

        {open ? (
          <React.Fragment>
            <Divider />

            <Stack spacing={1} sx={{ p: 1 }}>
              {subsForCounty.map((sub) => {
                return (
                  <Paper
                    key={sub.TempID}
                    elevation={0}
                    sx={{ border: '1px solid #00000022', overflow: 'hidden' }}
                  >
                    <Stack
                      spacing={1}
                      direction="row"
                      alignItems="center"
                      sx={{ p: 1 }}
                    >
                      <Box
                        sx={{
                          fontSize: 32,
                          fontWeight: 'bold',
                          fontFamily: 'Roboto Mono',
                          color: 'teal',
                        }}
                      >
                        {sub.LeadType}
                      </Box>

                      <Stack>
                        <Stack
                          sx={{
                            flexDirection: { xs: 'column', md: 'row' },
                            alignItems: { xs: 'flex-start', md: 'flex-end' },
                          }}
                        >
                          <Stack
                            spacing={1}
                            direction="row"
                            alignItems="flex-end"
                            sx={{ pr: 1 }}
                          >
                            {sub.WklyInv ? (
                              <Box
                                sx={{ fontFamily: 'Roboto Mono', fontSize: 22 }}
                              >
                                {toCurrency({ value: Number(sub.WklyInv) })}
                              </Box>
                            ) : null}

                            {sub.Exclusive ? (
                              <Box
                                sx={{
                                  textTransform: 'uppercase',
                                  fontWeight: 'bold',
                                  color: 'darkorange',
                                }}
                              >
                                Exclusive
                              </Box>
                            ) : null}
                          </Stack>

                          <Stack>
                            <Stack
                              sx={{
                                flexDirection: { xs: 'column', md: 'row' },
                                alignItems: {
                                  xs: 'flex-start',
                                  md: 'flex-end',
                                },
                              }}
                            >
                              <Box
                                component="span"
                                sx={{
                                  pr: 0.5,
                                  fontWeight: 'bold',
                                  color: getProductColor(sub.Product),
                                }}
                              >
                                {getProductTitle(sub.Product)}
                              </Box>
                              {sub.RecAgt ? (
                                <Box component="span">
                                  for{' '}
                                  <Box component="strong">
                                    {sub.RecAgt.Fname} {sub.RecAgt.Lname}
                                  </Box>{' '}
                                  <Box
                                    component="span"
                                    sx={{
                                      fontFamily: 'Roboto Mono',
                                      fontWeight: 'bold',
                                      fontSize: 12,
                                    }}
                                  >
                                    {sub.RecAgt.AgtNo}
                                  </Box>
                                </Box>
                              ) : null}
                            </Stack>
                          </Stack>
                        </Stack>

                        {sub.SubRegions.length ? (
                          <Stack
                            spacing={1}
                            direction="row"
                            alignItems="center"
                          >
                            <Box sx={{ fontSize: 12 }}>Regions:</Box>
                            {sub.SubRegions.map((region) => {
                              return (
                                <Box
                                  key={region.FIPSRegId}
                                  sx={{
                                    fontWeight: 'bold',
                                    fontSize: 14,
                                    border: '1px solid #0002',
                                    borderRadius: 1,
                                    px: 0.5,
                                  }}
                                >
                                  {region.Region}
                                </Box>
                              );
                            })}
                          </Stack>
                        ) : null}
                      </Stack>

                      <Box sx={{ flex: 1 }} />

                      <Tooltip title="Remove from cart" placement="left" arrow>
                        <IconButton
                          size="small"
                          onClick={() =>
                            dispatch({
                              type: 'CART_SUB_REMOVE',
                              payload: sub.TempID,
                            })
                          }
                        >
                          <CloseIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  </Paper>
                );
              })}

              <Button
                fullWidth
                variant="outlined"
                size="large"
                color="info"
                sx={{ backgroundColor: '#fffe' }}
                startIcon={<LibraryAdd />}
                onClick={() => setAdding(true)}
              >
                Add Subscription for County
              </Button>
            </Stack>
          </React.Fragment>
        ) : null}
      </Paper>

      <SubscriptionAdd
        open={adding}
        onClose={() => setAdding(false)}
        feature={props.feature}
        filters={props.filters}
      />
    </React.Fragment>
  );
}
