import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import { useQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import { format } from 'date-fns/format';
import { orderBy, uniq } from 'lodash';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { LeadItem } from '../../components/lead-item';
import { RouteContainer } from '../../components/route-container';
import { SearchField, searchBy } from '../../components/search-field';
import { AuthContext } from '../../contexts/auth-context';
import { countyFeatures } from '../../data/county-features';
import { materialTheme } from '../../theme';
import { LEAD_PRODUCT, LEAD_STATUS, LEAD_TYPE, LeadData } from '../../types';
import { formatDate } from '../../utils/formatter';
import { getProductColor, getProductTitle } from '../../utils/general';
import { isAgt } from '../../utils/is-agent';
import { BackToTop } from './back-to-top';
import { leadStatuses } from './data';
import { LeadListItem } from './lead-list-item';
import { LeadsExport } from './leads-export';
import { LeadsMap } from './leads-map';

export function Leads() {
  // Context
  const {
    state: { impersonatedAgent, user },
  } = React.useContext(AuthContext);
  // State
  const [searchString, setSearchString] = React.useState('');
  const [dateRec, setDateRec] = React.useState('All');
  const [product, setProduct] = React.useState<LEAD_PRODUCT | 'All'>('All');
  const [leadType, setLeadType] = React.useState<LEAD_TYPE | 'All'>('All');
  const [status, setStatus] = React.useState<LEAD_STATUS | 'All'>('All');
  const [county, setCounty] = React.useState('All');
  const [distSrc, setDistSrc] = React.useState('All');
  const [displayAll, setDisplayAll] = React.useState(false);
  const [showButtonTop, setShowButtonTop] = React.useState(false);
  // Ref
  const listRef = React.useRef<HTMLDivElement>(null);
  // Hooks
  const [searchParams, setSearchParams] = useSearchParams();

  const selected = decodeURIComponent(searchParams.get('LeadID') || '');

  const handleScrollToTop = () => {
    if (listRef.current) {
      listRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  // Query - Leads
  const pathLeads = '/leads/distribution';
  const AgtNo = impersonatedAgent?.AgtNo || user?.getUsername();
  const query = useQuery({
    queryKey: [pathLeads, { AgtNo }],
    queryFn: async () => {
      if (isAgt(AgtNo)) {
        const response: {
          data: LeadData[];
        } = await API.post('LeadsAPI', pathLeads, { body: { AgtNo } });

        return response.data || [];
      } else {
        return [];
      }
    },
  });

  React.useEffect(() => {
    // Reset Leads Filters whenever the AgtNo changes
    setDateRec('All');
    setProduct('All');
    setLeadType('All');
    setStatus('All');
    setCounty('All');
    setDistSrc('All');
  }, [AgtNo]);

  React.useEffect(() => {
    const scrollableDiv = listRef.current;

    const handleScroll = () => {
      const position = scrollableDiv?.scrollTop ?? 0;
      if (position > 500) {
        setShowButtonTop(true);
      } else {
        setShowButtonTop(false);
      }
    };

    if (scrollableDiv) {
      scrollableDiv.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollableDiv) {
        scrollableDiv.removeEventListener('scroll', handleScroll);
      }
    };
  }, [query.isLoading]);

  const receiveDates = orderBy(
    uniq(query.data?.map((i) => i.RecDate?.slice(0, 10) || '')),
    [],
    'desc',
  );

  const sortedLeads = orderBy(
    query.data,
    ['RecDate', 'FIPS', 'City', 'LeadType', (i) => i.LastName?.toLowerCase()],
    ['desc', 'asc', 'asc', 'asc', 'asc'],
  );
  // Filter by Date Receives
  let leadsByDate = sortedLeads;
  if (dateRec !== 'All') {
    leadsByDate = sortedLeads.filter((i) => i.RecDate?.startsWith(dateRec));
  }
  // Filter by Product
  let leadsByProduct = leadsByDate;
  if (product !== 'All') {
    leadsByProduct = leadsByDate.filter((i) => i.Product === product);
  }
  // Filter by Lead Type
  let leadsByType = leadsByProduct;
  if (leadType !== 'All') {
    leadsByType = leadsByProduct.filter((i) => i.LeadType === leadType);
  }
  // Filter by Lead Status
  let leadsByStatus = leadsByType;
  if (status !== 'All') {
    leadsByStatus = leadsByType.filter((i) => i.ContactStatus === status);
  }
  // Filter by County
  let leadsByCounty = leadsByStatus;
  if (county !== 'All') {
    leadsByCounty = leadsByStatus.filter((i) => i.FIPS === county);
  }
  // Filter by Distribution Source
  let leadsByDistSrc = leadsByCounty;
  if (distSrc !== 'All') {
    leadsByDistSrc = leadsByCounty.filter((i) => i.DistSrc === distSrc);
  }
  // Filter by Search
  let searchedLeads = leadsByDistSrc;
  if (searchString) {
    searchedLeads = searchBy(leadsByDistSrc, searchString);
  }

  let displayLeads = searchedLeads;
  // Limit up to 60
  const displayLimit = 60;
  if (!displayAll && searchedLeads.length > displayLimit) {
    displayLeads = searchedLeads.slice(0, displayLimit);
  }

  // Get each unique Product for the Leads the user has
  const leadProducts: string[] = [];
  orderBy(query.data, 'Product').forEach((i) => {
    if (i.Product && !leadProducts.includes(i.Product)) {
      leadProducts.push(i.Product);
    }
  });

  // Get each unique Lead Type for the Leads the user has
  const leadTypes: string[] = [];
  orderBy(query.data, 'LeadType').forEach((i) => {
    if (i.LeadType && !leadTypes.includes(i.LeadType)) {
      leadTypes.push(i.LeadType);
    }
  });

  // Get each unique County for the Leads the user has
  const leadFIPS: string[] = [];
  orderBy(query.data, 'FIPS').forEach((i) => {
    if (i.FIPS && !leadFIPS.includes(i.FIPS)) {
      leadFIPS.push(i.FIPS);
    }
  });

  // Get each unique Distribution Source for the Leads the user has
  const leadDist: string[] = [];
  orderBy(query.data, 'DistSrc').forEach((i) => {
    if (i.DistSrc && !leadDist.includes(i.DistSrc)) {
      leadDist.push(i.DistSrc);
    }
  });

  return (
    <RouteContainer routeTitle="My Leads" loading={query.isLoading}>
      <React.Fragment>
        <Box
          sx={{
            height: { xs: 'inherit', md: 'calc(100dvh - 64px)' },
            display: 'flex',
            flexDirection: { xs: 'column-reverse', xl: 'row' },
            minHeight: 0,
            '@media screen and (max-height: 800px)': {
              height: { xs: 'inherit', xl: 'calc(100dvh - 64px)' },
            },
          }}
        >
          <Box
            sx={{
              flex: { xs: 1, md: 3, xl: 1 },
              minHeight: 0,
              borderRight: { xs: 'none', xl: '1px solid #c1c1c1' },
            }}
          >
            <Box
              sx={{
                minHeight: 0,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box
                sx={{
                  p: 2,
                  borderBottom: { xs: 'none', md: '1px solid #e1e1e1' },
                }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6} md={4}>
                    <FormControl fullWidth size="small">
                      <InputLabel id="lead-date-select-label">
                        Date Received
                      </InputLabel>
                      <Select
                        labelId="lead-date-select-label"
                        id="lead-date-select"
                        value={dateRec}
                        label="Date Received"
                        onChange={(event: SelectChangeEvent) => {
                          setDateRec(event.target.value);
                          setProduct('All');
                          setLeadType('All');
                          setStatus('All');
                          setCounty('All');
                          setDistSrc('All');
                          setDisplayAll(false);
                        }}
                      >
                        {['All', ...receiveDates].map((RecDate) => {
                          let count = query.data?.length || 0;
                          if (RecDate !== 'All') {
                            count =
                              query.data?.filter(
                                (i) => i.RecDate?.startsWith(RecDate),
                              ).length || 0;
                          }

                          let displayDate = 'All';
                          if (RecDate !== 'All') {
                            displayDate = format(
                              new Date(formatDate({ value: RecDate })),
                              'EEE, LLL do, yyyy',
                            );
                          }

                          return (
                            <MenuItem key={RecDate} value={RecDate}>
                              <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                                sx={{ width: '100%' }}
                              >
                                {RecDate === 'All' ? (
                                  <Box sx={{ fontSize: 16 }}>All</Box>
                                ) : (
                                  <Box
                                    sx={{
                                      textTransform: 'uppercase',
                                      fontFamily: 'Roboto Mono',
                                      fontSize: 12,
                                    }}
                                  >
                                    {displayDate}
                                  </Box>
                                )}

                                <Box
                                  sx={{
                                    fontFamily: 'Roboto Mono',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {count}
                                </Box>
                              </Stack>
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <FormControl fullWidth size="small">
                      <InputLabel id="lead-product-select-label">
                        Product
                      </InputLabel>
                      <Select
                        labelId="lead-product-select-label"
                        id="lead-product-select"
                        value={product}
                        label="Product"
                        onChange={(event: SelectChangeEvent) => {
                          setProduct(event.target.value as LEAD_PRODUCT);
                          setLeadType('All');
                          setStatus('All');
                          setCounty('All');
                          setDistSrc('All');
                          setDisplayAll(false);
                        }}
                      >
                        {['All', ...leadProducts].map((lp) => {
                          let count = leadsByDate.length || 0;
                          let title = lp;
                          if (lp !== 'All') {
                            count =
                              leadsByDate.filter((i) => i.Product === lp)
                                .length || 0;
                            title = getProductTitle(lp as LEAD_PRODUCT);
                          }

                          return (
                            <MenuItem
                              disabled={count === 0}
                              key={lp}
                              value={lp}
                            >
                              <Stack
                                spacing={1}
                                direction="row"
                                alignItems="center"
                                sx={{ width: '100%' }}
                              >
                                {lp !== 'All' ? (
                                  <Box
                                    sx={{
                                      fontSize: 14,
                                      fontWeight: 'bold',
                                      color: getProductColor(
                                        lp as LEAD_PRODUCT,
                                      ),
                                    }}
                                  >
                                    {title}
                                  </Box>
                                ) : (
                                  <Box sx={{ fontSize: 16 }}>{lp}</Box>
                                )}

                                <Box sx={{ flex: 1 }} />
                                <Box
                                  sx={{
                                    fontFamily: 'Roboto Mono',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {count}
                                </Box>
                              </Stack>
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <FormControl fullWidth size="small">
                      <InputLabel id="lead-type-select-label">Type</InputLabel>
                      <Select
                        labelId="lead-type-select-label"
                        id="lead-type-select"
                        value={leadType}
                        label="Type"
                        onChange={(event: SelectChangeEvent) => {
                          setLeadType(event.target.value as LEAD_TYPE);
                          setStatus('All');
                          setCounty('All');
                          setDistSrc('All');
                          setDisplayAll(false);
                        }}
                      >
                        {['All', ...leadTypes].map((lt) => {
                          let count = leadsByProduct.length || 0;
                          if (lt !== 'All') {
                            count =
                              leadsByProduct.filter((i) => i.LeadType === lt)
                                .length || 0;
                          }

                          return (
                            <MenuItem
                              disabled={count === 0}
                              key={lt}
                              value={lt}
                            >
                              <Stack
                                spacing={1}
                                direction="row"
                                alignItems="center"
                                sx={{ width: '100%' }}
                              >
                                {lt === 'All' ? (
                                  <Box sx={{ fontSize: 16 }}>{lt}</Box>
                                ) : (
                                  <Box
                                    sx={{
                                      fontSize: 16,
                                      fontFamily: 'Roboto Mono',
                                      fontWeight: 'bold',
                                      color: 'teal',
                                    }}
                                  >
                                    {lt}
                                  </Box>
                                )}

                                <Box sx={{ flex: 1 }} />
                                <Box
                                  sx={{
                                    fontFamily: 'Roboto Mono',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {count}
                                </Box>
                              </Stack>
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <FormControl fullWidth size="small">
                      <InputLabel id="lead-status-select-label">
                        Status
                      </InputLabel>
                      <Select
                        labelId="lead-status-select-label"
                        id="lead-status-select"
                        value={status}
                        label="Status"
                        onChange={(event: SelectChangeEvent) => {
                          setStatus(event.target.value as LEAD_STATUS);
                          setCounty('All');
                          setDistSrc('All');
                          setDisplayAll(false);
                        }}
                      >
                        {[{ status: 'All', color: '' }, ...leadStatuses].map(
                          (ls) => {
                            let count = leadsByType.length || 0;
                            if (ls.status !== 'All') {
                              count =
                                leadsByType.filter(
                                  (i) => i.ContactStatus === ls.status,
                                ).length || 0;
                            }

                            return (
                              <MenuItem
                                disabled={count === 0}
                                key={ls.status}
                                value={ls.status}
                              >
                                <Stack
                                  spacing={1}
                                  direction="row"
                                  alignItems="center"
                                  sx={{ width: '100%' }}
                                >
                                  {ls.color ? (
                                    <Box>
                                      <Box
                                        sx={{
                                          backgroundColor: ls.color,
                                          borderRadius: 2,
                                          height: 22,
                                          width: 6,
                                        }}
                                      />
                                    </Box>
                                  ) : null}
                                  <Box sx={{ fontSize: 16 }}>{ls.status}</Box>
                                  <Box sx={{ flex: 1 }} />
                                  <Box
                                    sx={{
                                      fontFamily: 'Roboto Mono',
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    {count}
                                  </Box>
                                </Stack>
                              </MenuItem>
                            );
                          },
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <FormControl fullWidth size="small">
                      <InputLabel id="lead-county-select-label">
                        County
                      </InputLabel>
                      <Select
                        labelId="lead-county-select-label"
                        id="lead-county-select"
                        value={county}
                        label="County"
                        onChange={(event: SelectChangeEvent) => {
                          setCounty(event.target.value);
                          setDistSrc('All');
                          setDisplayAll(false);
                        }}
                      >
                        {['All', ...leadFIPS].map((FIPS) => {
                          let count = leadsByStatus.length || 0;
                          if (FIPS !== 'All') {
                            count =
                              leadsByStatus.filter((i) => i.FIPS === FIPS)
                                .length || 0;
                          }

                          let County = 'All';
                          let State = '';
                          if (FIPS !== 'All') {
                            const countyData = countyFeatures.find(
                              (i) => i.FIPS === FIPS,
                            );
                            if (countyData) {
                              County = countyData.NAME;
                              State = countyData.StateAbbr;
                            }
                          }

                          return (
                            <MenuItem
                              disabled={count === 0}
                              key={FIPS}
                              value={FIPS}
                            >
                              <Stack
                                spacing={1}
                                direction="row"
                                alignItems="center"
                                sx={{ width: '100%' }}
                              >
                                {State ? (
                                  <Box
                                    sx={{
                                      color: '#b26500',
                                      fontSize: 14,
                                      fontWeight: 'bold',
                                      fontFamily: 'Roboto Mono',
                                    }}
                                  >
                                    {State}
                                  </Box>
                                ) : null}
                                <Box sx={{ fontSize: 16 }}>{County}</Box>
                                <Box sx={{ flex: 1 }} />
                                <Box
                                  sx={{
                                    fontFamily: 'Roboto Mono',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {count}
                                </Box>
                              </Stack>
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <FormControl fullWidth size="small">
                      <InputLabel id="lead-distribution-source-select-label">
                        Dist Source
                      </InputLabel>
                      <Select
                        labelId="lead-distribution-source-select-label"
                        id="lead-distribution-source-select"
                        value={distSrc}
                        label="Dist Source"
                        onChange={(event: SelectChangeEvent) => {
                          setDistSrc(event.target.value);
                          setDisplayAll(false);
                        }}
                      >
                        {['All', ...leadDist].map((ds) => {
                          let count = leadsByCounty.length || 0;
                          if (ds !== 'All') {
                            count =
                              leadsByCounty.filter((i) => i.DistSrc === ds)
                                .length || 0;
                          }

                          return (
                            <MenuItem
                              disabled={count === 0}
                              key={ds}
                              value={ds}
                            >
                              <Stack
                                spacing={1}
                                direction="row"
                                alignItems="center"
                                sx={{ width: '100%' }}
                              >
                                {ds === 'All' ? (
                                  <Box sx={{ fontSize: 16 }}>{ds}</Box>
                                ) : (
                                  <Box
                                    sx={{
                                      fontSize: 14,
                                      fontWeight: 'bold',
                                      color: materialTheme.palette.primary.main,
                                    }}
                                  >
                                    {ds}
                                  </Box>
                                )}

                                <Box sx={{ flex: 1 }} />
                                <Box
                                  sx={{
                                    fontFamily: 'Roboto Mono',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {count}
                                </Box>
                              </Stack>
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack spacing={1} direction="row" alignItems="center">
                      <SearchField
                        fullWidth
                        id="search-leads"
                        size="small"
                        placeholder="Search Leads"
                        value={searchString}
                        onChange={(event) =>
                          setSearchString(event.target.value)
                        }
                      />

                      <LeadsExport
                        data={query.data || []}
                        filteredLeads={
                          searchedLeads.length !== query.data?.length
                            ? searchedLeads
                            : []
                        }
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </Box>

              <Box
                ref={listRef}
                sx={{ minHeight: 0, px: 2, flex: 1, overflow: 'auto' }}
              >
                <Stack spacing={1} sx={{ pt: 1, pb: 16 }}>
                  {query.data && query.data.length === 0 ? (
                    <Box
                      sx={{
                        p: 4,
                        textAlign: 'center',
                        backgroundColor: '#f5f5f5',
                        borderRadius: 2,
                      }}
                    >
                      <Box sx={{ fontFamily: 'Roboto Mono' }}>
                        Purchased Leads will display here
                      </Box>
                    </Box>
                  ) : null}

                  {displayLeads.map((item) => {
                    const key = `${item.LeadID}-${item.DistHistID}`;
                    return (
                      <LeadListItem
                        key={key}
                        item={item}
                        onClick={() =>
                          setSearchParams({ LeadID: String(item.LeadID) })
                        }
                      />
                    );
                  })}

                  {!displayAll && searchedLeads.length > displayLimit ? (
                    <Button
                      size="large"
                      variant="outlined"
                      startIcon={<ExpandCircleDownOutlinedIcon />}
                      onClick={() =>
                        setDisplayAll((currentState) => !currentState)
                      }
                    >
                      Load More
                    </Button>
                  ) : null}
                </Stack>

                {showButtonTop ? (
                  <BackToTop onClick={handleScrollToTop} />
                ) : null}
              </Box>
            </Box>
          </Box>

          <Box sx={{ flex: 1 }}>
            <LeadsMap data={searchedLeads} />
          </Box>
        </Box>

        <LeadItem
          item={query.data?.find((i) => i.LeadID === Number(selected))}
          onClose={() => setSearchParams({})}
        />
      </React.Fragment>
    </RouteContainer>
  );
}
