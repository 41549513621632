import { Checklist, Receipt } from '@mui/icons-material';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import DiscountIcon from '@mui/icons-material/Discount';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';
import FormatListNumberedRtlIcon from '@mui/icons-material/FormatListNumberedRtl';
import GroupsIcon from '@mui/icons-material/Groups';
import HomeIcon from '@mui/icons-material/Home';
import PaymentsIcon from '@mui/icons-material/Payments';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import { useQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import React from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { AppContext } from '../contexts/app-context';
import { AuthContext } from '../contexts/auth-context';
import { CartContext } from '../contexts/cart-context';
import { usePermissions } from '../hooks/use-permissions';
import { CartData } from '../routes/leads-map/data';
import { appTheme, materialTheme } from '../theme';
import { AWS_GROUP } from '../types';
import { isAgt } from '../utils/is-agent';
import { Cart } from './cart';
import { SelectAgentImpersonate } from './select-agent-impersonate';

interface NavItem {
  name: string;
  icon: JSX.Element;
  path?: string;
  children?: NavItem[];
  // Whether or not this link should be hidden from production
  hideFromProd?: boolean;
  // Only this Group can see this link
  hasGroup?: AWS_GROUP;
  // User must have one of the permissions
  permissions?: AWS_GROUP[];
}

const navLinks: NavItem[] = [
  {
    name: 'Home',
    icon: <HomeIcon color="inherit" />,
    path: '/home',
  },
  {
    name: 'Admin',
    icon: <AdminPanelSettingsIcon />,
    permissions: [
      AWS_GROUP.ARC_GlobalAdmin,
      AWS_GROUP.LDS_GlobalAdmin,
      AWS_GROUP.LDS_KeyingMgr,
    ],
    children: [
      {
        name: 'Subscriptions',
        icon: <DynamicFeedIcon />,
        path: '/admin/subscriptions',
        permissions: [AWS_GROUP.LDS_GlobalAdmin],
      },
      {
        name: 'Inventory Count',
        icon: <FormatListNumberedRtlIcon color="inherit" />,
        path: '/admin/inventory-count',
        permissions: [AWS_GROUP.LDS_GlobalAdmin],
      },
      {
        name: 'Pricing Manager',
        icon: <CurrencyExchangeIcon color="inherit" />,
        path: '/admin/pricing-manager',
        permissions: [AWS_GROUP.LDS_GlobalAdmin],
      },
      {
        name: 'Discounts',
        icon: <DiscountIcon color="inherit" />,
        path: '/admin/discounts',
        permissions: [AWS_GROUP.LDS_GlobalAdmin],
      },
      {
        name: 'Leads Processing',
        icon: <Checklist fontSize="small" />,
        path: '/admin/leads-processing',
        permissions: [AWS_GROUP.ARC_GlobalAdmin, AWS_GROUP.LDS_KeyingMgr],
      },
    ],
  },
  {
    name: 'Team Management',
    icon: <GroupsIcon />,
    path: '/team',
  },
  {
    name: 'Lead Map',
    icon: <TravelExploreIcon color="inherit" />,
    path: '/map',
  },
  {
    name: 'Lead Subscriptions',
    icon: <DynamicFeedIcon color="inherit" />,
    path: '/subscriptions',
  },
  {
    name: 'My Leads',
    icon: <RecentActorsIcon />,
    path: '/leads',
  },
  {
    name: 'Cart History',
    icon: <PaymentsIcon />,
    path: '/history',
  },
  {
    name: 'Weekly Invoices',
    icon: <Receipt />,
    path: '/invoices',
  },
  {
    hideFromProd: true,
    name: 'Lead Resources',
    icon: <FolderSpecialIcon />,
    path: '/resources',
  },
];

function NavListItem(props: { item: NavItem; isActive: boolean }) {
  // Props
  const { item, isActive } = props;
  // State
  const [open, setOpen] = React.useState(false);
  // Hooks
  const location = useLocation();
  const { checkPermissions, hasGroup } = usePermissions();

  const handleClick = () => {
    setOpen((currentState) => !currentState);
  };

  let canView = true;
  if (item.hideFromProd) {
    canView = process.env.REACT_APP_AWS_BRANCH !== 'master';
  } else if (item.hasGroup) {
    // User must have this specific group to view link
    canView = hasGroup(item.hasGroup);
  } else if (item.permissions) {
    // User must have one of the permissions
    canView = checkPermissions(item.permissions);
  }

  if (canView && item.children && item.children.length) {
    return (
      <React.Fragment>
        <ListItem disablePadding>
          <ListItemButton onClick={handleClick}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.name} disableTypography />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        </ListItem>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" dense disablePadding sx={{ pl: 4 }}>
            {item.children.map((navItem) => {
              const childActive = location.pathname === navItem.path;
              return (
                <NavListItem
                  key={navItem.name}
                  item={navItem}
                  isActive={childActive}
                />
              );
            })}
          </List>
        </Collapse>
      </React.Fragment>
    );
  } else if (canView && item.path) {
    return (
      <ListItem
        disablePadding
        sx={{
          transition: 'all 0.2s',
          backgroundColor: isActive ? '#00000011' : 'inherit',
          borderRightWidth: 4,
          borderRightStyle: 'solid',
          borderRightColor: isActive
            ? materialTheme.palette.secondary.main
            : 'transparent',
          ':hover': {
            borderRightColor: isActive
              ? materialTheme.palette.secondary.main
              : '#00000022',
          },
        }}
      >
        <ListItemButton component={Link} to={item.path}>
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText
            primary={item.name}
            disableTypography
            sx={{ fontWeight: isActive ? 'bold' : 'inherit' }}
          />
        </ListItemButton>
      </ListItem>
    );
  } else {
    return null;
  }
}

export function Dashboard() {
  // Context
  const { state, dispatch } = React.useContext(AppContext);
  const authContext = React.useContext(AuthContext);
  const cartContext = React.useContext(CartContext);
  // Hooks
  const location = useLocation();
  const { hasGroup } = usePermissions();

  const { impersonatedAgent, user } = authContext.state;
  // Only Admins can manage carts for other users
  let AgtNo = user?.getUsername() || null;
  if (hasGroup(AWS_GROUP.LDS_GlobalAdmin)) {
    AgtNo = impersonatedAgent?.AgtNo || null;
  }
  // Query - Cart
  const pathCart = '/leads/instant-purchase/cart';
  useQuery({
    queryKey: [pathCart, { AgtNo }],
    queryFn: async () => {
      if (isAgt(AgtNo)) {
        const response: {
          data: CartData;
        } = await API.post('LeadsAPI', pathCart, {
          body: { BillToAgt: AgtNo },
        });

        // Add Cart data to Cart Context
        cartContext.dispatch({
          type: 'CART_SET_LEADS',
          payload: response.data,
        });

        return response.data;
      } else {
        cartContext.dispatch({
          type: 'CART_SET_LEADS',
          payload: null,
        });

        return null;
      }
    },
  });

  const drawer = (
    <Box
      sx={{
        backgroundColor: '#fffbf3',
        height: '100dvh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Toolbar
        sx={{
          backgroundColor: materialTheme.palette.primary.main + 'ee',
          color: '#fff',
        }}
      >
        {authContext.state.authorize?.IMO.Logo ? (
          <Box
            component="img"
            src={authContext.state.authorize.IMO.Logo}
            alt={authContext.state.authorize?.IMO.IMODisplayName || 'Leads'}
            sx={{ maxHeight: 40 }}
          />
        ) : authContext.state.authorize?.IMO.IMODisplayName ? (
          <Box sx={{ fontWeight: 'bold', fontSize: 14 }}>
            {authContext.state.authorize?.IMO.IMODisplayName}
          </Box>
        ) : (
          <Box
            sx={{
              fontWeight: 'bold',
              fontSize: 24,
              fontFamily: 'Roboto Mono',
              textTransform: 'uppercase',
            }}
          >
            Leads
          </Box>
        )}
      </Toolbar>

      <Box
        sx={{
          minHeight: 0,
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          borderRightColor: materialTheme.palette.primary.main + '11',
          borderRightStyle: 'solid',
          borderRightWidth: 1,
        }}
      >
        <SelectAgentImpersonate />

        <Box
          sx={{
            minHeight: 0,
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'auto',
          }}
        >
          <List dense sx={{ flex: 1 }}>
            {navLinks.map((navItem) => {
              const isActive = location.pathname === navItem.path;
              return (
                <NavListItem
                  key={navItem.name}
                  item={navItem}
                  isActive={isActive}
                />
              );
            })}
            <ListItem
              disablePadding
              sx={{
                transition: 'all 0.2s',
                backgroundColor: 'inherit',
                borderRightWidth: 4,
                borderRightStyle: 'solid',
                borderRightColor: 'transparent',
                ':hover': {
                  borderRightColor: '#00000022',
                },
              }}
            >
              <ListItemButton
                component="a"
                href="https://arc.naaleads.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <ListItemIcon>
                  <ArrowBackIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Back to ARC"
                  disableTypography
                  sx={{ fontWeight: 'inherit' }}
                />
              </ListItemButton>
            </ListItem>
          </List>

          <Box sx={{ p: 1 }}>
            <Box>
              <Box
                component="a"
                href="https://a7d9021b860b18c4222a-d761a6fa6590560fc9f9e4d318d2556e.ssl.cf1.rackcdn.com/contracting/documents/alliance-terms-of-service.pdf"
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  display: 'inline-block',
                  textDecorationLine: 'none',
                  fontSize: 12,
                  fontWeight: 'bold',
                  textTransform: 'uppercase',
                  transition: 'all 0.2s',
                  color: '#9b9b9b',
                  ':hover': {
                    color: '#2196f3',
                  },
                }}
              >
                Terms of Service
              </Box>
            </Box>

            <Box>
              <Box
                component="a"
                href="https://a7d9021b860b18c4222a-d761a6fa6590560fc9f9e4d318d2556e.ssl.cf1.rackcdn.com/contracting/documents/alliance-privacy-notice.pdf"
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  display: 'inline-block',
                  textDecorationLine: 'none',
                  fontSize: 12,
                  fontWeight: 'bold',
                  textTransform: 'uppercase',
                  transition: 'all 0.2s',
                  color: '#9b9b9b',
                  ':hover': {
                    color: '#2196f3',
                  },
                }}
              >
                Privacy Notice
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <Box
        component="nav"
        sx={{ width: { sm: appTheme.drawerWidth }, flexShrink: { sm: 0 } }}
      >
        <Drawer
          container={window.document.body}
          variant="temporary"
          open={state.drawerOpen}
          onClose={() => dispatch({ type: 'TOGGLE_DRAWER' })}
          ModalProps={{ keepMounted: true }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: appTheme.drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>

        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              border: 'none',
              width: appTheme.drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>

      <Outlet />

      <Cart />
    </Box>
  );
}
