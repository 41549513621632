import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { orderBy, uniq } from 'lodash';
import React from 'react';
import { PaperExpandable } from '../../components/paper-expandable';
import { searchBy } from '../../components/search-field';
import { LeadSubData } from './data';
import { ManageSubscription } from './manage-subscription';
import { MySubscriptionsCounty } from './my-subscriptions-county';

export function MySubscriptions(props: { subs: LeadSubData[] | undefined }) {
  // Props
  const { subs } = props;
  // State
  const [selected, setSelected] = React.useState<LeadSubData['LeadSubId']>();
  const [searchString, setSearchString] = React.useState('');

  // Searching
  const searchedItems = searchBy(subs || [], searchString);

  // Uniqe Counties
  const counties = orderBy(uniq(searchedItems?.map((i) => i.FIPS)));

  return (
    <PaperExpandable
      count={subs?.length}
      title="My Subscriptions"
      searchString={searchString}
      onChangeSearch={(event) => setSearchString(event.target.value)}
    >
      {searchedItems.length > 0 ? (
        <Stack spacing={1} sx={{ p: 1 }}>
          {counties.map((FIPS) => {
            const subsForCounty = subs?.filter((i) => i.FIPS === FIPS);
            if (subsForCounty && subsForCounty.length) {
              return (
                <MySubscriptionsCounty
                  key={FIPS}
                  FIPS={FIPS}
                  data={subsForCounty}
                  onSelect={(newLeadSubId) => setSelected(newLeadSubId)}
                />
              );
            } else {
              return null;
            }
          })}

          <ManageSubscription
            item={subs?.find((i) => i.LeadSubId === selected)}
            subs={subs}
            onClose={() => setSelected(undefined)}
          />
        </Stack>
      ) : (
        <Box
          sx={{
            p: 3,
            textAlign: 'center',
            fontFamily: 'Roboto Mono',
            color: '#4a4a4a',
          }}
        >
          You have no Active Subscriptions
        </Box>
      )}
    </PaperExpandable>
  );
}
