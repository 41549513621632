import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { CartItem } from '../../routes/leads-map/cart-item';
import { CartItemData } from '../../routes/leads-map/data';
import {
  getCartItemID,
  getDetailsFIPS,
} from '../../routes/leads-map/purchase-utils';
import { toCurrency } from '../../utils/formatter';
import { getCountyByFIPS } from '../../utils/map';

export function CartPurchaseCounty(props: {
  FIPS: string;
  items: CartItemData[];
}) {
  // Props
  const { FIPS, items } = props;

  const county = getCountyByFIPS(FIPS);
  const purchaseDetails = getDetailsFIPS({ data: items, FIPS });

  return (
    <Paper
      elevation={0}
      sx={{ border: '1px solid #00000022', overflow: 'hidden' }}
    >
      <Stack
        spacing={1}
        direction="row"
        alignItems="center"
        sx={{ p: 1, py: 0.5 }}
      >
        <Box
          sx={{
            color: '#b26500',
            fontSize: { xs: 16, md: 22 },
            fontWeight: 'bold',
            fontFamily: 'Roboto Mono',
          }}
        >
          {county?.StateAbbr}
        </Box>

        <Box sx={{ fontSize: { xs: 18, md: 24 } }}>{county?.NAME}</Box>

        <Box sx={{ flex: 1, borderTop: '1px solid #eaeaea' }} />

        <Box sx={{ fontFamily: 'Roboto Mono', color: '#2e7d32' }}>
          {purchaseDetails.quantity}
        </Box>

        <Box sx={{ width: 8, borderTop: '1px solid #eaeaea' }} />

        <Box
          sx={{
            fontFamily: 'Roboto Mono',
            fontSize: 14,
            color: '#9b9b9b',
          }}
        >
          {toCurrency({ value: purchaseDetails.spend })}
        </Box>
      </Stack>

      <Box sx={{ p: 1 }}>
        <Stack spacing={1}>
          {purchaseDetails.purchases.map((purchase) => {
            const purchaseKey = getCartItemID({ data: purchase });
            return <CartItem key={purchaseKey} item={purchase} />;
          })}
        </Stack>
      </Box>
    </Paper>
  );
}
