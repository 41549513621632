import Box from '@mui/material/Box';
import { PaperExpandable } from '../../../components/paper-expandable';

export function Notifications() {
  return (
    <PaperExpandable title="Notifications" count={0}>
      <Box
        sx={{
          p: 4,
          textAlign: 'center',
          fontFamily: 'Roboto Mono',
          color: '#9b9b9b',
        }}
      >
        No notifications at this time
      </Box>
    </PaperExpandable>
  );
}
