import { Parser } from '@json2csv/plainjs';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import TableViewIcon from '@mui/icons-material/TableView';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import { orderBy } from 'lodash';
import React from 'react';
import { AuthContext } from '../../contexts/auth-context';
import { LeadData } from '../../types';
import { captureError } from '../../utils/capture-error';
import { getProductColor } from '../../utils/general';
import { leadStatuses } from './data';

const formatExport = (item: LeadData) => {
  return {
    FirstName: item.FirstName || '',
    LastName: item.LastName || '',
    Phone: item.Phone || '',
    Address1: item.Address1 || '',
    Address2: item.Address2 || '',
    City: item.City || '',
    St: item.St || '',
    Zip: item.Zip || '',
    County: item.County || '',
    Email: item.Email || '',
    DOB: item.DOB || '',
    Age: item.Age || '',
    SpouseName: item.SpouseName || '',
    SpouseAge: item.SpouseAge || '',
    ContactStatus: item.ContactStatus || '',
    MortgageDate: item.MortgageDate || '',
    MortgageAmount: item.MortgageAmount || '',
    Lender: item.Lender || '',
    Product: item.Product || '',
    LeadType: item.LeadType || '',
    LeadID: item.LeadID || '',
    RecDate: item.RecDate || '',
  };
};

const createPDFDownload = (response: any) => {
  const blob = new Blob([response], { type: 'application/pdf' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.setAttribute('download', `${new Date().getTime()}-leads-export.pdf`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

// The total number of Leads that can be exported at one time
const exportPDFLimit = 200;

interface ExportChuck {
  index: number;
  exported: boolean;
  start: number;
  end: number;
}

export function LeadsExport(props: {
  data: LeadData[];
  filteredLeads: LeadData[];
}) {
  // Context
  const {
    state: { impersonatedAgent, user },
  } = React.useContext(AuthContext);
  // State
  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState<LeadData['DistHistID'][]>([]);
  const [selectAll, setSelectAll] = React.useState(false);
  const [selectUnx, setSelectUnx] = React.useState(false);
  const [exportStack, setExportStack] = React.useState<ExportChuck[]>([]);
  const [selectedStack, setSelectedStack] = React.useState<ExportChuck[]>([]);

  // Create export Stack when amount of Leads exceeds PDF Limits
  React.useEffect(() => {
    if (open && props.data.length > exportPDFLimit) {
      const chunks = Math.ceil(props.data.length / exportPDFLimit);
      setExportStack(
        Array.from({ length: chunks }).map((_, index) => {
          const start = index * exportPDFLimit + 1;
          let end = index * exportPDFLimit + exportPDFLimit;
          if (end > props.data.length) {
            end = props.data.length;
          }
          return { index, exported: false, start, end };
        }),
      );
    } else {
      setExportStack([]);
    }
  }, [props.data, open]);

  // Create Selected export Stack when amount of Leads selected exceeds PDF Limits
  React.useEffect(() => {
    if (selected.length > exportPDFLimit) {
      const chunks = Math.ceil(selected.length / exportPDFLimit);
      setSelectedStack(
        Array.from({ length: chunks }).map((_, index) => {
          const start = index * exportPDFLimit + 1;
          let end = index * exportPDFLimit + exportPDFLimit;
          if (end > selected.length) {
            end = selected.length;
          }
          return { index, exported: false, start, end };
        }),
      );
    } else {
      setSelectedStack([]);
    }
  }, [selected]);

  // Default select any Leads that have been filtered on in the main List
  React.useEffect(() => {
    // Only do this when the export panel is closed so that it doesn't reset
    // the current selected list when it is open
    if (!open) {
      setSelected(props.filteredLeads.map((i) => i.DistHistID));
    }
  }, [open, props.filteredLeads]);

  // CSV - All
  const handleDownloadCSVAll = () => {
    const formattedData = props.data.map((i) => formatExport(i));
    const parser = new Parser();
    const csv = parser.parse(formattedData);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', `${new Date().getTime()}-leads-export.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // CSV - Selected
  const handleDownloadCSVSelected = () => {
    const selectedData: LeadData[] = [];
    props.data.forEach((i) => {
      if (selected.includes(i.DistHistID)) {
        selectedData.push(i);
      }
    });
    const formattedData = selectedData.map((i) => formatExport(i));
    const parser = new Parser();
    const csv = parser.parse(formattedData);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', `${new Date().getTime()}-leads-export.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const AgtNo = impersonatedAgent?.AgtNo || user?.getUsername();

  const queryClient = useQueryClient();

  // PDF - All
  const mutationPDFAll = useMutation({
    mutationFn: async () => {
      const DistHistIDs = props.data.map((i) => i.DistHistID);
      const response = await API.post('LeadsAPI', '/leads/distribution/batch', {
        responseType: 'arraybuffer',
        body: { AgtNo, DistHistIDs },
      });
      createPDFDownload(response);
    },
    onSuccess: async () => {
      // Refetch leads for user
      await queryClient.invalidateQueries({
        queryKey: ['/leads/distribution', { AgtNo }],
      });
    },
    onError: (error) => captureError({ data: { error } }),
  });

  // PDF - Selected
  const mutationPDFSelected = useMutation({
    mutationFn: async () => {
      const response = await API.post('LeadsAPI', '/leads/distribution/batch', {
        responseType: 'arraybuffer',
        body: { AgtNo, DistHistIDs: selected },
      });
      createPDFDownload(response);
    },
    onSuccess: async () => {
      // Refetch leads for user
      await queryClient.invalidateQueries({
        queryKey: ['/leads/distribution', { AgtNo }],
      });
    },
    onError: (error) => captureError({ data: { error } }),
  });

  // PDF - Chunk of All Leads
  const mutationPDFChunk = useMutation({
    mutationFn: async (chunk: ExportChuck) => {
      const startIndex = chunk.start - 1;
      const endIndex = chunk.end;
      const DistHistIDs = props.data
        .slice(startIndex, endIndex)
        .map((i) => i.DistHistID);
      const response = await API.post('LeadsAPI', '/leads/distribution/batch', {
        responseType: 'arraybuffer',
        body: { AgtNo, DistHistIDs },
      });
      createPDFDownload(response);

      setExportStack((currentState) =>
        currentState.map((i) => {
          if (i.index === chunk.index) {
            return { ...i, exported: true };
          } else {
            return i;
          }
        }),
      );
    },
    onSuccess: async () => {
      // Refetch leads for user
      await queryClient.invalidateQueries({
        queryKey: ['/leads/distribution', { AgtNo }],
      });
    },
    onError: (error) => captureError({ data: { error } }),
  });

  // PDF - Chunk of Selected Leads
  const mutationPDFChunkSelected = useMutation({
    mutationFn: async (chunk: ExportChuck) => {
      const startIndex = chunk.start - 1;
      const endIndex = chunk.end;
      const DistHistIDs = selected.slice(startIndex, endIndex);
      const response = await API.post('LeadsAPI', '/leads/distribution/batch', {
        responseType: 'arraybuffer',
        body: { AgtNo, DistHistIDs },
      });
      createPDFDownload(response);

      setSelectedStack((currentState) =>
        currentState.map((i) => {
          if (i.index === chunk.index) {
            return { ...i, exported: true };
          } else {
            return i;
          }
        }),
      );
    },
    onSuccess: async () => {
      // Refetch leads for user
      await queryClient.invalidateQueries({
        queryKey: ['/leads/distribution', { AgtNo }],
      });
    },
    onError: (error) => captureError({ data: { error } }),
  });

  const handleClose = async () => {
    setOpen(false);
    setSelectAll(false);
    setSelected([]);

    // Reset requests
    mutationPDFAll.reset();
    mutationPDFSelected.reset();
    mutationPDFChunk.reset();
    mutationPDFChunkSelected.reset();
  };

  // Move selected Leads to the top of the list
  const checkedItems: LeadData[] = [];
  const uncheckedItems: LeadData[] = [];
  const sortedData = orderBy(
    props.data,
    [
      'AgtExport',
      'RecDate',
      'FIPS',
      'City',
      'LeadType',
      (i) => i.LastName?.toLowerCase(),
    ],
    ['asc', 'desc', 'asc', 'asc', 'asc', 'asc'],
  );
  sortedData.forEach((i) => {
    if (selected.includes(i.DistHistID)) {
      checkedItems.push(i);
    } else {
      uncheckedItems.push(i);
    }
  });
  const displayData = [...checkedItems, ...uncheckedItems];

  // The total amount of leads is less than or equal to the PDF export limit
  const canExportPDFAll = props.data.length <= exportPDFLimit;

  return (
    <React.Fragment>
      <Button
        size="medium"
        variant="outlined"
        startIcon={<FileDownloadIcon />}
        onClick={() => setOpen(true)}
      >
        Export
      </Button>

      <Drawer
        container={window.document.body}
        variant="temporary"
        anchor="right"
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: { xs: '100vw', md: '60vw', lg: '50vw', xl: '40vw' },
            height: '100dvh',
          },
        }}
      >
        <Box
          sx={{ height: '100dvh', display: 'flex', flexDirection: 'column' }}
        >
          <Toolbar>
            <IconButton sx={{ mr: 2 }} onClick={handleClose}>
              {false ? <CircularProgress size={24} /> : <CloseIcon />}
            </IconButton>

            <Stack
              spacing={1}
              direction="row"
              alignItems="center"
              sx={{ flex: 1 }}
            >
              <Typography variant="h6" noWrap component="div">
                Export Leads
              </Typography>

              <Box sx={{ flex: 1 }} />
            </Stack>
          </Toolbar>

          <Divider />

          <Box sx={{ pt: 1, px: 1 }}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <Button
                  disabled={props.data.length === 0}
                  fullWidth
                  size="small"
                  variant="outlined"
                  startIcon={<TableViewIcon fontSize="small" />}
                  onClick={handleDownloadCSVAll}
                >
                  Export all to CSV
                </Button>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  disabled={selected.length === 0}
                  fullWidth
                  color="info"
                  size="small"
                  variant="outlined"
                  startIcon={<TableViewIcon fontSize="small" />}
                  onClick={handleDownloadCSVSelected}
                >
                  {selected.length === 0
                    ? 'Export selected to CSV'
                    : `Export (${selected.length}) to CSV`}
                </Button>
              </Grid>

              {canExportPDFAll ? (
                <React.Fragment>
                  <Grid item xs={12} sm={6}>
                    <Button
                      disabled={
                        !canExportPDFAll ||
                        props.data.length === 0 ||
                        mutationPDFAll.isPending ||
                        mutationPDFSelected.isPending
                      }
                      fullWidth
                      size="small"
                      variant="outlined"
                      startIcon={<PictureAsPdfIcon fontSize="small" />}
                      endIcon={
                        mutationPDFAll.isPending ? (
                          <CircularProgress size={16} color="inherit" />
                        ) : null
                      }
                      onClick={() => mutationPDFAll.mutate()}
                    >
                      {mutationPDFAll.isPending
                        ? 'Exporting...'
                        : 'Export all to PDF'}
                    </Button>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Button
                      disabled={
                        selected.length === 0 ||
                        mutationPDFAll.isPending ||
                        mutationPDFSelected.isPending
                      }
                      fullWidth
                      color="info"
                      size="small"
                      variant="outlined"
                      startIcon={<PictureAsPdfIcon fontSize="small" />}
                      endIcon={
                        mutationPDFSelected.isPending ? (
                          <CircularProgress size={16} color="inherit" />
                        ) : null
                      }
                      onClick={() => mutationPDFSelected.mutate()}
                    >
                      {mutationPDFSelected.isPending
                        ? 'Exporting...'
                        : selected.length === 0
                          ? 'Export selected to PDF'
                          : `Export (${selected.length}) to PDF`}
                    </Button>
                  </Grid>
                </React.Fragment>
              ) : null}

              {mutationPDFAll.isError || mutationPDFSelected.isError ? (
                <Grid item xs={12}>
                  <Alert
                    severity="error"
                    onClick={() => {
                      mutationPDFAll.reset();
                      mutationPDFSelected.reset();
                    }}
                  >
                    <AlertTitle>Export Error</AlertTitle>
                    Try selecting less Leads for export
                  </Alert>
                </Grid>
              ) : null}
            </Grid>
          </Box>

          {exportStack.length ? (
            <Box sx={{ p: 1 }}>
              <Stack spacing={1}>
                {exportStack.map((chunk) => {
                  return (
                    <Button
                      key={chunk.index}
                      disabled={chunk.exported || mutationPDFChunk.isPending}
                      fullWidth
                      size="small"
                      variant="outlined"
                      startIcon={<PictureAsPdfIcon fontSize="small" />}
                      endIcon={
                        chunk.exported ? (
                          <CheckCircleIcon />
                        ) : (
                          <RadioButtonUncheckedIcon />
                        )
                      }
                      onClick={() => mutationPDFChunk.mutate(chunk)}
                    >
                      <Stack spacing={1} direction="row" alignItems="center">
                        <Box>Export All to PDF</Box>
                        <Box sx={{ fontFamily: 'Roboto Mono' }}>
                          ({chunk.start}-{chunk.end})
                        </Box>
                      </Stack>
                    </Button>
                  );
                })}

                {selected.length === props.data.length ? null : (
                  <React.Fragment>
                    {selectedStack.map((chunk) => {
                      return (
                        <Button
                          key={chunk.index}
                          disabled={
                            chunk.exported || mutationPDFChunkSelected.isPending
                          }
                          fullWidth
                          color="info"
                          size="small"
                          variant="outlined"
                          startIcon={<PictureAsPdfIcon fontSize="small" />}
                          endIcon={
                            chunk.exported ? (
                              <CheckCircleIcon />
                            ) : (
                              <RadioButtonUncheckedIcon />
                            )
                          }
                          onClick={() => mutationPDFChunkSelected.mutate(chunk)}
                        >
                          <Stack
                            spacing={1}
                            direction="row"
                            alignItems="center"
                          >
                            <Box>Export selected to PDF</Box>
                            <Box sx={{ fontFamily: 'Roboto Mono' }}>
                              ({chunk.start}-{chunk.end})
                            </Box>
                          </Stack>
                        </Button>
                      );
                    })}

                    {selectedStack.length === 0 && exportStack.length > 0 ? (
                      <Button
                        disabled={
                          selected.length === 0 || mutationPDFSelected.isPending
                        }
                        fullWidth
                        color="info"
                        size="small"
                        variant="outlined"
                        startIcon={<PictureAsPdfIcon fontSize="small" />}
                        endIcon={
                          mutationPDFSelected.isPending ? (
                            <CircularProgress size={16} color="inherit" />
                          ) : null
                        }
                        onClick={() => mutationPDFSelected.mutate()}
                      >
                        {mutationPDFSelected.isPending
                          ? 'Exporting...'
                          : selected.length === 0
                            ? 'Export selected to PDF'
                            : `Export (${selected.length}) to PDF`}
                      </Button>
                    ) : null}

                    {mutationPDFChunk.isPending ||
                    mutationPDFChunkSelected.isPending ? (
                      <LinearProgress />
                    ) : null}

                    {mutationPDFChunk.isError ||
                    mutationPDFChunkSelected.isError ? (
                      <Alert
                        severity="error"
                        onClick={() => {
                          mutationPDFAll.reset();
                          mutationPDFSelected.reset();
                          mutationPDFChunk.reset();
                          mutationPDFChunkSelected.reset();
                        }}
                      >
                        <AlertTitle>Export Error</AlertTitle>
                        Please refresh the page and try again.
                      </Alert>
                    ) : null}
                  </React.Fragment>
                )}
              </Stack>
            </Box>
          ) : null}

          <Box sx={{ p: 1 }}>
            <Stack spacing={1} direction="row" alignItems="center">
              <Box
                sx={{
                  flex: 1,
                  borderWidth: 1,
                  borderStyle: 'solid',
                  borderColor: '#eaeaea',
                  borderRadius: 1,
                  transition: 'all 0.2s',
                  ':hover': {
                    borderColor: '#aeaeae',
                  },
                }}
              >
                <Stack
                  spacing={1}
                  direction="row"
                  alignItems="center"
                  sx={{ pr: 1 }}
                >
                  <Checkbox
                    size="small"
                    color="info"
                    checked={selectAll}
                    onChange={(event, checked) => {
                      if (checked) {
                        setSelectAll(true);
                        setSelectUnx(false);
                        setSelected(props.data.map((i) => i.DistHistID));
                      } else {
                        setSelectAll(false);
                        setSelectUnx(false);
                        setSelected([]);
                      }
                    }}
                  />
                  <Box
                    sx={{ fontWeight: 'bold', cursor: 'pointer' }}
                    onClick={(event) => {
                      if (!selectAll) {
                        setSelectAll(true);
                        setSelectUnx(false);
                        setSelected(props.data.map((i) => i.DistHistID));
                      } else {
                        setSelectAll(false);
                        setSelectUnx(false);
                        setSelected([]);
                      }
                    }}
                  >
                    {selectAll ? (
                      <Box>Deselect All ({props.data.length})</Box>
                    ) : (
                      <Box>Select All ({props.data.length})</Box>
                    )}
                  </Box>
                </Stack>
              </Box>

              <Box
                sx={{
                  flex: 1,
                  borderWidth: 1,
                  borderStyle: 'solid',
                  borderColor: '#eaeaea',
                  borderRadius: 1,
                  transition: 'all 0.2s',
                  ':hover': {
                    borderColor: '#aeaeae',
                  },
                }}
              >
                <Stack
                  spacing={1}
                  direction="row"
                  alignItems="center"
                  sx={{ pr: 1 }}
                >
                  <Checkbox
                    size="small"
                    color="info"
                    checked={selectUnx}
                    onChange={(event, checked) => {
                      if (checked) {
                        setSelectUnx(true);
                        setSelectAll(false);
                        setSelected(
                          props.data
                            .filter((i) => i.AgtExport === false)
                            .map((i) => i.DistHistID),
                        );
                      } else {
                        setSelectUnx(false);
                        setSelectAll(false);
                        setSelected([]);
                      }
                    }}
                  />
                  <Box
                    sx={{ fontWeight: 'bold', cursor: 'pointer' }}
                    onClick={(event) => {
                      if (!selectUnx) {
                        setSelectUnx(true);
                        setSelectAll(false);
                        setSelected(
                          props.data
                            .filter((i) => i.AgtExport === false)
                            .map((i) => i.DistHistID),
                        );
                      } else {
                        setSelectUnx(false);
                        setSelectAll(false);
                        setSelected([]);
                      }
                    }}
                  >
                    {selectUnx ? (
                      <Box>
                        Deselect Unexported (
                        {props.data.filter((i) => i.AgtExport === false).length}
                        )
                      </Box>
                    ) : (
                      <Box>
                        Select Unexported (
                        {props.data.filter((i) => i.AgtExport === false).length}
                        )
                      </Box>
                    )}
                  </Box>
                </Stack>
              </Box>
            </Stack>
          </Box>

          <Divider />

          <Box
            sx={{
              pb: 16,
              minHeight: 0,
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              overflow: 'auto',
            }}
          >
            <Stack spacing={1} sx={{ p: 1 }}>
              {displayData.map((item) => {
                const key = `${item.LeadID}-${item.DistHistID}`;

                const statusType = leadStatuses.find(
                  (i) => i.status === item.ContactStatus,
                );

                const isChecked = selected.includes(item.DistHistID);

                return (
                  <Box
                    key={key}
                    sx={{
                      borderWidth: 1,
                      borderStyle: 'solid',
                      borderColor: isChecked ? '#2196f3' : '#eaeaea',
                      borderRadius: 1,
                      transition: 'all 0.2s',
                      ':hover': {
                        borderColor: '#aeaeae',
                      },
                    }}
                  >
                    <Stack
                      spacing={1}
                      direction="row"
                      alignItems="center"
                      sx={{ pr: 1 }}
                    >
                      <Checkbox
                        size="small"
                        color="info"
                        checked={isChecked}
                        onChange={(event, checked) => {
                          setSelected((currentState) => {
                            if (checked) {
                              return [item.DistHistID, ...currentState];
                            } else {
                              return currentState.filter(
                                (i) => i !== item.DistHistID,
                              );
                            }
                          });
                        }}
                      />

                      <Box
                        sx={{
                          minWidth: 18,
                          textTransform: 'uppercase',
                          fontWeight: 'bold',
                          color: getProductColor(item.Product),
                          fontSize: 10,
                        }}
                      >
                        {item.Product}
                      </Box>

                      <Box
                        sx={{
                          textTransform: 'uppercase',
                          fontWeight: 'bold',
                          fontFamily: 'Roboto Mono',
                          color: 'teal',
                          fontSize: 12,
                        }}
                      >
                        {item.LeadType}
                      </Box>

                      <Box
                        sx={{
                          backgroundColor: statusType?.color || '#e1e1e1',
                          borderRadius: 2,
                          height: 24,
                          width: 4,
                        }}
                      />

                      <Box>
                        <Stack spacing={1} direction="row" alignItems="center">
                          <Box sx={{ fontSize: { xs: 12, sm: 14 } }}>
                            {item.FirstName} {item.LastName}
                          </Box>

                          {item.Age ? (
                            <Box
                              sx={{
                                fontSize: 12,
                                fontFamily: 'Roboto Mono',
                                fontWeight: 'bold',
                              }}
                            >
                              {item.Age}
                            </Box>
                          ) : null}
                        </Stack>

                        {item.AgtExport ? (
                          <Box
                            sx={{
                              fontSize: 10,
                              fontWeight: 'bold',
                              fontFamily: 'Roboto Mono',
                              textTransform: 'uppercase',
                              color: '#2196f3',
                            }}
                          >
                            Exported to PDF
                          </Box>
                        ) : null}
                      </Box>

                      <Box sx={{ flex: 1 }} />

                      <Stack spacing={1} direction="row" alignItems="center">
                        <Box sx={{ textAlign: 'right' }}>
                          <Box sx={{ fontSize: 12 }}>{item.City}</Box>
                          <Box
                            sx={{
                              textTransform: 'uppercase',
                              fontWeight: 'bold',
                              color: '#9b9b9b',
                              fontSize: 9,
                            }}
                          >
                            {item.County}
                          </Box>
                        </Box>

                        <Box
                          sx={{
                            color: '#b26500',
                            fontSize: 16,
                            fontWeight: 'bold',
                            fontFamily: 'Roboto Mono',
                          }}
                        >
                          {item.St}
                        </Box>
                      </Stack>
                    </Stack>
                  </Box>
                );
              })}
            </Stack>
          </Box>
        </Box>
      </Drawer>
    </React.Fragment>
  );
}
