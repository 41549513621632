import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Tooltip from '@mui/material/Tooltip';
import {
  GoogleMap,
  HeatmapLayer,
  useJsApiLoader,
} from '@react-google-maps/api';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import React from 'react';
import { RouteContainer } from '../../components/route-container';
import { AuthContext } from '../../contexts/auth-context';
import { CartContext } from '../../contexts/cart-context';
import geoCounties from '../../data/gz_2010_us_050_00_20m.json';
import { usePermissions } from '../../hooks/use-permissions';
import {
  AWS_GROUP,
  FeatureData,
  FeaturePropertiesData,
  LEAD_PRODUCT,
} from '../../types';
import { captureError } from '../../utils/capture-error';
import { getProductTitle } from '../../utils/general';
import {
  defaultStyle,
  discountStyle,
  getCounty,
  getCountyCoords,
  getCountyFIPS,
  getFeatureFIPS,
  hoverStyle,
  libraries,
  premiumStyle,
  selectedStyle,
  stateAbbrs,
  stateNames,
} from '../../utils/map';
import { stripDiacritics } from '../../utils/strip-diacritics';
import { LeadMultiplierData } from '../subscriptions/data';
import { CountyListItem } from './county-list-item';
import { CountyInventoryData } from './data';
import { getCartItemUpdate, getDetailsFIPS } from './purchase-utils';

// @ts-ignore
const searchOptions = geoCounties.features.map((i) => {
  return i.properties;
}) as FeaturePropertiesData[];

const filterOptions = ['INV', LEAD_PRODUCT['FEX'], LEAD_PRODUCT['MP']];

export function LeadsMap() {
  // Context
  const {
    state: { leadsMap, cart },
    dispatch,
  } = React.useContext(CartContext);
  const {
    state: { impersonatedAgent, user },
  } = React.useContext(AuthContext);
  // Ref
  const mapRef = React.useRef<google.maps.Map>();
  // State
  const [filters, setFilters] = React.useState<string[]>([filterOptions[0]]);
  const [center, setCenter] = React.useState({
    lat: 36.07813,
    lng: -79.432279,
  });
  const [loading, setLoading] = React.useState(true);
  const [heatmap, setHeatmap] = React.useState([]);
  const [zoom, setZoom] = React.useState(7);
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  // Hooks
  const { hasGroup } = usePermissions();

  // Get user's location
  React.useEffect(() => {
    const getCurrentPosition = () => {
      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          (successCallback) => {
            resolve(successCallback);
          },
          (errorCallback) => {
            reject(errorCallback);
          },
        );
      });
    };
    const fetchData = async () => {
      try {
        setLoading(true);
        // Alliance Convention Center
        const defaultCoordinates: GeolocationCoordinates = {
          accuracy: 10,
          altitude: null,
          altitudeAccuracy: null,
          heading: null,
          latitude: 36.07813,
          longitude: -79.432279,
          speed: null,
        };

        if ('geolocation' in navigator) {
          // Coordinates
          try {
            const position =
              (await getCurrentPosition()) as GeolocationPosition;
            setCenter({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
          } catch (error) {
            // Permission was denied
            setCenter({
              lat: defaultCoordinates.latitude,
              lng: defaultCoordinates.longitude,
            });
          }
        } else {
          /* geolocation IS NOT available */
          setCenter({
            lat: defaultCoordinates.latitude,
            lng: defaultCoordinates.longitude,
          });
        }
      } catch (error) {
        captureError({ data: { error } });
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  // Query - Multipliers
  const pathMult = '/leads/multipliers';
  const queryMult = useQuery({
    queryKey: [pathMult],
    queryFn: async () => {
      const response: {
        data: LeadMultiplierData[];
      } = await API.post('LeadsAPI', pathMult, {});

      return response.data || [];
    },
  });

  React.useEffect(() => {
    if (mapRef.current) {
      mapRef.current.data.forEach((feature) => {
        const FIPS = getFeatureFIPS(feature);
        const addedCounty = leadsMap.find((i) => getFeatureFIPS(i) === FIPS);
        if (Boolean(addedCounty)) {
          feature.setProperty('isSelected', true);
        } else {
          feature.setProperty('isSelected', false);
        }
      });
    }
  }, [mapRef, leadsMap]);

  React.useEffect(() => {
    if (mapRef.current && queryMult.data) {
      mapRef.current.data.setStyle((feature) => {
        const FIPS = getFeatureFIPS(feature);
        const CountyMult = queryMult.data.find((i) => i.FIPS === FIPS) || null;
        if (feature.getProperty('isSelected')) {
          return selectedStyle;
        } else if (filters.includes(LEAD_PRODUCT['FEX'])) {
          const Multiplier = CountyMult?.MltFEX || 1;
          if (Multiplier > 1) {
            return { ...premiumStyle, fillOpacity: Multiplier / 3 };
          } else if (Multiplier < 1) {
            return { ...discountStyle, fillOpacity: 1 - Multiplier };
          }
        } else if (filters.includes(LEAD_PRODUCT['MP'])) {
          const Multiplier = CountyMult?.MltMP || 1;
          if (Multiplier > 1) {
            return { ...premiumStyle, fillOpacity: Multiplier / 3 };
          } else if (Multiplier < 1) {
            return { ...discountStyle, fillOpacity: 1 - Multiplier };
          }
        }

        return defaultStyle;
      });
    }
  }, [mapRef, filters, queryMult.data]);

  // Only Admins can manage carts for other users
  let AgtNo = user?.getUsername() || null;
  if (hasGroup(AWS_GROUP.LDS_GlobalAdmin)) {
    AgtNo = impersonatedAgent?.AgtNo || null;
  }

  // Query - Available
  const path = '/leads/instant-purchase/available';
  const query = useQuery({
    queryKey: [path, { AgtNo }],
    queryFn: async () => {
      const response: {
        data: CountyInventoryData[];
      } = await API.post('LeadsAPI', path, { body: { AgtNo } });

      return response.data || [];
    },
  });

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY || '',
    libraries,
  });

  // Create Heatmap for Inventory
  React.useEffect(() => {
    if (isLoaded && query.data) {
      const newHeatmap: any = [];
      // @ts-ignore
      geoCounties.features.forEach((geoCounty: FeatureData) => {
        const FIPS = getCountyFIPS(geoCounty);

        // Find Available Leads for County
        const invCounty = query.data.find((i) => i.FIPS === FIPS);
        let weight = 0;
        if (invCounty) {
          weight = invCounty.FEXLeads + invCounty.MPLeads;
        }

        // Coordinates for County
        const location = getCountyCoords(geoCounty);

        if (location) {
          newHeatmap.push({ location: location, weight });
        }
      });

      setHeatmap(newHeatmap);
    }
  }, [query.data, isLoaded]);

  // Don't render all 3000+ Counties in the Autcomplete component until
  // after the user has started typing
  let filteredOptions: FeaturePropertiesData[] = [];
  if (inputValue.trim().length >= 2) {
    filteredOptions = searchOptions.filter((i) =>
      stripDiacritics(`${i.NAME} ${i.LSAD}`)
        .toLowerCase()
        .includes(inputValue.toLowerCase()),
    );
  }

  const queryClient = useQueryClient();
  const pathCart = '/leads/instant-purchase/cart';

  // Mutation - Remove Leads for County from Cart
  const mutationRemove = useMutation({
    mutationFn: async (params: { FIPS: string }) => {
      if (cart) {
        const { FIPS } = params;
        const CartItems = cart.CartItems || [];
        const { purchases } = getDetailsFIPS({ data: CartItems, FIPS });

        if (purchases.length) {
          for (const key in purchases) {
            const purchase = purchases[key];
            const Quantity = 0;
            const newItem = { ...purchase, Quantity };
            const update = getCartItemUpdate({
              item: newItem,
              BillToAgt: cart.BillToAgt,
            });
            if (update) {
              await API.post(
                'LeadsAPI',
                '/leads/instant-purchase/cart/update',
                { body: { ...update } },
              );
            }
          }
        }
      }
    },
    onSuccess: async () => {
      // Refetch cart data
      await queryClient.invalidateQueries({ queryKey: [pathCart] });
    },
    onError: (error) => captureError({ data: { error } }),
  });

  // Mutation - Reset Cart
  const mutationReset = useMutation({
    mutationFn: async () => {
      if (cart) {
        await API.post('LeadsAPI', '/leads/instant-purchase/cart/clear', {
          body: { CartId: cart.CartId, BillToAgt: cart.BillToAgt },
        });
      }
    },
    onSuccess: async () => {
      // Refetch Cart Content after adding any updates
      await queryClient.invalidateQueries({ queryKey: [pathCart] });
      // Reset selected counties on Map
      dispatch({ type: 'RESET_LEADS' });
    },
    onError: (error) => captureError({ data: { error } }),
  });

  return (
    <RouteContainer routeTitle="Lead Map" loading={query.isLoading}>
      <Container maxWidth="lg">
        <Box sx={{ pt: 2, pb: 16 }}>
          <Paper
            elevation={0}
            sx={{ border: '1px solid #00000022', overflow: 'hidden' }}
          >
            <Stack
              spacing={{ xs: 0.5, md: 1 }}
              direction="row"
              alignItems="center"
              sx={{ p: { xs: 0.5, md: 1 } }}
            >
              <Tooltip title="Counties selected" placement="right" arrow>
                <Box
                  sx={{
                    mr: 1,
                    height: 28,
                    width: 28,
                    borderRadius: 3,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor:
                      leadsMap.length === 0 ? '#2196f388' : '#2196f3',
                    fontWeight: 'bold',
                    fontSize: 16,
                    color: '#fff',
                  }}
                >
                  {leadsMap.length}
                </Box>
              </Tooltip>

              <Box sx={{ flex: 1 }}>
                <Autocomplete
                  disabled={loading}
                  fullWidth
                  blurOnSelect
                  clearOnBlur
                  size="small"
                  noOptionsText="Start typing for options..."
                  options={filteredOptions}
                  getOptionLabel={(option) => `${option.NAME}`}
                  getOptionKey={(option) => `${option.GEO_ID}`}
                  filterOptions={createFilterOptions({
                    stringify: (option) => `${option.NAME} ${option.LSAD}`,
                  })}
                  renderOption={(props, option) => {
                    if (option.STATE) {
                      const StateName = stateNames[option.STATE];
                      const StateAbbr = stateAbbrs[StateName];
                      return (
                        <Box component="li" {...props}>
                          <Stack
                            spacing={1}
                            direction="row"
                            alignItems="center"
                          >
                            <Box
                              sx={{
                                color: '#b26500',
                                fontSize: 14,
                                fontWeight: 'bold',
                                fontFamily: 'Roboto Mono',
                              }}
                            >
                              {StateAbbr}
                            </Box>
                            <Box>
                              {option.NAME} {option.LSAD}
                            </Box>
                          </Stack>
                        </Box>
                      );
                    } else {
                      return null;
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search for County"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                      }}
                      InputProps={{
                        ...params.InputProps,
                        type: 'search',
                      }}
                    />
                  )}
                  value={value}
                  onChange={(event, value, reason) => {
                    if (reason === 'selectOption' && value) {
                      if (mapRef.current) {
                        mapRef.current.data.forEach((feature) => {
                          const GEO_ID = feature.getProperty(
                            'GEO_ID',
                          ) as string;
                          if (GEO_ID === value.GEO_ID) {
                            // Add this county as a selected county on the map
                            dispatch({
                              type: 'ADD_FEAT_LEADS',
                              payload: feature,
                            });
                            // Recent the map on this county
                            const county = getCounty(feature);
                            if (county) {
                              setCenter({ lat: county.lat, lng: county.lng });
                            }
                          }
                        });
                      }
                      // Reset search component
                      setValue(null);
                      setInputValue('');
                    }
                  }}
                  inputValue={inputValue}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                  }}
                />
              </Box>

              <ToggleButtonGroup
                color="info"
                size="small"
                value={filters}
                onChange={(event, newValues) => {
                  const newFilters = newValues as string[];
                  // Determine which button was clicked
                  const changedValue =
                    newFilters.find((value) => !filters.includes(value)) ||
                    filters.find((value) => !newFilters.includes(value));

                  // Prevent 'FEX' and 'MP' from being selected together
                  let updatedSelectedValues = [...newFilters];
                  if (
                    newFilters.includes(LEAD_PRODUCT['FEX']) &&
                    newFilters.includes(LEAD_PRODUCT['MP'])
                  ) {
                    if (changedValue === LEAD_PRODUCT['FEX']) {
                      updatedSelectedValues = newFilters.filter(
                        (value) => value !== LEAD_PRODUCT['MP'],
                      );
                    } else if (changedValue === LEAD_PRODUCT['MP']) {
                      updatedSelectedValues = newFilters.filter(
                        (value) => value !== LEAD_PRODUCT['FEX'],
                      );
                    }
                  }

                  setFilters(updatedSelectedValues);
                }}
              >
                {filterOptions.map((option) => {
                  const isSelected = filters.includes(option);

                  let buttonText = option;
                  if (Object.keys(LEAD_PRODUCT).includes(option)) {
                    buttonText = getProductTitle(option as LEAD_PRODUCT);
                  } else if (option === 'INV') {
                    buttonText = 'Inventory';
                  }

                  return (
                    <ToggleButton key={option} value={option} sx={{ py: 0.4 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box
                          sx={{
                            display: { xs: 'none', md: 'flex' },
                            alignItems: 'center',
                            mr: 0.6,
                          }}
                        >
                          {isSelected ? (
                            <HighlightOffIcon fontSize="small" />
                          ) : (
                            <AddCircleOutlineIcon fontSize="small" />
                          )}
                        </Box>
                        <Box sx={{ display: { xs: 'inherit', lg: 'none' } }}>
                          {option}
                        </Box>
                        <Box
                          sx={{
                            display: { xs: 'none', lg: 'inherit' },
                            textWrap: 'nowrap',
                          }}
                        >
                          {buttonText}
                        </Box>
                      </Box>
                    </ToggleButton>
                  );
                })}
              </ToggleButtonGroup>

              <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                <Button
                  disabled={leadsMap.length === 0 || mutationReset.isPending}
                  size="small"
                  variant="outlined"
                  startIcon={
                    mutationReset.isPending ? (
                      <CircularProgress size={18} color="inherit" />
                    ) : (
                      <RestartAltIcon />
                    )
                  }
                  onClick={() => {
                    if (cart && cart.CartId) {
                      mutationReset.mutate();
                    } else {
                      dispatch({ type: 'RESET_LEADS' });
                    }
                  }}
                >
                  Reset
                </Button>
              </Box>

              <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                <IconButton
                  disabled={leadsMap.length === 0 || mutationReset.isPending}
                  size="small"
                  onClick={() => {
                    if (cart && cart.CartId) {
                      mutationReset.mutate();
                    } else {
                      dispatch({ type: 'RESET_LEADS' });
                    }
                  }}
                >
                  {mutationReset.isPending ? (
                    <CircularProgress size={18} color="inherit" />
                  ) : (
                    <RestartAltIcon />
                  )}
                </IconButton>
              </Box>
            </Stack>

            <Divider />

            {isLoaded && !loading ? (
              <Box sx={{ height: { xs: 256, md: 384 } }}>
                <GoogleMap
                  options={{ streetViewControl: false }}
                  mapContainerStyle={{ width: '100%', height: '100%' }}
                  center={center}
                  zoom={zoom}
                  onZoomChanged={() => {
                    if (mapRef.current) {
                      setZoom(mapRef.current.getZoom() || 7);
                    }
                  }}
                  onLoad={(map) => {
                    mapRef.current = map;

                    const geoJsonData = geoCounties;
                    map.data.addGeoJson(geoJsonData);
                    map.data.setStyle(defaultStyle);
                    map.data.forEach((feature) => {
                      const FIPS = getFeatureFIPS(feature);
                      const addedCounty = leadsMap.find(
                        (i) => getFeatureFIPS(i) === FIPS,
                      );

                      if (Boolean(addedCounty)) {
                        feature.setProperty('isSelected', true);
                      } else {
                        feature.setProperty('isSelected', false);
                      }

                      // Check if feature is in cart
                      if (FIPS && cart && cart.CartItems) {
                        const purchase = cart.CartItems.find(
                          (i) => i.FIPS === FIPS,
                        );
                        if (purchase) {
                          dispatch({ type: 'CART_SET_FEAT', payload: feature });
                        }
                      }
                    });

                    // Change the color when the isSelected property is set to true.
                    map.data.setStyle((feature) => {
                      if (feature.getProperty('isSelected')) {
                        return selectedStyle;
                      } else {
                        return defaultStyle;
                      }
                    });

                    // When the user clicks, set 'isSelected', changing the color of the feature
                    map.data.addListener('click', (event: any) => {
                      const feature = event.feature as google.maps.Data.Feature;
                      if (feature.getProperty('isSelected')) {
                        const GEO_ID = feature.getProperty('GEO_ID') as string;
                        dispatch({
                          type: 'REMOVE_FEAT_LEADS',
                          payload: { GEO_ID },
                        });
                        const FIPS = getFeatureFIPS(feature);
                        if (FIPS) {
                          // Remove any Cart Items for this County
                          mutationRemove.mutate({ FIPS });
                        }
                      } else {
                        dispatch({ type: 'ADD_FEAT_LEADS', payload: feature });
                      }
                    });

                    // Call revertStyle() to remove all overrides. This will use the style rules
                    // defined in the function passed to setStyle()
                    map.data.addListener('mouseover', (event: any) => {
                      map.data.revertStyle();
                      map.data.overrideStyle(event.feature, hoverStyle);
                    });

                    map.data.addListener('mouseout', (event: any) => {
                      map.data.revertStyle();
                    });
                  }}
                >
                  {filters.includes('INV') ? (
                    <HeatmapLayer
                      data={heatmap.map((item: any) => {
                        return {
                          location: new window.google.maps.LatLng(
                            item.location[0],
                            item.location[1],
                          ),
                          weight: item.weight,
                        };
                      })}
                      options={{
                        radius:
                          zoom < 5
                            ? 5 * zoom
                            : zoom < 9
                              ? 10 * zoom
                              : 20 * zoom,
                        opacity: 0.7,
                      }}
                    />
                  ) : null}
                </GoogleMap>
              </Box>
            ) : (
              <Box
                sx={{
                  height: { xs: 256, md: 384 },
                  backgroundColor: '#E6E3E0',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <CircularProgress color="info" size={64} />
              </Box>
            )}

            {leadsMap.length > 0 ? (
              <Box>
                <Box sx={{ p: 1 }}>
                  <Stack spacing={1}>
                    {leadsMap.map((feature, index) => {
                      const GEO_ID = feature.getProperty('GEO_ID') as string;
                      const county = getCounty(feature);
                      const leadMult = queryMult.data?.find(
                        (i) => i.FIPS === county?.FIPS,
                      );

                      return (
                        <CountyListItem
                          key={GEO_ID}
                          feature={feature}
                          county={county}
                          leadMult={leadMult}
                          index={index}
                          onUnselect={() => {
                            if (mapRef.current) {
                              dispatch({
                                type: 'REMOVE_FEAT_LEADS',
                                payload: { GEO_ID },
                              });
                            }
                          }}
                        />
                      );
                    })}
                  </Stack>
                </Box>

                <Divider />

                <Button
                  fullWidth
                  color="info"
                  size="large"
                  startIcon={<ShoppingCartCheckoutIcon />}
                  sx={{ borderRadius: 0 }}
                  onClick={() => dispatch({ type: 'TOGGLE_CART' })}
                >
                  View Cart
                </Button>
              </Box>
            ) : (
              <Box
                sx={{
                  p: 3,
                  textAlign: 'center',
                  fontFamily: 'Roboto Mono',
                  color: '#4a4a4a',
                }}
              >
                Select counties from the map above to see a breakdown of Lead
                types
              </Box>
            )}
          </Paper>
        </Box>
      </Container>
    </RouteContainer>
  );
}
